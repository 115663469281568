<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="fill-width">
    <v-card id="messagePage" class="card-container">
      <v-card
        v-show="!isMobile || (isMobile && !isSelectedMember)"
        class="member-list-sidebar"
        :class="tab === 1 ? 'min-full-width' : ''"
      >
        <div
          class="d-flex align-center"
          :class="tab === 1 ? 'tab-width' : ''"
        >
          <v-card-title class="py-1">Messages</v-card-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="isUnseenMessages">
            <template v-slot:activator="{ on }">
              <v-btn
                  icon
                  v-on="on"
                  @click="markAllAsRead"
                  color="primary"
                  :loading="markLoading"
              >
                <v-icon color="primary">fa-regular fa-envelope-dot</v-icon>
              </v-btn>
            </template>
            <span>Mark all as read</span>
          </v-tooltip>
          <v-btn icon color="primary" class="ma-0" :loading="isLoading" @click="refresh">
            <v-icon dark>refresh</v-icon>
          </v-btn>
          <v-icon v-if="isMobile" class="ma-0" @click.native="$emit('closeModal')">close</v-icon>
        </div>
        <v-card-text class="pt-0">
          <v-tabs
              grow
              v-model="tab"
              show-arrows
              :class="tab === 1 ? 'tab-width' : ''"
          >
            <v-tab>
              <h5>User View</h5>
            </v-tab>
            <v-tab>
              <h5>Thread View</h5>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <user-view-component
                  class="mt-2"
                  ref="UserViewComponent"
                  @onSelectMember="changeMember"
                  @refresh="refresh"
              />
            </v-tab-item>
            <v-tab-item>
              <thread-view-component
                  ref="ThreadViewComponent"
                  @isMessageTopic="value => this.isMessageTopic = value"
                  @onSelectConversation="onSelectConversation"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
      <v-card-text class="conversation-container" v-if="isSelectedMember && tab === 0">
        <div class="py-3">
          <v-layout align-center>
            <v-flex xs2>
              <v-btn icon @click="closeConversationContainer" v-if="isMobile">
                <v-icon size="24">chevron_left</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs10>
              <v-layout justify-center align-center>
                <div class="text-xs-center title">{{ selectedMember.nickname }} {{ selectedMember.lastname }}</div>
                <v-icon size="24" class="ml-1" v-if="!isSms">mail_outline</v-icon>
                <v-icon size="24" class="ml-1" v-else>phone_iphone</v-icon>
              </v-layout>
            </v-flex>
            <v-flex xs2></v-flex>
          </v-layout>
        </div>
        <v-divider></v-divider>
        <div ref="messageListView" class="message-list-view">
          <v-btn
              small
              color="primary"
              block
              text
              @click="loadMoreMessages"
              v-if="messages.pagination.last_page > messages.pagination.page"
              :loading="isMessagesLoading"
          >
            <span>Show more messages</span>
          </v-btn>
          <div v-for="(group, index) in groupedMessageList" :key="index">
            <div>
              <v-layout align-center class="my-4">
                <v-divider></v-divider>
                <div class="px-4">{{ group.date }}</div>
                <v-divider></v-divider>
              </v-layout>
            </div>
            <div class="mb-2" v-for="message in group.messageList" :key="message.id">
              <v-layout>
                <v-spacer v-if="!message.is_reply"></v-spacer>
                <v-avatar
                    v-if="message.is_reply && !selectedMember.avatar_url"
                    :color="getColor(selectedMember.nickname)" >
                  <span class="white--text headline">{{ selectedMember.nickname[0] }}</span>
                </v-avatar>
                <v-avatar v-if="message.is_reply && selectedMember.avatar_url">
                  <img
                      :src="getThumbImageMedium(selectedMember.avatar_url)"
                      :alt="selectedMember.nickname + ' '+ selectedMember.lastname"
                  />
                </v-avatar>
                <div class="message" :class="{'reply': message.is_reply, opened: message.opened_at}">

                  <v-layout align-center>
                    <div class="font-weight-bold">
                      {{ getMessageTitle(message) }}
                      {{ message.is_reply ? `- ${selectedMember.nickname} ${selectedMember.lastname}` : '' }}
                    </div>
                    
                    <!-- empty track_filter length is 13: Example: {"filter":[]} -->
                    <v-btn 
                      v-if="message.track_filter && message.track_filter.length > 13 && !message.is_reply" 
                      color="success" 
                      x-small 
                      class="ml-2" 
                      outlined 
                      @click="handleFilterClick(message.track_filter)">Filter</v-btn>

                    <v-spacer/>
                    <div class="ml-3">{{ message.created_at | timeFormatter }}</div>
                  </v-layout>
                  <div v-if="message.is_sms && message.files && message.files.length > 0">
                    <div
                        v-for="file in message.files"
                        :key="file.id"
                    >
                      <v-img
                          v-if="file.type.includes('image')"
                          :alt="file.name"
                          :src="pathName(file)"></v-img>
                    </div>
                  </div>
                  <div class="mt-1 tinyEditor readonly" v-html="message.message"></div>
                  <div class="message_read-at"
                       v-if="message.opened_at"
                  >
                    read at - {{ message.opened_at | dateTimeFormatter }}
                  </div>
                  <div class="message_mark-unseen" v-if="message.is_reply">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            small
                            v-on="on"
                            @click="unSeenMessage(message.id)"
                            class="message_mark-unseen__btn"
                        >
                          <v-icon size="18">mail_outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Mark as unread</span>
                    </v-tooltip>
                  </div>

                  <div
                      v-if="!message.is_sms && message.files && message.files.length > 0"
                  >
                    Attached files:
                    <div
                        v-for="file in message.files"
                        :key="file.id"
                    >
                      <v-chip
                          class="mr-2 mt-2"
                      >
                        {{ file.name }}
                      </v-chip>
                      <v-tooltip bottom >
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" size="16" @click="downloadFile(file)">fa-solid fa-download</v-icon>
                        </template>
                        <span>Downland</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <v-avatar color="blue-grey" v-if="!message.is_reply && !client.avatar_url">
                  <span class="white--text headline">{{ client.assoc_abbrev[0] }}</span>
                </v-avatar>
                <v-avatar v-if="!message.is_reply && client.avatar_url" style="border: 1px solid #3d4977;">
                  <img :src="getThumbImageMedium(client.avatar_url)" :alt="client.assoc_abbrev"/>
                </v-avatar>
              </v-layout>
            </div>
          </div>
        </div>
        <div class="input_area" v-if="!isSms">
          <div v-if="isBad(selectedMember.email)">
            <v-alert :value="true" type="warning">
              Emails to {{ selectedMember.nickname }} {{ selectedMember.lastname }} unavailable due to an undeliverable
              email address. Please correct and try again.
            </v-alert>
          </div>
          <div v-else-if="selectedMember.silence">
            <v-alert :value="true" type="warning">
              This user is currently silenced and cannot receive messages at this time.
              <v-btn
                  v-if="isSuperAdmin || isVIP"
                  x-small
                  depressed
                  @click="updateUserMember()"
                  class="ml-4"
              >
                Unsilence
              </v-btn>
            </v-alert>
          </div>
          <div v-else>
            <div>
              <v-alert v-if="isSummaryUploadLimit" class="mt-3" dense type="error" outlined :value="true">
                The size of images and attached files is more than 25MB
              </v-alert>
              <div
                  v-if="isEditor && !client.is_reply_disabled"
                  class="input_area__field input_area__editor"
              >
                <div class="input_area__field--ez-login-btn">
                  <v-flex
                      v-if="!isFilesInput"
                      @click="isFilesInput = !isFilesInput"
                      class="d-flex align-baseline"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            small
                            color="primary"
                            class="ml-0"
                            @click="removeAllAttachedFiles"
                            v-on="on"
                            :loading="linkInsertion"
                        >
                          <v-icon size="16">mdi-paperclip</v-icon>
                        </v-btn>
                      </template>
                      <span>Attach files</span>
                    </v-tooltip>
                  </v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          icon
                          small
                          color="primary"
                          class="ml-0"
                          @click="onAddVariableEmail('{ez_login}')"
                          v-on="on"
                          :loading="linkInsertion"
                      >
                        <v-icon size="16">insert_link</v-icon>
                      </v-btn>
                    </template>
                    <span>Insert ezlogin link</span>
                  </v-tooltip>
                </div>
                <div class="input_area__field--wrapper tiny_editor">
                  <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
                  <editor
                      v-if="!isLoading"
                      :api-key="TINY_MCE_API_KEY"
                      :plugins="tinyPlugins"
                      :toolbar="tinyToolbars"
                      :init="tinyInit"
                      spell-check="false"
                      v-model="replyMessage"
                      :disabled="disabledEditor"
                      @input="inputEmail"
                  />
                </div>
                <v-btn
                    icon color="primary"
                    @click="onSendMessage"
                    :disabled="isDisabledSendEmailButton">
                  <v-icon size="16">send</v-icon>
                </v-btn>
                <div class="input_area__cancel">
                  <v-btn @click="hideEditor" color="error" icon text>
                    <v-icon size="16">close</v-icon>
                  </v-btn>
                </div>
              </div>
              <div v-else class="input_area__field input_area__default">
                <v-text-field
                    placeholder="Enter the message to reply"
                    class="mt-2"
                    @focus="showEditor"
                    v-if="!client.is_reply_disabled"
                >
                </v-text-field>
              </div>
            </div>
            <div>
              <v-flex v-if="isFilesInput && isEditor && !client.is_reply_disabled" class="d-flex align-baseline">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        small
                        color="red"
                        class="ml-0"
                        @click="removeAllAttachedFiles"
                        v-on="on"
                        :loading="linkInsertion"
                    >
                      <v-icon size="16">mdi-paperclip</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove all attached files</span>
                </v-tooltip>
                <v-file-input
                    class="pt-4"
                    v-model="files"
                    color="primary"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon=""
                    outlined
                    :loading="loadingFile"
                    :disabled="loadingFile"
                    :show-size="1000"
                    :rules="fileRules"
                    dense
                    @change="filesInputChanged"
                    @click:clear="removeAllAttachedFiles"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        color="primary"
                        dark
                        label
                        small
                        close
                        @click:close="removeAttachedFile(index)"
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-flex>
              <div v-if="maxFileSize">
                <v-alert
                    outlined
                    type="info"
                    text
                >
                  To reduce the size of the files, you can use
                  <a href="https://shrinkpdf.com/">shrinkpdf.com</a> or
                  <a href="https://imagecompressor.com/">imagecompressor.com</a>
                </v-alert>
              </div>
            </div>
          </div>
        </div>
        <div class="input_area" v-else>
          <div
              v-show="!selectedMember.bad_cell && selectedMember.cell && selectedMember.cell.length"
              class="input_area__field input_area__default tiny_editor"
          >
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      icon
                      small
                      color="primary"
                      class="mt-0 mb-5 ml-0"
                      @click="onAddVariableSms('{ez_login}')"
                      v-on="on"
                      :loading="linkInsertion"
                  >
                    <v-icon size="16">insert_link</v-icon>
                  </v-btn>
                </template>
                <span>Insert ezlogin link</span>
              </v-tooltip>
            </div>
            <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
            <v-textarea
                v-show="!client.is_reply_disabled"
                id="smsInput"
                ref="smsInput"
                class="mt-2"
                placeholder="Enter the message to reply"
                v-model="replyMessage"
                outlined
                hide-details
                :disabled="linkInsertion"
                @input="inputEmail"
            >
              <template v-slot:append-outer>
                <v-icon :disabled="replyMessage.length > 320" @click="onSendMessage">
                  send
                </v-icon>
              </template>
            </v-textarea>
          </div>
          <span v-if="!!isSms" class="text-counter" :class="replyMessage.length > 320 ? 'error--text' : ''">
                {{ replyMessage.length }}/320
              </span>
          <v-alert  v-show="!(!selectedMember.bad_cell && selectedMember.cell && selectedMember.cell.length)" class="members_message__wrapper" type="error" outlined>
            This Advocate does not have a valid cell phone number
          </v-alert>
        </div>
      </v-card-text>
    </v-card>
    <show-message-filter-list-modal
      ref="showMessageFilterListModal"
      :filterData ="filterData"
    />
  </div>
</template>

<script>

import UserViewComponent from '@/components/messages/user-view-component'
import ThreadViewComponent from '@/components/messages/thread-view-component'
import UserService from '@/services/UserService'
import ClientService from "@/services/ClientService";
import MemberService from '@/services/MemberService'

import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import ShorterUrl from "@/mixins/shorterUrl";

import swal from "sweetalert2";
import _ from 'lodash'
import {mapGetters} from "vuex";
import MessageService from "@/services/MessageService";
import ShowMessageFilterListModal from "@/components/messages/show-message-filter-list-modal";

export default {
  name: 'MessagesPage',
  mixins: [
    ClientService,
    UserService,
    TinyConfig,
    ShorterUrl,
    MessageService,
    MemberService,
  ],
  components: {
    'editor': Editor,
    'user-view-component': UserViewComponent,
    'thread-view-component': ThreadViewComponent,
    'show-message-filter-list-modal': ShowMessageFilterListModal,
  },
  data() {
    return {
      isSelectedMember: false,
      selectedMember: {},
      isMessageTopic: true,
      isSms: false,
      tab: 0,
      messages: {
        pagination: {
          page: 1,
          itemsPerPage: 15,
          last_page: 0,
        },
        items: []
      },
      isMessagesLoading: false,
      conversation: {},
      isEditor: false,
      isFilesInput: false,
      linkInsertion: false,
      replyMessage: '',
      files: [],
      currFiles: [],
      isLoading: false,
      loadingFile: false,
      markLoading: false,
      filterData: {}
    }
  },
  async mounted() {
    await this.refresh()
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    isDisabledSendEmailButton() {
      return this.checkEmptyMessage.length <= 0 || this.linkInsertion || this.maxFileSize || this.isSummaryUploadLimit
    },
    isApproval() {
      return this.client.uses_approval_coordinator_m && this.isCoordinator
    },
    maxImgsSize() {
      if (this.currentImgsSize > this.imgSizeLimit) {
        this.onCallToastr('error', 'The size of the image is more than 25MB!', 'Failed!')
        return true
      } else return false
    },
    checkEmptyMessage() {
      return this.replyMessage
          .replace(/[&]nbsp[;]/gi, '')
          .replace(/<p>[\s]*<\/p>/gi, '')
    },
    groupedMessageList() {
      let messageList = _.cloneDeep(this.messages.items.reverse())
      let groupedMessageMap = _.groupBy(messageList, (message) => {
        return this.getLocaleFromUTC(message.created_at, 'dddd MMMM DD, YYYY')
      })
      let groupedMessageList = []
      _.forEach(groupedMessageMap, (messageList, date) => {
        groupedMessageList.push({
          date,
          messageList
        })
      })
      return groupedMessageList
    },
    isUnseenMessages() {
      return this.$store.state.app.unseenMessageCount > 0
    },
    isSummaryUploadLimit() {
      return (this.currentImgsSize + this.currentFilesSize) > this.imgSizeLimit;
    }
  },
  methods: {
    async changeMember(selectData) {
      this.isSelectedMember = true
      if (selectData.isSms) {
        this.$nextTick().then(() => {
          let input = document.getElementById("smsInput")
          input.selectionStart = this.selectedMember.nickname.length + 5;
          input.selectionEnd = this.selectedMember.nickname.length + 5;
          input.focus();
        })
      }
      this.selectedMember = selectData.member
      this.isSms = !!selectData.isSms
      const hasUnseenEmailMessages = this.selectedMember.unseen_email_count > 0
      const hasUnseenTextMessages = this.selectedMember.unseen_text_count > 0

      let startText = `Hi ${this.selectedMember.nickname},\n\n- ${this.userData.nickname}`
      if (!this.isSms) {
        this.replyMessage = this.userData && this.userData.nickname ? `<br>  - ${this.userData.nickname}` : ''
        if (hasUnseenEmailMessages) {
          this.onCallToastr('success', `Please check ${this.selectedMember.unseen_email_count} new emails`, 'New Messages!')
        }
      } else {
        this.replyMessage = this.userData && this.userData.nickname ? startText : ''
        if (hasUnseenTextMessages) {
          this.onCallToastr('success', `Please check ${this.selectedMember.unseen_text_count} new texts`, 'New Texts!')
        }
      }
      this.messages.pagination.page = 1
      this.messages.items = []
      this.getMessages()

      this.getUnseenMessageCount()
    },
    onSelectConversation(conversation) {
      this.clearMessages()
      this.replyMessage = ''
      this.conversation = conversation
      this.selectedMember = conversation
      this.isSelectedMember = true
      this.getConversationMessages()
      this.readConversationMessage()
    },
    readConversationMessage() {
      this.isMessagesLoading = true
      let params = {
        group_id: this.conversation.group_id
      }

      this.toReadConversationMessage(this.client.id, params)
        .then(() => {
          if (this.$refs.ThreadViewComponent) {
            this.$refs.ThreadViewComponent.refresh()
          }
          this.isMessagesLoading = false
        })
        .catch((err) => {
          this.handleError(err)
        })
    },
    getConversationMessages() {
      this.isMessagesLoading = true
      let params = {
        page: this.messages.pagination.page,
        limit: this.messages.pagination.itemsPerPage,
        group_id: this.conversation.group_id
      }

      this.getConversation(this.client.id, params)
          .then(response => {
            this.messages.items = this.messages.items.concat(response.data.data ? response.data.data : response.data)
            this.messages.pagination.last_page = response.data.last_page
            if (this.messages.pagination.page === 1) {
              this.scrollToBottom()
            }
            this.isMessagesLoading = false
          })
          .catch((err) => {
            this.handleError(err)
          })
    },
    pathName(file) {
      let url = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'http://kp-dashboard.local';
      return url + '/' + file.path;
    },
    scrollToBottom() {
      this.$nextTick().then(() => {
        const element = this.$refs.messageListView
        if (element && element.scrollHeight) {
          element.scrollTop = element.scrollHeight
        }
      })
    },
    async inputEmail() {
      this.$nextTick(async () => {
        const newContent = await this.parsUrl(this.replyMessage)

        if (newContent !== this.replyMessage) {
          this.replyMessage = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    async refresh() {
      this.isSelectedMember = false
      this.clearMessages()
      this.getUnseenMessageCount()
      if (this.tab === 0) {
        this.$nextTick(() => {
          this.$refs && this.$refs.UserViewComponent && this.$refs.UserViewComponent.refresh()
        })
      } else if (this.tab === 1) {
        this.$nextTick(() => {
          if (this.$refs.ThreadViewComponent) {
            this.$refs && this.$refs.ThreadViewComponent && this.$refs.ThreadViewComponent.refresh()
          }
        })
      }
    },
    getMessageFromText() {
      if (this.userData.nickname && this.userData.lastname) {
        return `${this.userData.nickname} ${this.userData.lastname}`
      }
      return null
    },
    clearMessages() {
      this.messages = {
        pagination: {
          page: 1,
          itemsPerPage: 15,
          last_page: 0,
        },
        items: []
      }
      this.selectedMember = {}
      this.isSelectedMember = false
    },
    sendMessage() {
      if (this.tab === 1) this.isSms = 0;
      let message = {
        is_reply: 0,
        is_admin_send: false,
        members: [this.selectedMember.id],
        message: this.replyMessage,
      }
      let payload = {
        clientId: this.client.id,
        message: this.replyMessage,
        is_approval: this.isApproval,
        subject: '-',
        from: this.getMessageFromText(),
        is_kps_send: 0,
        is_admin_send: false,
        members: [this.selectedMember.id],
        reply_to_email: this.client.reply_to_email,
        files: []
      }

      if (this.isSms == 0) {
        if (this.tab === 1) {
          payload.group_id = this.conversation.group_id;
          payload.members = [this.selectedMember.member_id];
        }
        message.is_sms = 0
        payload.is_sms = 0
        this.messages.items.push(message)
        this.scrollToBottom()

        this.addFiles().then(() => {
          this.loadingFile = false
          payload.files = this.files
          this.saveClientScheduleMessage(payload)
              .then(() => {
                this.onCallToastr('success', 'Message has been sent.', 'Success!')
                if (this.tab === 0) this.changeMember({member: this.selectedMember, isSms: this.isSms});
                if (this.tab === 1) this.onSelectConversation(this.conversation);
                this.removeAllAttachedFiles()
              })
              .catch((err) => {
                this.handleError(err)
              })
        })
      } else {
        message.is_sms = 1
        payload.is_sms = 1
        this.scrollToBottom()
        this.saveClientScheduleMessage(payload)
            .then(() => {
              this.onCallToastr('success', 'Message has been sent.', 'Success!')
              this.changeMember({member: this.selectedMember, isSms: this.isSms})
            })
            .catch((err) => {
              this.handleError(err)
            })
      }
      this.replyMessage = ''
    },
    onSendMessage() {
      if (!this.replyMessage) return
      if (this.isApproval) {
        swal({
          text: 'Ready to place this message into the queue for approval? You will be notified once it has been approved and sent.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Queue for Approval',
          cancelButtonText: 'Go Back',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.sendMessage()
          }
        })
      } else {
        this.sendMessage()
      }
    },
    hideEditor() {
      this.isEditor = false
      this.replyMessage = ''
    },
    showEditor() {
      this.isEditor = true
    },
    onAddVariableEmail(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);

      setTimeout(() => {
        this.getFormattedText()
      }, 200)
    },
    onAddVariableSms(variable) {
      let field = this.$refs.smsInput.$refs.input
      if (field.selectionStart || field.selectionStart == '0') {
        let startPos = field.selectionStart;
        let endPos = field.selectionEnd;
        this.replyMessage = field.value.substring(0, startPos)
            + variable
            + field.value.substring(endPos, field.value.length);
      } else {
        this.replyMessage = field.value + variable;
      }

      this.getFormattedText()
    },
    getFormattedText() {
      this.linkInsertion = true
      let payload = {
        legislator_id: this.selectedMember.client_id,
        member_id: this.selectedMember.id,
        message: this.replyMessage,
      }

      this.getAppFormattedMessage(payload)
          .then(response => {
            this.replyMessage = response.data
            this.linkInsertion = false
          })
          .catch((err) => {
            this.handleError(err)
          })
    },
    downloadFile(file) {
      this.getFileById(this.client.id, file.id)
          .then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data], {type: "application/jpeg"}))
            let link = document.createElement('a')
            link.href = fileURL;
            link.setAttribute('download', file.name)
            link.click()
            link.remove();
          })
          .catch((err) => {
            this.handleError(err)
          })
    },
    getMessages() {
      this.isMessagesLoading = true
      let data = {
        page: this.messages.pagination.page,
        limit: this.messages.pagination.itemsPerPage,
        member_id: this.selectedMember.id,
        is_sms: +this.isSms
      }

      return this.getClientMessagesWithPagination(this.client.id, data).then(response => {
        this.messages.items = this.messages.items.concat(response.data.data ? response.data.data : response.data)
        this.messages.pagination.last_page = response.data.last_page
        if (this.messages.pagination.page === 1) {
          this.scrollToBottom()
        }
        this.isMessagesLoading = false
      })
    },
    loadMoreMessages() {
      this.messages.pagination.page++
      this.getMessages()
    },
    unSeenMessage(id) {
      let payload = {
        message_id: id
      }
      this.markMessageAsUnread(this.userId, payload).then(() => {
        this.getUnseenMessageCount()
        this.refresh()
      })
    },
    async getUnseenMessageCount() {
      if (this.client && this.client.id) {
        const messageCount = (await this.getClientUnseenMessageCount(this.client.id)).data
        this.$store.dispatch('app/setUnseenMessageCount', messageCount)
      }
    },
    markAllAsRead() {
      this.markLoading = true
      this.markAllMessagesAsRead(this.userId).then(() => {
        this.getUnseenMessageCount()
        this.refresh()
        this.markLoading = false
      }, () => {
        this.markLoading = false
      })
    },
    filesInputChanged() {
      this.currFiles = [
        ...this.currFiles,
        ...this.files
      ]
      this.currFiles = [...new Set(this.currFiles)]
      this.files = [...new Set(this.currFiles)]
    },
    removeAttachedFile(index) {
      this.files.splice(index, 1)
      this.currFiles.splice(index, 1)
    },
    removeAllAttachedFiles() {
      this.currFiles = []
      this.files = []
      this.isFilesInput = false
    },
    async addFiles() {
      if (this.files.length) {
        let promises = []
        this.loadingFile = true

        for (let file of this.files) {
          let data = new FormData()
          data.append('file', file)
          promises.push(this.saveScheduleMessageFile(data))
        }

        this.files = (await Promise.all(promises)).map(res => {
          return res.data
        })
      }
    },
    updateUserMember() {
      this.isLoading = true
      this.selectedMember.silence = 0
      this.updateMember(this.selectedMember)
          .then(response => {
            this.isLoading = false
          })
          .catch((err) => {
            this.handleError(err)
          })
    },
    getMessageTitle(message) {
      if (message.sender_name) {
        if (message.is_reply) {
          return `${this.client.assoc_abbrev}`
        } else {
          return `${this.client.assoc_abbrev} - ${message.sender_name}`
        }
      } else {
        return this.client.assoc_abbrev
      }
    },
    closeConversationContainer() {
      this.isSelectedMember = false
    },
    handleFilterClick(filter) {
      try {
        this.filterData = JSON.parse(filter);
        this.$refs.showMessageFilterListModal.open();
      } catch (error) {
        this.filterData = {};
      }
      
    }
  },
  watch: {
    'tab': function () {
      this.refresh()
    }
  }
}
</script>

<style lang="scss" scoped>
$mobile-screen-width: 768px;


#messagePage {
  .member-list-sidebar {
    .v-list__tile__action {
      min-width: 75px;
    }
  }

  .conversation-container {
    .v-avatar {
      min-width: 48px;
    }
  }

  .message {
    img {
      max-width: 100%;
    }
  }

  .tab-width {
    max-width: 380px;
  }
}

.card-container {
  display: flex;
  height: calc(100vh - 150px);

  .member-list-sidebar {
    max-width: 380px;
    width: 100%;
    @media(max-width: 767px) {
      max-width: 320px;
    }

    @media screen and (max-width: $mobile-screen-width) {
      max-width: inherit;
    }
  }
  .conversation-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .message-list-view {
      flex: 1;
      overflow-y: auto;

      .message {
        margin: 0 8px;
        max-width: 600px;
        min-width: 200px;
        border-radius: 8px;
        position: relative;

        &.opened {
          margin: 0 8px 20px;
        }

        &_read-at {
          position: absolute;
          right: 0;
          bottom: -20px;
          font-style: italic;
          font-size: 12px;
        }

        &_mark-unseen {
          position: absolute;
          right: 0;
          opacity: 0;
          z-index: -1;
          top: 0;
          transition: all 0.2s;
          @media (max-width: 767px) {
            position: absolute;
            left: -57px;
            opacity: 1;
            z-index: 1;
            top: 55px;
          }
        }

        &:hover {
          .message_mark-unseen {
            right: -30px;
            opacity: 1;
            z-index: 1;
          }
        }
      }
    }
  }
}

.input {
  &_area {
    &__field {
      display: flex;
      align-items: flex-end;
      position: relative;

      &--wrapper {
        width: 100%;
      }

      .tiny-editor {
        width: 100%;
      }

      &--counter {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #666;
        margin-top: 4px;

        &.error-color {
          color: red;
        }

        span {
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }

    &__cancel {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>
