<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog persistent max-width="800" :fullscreen="isMobile" v-model="isModalOpen">
      <v-card>
        <v-card-title>
          <span class="title">{{ title }}</span>
          <v-spacer></v-spacer>
          <message-send-as @changeMessageType="switchEmail" :disabled="isBusy" class="mr-2" v-if="messageType === 'blank'" :messageLength="finalMessageLength"/>
          <v-icon
              class="d-flex ml-auto "
              @click.native="closeModal()"
          >close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          <v-alert v-if="isEmail" :value="hasBadEmails" type="warning" outlined class="mb-3 mt-0">
            Members with a red icon have an undeliverable email address and will not receive this
            message.
          </v-alert>
          <v-alert v-if="!isEmail" :value="hasBadCells || hasBadEmails" type="warning" outlined class="mb-3 mt-0">
            Members with a red icon have an undeliverable phone/email and will not receive this
            message.
          </v-alert>
          <v-flex xs12 class="my-2" v-if="filter.customFields && filter.customFields.length && (isVIP || isSuperAdmin)">
            <v-expansion-panels v-model="isFilterOpen">
              <v-expansion-panel>
                <v-expansion-panel-header>Filters (Optional)</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout row wrap>
                    <v-flex
                        v-for="(item, index) in filter.customFields"
                        :key="index"
                        xs12
                        sm6
                        class="legislators_filter__additional--item_wrap px-3"
                    >
                      <div v-if="item.type == 'text'" class="mb-4">
                        <h4 class="my-2">{{ item.name }}</h4>
                        <v-text-field v-model="item.value" label="Search" outlined dense hide-details></v-text-field>
                      </div>
                      <div v-if="item.type == 'checkbox' && item.metadata && item.metadata.length">
                        <h4 class="my-2">{{ item.name }}</h4>
                        <v-radio-group
                            v-for="(data, j) in item.metadata"
                            :key="j"
                            v-model="data.type"
                            :label="data.name"
                            hide-details
                            row
                        >
                          <v-radio
                              color="primary"
                              v-for="(label, key) in checkboxLabels"
                              :key="key"
                              :label="label.name"
                              :value="label.value"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div v-if="item.type == 'toggle'">
                        <h4 class="my-2">{{ item.name }}</h4>
                        <v-checkbox
                            :label="item.name"
                            class="mt-0"
                            v-model="item.value"
                            hide-details
                        ></v-checkbox>
                      </div>
                      <div v-if="item.type == 'dropdown' && item.metadata && item.metadata.length">
                        <h4 class="my-2">{{ item.name }}</h4>
                        <v-checkbox
                            color="primary"
                            v-for="(data, j) in item.metadata"
                            :key="j"
                            hide-details
                            :label="data.name"
                            class="mt-0"
                            v-model="data.checked"
                        >
                        </v-checkbox>
                      </div>
                    </v-flex>
                  </v-layout>
                  <div class="d-flex mt-4">
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="clearFilter" :disabled="isBusy" small>Clear filter</v-btn>
                    <v-btn color="primary" text @click="refreshMemberList" :disabled="isBusy" small>Apply filter</v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
          <div class="district_lookup_filters_list my-2" v-if="checkSelectedFilters">
            <div
                v-for="(filter, index) in checkSelectedFilters"
                :key="index"
                class="district_lookup_filters_list_item"
            >
              <v-chip
                  v-if="filter.type == 'checkbox'"
                  v-for="(data, i) in filter.value"
                  :key="i"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter, data)"
              >
                {{ data }}
              </v-chip>
              <v-chip
                  v-if="filter.type =='text' || filter.type == 'toggle'"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter)"
              >
                <span v-if="filter.type == 'text'">{{ filter.value }}</span>
                <span v-else>{{ filter.name }}</span>
              </v-chip>
              <v-chip
                  v-if="filter.type == 'dropdown'"
                  v-for="(data, j) in filter.value"
                  :key="j"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter, data)"
              >
                {{ data }}
              </v-chip>
            </div>
          </div>
          <v-expansion-panels v-model="isRecipientsOpen">
            <v-expansion-panel>
              <v-expansion-panel-header v-slot="{ open }">
                <v-row no-gutters>
                  <v-col cols="4">
                    Recipients ({{ selectMembers.length }})
                  </v-col>
                  <v-col
                      cols="8"
                      class="text--secondary"
                  >
                    <v-fade-transition leave-absolute>
                      <span v-if="open">X out any members you'd like to remove</span>
                      <v-row
                          v-else
                          no-gutters
                          style="width: 100%"
                      >
                        <v-col cols="12">
                          View/Remove Recipients?
                        </v-col>
                      </v-row>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-select
                    multiple
                    chips
                    outlined
                    dense
                    deletable-chips
                    return-object
                    item-text="fullname"
                    label="District Members"
                    :items="districtMemberList"
                    v-model="selectMembers"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                        :style="kpPreferenceBorder(item)"
                        close
                        :key="item.id"
                        @click:close="removeMember(item)"
                        @click.stop="openMemberDetailModal(item)"
                    >
                      <v-icon left small :color="isBad(item.email) ? 'error' : 'primary'">email</v-icon>
                      <v-icon left small :color="item.bad_cell ? 'error' : 'primary'">
                        {{ item.bad_cell ? 'no_cell' : 'phone_iphone' }}
                      </v-icon>
                      {{ item.fullname }}
                    </v-chip>
                  </template>
                </v-select>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div v-show="isEmail">
            <send-email
                ref="sendEmail"
                :propMessage="message"
                :showPreview="showPreview"
                :email="email"
                :errorMessage="errorMessage"
                :isUploadingFiles="isUploadingFiles"
                :memberId="memberId"
                :is-loading="isBusy"
                :districtLegislator="districtLegislator"
                @badVariables="onBadVariables"
                @maxFileSize="onChangeMaxFileSize"
                @isLoading="value => this.isBusy = value"
                @setMessage="value => this.message = value"
                @finalMessageLength="value => this.setFinalMessageLength(value)"
            />
          </div>
          <div v-show="!isEmail" class="mt-3">
            <send-text
                :districtLegislator="districtLegislator"
                :propMessage="message"
                :selectedDistrict="selectedDistrict"
                :memberId="memberId"
                :errorMessage="errorMessage"
                :selectMembers="selectMembers"
                @badVariables="onBadVariables"
                @setMessage="value => this.message = value"
                @finalMessageLength="value => this.setFinalMessageLength(value)"
            />
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.xs ?
          'd-flex flex-column align-start' : 'd-flex justify-space-between '">
          <v-tooltip
              v-if="isEmail && selectedClient && selectedClient.send_mailgun_notifications && clientAdmins &&
              clientAdmins.length > 0 && !isKP" top>
            <template v-slot:activator="{ on }" class="mt-2">
              <v-checkbox
                  color="primary"
                  hide-details
                  class="mt-0 admins-copies_checkbox"
                  v-model="send_copies_to_admins"
                  v-on="on"
              >
                <template v-slot:label>
                  <p class="mb-0">Send copy to select Admins
                    <span v-if="clientAdmins.length" class="primary--text">({{ clientAdmins }})</span>
                  </p>
                </template>
              </v-checkbox>
            </template>
            <span>This will send a copy of this message to all Admins who have their<br> "Receive copy of outgoing messages" in their Profile within your Team page.</span>
          </v-tooltip>
          <v-spacer />
          <div class="mt-2">
            <v-btn outlined color="secondary" class="mr-2" @click.native="closeModal">Cancel</v-btn>
            <v-btn
                @click="sendRequestEmail"
                :loading="isBusy"
                color="primary"
                :disabled="(maxSizeFile || isSummaryUploadLimit) || (finalMessageLength > 320 && !isEmail) || isBadVariables"
            >Send
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import swal from 'sweetalert2'
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import SendEmail from "@/components/district-lookup/blank-email/send-email";
import SendText from "@/components/district-lookup/blank-email/send-text";
import MessageSendAs from "@/components/global/message-send-as";
import CustomFieldsFilter from '@/mixins/custom-fields-filter'

import ClientService from '@/services/ClientService'
import MemberService from '@/services/MemberService'
import he from "he";
import ShorterUrl from "@/mixins/shorterUrl";
import CustomMessageService from "@/services/CustomMessageService";

export default {
  name: "BlankRequestEmailModal",
  mixins: [
    ClientService,
    MemberService,
    TinyConfig,
    CustomFieldsFilter,
    ShorterUrl,
    CustomMessageService,
  ],
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE
    'send-email': SendEmail,
    'send-text': SendText,
    MessageSendAs,
  },
  props: {
    selectedClient: {
      type: Object,
      default: function () {
        return {}
      }
    },
    selectedDistrict: {
      type: String,
      default: ''
    },
    districtLegislator: {
      type: Object,
      default: function () {
        return {}
      }
    },
    memberList: {
      type: Array,
      default: function () {
        return []
      }
    },
    formalAddress: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      isModalOpen: false,
      districtMemberList: [],
      messageType: '',
      isUploadingFiles: false,
      isBusy: false,
      isEmail: true,
      title: '',
      errorMessage: '',
      message: "",
      finalMessageLength: 0,
      email: {
        finalMessageLength: 0,
      },
      isBadVariables: false,
      maxSizeFile: false,
      finalMessageLength: 0,
      selectMembers: [],
      showPreview: false,
      isRecipientsOpen: false,
      send_copies_to_admins: false,
      selection: 0,
      filter: {
        customFields: []
      },
      checkSelectedFilters: [],
      isFilterOpen: false,
      checkboxLabels: [
        {value: 'default', name: 'All'},
        {value: 1, name: 'Yes'},
        {value: 0, name: 'No'},
      ],
      variable: '',
    }
  },
  computed: {
    isApproval() {
      if (this.isKP) {
        return this.selectedClient.uses_approval_kp_m
      } else {
        return this.selectedClient.uses_approval_coordinator_m && this.isCoordinator
      }
    },
    hasBadEmails() {
      return this.selectMembers.some(member => this.isBad(member.email))
    },
    hasBadCells() {
      return this.selectMembers.some(member => member.bad_cell)
    },
    isSummaryUploadLimit() {
      return (this.currentImgsSize + this.currentFilesSize) > this.imgSizeLimit
    },
    memberId() {
      return this.selectMembers.length ? this.selectMembers[0].id : 0
    },
  },
  methods: {
    async toggle(messageType, message) {
      this.showPreview = false
      this.isBusy = true
      this.message = message ? message.body : ''
      this.email.subject = message ? message.subject : ''
      this.email.sendFrom = this.userName
      this.errorMessage = ''
      this.isBadVariables = false
      await this.getMessageTemplates()
      if (this.isKP) this.email.reply_to_email = this.userEmail;
      this.isUploadingFiles = false


      this.isModalOpen = true

      let membersList = this.memberList
          .map(item => {
            return {
              ...item,
              fullname: `${item.nickname} ${item.lastname}`
            }
          })
          .filter((item) => {
            if (!item.silence) {
              return item
            }
          })
      this.messageType = messageType;
      this.title = messageType !== 'blank' ? message.name : 'General Message Form'
      this.districtMemberList = membersList
      this.selectMembers = membersList
      this.isEmail = true
      await this.loadCustomFields(this.selectedClient.id)
      this.isBusy = false
      this.$nextTick(() => {
        this.$refs.sendEmail.init()
        this.showPreview = !!this.message
      })
    },
    onChangeMaxFileSize(value) {
      this.maxSizeFile = value
    },
    async refreshMemberList() {
      if (!this.selectedClient || !this.selectedDistrict) return
      this.isBusy = true
      const res = (await this.searchMembersByDistrict({
        client_id: this.selectedClient.id,
        district: this.selectedDistrict,
        custom_fields: this.collectCustomFieldsFilter(),
      })).data
      let data = res.member_list
          .map(item => {
            return {...item, fullname: `${item.nickname} ${item.lastname}`}
          })
      this.districtMemberList = data
      this.selectMembers = data.filter((item) => {
        if (!item.silence) {
          return item
        }
      })
      this.isBusy = false
      this.isFilterOpen = false
      this.checkSelectedFilters = this.getCheckSelectedFilters()
    },
    onBadVariables(value) {
      this.isBadVariables = value
    },
    async getMessageTemplates() {
      const params = {dlu: 'true'}
      this.getCustomMessages(this.selectedClient.id, params)
          .then((result) => {
            let messageTemplates = result.data
            if (this.isKP) {
              let kpMessage = messageTemplates.find(message => message.name === 'Sample Donation Request')
              this.message = kpMessage ? kpMessage.body : ''
            }
          })
          .catch((err) => {
            this.handleError(err)
          })
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
          re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    setFinalMessageLength(value) {
      this.finalMessageLength = value
    },
    clearFilter() {
      this.loadCustomFields(this.selectedClient.id).then(() => {
        this.refreshMemberList()
      })
    },
    async addFiles() {
      if (this.email.files && this.email.files.length) {
        this.isUploadingFiles = true
        let promises = []

        for (let file of this.email.files) {
          let data = new FormData()
          data.append('file', file)
          promises.push(this.saveScheduleMessageFile(data))
        }

        this.email.files = (await Promise.all(promises)).map(res => {
          return res.data
        })
      }
    },
    switchEmail(value) {
      this.isEmail = value
      this.$nextTick(() => {
        this.searchIncorrectVariables(this.message, this.variableList)
        this.errorMessage = ''
      })
    },
    removeMember(item) {
      this.selectMembers = this.selectMembers.filter(member => member.id != item.id)
    },
    openMemberDetailModal(item) {
      this.$emit('openDetails', item)
    },
    removeFilterItem(item, key = null) {
      this.filter.customFields.forEach(field => {
        if (field.id == item.id) {
          if (item.type == 'checkbox' || item.type == 'dropdown') {
            field.metadata.forEach(data => {
              if (data.name == key) data.checked = false
            })
          } else if (item.type == 'text') {
            field.value = ''
          } else {
            field.value = false
          }
        }
      })
      this.refreshMemberList()
    },
    getCheckSelectedFilters() {
      let selectedFilters = []

      this.filter.customFields.forEach(item => {
        if (item.type == 'text' || item.type == 'toggle')
          if (item.value)
            selectedFilters.push({id: item.id, name: item.name, type: item.type, value: item.value})
        if (item.type == 'checkbox' || item.type == 'dropdown') {
          let value = item.metadata.filter(meta => meta.checked).map(meta => meta.name)
          if (value && value.length)
            selectedFilters.push({id: item.id, type: item.type, name: item.name, value: value})
        }
      })

      return selectedFilters
    },
    async sendEmailMessage() {
      await this.addFiles()
      let email = {
        subject: this.email.subject,
        message: this.message,
        reply_to_email: this.email.reply_to_email,
        is_approval: this.isApproval,
        show_legislators: 0,
        is_sms: 0,
        legislator_id: this.districtLegislator.id,
        clientId: this.selectedClient.id,
        send_copies_to_admins: +this.send_copies_to_admins,
        members: this.selectMembers.filter(item => !this.isBad(item.email)).map(item => item.id),
        files: this.email.files,
        is_admin_send: +this.send_copies_to_admins
      }
      email.trackFilter = {
        from: 'District Lookup',
        title: this.title,
        filter: this.filter
      }

      if (this.isKP) {
        email.from = `${this.userData.nickname} ${this.userData.lastname}`
        email.is_kps_send = 1

        this.saveClientScheduleMessage(email)
            .then(() => {
              this.isBusy = false
              this.onCallToastr('success', 'Email has been sent.', 'Success!')
              this.isModalOpen = false
            })
            .catch(() => {
              this.isBusy = false
              this.isUploadingFiles = false
              this.onCallToastr('error', 'Something went wrong!', 'Error!')
            })
      } else {
        email.is_kps_send = 0
        this.saveClientScheduleMessage(email)
            .then(() => {
              this.isBusy = false
              this.onCallToastr('success', 'Email has been sent.', 'Success!')
              this.isModalOpen = false
            })
            .catch(() => {
              this.isBusy = false
              this.isUploadingFiles = false
              this.onCallToastr('error', 'Something went wrong!', 'Error!')
            })
      }
    },
    sendTextMessage() {
      let text = {
        message: this.html2Text(this.message),
        clientId: this.selectedClient.id,
        is_approval: this.isApproval,
        legislator_id: this.districtLegislator.id,
        show_legislators: 0,
        finalMessageLength: this.finalMessageLength,
        is_sms: 1,
        members: this.selectMembers.filter(item => !item.bad_cell).map(item => item.id)
      }
      text.trackFilter = {
        from: 'District Lookup',
        title: this.title,
        filter: this.filter
      }

      if (this.isKP) {
        text.is_kps_send = 1
        this.sendText(text)
            .then(() => {
              this.isBusy = false
              this.onCallToastr('success', 'Text has been sent.', 'Success!')
              this.isModalOpen = false
            })
            .catch(() => {
              this.isBusy = false
              this.onCallToastr('error', 'Something went wrong!', 'Error!')
            })
      } else {
        text.is_admin_send = 0
        text.is_kps_send = 0

        this.saveClientScheduleMessage(text)
            .then(() => {
              this.isBusy = false
              this.onCallToastr('success', 'Text has been sent.', 'Success!')
              this.isModalOpen = false
            })
            .catch(() => {
              this.isBusy = false
              this.onCallToastr('error', 'Something went wrong!', 'Error!')
            })
      }
    },
    sendRequestEmail() {
      if (this.isEmail && !this.$refs.sendEmail.validationForm) {
        return;
      }
      this.errorMessage = ''
      if (!this.message.length) {
        this.errorMessage = 'Message is required.';
        return;
      } else if (!this.isEmail && this.finalMessageLength > 320) {
        this.errorMessage = 'Message should be less than 320 characters.';
        return;
      }

      swal({
        title: this.isApproval ? 'Ready to place this message into the queue for approval? You will be notified once it has been approved and sent.' : 'Ready to send?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.isApproval ? 'Queue for Approval' : 'Yes, Send Now',
        cancelButtonText: this.isApproval ? 'Go Back' : 'Continue Editing',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          this.isBusy = true
          if (this.isEmail) {
            await this.sendEmailMessage()
          } else {
            this.sendTextMessage()
          }
        }
      })
    },
    closeModal() {
      this.email = {}
      this.showPreview = false
      this.message = ''
      this.errorMessage = ''
      this.isModalOpen = false
      this.isUploadingFiles = false
      this.isEmail = true
      this.removeAllAttachedFiles()
      this.currentImgsSize = 0
      this.currentFilesSize = 0
    },
  },
}
</script>
