import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,{staticClass:"pa-0"},[_vm._l((_vm.helpMenuItems()),function(item,key){return [_c(VListItem,{key:key,staticClass:"py-2",class:{ 'active': item.link === _vm.$route.name },attrs:{"ripple":false},on:{"click":function($event){return _vm.onClickMainMenuItem($event, item)}}},[_c(VListItemIcon,{staticClass:"sidebar-icon"},[_c(VIcon,{attrs:{"size":"24"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(item.title))])])],1)]})],2),_c('send-feedback-modal',{ref:"SendFeedbackModal"}),_c('support-modal',{ref:"SupportModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }