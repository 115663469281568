<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="map-container">
      <div class="map-el" :id="`${id}_${legislator.id}`" :ref="`${id}_${legislator.id}`" :style="`width: ${ options.width}px; height: ${ options.height}px;`"></div>
      <div v-if="isShowLargeMapLink" class="text-right">
        <small @click="onViewLargeMap" class="detail-link primary--text cursor-pointer" title="Show large view">Show larger map</small>
      </div>
    </div>
    <template v-if="isLoadLargeMap">
      <map-openlayer-modal
        ref="MapOpenlayerModal"
        :client="client"
        :loading="loading"
        :legislator="legislator"
      ></map-openlayer-modal>
    </template>
  </div>
</template>

<script>
  import helpers from '@/utils/helpers';
  import DistrictService from '@/services/DistrictService'
  import { partyKmlColorList } from '@/entities/party-color-list'
  import MapOpenlayerModal from '@/components/common/map-ol-large-modal'

export default {
  name: "MapOpenlayer",
  components: {
    'map-openlayer-modal': MapOpenlayerModal,
  },
  mixins: [
    DistrictService
  ],
  props: {
    id: {
      type: String,
      default: 'map-'+helpers.generateRandomString(10),
    },
    districtId: {
      type: Number,
      default: ''
    },
    options: {
      type: Object,
      default: () => {
        return {
          width: 265,
          height: 200
        }
      }
    },
    legislator: {
      type: Object,
      default: () => {
        return {}
      }
    },
    client: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      isLoadLargeMap: false,
      isShowLargeMapLink: false,
      partyKmlColorList: partyKmlColorList(),
    }
  },
  async mounted(){
    await this.initMap();
  },
  methods: {
    onViewLargeMap () {
      this.isLoadLargeMap = true;
      setTimeout(()=>{
        this.$refs.MapOpenlayerModal.toggle()
      }, 100)
    },
    async toggle () {
      this.isLoadLargeMap = false;
      setTimeout(()=>{
        this.isLoadLargeMap = true;
        this.isModalOpen = true;
      }, 100)
    },
    async initMap(){
      try {
        const lagislatorParty = this.legislator && this.legislator.party? this.legislator.party : '';
        const { data } = (await this.getKmlFileByDistrict(this.districtId)).data;
        //Replace outline color with purpile color hex code
        const searchOutlineString = "<LineStyle><color>ff0000ff</color>";
        const replacementOutlineString = "<LineStyle><color>#88801788</color>";
        let modifiedString = data.replace(searchOutlineString, replacementOutlineString);
        
        const partyColor = `${this.partyKmlColorList[lagislatorParty]}`;
        const searchPolyStyleString = "<PolyStyle><color>7fff8080</color></PolyStyle>";
        const replacementPolyStyleString = `<PolyStyle><color>${partyColor}</color></PolyStyle>`;
        modifiedString = modifiedString.replace(searchPolyStyleString, replacementPolyStyleString);
        
        // Set up the geographic projection
        //new ol.proj.useGeographic();

        const kmlFormat = new ol.format.KML({
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:4326', // Keep
        });

        const features = kmlFormat.readFeatures(modifiedString);
        
        const vectorSource = new ol.source.Vector({
          features: features,
        });

        const vectorLayer = new ol.layer.Vector({
          source: vectorSource,
        });

        const view = new ol.View({
          center: ol.extent.getCenter(vectorSource.getExtent()),
          zoom: 7, // Adjust the zoom level as needed
          projection: 'EPSG:4326',
        });

        const map = new ol.Map({
          layers: [
            new ol.layer.Tile({
              source: new ol.source.OSM(),
            }),
            vectorLayer,
          ],
          target: `${this.id}_${this.legislator.id}`,
          view: view,
        });

        // Listen for the rendercomplete event
        map.once('rendercomplete', (event) => {
         this.isShowLargeMapLink = true;
        });

        vectorSource.on('addfeature', () => {
          console.log('addfeature event triggered');
        });

        //Remove extra intialized map content
        this.removeExtraChild();
        
      } catch (error) {
        console.error('Error reading features from KML:', error);
      } 
    },
    removeExtraChild(){
      // Assuming 'parentElement' is the parent element you want to clear
      const parentElement = document.getElementById(`${this.id}_${this.legislator.id}`);

      // Keep the first child and remove the rest
      while (parentElement && parentElement.children && parentElement.children.length > 1) {
          parentElement.removeChild(parentElement.lastChild);
      }
    }
  }
}
</script>

<style>
  .ol-attribution {
    display: none !important;
  }
</style>
