<template>
	<div class="d-flex align-center flex-column mt-0">
          <!-- <div class="text-subtitle-2" small>Send As</div> -->
          <v-btn-toggle v-model="isEmail" mandatory rounded size="small" divided :disabled="disabled" hide-details>
		  <!-- adam removed mt-2 padding from text/email button -->
			<v-btn-toggle
                  class="full-width"
                  rounded
                >
				<v-tooltip bottom v-if="disabledText()">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						small
						value="left"
						class="disabled-text"
						v-bind="attrs"
						v-on="on"
						>
						<span small>Text</span>
						<v-icon end small :color="'error'"> mdi-cellphone </v-icon>
						</v-btn>
					</template>
					<span>Message has exceeded 320-character texting max</span>
				</v-tooltip>
				<v-btn
						v-else
						small
						value="left"
						:class="!isEmail ? 'primary' : ''"
						:style="!isEmail ? 'color: white' : ''"
						@click="isEmail = false"
						>
						<span small>Text</span>
						<v-icon end small :style="!isEmail && 'color: white'"> mdi-cellphone </v-icon>				
				</v-btn>
                <v-btn small value="center" :class="isEmail ? 'primary' : ''" :style="isEmail && 'color: white'" @click="isEmail = true">
					<span small>Email</span>
					<v-icon end small :style="isEmail && 'color: white'"> mdi-email-outline </v-icon>
				</v-btn>
            </v-btn-toggle>
        </v-btn-toggle>
    </div>
</template>

<script>
export default {
	name: "MessageSendAs",
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		messageLength: {
			type: Number,
			default: 0
		},
    value: {
      type: Boolean,
      default: true
    }
	},
	data() {
		return {
			isEmail: true,
      icon: false
		}
	},
	methods: {
		disabledText() {
			return this.messageLength > 320 && this.isEmail
		}
	},
	watch: {
		isEmail: function (val) {
			this.$emit('changeMessageType', val)
		},
    value: {
      handler() {
        this.isEmail = this.value
      },
      immediate: true
    }
	},
}
</script>
<style>
 .disabled-text {
	background-color: rgba(255, 255, 255, 0.12);
	color: #ff4c51 !important;
	cursor: not-allowed;
  }

</style>