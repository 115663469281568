<template>
  <v-flex xs12 sm8 lg4 xl3 class="my-auto">
    <v-form ref="forgotPasswordForm" @submit.prevent="onSendEmail">
      <v-card>
        <v-card-title>
          <p :style="{fontSize: isMobile ? '26px' : '36px'}" class="mb-4">FORGOT PASSWORD?</p>
        </v-card-title>
        <v-card-text>
          <v-alert type="error" color="error" :value="true" outlined class="mb-3" v-if="error">{{ error }}</v-alert>
          <v-alert type="success" color="primary" :value="true" outlined class="mb-3" v-if="message">{{ message
            }}</v-alert>
          <v-text-field
            type="email"
            prepend-inner-icon="person"
            label="E-mail Address*"
            v-model="user.email"
            :rules="emailRules"
            @input="error = ''"
          >
          </v-text-field>
          <div class="text-xs-right mb-3">
            <router-link :to="{name: 'login'}">Back to login</router-link>
          </div>
          <v-btn type="submit" class="mt-2 btn-primary" :loading="isBusy">Send Reset Password Link</v-btn>
        </v-card-text>
      </v-card>
    </v-form>
  </v-flex>
</template>

<script>
import AuthService from '@/services/AuthService'

export default {
  mixins: [AuthService],
  data () {
    return {
      user: {},
      error: '',
      message: '',
      isBusy: false,
    }
  },
  computed: {
    emailRules () {
      return [
        v => !!v || 'E-mail is required.',
        v => this.validateEmail(v) || 'E-mail must be valid.',
      ]
    }
  },
  methods: {
    onSendEmail () {
      if (!this.$refs.forgotPasswordForm.validate()) return
      this.isBusy = true
      this.remember(this.user)
        .then((res) => {
          this.error = ''
          this.message = res.data.message
          this.isBusy = false
        })
        .catch((err) => {
          this.error = err.response.data.message
          this.message = ''
          this.isBusy = false
        })
    }
  }
}
</script>
