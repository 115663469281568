import axios from 'axios'

export default {
  methods: {
    getResourcesList (clientId) {
      return axios.get(`/clients/${clientId}/resources`)
    },
    getResource (clientId, id) {
      return axios.get(`/clients/${clientId}/resources/${id}`)
    },
    saveResource (payload, clientId) {
      return axios.post(`/clients/${clientId}/resources/`, payload)
    },
    deleteResource (clientId, id) {
      return axios.post(`/clients/${clientId}/resources/${id}/delete`)
    },
    sortClientResources(clientId, data) {
      return axios.post(`/clients/${clientId}/resources/sort`, data)
    },
    uploadAttachment (clientId, data) {
      return axios.post(`/clients/${clientId}/resources/upload`, data)
    },
    getFile(clientId, resourcetId, fileId) {
      return axios.get(`/clients/${clientId}/resources/${resourcetId}/file/${fileId}`, {responseType: 'blob'})
    }
  }
}
