import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAutocomplete,{attrs:{"label":"Favorite Team(s)","color":"blue-grey lighten-2 mt-3","menu-props":{maxHeight: '40vh'},"value":_vm.memberFavoriteTeams,"search-input":_vm.search,"items":_vm.favoriteTeam,"item-text":"name","item-value":"id","loading":_vm.loading,"hide-details":"","return-object":"","outlined":"","multiple":"","filled":"","dense":""},on:{"change":_vm.setValue,"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function({item}){return undefined}},{key:"item",fn:function(data){return [[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]]}}])}),_c('div',{staticClass:"mb-2 pt-1"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.memberFavoriteTeams),function(item,index){return _c('div',{staticClass:"mr-2"},[_c(VMenu,{key:index,staticClass:"my-1",attrs:{"bottom":"","left":"","close-on-content-click":false,"origin":"top left","content-class":"role_card"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VChip,_vm._g({staticClass:"my-1",attrs:{"close":"","outlined":"","color":"primary","input-value":item.name},on:{"click:close":function($event){return _vm.$emit('remove', item)}}},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.displaySelected[_vm.getIndex(item.id, _vm.memberFavoriteTeams)]),callback:function ($$v) {_vm.$set(_vm.displaySelected, _vm.getIndex(item.id, _vm.memberFavoriteTeams), $$v)},expression:"displaySelected[getIndex(item.id, memberFavoriteTeams)]"}})],1)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }