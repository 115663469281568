<template>
  <v-flex xs12 sm11 md9 class="register-page">
    <div class="register-page_img">
      <img src="/images/register_img.jpg" alt="Register Your Account">
    </div>
    <div class="register-page_form">
      <div class="register-page_form__inner">
        <h1>Welcome to<br> KP Dashboard</h1>
        <p>Register Your Account</p>
        <v-alert type="error" :value="true" outlined class="mb-3" v-if="!user.email">Seems you entered wrong link.</v-alert>
        <v-form ref="registerForm" @submit.prevent="onRegister">
          <v-text-field
            type="email"
            outlined
            prepend-icon="email"
            label="E-mail ID*"
            :value="user.email"
            readonly
          ></v-text-field>
          <v-text-field
            type="password"
            outlined
            prepend-icon="lock"
            label="Password*"
            :rules="passwordRules"
            v-model="user.password"
            @input="error = ''"
          ></v-text-field>
          <v-text-field
            type="password"
            outlined
            prepend-icon="lock"
            label="Password Confirmation*"
            :rules="passwordConfirmationRules"
            v-model="user.password_confirmation"
            @input="error = ''"
          ></v-text-field>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn type="submit" color="primary" :loading="isBusy" :disabled="!user.email">Register and Log in</v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </v-flex>

</template>

<script>
import AuthService from '@/services/AuthService'

import defaultRoutes from '@/router/default-routes'

export default {
  mixins: [AuthService],
  data () {
    return {
      defaultRoutes: defaultRoutes,
      user: {
        email: '',
        password: '',
        password_confirmation: '',
      },
      isBusy: false,
      token: ''
    }
  },
  computed: {
    passwordRules () {
      return [
        v => !!v || 'Password is required.',
        v => (v && v.length >= 6) || 'Password should contain more than 6 characters.'
      ]
    },
    passwordConfirmationRules () {
      return [
        v => v === this.user.password || 'Password doesn\'t match.'
      ]
    },
  },
  mounted () {
    this.user.email = this.$route.query.email;
    this.token = this.$route.params.token;
    this.fetchUserByEmail();
  },
  methods: {
    onRegister () {
      if (!this.$refs.registerForm.validate()) return
      this.isBusy = true
      this.register({token: this.token, ...this.user})
        .then((res) => {
          this.$store.dispatch('auth/setUserData', res.data)
          this.$store.dispatch('auth/refreshSession')
          this.isBusy = false
          this.setDefaultRoute()
        })
        .catch((err) => {
          this.handleError(err)
        })
    },
    fetchUserByEmail () {
      if (this.user && this.user.email) {
        this.userCheck({token: this.token, ...this.user})
          .then( (res) => {
            const resData = res && res.data || null;
            if(resData && resData.status) {
              this.setDefaultRoute();
              this.onCallToastr('error', resData.message, 'Failed!');
            }
          })
          .catch((err) => {
            this.handleError(err)
          })
      }
    },
    setDefaultRoute() {
      let userRole = this.$store.state.auth.userData ? this.$store.state.auth.userData.role : false
      let serviceType = this.$store.state.auth.userData ? this.$store.state.auth.userData.serviceType : false
      let route = null;

      if (userRole == 'KP') {
        route = serviceType == 'adv' ? '/assigned-tasks' : '/my-tasks';
      } else if (this.defaultRoutes[userRole]) {
        route = this.defaultRoutes[userRole];
      } else {
        route = '/'; // Fallback route
      }

      if (route) {
        this.$router.push(route);
      } else {
        console.error('No valid route found for userRole:', userRole);
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.register-page {
  @media (max-width: 767px) {
    height: calc(100vh - 500px);
  }

  &_img {
    width: 50%;
  }

  &_form {
    width: 50%;
    background: white;
    @media screen and (min-width: 500px) {
      max-width: unset !important;
    }
    @media (max-width: 767px) {
      width: 100%;
      height: 480px;
    }
  }
}

</style>
