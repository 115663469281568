<template>
  <v-dialog max-width="700" v-model="isModalOpen">
    <v-form ref="textForm" @submit.prevent="onSend()">
      <v-card v-if="kp">
        <v-card-title>
          <span class="title">Send {{ isEmail ? 'an Email' : 'a Text' }} to {{ kp.nickname }} {{ kp.lastname }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout wrap>
            <v-flex xs12 id="editorContainer" class="px-2 tiny_editor">
              <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
              <v-textarea
                  ref="textarea"
                  class="mt-4"
                  label="Message*"
                  outlined
                  :counter="!isEmail ? 320 : false"
                  :rules="messageRules"
                  v-model="message"
                  @input="onChangeText"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Close</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy"
                 :disabled="isEmptyMessage < 1 || message.length > 320 && !isEmail">
            {{ isApproval ? 'Queue for Approval' : 'Send' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AppService from '@/services/AppService'
import ClientService from '@/services/ClientService'
import ShorterUrl from "@/mixins/shorterUrl";
import swal from "sweetalert2";

export default {
  mixins: [AppService, ClientService, ShorterUrl],
  data() {
    return {
      isModalOpen: false,
      kp: null,
      message: '',
      error: '',
      isBusy: false,
      isEmail: false,
    }
  },
  computed: {
    isApproval() {
      return this.userClient.uses_approval_coordinator_m && this.isCoordinator
    },
    isEmptyMessage() {
      if (!this.message) return 0
      return this.message.split(' ').join('').length
    },
    messageRules() {
      return [
        v => !!(v !== null ? v.split(' ').join('') : v) || 'Message is required.',
        v => (v && this.message && !this.isEmail <= 320) || 'Message should be less than 320 characters.',
      ]
    },
    replyToEmailList() {
      const emailList = []
      if (this.userClient && this.userClient.reply_to_email) {
        emailList.push(this.userClient.reply_to_email)
      }
      emailList.push(this.userEmail)
      return emailList
    },
  },
  methods: {
    toggle(kp, isEmail) {
      this.isModalOpen = true
      this.isEmail = isEmail
      this.message = ''
      this.error = ''
      if (this.$refs.textForm) this.$refs.textForm.reset()
      this.kp = kp

      this.$nextTick().then(() => {
        this.text = {
          clientId: this.userClient.id,
          is_approval: this.isApproval,
          is_admin_send: 0,
          is_sms: 1,
          is_kps_send: 1,
          member_legislator: kp.member_legislator.map(memberLegislator => {
            return {
              'member_id': memberLegislator.member_id,
              'legislator_id': memberLegislator.leg_id
            }
          })
        }
        this.email = {
          clientId: this.userClient.id,
          is_approval: this.isApproval,
          is_sms: 0,
          is_kps_send: 1,
          from: this.userName,
          is_admin_send: 0,
          subject: `KP Dashboard - ${this.userName}`,
          member_legislator: kp.member_legislator.map(memberLegislator => {
            return {
              'member_id': memberLegislator.member_id,
              'legislator_id': memberLegislator.leg_id
            }
          })
        }
        this.message = `Hi ${kp.nickname},  \nThanks! -${this.userData.nickname}`
        this.isModalOpen = true
      })
    },
    async onChangeText() {
      this.$nextTick(async () => {
        const newContent = await this.parsUrl(this.message)
        if (newContent !== this.message) {
          document.activeElement.blur()
          this.message = newContent
          this.disabledEditor = false
        }
      })
    },
    async send() {
      if (!this.$refs.textForm.validate()) return
      this.isBusy = true
      if (!this.isEmail) {
        try {
          this.text.message = this.message
          await this.saveClientScheduleMessage(this.text)
          this.onCallToastr('success', 'Text has been sent.', 'Success!')
        } catch (err) {
          this.isBusy = false
          this.error = 'Something went wrong!'
          return
        }
      } else {
        try {
          this.email.message = this.message
          await this.saveClientScheduleMessage(this.email)
          this.onCallToastr('success', 'Email has been sent.', 'Success!')
        } catch (err) {
          this.isBusy = false
          this.error = 'Something went wrong!'
          return
        }
      }
      this.isBusy = false
      this.isModalOpen = false
    },
    onSend() {
      if (!this.$refs.textForm.validate()) return

      if (this.isApproval) {
        swal({
          text: 'Ready to place this message into the queue for approval? You will be notified once it has been approved and sent.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Queue for Approval',
          cancelButtonText: 'Go Back',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.send()
          }
        })
      } else {
        this.send()
      }
    }
  },
}
</script>