<template>
  <v-dialog content-class="status_modal" v-model="isModalOpen">
    <v-card v-if="clientStatus" class="br_none">
      <v-card-title class="br_none">
        <span class="title">Setup</span>
        <v-progress-circular
          class="ml-4"
          :rotate="-90"
          :size="30"
          :width="5"
          :value="success * 100 / total"
          :color="getProgressColor(success / total)"
        >
          <div class="circular_text">{{ Math.ceil(success * 100 / total) }}%</div>
        </v-progress-circular>
        <v-spacer/>
        <v-icon class="ml-3" @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-4 mt-3">
        <div
          class="px-3"
          v-for="(instruction, index) in instructionList"
          :key="index"
        >
          <div class="d-flex flex-nowrap">
            <h4 class="mb-2">{{ instruction.title }}</h4>
            <v-spacer></v-spacer>
            <v-btn x-small color="primary" class="white--text" :to="{name: instruction.buttonLink}">
              {{ instruction.buttonTitle }}
            </v-btn>
          </div>

          <v-divider></v-divider>
          <v-list class="pt-0" dense>
            <v-list-item
              two-line
              v-for="(text, index) in instruction.list"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold" v-text="text.title"></v-list-item-title>
                <v-list-item-subtitle class="caption">{{ text.body }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon v-if="text.status" color="success">check_circle</v-icon>
                <v-icon v-else color="error">cancel</v-icon>
              </v-list-item-icon>
            </v-list-item>

          </v-list>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import ClientService from "@/services/ClientService";

export default {
  name: "SetupModal",
  mixins: [ClientService],
  data() {
    return {
      isModalOpen: false
    }
  },
  computed: {
    success () {
      let countErrors = 0
      for (let item in this.clientStatus) {
        if(item == 'settings' ) {
          for (let setting in this.clientStatus[item]) {
            if(this.clientStatus[item][setting]) countErrors++
          }
        } else {
          if(this.clientStatus[item]) countErrors++
        }
      }
      return countErrors
    },
    total () {
      let count = 0
      for (let item in this.clientStatus) {
        if(item == 'settings' ) {
          for (let setting in this.clientStatus[item]) {
            count++
          }
        } else {
          count++
        }
      }
      return count
    },
    clientStatus() {
      return this.$store.state.app.clientStatus
    },
    statusSettingRegion() {
      return this.userClient && this.userClient.uses_regions ? this.clientStatus.settings.regions : true;
    },
    statusSettings() {
      const settings = this.clientStatus.settings
      return settings.email && settings.texting && settings.committees && settings.relationships && this.statusSettingRegion
    },
    instructionList() {
      if(!(this.clientStatus && this.clientStatus.settings)) return []
      return [
        {
          title: 'Setting',
          buttonTitle: 'GO TO CLIENT OPTIONS',
          buttonLink: 'client-options-page',
          list: [
            {
              status: this.clientStatus.settings.email,
              title: 'Email',
              body: 'Make sure your client has a functional Mailgun email address'
            },
            {
              status: this.clientStatus.settings.texting,
              title: 'Cell number',
              body: 'Make sure your client has a functional Twilio number'
            },
            {
              status: this.clientStatus.settings.committees,
              title: 'Committee',
              body: 'Make sure your client has at least one committee is chosen in client settings'
            },
            {
              status: this.clientStatus.settings.relationships,
              title: 'Relationship',
              body: 'Make sure your client has at least one active relationship'
            },
            {
              status: this.statusSettingRegion,
              title: 'Region',
              body: 'Make sure your client has no orphaned regions'
            },
          ]
        },
        {
          title: 'Legislators',
          buttonTitle: 'GO TO LEGISLATORS PAGE',
          buttonLink: 'legislators-page',
          list: [
            {
              status: this.clientStatus.legislators,
              title: 'Review and edit your legislators as necessary',
              body: 'Your State’s Legislators will be provided for you; however, there are certain fields you can update'
            }
          ]
        },
        {
          title: 'Key Persons',
          buttonTitle: 'GO TO ASSIGNMENTS PAGE',
          buttonLink: 'assignments-page',
          list: [
            {
              status: this.clientStatus.key_persons,
              title: 'Assign your advocates to legislators and fill the kp positions',
              body: 'Once a Member is assigned into a KP position, they are considered “KPs”'
            }
          ]
        },
        {
          title: 'Advocates',
          buttonTitle: 'GO TO ADVOCATE PAGE',
          buttonLink: 'members-page',
          list: [
            {
              status: this.clientStatus.advocates,
              title: 'Import your advocates',
              body: 'Your Advocatea should have legislative districts set for Home, Work, and any other extra work addresses you’d like to monitor.'
            },
          ]
        },
        {
          title: 'Sessions/Tasks',
          buttonTitle: 'GO TO SESSIONS PAGE',
          buttonLink: 'sessions-page',
          list: [
            {
              status: this.clientStatus.active_session,
              title: 'Create a session',
              body: 'Sessions are time frames of when you’d like your Key People to accomplish certain tasks.'
            },
            {
              status: this.clientStatus.tasks_created,
              title: 'Create session tasks',
              body: 'Once you have an open Session, you will be able to create your tasks.'
            },
          ]
        },
        {
          title: 'Team Members',
          buttonTitle: 'GO TO ADVOCATE PAGE',
          buttonLink: 'members-page',
          list: [
            {
              status: this.clientStatus.team_members,
              title: 'Advocates logged in',
              body: `Make sure your members at least one active team member who has logged in`
            },

          ]
        },
      ]
    }
  },
  async mounted() {
    if (this.isSuperAdmin) return;
    if (!this.userClientId) {
      this.logout()
      return;
    }
    const result = (await this.getClientStatus(this.userClientId)).data
    await this.$store.dispatch('app/setClientStatus', result)
  },
  methods: {
    open() {
      this.isModalOpen = true
    }
  },
};
</script>

<style scoped lang="scss">
.v-dialog__content {
    justify-content: right !important;
}
.circular_text {
  font-size: 0.50rem !important;
}
</style>
