<template>

 <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          :min-width="isMobile ? '100%' : '110px'" 
          class="my-1"
          :disabled="!disabled" small 
          :loading="isBusy"
        >
        <v-icon dark class="mr-2">cloud_download</v-icon> Export KPs
        </v-btn>
      </template>
      <v-list>
        <v-list-item link small>
          <v-list-item-title class="text-center" @click="onDownloadTemplate('xlsx')">
            <v-icon class="mr-2"> mdi-file-excel </v-icon>Excel
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item link small>
          <v-list-item-title  class="text-center" @click="onDownloadTemplate('csv')">
            <v-icon class="mr-2"> mdi-file-download </v-icon>CSV
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import ClientService from '@/services/ClientService'
import {mapGetters} from "vuex";

export default {
    name: "ExportLegislatorsButton",
    props: {
      disabled: {
        type: Number,
        required: true
      },
      params: {
        type: Object,
        required: false,
        default: null
      }
    },
    mixins: [ClientService],
    data() {
      return {
        isBusy: false
      }
    },
    computed: {
      ...mapGetters('app', ['superAdminSelectedClient']),
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
    },
    methods: {
      onDownloadTemplate (type) {
        this.isBusy = true
        this.downloadLegislators(this.client.id, {...this.params, download_type: type}).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          let abbr = this.client ? this.client.assoc_abbrev : this.client.assoc_abbrev
          link.setAttribute('download', `KPD_${abbr}_legislators_${moment().format('MM-DD-YYYY')}.${type==='csv'?'csv':'xlsx'}`)
          document.body.appendChild(link)
          link.click()
          this.isBusy = false
        }, error => {
          this.isBusy = false
          console.error(error)
        })
      },
    }
  }
</script>

