<template>
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isLoading"
    ></v-progress-linear>
      <v-layout wrap>
        <v-flex class="d-flex align-center">
          <h1>Relationships</h1>
          <relationship-page-help-modal></relationship-page-help-modal>
          <v-spacer></v-spacer>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex class="d-flex align-center justify-end flex-wrap">
          <v-btn color="warning" small @click="openRelationshipModal(0)" :disabled="!(client && client.id)">
            New relationship
            <v-icon dark class="ml-2">playlist_add</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    <div v-if="client && client.id">
      <v-data-table
        class="elevation-1 relationships_table"
        :options.sync="pagination"
        hide-default-footer
        :headers="tableHeader"
        :items="readyList"
      >
        <template v-slot:item.handle="{ item }">
          <v-icon class="handle">drag_indicator</v-icon>
        </template>

        <template style="max-width: 70px" v-slot:item.action="{ item }">
          <div class="d-flex">
            <v-btn icon class="mx-0" @click="openRelationshipModal(item.id)">
              <v-icon size="18" color="primary">edit</v-icon>
            </v-btn>
            <v-btn icon class="mx-0" @click="onDeleteRelationship(item.id)">
              <v-icon size="18" color="error">delete_forever</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:item.name="{ item }">
          <div>
            {{ item.name }}
          </div>
        </template>

          <template v-slot:item.icon="{ item }">
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-avatar v-on="on" size="25" dark :color="item.color" class="ml-1">
                    <v-icon size="14" color="white" v-if="item.icon == 'phone'">{{ item.icon }}</v-icon>
                    <v-icon size="14" color="white" v-else-if="item.icon == 'fa-brands fa-twitter'">{{ item.icon }}</v-icon>
                    <small v-else class="white--text">{{ item.icon }}</small>
                  </v-avatar>
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
            </div>
          </template>

        <template v-slot:item.active="{ item }">
          <div class="d-flex justify-center">
            <v-switch
              color="primary"
              v-model="item.active"
              @change="changeStatus(item)"
              hide-details class="mt-0"
              :disabled="isLoading"
            ></v-switch>
          </div>
        </template>

        <template v-slot:no-data>
          <p class="text-xs-center mb-0">
            No relationships created yet.
          </p>
        </template>
      </v-data-table>

      <relationship-form-modal
          ref="form"
          @update="load"
          :list="list"
          :client="client"
      ></relationship-form-modal>
    </div>
  </v-container>
</template>

<script>
  import Sortable from 'sortablejs'
  import swal from 'sweetalert2'
  import ClientService from '@/services/ClientService'

  import modal from '@/components/relationships/relationship-form-modal'
  import relationshipPageHelpModal from '@/components/help-modals/relationship-page-help-modal'
  import {mapGetters} from "vuex";

  export default {
    name: "RelationshipsPage",
    mixins: [ClientService],

    components: {
      'relationship-page-help-modal': relationshipPageHelpModal,
      'relationship-form-modal': modal
    },
    data() {
      return {
        isLoading: false,
        list: [],
        pagination: {
          itemsPerPage: -1
        },
        tableHeader: [
          { text: '', value: 'handle', align: 'center', sortable: false },
          { text: '', value: 'action', align: 'center', sortable: false },
          { text: 'Label', value: 'name', align: 'left', sortable: false },
          { text: 'Icon', value: 'icon', align: 'center', sortable: false },
          { text: 'Status', value: 'active', align: 'center', sortable: false },
        ],
      }
    },
    computed: {
      ...mapGetters('app', ['superAdminSelectedClient']),
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      readyList() {
        return this.list.map(item => {return {...item, action: ''}})
      },
    },
    methods: {
      load() {
        this.isLoading = true
        this.getClientRelationshipsAll(this.client.id).then(response => {
          this.list = response.data
          this.isLoading = false
          this.dragInit()
        })
      },
      openRelationshipModal(id) {
        this.$refs.form.open(id)
      },
      deleteRelationship(id) {
        return this.deleteClientRelationships(this.client.id, id)
      },
      onDeleteRelationship (id) {
        swal({
          title: 'Are you sure to delete this relationship?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.deleteRelationship(id)
              .then(() => {
                this.onCallToastr('success', 'Relationship has been deleted.', 'Success!')
                this.load()
              })
              .catch(() => {
                this.onCallToastr('success', 'Something went wrong.', 'Error!')
              })
          }
        })
      },
      changeStatus(item) {
        this.isLoading = true
        let data = {
          ...item,
          active: !item.active ? 0 : 1
        }
        this.saveClientRelationships(this.client.id, data).then(()=> {
          this.onCallToastr('success', 'New relationship has been changed.', 'Success!')
          this.load()
          this.isLoading = false
        }, () => {
          this.isLoading = false
        })
      },
      dragInit() {
        let table = document.querySelector('table tbody')
        let self = this
        Sortable.create(table, {
          handle: ".handle",
          onEnd({ newIndex, oldIndex}) {
            const rowSelected = self.list.splice(oldIndex, 1)[0]
            self.list.splice(newIndex, 0, rowSelected)

            let startFromIndex = oldIndex < newIndex ? oldIndex : newIndex
            let result = []

            for (let i = 0; i < self.list.length; i++) {
              if(i >= startFromIndex) {
                if(i == newIndex) {
                  result.push({
                    id: self.list[i].id,
                    sort_order: newIndex
                  })
                } else {
                  result.push({
                    id: self.list[i].id,
                    sort_order: i
                  })
                }
              }
            }

            self.saveSort(result)
          }
        })
      },
      saveSort(result) {
        this.sortClientRelationships(this.client.id, result).then(()=> {
          this.load()
        })
      },
      init() {
        if (!(this.client && this.client.id)) return
        this.load()
      },
    },
    mounted() {
      this.init()
    },
    watch: {
      'client': function () {
        this.init()
      }
    }
  }
</script>
