import axios from 'axios'

export default {
  methods: {
    convertToShort(payload) {
      return axios.post('/schedule-messages/shorter-url', payload)
    },
    getShorterUrl(payload) {
      return axios.post('/schedule-messages/get-shorter-url', payload)
    },
  }
}
