import axios from 'axios'

export default {
  methods: {
    createAdvocacyMessage (payload) {
      return axios.post('/advocacy-messages', payload)
    },
    updateAdvocacyMessage (payload) {
      return axios.post(`/advocacy-messages/${payload.id}/update`, this.packageData(payload))
    },
    archiveAdvocacyMessage (payload) {
      return axios.post(`/advocacy-messages/${payload.id}/archived`, payload)
    },
    deleteAdvocacyMessage (advocacyMessageId) {
      return axios.delete(`/advocacy-messages/${advocacyMessageId}`)
    },
    sendAdvocacyMessage (advocacyMessageId) {
      return axios.post(`/advocacy-messages/${advocacyMessageId}/send`)
    },
    remindAdvocacyMessage (advocacyMessageId, payload) {
      return axios.post(`/advocacy-messages/${advocacyMessageId}/remind`, payload)
    },
    searchMembers (payload) {
      return axios.post('/advocacy-messages/search-members', payload)
    },
    getRemainingLegislators (advocacyMessageId, params) {
      return axios.get(`/advocacy-messages/${advocacyMessageId}/remaining-legislators`, { params })
    },
    getStats (advocacyMessageId, params) {
      return axios.get(`/advocacy-messages/${advocacyMessageId}/stats`, {params})
    },
    getScheduledStats (id) {
      return axios.post(`/schedule-messages/${id}/stats`, {id})
    },
    getReplyStats (advocacyMessageId, params) {
      return axios.get(`/advocacy-messages/${advocacyMessageId}/reply-stats`, { params })
    },
    getFormattedSubject (advocacyMessageId, params) {
      return axios.get(`/advocacy-messages/${advocacyMessageId}/formatted-subject`, { params })
    },
    getFormattedMessage (advocacyMessageId, params) {
      return axios.get(`/advocacy-messages/${advocacyMessageId}/formatted-message`, { params })
    },
    sendReplyAdvocacyMessage (advocacyMessageId, payload) {
      return axios.post(`/advocacy-messages/${advocacyMessageId}/send-reply`, payload)
    },
    completeAdvocacyMessage (advocacyMessageId, payload) {
      return axios.post(`/advocacy-messages/${advocacyMessageId}/completed`, payload)
    },
    getSendStat (advocacyMessageId, params) {
      return axios.get(`/advocacy-messages/${advocacyMessageId}/send-stat`, { params })
    }
  }
}
