import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary","size":"24"},on:{"click":function($event){$event.stopPropagation();_vm.isModalOpen = true}}},[_vm._v("help")]),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("KP Leaderboard Help")]),_c(VCardText,[_vm._v(" Motivation waxes and wanes for any volunteer. At times you may have to replace a volunteer due to any number of situations. Also, you may want to reward your most-active KPs at the end of a successful session. The KP Leaderboard will allow you to see who is doing a great job and maybe those who could use a break. "),_c('br'),_c('br'),_vm._v(" You may sort each column to determine who gave the most, accomplished the most tasks for the current Session, or over their KP lifetime. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }