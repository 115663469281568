import axios from 'axios'

export default {
  methods: {
    getOccupations () {
      return axios.get('/occupations')
    },
    createOccupation (payload) {
      return axios.post('/occupations', payload)
    },
    updateOccupation (payload) {
      return axios.put(`/occupations/${payload.id}`, payload)
    },
    deleteOccupation (occupationId) {
      return axios.delete(`/occupations/${occupationId}`)
    }
  }
}
