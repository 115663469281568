<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">Committees Page Help</v-card-title>
        <v-card-text>
        <p>
        This is where you will select all of the Committees that are relevent to your cause and/or industry. Afterwards, you will need to populate the Committee and/or the Subcommittee with appointed Legislators. 
        It is your responsibility to keep these Committee appointments accurate as they may change frequently. You may then also choose these Committees for you Sessions.
        </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
