<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog max-width="600" v-model="isModalOpen">
    <v-card class="legislator-timeline">
      <v-progress-linear
        color="warning"
        indeterminate
        rounded
        height="3"
        v-if="isLoading"
      ></v-progress-linear>
      <v-card-title class="d-flex flex-nowrap">
        <v-avatar left>
          <v-img
            class="avatar_image"
            :src="legislator.official_image ? getThumbImage(legislator.official_image) : '/images/member-avatar.png'"
          ></v-img>
        </v-avatar>
        <v-spacer></v-spacer>
        <h3>{{ legislator.fullname }} Timeline</h3>
        <v-spacer></v-spacer>
        <v-icon @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="d-flex flex-nowrap align-center mt-4">
          <v-checkbox
            class="ma-0"
            color="primary"
            hide-details
            v-model="hideIncompleteTask"
            @change="changeTaskList"
          >
            <template v-slot:label>
              Hide incomplete tasks
            </template>
          </v-checkbox>
          <v-spacer></v-spacer>
          <v-select
              class="legislator-timeline_filter"
              label="sessions"
              hide-details
              outlined
              dense
              :items="sessionFilter"
              v-model="selectedSession"
              @change="changeTaskList"
          >
          </v-select>
          <v-select
            class="legislator-timeline_filter"
            label="filter"
            hide-details
            outlined
            dense
            :items="filter"
            v-model="selectedFilter"
            @change="changeTaskList"
          >
          </v-select>
        </div>
        <v-timeline
          class="mt-5"
          dense
        >
          <v-timeline-item
            v-for="(task, index) in timelineItems"
            :key="index"
            color="white"
            class="d-flex"
          >
            <template v-slot:icon>
              <v-avatar
                v-if="(task.task_type.data_type === 'Boolean' || task.task_type.data_type === 'Select' || task.task_type.data_type === 'String') && task.task_results.length > 0"
                size="35"
                color="white"
                class="justify-center"
              >
                <v-icon size="30" color="green">far fa-check-circle</v-icon>
              </v-avatar>
              <v-avatar
                v-if="(task.task_type.data_type === 'Boolean' || task.task_type.data_type === 'Select' || task.task_type.data_type === 'String') && task.task_results.length === 0"
                size="35"
                color="white"
                class="justify-center"
              >
                <v-icon size="30" color="red">far fa-circle-xmark</v-icon>
              </v-avatar>
              <v-avatar
                v-if="task.task_type.data_type === 'Currency' && task.task_results.length > 0"
                size="35"
                color="white"
                class="justify-center"
              >
                <v-icon size="30" color="green">fa-solid fa-dollar-sign</v-icon>
              </v-avatar>
              <v-avatar
                v-if="task.task_type.data_type === 'Notes' && task.task_results.length > 0"
                size="35"
                color="white"
                class="justify-center"
              >
                <v-icon size="25" color="blue">far fa-comment</v-icon>
              </v-avatar>
            </template>
            <v-row class="pt-1 align-center">
              <v-col cols="4">
                <div>
                  <strong v-if="task.task_results && task.task_results.length > 0">
                    {{ formatDate(task.task_result_date) }}
                  </strong>
                  <strong v-else>
                    {{ formatDate(task.deadline) }}
                  </strong>
                </div>
                <div class="text-caption"><em>{{task.kp_nickname}} {{task.kp_lastname}}</em></div>
              </v-col>
              <v-col v-if="task.task_type.data_type === 'Boolean' || task.task_type.data_type === 'Select' || task.task_type.data_type === 'String'">
                <h3>{{ task.name }}</h3>
                <div class="text-caption">{{ task.tooltip }}</div>
              </v-col>
              <v-col v-if="task.task_type.data_type === 'Currency'">
                <h3>${{ task.task_result }}</h3>
                <div class="text-caption">{{ task.task_results[0].donation ? task.task_results[0].donation.comment : '' }}</div>
              </v-col>
              <v-col v-if="task.task_type.data_type === 'Notes'">
                <h3>Notes</h3>
                <div
                  v-if="task.readMore || task.task_result.length < 100"
                  class="text-caption cursor-pointer"
                  @click="task.readMore = false"
                >
                  {{ task.task_result }}
                </div>
                <div
                  v-else
                  class="text-caption cursor-pointer"
                  @click="task.readMore = true"
                >
                  {{ task.task_result.slice(0, 100) }}...
                </div>
              </v-col>
            </v-row>

          </v-timeline-item>
        </v-timeline>
        <v-btn
            color="primary"
            text
            block
            small
            @click="loadMoreTasks"
            v-if="lastPage < tasks.length"
            :loading="isLoading"
        >
          <span>Load more</span>
          <v-icon class="ml-2">keyboard_arrow_down</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LegislatorService from '@/services/LegislatorService'
import ClientService from "@/services/ClientService";

export default {
  mixins: [LegislatorService, ClientService],
  components: {},
  props: {
    clientId: {
      default: null,
      type: Number
    }
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      legislator: {},
      hideIncompleteTask: false,
      timelineItems: [],
      selectedFilter: 'Show All',
      selectedSession: 'All Sessions',
      sessions: [],
      sessionFilter: [],
      lastPage: 10,
      tasks: [],
    }
  },
  computed: {
    filter() {
      return [
        'Show All',
        'Only Tasks',
        'Only Donations',
        'Only Notes',
      ];
    }
  },
  methods: {
    async toggle(legislator) {
      this.isModalOpen = true
      this.isLoading = true
      this.lastPage = 10
      this.selectedFilter = 'Show All'
      this.selectedSession = 'All Sessions'
      this.hideIncompleteTask = false
      this.timelineItems = []
      this.legislator = {...legislator}
      await this.getSessionList()
      await this.getTaskResults()
      this.isLoading = false
    },
    getSessionList() {
      this.getClientSessions(this.clientId)
          .then((data) => {
            this.sessions = data.data
            this.sessionFilter = ['All Sessions'].concat(this.sessions.map(session => session.name))
          })
          .catch((err) => {
            this.isLoading = false
            this.handleError(err, true)
          })
    },
    getTaskResults() {
      this.getLegislatorTaskResults(this.legislator.id, {
        clientId : this.clientId
      })
        .then((data) => {
          let result = data.data
          const sortOrder = ["Boolean", "Select", "Currency", "Notes"];
          this.tasks = result
            .map(task => {
              if (task.task_results && task.task_results.length > 0) {
                task.task_results = task.task_results.filter(taskResult => taskResult.id === task.task_results_id)
              }
              if (task.task_type.data_type === 'Notes') task.readMore = false
              return task
            })
            .filter(task => !((task.task_type.data_type === 'Notes' || task.task_type.data_type === 'Currency') && task.task_results && task.task_results.length === 0))
            .filter(task => !(task.task_type.data_type === 'Currency' && typeof task.task_result !== 'string'))
            .sort((a, b) => sortOrder.indexOf(a.task_type.data_type) - sortOrder.indexOf(b.task_type.data_type))
          this.changeTaskList()
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.handleError(err, true)
        })
    },
    changeTaskList() {
      this.timelineItems = [...this.tasks]
      if (this.hideIncompleteTask) {
        this.timelineItems = this.timelineItems.filter(task => !task.hide_from_kp)
      }
      this.timelineItems = this.timelineItems
          .filter(task => {
            if (this.selectedFilter === 'Show All') return true
            if (this.selectedFilter === 'Only Tasks') return task.task_type.data_type === 'Boolean' || task.task_type.data_type === 'Select' || task.task_type.data_type === 'String'
            if (this.selectedFilter === 'Only Donations') return task.task_type.data_type === 'Currency'
            if (this.selectedFilter === 'Only Notes') return task.task_type.data_type === 'Notes'
          })
          .filter(task => {
            if (this.selectedSession === 'All Sessions') return true
            for (let session of this.sessions) {
              if (this.selectedSession === session.name) return task.session.id === session.id
            }
          }).slice(0,this.lastPage)
    },
    loadMoreTasks() {
      this.lastPage = this.lastPage + 10
      this.changeTaskList()
    }
  }
}
</script>
