import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary","size":"24"},on:{"click":function($event){$event.stopPropagation();_vm.isModalOpen = true}}},[_vm._v("help")]),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Team Page Help")]),_c(VCardText,[_c('p',[_vm._v(" Your team will consist of those trusted Admins and/or Regional Coordinators. All of your team members will have a traditional login/password unlike KPs who do not and instead log in using auto-login links. ")]),_c('p',[_c('strong',[_vm._v("Admins")]),_vm._v(" can be lobbyists, Executive Directors, Legislative Chairs, or membership specialists. Admin access should be limited to only those who require full access. ")]),_c('p',[_c('strong',[_vm._v("Coordinators")]),_vm._v(" can view only their respective Regions and district KPs. You may allow all Coordinators to view all rows in the Session Dashboard by checking \"Show all rows for Coordinators\" in Options. ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }