import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"message-modal-content"},[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v(" Edit Political Leanings "),(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary","size":"20","width":"2"}}):_vm._e()],1)]),_c(VDivider),_c(VCardText,{staticClass:"py-3"},[_c(VTextField,{staticClass:"ma-0",attrs:{"label":"Political Leanings name","value":_vm.form.name},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('div',{staticClass:"committee-colors_item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.color),expression:"form.color"}],attrs:{"type":"color","id":"committee_color"},domProps:{"value":(_vm.form.color)},on:{"change":function($event){return _vm.changeColor($event)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "color", $event.target.value)}}}),_c('label',{attrs:{"for":"committee_color"}},[_c(VChip,{attrs:{"label":"","dark":"","color":_vm.form.color}},[_vm._v("Color")])],1)])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{on:{"click":_vm.close}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }