<template>
  <gmap-autocomplete
    class="address-input mb-0"
    :class="dense ? 'dense': ''"
    :placeholder="placeholder"
    :value="value"
    @focus="deleteError"
    @place_changed="onPlaceChanged"
    @input="onChanging"
  >
  </gmap-autocomplete>
</template>

<script>
export default {
  props: ['placeholder', 'value', 'dense'],
  methods: {
    deleteError() {
      this.$emit('deleteError')
    },
    onPlaceChanged(address) {
      this.$emit('input', address.formatted_address)
      this.$emit('blur')
    },
    onChanging(event) {
      this.$emit('change', event.target.value)
    }
  },
}
</script>

<style lang="scss" scoped>
.address-input {
  margin-bottom: 20px;
  padding: 20px 12px 12px;
  width: 100%;
  line-height: 20px;
  font-size: 16px;
  border: thin solid rgba(94,86,105,.14);
  border-radius: 4px;
  &.dense {
    padding: 9px 5px;
  }
}
</style>
