<template>
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isLoading"
    ></v-progress-linear>
    <v-layout>
      <v-flex xs12 class="d-flex align-center flex-wrap">
        <h1>Resources</h1>
        <resources-page-help-modal></resources-page-help-modal>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="client && client.id" class="resources_page">
      <transition name="slide-list">
        <v-flex class="resources_table" xs12 md5 lg4 v-if="isDesktop || isDevice || (isMobile && !isShowOverview)">
          <v-card
              class="mx-auto resources_page_list"
              elevation="10"
          >
            <div v-if="isSuperAdmin || isVIP">
              <v-data-table
                class="elevation-1 relationships_table"
                :options.sync="pagination"
                hide-default-footer
                :headers="tableHeader"
                :items="resourcesList"
                :hide-default-header="isDesktop || isDevice"
              >
                <template v-slot:header="{ props: { headers } }" v-if="isDesktop || isDevice">
                  <thead>
                  <tr>
                    <th
                      v-for="(header, index) in headers"
                      :key="index"
                      :style="{'text-align': header.align, 'width': header.width ? header.width : 'unset'}"
                    >
                      <span v-if="header.text && !header.description">
                        {{ header.text }}
                      </span>
                      <v-tooltip bottom v-if="header.description">
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ header.text }}</span>
                        </template>
                        <span>{{ header.description }}</span>
                      </v-tooltip>
                    </th>
                  </tr>
                  </thead>
                </template>

                <template v-slot:[`item.handle`] v-if="isDesktop || isDevice">
                  <v-icon class="handle">drag_indicator</v-icon>
                </template>

                <template v-slot:[`item.title`]="{ item }">
                  <a
                    class="resources_page_list__table-link"
                    @click="showResource(item)"
                    :class="{ active: item.id == resource.id}"
                  >
                    {{ item.title }}
                  </a>
                </template>

                <template v-slot:[`item.allowed_to_admins`]>
                  <v-icon color="primary">visibility</v-icon>
                </template>

                <template v-slot:[`item.allowed_to_coordinators`]="{ item }">
                  <v-icon :color="item.allowed_to_coordinators ? 'primary' : ''">
                    {{ item.allowed_to_coordinators ? 'visibility' : 'visibility_off'}}
                  </v-icon>
                </template>

                <template v-slot:[`item.allowed_to_kps`]="{ item }">
                  <v-icon :color="item.allowed_to_kps ? 'primary' : ''">
                    {{ item.allowed_to_kps ? 'visibility' : 'visibility_off'}}
                  </v-icon>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-icon :color="item.status ? 'success' : 'error'">
                    {{ item.status ? 'done' : 'close'}}
                  </v-icon>
                </template>

                <template v-slot:no-data>
                  <p class="text-xs-center mb-0">
                    No resources created yet.
                  </p>
                </template>
              </v-data-table>
            </div>

            <v-card-actions v-if="client.id" class="my-2">
              <v-btn :loading="isLoading" block small color="primary" @click="addResource()">
                <v-icon>add</v-icon>
                Add resource
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </transition>
      <transition name="slide-resource">
        <v-flex class="resource_card" xs12 md7 lg8 v-if="isDesktop || isDevice || isShowOverview">
          <v-card
              class="resources_page_item"
              elevation="10"
          >
            <v-card-title class="d-flex justify-space-between">
              <div class="resources_page_title d-flex">
                <v-btn class="to_list_button" icon @click="isShowOverview = false" v-if="isMobile">
                  <v-icon size="24">chevron_left</v-icon>
                </v-btn>
                <div>{{ resource.title ? resource.title : 'Create new resource' }}</div>
              </div>
              <v-spacer></v-spacer>
              <div class="d-flex" v-if="client.id">
                <v-switch
                    color="primary"
                    class="mt-0 mr-2"
                    v-model="resource.status"
                    label="Active"
                    hide-details
                ></v-switch>
                <v-btn
                    v-if="((isVIP || isSuperAdmin)) && resourcesList.length"
                    small
                    color="error"
                    @click="onDeleteResource()"
                    class="ml-2"
                    :disabled="isLoadingSave"
                    :loading="isLoadingDelete"
                >
                  Delete
                </v-btn>
                <v-btn
                    small
                    color="success"
                    @click="saveUpdateResource()"
                    class="ml-2"
                    :loading="isLoadingSave"
                    :disabled="isLoadingDelete"
                >
                  Save
                </v-btn>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="client.id">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12 lg6>
                  <v-text-field v-model="resource.title" :error-messages="errorMessages('title')"
                                label="Title"></v-text-field>
                </v-flex>
                <v-flex class="d-flex resources_access" xs12 sm12 md12 lg6>
                  <v-spacer></v-spacer>
                  <v-flex>
                    <v-checkbox
                        color="primary"
                        hide-details
                        :label="!adminLabel ? 'Admins only' : 'Admins'"
                        disabled
                        v-model="fakeCheckbox"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex v-if="client.uses_regions">
                    <v-checkbox
                        color="primary"
                        hide-details
                        label="Coordinators"
                        v-model="resource.allowed_to_coordinators"
                        :error-messages="errorMessages('allowed_to_coordinators')"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex>
                    <v-checkbox
                        color="primary"
                        hide-details
                        label="KPs"
                        v-model="resource.allowed_to_kps"
                        :error-messages="errorMessages('allowed_to_kps')"
                    ></v-checkbox>
                  </v-flex>
                </v-flex>
              </v-layout>
              <div>
                <editor
                  v-model="resource.text"
                  :value="resource.text"
                  :api-key="TINY_MCE_API_KEY"
                  :plugins="tinyPlugins"
                  :toolbar="tinyToolbars"
                  :init="tinyInit"
              ></editor>
                <div v-if="errorMessages('text').length" class="red--text mt-1">
                  {{ errorMessages('text') }}
                </div>
                <div>
                  <v-card-title class="px-0">
                    Secure Attachments
                  </v-card-title>
                  <div class="mb-4 d-flex">
                    <div class="mr-2">
                      <v-btn
                          :loading="isLoading"
                          small
                          color="primary"
                          type="input"
                          class="mb-4"
                          @click="uploadAttachments()"
                      >
                        Upload
                      </v-btn>
                      <input
                          ref="uploader"
                          class="d-none"
                          type="file"
                          @change="onFileChanged"
                      >
                    </div>
                    <div class="d-flex flex-wrap">
                      <v-chip
                          v-for="(item, index) in resource.files"
                          :key="index"
                          class="mx-2 mb-2"
                          color="primary"
                          close
                          outlined
                          @click:close="deleteFile(index)"
                      >
                        <v-avatar left>
                          <v-icon>mdi-{{ fileType(item.type) }}</v-icon>
                        </v-avatar>
                        {{ item.name }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-card-text v-else>
              <v-alert type="info" :value="true">There are no resources yet.</v-alert>
            </v-card-text>
          </v-card>
        </v-flex>
      </transition>
    </v-layout>
  </v-container>
</template>

<script>
  import ResourcesService from '@/services/ResourcesService'
  import ClientService from '@/services/ClientService'
  import resourcesPageHelpModal from '@/components/help-modals/resources-page-help-modal'
  import TinyConfig from "@/mixins/tiny-mce-config"

  import Sortable from 'sortablejs'
  import swal from "sweetalert2";
  import {mapGetters} from "vuex";
  import Editor from '@tinymce/tinymce-vue';

  export default {
    name: "ResourcesPage",
    mixins: [ResourcesService, ClientService, TinyConfig],
    components: {
      'editor': Editor,
      'resources-page-help-modal': resourcesPageHelpModal,
    },
    data () {
      return {
        errors: false,
        resourcesList: [],
        fakeCheckbox: true,
        resource: {
          text:'',
          title: '',
          allowed_to_coordinators: true,
          allowed_to_kps: true,
          status: true,
          attachments: [],
          files: [],
        },
        text: '',
        isLoading: false,
        isLoadingSave: false,
        isLoadingDelete: false,
        isLoadingSort: false,
        pagination: {
          itemsPerPage: -1
        },
        selectedFiles: null,
        isSelecting: false,
        isShowOverview: false
      }
    },
    computed: {
      ...mapGetters('app', ['superAdminSelectedClient']),
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      tableHeader() {
        return [
          { text: '', value: 'handle', align: 'center', sortable: false },
          { text: 'Title', value: 'title', align: !this.isMobile ? 'left' : 'center', sortable: false, 'width': '50%' },
          { text: 'Admin', description: 'Admin can view', value: 'allowed_to_admins', align: 'center', sortable: false },
          { text: 'Coord.', description: 'Coordinators can view', value: 'allowed_to_coordinators', align: 'center', sortable: false },
          { text: 'KP', description: 'KP can view', value: 'allowed_to_kps', align: 'center', sortable: false },
          { text: 'Active', value: 'status', align: 'center', sortable: false }
        ]
      },
      adminLabel() {
        return (this.resource.allowed_to_coordinators || this.resource.allowed_to_kps)
      },
    },
    methods: {
      addResource() {
        this.resource = {
          text: '',
          title: '',
          allowed_to_coordinators: true,
          allowed_to_kps: true,
          status: true,
          attachments: [],
          files: []
        }
      },
      getList() {
        this.isLoading = true
        this.getResourcesList(this.client.id).then(response => {
          this.resourcesList = response.data
          if (this.resourcesList.length && !this.resource.id) {
            this.resource = this.resourcesList[0]
            this.resource.attachments = this.resource.files
          }
          this.text = this.resource.text
          this.dragInit()
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        })
      },
      showResource(item) {
        if(this.isMobile) this.isShowOverview = true
        this.resource = _.cloneDeep(item)
        this.resource.attachments = this.resource.files
        this.text = this.resource.text
      },
      init() {
        if(!(this.client && this.client.id)) return
          this.getList()
      },
      saveUpdateResource() {
        this.isLoading = this.isLoadingSave = true
        this.saveResource(this.resource, this.client.id)
          .then((res) => {
            if(!this.resource.id) {
              this.resource.id = res.data.id
            }
            this.onCallToastr('success', 'Resource has been save.', 'Success!')
            this.getList()
            this.isLoadingSave = false
          })
          .catch(err => {
            this.errors = err.response.data.errors
            this.onCallToastr('error', 'Something went wrong.', 'Error!')
            this.isLoading = this.isLoadingSave = false
          })
      },
      onDeleteResource() {
        let id = this.resource.id
        swal({
          title: 'Are you sure to delete this Resource?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: this.appTheme.accent,
          cancelButtonColor: this.appTheme.error,
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = this.isLoadingDelete = true
            this.deleteResource(this.client.id, id)
              .then(() => {
                this.onCallToastr('success', 'Resource has been deleted.', 'Success!')
                this.getList()
                this.resource = this.resourcesList[0]
                this.isLoadingDelete = false
              })
              .catch(() => {
                this.onCallToastr('error', 'Something went wrong.', 'Error!')
                this.isLoading = this.isLoadingDelete = false
              })
          }
        })
      },
      errorMessages(field) {
        return (this.errors && this.errors[field]) ? this.errors[field][0] : []
      },
      dragInit() {
        let table = document.querySelector('table tbody')
        if (!table) return
        let self = this
        Sortable.create(table, {
          handle: ".handle",
          onEnd({ newIndex, oldIndex}) {
            const rowSelected = self.resourcesList.splice(oldIndex, 1)[0]
            self.resourcesList.splice(newIndex, 0, rowSelected)

            let startFromIndex = oldIndex < newIndex ? oldIndex : newIndex
            let result = []

            for (let i = 0; i < self.resourcesList.length; i++) {
              if(i >= startFromIndex) {
                if(i == newIndex) {
                  result.push({
                    id: self.resourcesList[i].id,
                    sort_order: newIndex
                  })
                } else {
                  result.push({
                    id: self.resourcesList[i].id,
                    sort_order: i
                  })
                }
              }
            }

            self.saveSort(result)
          }
        })
      },
      saveSort(result) {
        this.sortClientResources(this.client.id, result).then(()=> {
          this.getList()
        })
      },
      fileType(type) {
        let fileType

        if (type.search('video') == 0) {
          fileType = 'video'
          return fileType
        }

        if (type.search('image') == 0) {
          fileType = 'image'
          return fileType
        }

        if (type.search('application') == 0) {
          fileType = 'application'
          return fileType
        }

        if (!type && type.length == 0) {
          fileType = 'file-document'
          return fileType
        }
      },
      uploadAttachments() {
        this.isSelecting = true
        window.addEventListener('focus', () => {
          this.isSelecting = false
        }, { once: true })

        this.$refs.uploader.click()
      },
      onFileChanged(e) {
        this.selectedFile = e.target.files[0]
        this.upload(this.selectedFile)
      },
      deleteFile(index) {
        this.resource.files.splice(index, 1)
        this.resource.attachments = this.resource.files
      },
      upload(file) {
        
        this.onCallToastr('success', "Uploading(Please wait)...")
        let data = new FormData()
        data.append('file', file)

        this.uploadAttachment(this.client.id, data).then((response) => {
          this.resource.files.push(response.data.entity)
          this.resource.attachments = this.resource.files
          this.saveUpdateResource()
        }).catch(err => {
          this.onCallToastr('error', 'Something went wrong', 'Error!')
        })
      },
    },
    mounted() {
      this.init()
    },
    watch: {
      'client': function () {
        this.init()
      }
    }
  }
</script>