import axios from 'axios'

export default {
  methods: {
    getCommittees () {
      return axios.get('/committees')
    },
    getMemberships() {
      return axios.get('/get-memberships')
    },
    getClientCommittees(clientId) {
      return axios.get(`clients/${clientId}/committees`)
    },
    createCommittee (payload) {
      return axios.post('/committees', payload)
    },
    updateCommittee (payload) {
      return axios.put(`/committees/${payload.id}`, payload)
    },
    deleteCommittee (committeeId) {
      return axios.delete(`/committees/${committeeId}`)
    },
    getCommitteeMemberships (stateId) {
      return axios.get(`/states/${stateId}/get-memberships`)
    },
    saveCommitteeMemberships (stateId, payload) {
      return axios.post(`/states/${stateId}/update-memberships-state`, payload)
    },
  }
}
