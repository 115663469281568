import AppService from '@/services/AppService'

export default {
  mixins: [AppService],
  data() {
    return {
      TINY_MCE_API_KEY: "u7s4mgabn0ny6bc2e52j96v1o2a6i6xz62i7yz79kmupw3l2",
      currentImgsSize: 0,
      isChild: false,
      updatedImgSize: 0,
      imgSizeLimit: 26214400,
      imgList: []
    }
  },
  computed: {
    tinyPlugins() {
      return 'advlist autolink lists link image charmap print preview anchor ' +
        'searchreplace visualblocks fullscreen ' +
        'insertdatetime media table paste help'
    },
    tinyToolbars() {
      return [
        `undo redo |
        link image media |
        bold italic backcolor |
        alignleft aligncenter alignright |
        bullist numlist outdent indent |
        removeformat`
      ]
    },
    tinyInit() {
      return {
        plugins: 'autoresize',
        autoresize_bottom_margin: 0,
        autoresize_top_margin: 0,
        content_style: "p { margin: 8px 0; }",
        menubar: false,
        statusbar: false,
        image_title: true,
        automatic_uploads: true, // Do not remove, it will cause an error while large images are uploading
        file_picker_types: 'image',
        image_dimensions: false,
        branding: false,
        link_context_toolbar: true,
        target_list: true,
        images_upload_handler: this.imageUploadHandler,
        file_picker_callback: this.filePicker,
        paste_data_images: true,
      }
    },
    maxImgsSize() {
      return this.currentImgsSize > this.imgSizeLimit
    },
  },
  methods: {
    filePicker(cb, value, meta) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.onchange = function () {
        const file = this.files[0];

        const reader = new FileReader();
        reader.onload = function () {

          const id = 'blobid' + (new Date()).getTime();
          const blobCache = tinymce.activeEditor.editorUpload.blobCache;
          const base64 = reader.result.split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          cb(blobInfo.blobUri(), {title: file.name});
        };
        reader.readAsDataURL(file);
      };

      input.click();
    },
    sendImage(file) {
      let data = new FormData()
      data.append('file', file)
      return this.uploadImage(data).then((response) => {
        return response.data.link
      })
    },
    async imageUploadHandler(blobInfo, success) {
      let file = blobInfo.blob()
      let filePath = await this.sendImage(file)
      success(filePath);
      // Upload handler should return a new location for the uploaded file in the exact following format
      return {location: filePath}
    },
  },
}
