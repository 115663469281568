<template>
  <div>
    <template v-if="isDarkTheme">
      <div v-if="!isMobile|| $route.name === 'profile-update-page'" class="app-logo-main mx-auto app-logo-full">
        <svg id="Layer_1" class="main-logo" style="enable-background:new 0 0 517.5 85.5;" version="1.1"
             viewBox="0 0 517.5 85.5" x="0px" xml:space="preserve"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             y="0px">
      <g id="Layer_2_00000151525336406604570280000009906871600128842894_">
      <g id="Layer_3">
        <path class="st0-white"
              d="M57,67.3l-15.4-21l-6,6.4l10.5,14.6H57z M91.3,40.5v-0.1c0-2-0.3-4-1.1-5.8c-0.7-1.7-1.8-3.2-3.1-4.5
          c-1.4-1.3-3.1-2.3-4.9-2.9c-2.1-0.7-4.4-1-6.6-1H58.7v41.1h9.1V55h6.9c2.2,0,4.4-0.3,6.5-0.9c1.9-0.6,3.7-1.5,5.3-2.7
          c1.5-1.2,2.7-2.8,3.6-4.5C90.9,44.9,91.3,42.7,91.3,40.5L91.3,40.5z M82.1,40.7c0,1.7-0.7,3.3-1.9,4.4c-1.5,1.3-3.4,1.9-5.3,1.8
          h-7.2V34.4h7c1.9-0.1,3.8,0.5,5.4,1.6C81.5,37.1,82.2,38.9,82.1,40.7L82.1,40.7z"/>
      </g>
        <g id="Layer_4">
        <polygon class="st0-white" points="18.8,67.3 73.5,7.8 80.7,0 27.8,44.2 27.8,26.2 18.8,26.2 		"/>
      </g>
        <g id="Layer_5">
        <path class="st0-white"
              d="M54.7,16.5l10.1-8.4H0v77.4h93.2c9.3,0,16.8-7.5,16.8-16.8V25.6c0-9.7-7.9-17.6-17.6-17.6H78.8L71,16.5
          l0.8-0.9h20.6c5.5,0,10,4.5,10,10v43.1c0,5.1-4.1,9.2-9.2,9.2H8.5c-0.5,0-0.9-0.4-0.9-0.9c0,0,0,0,0,0V16.5c0-0.5,0.4-0.9,0.9-0.9
          h47.3L54.7,16.5z"/>
      </g>
        <g id="Layer_6">
        <path class="st0-white"
              d="M511.1,79.3l-207.8,0.8l-185.9-0.3h-2.7c-1.2,2.9-3.2,3.5-5.4,5.6h408.3V8.1H108.8c2.2,2.1,3.8,2.9,5.1,5.7
          h20L511.2,14"/>
      </g>
        <g id="dashboard">
      <g class="st1-white">
        <path class="st2-white"
              d="M123.5,26.8h15.6c12.6,0,21.2,8.6,21.2,19.9v0.1c0,11.2-8.7,20-21.2,20h-15.6V26.8z M139.1,58.8
          c7.2,0,12-4.8,12-11.9v-0.1c0-7.1-4.8-12-12-12h-6.8v24.1H139.1z"/>
      </g>
          <g class="st1-white">
        <path class="st2-white"
              d="M179,26.5h8.1l17.1,40.2H195l-3.7-9h-16.9l-3.7,9h-9L179,26.5z M188.2,50l-5.3-13l-5.3,13H188.2z"/>
      </g>
          <g class="st1-white">
        <path class="st2-white"
              d="M207.1,60.9l5.2-6.2c3.6,3,7.4,4.8,11.9,4.8c3.6,0,5.8-1.4,5.8-3.8v-0.1c0-2.2-1.4-3.4-8-5.1
          c-8-2.1-13.2-4.3-13.2-12.2v-0.1c0-7.2,5.8-12,14-12c5.8,0,10.8,1.8,14.8,5.1l-4.6,6.6c-3.5-2.5-7-3.9-10.4-3.9s-5.1,1.5-5.1,3.5
          v0.1c0,2.6,1.7,3.5,8.6,5.2c8.1,2.1,12.7,5,12.7,12v0.1c0,7.9-6,12.4-14.7,12.4C218,67.3,211.8,65.2,207.1,60.9z"/>
            <path class="st2-white" d="M246.3,26.8h8.8v15.8h16.2V26.8h8.8v39.9h-8.8v-16h-16.2v16h-8.8V26.8z"/>
            <path class="st2-white"
                  d="M289.7,26.8h18.5c4.6,0,8.2,1.3,10.4,3.5c1.8,1.8,2.7,4.1,2.7,6.8v0.1c0,4.5-2.4,7-5.2,8.6
            c4.6,1.8,7.5,4.4,7.5,9.8v0.1c0,7.3-5.9,11-14.9,11h-19V26.8z M306.3,42.9c3.9,0,6.3-1.3,6.3-4.2v-0.1c0-2.6-2.1-4.1-5.8-4.1
            h-8.7v8.4H306.3z M308.7,59c3.9,0,6.2-1.4,6.2-4.3v-0.1c0-2.7-2-4.3-6.5-4.3h-10.2V59H308.7z"/>
      </g>
          <g class="st1-white">
        <path class="st2-white"
              d="M329.1,46.9v-0.1c0-11.4,9-20.7,21.3-20.7s21.2,9.2,21.2,20.5v0.1c0,11.4-9,20.7-21.3,20.7
          S329.1,58.2,329.1,46.9z M362.3,46.9v-0.1c0-6.8-5-12.6-12.1-12.6s-12,5.6-12,12.4v0.1c0,6.8,5,12.6,12.1,12.6
          S362.3,53.7,362.3,46.9z"/>
      </g>
          <g class="st1-white">
        <path class="st2-white"
              d="M390.5,26.5h8.1l17.1,40.2h-9.2l-3.7-9h-16.9l-3.7,9h-9L390.5,26.5z M399.7,50l-5.3-13l-5.3,13H399.7z"/>
            <path class="st2-white"
                  d="M421.8,26.8h18.3c5.1,0,9,1.4,11.6,4.1c2.2,2.2,3.4,5.4,3.4,9.1v0.1c0,6.4-3.5,10.5-8.6,12.4l9.8,14.3h-10.3
          L437.5,54h-6.9v12.8h-8.8V26.8z M439.5,46.2c4.3,0,6.7-2.3,6.7-5.6v-0.1c0-3.8-2.6-5.7-6.9-5.7h-8.7v11.5H439.5z"/>
            <path class="st2-white"
                  d="M463.1,26.8h15.6c12.6,0,21.2,8.6,21.2,19.9v0.1c0,11.2-8.7,20-21.2,20h-15.6V26.8z M478.7,58.8
          c7.2,0,12-4.8,12-11.9v-0.1c0-7.1-4.8-12-12-12h-6.8v24.1H478.7z"/>
      </g>
    </g>
  </g>
  </svg>
      </div>
      <div v-else class="app-logo-main mx-auto pl-1 app-logo-short">
        <svg class="main-logo"
             viewBox="0 0 209.88 163.18" xmlns="http://www.w3.org/2000/svg">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_3" data-name="Layer 3">
              <path class="white"
                    d="M108.71,128.54,79.31,88.37,67.94,100.68l20,27.86ZM174.2,77.38v-.23a28.58,28.58,0,0,0-2.07-11.05,24.29,24.29,0,0,0-5.95-8.58A26.71,26.71,0,0,0,156.76,52a37.76,37.76,0,0,0-12.63-2H112v78.54h17.28V105h13.13a42.82,42.82,0,0,0,12.4-1.74A29.46,29.46,0,0,0,165,98a24.43,24.43,0,0,0,6.78-8.64,27.45,27.45,0,0,0,2.47-12Zm-17.5.33a11.24,11.24,0,0,1-3.59,8.47q-3.59,3.43-10.1,3.43H129.32v-24h13.35q6.51,0,10.27,3t3.76,8.87Z"/>
            </g>
            <g id="Layer_4" data-name="Layer 4">
              <polygon class="white"
                       points="35.79 128.54 140.3 14.98 154.09 0 53.07 84.33 53.07 50 35.79 50 35.79 128.54"/>
            </g>
            <g id="Layer_5" data-name="Layer 5">
              <path class="white"
                    d="M104.34,31.5l19.33-16.14H0V163.18H177.81a32.12,32.12,0,0,0,32.07-32.06V48.87a33.57,33.57,0,0,0-33.51-33.51h-26L135.56,31.5l1.59-1.72h39.22a19.15,19.15,0,0,1,19.09,19.09v82.25a17.69,17.69,0,0,1-17.65,17.64H16.14A1.72,1.72,0,0,1,14.42,147V31.5a1.72,1.72,0,0,1,1.72-1.72H106.4Z"/>
            </g>
          </g>
        </svg>
      </div>
    </template>
    <template v-else>
      <div v-if="!isMobile || $route.name === 'profile-update-page'" class="app-logo-main mx-auto app-logo-full">
        <svg id="Layer_1" class="main-logo" style="enable-background:new 0 0 517.5 85.5;" version="1.1"
             viewBox="0 0 517.5 85.5"
             x="0px" xml:space="preserve"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             y="0px">
    <g id="Layer_2_00000151510439595823419280000015000258804277402301_">
	<g id="Layer_3">
		<path class="st0" d="M57,67.3l-15.4-21l-6,6.4l10.5,14.6H57z M91.3,40.5v-0.1c0-2-0.3-4-1.1-5.8c-0.7-1.7-1.8-3.2-3.1-4.5
			c-1.4-1.3-3.1-2.3-4.9-2.9c-2.1-0.7-4.4-1-6.6-1H58.7v41.1h9.1V55h6.9c2.2,0,4.4-0.3,6.5-0.9c1.9-0.6,3.7-1.5,5.3-2.7
			c1.5-1.2,2.7-2.8,3.6-4.5C90.9,44.9,91.3,42.7,91.3,40.5L91.3,40.5z M82.1,40.7c0,1.7-0.7,3.3-1.9,4.4c-1.5,1.3-3.4,1.9-5.3,1.8
			h-7.2V34.4h7c1.9-0.1,3.8,0.5,5.4,1.6C81.5,37.1,82.2,38.9,82.1,40.7L82.1,40.7z"/>
	</g>
      <g id="Layer_4">
		<polygon class="st1" points="18.8,67.3 73.5,7.8 80.7,0 27.8,44.2 27.8,26.2 18.8,26.2 		"/>
	</g>
      <g id="Layer_5">
		<path class="st2" d="M54.7,16.5l10.1-8.4H0v77.4h93.2c9.3,0,16.8-7.5,16.8-16.8V25.6c0-9.7-7.9-17.6-17.6-17.6H78.8L71,16.5
			l0.8-0.9h20.6c5.5,0,10,4.5,10,10v43.1c0,5.1-4.1,9.2-9.2,9.2H8.5c-0.5,0-0.9-0.4-0.9-0.9c0,0,0,0,0,0V16.5c0-0.5,0.4-0.9,0.9-0.9
			h47.3L54.7,16.5z"/>
	</g>
      <g id="Layer_6">
		<path class="st0"
          d="M511.1,79.3l-207.8,0.8l-185.9-0.3h-2.7c-1.2,2.9-3.2,3.5-5.4,5.6h408.3V8.1H108.8c2.2,2.1,3.8,2.9,5.1,5.7
			h20L511.2,14"/>
	</g>
      <g id="dashboard">
		<g class="st3">
			<path class="st4" d="M127.7,26.6h15.1c3.4,0,6,0.3,7.8,0.8c2.4,0.7,4.4,2,6.2,3.8c1.7,1.8,3,4,3.9,6.6c0.9,2.6,1.3,5.8,1.3,9.7
				c0,3.4-0.4,6.3-1.3,8.7c-1,3-2.5,5.4-4.4,7.2c-1.4,1.4-3.4,2.5-5.8,3.3c-1.8,0.6-4.3,0.9-7.3,0.9h-15.5V26.6z M135.9,33.5v27h6.2
				c2.3,0,4-0.1,5-0.4c1.3-0.3,2.4-0.9,3.3-1.7c0.9-0.8,1.6-2.1,2.2-3.9s0.8-4.3,0.8-7.5s-0.3-5.6-0.8-7.3c-0.6-1.7-1.3-3-2.3-4
				s-2.3-1.6-3.8-1.9c-1.2-0.3-3.4-0.4-6.8-0.4H135.9z"/>
		</g>
        <g class="st3">
			<path class="st4" d="M206.3,67.4h-9l-3.6-9.3h-16.3l-3.4,9.3h-8.7l15.9-40.8h8.7L206.3,67.4z M191.1,51.3l-5.6-15.2L180,51.3
				H191.1z"/>
		</g>
        <g class="st3">
			<path class="st4" d="M211.9,54.1l8-0.8c0.5,2.7,1.5,4.7,2.9,5.9s3.5,1.9,6,1.9c2.7,0,4.7-0.6,6-1.7c1.3-1.1,2-2.4,2-3.9
				c0-1-0.3-1.8-0.8-2.5s-1.6-1.3-3-1.8c-1-0.3-3.2-0.9-6.6-1.8c-4.4-1.1-7.5-2.4-9.3-4c-2.5-2.2-3.8-5-3.8-8.2c0-2.1,0.6-4,1.8-5.8
				c1.2-1.8,2.9-3.2,5.1-4.1s4.9-1.4,8-1.4c5.1,0,9,1.1,11.6,3.4c2.6,2.2,4,5.2,4.1,9l-8.2,0.4c-0.4-2.1-1.1-3.6-2.3-4.5
				c-1.2-0.9-2.9-1.4-5.2-1.4c-2.4,0-4.3,0.5-5.6,1.5c-0.9,0.6-1.3,1.5-1.3,2.5c0,1,0.4,1.8,1.2,2.5c1,0.9,3.6,1.8,7.6,2.7
				c4,0.9,7,1.9,8.9,2.9c1.9,1,3.4,2.4,4.5,4.2s1.6,3.9,1.6,6.5c0,2.3-0.6,4.5-1.9,6.6c-1.3,2-3.1,3.6-5.5,4.6
				c-2.4,1-5.3,1.5-8.9,1.5c-5.2,0-9.1-1.2-11.9-3.6C214.1,62.2,212.4,58.7,211.9,54.1z"/>
          <path class="st4" d="M252,67.4V26.6h8.2v16.1h16.2V26.6h8.2v40.8h-8.2V49.6h-16.2v17.9H252z"/>
          <path class="st4" d="M293.2,26.6h16.3c3.2,0,5.6,0.1,7.2,0.4c1.6,0.3,3,0.8,4.3,1.7c1.3,0.9,2.3,2,3.1,3.4s1.3,3,1.3,4.8
				c0,1.9-0.5,3.7-1.5,5.3c-1,1.6-2.4,2.8-4.2,3.6c2.5,0.7,4.4,2,5.7,3.7c1.3,1.7,2,3.8,2,6.2c0,1.9-0.4,3.7-1.3,5.4
				c-0.9,1.8-2,3.2-3.5,4.2c-1.5,1-3.3,1.7-5.5,1.9c-1.4,0.1-4.7,0.2-9.9,0.3h-13.9V26.6z M301.5,33.4v9.4h5.4c3.2,0,5.2,0,6-0.1
				c1.4-0.2,2.5-0.7,3.3-1.5s1.2-1.9,1.2-3.2c0-1.3-0.3-2.3-1-3.1c-0.7-0.8-1.7-1.3-3.1-1.4c-0.8-0.1-3.2-0.1-7-0.1H301.5z
				 M301.5,49.6v10.9h7.6c3,0,4.9-0.1,5.7-0.2c1.2-0.2,2.2-0.8,3-1.6c0.8-0.9,1.2-2,1.2-3.5c0-1.2-0.3-2.3-0.9-3.1
				c-0.6-0.9-1.5-1.5-2.6-1.9c-1.1-0.4-3.6-0.6-7.3-0.6H301.5z"/>
		</g>
        <g class="st3">
			<path class="st4" d="M333.3,47.2c0-4.2,0.6-7.7,1.9-10.5c0.9-2.1,2.2-3.9,3.8-5.6c1.6-1.7,3.4-2.9,5.3-3.7
				c2.5-1.1,5.5-1.6,8.8-1.6c6,0,10.8,1.9,14.4,5.6c3.6,3.7,5.4,8.9,5.4,15.6c0,6.6-1.8,11.8-5.4,15.5c-3.6,3.7-8.4,5.6-14.4,5.6
				c-6.1,0-10.9-1.9-14.5-5.6C335.1,58.9,333.3,53.7,333.3,47.2z M341.8,47c0,4.6,1.1,8.1,3.2,10.5s4.8,3.6,8.1,3.6s6-1.2,8.1-3.6
				c2.1-2.4,3.2-5.9,3.2-10.7c0-4.7-1-8.2-3.1-10.5c-2.1-2.3-4.8-3.5-8.2-3.5s-6.1,1.2-8.2,3.5C342.9,38.8,341.8,42.3,341.8,47z"/>
		</g>
        <g class="st3">
			<path class="st4" d="M417.8,67.4h-9l-3.6-9.3h-16.3l-3.4,9.3h-8.7l15.9-40.8h8.7L417.8,67.4z M402.6,51.3l-5.6-15.2l-5.5,15.2
				H402.6z"/>
          <path class="st4" d="M422.2,67.4V26.6h17.4c4.4,0,7.5,0.4,9.5,1.1c2,0.7,3.6,2,4.7,3.9s1.8,4,1.8,6.4c0,3.1-0.9,5.6-2.7,7.6
				c-1.8,2-4.5,3.3-8.1,3.8c1.8,1,3.3,2.2,4.4,3.4c1.2,1.2,2.7,3.5,4.7,6.6l5,8H449l-6-8.9c-2.1-3.2-3.6-5.2-4.3-6
				c-0.8-0.8-1.6-1.4-2.5-1.7c-0.9-0.3-2.3-0.5-4.2-0.5h-1.7v17H422.2z M430.4,43.8h6.1c4,0,6.4-0.2,7.4-0.5c1-0.3,1.8-0.9,2.3-1.7
				c0.6-0.8,0.8-1.8,0.8-3.1c0-1.4-0.4-2.5-1.1-3.3c-0.7-0.8-1.8-1.4-3.1-1.6c-0.7-0.1-2.7-0.1-6-0.1h-6.4V43.8z"/>
          <path class="st4" d="M463.3,26.6h15.1c3.4,0,6,0.3,7.8,0.8c2.4,0.7,4.4,2,6.2,3.8c1.7,1.8,3,4,3.9,6.6s1.3,5.8,1.3,9.7
				c0,3.4-0.4,6.3-1.3,8.7c-1,3-2.5,5.4-4.4,7.2c-1.4,1.4-3.4,2.5-5.8,3.3c-1.8,0.6-4.3,0.9-7.3,0.9h-15.5V26.6z M471.5,33.5v27h6.2
				c2.3,0,4-0.1,5-0.4c1.3-0.3,2.4-0.9,3.3-1.7c0.9-0.8,1.6-2.1,2.2-3.9c0.6-1.8,0.8-4.3,0.8-7.5s-0.3-5.6-0.8-7.3
				c-0.6-1.7-1.3-3-2.3-4s-2.3-1.6-3.8-1.9c-1.2-0.3-3.4-0.4-6.8-0.4H471.5z"/>
		</g>
	</g>
</g>
</svg>
      </div>
      <div v-else class="app-logo-main mx-auto pl-1 app-logo-short">
        <svg class="main-logo" viewBox="0 0 209.88 163.18"
             xmlns="http://www.w3.org/2000/svg">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_3" data-name="Layer 3">
              <path class="cls-1"
                    d="M108.71,128.54,79.31,88.37,67.94,100.68l20,27.86ZM174.2,77.38v-.23a28.58,28.58,0,0,0-2.07-11.05,24.29,24.29,0,0,0-5.95-8.58A26.71,26.71,0,0,0,156.76,52a37.76,37.76,0,0,0-12.63-2H112v78.54h17.28V105h13.13a42.82,42.82,0,0,0,12.4-1.74A29.46,29.46,0,0,0,165,98a24.43,24.43,0,0,0,6.78-8.64,27.45,27.45,0,0,0,2.47-12Zm-17.5.33a11.24,11.24,0,0,1-3.59,8.47q-3.59,3.43-10.1,3.43H129.32v-24h13.35q6.51,0,10.27,3t3.76,8.87Z"/>
            </g>
            <g id="Layer_4" data-name="Layer 4">
              <polygon class="cls-2"
                       points="35.79 128.54 140.3 14.98 154.09 0 53.07 84.33 53.07 50 35.79 50 35.79 128.54"/>
            </g>
            <g id="Layer_5" data-name="Layer 5">
              <path class="cls-3"
                    d="M104.34,31.5l19.33-16.14H0V163.18H177.81a32.12,32.12,0,0,0,32.07-32.06V48.87a33.57,33.57,0,0,0-33.51-33.51h-26L135.56,31.5l1.59-1.72h39.22a19.15,19.15,0,0,1,19.09,19.09v82.25a17.69,17.69,0,0,1-17.65,17.64H16.14A1.72,1.72,0,0,1,14.42,147V31.5a1.72,1.72,0,0,1,1.72-1.72H106.4Z"/>
            </g>
          </g>
        </svg>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    isSidebarExpanded: {
      type: Boolean,
      default: true
    },
    noHovered: {
      type: Boolean,
      default: true
    }
  },
}
</script>

<style lang="scss" scoped>
//short logo color
.cls-1 {
  fill: #444545;
}

.cls-1, .cls-2, .cls-3 {
  fill-rule: evenodd;
}

.cls-2 {
  fill: #6d5ca6;
}

.cls-3 {
  fill: #444645;
}

//short logo white
.white {
  fill: #fff;
  fill-rule: evenodd;
}

//full logo white
.st0-white {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
}

.st1-white {
  enable-background: new;
}

.st2-white {
  fill: #FFFFFF;
}

//full logo color
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #444545;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #6D5CA6;
}

.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #444645;
}

.st3 {
  enable-background: new;
}

.st4 {
  fill: #444545;
}

.app-logo-main {
  display: flex;
  justify-content: center;

  &.app-logo-full {
    svg {
      width: 90%;
      height: 35px;
    }
  }
  &.app-logo-short {
    svg {
      width: 50px;
      height: 35px;
    }

  }
}
</style>
