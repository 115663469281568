<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">Advocacy (Legislator) Messaging Help</v-card-title>
        <v-card-text>
          Advocacy Messages are personalized emails that you create on behalf of each Advocate which the Advocates themselves send directly to specific legislators of your choosing. These
          messages can be sent in only a few clicks from an advocate's phone. (we recommend sending by text if this is important). The Legislator will receive all
          Messages via email - even if you send the Request to the Advocate via text.
          <br /><br />
          The process is simple, 1) choose the legislator(s) who will recieve the message, 2) then filter the constituent Advocates of those legislators who will
          be sending (either all constituent Advocates or just KP(s) of the Legislator(s)). Both the Advoacy Message and the Request message to the Advocate can be
          customized before sending.
          <br /><br />
          After the message is created, you may send it right away or save for later. All sent messages are logged so you can know exactly who sent what message and
           and when. It is also very easy to send a reminder to either all Advocate or specific Advocate who have not yet sent their Advocacy Message.
          <br /><br />
          Important Note: Advocates can NOT send a message after the deadline has passed. This ensures a Legislator will not get a message after an important date has
          already passed. If a deadline has yet to pass, you may edit the deadline or inactivate a message. Inactivated messages cannot be sent at all either.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AdvocacyPageHelpModal',
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
