<template>
  <v-dialog persistent max-width="750" :fullscreen="$vuetify.breakpoint.xs" v-model="isModalOpen">
    <v-card>
      <v-icon class="d-flex ml-auto pt-2 pr-4" @click.native="closeModal()" @click="$refs.sendEmail.removeAllAttachedFiles()">close
      </v-icon>
      <v-card-title>
        <span class="title">Sending {{ isEmail ? 'Email' : 'Text' }} to {{ kpCount }} KP(s)</span>
        <v-spacer></v-spacer>
        <message-send-as @changeMessageType="(value) => isEmail = value" :disabled="disabledSwitchSend" class="mb-4" :messageLength="finalMessageLength"/>
        <v-card-subtitle v-if="!isEmail" class="pa-0">This will send a text to all KPs with valid cell phone and an
          email if
          they do not.
        </v-card-subtitle>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-alert v-if="error" dense type="error" outlined :value="true">{{ error }}</v-alert>
        <v-alert v-if="!isEmail && badCellOrEmails.length" dense type="error" outlined :value="true">
          Members with a red icon have an undeliverable phone/email and will not receive this message.
        </v-alert>
        <v-alert v-if="isEmail && badEmails.length" dense type="error" outlined :value="true">
          Members with a red icon have an undeliverable email and will not receive this message.
        </v-alert>
        <v-flex xs12 class="px-2 my-4">
          <v-expansion-panels v-model="isKpsOpen">
            <v-expansion-panel>
              <v-expansion-panel-header>Recipients ({{ members.length }})</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-select
                  v-model="members"
                  :items="members"
                  label="Selected KP(s)"
                  item-text="fullname"
                  return-object
                  multiple
                  :item-value="'members'"
                  dense
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      :style="kpPreferenceBorder(item)"
                      :key="item.id"
                      @click:close="() => members.splice(index, 1)"
                      close
                    >
                      <v-icon left size="16" :color="isBad(item.email) ? 'error' : 'primary'">
                        email
                      </v-icon>
                      <v-icon
                        v-if="item.bad_cell || item.cell === null"
                        left
                        size="16"
                        color="error"
                      >
                        no_cell
                      </v-icon>
                      <v-icon
                        v-else
                        left
                        size="16"
                        color="primary"
                      >
                        phone_iphone
                      </v-icon>
                      {{ item.fullname }}
                    </v-chip>
                  </template>
                </v-select>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
        <div v-show="!isEmail">
          <send-text
            ref="sendText"
            :propMessage="message"
            :text="text"
            :members="members"
            :isEmail="isEmail"
            @badVariables="onBadVariables"
            @error="childError"
            @setMessage="value => this.message = value"
            @finalMessageLength="value => setFinalMessageLength(value)"
          ></send-text>
        </div> 
        <div v-show="isEmail">
          <send-email
            ref="sendEmail"
            :propMessage="message"
            :email="email"
            :members="members"
            :client="client"
            :isEmail="isEmail"
            :attachedFiles="attachedFiles"
            :isUploadingFiles="isUploadingFiles"
            @setMessage="value => this.message = value"
            @error="childError"
            @badVariables="onBadVariables"
            @maxFileSize="onChangeMaxFileSize"
            @onAttachedFiles="onAttachedFiles"
            @updateImgSize="onFileSizeUpdate"
            @finalMessageLength="value => setFinalMessageLength(value)"
          ></send-email>
        </div>
        <div v-if="!isSendNow" class="mt-2 px-2 full-width">
          <div class="caption grey--text">Schedule</div>
          <v-row>
            <v-col
              class="py-0"
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Select date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :min="currentDate"
                  no-title
                  scrollable
                  @change="$refs.dateMenu.save(date)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                ref="timeMenu"
                v-model="timeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :disabled="!date"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="getFormattedTime"
                    label="Select time"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :clearable="text.id == null"
                    :disabled="!date"
                    hide-details
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeMenu"
                  v-model="time"
                  :min="currentDate == date ? currentTime : ''"
                  full-width
                  ampm-in-title
                  @click:minute="$refs.timeMenu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <v-flex class="px-2">
          <v-checkbox
            color="primary"
            v-model="isSendNow"
            label="Send Now"
            hide-details
            class="mt-3"
          >
          </v-checkbox>
        </v-flex>
        <v-alert
          v-if="isSummaryUploadLimit"
          class="mt-3"
          dense type="error"
          outlined
          :value="true"
        >
          The size of images and attached files is more than 25MB
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click.native="closeModal">Cancel</v-btn>
        <v-btn
          v-if="isSendNow"
          color="primary"
          :loading="isBusy"
          :disabled="disabledSendButton || isSummaryUploadLimit || isBadVariables"
          @click.native="onSend"
        >
          {{ isApproval ? 'Queue for Approval' : 'Send' }}
        </v-btn>
        <v-btn
          v-else
          color="primary"
          :loading="isBusy"
          :disabled="!time || !date || disabledSendButton || isSummaryUploadLimit || isBadVariables"
          @click.native="onSendSchedule"
        >
          {{ isApproval ? 'Queue for Approval' : 'Save' }} & Schedule
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import sendEmail from '@/components/my-kp/send-message-modal/send-email'
import sendText from '@/components/my-kp/send-message-modal/send-text'
import MessageSendAs from "@/components/global/message-send-as"
import TinyConfig from "@/mixins/tiny-mce-config"
import ClientService from "@/services/ClientService";
import swal from "sweetalert2";
import he from "he";
import ShorterUrl from "@/mixins/shorterUrl";

export default {
  name: 'SendMessageModal',
  mixins: [TinyConfig, ClientService, ShorterUrl],
  components: {
    sendEmail, sendText, MessageSendAs,
  },
  props: {
    Kps: {
      type: Array,
      default: function () {
        return []
      }
    },
    client: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      disabledSwitchSend: false,
      isUploadingFiles: false,
      isBadVariables: false,
      isModalOpen: false,
      maxSizeFile: false,
      isKpsOpen: false,
      isSendNow: true,
      dateMenu: false,
      timeMenu: false,
      isEmail: false,
      isBusy: false,
      date: null,
      time: null,
      attachedFiles: [],
      members: [],
      message: '',
      error: '',
      finalMessageLength: 0,
      email: {
        from: '',
        reply_to_email: '',
        subject: '',
        message: '',
        is_sms: false,
        formattedMessage: '',
        finalMessageLength: 0,

        files: [],
        members: [],
      },
      text: {
        message: '',
        is_sms: true,
        formattedMessage: '',
        finalMessageLength: 0,
        members: []
      }
    }
  },
  computed: {
    isApproval() {
      return this.client.uses_approval_coordinator_m && this.isCoordinator
    },
    kpCount() {
      return this.members ? this.members.length : 0
    },
    disabledSendButton() {
      const checkEmail = !this.message || this.maxSizeFile
      const checkText = !this.message || this.text.finalMessageLength > 320
      return (this.isEmail && checkEmail) || (!this.isEmail && checkText) || (this.members && !this.members.length)
    },
    badCellOrEmails() {
      return this.members.filter(member => (!member.cell || member.bad_cell) || this.isBad(member.email))
    },
    badEmails() {
      return this.members.filter(member => this.isBad(member.email))
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    getFormattedTime() {
      return this.time ? moment(this.time, "hh:mm").format('h:mm a') : ''
    },
    titleModalHasEzLink() {
      if (this.isApproval) {
        return `Ready to place this message into the queue for approval? You will be notified once it has been approved and sent.`
      } else if (this.hasEzLogin) {
        return `Ready to ${this.titleButtonSend}?`
      } else {
        return 'You did not include an ez_login link and KPs will not be able to easily log into the Dashboard. ' +
          `If this was on purpose, please click ${this.titleButtonSend}. If you'd like for KPs to easily self-report their progress, ` +
          'click Continue Editing and add the {ez_login} variable into the message body.'
      }
    },
    titleButtonSend() {
      return this.isSendNow ? this.isApproval ? 'Queue for Approval' : 'Send' : this.isApproval ? 'Queue for Approval & Schedule' : 'Save & Schedule'
    },
    hasEzLogin() {
      let reg = /{ez_login}/
      return reg.test(this.message)
    },
    isSummaryUploadLimit() {
      return (this.updatedImgSize + this.updatedFilesSize) > this.imgSizeLimit
    },
    currentTime() {
      const timezone = this.getTimezone(this.client);
      return moment().tz(timezone).add(1, 'hour').format('H:m');
    },
  },
  methods: {
    toggle() {
      this.isBadVariables = false
      this.isModalOpen = true
      this.isSendNow = true
      this.isPreview = false
      this.error = ''

      this.members = _.clone(this.Kps)

      this.$nextTick().then(() => {
        if (this.$refs.sendEmail.$refs.emailForm) this.$refs.sendEmail.$refs.emailForm.reset()
      }).then(() => {
        this.setText()
        this.setEmail()
      })
    },
    setText() {
      this.text = {
        clientId: this.client.id,
        is_approval: this.isApproval,
        is_sms: 1,
        is_kps_send: 0,
        members: this.members.map(member => member.id),
        finalMessageLength: 0,
        message: `Hi {kp_first},\n\nThanks! -${this.userData.nickname}`
      }
    },
    setEmail() {
      this.email = {
        clientId: this.client.id,
        is_approval: this.isApproval,
        is_kps_send: 0,
        from: this.userName,
        members: this.members.map(member => member.id),
        message: `Hi {kp_first},<p><br/></p>Thanks! -${this.userData.nickname}`
      }
    },
    onAttachedFiles(value) {
      this.attachedFiles = value
    },
    onBadVariables(value) {
      this.isBadVariables = value
    },
    onChangeMaxFileSize(value) {
      this.maxSizeFile = value
    },
    closeModal() {
      this.isModalOpen = false
      this.disabledSwitchSend = false
      this.isUploadingFiles = false
      this.isBusy = false
      this.email.files = []
      this.date = ''
      this.time = ''
      this.updatedImgSize = 0
      this.updatedFilesSize = 0
      this.$refs.sendEmail.removeAllAttachedFiles()
    },
    childError() {
      this.error = ''
    },
    async addFiles() {
      if (this.email.files && this.email.files.length) {
        this.isUploadingFiles = true
        let promises = []

        for (let file of this.email.files) {
          let data = new FormData()
          data.append('file', file)
          promises.push(this.saveScheduleMessageFile(data))
        }

        this.email.files = (await Promise.all(promises)).map(res => {
          return res.data
        })
      }
    },
    async onSendEmail() {
      await this.addFiles()
      await this.saveClientScheduleMessage(this.email)
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
        re = new RegExp(find, 'g');
      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    setFinalMessageLength(value) {
      this.finalMessageLength = value
      if(value > 360) {
        this.isEmail = true
      }
    },
    async onSendText() {
      const membersWithBadCell = this.members.filter(member => member.bad_cell).map(member => member.id)

      this.text.members = this.members.filter(item => !membersWithBadCell.includes(item)).map(member => member.id);

      this.email = {
        clientId: this.client.id,
        from: this.userName,
        is_sms: 0,
        is_approval: this.isApproval,
        is_kps_send: 0,
        subject: `KP Dashboard - ${this.userName}`,
        is_admin_send: this.text.is_admin_send,
        members: membersWithBadCell,
        message: this.message
      }
      this.text.message = this.html2Text(this.message)

      if (this.email.members && this.email.members.length > 0) {
        await this.saveClientScheduleMessage(this.email)
      }
      if (this.text.members && this.text.members.length > 0) {
        await this.saveClientScheduleMessage({...this.text})
      }
    },
    somethingWentWrong() {
      this.isBusy = false
      this.isUploadingFiles = false
      this.error = 'Something went wrong!'
    },
    onSend() {
      swal({
        text: this.titleModalHasEzLink,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.titleButtonSend,
        cancelButtonText: this.isApproval ? 'Go Back' : 'Continue Editing',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (!this.members || !this.members.length) {
            this.somethingWentWrong()
            return
          }

          if (this.isEmail) {
            if (!this.$refs.sendEmail.validationForm) return
            this.isBusy = true
            this.email.is_sms = 0
            this.email.is_admin_send = 0
            this.email.is_approval = this.isApproval
            this.email.message = this.removeHighlight(this.message)
            this.email.members = this.members.map(member => member.id)
            delete this.email.delivery_at

            this.onSendEmail().then(() => {
              this.onCallToastr('success', 'Email has been sent.', 'Success!')
              this.$emit('update')
              this.closeModal()
            }, (err) => {
              console.error(err)
              this.somethingWentWrong()
            })
          } else {
            this.isBusy = true
            this.text.is_sms = 1
            this.text.message = this.removeHighlight(this.message)
            this.text.is_admin_send = 0
            this.text.is_approval = this.isApproval
            delete this.text.delivery_at

            this.onSendText().then(() => {
              this.onCallToastr('success', 'Text has been sent.', 'Success!')
              this.closeModal()
            }, (err) => {
              console.error(err)
              this.somethingWentWrong()
            })
          }
        }
      })
    },
    onSendSchedule() {
      swal({
        text: this.titleModalHasEzLink,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.titleButtonSend,
        cancelButtonText: 'Continue Editing',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (!this.members || !this.members.length) {
            this.somethingWentWrong()
            return
          }

          if (this.isEmail) {
            if (!this.$refs.sendEmail.validationForm) return
            this.isBusy = true
            this.email.is_sms = 0
            this.email.is_admin_send = 0
            this.email.message = this.removeHighlight(this.message)
            this.email.is_approval = this.isApproval
            this.email.members = this.members.map(member => member.id)
            this.email.delivery_at = `${this.date} ${this.time}`

            this.onSendEmail().then(() => {
              this.onCallToastr('success', 'Email has been scheduled.', 'Success!')
              this.closeModal()
            }, (err) => {
              this.somethingWentWrong()
              console.error(err)
            })
          } else {
            this.isBusy = true
            this.text.is_sms = 1
            this.text.message = this.removeHighlight(this.message)
            this.text.is_approval = this.isApproval
            this.text.is_admin_send = 0
            this.text.delivery_at = `${this.date} ${this.time}`

            this.onSendText().then(() => {
              this.onCallToastr('success', 'Text has been scheduled.', 'Success!')
              this.closeModal()
            }, (err) => {
              this.somethingWentWrong()
              console.error(err)
            })
          }
        }
      })
    }
  },
  watch: {
    isEmail: function () {
      this.isBadVariables = false
    }
  }
}
</script>
