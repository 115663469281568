<template>
  <div class="fill-width">
    <v-progress-linear
        style="z-index: 6"
        color="warning"
        indeterminate
        rounded
        height="3"
        fixed
        top
        v-if="isLoading"
    ></v-progress-linear>
    <v-tabs
        v-model="tabs"
    >
      <v-tab>
        Messages
        <v-spacer></v-spacer>
        <v-tooltip bottom v-if="isUnseenMessages">
          <template v-slot:activator="{ on }">
            <v-btn
                icon
                v-on="on"
                @click="markAllAsRead"
                color="primary"
                :loading="markLoading"
            >
              <v-icon>mail_outline</v-icon>
            </v-btn>
          </template>
          <span>Mark all as read</span>
        </v-tooltip>
        <v-btn icon color="primary" class="ma-0" :loading="isLoading" @click="refresh">
          <v-icon dark>refresh</v-icon>
        </v-btn>
      </v-tab>
      <v-tab>
        History
      </v-tab>
      <v-icon v-if="dialog && isMobile" class="close-modal" @click.native="$emit('closeModal')">close</v-icon>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item class="card-container">
        <v-card id="messagePage" >
          <v-icon v-if="dialog && (isDesktop || isDevice)" class="close-modal" @click.native="$emit('closeModal')">close</v-icon>
          <v-card class="member-list-sidebar" v-show="!isMobile || (isMobile && !selectedMember)">

            <v-card-text class="pt-4">
              <v-text-field
                  class="pt-0"
                  hide-details
                  outlined
                  dense
                  placeholder="Name or keyword"
                  prepend-inner-icon="search"
                  v-model="searchText"
              ></v-text-field>

              <v-list class="member-list" :class="isMobile ? 'px-0' : 'mt-4'">
                <template v-for="(member, index) in members.items">
                  <v-divider v-if="index !== 0"></v-divider>
                  <v-list-item
                      :class="{
                  'activeDark': selectedMemberIndex === index && $vuetify.theme.dark,
                  'activeLight': selectedMemberIndex === index && !$vuetify.theme.dark,
                  'px-0 py-0': isMobile,
                  'vipLight': isVIP && member.is_my_kp && !$vuetify.theme.dark,
                  'vipDark': isVIP && member.is_my_kp && $vuetify.theme.dark
                }"
                      @click="onSelectMember(index, 0)"
                      :key="member.id"
                  >
                    <v-list-item-avatar
                        v-if="!member.avatar_url"
                        :style="kpPreferenceBorder(member)"
                        :color="getColor(member.nickname)"
                        class="justify-center"
                        :class="isMobile ? 'mr-2' : 'mr-4'"
                    >
                      <span class="white--text headline">{{ member.nickname[0] }}</span>
                    </v-list-item-avatar>
                    <v-list-item-avatar v-else :class="isMobile ? 'mr-2' : 'mr-4'">
                      <img
                          v-if="member.avatar_url"
                          :src="getThumbImageMedium(member.avatar_url)"
                          :alt="member.nickname + ' '+ member.lastname"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title>
                        <v-btn
                            small
                            text
                            class="mr-1 no-line-break elevation-0 px-0"
                            @click.stop="$refs.memberDetailModal.toggle(member)"
                        >
                          {{ member.nickname }} {{ member.lastname }}
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ member.last_message | dateDiffFormatter(true) }} | {{ getTextFromHTML(member.message) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="isVIP && member.legislator_regions && member.legislator_regions.length">
                        <div>
                          {{ member.legislator_regions[0].name }} - {{ member.legislator_regions[0].member.fullname }}
                        </div>
                        <v-tooltip bottom v-if="member.legislator_regions.length > 1">
                          <template v-slot:activator="{ on }">
                            <a v-on="on" class="d-flex align-center">
                              <span>{{ member.legislator_regions.length - 1 }} more region(s)</span>
                            </a>
                          </template>
                          <div>
                            <div
                                v-if="index != 0"
                                v-for="(data, index) in member.legislator_regions"
                                :key="index"
                                class="my-1"
                            >
                              {{ data.name }} - {{ data.member.fullname }}<br>
                            </div>
                          </div>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="ml-0 my-0">
                      <v-layout v-if="member.silence">
                        <v-badge right overlap :value="member.unseen_email_count > 0" color="error">
                          <v-btn size="18" icon ripple @click.stop="onSelectMember(index, 0)" :small="isMobile">
                            <v-icon color="secondary">mdi-comment-remove-outline</v-icon>
                          </v-btn>
                        </v-badge>
                      </v-layout>
                      <v-layout v-else >
                        <v-badge right overlap :value="member.unseen_email_count > 0" color="error">
                          <template v-slot:badge>
                            <span v-if="!!member.unseen_email_count">{{ member.unseen_email_count }}</span>
                          </template>
                          <v-btn icon @click.stop="onSelectMember(index, 0)" :small="isMobile">
                            <v-icon size="18" v-if="isBad(member.email)" color="error">
                              {{ selectedMemberIndex === index && messageType === 0 ? "mail" : "mail_outline" }}
                            </v-icon>
                            <v-icon
                                v-else
                                size="18"
                                :color="selectedMemberIndex === index && messageType === 0 ? 'primary' : getTextColor(selectedMemberIndex)"
                            >
                              mail_outline
                            </v-icon>
                          </v-btn>
                        </v-badge>
                        <v-badge right overlap :value="member.unseen_text_count > 0" color="error">
                          <template v-slot:badge>
                            <span v-if="!!member.unseen_text_count">{{ member.unseen_text_count }}</span>
                          </template>
                          <v-btn icon ripple @click.stop="onSelectMember(index, 1)" :small="isMobile">
                            <v-icon
                                size="18"
                                :color="selectedMemberIndex === index && messageType === 1 ? 'primary' : member.bad_cell ?
                       'error' : getTextColor()"
                            >
                              {{ member.bad_cell || member.bad_cell === null ? 'no_cell' : 'phone_iphone'}}
                            </v-icon>
                          </v-btn>
                        </v-badge>
                      </v-layout>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>

              <v-btn
                  color="primary"
                  text
                  block
                  small
                  @click="loadMoreMembers"
                  v-if="members.pagination.last_page > members.pagination.page"
                  :loading="isMembersLoading"
              >
                <span>Load more</span>
                <v-icon class="ml-2">keyboard_arrow_down</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
          <v-card-text class="conversation-container" v-if="selectedMember">
            <div class="py-3">
              <v-layout align-center>
                <v-flex xs2>
                  <v-btn icon @click="selectedMemberIndex = null" v-if="isMobile">
                    <v-icon size="24">chevron_left</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs10>
                  <v-layout justify-center align-center>
                    <div class="text-xs-center title">{{ selectedMember.nickname }} {{ selectedMember.lastname }}</div>
                    <v-icon size="24" class="ml-1" v-if="messageType === 0">mail_outline</v-icon>
                    <v-icon size="24" class="ml-1" v-else>phone_iphone</v-icon>
                  </v-layout>
                </v-flex>
                <v-flex xs2></v-flex>
              </v-layout>
            </div>
            <v-divider></v-divider>
            <div ref="messageListView" class="message-list-view">
              <v-btn
                  small
                  color="primary"
                  block
                  text
                  @click="loadMoreMessages"
                  v-if="messages.pagination.last_page > messages.pagination.page"
                  :loading="isMessagesLoading"
              >
                <span>Show more messages</span>
              </v-btn>
              <div v-for="(group, index) in groupedMessageList" :key="index">
                <div>
                  <v-layout align-center class="my-4">
                    <v-divider></v-divider>
                    <div class="px-4">{{ group.date }}</div>
                    <v-divider></v-divider>
                  </v-layout>
                </div>
                <div class="mb-2" v-for="message in group.messageList" :key="message.id">
                  <v-layout>
                    <v-spacer v-if="!message.is_reply"></v-spacer>
                    <v-avatar
                        v-if="message.is_reply && !selectedMember.avatar_url"
                        :color="getColor(selectedMember.nickname)" >
                      <span class="white--text headline">{{ selectedMember.nickname[0] }}</span>
                    </v-avatar>
                    <v-avatar v-if="message.is_reply && selectedMember.avatar_url">
                      <img
                          :src="getThumbImageMedium(selectedMember.avatar_url)"
                          :alt="selectedMember.nickname + ' '+ selectedMember.lastname"
                      />
                    </v-avatar>
                    <div class="message" :class="{'reply': message.is_reply, opened: message.opened_at}">

                      <v-layout align-center>
                        <div class="font-weight-bold">
                          {{ getMessageTitle(message) }}
                          {{ message.is_reply ? `- ${selectedMember.nickname} ${selectedMember.lastname}` : '' }}
                        </div>
                        <v-spacer/>
                        <div class="ml-3">{{ message.created_at | timeFormatter }}</div>
                      </v-layout>
                      <div v-if="message.is_sms && message.files && message.files.length > 0">
                        <div
                            v-for="file in message.files"
                            :key="file.id"
                        >
                          <v-img
                              v-if="file.type.includes('image')"
                              :alt="file.name"
                              :src="pathName(file)"></v-img>
                        </div>
                      </div>
                      <div class="mt-1 tinyEditor readonly" v-html="message.message"></div>
                      <div class="message_read-at"
                           v-if="message.opened_at"
                      >
                        read at - {{ message.opened_at | dateTimeFormatter }}
                      </div>
                      <div class="message_mark-unseen" v-if="message.is_reply">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                small
                                v-on="on"
                                @click="unSeenMessage(message.id)"
                                class="message_mark-unseen__btn"
                            >
                              <v-icon size="18">mail_outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Mark as unread</span>
                        </v-tooltip>
                      </div>

                      <div
                          v-if="!message.is_sms && message.files && message.files.length > 0"
                      >
                        Attached files:
                        <div
                            v-for="file in message.files"
                            :key="file.id"
                        >
                          <v-chip
                              class="mr-2 mt-2"
                          >
                            {{ file.name }}
                          </v-chip>
                          <v-tooltip bottom >
                            <template v-slot:activator="{ on }">
                              <v-icon color="primary" size="16" @click="downloadFile(file)">fa-solid fa-download</v-icon>
                            </template>
                            <span>Downland</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                    <v-avatar color="blue-grey" v-if="!message.is_reply && !userClient.avatar_url">
                      <span class="white--text headline">{{ userClient.assoc_abbrev[0] }}</span>
                    </v-avatar>
                    <v-avatar v-if="!message.is_reply && userClient.avatar_url" style="border: 1px solid #3d4977;">
                      <img :src="getThumbImageMedium(userClient.avatar_url)" :alt="userClient.assoc_abbrev"/>
                    </v-avatar>
                  </v-layout>
                </div>
              </div>
            </div>
            <div class="input_area" v-if="messageType == 0">
              <div v-if="isBad(selectedMember.email)">
                <v-alert :value="true" type="warning">
                  Emails to {{ selectedMember.nickname }} {{ selectedMember.lastname }} unavailable due to an undeliverable
                  email address. Please correct and try again.
                </v-alert>
              </div>
              <div v-else-if="selectedMember.silence">
                <v-alert :value="true" type="warning">
                  This user is currently silenced and cannot receive messages at this time.
                  <v-btn
                      v-if="isSuperAdmin || isVIP"
                      x-small
                      depressed
                      @click="updateUserMember()"
                      class="ml-4"
                  >
                    Unsilence
                  </v-btn>
                </v-alert>
              </div>
              <div v-else>
                <div>
                  <v-alert v-if="isSummaryUploadLimit" class="mt-3" dense type="error" outlined :value="true">
                    The size of images and attached files is more than 25MB
                  </v-alert>
                  <div
                      v-if="isEditor && !userClient.is_reply_disabled"
                      class="input_area__field input_area__editor"
                  >
                    <div class="input_area__field--ez-login-btn">
                      <v-flex v-if="!isFilesInput" v-model="isFilesInput" @click="isFilesInput = !isFilesInput"
                              class="d-flex align-baseline">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn icon small color="primary" class="ml-0" @click="removeAllAttachedFiles" v-on="on"
                                   :loading="linkInsertion">
                              <v-icon size="16">mdi-paperclip</v-icon>
                            </v-btn>
                          </template>
                          <span>Attach files</span>
                        </v-tooltip>
                      </v-flex>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon small color="primary" class="ml-0" @click="onAddVariableEmail('{ez_login}')" v-on="on"
                                 :loading="linkInsertion">
                            <v-icon size="16">insert_link</v-icon>
                          </v-btn>
                        </template>
                        <span>Insert ezlogin link</span>
                      </v-tooltip>
                    </div>
                    <div class="input_area__field--wrapper tiny_editor" style="width: calc(100% - 64px);">
                      <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
                      <editor
                          v-if="!isLoading"
                          :api-key="TINY_MCE_API_KEY"
                          :plugins="tinyPlugins"
                          :toolbar="tinyToolbars"
                          :init="tinyInit"
                          spell-check="false"
                          v-model="replyMessage"
                          :disabled="disabledEditor"
                          @input="inputEmail"
                      />
                    </div>
                    <v-btn
                        icon color="primary"
                        @click="onSendMessage"
                        :disabled="checkEmptyMessage.length <= 0 || linkInsertion || maxFileSize || isSummaryUploadLimit">
                      <v-icon size="16">send</v-icon>
                    </v-btn>
                    <div class="input_area__cancel">
                      <v-btn @click="hideEditor" color="error" icon text>
                        <v-icon size="16">close</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div v-else class="input_area__field input_area__default">
                    <v-text-field
                        placeholder="Enter the message to reply"
                        class="mt-2"
                        @focus="showEditor"
                        v-if="!userClient.is_reply_disabled"
                    >
                    </v-text-field>
                  </div>
                </div>
                <div>
                  <v-flex v-if="isFilesInput && isEditor && !userClient.is_reply_disabled" class="d-flex align-baseline">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            small
                            color="red"
                            class="ml-0"
                            @click="removeAllAttachedFiles"
                            v-on="on"
                            :loading="linkInsertion"
                        >
                          <v-icon size="16">mdi-paperclip</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove all attached files</span>
                    </v-tooltip>
                    <v-file-input
                        class="pt-4"
                        v-model="files"
                        color="primary"
                        counter
                        label="File input"
                        multiple
                        placeholder="Select your files"
                        prepend-icon=""
                        outlined
                        :loading="loadingFile"
                        :disabled="loadingFile"
                        :show-size="1000"
                        :rules="fileRules"
                        dense
                        @change="filesInputChanged"
                        @click:clear="removeAllAttachedFiles"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                            color="primary"
                            dark
                            label
                            small
                            close
                            @click:close="removeAttachedFile(index)"
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-flex>
                  <div v-if="maxFileSize">
                    <v-alert
                        outlined
                        type="info"
                        text
                    >
                      To reduce the size of the files, you can use
                      <a href="https://shrinkpdf.com/">shrinkpdf.com</a> or
                      <a href="https://imagecompressor.com/">imagecompressor.com</a>
                    </v-alert>
                  </div>
                </div>
              </div>
            </div>
            <div class="input_area" v-else>
              <div
                  v-show="!selectedMember.bad_cell && selectedMember.cell && selectedMember.cell.length"
                  class="input_area__field input_area__default tiny_editor"
              >
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          icon
                          small
                          color="primary"
                          class="mt-0 mb-5 ml-0"
                          @click="onAddVariableSms('{ez_login}')"
                          v-on="on"
                          :loading="linkInsertion"
                      >
                        <v-icon size="16">insert_link</v-icon>
                      </v-btn>
                    </template>
                    <span>Insert ezlogin link</span>
                  </v-tooltip>
                </div>
                <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
                <v-textarea
                    v-show="!userClient.is_reply_disabled"
                    id="smsInput"
                    ref="smsInput"
                    class="mt-2"
                    placeholder="Enter the message to reply"
                    v-model="replyMessage"
                    hide-details
                    outlined
                    :disabled="linkInsertion"
                    @input="inputEmail"
                >
                  <template v-slot:append-outer>
                    <v-icon :disabled="replyMessage.length > 320" @click="onSendMessage">
                      send
                    </v-icon>
                  </template>
                </v-textarea>
              </div>
              <span v-if="!!messageType" class="text-counter" :class="replyMessage.length > 320 ? 'error--text' : ''">
                {{ replyMessage.length }}/320
              </span>
              <v-alert  v-show="!(!selectedMember.bad_cell && selectedMember.cell && selectedMember.cell.length)" class="members_message__wrapper" type="error" outlined>
                This Advocate does not have a valid cell phone number
              </v-alert>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-layout  class="legislators_filter__additional--filter my-2" v-if="isDesktop || isDevice" wrap>
          <div
              class="ml-3"
              :class="{ 'w-100': isMobile }"
          >
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                left
                offset-y
                :close-on-click="false"
                max-width="300"
            >
              <template v-slot:activator="{ on }">
                <v-btn small rounded :outlined="isDesktop || isDevice" color="primary" v-on="on">
                  <span>Filters</span>
                  <v-icon>filter_list</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text class="members_filter-overflow">
                  <v-layout wrap>
                    <v-flex xs6 sm6 class="legislators_filter__additional--item_wrap mt-2">
                      <h4>Sent as:</h4>
                      <v-radio-group
                          v-model="filter.send_as"
                      >
                        <v-radio color="primary" label="All" :value="null"></v-radio>
                        <v-radio color="primary" label="Text/Email" :value="1"></v-radio>
                        <v-radio color="primary" label="Email Only" :value="0"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="error" text @click="clearFilter">Clear</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="secondary" @click="menu = false">Cancel</v-btn>
                  <v-btn color="primary" text @click="doFilter">Apply Filter</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
          <v-spacer></v-spacer>
          <v-icon v-if="dialog && (isDesktop || isDevice)" class="close-modal" @click.native="$emit('closeModal')">close</v-icon>
        </v-layout>
        <v-data-table
            v-if="schedule_messages.items && schedule_messages.items.length > 0"
            :loading="isLoading"
            :options.sync="schedule_messages.pagination"
            :server-items-length="schedule_messages.pagination.totalItems"
            :headers="messagesHeader"
            :items="schedule_messages.items"
            @update:options="getScheduleMessages"
            :disable-sort="isMobile"
        >

          <template v-slot:item.sender_name="{ item }">
            <div>
              {{ getSenderName(item) }}
            </div>
            <div class="d-flex justify-center">
              <h6 v-if="item.is_kps_send">KP</h6>
              <h6 v-if="item.is_coordinator_send">Coordinator</h6>
            </div>
          </template>

          <template v-slot:item.message="{ item }">
            <div
                @click="$refs.messageDetailModal.open(item.message)"
                class="cursor-pointer d-flex align-center"
            >
              <div>
                <v-chip v-if="item.is_sms" x-small dark color="indigo lighten-1">SMS</v-chip>
                <v-chip v-else x-small color="primary">Email</v-chip>
                <v-chip v-if="item.to_regional_coordinators" class="ml-2" x-small color="primary">RC</v-chip>
                <v-chip v-if="item.from_members_page" class="ml-2" x-small dark color="deep-purple darken-1">
                  Advocates
                </v-chip>
                <v-chip v-if="item.from_dashboard_page" class="ml-2" x-small dark color="deep-purple darken-1">KP
                </v-chip>
              </div>
              <v-btn
                  color="primary"
                  small
                  class="ml-2"
                  :loading="item.loading"
              >
                <v-icon size="20" left dark class="ml-2" v-if="$vuetify.breakpoint.smAndUp">fa-eye</v-icon>
                View message
              </v-btn>
            </div>
          </template>

          <template v-slot:item.sent_messages="{ item }">
            <div class="d-flex align-center justify-center schedule-circle">
              <v-progress-circular
                  class="cursor-pointer"
                  :rotate="-90"
                  :size="65"
                  :width="5"
                  :value="item.sent_messages_count * 100 / item.members_count"
                  :color="getProgressColor(item.sent_messages_count / item.members_count)"
                  @click="$refs.statsModal.toggle(item)"
              >
                {{ item.sent_messages_count }} / {{ item.members_count }}
              </v-progress-circular>
            </div>
          </template>

          <template v-slot:item.opened_emails="{ item }">
            <div v-if="item.is_sms">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="error" size="42">fa-thin fa-ban</v-icon>
                </template>
                <span>Unfortunately, it is not possible to track SMS opens</span>
              </v-tooltip>
            </div>
            <div v-else class="d-flex align-center justify-center schedule-circle">
              <v-progress-circular
                  class="cursor-pointer"
                  :rotate="-90"
                  :size="65"
                  :width="5"
                  :value="item.seen_messages_count * 100 / item.sent_messages_count"
                  :color="getProgressColor(item.seen_messages_count / item.sent_messages_count)"
                  @click="$refs.statsModal.toggle(item)"
              >
                {{ item.seen_messages_count }} / {{ item.sent_messages_count }}
              </v-progress-circular>
            </div>
          </template>

          <template v-slot:item.action="{ item }">
            <div class="d-flex justify-end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      dark
                      color="error"
                      @click="onDelete(item)"
                      v-on="on"
                      small
                      class="mx-3"
                      :loading="item.loading"
                  >
                    <v-icon size="20" left dark class="ml-2">fa-trash-alt</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.delivery_at="{ item }">
            <div>
              {{ getFormattedDate(item.delivery_at, 'MM/DD/YY h:mm a') }}
            </div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div>
              {{ getFormattedDate(item.created_at, 'MM/DD/YY h:mm a') }}
            </div>
          </template>

          <template v-slot:no-data>
            <p class="text-xs-center mb-0">No messages yet.</p>
          </template>
        </v-data-table>
        <v-alert type="warning" outlined :value="true" v-show="!schedule_messages.items.length && isLoading">
          Loading messages...
        </v-alert>
        <v-alert type="info" outlined :value="true" v-show="!schedule_messages.items.length && !isLoading">
          There are currently no messages sent.
        </v-alert>
      </v-tab-item>
    </v-tabs-items>


    <member-detail-modal
        ref="memberDetailModal"
        :selected-client="selectedClient"
        @openDistrictModal="openDistrictModal"
    ></member-detail-modal>
    <send-message-modal ref="sendMessageModal" :unique="isShowingUnique" :client="client"
                        @update="getScheduleMessages"></send-message-modal>
    <coordinator-message-modal
        :client="client"
        :legislators="legislatorsList"
        :coordinators="selectedCoordinatorsList"
        @update="getScheduleMessages"
        ref="coordinatorMessageModal"/>
    <message-detail-modal ref="messageDetailModal"></message-detail-modal>
    <stats-modal ref="statsModal"></stats-modal>

    <district-modal v-if="districtModalEnabled"></district-modal>
    <div id="htmlConvertContainer" v-show="false"></div>
  </div>

</template>

<script>
import _ from 'lodash'

import ClientService from '@/services/ClientService'
import UserService from '@/services/UserService'
import MemberService from '@/services/MemberService'
import DistrictService from '@/services/DistrictService'

import MemberDetailModal from '@/components/members/member-detail-modal'
import districtModal from '@/components/common/district-modal'
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import ShorterUrl from "@/mixins/shorterUrl";
import messageDetailModal from "@/components/messages/message-detail-modal";
import SendMessageModal from "@/components/kp-tasks/send-message-modal";
import statsModal from "@/components/messages/stats-modal";
import CoordinatorMessageModal from "@/components/kp-tasks/coordinator-message-modal";
import swal from "sweetalert2";
import {mapGetters} from "vuex";

export default {
  name: 'MessagesComponent',
  mixins: [ClientService, UserService, TinyConfig, MemberService, DistrictService, ShorterUrl],
  components: {
    'district-modal': districtModal,
    'member-detail-modal': MemberDetailModal,
    'editor': Editor,
    'message-detail-modal': messageDetailModal,
    SendMessageModal,
    statsModal,
    CoordinatorMessageModal
  },
  props: {
    route: {
      type: String,
      default: ''
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabs: null,
      menu: false,
      isMini: false,
      isLoading: true,
      loadingFile: false,
      isMembersLoading: false,
      isMessagesLoading: false,
      isShowingUnique: false,
      searchText: null,
      selectedMemberIndex: null,
      messageType: 0,
      replyMessage: '',
      isEditor: false,
      linkInsertion: false,
      markLoading: false,
      isFilesInput: false,
      files: [],
      currFiles: [],
      legislatorsList: [],
      filter: {
        messages_to: null,
        send_as: null,
      },
      schedule_messages: {
        pagination: {
          page: 1,
          itemsPerPage: 15,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
          sortBy: [],
          sortDesc: []
        },
        items: []
      },
      members: {
        pagination: {
          page: 1,
          itemsPerPage: 10,
          last_page: 0,
        },
        items: []
      },
      messages: {
        pagination: {
          page: 1,
          itemsPerPage: 15,
          last_page: 0,
        },
        items: []
      },
    }
  },
  async mounted() {
    this.isLoading = true
    await this.refresh()
    this.isLoading = false
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    isApproval() {
      return this.client.uses_approval_coordinator_m && this.isCoordinator
    },
    checkEmptyMessage() {
      return this.replyMessage
          .replace(/[&]nbsp[;]/gi, '')
          .replace(/<p>[\s]*<\/p>/gi, '')
    },
    messagesHeader() {
      return [
        {text: 'Creator', width: '125px', value: 'sender_name', align: 'left'},
        {text: 'Message', width: '30%', value: 'message', align: 'left'},
        {text: 'Date Sent', value: 'delivery_at', align: 'center'},
        {text: 'Created', value: 'created_at', align: 'center'},
        {text: 'Sent', value: 'sent_messages', align: 'center'},
        {text: 'Opened', value: 'opened_emails', align: 'center'},
        {text: '', value: 'action', align: 'right', sortable: false},
      ]
    },
    selectedContactList() {
      const selectedContactList = {
        contactCount: 0,
        emailList: [],
        cellList: [],
      }

      selectedContactList.contactCount += 1
      selectedContactList.cellList.push(this.userData.cell_phone)
      selectedContactList.emailList.push(this.userData.email)

      return selectedContactList
    },
    selectedCoordinatorsList() {
      if (!this.legislatorsList || this.legislatorsList.length < 1) return []

      let array = this.legislatorsList.filter(legislator =>
          legislator.district &&
          legislator.district.region &&
          legislator.district.region.member
      ).map(legislator => legislator.district.region.member)

      return array.filter((coordinator, index, self) =>
          index === self.findIndex((item) => (
              item.id === coordinator.id && item.name === coordinator.name && item.id
          ))
      )
    },
    selectedEmailList() {
      return [this.userData.email]
    },
    maxImgsSize() {
      if (this.currentImgsSize > this.imgSizeLimit) {
        this.onCallToastr('error', 'The size of the image is more than 25MB!', 'Failed!')
        return true
      } else return false
    },
    selectedMember() {
      return this.members.items[this.selectedMemberIndex]
    },
    selectedClient() {
      return this.userClient
    },
    groupedMessageList() {
      let messageList = this.messages.items.filter(message => message.is_sms === this.messageType).reverse()
      let groupedMessageMap = _.groupBy(messageList, (message) => {
        return this.getLocaleFromUTC(message.created_at, 'dddd MMMM DD, YYYY')
      })
      let groupedMessageList = []
      _.forEach(groupedMessageMap, (messageList, date) => {
        groupedMessageList.push({
          date,
          messageList
        })
      })
      return groupedMessageList
    },
    isMobile() {
      return window.screen.width <= 768
    },
    isUnseenMessages() {
      return this.$store.state.app.unseenMessageCount > 0
    },
    isSummaryUploadLimit() {
      return (this.currentImgsSize + this.currentFilesSize) > this.imgSizeLimit;
    }
  },
  filters: {
    dateFormatter(value) {
      return moment(value).format('hh:mm A')
    }
  },
  methods: {
    getTextColor() {
      this.$vuetify.theme.dark ? 'rgba(231,227,252,.87)' : 'rgba(94,86,105,.87)'
    },
    clearFilter() {
      this.filter = {
        send_as: null,
        messages_to: null,
      }
      this.doFilter()
    },
    doFilter() {
      this.schedule_messages.pagination.current_page = 1
      this.menu = false
      this.getScheduleMessages()
    },
    getScheduleMessages() {
      if (!(this.client && this.client.id)) return
      this.isLoading = true

      let params = {
        client_id: this.client.id,
        page: this.schedule_messages.pagination.page,
        limit: this.schedule_messages.pagination.itemsPerPage,
        is_sent: 1,
        order: this.schedule_messages.pagination.sortBy[0],
        messages_to: this.filter.messages_to,
        send_as: this.filter.send_as,
        direction: this.schedule_messages.pagination.sortDesc[0] ? 'asc' : 'desc'
      }

      this.getClientScheduleMessages(params).then(response => {
        this.schedule_messages.items = response.data.data ? response.data.data.map(message => {
          message.loading = false
          return message
        }) : response.data
        this.schedule_messages.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.schedule_messages.pagination.current_page = response.data.current_page
        this.schedule_messages.pagination.per_page = response.data.per_page
        this.schedule_messages.pagination.last_page = response.data.last_page

        this.isLoading = false
      }, () => {
        this.isLoading = false
        this.error = true
      })
    },
    openMessageModal(scheduleMessage) {
      this.$refs.sendMessageModal.toggleOnScheduleMessagePage(scheduleMessage)
    },
    onDelete(scheduleMessage) {
      swal({
        title: 'Are you sure you want to delete this scheduled message?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          scheduleMessage.loading = true
          const payload = { client_id: this.client.id }
          this.deleteClientScheduleMessage(scheduleMessage.id, payload).then(() => {
            scheduleMessage.loading = false
            this.onCallToastr('success', 'Scheduled message has been removed.', 'Success!')
            this.getScheduleMessages()
          }).catch((err) => {
            scheduleMessage.loading = false
            this.handleError(err)
          })
        }
      })
    },
    getSenderName(scheduleMessage) {
      if (!scheduleMessage.user) {
        return '-'
      }

      return scheduleMessage.member_like_sender ?
          `${scheduleMessage.member_like_sender.nickname} ${scheduleMessage.member_like_sender.lastname}` :
          `${scheduleMessage.user.nickname} ${scheduleMessage.user.lastname}`
    },
    customFilter(value, search, item) {
      search = search.toString().toLowerCase()
      return item.nickname.toLowerCase().includes(search) || item.lastname.toLowerCase().includes(search)
    },
    formatMessage(message) {
      if (message.length >= 200) {
        message = message.substr(0, 30)
        message += '...'
        return message
      }

      return message
    },
    async getLegislatorList() {
      let result = (await this.getClientKPTasks(this.client.id)).data
      this.result = result
      this.legislatorsList = result.legislators
    },
    pathName(file) {
      let url = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'http://kp-dashboard.local';
      return url + '/' + file.path;
    },
    scrollToBottom() {
      this.$nextTick().then(() => {
        const element = this.$refs.messageListView
        if (element && element.scrollHeight) {
          element.scrollTop = element.scrollHeight
        }
      })
    },
    async inputEmail() {
      this.$nextTick(async () => {
        const newContent = await this.parsUrl(this.replyMessage)

        if (newContent !== this.replyMessage) {
          this.replyMessage = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    getTextFromHTML(html) {
      const element = document.getElementById('htmlConvertContainer')
      element.innerHTML = html
      return element.textContent
    },
    async refresh() {
      if (!(this.client && this.client.id)) return
      await this.getScheduleMessages()
      await this.getLegislatorList()
      this.isLoading = true
      this.selectedMemberIndex = null
      this.messages.items = []
      this.members.items = []

      this.getMembersMessages().then(() => {
        this.isLoading = false
      })
    },
    fetchMessageList() {
      this.getMessages().then(() => {
        if (this.messages.pagination.page == 1) {
          this.scrollToBottom()
        }
      })
    },
    async onSelectMember(memberIndex, messageType) {
      if (messageType == 1) {
        this.$nextTick().then(() => {
          let input = document.getElementById("smsInput")
          input.selectionStart = this.selectedMember.nickname.length + 5;
          input.selectionEnd = this.selectedMember.nickname.length + 5;
          input.focus();
        })
      }

      this.messageType = messageType
      this.selectedMemberIndex = memberIndex
      let startText = `Hi ${this.selectedMember.nickname},\n\n- ${this.userData.nickname}`
      if (messageType === 0) {
        this.replyMessage = this.userData && this.userData.nickname ? `<br>  - ${this.userData.nickname}` : ''
        if (this.selectedMember.unseen_email_count > 0) {
          this.onCallToastr('success', `Please check ${this.selectedMember.unseen_email_count} new emails`, 'New Messages!')
        }
        this.selectedMember.unseen_email_count = 0
      } else {
        this.replyMessage = this.userData && this.userData.nickname ? startText : ''
        if (this.selectedMember.unseen_text_count > 0) {
          this.onCallToastr('success', `Please check ${this.selectedMember.unseen_text_count} new texts`, 'New Texts!')
        }
        this.selectedMember.unseen_text_count = 0
      }
      this.members.items.splice(memberIndex, 1, this.selectedMember)
      this.messages.pagination.page = 1
      this.messages.items = []

      this.fetchMessageList()

      await this.sawMessage(this.userId, {
        member_id: this.selectedMember.id,
        is_sms: messageType,
      })
      const messageCount = (await this.getClientUnseenMessageCount(this.userClientId)).data
      this.$store.dispatch('app/setUnseenMessageCount', messageCount)
    },
    getMessageFromText() {
      if (this.userData.nickname && this.userData.lastname) {
        return `${this.userData.nickname} ${this.userData.lastname}`
      }
      return false
    },
    send() {
      if (this.messageType === 0) {
        const message = {
          is_reply: 0,
          is_sms: 0,
          is_admin_send: false,
          members: [this.selectedMember.id],
          message: this.replyMessage,
        }
        this.messages.items.push(message)
        this.scrollToBottom()
        // Send Reply Email
        let payload = {
          clientId: this.userClientId,
          tos: [this.selectedMember.email],
          reply_to_email: this.userClient.reply_to_email,
          is_approval: this.isApproval,
          subject: '-',
          is_sms: 0,
          is_kps_send: 0,
          is_admin_send: false,
          members: [this.selectedMember.id],
          message: this.replyMessage,
          files: []
        }

        if (this.getMessageFromText()) {
          payload.from = this.getMessageFromText()
        }

        this.addFiles().then(() => {
          this.loadingFile = false
          payload.files = this.files
          this.saveClientScheduleMessage(payload)
              .then(() => {
                this.onCallToastr('success', 'Message has been sent.', 'Success!')
                this.onSelectMember(this.selectedMemberIndex, 0)
                this.removeAllAttachedFiles()
              })
        })
      } else {
        const message = {
          is_reply: 0,
          is_sms: 1,
          is_approval: this.isApproval,
          is_admin_send: false,
          members: [this.selectedMember.id],
          message: this.replyMessage,
        }
        this.messages.items.push(message)
        this.scrollToBottom()
        // Send Reply Text
        let payload = {
          clientId: this.userClientId,
          tos: [this.selectedMember.cell],
          message: this.replyMessage,
          is_sms: 1,
          is_kps_send: 0,
          is_admin_send: false,
          members: [this.selectedMember.id],
          files: []
        }
        this.addFiles().then(() => {
          this.loadingFile = false
          payload.files = this.files
          this.saveClientScheduleMessage(payload)
              .then(() => {
                this.onCallToastr('success', 'Message has been sent.', 'Success!')
                this.onSelectMember(this.selectedMemberIndex, 1)
              })
        })
      }
      this.replyMessage = ''
    },
    onSendMessage() {
      if (!this.replyMessage) return
      if (this.isApproval) {
        swal({
          text: 'Ready to place this message into the queue for approval? You will be notified once it has been approved and sent.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Queue for Approval',
          cancelButtonText: 'Go Back',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.send()
          }
        })
      } else {
        this.send()
      }
    },
    hideEditor() {
      this.isEditor = false
      this.replyMessage = ''
    },
    showEditor() {
      this.isEditor = true
    },
    onAddVariableEmail(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);

      setTimeout(() => {
        this.getFormatedText()
      }, 200)
    },
    onAddVariableSms(variable) {
      let field = this.$refs.smsInput.$refs.input
      if (field.selectionStart || field.selectionStart == '0') {
        let startPos = field.selectionStart;
        let endPos = field.selectionEnd;
        this.replyMessage = field.value.substring(0, startPos)
            + variable
            + field.value.substring(endPos, field.value.length);
      } else {
        this.replyMessage = field.value + variable;
      }

      this.getFormatedText()
    },
    getFormatedText() {
      this.linkInsertion = true
      let payload = {
        legislator_id: this.selectedMember.client_id,
        member_id: this.selectedMember.id,
        message: this.replyMessage,
      }

      this.getAppFormattedMessage(payload).then(response => {
        this.replyMessage = response.data
        this.linkInsertion = false
      })
    },
    getMembersMessages(action) {
      this.isMembersLoading = true
      let data = {
        page: this.members.pagination.page,
        limit: this.members.pagination.itemsPerPage,
        search_text: this.searchText
      }

      return this.getClientMessageMembersWithPagination(this.userClientId, data).then(response => {
        if (action === 'loadMore') {
          this.members.items = this.members.items.concat(response.data.data ? response.data.data : response.data)
        } else {
          this.members.items = response.data.data ? response.data.data : response.data
        }
        this.members.pagination.last_page = response.data.last_page
        this.isMembersLoading = false
      })
    },
    loadMoreMembers() {
      this.members.pagination.page++
      this.getMembersMessages('loadMore')
    },
    downloadFile(file) {
      this.getFileById(this.selectedClient.id, file.id).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data], {type: "application/jpeg"}))
        let link = document.createElement('a')
        link.href = fileURL;
        link.setAttribute('download', file.name)
        link.click()
        link.remove();
      })
    },
    getMessages() {
      this.isMessagesLoading = true
      let data = {
        page: this.messages.pagination.page,
        limit: this.messages.pagination.itemsPerPage,
        member_id: this.selectedMember.id
      }

      return this.getClientMessagesWithPagination(this.userClientId, data).then(response => {
        this.messages.items = this.messages.items.concat(response.data.data ? response.data.data : response.data)
        this.messages.pagination.last_page = response.data.last_page
        this.isMessagesLoading = false
      })
    },
    loadMoreMessages() {
      this.messages.pagination.page++
      this.fetchMessageList()
    },
    unSeenMessage(id) {
      let payload = {
        message_id: id
      }
      this.markMessageAsUnread(this.userId, payload).then(() => {
        this.getUnseenMessageCount()
        this.refresh()
      })
    },
    async getUnseenMessageCount() {
      if (this.userClientId) {
        const messageCount = (await this.getClientUnseenMessageCount(this.userClientId)).data
        this.$store.dispatch('app/setUnseenMessageCount', messageCount)
      }
    },
    markAllAsRead() {
      this.markLoading = true
      this.markAllMessagesAsRead(this.userId).then(() => {
        this.getUnseenMessageCount()
        this.refresh()
        this.markLoading = false
      }, () => {
        this.markLoading = false
      })
    },
    filesInputChanged() {
      this.currFiles = [
        ...this.currFiles,
        ...this.files
      ]
      this.currFiles = [...new Set(this.currFiles)]
      this.files = [...new Set(this.currFiles)]
    },
    removeAttachedFile(index) {
      this.files.splice(index, 1)
      this.currFiles.splice(index, 1)
    },
    removeAllAttachedFiles() {
      this.currFiles = []
      this.files = []
      this.isFilesInput = false
    },
    async addFiles() {
      if (this.files.length) {
        let promises = []
        this.loadingFile = true

        for (let file of this.files) {
          let data = new FormData()
          data.append('file', file)
          promises.push(this.saveScheduleMessageFile(data))
        }

        this.files = (await Promise.all(promises)).map(res => {
          return res.data
        })
      }
    },
    updateUserMember() {
      this.isLoading = true
      this.selectedMember.silence = 0
      this.updateMember(this.selectedMember).then(response => {
        this.isLoading = false
      })
    },
    getMessageTitle(message) {
      if (message.sender_name) {
        if (message.is_reply) {
          return `${this.userClient.assoc_abbrev}`
        } else {
          return `${this.userClient.assoc_abbrev} - ${message.sender_name}`
        }
      } else {
        return this.userClient.assoc_abbrev
      }
    }
  },
  watch: {
    searchText: _.debounce(function () {
      this.members.items = []
      this.members.pagination.page = 1
      this.refresh()
    }, 300),
  }
}
</script>

<style lang="scss" scoped>
$mobile-screen-width: 768px;

.activeLight {
  background-color: #f4f5fa;
}

.activeDark {
  background-color: #28243d;
}

.vipDark {
  background-color: rgba(145, 85, 253, 0.3);
}

.vipLight {
  background-color: rgba(197, 172, 246, 0.87);
}

.close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
}

.card-container {
  display: flex;

  .member-list-sidebar {
    max-width: 380px;
    width: 100%;
    @media(max-width: 767px) {
      max-width: 320px;
    }

    header {
      background-color: #FAFAFA;
    }

    .member-list {

      overflow-y: auto;
      height: calc(100vh - 270px);
      @media(max-width: 767px) {
        height: calc(100vh - 150px);
      }

      .v-list-item {
        padding: 0;
      }
    }

    @media screen and (max-width: $mobile-screen-width) {
      max-width: inherit;
    }

  }

  .member {
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    &.active {
      background-color: #eee;
    }
  }

  .conversation-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100vh - 150px);

    .reply-tabbar {
      border-bottom: 2px solid #eee;
    }

    .message-list-view {
      flex: 1;
      overflow-y: auto;

      .message {
        margin: 0 8px;
        max-width: 600px;
        min-width: 200px;
        border-radius: 8px;
        position: relative;

        &.opened {
          margin: 0 8px 20px;
        }

        &_read-at {
          position: absolute;
          right: 0;
          bottom: -20px;
          font-style: italic;
          font-size: 12px;
        }

        &_mark-unseen {
          position: absolute;
          right: 0;
          opacity: 0;
          z-index: -1;
          top: 0;
          transition: all 0.2s;
          @media (max-width: 767px) {
            position: absolute;
            left: -57px;
            opacity: 1;
            z-index: 1;
            top: 55px;
          }
        }

        &:hover {
          .message_mark-unseen {
            right: -30px;
            opacity: 1;
            z-index: 1;
          }
        }
      }
    }
  }
}

.input {
  &_area {
    &__field {
      display: flex;
      align-items: flex-end;
      position: relative;

      &--wrapper {
        width: 100%;
      }

      .tiny-editor {
        width: 100%;
      }

      &--counter {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #666;
        margin-top: 4px;

        &.error-color {
          color: red;
        }

        span {
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }

    &__cancel {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>

<style lang="scss">
#messagePage {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  .member-list-sidebar {
    .v-list__tile__action {
      min-width: 75px;
    }
  }

  .conversation-container {
    .v-avatar {
      min-width: 48px;
    }
  }

  .message {
    img {
      max-width: 100%;
    }
  }
}

</style>
