const state = {
  notifications: [],
  loading: false,
}

const getters = {
  notifications: state => state.notifications,
  loading: state => state.loading,
}

const actions = {

  setLoading({ commit }, data) {
    commit('setLoading', data)
  },

  setNotifications({ commit }, items) {
    commit('setNotifications', items)
  },

}

const mutations = {

  setLoading(state, value) {
    state.loading = value
  },

  setNotifications(state, items) {
    state.notifications = items
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
