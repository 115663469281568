<template>
  <v-main>
    <v-container class="purple-background pb-12" style="display: -webkit-box; display: -ms-flexbox; -ms-flex-wrap: wrap; flex-wrap: wrap; height: 100%;" fluid>
      <v-flex class="d-flex fill-height align-md-center justify-center">
        <router-view></router-view>
      </v-flex>
      <v-layout class="d-flex justify-center login-footer" :class="isMobile ? 'flex-column text-center' : ''">
        <!--<v-flex :class="isDesktop || isDevice ? 'login-footer_version' : ''">Version 2.9 </v-flex>-->
        <v-flex v-if="!isMobile" class="d-flex justify-center login-footer_links">
          &copy; {{ new Date().getFullYear() }} KP Dashboard, All Rights Reserved
          <div><a href="https://kpdashboard.com/kpdashboard-license-agreement.pdf" style="" target="_blank">Legal</a></div>
          <div><a href="https://kpdashboard.com/KPD_Privacy_Policy_v2.pdf" style="" target="_blank">Privacy Policy</a></div>
          <!--<div><a href="#" style="display: none" target="_blank">Support</a></div>-->
          <div><a href="https://kpd.statuspage.io/" target="_blank">Status Page</a></div>
        </v-flex>
        <v-flex v-else class="d-flex flex-column justify-center align-center login-footer_links">
          <div>&copy; {{ new Date().getFullYear() }} KP Dashboard, All Rights Reserved</div>
          <div class="d-flex">

            <div><a href="https://kpdashboard.com/kpdashboard-license-agreement.pdf" style="" target="_blank">Legal</a></div>
            <div><a href="https://kpdashboard.com/KPD_Privacy_Policy_v2.pdf" style="" target="_blank">Privacy Policy</a></div>
            <!--<div><a href="#" style="display: none" target="_blank">Support</a></div>-->
            <div><a href="https://kpd.statuspage.io/" target="_blank">Status Page</a></div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'LoginLayout'
  }
</script>

<style lang="scss">
a {
  text-decoration: unset;
}
</style>
