<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">District Lookup Help</v-card-title>
        <v-card-text v-if="isSuperAdmin || isVIP || isCoordinator">
          This page allows you to view all of your Advocates that either live or work in a certain district. These Advocate "constituents" are far more important to
          Legislators than a non-constituent. If at all possible, you would want to find a consituent as a KP for any legislator!
          <br /><br />
          Admins can see the "Sample Volunteer Request" button. This is helpful if you need to ask Advocates who live or work in this district to become a KP. Admins,
          Coordinators, and KPs can all see the "Sample Donation Request" button. This will allow KPs to request donations from a legislator's constituent
          Advocates to present to a Legislator at a meeting. These requests are better to come directly from a personal email, which is why we require you to
          copy the message and member list into your own email software.
          <br /><br />
          Important note: the "Sample Volunteer Request" popup window only shows you a list of Advocates who are eligible. If an Advocate has expressed no interest
          in the KP structure, then his/her "Eligible" checkbox should be unchecked. This will remove him/her from this list. ALL district Advocates are displayed in the
          "Sample Donation Request" popup window.
        </v-card-text>
        <v-card-text v-if="isKP">
          Use this page to see all of the {{ client.abrev }} Advocates that either live or work in a certain district. Before meeting with a Legislator, consider contacting this
          group of Advocates and request a donation check for the Legislator. The Legislator is much more likely to accept a meeting invitation if he or she knows that
          you are bringing campaign donations from actual constituents.
          <br /><br />
          Click the "Sample Donation Request" button then copy the email list into your email software. Also copy the sample text and modify it to your liking. Do not forget
          to change the dates and other pertinent info!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DistrictLookupPageHelpModal',
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
