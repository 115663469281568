<template>
  <v-dialog persistent max-width="750" v-model="isModalOpen">
    <v-card :loading="isLoading">
      <v-card-title>
        <span class="title">Send test to {{ isEmail ? userData.email : userData.cell_phone }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <div class="mb-6">
          <editor
            v-if="formattedMessage"
            :value="formattedMessage"
            disabled
            :api-key="TINY_MCE_API_KEY"
            :init="tinyInit"
            toolbar="false"
          />
        </div>
        <v-btn
          class="mr-4"
          outlined
          color="secondary"
          @click="closeModal"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isLoading"
          :loading="isLoading"
          @click.native="onSend"
        >
          Send Test
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import TinyConfig from "@/mixins/tiny-mce-config"
import he from "he";
import SuperAdminService from "@/services/SuperAdminService";

export default {
  name: 'SuperAdminTestMessageModal',
  components: {
    'editor': Editor,
  },
  mixins: [TinyConfig, SuperAdminService],
  props: {
    isEmail: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: ''
    },
    recipient: {
      type: Object,
      default: function () {
        return {}
      }
    },
    subject: {
      type: String,
      default: ''
    },
    files: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      formattedMessage: ''
    }
  },
  methods: {
    open() {
      this.isModalOpen = true
      this.formatTestMessage()
    },
    closeModal() {
      this.isModalOpen = false
      this.formattedMessage = ''
    },
    async formatTestMessage() {
      if (this.message === '') {
        this.isLoading = false
        return
      }
      this.isLoading = true

      let payload = {
        message: this.message,
      }

      if (this.recipient.is_user) {
        payload.user_id = this.recipient.id
      } else {
        payload.member_id = this.recipient.id
      }

      try {
        await this.getAppFormattedMessage(payload)
          .then((response) => {
            this.formattedMessage = response.data
          })
        this.isLoading = false
      } catch (e) {
        this.onCallToastr('error', 'Something went wrong, please try again later!', 'Failed!')
        this.isLoading = false
        this.isModalOpen = false
      }
    },
    async onSend() {
      this.isLoading = true

      let payload = {
        is_sms: +!this.isEmail
      }

      if (this.isEmail) {
        payload.message = this.message
        payload.subject = `(Test message) ${this.subject}`
        payload.files = this.files
      } else {
        payload.subject = 'Test message'
        payload.message = this.html2Text(this.message)
      }

      if (this.recipient.is_user) {
        payload.user_id = this.recipient.id
      } else {
        payload.member_id = this.recipient.id
      }

      try {
        await this.sendSuperAdminTestMessage(payload)
        this.onCallToastr('success', 'Test message sent!', 'Success!')
      } catch (e) {
        this.onCallToastr('error', 'Something went wrong, please try again later!', 'Failed!')
      } finally {
        this.isLoading = false
      }
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
        re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    }
  }
}
</script>
