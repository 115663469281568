import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VForm,{ref:"disableTwoFactorVerification",on:{"submit":function($event){$event.preventDefault();return _vm.disableTwoFactor.apply(null, arguments)}}},[(_vm.user)?_c(VCard,[_c(VCardText,[_c(VAlert,{attrs:{"type":"info","outlined":"","value":true}},[_vm._v(_vm._s("Verification code has sent to your phone, please check and deactivate 2FA"))]),(_vm.error && typeof _vm.error !== 'boolean')?_c(VAlert,{attrs:{"type":"error","outlined":"","value":true}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c(VLayout,{staticClass:"py-3",attrs:{"wrap":""}},[_c(VFlex,[_c(VTextField,{attrs:{"label":"2FA code","rules":_vm.verificationCodeRules,"type":"text","error-messages":_vm.error?[_vm.message]: []},on:{"input":function($event){_vm.error = ''}},model:{value:(_vm.verificationCode),callback:function ($$v) {_vm.verificationCode=$$v},expression:"verificationCode"}})],1)],1)],1),_c(VDivider,{attrs:{"light":""}}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},nativeOn:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"type":"submit","color":"primary","loading":_vm.isBusy}},[_vm._v(_vm._s('Disable 2FA'))])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }