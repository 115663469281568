<template>
  <v-container fluid grid-list-lg>
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>API Mappings</h1>
        <v-spacer></v-spacer>
        <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  small
                  color="primary"
                  dark
                  class="mb-2"
                  @click="editItem({})"
              >
                New Mapping
              </v-btn>
            </template>
            <span>Create new API mapping</span>
          </v-tooltip>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex>
        <v-select
            label="API Source"
            :items=apiSources
            item-text="api_client_name"
            item-value="id"
            v-model="selectedApi"
            :disabled="isLoading"
            @change="changeApi()"
            class="state-selector"
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout wrap>
    <v-flex class="w-100">
      <v-data-table
          :headers="this.table.headers"
          :items="this.currentMappings"
          :items-per-page="-1"
      >
        <template v-slot:top>
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-text-field
                        v-model="editedItem.db_field"
                        label="DB field"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                        v-model="editedItem.api_field"
                        label="API template"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-select
                        label="API Source"
                        :items=apiSources
                        item-text="api_client_name"
                        item-value="id"
                        v-model="editedItem.api_source_id"
                        :disabled="isLoading"
                    ></v-select>

                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.api_source_id="{ item }">
          {{ getApiClientName(item) }}
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="init()"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ApiSourcesService from "@/services/ApiSourcesService";

export default {
  name: 'api-sources',
  mixins: [ApiSourcesService],
  data() {
    return {
      isLoading: true,
      dialog: false,
      dialogDelete: false,
      apiSources: [],
      selectedApi: {},
      mappingsApi: {},
      mappings: [],
      table: {
        headers: [
          {text: 'ID', value: 'id'},
          {text: 'API Source', value: 'api_source_id'},
          {text: 'DB field', value: 'db_field'},
          {text: 'API template', value: 'api_field'},
          {text: 'Actions', value: 'actions', sortable: false},
        ],
      },
      editedIndex: -1,
      editedItem: {
        db_field: '',
        api_field: ''
      },
      defaultItem: {
        db_field: '',
        api_field: ''
      },
    }
  },
  methods: {
    getApiClientName(item){
      //At first get item index within apisources
      //Get client information by index within apisources
      const clientIndx = this.apiSources.findIndex(el => {
              return el.id === item.api_source_id
            });
      const clientInfo = this.apiSources[clientIndx];
      const clientName = clientInfo && clientInfo.api_client_name? clientInfo.api_client_name: '';
      
      return clientName;
    },
    init() {
      this.isLoading = true
      this.getApiSources().then(res => {
        this.apiSources = res.data
        this.selectedApi = this.apiSources.filter(v => {
          return v.enabled
        }).shift()
        this.mappingsApi = this.apiSources.filter(v => {
          return v.enabled
        }).shift()

      })
      this.getApiSourceMappings().then(res => {
        this.mappings = res.data
      })
      this.isLoading = false
    },
    editItem(item) {
      this.editedIndex = this.mappings.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.mappings.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteApiSourceMapping(this.mappings[this.editedIndex].id).then(() => this.init())
      this.init()
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.mappings[this.editedIndex], this.editedItem)
        this.editApiSourceMapping(this.editedItem.id, this.editedItem).then(() => this.init())
      } else {
        this.mappings.push(this.editedItem)
        this.createApiSourceMapping(this.editedItem).then(() => this.init())
      }
      this.close()
    },
    changeApi() {
      this.switchApiSource(this.selectedApi)
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    currentMappings() {
      return this.mappings
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Mapping' : 'Edit Mapping'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
}
</script>
