<template>
  <v-navigation-drawer fixed temporary right touchless width="500" v-model="isModalOpen">
    <v-layout fill-height>
      <v-form ref="committeeForm" @submit.prevent="onUpdateCommittee()">
        <v-card style="box-shadow: none !important;">
          <v-card-title>
            <span class="title">Edit Committee</span>
            <v-spacer />
            <v-icon @click="isModalOpen = false">close</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
            <v-layout wrap>
              <v-flex xs12 class="px-2">
                <v-select label="Type" item-value="id" item-text="text" :items="committeeTypeList" :rules="typeRules" v-model="committee.type"></v-select>
              </v-flex>
              <v-flex xs12 class="px-2">
                <v-text-field label="Short Name" :rules="shortNameRules" v-model="committee.shortname"></v-text-field>
              </v-flex>
              <v-flex xs12 class="px-2">
                <v-text-field label="Full Name" :rules="fullNameRules" v-model="committee.fullname"></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
            <v-btn type="submit" color="primary" :loading="isBusy">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import CommitteeService from '@/services/CommitteeService'
import _ from "lodash";

export default {
  name: 'EditCommitteeModal',
  props: ['stateList', 'refresh'],
  mixins: [CommitteeService],
  data () {
    return {
      isModalOpen: false,
      committee: {},
      error: '',
      isBusy: false,
      selectedLegislators: [],
    }
  },
  computed: {
    stateRules () {
      return [
        v => !!v || 'State is required.',
      ]
    },
    typeRules () {
      return [
        v => typeof v !== 'undefined' || 'Type is required',
      ]
    },
    shortNameRules () {
      return [
        v => !!v || 'Short name is required.',
        v => (v && v.length <= 8) || 'Short name should contain less than 8 characters.'
      ]
    },
    fullNameRules () {
      return [
        v => !!v || 'Full name is required.',
      ]
    },
    committeeTypeList () {
      return [
        {
          id: 0,
          text: 'House',
        },
        {
          id: 1,
          text: 'Senate',
        },
        {
          id: 2,
          text: 'Joint',
        },
      ]
    },
  },
  methods: {
    toggle (committee) {
      this.isModalOpen = true
      this.error = ''
      this.committee = _.cloneDeep(committee)
    },
    onUpdateCommittee () {
      if (!this.$refs.committeeForm.validate()) return
      this.isBusy = true
      this.committee.is_admin_edit = true
      this.updateCommittee(this.committee)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'Committee info has been updated.', 'Success!')
          this.refresh()
          this.isModalOpen = false
        })
        .catch((err) => {
          this.isBusy = false
          this.handleError(err)
        })
    }
  }
}
</script>
