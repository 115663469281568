<template>
  <div class="mt-3">
    <v-expansion-panels v-model="isFilterOpen">
      <v-expansion-panel>
        <v-expansion-panel-header>Filters (Optional)</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-layout row wrap>
            <v-flex xs12 class="pb-4">
              <v-layout wrap>
                  <h4 class="mb-0 mt-2 px-3">Legislators</h4>
                  <v-spacer></v-spacer>
                    <v-checkbox
                      class="mt-0 px-3"
                      color="primary"
                      label="Specific Legislator(s) Only"
                      hide-details
                      v-model="filter.isSpecificLegislatorsOnly"
                      @change="fillLegislator"
                    ></v-checkbox>
                <v-flex
                  xs12
                  class="px-2"
                  v-if="!filter.isSpecificLegislatorsOnly"
                >
                  <v-radio-group
                    row
                    v-model="filter.committeeType"
                    @change="onFilterLegislators(true)"
                  >
                    <v-checkbox
                        label="All"
                        :value="this.client.state.state !== 'Nebraska'"
                        :disabled="this.client.state.state === 'Nebraska'"
                        color="primary"
                        v-model="filter.all"
                        @change="onFilterLegislators(true)"
                        class="pr-2"
                    >
                      All
                    </v-checkbox>
                    <v-radio label="House" value="0" color="primary" :disabled="filter.all"></v-radio>
                    <v-radio label="Senate" value="1" color="primary" :disabled="filter.all"></v-radio>
                    <v-radio label="Joint" value="2" color="primary" :disabled="filter.all" v-if="client && client.state && client.state.has_joint_committees"></v-radio>
                  </v-radio-group>

                  <div class="d-flex flex-nowrap">
                    <v-checkbox
                      color="primary"
                      class="mr-3"
                      label="Vip"
                      @change="onFilterLegislators"
                      v-model="filter.vip"
                    ></v-checkbox>
                    <v-checkbox
                      color="primary"
                      label="Lame Duck"
                      @change="onFilterLegislators"
                      v-model="filter.lameDuck"
                    ></v-checkbox>
                  </div>
                </v-flex>
                <v-flex xs12 class="px-2" v-if="!filter.isSpecificLegislatorsOnly && filter.isDistricts !== null">
                  <div class="d-flex flex-nowrap" v-for="committee in selectedCommitteeList" :key="committee.id">
                    <v-flex xs12 sm7>
                      <v-checkbox
                        color="primary"
                        @change="onSelectCommittee(committee)"
                        :input-value="filter.selectedCommitteeIdList.includes(committee.id)"
                        :label="committee.label"
                        :disabled="filter.selectedSubCommitteeIdList.includes(committee.id) || isBusy"
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs12 sm5
                            v-if="client && client.state && client.state.has_subcommittees">
                      <v-checkbox
                        color="primary"
                        :disabled="(!filter.selectedSubCommitteeIdList.includes(committee.id) && !filter.selectedCommitteeIdList.includes(committee.id)) || isBusy"
                        :input-value="filter.selectedSubCommitteeIdList.includes(committee.id)"
                        @change="onSelectCommittee(committee, true)"
                        label="Subcommittee only"
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                  </div>
                </v-flex>

                <v-flex xs12 class="mt-3 px-2">
                  <p>{{ 'Send to members of these legislators' }}</p>
                  <v-autocomplete
                    v-model="filter.legislators"
                    :items="sortLegislatorsList"
                    outlined
                    dense
                    color="blue-grey lighten-2"
                    label="Select legislators"
                    item-text="fullname"
                    item-value="id"
                    :label="'Select Legislator(s) (' + (filter.legislators || []).length + ')'"
                    multiple
                    return-object
                    :search-input.sync="searchInputFull"
                    hide-details
                    :menu-props="{maxHeight: 'calc(100% - 320px)'}"
                    @change="searchInputFull=''"
                  >
                    <template v-slot:selection="{ item }">
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar class="avatar_image">
                          <img
                            :src="data.item.official_image ? getThumbImage(data.item.official_image) : '/images/member-avatar.png'"
                            :alt="data.item.fullname">
                        </v-list-item-avatar>
                        <vip-chip
                          v-if="data.item.vip"
                          class="mr-2 mb-1"
                          :legislator="data.item"
                          :show="'chip_code'"
                        >
                        </vip-chip>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ `${ data.item.fullname } (${ data.item.district.district })` }}
                            <v-tooltip bottom v-if="data.item.lame_duck">
                              <template v-slot:activator="{ on }">
                                <a v-on="on">
                                  <v-icon color="#EECB23" size="16" class="mx-2">fa-solid fa-duck</v-icon>
                                </a>
                              </template>
                              <span>Lame Duck</span>
                            </v-tooltip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <v-layout wrap :class="isMobile ? 'flex-column' : ''">
                    <v-flex
                      xs12
                    >
                      <div class="mb-2 pt-1" v-if="filter.legislators && filter.legislators.length">
                        <div class="d-flex flex-wrap">
                          <div v-for="item of filter.legislators" :key="item.id" class="mr-2">
                            <v-menu
                              v-model="displaySelected[getIndex(item.id, filter.legislators)]"
                              bottom
                              left
                              origin="top left"
                              content-class="role_card"
                            >
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  close
                                  class="mt-2"
                                  pill
                                  small
                                  v-on="on"
                                  :style=colorsLegislatorsParties(item,legislators)
                                  @click:close="removeLegislator(getIndex(item.id, filter.legislators), filter.legislators, item)"
                                >
                                  <v-avatar max-width="24" max-height="24" left>
                                    <v-img width="24" height="24" class="avatar_image"
                                           :src="item.official_image ? getThumbImage(item.official_image) : '/images/member-avatar.png'"></v-img>
                                  </v-avatar>
                                  <vip-chip
                                    v-if="item.vip"
                                    class="mr-2 mb-1"
                                    :legislator="item"
                                    :show="'chip_code'"
                                  >
                                  </vip-chip>
                                  {{ item.fullname }}
                                </v-chip>
                              </template>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12 v-if="filter.exceptLegislators && filter.exceptLegislators.length">
                      <h3>Except:</h3>
                      <div class="mb-2 pt-1">
                        <div class="d-flex flex-wrap">
                          <div v-for="item of filter.exceptLegislators" :key="item.id" class="mr-2">
                            <v-menu
                              v-model="displayExceptSelected[getIndex(item.id, filter.exceptLegislators)]"
                              bottom
                              left
                              origin="top left"
                              content-class="role_card"
                            >
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  close
                                  class="mt-2"
                                  small
                                  pill
                                  v-on="on"
                                  :style=colorsLegislatorsParties(item,legislators)
                                  @click:close="removeExceptLegislator(getIndex(item.id, filter.exceptLegislators), filter.exceptLegislators)"
                                >
                                  <v-avatar max-width="24" max-height="24" left>
                                    <v-img width="24" height="24" class="avatar_image"
                                           :src="item.official_image ? getThumbImage(item.official_image) : '/images/member-avatar.png'"></v-img>
                                  </v-avatar>
                                  <vip-chip
                                    v-if="item.vip"
                                    class="mr-2 mb-1"
                                    :legislator="item"
                                    :show="'chip_code'"
                                  >
                                  </vip-chip>
                                  {{ item.fullname }}
                                </v-chip>
                              </template>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

            </v-flex>
              <v-expansion-panels
                v-model="isFiltersOpen"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>Filter by Task</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                        <v-list-item v-for="(task, index) in sessionTasks" :key="index" persistent-hint
                          :hint="task.tooltip"
                        >
                        <v-checkbox
                          color="primary"
                          :label="getTaskLabel(task)"
                          @change="filterMenu(task.id)"
                          hide-details
                          v-model="filterTasksData[index]"
                        ></v-checkbox>
                        <v-spacer></v-spacer>
                        <div v-if="task && filterTaskMenuData">
                          <v-menu
                              role="dialog"
                              content-class="c-menu--popover"
                              :close-on-content-click="true"
                              :eager="true"
                              :offset-y="true"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                              small
                              v-bind="attrs"
                              v-on="on"
                              v-if="showMenu"
                              class="mt-4"
                              >
                              <span v-if="filterTasksData[task.id]" @click="toggleFilterMenu(task.id)">{{filterTaskMenuData[task.id]['selected'] === 'Uncompleted' ? 'Not Complete' : filterTaskMenuData[task.id]['selected']}}({{filterTaskMenuData[task.id][`${filterTaskMenuData[task.id]['selected']}`]}})</span>
                                <v-icon v-if="!filterTaskMenu[task.id] && filterTasksData[task.id]" :color="isDarkTheme ? 'primary' : 'secondary'" @click="toggleFilterMenu(task.id)">mdi-chevron-up</v-icon>
                                <v-icon v-else-if="filterTaskMenu[task.id] && filterTasksData[task.id]" :color="isDarkTheme ? 'primary' : 'secondary'" @click="toggleFilterMenu(task.id)">mdi-chevron-down</v-icon>
                              </div>
                            </template>
                              <v-list>
                                <v-list-item @click="selectedFilter(task.id, 'All')">
                                  <v-list-item-title>
                                    <template v-if="filterTaskMenuData[task.id] && filterTaskMenuData[task.id]['All'] !== undefined">
                                      All ({{ filterTaskMenuData[task.id]['All'] }})
                                    </template>
                                    <template v-else> All (0) </template>
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item  @click="selectedFilter(task.id, 'Completed')">
                                  <v-list-item-title>
                                    <template v-if="filterTaskMenuData[task.id] && filterTaskMenuData[task.id]['Completed'] !== undefined">
                                      Completed ({{ filterTaskMenuData[task.id]['Completed'] }})
                                    </template>
                                    <template v-else> Completed (0) </template>
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="selectedFilter(task.id, 'Uncompleted')">
                                  <v-list-item-title>
                                    <template v-if="filterTaskMenuData[task.id] && filterTaskMenuData[task.id]['Uncompleted'] !== undefined">
                                      Not Completed ({{ filterTaskMenuData[task.id]['Uncompleted'] }})
                                    </template>
                                    <template v-else> Not Completed (0) </template>
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                        </v-menu>
                      </div>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-layout>
          <div class="d-flex mt-8">
            <v-spacer></v-spacer>
            <v-btn color="error" outlined text @click="clearFilter" :disabled="isBusy" small class="mr-2">Cancel</v-btn>
            <v-btn
            color="primary" @click="refreshMembers" :disabled="isBusy" small>Apply</v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

import VipChip from "@/components/legislators/vip-setting/vip-chip";
import swal from "sweetalert2";
import ClientService from "@/services/ClientService";

export default {
  name: 'KpsFilter',
  mixins: [
    ClientService,
  ],
  components: {
    'vip-chip': VipChip,
  },
  props: {
    client: {
      type: Object,
      default: () => {
        return {}
      }
    },
    legislators: {
      type: Array,
      default: () => {
        return []
      }
    },
    committeeList: {
      type: Array,
      default: () => {
        return []
      }
    },
    sessionTasks: {
      type: Array,
      default: () => {
        return []
      }
    },
    filterTasks: {
      type: Object,
      default: () => {
        return {}
      }
    },
    filterTaskMenuData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      filter: {
        all: this.client.state.state !== 'Nebraska',
        isDistricts: null,
        vip: false,
        lameDuck: false,
        committees: [],
        legislators: [],
        exceptLegislators: [],
        isSpecificLegislatorsOnly: true,
        selectedSubCommitteeIdList: [],
        selectedCommitteeIdList: [],
        tasks: {}
      },
      displaySelected: {},
      displayExceptSelected: [],
      isFilterOpen: false,
      isFiltersOpen: false,
      isBusy: false,
      searchInputFull: '',
      checkboxLabels: [
        { value: 'default', name: 'All' },
        { value: 1, name: 'Yes' },
        { value: 0, name: 'No' },
      ],
      panel: [],
      filterTasksData: _.cloneDeep(this.filterTasks),
      showFilterList: true,
      showMenu: true,
      filterTaskMenu: {},
      filters: {}
    }
  },
  computed: {
    props() {
      return props
    },
    taskItems() {
      return ['All', 'Completed', 'Not done']
    },
    selectedCommitteeList() {
      let selectedCommitteeList = []

      if (this.filter.committeeType === '0') {
        selectedCommitteeList = [...this.committeeList
          .filter(committee => committee.type === 0 && (this.client.house_committees || [])
            .includes(committee.id))
        ]
      }

      if (this.filter.committeeType === '1') {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList
            .filter(committee => committee.type === 1 && (this.client.senate_committees || [])
              .includes(committee.id))
        ]
      }

      if (this.filter.committeeType === '2') {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList
              .filter(committee => committee.type === 2 && (this.client.joint_committees || [])
                  .includes(committee.id))
        ]
      }

      const committeeTypeList = ['H', 'S', 'J']

      selectedCommitteeList.forEach((committee) => {
        committee.label = `${ committee.fullname } (${ committeeTypeList[committee.type] })`
        committee.isSubCommittee = false
      })

      return selectedCommitteeList
    },
    sortLegislatorsList() {
      const house = this.legislators.filter(legislator => legislator.district.district[0] === 'H')
      const senate = this.legislators.filter(legislator => legislator.district.district[0] === 'S')
      const joint = this.legislators.filter(legislator => !(legislator.district.district[0] === 'H' || legislator.district.district[0] === 'S'))
      return [
        ...this.checkAndSortArray(house, 'House'),
        ...this.checkAndSortArray(senate, 'Senate'),
        ...this.checkAndSortArray(joint, 'Joint')
      ]
    },
  },
  methods: {
    getTaskLabel(task) {
      const { name } = task || {}
      const labelList = {
        'Currency': 'Donations',
      }
      return labelList[name] || name
    },
    checkAndSortArray(array, name) {
      if (array.length) {
        array.sort((a, b) => a.lastname > b.lastname ? 1 : -1)
        array.push({ divider: true })
        array.unshift({ header: name })
      }
      return array
    },
    fillLegislator() {
      this.filter.legislators = !this.filter.isSpecificLegislatorsOnly ? this.legislators : [];
    },
    setFilterLegislators() {
      if (!this.filter.all && this.filter.isDistricts !== null && this.filter.committeeType === '0') {
        this.filter.legislators = this.legislators.filter(legislator => legislator.district.district[0] === 'H')
      } else if (!this.filter.all && this.filter.isDistricts !== null && this.filter.committeeType === '1') {
        this.filter.legislators = this.legislators.filter(legislator => legislator.district.district[0] === 'S')
      } else if (!this.filter.all && this.filter.isDistricts !== null && this.filter.committeeType === '2') {
        this.filter.legislators = this.legislators.filter(legislator => !(legislator.district.district[0] === 'H' || legislator.district.district[0] === 'S'))
      } else if (this.filter.all) {
        this.filter.legislators = this.legislators
      }
    },
    onSelectCommittee(committee, sub = false) {
      if (sub) {
        if (this.filter.selectedSubCommitteeIdList.includes(committee.id)) {
          this.filter.selectedSubCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedSubCommitteeIdList, committee.id)
        } else {
          this.filter.selectedSubCommitteeIdList.push(committee.id)
          this.filter.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedCommitteeIdList, committee.id)
        }
      } else {
        if (this.filter.selectedCommitteeIdList.includes(committee.id)) {
          this.filter.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedCommitteeIdList, committee.id)
        } else {
          this.filter.selectedCommitteeIdList.push(committee.id)
        }
      }
      this.onFilterLegislators()
    },
    onSelectCommitteeIdList(arr, id) {
      return arr.filter(_committeeId => _committeeId !== id)
    },
    onFilterLegislators(isReset = false) {
      this.loading = true
      if(isReset) {
        this.filter.selectedCommitteeIdList = [];
        this.filter.selectedSubCommitteeIdList = [];
      }

      if (this.filter.all) {
        this.filter.isDistricts = null
        this.filter.committeeType = null;
      } else {
        this.filter.isDistricts = true
      }

      this.filter.legislators = this.legislators.filter((legislator) => {
        if (this.filter.selectedCommitteeIdList.length === 0 && this.filter.selectedSubCommitteeIdList.length === 0) {
          if (!this.filter.all) {
              if (this.filter.committeeType === "0") {
                  return legislator.district.district[0] === 'H';
              } else if (this.filter.committeeType === '1') {
                  return legislator.district.district[0] === 'S';
              } else if (this.filter.committeeType === '2') {
                  return !(legislator.district.district[0] === 'H' || legislator.district.district[0] === 'S');
              }
          } else {
              return true;
          }
        }

        if (!legislator.legislator_committees || legislator.legislator_committees.length < 1) return false

        const subCommitteeIds = legislator.legislator_committees
          .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 1))
          .map(legislatorCommittee => legislatorCommittee.committee_id)

        const subFilter = this.filter.selectedSubCommitteeIdList.find(sub => subCommitteeIds.includes(sub))
        if (subFilter) return true

        const committeeId = legislator.legislator_committees
          .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 0))
          .map(legislatorCommittee => legislatorCommittee.committee_id)

        const mainFilter = this.filter.selectedCommitteeIdList.find(committee => committeeId.includes((committee)))
        if (mainFilter) return true

        if (subCommitteeIds.length === 0 && committeeId.length === 0) return false
      })
        .filter((legislator) => {
        if (this.filter.vip) {
          return legislator.vip
        }
        return legislator
      })
        .filter((legislator) => {
          if (this.filter.lameDuck) {
            return legislator.lame_duck
          }
          return legislator
        })
      this.loading = false
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    removeLegislator(index, array, item) {
      swal({
        title: `Remove ${ item.fullname }?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#9E9E9E',
        confirmButtonText: '&#10003 Remove',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.filter.exceptLegislators.push(item)
          array.splice(index, 1)
        }
      })
    },
    removeExceptLegislator(index, array) {
      array.splice(index, 1)
    },
    clearFilter() {
      this.filter.legislators = []
      this.filter.exceptLegislators = []
      this.filter.vip = false
      this.filter.lameDuck = false
      this.filter.tasks = _.cloneDeep(this.filterTasks)
      this.filterTasksData = _.cloneDeep(this.filterTasks)
      this.refreshMembers()
      this.filter.tasks = {}
    },
    refreshMembers() {
      this.isFilterOpen = false
      this.$emit('refreshAdvocates', this.filter)
    },
    filterMenu(id) {
      this.showMenu = false
      if (this.filterTasksData[id]) {
        this.filterTasksData[id] = false
        this.selectedFilter(id, false)
      }
      else {
        this.filterTasksData[id] = true
        this.selectedFilter(id, 'All')
      }
      this.showMenu = true
    },
    toggleFilterMenu(id) {
      this.showMenu = false
      this.filterTaskMenu[id] = !this.filterTaskMenu[id]
      this.showMenu = true
    },
    selectedFilter(id, value) {
      value === 'Uncompleted' ? this.filters[id] = 'Not done' : this.filters[id] = value;
      this.filterTaskMenuData[id]['selected'] = value
      value !== false ? this.filter.tasks[id] = this.filters[id] : delete this.filter.tasks[id]
      this.toggleFilterMenu(id)
    }
  },
  async mounted() {
    for (const key in this.filterTaskMenuData) {
      this.filterTaskMenu[key] = false;
      if(key === 'Uncompleted') {
        this.filters['Not done'] = false;
      } else {
        this.filters[key] = false;
      }
    }
  }
}
</script>

