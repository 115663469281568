<template>
  <div style="height: 100%" class="message_layout">
    <client-settings-modal v-if="userClient"></client-settings-modal>
    <profile-modal></profile-modal>
    <app-toolbar class="header"></app-toolbar>
    <app-sidebar v-if="!isMember && !isProfileUpdate"></app-sidebar>
    <v-main app>
      <v-flex class="d-flex align-center justify-end flex-wrap">
      <v-btn
        class="ml-2"
        color="primary"
        small
        @click="$refs.LargeSendMessage.toggle()"
        :disabled="!client"
      >
        Send Message
      </v-btn>
      <br><br></v-flex>
      <v-tabs
        v-model="currentTab"
        fixed-tabs
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        :show-arrows="isMobile"
      >
        <v-tab v-for="tab of getTabs" :key="tab.id" @click="toCurrentPage(tab.route)">
          <v-badge color="primary" class="mt-0" :value="scheduledMessagesCount" v-if="tab.name === 'Scheduled'">
            <template v-slot:badge>
              <span>{{ scheduledMessagesCount > 9 ? '9+' : scheduledMessagesCount }}</span>
            </template>
            <span>{{tab.name}}</span>
          </v-badge>
          <span v-else>{{tab.name}}</span>
        </v-tab>
      </v-tabs>
      <transition name="fade" mode="out-in" appear>
        <router-view :route="$route.path" @getScheduledMessagesCount="getScheduledMessagesCount"/>
      </transition>
    </v-main>
    <large-send-message
      ref="LargeSendMessage"
      :client="client"
      :fromPage="'Messages Page'"
    >
    </large-send-message>
  </div>
</template>

<script>

  import toolbar from '@/components/global/toolbar'
  import clientSettingsModal from '@/components/global/client-settings-modal'
  import profileModal from '@/components/global/profile-modal'
  import ClientService from '@/services/ClientService'
  import sidebar from "@/components/global/sidebar";
  import LargeSendMessage from '@/components/kp-tasks/large-message-modal'

  export default {
    name: "MessagesLayout",
    mixins: [ClientService],
    components: {
      'app-toolbar': toolbar,
      'client-settings-modal': clientSettingsModal,
      'profile-modal': profileModal,
      'app-sidebar': sidebar,
      LargeSendMessage
    },
    data: () => ({
      currentTab: 0,
      clientList: [],
      selectedClientId: '',
      scheduledMessagesCount: 0,
      schedule_messages: {
        pagination: {
          page: 1,
          itemsPerPage: 15,
          last_page: 0,
        },
        items: []
      },
      tabs: [
        { id: 0, name: 'Messages', route:  'messages-page'},
        { id: 1, name: 'Scheduled', route:  'scheduled-messages-page' },
        { id: 2, name: 'History', route:  'history-messages-page' }
      ]
    }),
    mounted () {
      this.getCurrentTab()
      if(this.isCoordinator || this.isVIP) {
        this.$intercom.boot({
          name: this.userName,
          email: this.userEmail,
          user_type: this.userRole,
          assoc_abbrev: this.userClient ? this.userClient.assoc_abbrev : '',
          assoc_profession: this.userClient ? this.userClient.profession : '',
          state: this.userClient ? this.userClient.state.state : '',
        })
      }
    },
    computed: {
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      getTabs() {
        if (this.isVIP || this.isCoordinator) {
          return this.tabs
        } else if (this.isSuperAdmin) {
          return this.tabs.splice(1, 2)
        }
      },
      isProfileUpdate(){
        return localStorage.getItem('onlyProfileUpdate')
      },
    },
    methods: {
      toCurrentPage (route) {
        this.$router.push({name: route})
      },
      getCurrentTab () {
        // Ensure that this.getTabs is an array before calling find
        const tabs = Array.isArray(this.getTabs) ? this.getTabs : [];
        
        // Find the current tab based on route name and set this.currentTab
        const currentTab = tabs.find(tab => tab.route === this.$route.name);

        // Ensure currentTab exists before trying to access its id
        if (currentTab) {
            this.currentTab = currentTab.id;

            // If the user is a super admin, adjust the currentTab
            if (this.isSuperAdmin) {
                --this.currentTab;
            }
        } else {
            // Optionally handle the case where no matching tab is found
            this.currentTab = null; // or some default value
        }
      },
      getScheduledMessagesCount (total) {
        this.scheduledMessagesCount = total
      }
    }
  }
</script>
