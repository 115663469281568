<template>
  <v-dialog max-width="500" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <span class="title">Add/Edit Donation</span>
      </v-card-title>
      <v-divider />
      <v-card-text class="my-3">
        <v-text-field
          v-model="donation.amount"
          :error-messages="isValidatedAmount"
          label="Amount"
          prefix="$"
          :counter="25"
        >
        </v-text-field>
        <v-select
            label="From?"
            hide-details
            item-text="displayText"
            item-value="actualValue"
            :items="types"
            v-model="donation.type"
            class="pb-3"
          >
          </v-select>
          <v-autocomplete
             label="Donor"
             item-text="fullname"
             item-value="id"
             :items="advocates"
             v-model="donation.donor_id"
             v-if="donation.type === 'Other' && advocates && advocates.length > 0">
             <template v-slot:selection="props">
               <v-tooltip bottom>
                 <template v-slot:activator="{ on }">
                     {{ props.item.fullname }}
                 </template>
                 <span>{{ props.item.fullname }}</span>
               </v-tooltip>
             </template>
           </v-autocomplete>
          <v-menu
            ref="dateMenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :return-value.sync="donation.donated_at"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="getFormatDate"
              label="Date of donation"
              prepend-icon="mdi-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              hide-details
              class="pb-3"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="donation.donated_at"
            no-title
            scrollable
            @change="$refs.dateMenu.save(donation.donated_at)"
          >
          </v-date-picker>
        </v-menu>
        <v-text-field
          v-model="donation.comment"
          label="Comment"
          hint="Optional: nature or circumstance of donation"
          :counter="255"
        >
        </v-text-field>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-flex class="d-flex justify-end py-4">
          <v-btn
            outlined
            color="secondary"
            @click="close()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!donation.donated_at || !donation.amount"
            class="ml-3"
            @click="addDonation"
          >
            Save
          </v-btn>
        </v-flex>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import LegislatorService from '@/services/LegislatorService'
  import ClientService from "@/services/ClientService";

  export default {
    name: "addDonationModal",
    mixins: [LegislatorService, ClientService],
    props: {
      clientId: {
        default: null,
        type: Number
      },
      legislator: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data() {
      return {
        isModalOpen: false,
        donation: {
          type: '',
        },
        dateMenu: false,
        loading: false,
        advocates: [],
      }
    },
    computed: {
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      types() {
        let options = []
        if (this.legislator.member_legislators) {
          this.legislator.member_legislators.filter((kp, index) => {
            switch (kp.kp_position) {
              case "1":
                  options[0] = {displayText: `KP1 - ${kp.member.fullname}`, actualValue: 'KP1'}
                break;
            
              case "2":
                  options[1] = {displayText: `KP2 - ${kp.member.fullname}`, actualValue: 'KP2'}
                break;
            
              case "3":
                if(this.legislator.member_legislators.length == 2) {
                  options[1] = {displayText: `KP3 - ${kp.member.fullname}`, actualValue: 'KP3'}
                } else {
                  options[2] = {displayText: `KP3 - ${kp.member.fullname}`, actualValue: 'KP3'}
                }
                break; 
              default:
                break;
            }
          })
        }
        options = options.concat([{displayText: 'Other', actualValue: 'Other'}, {displayText: 'PAC', actualValue: 'PAC'}]);

        return options    
      },
      getFormatDate() {
        return this.donation.donated_at ? this.getFormattedDate(this.donation.donated_at, 'MM/DD/YYYY') : ''
      },
      isValidatedAmount () {
        return (!(+this.donation.amount) && !!this.donation.amount) ? 'Only numeric values are valid for this field' : ''
      }
    },
    methods: {
      async open(donation) {
        this.isModalOpen = true
        if(donation) {
          this.donation = donation
          if(donation.type === 'Other') {
            await this.getAdvocates()
          }
        }
      },
      async addDonation() {
        this.loading = true
        this.donation.amount = +this.donation.amount
        let payload = this.donation
        try {
          await this.addLegislatorDonation(this.clientId, this.legislator.id, payload)
          this.$emit('updateList')
          this.onCallToastr('success', 'Donation has been saved.', 'Success!')
          this.loading = false
          this.close()
        }
        catch (e) {
          this.error = 'Something went wrong!'
          this.loading = false
        }

      },
      close() {
        this.donation = {
          type: '',
        }
        this.advocates = []
        this.isModalOpen = false
      },
      getAdvocates() {
        this.isLoading = true
        const clientId = this.client && this.client.id || '';
        this.getClientMembersWithPagination(clientId, { unlimit: 1 })
          .then((response) => {
            this.advocates = response.data.data ? response.data.data : response.data
            this.isLoading = false
          })
          .catch(err => {
            this.isLoading = false
            this.handleError(err)
          })
      }
    },
    watch: {
    'donation.type'(value) {
      if(value) {
        this.advocates = []
        this.donation.donor_id = this.donation.donor_id ? this.donation.donor_id : null
        if(value === 'Other' && this.advocates.length === 0) {
          this.getAdvocates()
        } else if (value.includes("KP")) {
          const position = value.split('KP')[1]
          this.legislator.member_legislators.filter(kp => {
            if (kp.kp_position == position) {
              this.advocates = [kp.member]
              this.donation.donor_id = kp.member.id
              return
            }
          })
        }
      }
    }
  }
  }
</script>
