<template>
  <v-dialog max-width="400" v-model="isModalOpen">
    <v-form ref="newUrlForm" @submit.prevent="onAddUrl()">
      <v-card>
        <v-card-title>
          <span class="title">Add New Legislator Url</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout wrap>
            <v-flex xs12 class="px-2">
              <v-text-field 
                  label="Url Name" 
                  :rules="urlNameRules"
                  v-model="newUrl.name"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field 
                  label="Url" 
                  :rules="urlRules" 
                  v-model="newUrl.url"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import StateService from "@/services/StateService";

export default {
  name: 'NewLegislatorUrlModal',
  props: {
    stateId: {
      type: Number,
      default: 0
    }
  },
  mixins: [StateService],
  data () {
    return {
      isModalOpen: false,
      newUrl: {},
      error: '',
      isBusy: false,
    }
  },
  computed: {
    urlNameRules () {
      return [
        v => !!v || 'Url name is required.',
      ]
    },
    urlRules () {
      return [
        v => !!v || 'Url is required.',
      ]
    },
  },
  methods: {
    toggle (url) {
      this.error = ''
      if(this.$refs.newUrlForm) {
        this.$refs.newUrlForm.reset()
      }
      if (url && url.id) {
        this.newUrl = url
      }
      this.isModalOpen = true
    },
    onAddUrl () {
      if (!this.$refs.newUrlForm.validate()) return
      this.isBusy = true
      this.storeLegislatorsUrls(this.stateId, this.newUrl)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'New url has been created.', 'Success!')
          this.$emit('refresh')
          this.isModalOpen = false
        })
        .catch((err) => {
          this.handleError(err)
        })
    }
  }
}
</script>
