<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg class="pa-0">
        <v-layout wrap>
          <v-flex class="d-flex align-center">
            <h1>LegSupport Page</h1>
            <v-spacer></v-spacer>

            <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isMobile">
              <v-btn color="warning" fab dark class="my-1" @click="$refs.newUserModal.toggle()">
                <v-icon dark small>fa-user-plus</v-icon>
              </v-btn>
            </v-flex>

          </v-flex>
          <v-spacer></v-spacer>

          <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
            <v-btn color="warning" class="mr-3" small dark @click="$refs.newUserModal.toggle()" >
              <v-icon dark small left>fa-user-plus</v-icon>
              Add Team Member
            </v-btn>
          </v-flex>
        </v-layout>

        <v-data-table
          :loading="isLoading"
          class="elevation-1"
          :options.sync="pagination"
          :headers="userTableHeader"
          :items="filteredUserList"
          :custom-filter="customFilter"
          :search="search"
          sort-by="roles[0].name"
          :sort-desc="true"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-2" @click="$refs.editUserModal.toggle(item)" v-on="on">
                  <v-icon size="18" color="primary">fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Edit Account</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn :disabled="userId == item.id || item.team_owner" v-on="on" icon class="mx-2" @click="onDeleteUser(item.id)">
                  <v-icon size="18" color="error">fa-trash-alt</v-icon>
                </v-btn>
              </template>
              <span>Remove Account</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn :disabled="userId == item.id" v-on="on" v-if="!item.last_login" class="mx-2" @click="onResendInvite(item.id)" x-small color="orange" dark>Resend Invite</v-btn>
              </template>
              <span>Resend Invitation Email</span>
            </v-tooltip>
          </template>

          <template v-slot:item.active="{ item }">
            <div>
              <v-icon size="22" color="success" v-if="item.active">fa-check-circle</v-icon>
              <v-icon size="22" color="error" v-else>fa-times-circle</v-icon>
            </div>
          </template>

          <template v-slot:item.roles[0].name="{ item }">
            <v-chip v-if="item.roles[0].name == 'Leg Support'"
                    small
                    class="ma-2"
                    color="primary"
                    text-color="white"
            >
              <v-avatar left>
                <v-icon small>fa-shield-alt</v-icon>
              </v-avatar>
              {{ item.roles[0].name == 'VIP' ? 'Leg Support' : item.roles[0].name }}
            </v-chip>
          </template>

          <template v-slot:item.lastname="{ item }">
            <div class="no-line-break">{{ item.nickname }} {{ item.lastname }}</div>
          </template>

          <template v-slot:item.email="{ item }">
            <div class="no-line-break">{{ item.email }}</div>
          </template>

          <template v-slot:item.cell_phone="{ item }">
            <div class="no-line-break">{{ item.cell_phone }}</div>
          </template>

          <template v-slot:item.last_login="{ item }">
            <div class="no-line-break">{{ formatDate(item.last_login, 'MM/DD/YY h:mma') }}</div>
          </template>

          <template v-slot:no-data>
            <p class="text-xs-center mb-0">No team members yet! Click the big orange button top right.</p>
          </template>
        </v-data-table>

    <edit-user-modal ref="editUserModal"  @refresh="refresh"></edit-user-modal>
    <new-user-modal ref="newUserModal" @refresh="refresh"></new-user-modal>
  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import UserService from '@/services/UserService'

import editUserModal from '@/components/users/edit-user-modal'
import newUserModal from '@/components/users/new-user-modal'
import teamPageHelpModal from '@/components/help-modals/team-page-help-modal'
import SuperAdminService from "@/services/SuperAdminService";

export default {
  name: 'HelperPage',
  mixins: [SuperAdminService, UserService],
  components: {
    'edit-user-modal': editUserModal,
    'new-user-modal': newUserModal,
    'team-page-help-modal': teamPageHelpModal,
  },
  data () {
    return {
      isLoading: false,
      search: '',
      userList: [],
      pagination: {
        itemsPerPage: 10,
      }
    }
  },
  computed: {
    userTableHeader () {
      return [
        { text: '', value: 'action', align: 'left', sortable: false },
        { text: 'Active', value: 'active', align: 'center' },
        { text: 'Role', value: 'roles[0].name', align: 'left' },
        { text: 'Name', value: 'lastname', align: 'left' },
        { text: 'Email', value: 'email', align: 'center' },
        { text: 'Cell Phone', value: 'cell_phone', align: 'center' },
        { text: 'Last Login', value: 'last_login', align: 'center' },
      ]
    },
    filteredUserList () {
      return this.userList
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh () {
      this.isLoading = true
      this.userList = (await this.getLegSupportUsers()).data
      this.isLoading = false
    },
    customFilter(value, search, item) {
      search = search.toString().toLowerCase()
      return item.nickname.toLowerCase().includes(search) || item.lastname.toLowerCase().includes(search)
    },
    onDeleteUser (userId) {
      swal({
        title: 'Are you sure you want to delete this team member\'s account? This will not delete the member\'s profile.',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteUser(userId)
            .then(() => {
              this.onCallToastr('success', 'Account deleted.', 'Success!')
              this.refresh()
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })
    },
    onResendInvite(userId) {
      swal({
        title: 'Resend the invitation email to this team member?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.resendInvite(userId)
            .then(() => {
              this.onCallToastr('success', 'The invite has been resent.', 'Success!')
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })
    }
  }
}
</script>
