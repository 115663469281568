export default {
  status: {
    started: 'In progress',
    finished: 'Completed',
    failed: 'Failed',
  },
  'UpdateDistricts': {
    title: {
      'started': 'Update Districts job have been started',
      'finished': 'Update Districts job have been completed',
      'failed': 'Update Districts job have been failed'
    },
    info({ data }) {
      return `${data.payload.updated} districts updated.<br /> ${data.payload.skipped} of invalid addresses skipped.`
    }
  },
  'ImportMembers': {
    title: {
      'started': 'Import Members job have been started',
      'finished': 'Import Members job have been completed',
      'failed': 'Import Members job have been failed'
    },
    info({ data }) {
      return `Successfully imported ${data.payload.success} members<br /> Failed to import ${data.payload.failure} members.`
    }
  },
  'ImportLegislators': {
    title: {
      'started': 'Import Legislators job have been started',
      'finished': 'Import Legislators job have been completed',
      'failed': 'Import Legislators job have been failed'
    },
    info({ data }) {
      return `Successfully imported ${data.payload.count} legislators.`
    }
  },
  'SendMail': {
    title: {
      'started': 'Send messages job have been started',
      'finished': 'Send messages job have been completed',
      'failed': 'Send messages job have been failed'
    },
    info({ data }) {
      return `Successfully sent ${data.payload.success} messages<br /> Failed to send ${data.payload.failure} messages.`
    }
  },
  'SendScheduleMessages': {
    title: {
      'started': 'Send messages job have been started',
      'finished': 'Send messages job have been completed',
      'failed': 'Send messages job have been failed'
    },
    info({ data }) {
      return `Successfully sent ${data.payload.success} messages<br /> Failed to send ${data.payload.failure} messages.`
    }
  },
  'SendAdvocacyMessage': {
    title: {
      'started': 'Send messages job have been started',
      'finished': 'Send messages job have been completed',
      'failed': 'Send messages job have been failed'
    },
    info({ data }) {
      return `Successfully sent ${data.payload.success} messages<br /> Failed to send ${data.payload.failure} messages.`
    }
  }
}
