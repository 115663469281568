<template>
	<v-dialog v-model="modalVisible" max-width="800px" max-height="auto">
        <v-card>
			<v-card-title>
				Support
				<v-spacer></v-spacer>
				<v-btn @click="closeModal()" small icon class="c-card-title-close--popover">
				<v-icon small>mdi-close</v-icon>
				<span class="d-sr-only">Close</span>
				</v-btn>
			</v-card-title>
			<div class="d-flex align-center justify-center register-page_form__inner mt-8 overflow-hidden">
          <div
            class="register-page_form__inner_major"
            :class="isDarkTheme ? 'card-dark' : 'card-light'"
          >
            <v-layout row v-if="userClient && userClient.tariff && userClient.tariff.name === 'Professional'">
				<v-flex class="register-page_person mr-0" col-sm-4 col-md-4 col-12 v-for="(item, index) in items" :key="index">
					<v-btn @click="selectOption(item)">
						<div class="text-wrap mt-4">{{ item.title }}<p class="mt-4" v-if="item.cell">+1 888 970 1899</p></div>
					</v-btn>
				</v-flex>
            </v-layout>
            <v-layout row v-else-if="userClient && userClient.tariff && userClient.tariff.name === 'Premium'">
				<v-flex class="register-page_person mr-0" col-sm-6 col-md-6 col-12 v-for="(item, index) in items" :key="index">
					<v-btn @click="selectOption(item)">
						<div class="text-wrap">{{ item.title }}</div>
					</v-btn>
				</v-flex>
            </v-layout>
          </div>
      </div>
			<v-card-actions>
				<v-btn
					outlined
					color="secondary"
					@click="closeModal()"
				>
					Cancel
				</v-btn>          
			</v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
  import ClientService from '@/services/ClientService'
  export default {
	mixins: [ClientService],
	name: "SupportModal",
	data() {
		return {
			items: [
				{ title: 'Submit a Ticket' },
				{ title: 'Chat Now' },
				{ title: 'Call Us', cell: '(888) 970-1899',
},
			],
      		modalVisible: false,
    	};
	},
	methods: {
		openModal() {
			this.modalVisible = true;
		},
		closeModal() {
			this.modalVisible = false;
		},
		selectOption(item) {
			if (item.title === 'Submit a Ticket') {
				window.open('https://help.kpdashboard.com/en/','_blank')
			}
			if (item.title === 'Chat Now') {
				if (this.$intercom) {
					this.$intercom.show()
				}
			}
			if (item.title === 'Call Us') {
				window.location.href = `tel:${item.cell}`
			}
		},
	},
	watch: {
		'$intercom.visible'(value) {
      		let launcherEl = document.querySelector('.intercom-launcher-frame') ? document.querySelector('.intercom-launcher-frame') : false
      		if (value && launcherEl) {
      		  launcherEl.classList.add('visible')
      		  launcherEl.classList.remove('intercom-hide')
      		}
      		if (!value && launcherEl) {
      		  launcherEl.classList.remove('visible')
      		  launcherEl.classList.add('intercom-hide')
      		}
    	}
  	}
  }
</script>
<style scoped>
.register-page_form__inner_major {
	height: min-content;
}
</style>