const secretKey = "kpdashboard";
// Simple XOR encryption/decryption with a key
const xorEncryptDecrypt = (value, key = secretKey) => {
  // Convert the input value and key to strings
  const text = value.toString();
  const keyStr = key.toString();

  let result = '';
  for (let i = 0; i < text.length; i++) {
    // XOR each character with the corresponding character of the key
    const charCode = text.charCodeAt(i) ^ keyStr.charCodeAt(i % keyStr.length);
    // Convert the result back to a character and append to the result string
    result += String.fromCharCode(charCode);
  }
  return result;
};

// Encrypt
const encryptedText = (originalText) => {
  return xorEncryptDecrypt(originalText);
}

// Decrypt
const decryptedText = (encryptedText) => {
 return xorEncryptDecrypt(encryptedText);
};

const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export default {
    encryptedText,
    decryptedText,
    generateRandomString,
};
  