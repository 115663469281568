<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg>
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>Committees</h1>
        <committees-page-help-modal></committees-page-help-modal>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" :x-small="isMobile || isDevice">
        <v-btn
          color="warning"
          small
          @click="selectCommittees"
          :disabled="!client"
        >
          Select Committees
        </v-btn>
      </v-flex>
    </v-layout>
    <v-data-table
      class="elevation-1 relationships_table"
      :loading="isLoading"
      :options.sync="pagination"
      :headers="committeeTableHeader"
      :items="committeeList"
      :search="search"
    >
      <template v-slot:item.action="{ item }">
        <v-btn v-if="committeeList && committeeList.length" icon class="mx-0" @click="$refs.assignLegislatorsModal.toggle(item)">
          <v-icon size="18" color="primary">edit</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.fullname="{ item }">
        <div>{{ item.fullname }}</div>
      </template>

      <template v-slot:item.shortname="{ item }">
        <div>{{ item.shortname }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ committeeTypeList[item.type] }}</div>
      </template>

      <template v-slot:item.chair="{ item }">
        <div>{{ item.chair && item.chair.fullname }}</div>
      </template>

      <template v-slot:item.#="{ item }">
        <div>{{ item.legislator_committees.length }}</div>
      </template>


      <template v-slot:item.republicant="{ item }">
        <div class="legislators_filter__progress--left ">
          <div class="legislators_title__progress legislators_title__progress_table" v-if="progressValue">
            <v-progress-linear
              background-color="accent"
              color="error"
              :value="progressValue(item.republicans, item.democrats)"
              height="25"
            ></v-progress-linear>
            <div class="legislators_title__progress--values">
              <span>R</span>
              <span>D</span>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.rating="{ item }">
          <v-rating2 
            v-model="item.rating"
            v-if="item.rating"
            half-increments
            readonly
            show-rating
            class="legislators_committee_rating"
          >
          </v-rating2>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No committee registered yet.</p>
      </template>
    </v-data-table>

    <client-committees-modal
      ref="clientCommitteesCodal"
    ></client-committees-modal>
    <assign-legislators-modal
      @update="refresh"
      ref="assignLegislatorsModal"
      :client="client"
    ></assign-legislators-modal>
  </v-container>
</template>

<script>

  import CommitteeService from '@/services/CommitteeService'
  import ClientService from '@/services/ClientService'
  import MemberService from '@/services/MemberService'
  import assignLegislatorsModal from '@/components/committees/assign-legislators-modal'
  import committeesPageHelpModal from '@/components/help-modals/committees-page-help-modal'
  import clientCommitteesModal from '@/components/committees/client-committees-modal'
  import {mapGetters} from "vuex";
  import VRating2 from '@/components/common/rating'

  export default {
    name: "ClientCommitteesPage",
    mixins: [CommitteeService, ClientService, MemberService],
    components: {
      'committees-page-help-modal': committeesPageHelpModal,
      'assign-legislators-modal': assignLegislatorsModal,
      'client-committees-modal': clientCommitteesModal,
      VRating2,
    },
    data () {
      return {
        isLoading: false,
        search: '',
        committeeList: [],
        pagination: {
          itemsPerPage: 10
        },
      }
    },
    computed: {
      ...mapGetters('app', ['superAdminSelectedClient']),
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      committeeTableHeader () {
        return [
          { text: '', value: 'action', align: 'left', sortable: false },
          { text: 'Full Name', value: 'fullname', align: 'center', width: '20%' },
          { text: 'Short Name', value: 'shortname', align: 'center', },
          { text: 'Chamber', value: 'type', align: 'center' },
          { text: 'Party Makeup', value: 'republicant', align: 'center', sortable: false },
          { text: 'Support', value: 'rating', align: 'center' },
          {text: 'Chair', value: 'chair', align: 'center'},
          {text: '#', value: '#', align: 'center'},

        ]
      },
      committeeTypeList () {
        return [
          'House',
          'Senate',
          'Joint',
        ]
      },
    },
    mounted () {
      if (!(this.client && this.client.id)) return
      this.refresh()
    },
    methods: {
      async refresh () {
        if (!this.client || !this.client.id) return

        this.isLoading = true
        this.committeeList = (await this.getClientCommittees(this.client.id)).data
        this.isLoading = false
      },
      progressValue(republicans, democrats) {
        let total = republicans + democrats
        return Math.floor(100 * republicans / total)
      },
      selectCommittees() {
        this.$refs.clientCommitteesCodal.init()
      },
    },
    watch: {
      'client': function () {
        this.refresh()
      }
    }
  }
</script>
