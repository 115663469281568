import Vue from 'vue'

const modals = {
  list: [],
  register(key, ref) {
    this.list[key] = ref
  },
  get(key) {
    return this.list[key]
  }
}

Vue.prototype.$modals = modals
