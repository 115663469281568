import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Full Message")]),_c(VSpacer),_c(VIcon,{staticClass:"ml-3",on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,[_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VTextarea,{attrs:{"label":"Subject","rows":"3","readonly":""},model:{value:(_vm.message.subject),callback:function ($$v) {_vm.$set(_vm.message, "subject", $$v)},expression:"message.subject"}})],1),_c(VFlex,{staticClass:"mt-3",attrs:{"xs12":""}},[_c('div',{staticClass:"mb-1 caption grey--text"},[_vm._v("Message")]),_c('div',{staticClass:"message-container",domProps:{"innerHTML":_vm._s(_vm.message.message)}})])],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},nativeOn:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }