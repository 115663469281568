<template>
  <div>
    <v-list class="pa-0">
      <template v-for="(item, key) in helpMenuItems()">
          <v-list-item
              :key="key"
              :ripple="false"
              :class="{ 'active': item.link === $route.name }"
              class="py-2"
              @click="onClickMainMenuItem($event, item)"
          >
            <v-list-item-icon class="sidebar-icon">
              <v-icon size="24">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span>{{ item.title }}</span>
            </v-list-item-title>
          </v-list-item>
      </template>
    </v-list>
    <send-feedback-modal ref="SendFeedbackModal"></send-feedback-modal>
    <support-modal ref="SupportModal"></support-modal>
  </div>
</template>
<script>
import ClientService from '@/services/ClientService'
import UserService from '@/services/UserService'
import SendFeedbackModal from '@/components/global/send-feedback-modal'
import SupportModal from '@/components/global/support-modal'

export default {
  mixins:[ClientService, UserService],
	name: "HelpMenuItems",
  components: {
    SendFeedbackModal,
    SupportModal,
  },
  methods: {
    helpMenuItems() {
      return [
        {
          icon: 'fa-light fa-book',
          title: 'Online Documentation',
          show: true,
        },
        {
          icon: 'fa-light fa-square-question',
          title: 'FAQ',
          show: true,
        },
        {
          icon: 'mdi mdi-chart-multiple',
          title: 'Case Studies',
          show: true,
        },
        {
          icon: 'mdi mdi-message-alert-outline',
          title: 'Send Feedback',
          show: this.isSuperAdmin || this.isVIP || this.isCoordinator,
        },
        {
          icon: 'mdi mdi-face-agent',
          title: 'Contact Support',
          show: true,
        },
      ]
    },
    onClickMainMenuItem (event, mainItem) {
      if (mainItem.title === 'FAQ') {
        window.open('https://help.kpdashboard.com/en/','_blank')
      }
      if (mainItem.title === 'Online Documentation') {
        window.open('https://help.kpdashboard.com/en/','_blank')
      }
      if (mainItem.title === 'Case Studies') {
        window.open('https://kpdashboard.com/resources/blog/13-case-study','_blank')
      }
      if (mainItem.title === 'Send Feedback') {
        this.$refs.SendFeedbackModal.openModal()
      }
      if (mainItem.title === 'Contact Support') {
        if(this.userClient && this.userClient.tariff.name === 'Essential') {
          if (this.$intercom) {
            this.$intercom.show()
          }
        } else {
          this.$refs.SupportModal.openModal()
        }
      }
    },
  },
  watch: {
		'$intercom.visible'(value) {
      		let launcherEl = document.querySelector('.intercom-launcher-frame') ? document.querySelector('.intercom-launcher-frame') : false
      		if (value && launcherEl) {
      		  launcherEl.classList.add('visible')
      		  launcherEl.classList.remove('intercom-hide')
      		}
      		if (!value && launcherEl) {
      		  launcherEl.classList.remove('visible')
      		  launcherEl.classList.add('intercom-hide')
      		}
    	}
  	}
}
</script>