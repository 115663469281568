<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="w-100">
  
  <!-- boolean yes/no fields -->
      <v-row v-if="dataType === 'Boolean'" justify="center" align="center" class="fill-height">
        <v-col cols="auto" class="text-center">
          <v-icon color="success" v-if="params.value === 'YES'">check_circle</v-icon>
            <span v-else-if="params.value === undefined">---</span>
          <v-icon color="error" v-else>cancel</v-icon>
        </v-col>
      </v-row>
    
    <!-- donation field -->
    <v-row justify="center" align="center" class="fill-height" v-if="dataType === 'Currency'">
    <v-col cols="auto" class="text-center">
    <!-- if there is no donation yet in this Session, show this tooltip and icon -->
      <v-tooltip bottom v-if="params.value==0" max-width="450">
        <template v-slot:activator="{ on }">
          <v-icon size="20" v-on="on" class="justify-center align-center" color="primary">fa-thin fa-money-check-dollar-pen</v-icon>
        </template>
          <span>Click to add a Session donation</span>
      </v-tooltip>
      <!-- if task not assigned, show "---" -->
      <span v-else-if="params.value === undefined">---</span>
      <!-- if there is a donation, show this tooltip and amount -->
        <v-tooltip bottom v-if="params.value" max-width="450">
          <template v-slot:activator="{ on }">
            <span v-on="on" v-if="params.value" >{{  params.value | currency  }}</span>
          </template>
          <span>Click to add or edit Session's donations</span>
        </v-tooltip>
      </v-col>
    </v-row>
    
    <!-- note field -->
    <v-row justify="center" align="center" class="fill-height" v-if="dataType === 'Notes'">
    <v-col cols="auto" class="text-center">
    <!-- if there are notes yet in this Session, show this tooltip and icon with tooltip to show the note -->
      <v-tooltip bottom v-if="params.value" max-width="450">
        <template v-slot:activator="{ on }">
          <v-icon color="primary" v-on="on">note</v-icon>
        </template>
        <span>{{ params.value }}</span>
      </v-tooltip>
      <!-- if there is not a note, show this tooltip and icon -->
      <v-tooltip v-if="params.value==0" bottom max-width="450">
        <template v-slot:activator="{ on }">
      <v-icon v-if="params.value==0" size="20" color="primary" v-on="on">fa-thin fa-pen-to-square</v-icon>
      </template>
        <span>Click to add a note</span>
      </v-tooltip>
      </v-col>
    </v-row>
    
    <!-- string or text fields -->
    <v-row justify="center" align="center" class="fill-height" v-if="dataType === 'String'">
    <v-col cols="auto" class="text-center">
    <v-tooltip bottom v-if="params.value == 0">
        <template v-slot:activator="{ on }">
          <v-icon size="20" v-if="params.value == 0" v-on="on" class="justify-center align-center" color="primary">fa-thin fa-pen-field</v-icon>
        </template>
          <span>Double click to add text</span>
      </v-tooltip>
      <span v-if="params.value === undefined">---</span>
      {{ params.value }}
      </v-col>
    </v-row>
    
    <!-- final vote fields -->
    <v-row justify="center" align="center" class="fill-height" v-if="dataType === 'Select' && fieldName === 'F-Vote'">
    <v-col cols="auto" class="text-center">
      <v-tooltip bottom v-if="fVoteData">
        <template v-slot:activator="{ on }">
          <v-icon :color="fVoteData.color" v-text="fVoteData.icon" v-on="on" v-if="fVoteData.icon"></v-icon>
          <v-chip small v-on="on" v-else>{{ fVoteData.text }}</v-chip>
        </template>
        <span>{{ fVoteData.text }}</span>
      </v-tooltip>
      </v-col>
    </v-row>
    
    <!-- predicted vote fields -->
    <v-row justify="center" align="center" class="fill-height" v-if="dataType === 'Select' && fieldId === 5">
    <v-col cols="auto" class="text-center">
      <v-tooltip bottom v-if="voteData">
        <template v-slot:activator="{ on }">
          <v-icon v-if="voteData.icon" :color="voteData.color" v-on="on">{{ voteData.icon }}</v-icon>
          <v-chip small v-on="on" v-else>{{ voteData.text }}</v-chip>
        </template>
        <span>{{ voteData.text }}</span>
      </v-tooltip>
      </v-col>
    </v-row>
    
    <!-- Select (non-vote) fields -->
    <v-row justify="center" align="center" class="fill-height" v-if="dataType === 'Select' && fieldName !== 'F-Vote' && fieldId !== 5">
    <v-col cols="auto" class="text-center">
      <v-tooltip bottom v-if="params.value == 0">
        <template v-slot:activator="{ on }">
          <v-icon size="20" v-if="params.value == 0" v-on="on" class="justify-center align-center" color="primary">fa-thin fa-list-dropdown</v-icon>
        </template>
          <span>Double click to select an option</span>
      </v-tooltip>
        <span v-if="params.value === undefined">---</span>
        <v-tooltip v-if="params.value != 0">
          <template v-slot:activator="{ on }">
            <span v-on="on" >{{ params.value }}</span>
          </template>
          <span>Double click to edit</span>
      </v-tooltip>
      </v-col>
    </v-row>
    <!-- end fields -->
    
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  computed: {
    dataType () {
      if (!this.params.node.data) return null
      return this.params.colDef.dataType.data_type
    },
    fieldName () {
      return this.params.colDef.headerName
    },
    fieldId () {
      return this.params.colDef.dataType.id
    },
    currencyData() {
      return this.params.value == 0 ? ' ' : this.params.value
    },
    voteData () {
      if (this.dataType !== 'Select' || this.fieldId !== 5) return null
      const optionList = [
        {
          icon: 'thumb_up',
          color: 'green',
          text: 'Supporting',
        },
        {
          icon: 'thumb_up',
          color: '#81C784',
          text: 'Inclined to Support',
        },
        {
          text: 'Neutral',
        },
        {
          icon: 'thumb_down',
          color: '#E57373',
          text: 'Inclined to Oppose',
        },
        {
          icon: 'thumb_down',
          color: 'red',
          text: 'Opposing',
        },
      ]
      return optionList.find(option => option.text === this.params.value)
    },
    fVoteData () {
      if (this.dataType !== 'Select' || this.fieldName !== 'F-Vote') return null
      const optionList = [
        {
          icon: 'thumb_up',
          color: 'green',
          text: 'Yea',
        },
        {
          icon: 'thumb_down',
          color: 'red',
          text: 'Nay',
        },
        {
          text: 'Abstain',
        },
      ]
      return optionList.find(option => option.text === this.params.value)
    }
  }
})
</script>
