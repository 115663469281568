<template>
  <div>
    <v-form ref="emailForm">
      <v-layout row wrap class="mt-2">
        <v-flex xs12 class="px-2">
          <v-text-field
            outlined
            dense
            v-model="from"
            label="From*"
            :rules="fromRules"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm8 class="px-2">
          <v-text-field
            outlined
            dense
            v-show="!isPreview"
            v-model="subject"
            :rules="subjectRules"
            id="subject"
            label="Subject*"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            v-show="isPreview"
            :value="formattedSubject"
            label="Subject*"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm4 class="px-2">
          <v-select
            outlined
            dense
            v-model="subjectVariable"
            :items="variableSubjectList"
            label="Subject Variables"
            class="subject_variable-select"
          >
            <template v-slot:item="data">
              <v-list-item-content  @click="onAddVariableToSubject(data.item)" v-text="data.item"></v-list-item-content>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs12 sm8 class="d-flex align-center preview-switch px-2">
          <div class="caption grey--text">Message*</div>
          <v-spacer/>
          <v-btn
              v-if="badVariables"
              x-small
              outlined
              color="primary"
              @click="onCheckVariables"
          >
            Show Error
          </v-btn>
          <v-switch
            color="primary"
            class="d-inline-block px-2 pt-0 sms-preview-switch"
            v-model="isPreview"
            :label="isPreview ? 'Edit' : 'Show Preview'"
            :loading="isLoading"
            hide-details
            :disabled="isLoading || (recipients && !recipients.length)"
            @change="onPreviewMessage"
          >
          </v-switch>
        </v-flex>
        <v-flex xs12 sm4 class="px-2">
          <v-select
            outlined
            v-model="bodyVariable"
            :items="variableList"
            label="Body Variables"
            hide-details
            dense
          >
            <template v-slot:item="data">
              <v-list-item-content  @click="onAddVariable(data.item)" v-text="data.item"></v-list-item-content>
            </template>
          </v-select>
        </v-flex>
        <v-flex class="mt-2 full-width">
          <p v-if="badVariables" class="red--text mb-0">You have bad variables! Please click on 'Show Error' button and fix them.</p>
          <div v-if="!isPreview && startEditor" class="tiny_editor">
            <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
            <editor
                v-model="message"
                :api-key="TINY_MCE_API_KEY"
                :plugins="tinyPlugins"
                :toolbar="tinyToolbars"
                :init="tinyInit"
                :disabled="disabledEditor"
                @input="inputEmail"
            />
          </div>
          <div class="mt-1">
            <editor
                v-if="isPreview && startEditor"
                :value="formattedMessage"
                disabled
                :api-key="TINY_MCE_API_KEY"
                :plugins="tinyPlugins"
                :toolbar="tinyToolbars"
                :init="tinyInit"
            />
          </div>
          <span class="text-counter">
          {{ finalMessageLength }}
        </span>
        </v-flex>
        <v-flex class="px-2">
            <div class="d-flex align-baseline justify-space-between">
          <div class="full-width">
            <v-flex
              v-if="!isFilesInput"
              class="d-flex align-baseline"
              @click="isFilesInput = !isFilesInput"
            >
              <v-icon color="primary" class="pt-4 icon_files">mdi-paperclip</v-icon>
              <a>Attach files</a>
            </v-flex>
            <v-flex
              v-if="isFilesInput && !isUploadingFiles"
              class="d-flex align-baseline"
              @click="removeAllAttachedFiles"
            >
              <v-icon class="pt-4 icon_files">mdi-paperclip</v-icon>
              <a class="v-label red--text">Cancel</a>
            </v-flex>
            <v-file-input
              v-if="isFilesInput && !isUploadingFiles"
              v-model="files"
              :rules="fileRules"
              :show-size="1000"
              placeholder="Select your files"
              label="Max size of all files is 15MB"
              color="primary"
              class="pt-4"
              :clearable="false"
              outlined
              multiple
              counter
              dense
              @change="filesInputChanged"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  color="primary"
                  dark
                  label
                  small
                  close
                  @click:close="removeAttachedFile(index)"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            <div v-if="maxFileSize && isFilesInput">
              <v-alert
                type="info"
                outlined
                text
              >
                To reduce the size of the files, you can use
                <a href="https://shrinkpdf.com/">shrinkpdf.com</a> or
                <a href="https://imagecompressor.com/">imagecompressor.com</a>
              </v-alert>
            </div>
            <v-flex v-if="isUploadingFiles" class="pa-5 d-flex align-center justify-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
              <div><span class="ml-3">Please wait, uploading files...</span></div>
            </v-flex>
            <v-flex v-if="attachedFiles != false" class="pt-5 pb-2">
              <div>
                <span>
                  Attached files: {{ attachedFiles.length + ' files (' + bytesToMb(attachedFilesSize) + ' MB)' }}
                </span>
              </div>
              <v-chip
                v-for="(attachedFile, index) in attachedFiles"
                :key="index"
                class="mr-2 mt-2"
                close
                @click:close="removeAttachedScheduledFile(attachedFile, index)"
              >
                {{ `${attachedFile.name} (${bytesToMb(attachedFile.size)} MB)` }}
              </v-chip>
            </v-flex>
          </div>
          <a style="min-width: fit-content;" v-if="canSendTest" @click="onOpenTestMessageModal">
            Send a test...
          </a>
        </div>
        </v-flex>
      </v-layout>
    </v-form>
    <SuperAdminTestMessageModal
      ref="SuperAdminTestMessageModal"
      :isEmail="true"
      :recipient="recipients && recipients[0]"
      :message="message"
      :subject="subject"
      :files="files"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import AppService from '@/services/AppService'
import ClientService from "@/services/ClientService";
import ShorterUrl from "@/mixins/shorterUrl";
import SuperAdminTestMessageModal from "@/components/global/super-admin-test-message-modal";
import he from "he";

export default {
  name: 'SendEmail',
  mixins: [AppService, ClientService, TinyConfig, ShorterUrl],
  components: {
    'editor': Editor,
    SuperAdminTestMessageModal
  },
  props: {
    recipients: {
      type: Array,
      default: function () {
        return []
      }
    },
    isBusy: {
      type: Boolean,
      default: false
    },
    isUploadingFiles: {
      type: Boolean,
      default: false
    },
    propMessage: {
      type: String,
      default: ''
    },
    propFrom: {
      type: String,
      default: ''
    },
    propSubject: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formattedSubject: '',
      subjectVariable: '',
      formattedMessage: '',
      isFilesInput: false,
      isPreview: false,
      startEditor: false,
      files: [],
      attachedFiles: [],
      attachedFilesSize: 0,
      currFiles: [],
      bodyVariable: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.startEditor = true
    })
  },
  computed: {
    getAttachedFiles: {
      get() {
        return this.attachedFiles
      },
      set(newValue) {
        this.$emit('onAttachedFiles', newValue)
      }
    },
    isLoading: {
      get() {
        return this.isBusy
      },
      set(newValue) {
        this.$emit('onLoading', newValue)
      }
    },
    message: {
      get() {
        return this.propMessage
      },
      set(newValue) {
        this.$emit('setMessage', newValue)
      }
    },
    subject: {
      get() {
        return this.propSubject
      },
      set(newValue) {
        this.$emit('setSubject', newValue)
      }
    },
    from: {
      get() {
        return this.propFrom
      },
      set(newValue) {
        this.$emit('setFrom', newValue)
      }
    },
    validationForm() {
      return this.$refs.emailForm.validate()
    },
    fromRules() {
      return [
        v => !!v || 'From name is required.',
      ]
    },
    subjectRules() {
      return [
        v => !!v || 'Subject is required.',
      ]
    },
    variableSubjectList() {
      return [
        '{recipient_first_name}',
        '{recipient_last_name}',
        '{assoc_abbrev}',
        '{assoc_fullname}',
      ]
    },
    variableList() {
      return [
        '{recipient_first_name}',
        '{recipient_last_name}',
        '{assoc_abbrev}',
        '{assoc_fullname}',
      ]
    },
    canSendTest() {
      const canSend = this.message && this.email.subject && this.recipients && this.recipients.length > 0;
      return canSend;
    }
  },
  methods: {
    bytesToMb(size) {
      return parseFloat(size / 1048576).toFixed(2)
    },
    onAddVariableToSubject(variable) {
      let field = document.querySelector('#subject')
      if (!this.isPreview) {
        if (field.selectionStart || field.selectionStart == '0') {
          let startPos = field.selectionStart;
          let endPos = field.selectionEnd;
          this.formattedSubject = field.value.substring(0, startPos)
              + variable
              + field.value.substring(endPos, field.value.length);
          this.subject = field.value.substring(0, startPos)
              + variable
              + field.value.substring(endPos, field.value.length);
        } else {
          this.subject = variable
          this.formattedSubject = variable
        }
      }
    },
    onAddVariable(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);
    },
    async onPreviewMessage() {
      if (!this.isPreview || this.message === '') {
        return
      }
      this.isLoading = true

      let payload = {
        message: this.message,
      }

      if (this.recipients && this.recipients[0].is_user) {
        payload.user_id = this.recipients[0].id
      } else {
        payload.member_id = this.recipients[0].id
      }

      if (this.message) {
        this.formattedMessage = (await this.getAppFormattedMessage(payload)).data
      }

      if (this.subject) {
        payload.message = this.subject
        this.formattedSubject = (await this.getAppFormattedMessage(payload)).data
      }
      this.isLoading = false
    },
    filesInputChanged() {
      this.currFiles = [
        ...this.currFiles,
        ...this.email.files
      ]
      this.currFiles = [...new Set(this.currFiles)]
      this.files = [...new Set(this.currFiles)]
      this.$nextTick().then(() => {
        this.$emit('maxFileSize', this.maxFileSize)
      })
    },
    onCheckVariables() {
      this.checkVariablesForEditor(this.message, this.variableList)
    },
    async inputEmail() {
      this.$nextTick(async () => {
        this.searchIncorrectVariables(this.message, this.variableList)

        const newContent = await this.parsUrl(this.message)

        if (newContent !== this.message) {
          this.message = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    removeAttachedFile(index) {
      this.files.splice(index, 1)
      this.currFiles.splice(index, 1)
    },
    removeAllAttachedFiles() {
      this.currFiles = []
      this.files = []
      this.isFilesInput = false
    },
    async removeAttachedScheduledFile(file, index) {
      try {
        await this.deleteScheduledFile(file.id)
        this.getAttachedFiles.splice(index, 1)
      } catch (e) {
        console.error(e)
        this.onCallToastr('error', 'File hasn`t been deleted', 'Error!')
      }
    },
    onOpenTestMessageModal() {
      this.$refs.SuperAdminTestMessageModal.open()
    },
    finalMessageLength() {
      let recipientFirstName = ''
      let recipientLastName = ''
      let assocAbbrev = ''
      let assocFullName = ''

      this.recipients.forEach(recipient => {
        if (recipientFirstName.length < (recipient.nickname || '').length) recipientFirstName = recipient.nickname
        if (recipientLastName.length < (recipient.lastname || '').length) recipientLastName = recipient.lastname
        if (assocAbbrev.length < (recipient.client.assoc_abbrev || '').length) assocAbbrev = recipient.client.assoc_abbrev
        if (assocFullName.length < (recipient.client.association || '').length) assocFullName = recipient.client.association
      })

      let message = this.message || ''
      message = message.replace(/{recipient_first_name}/g, recipientFirstName)
      message = message.replace(/{recipient_last_name}/g, recipientLastName)
      message = message.replace(/{assoc_abbrev}/g, assocAbbrev)
      message = message.replace(/{assoc_fullname}/g, assocFullName)
      message = this.html2Text(message)
      return message.length
    },
    html2Text(html) {
      let find = /(<br>|<\/p><p>)/,
        re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
  },
  watch: {
    badVariables: function () {
      this.$emit('badVariables', this.badVariables)
      this.searchIncorrectVariables(this.message, this.variableList)
      if (!this.badVariables) {
        this.checkVariablesForEditor(this.message, this.variableList)
      }
    },
    'message': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('finalMessageLength', this.finalMessageLength)
      }
    },
  }
}
</script>
