import axios from 'axios'

export default {
  methods: {
    getUserSessions (params) {
      return axios.get('/user-sessions', {params})
    },
    createUserSession (payload) {
      return axios.post('/user-sessions', payload)
    },
    updateUserSession (payload) {
      return axios.put(`/user-sessions/${payload.id}`, payload)
    },
    deleteUserSession (userId) {
      return axios.delete(`/user-sessions/${userId}`)
    }
  }
}
