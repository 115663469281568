<template>
  <v-dialog max-width="600" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <span class="title">Sent Message</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12 class="mt-3 px-2">
            <div class="message-container_sent" v-html="message"></div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click.native="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "SentMessageModal",
    data () {
      return {
        isModalOpen: false,
        message: ''
      }
    },
    methods: {
      open(message) {
        this.isModalOpen = true
        this.message = message
      },
      close() {
        this.isModalOpen = false
        this.message = ''
      }
    }
  }
</script>
