<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-menu
    ref="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    offset-y
    :disabled="disabled"
    max-width="290px"
    min-width="290px"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        prepend-icon="event"
        readonly outlined
        :label="label"
        :value="fieldValue"
        :rules="rules"
        v-on="on"
        :dense="dense"
      ></v-text-field>
    </template>
    <v-date-picker color="primary" scrollable :value="value" :min="minDate" :max="maxDate" @input="onChangeDate">
      <v-spacer v-if="!required"></v-spacer>
      <v-btn text color="primary" @click="onReset" v-if="!required">Reset</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => {
        return []
      }
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    isNormalDate: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: "MMM D, YYYY"
    }
  },
  computed: {
    maxDate() {
      return this.max ? this.max : ''
    },
    minDate() {
      return this.min ? this.min : ''
    },
    fieldValue(){
      if(this.isNormalDate) {
        return this.value? moment(this.value).format(this.format):null;
      } else {
        return this.formatDate(this.value);
      }
    },
  },
  methods: {
    onChangeDate (date) {
      this.$refs.menu.save(date)
      this.$emit('input', date)
    },
    onReset () {
      this.$refs.menu.save('')
      this.$emit('input', '')
    }
  }
}
</script>
