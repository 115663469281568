import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VForm,{ref:"tasksForm"},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Session Tasks (*optional)")]),_c(VSpacer),_c(VIcon,{staticClass:"ml-3",on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("close")])],1),_c(VDivider),_c(VList,{attrs:{"three-line":""}},[_vm._l((_vm.filteredTaskList),function(task){return [_c(VListItem,{key:task.name},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(task.name)+" ("+_vm._s(_vm.formatDate(task.deadline))+")")]),_c(VListItemSubtitle,[_c('em',[_vm._v(_vm._s(task.tooltip))])])],1)],1),_c(VDivider)]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }