<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-layout align-center class="mr-2">
    <span class="no-line-break cursor-pointer">{{ node.key }}</span>
    <span class="mx-1" v-if="coordinatorMember">-</span>
    <span v-if="coordinatorMember">{{ coordinatorMember.fullname }}</span>
    <v-tooltip bottom v-if="coordinatorMember">
      <template v-slot:activator="{ on }">
        <a :href="'tel:' + coordinatorMember.cell" v-on="on">
          <v-icon color="primary" size="16" class="mx-1">phone_iphone</v-icon>
        </a>
      </template>
      <span>{{ coordinatorMember.cell }}</span>
    </v-tooltip>
    <v-tooltip bottom v-if="coordinatorMember">
      <template v-slot:activator="{ on }">
        <a :href="'mailto:' + coordinatorMember.email" v-on="on">
          <v-icon color="primary" size="16">email</v-icon>
        </a>
      </template>
      <span>{{ coordinatorMember.email }}</span>
    </v-tooltip>
  </v-layout>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  computed: {
    node () {
      return this.params.node
    },
    region() {
      return this.params.node.allLeafChildren[0].data.fullDistrict.region
    },
    coordinatorMember () {
      let data = this.region.name == this.params.node.key ? this.region : ''
      return data ? data.member : '--'
    }
  }
})
</script>
