<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>{{ client ? `${ client.assoc_abbrev } ` : '' }} Team Members</h1>
        <team-page-help-modal></team-page-help-modal>
        <v-spacer></v-spacer>

        <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isMobile">
          <v-btn color="warning" fab dark class="my-1" @click="$refs.newUserModal.toggle()"
                 :disabled="!(client && client.id)">
            <v-icon dark small>fa-user-plus</v-icon>
          </v-btn>
          <v-btn color="primary" class="my-1" block small :loading="isLoading" @click="refresh">
            Refresh
            <v-icon dark class="ml-2">refresh</v-icon>
          </v-btn>
        </v-flex>

      </v-flex>
      <v-spacer v-if="!isMobile"></v-spacer>

      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
        <v-btn icon color="primary" :loading="isLoading" @click="refresh">
          <v-icon dark>refresh</v-icon>
        </v-btn>
        <v-btn color="warning" class="mr-3" small dark @click="click" :disabled="!(client && client.id)">
          <v-icon dark small left>fa-user-plus</v-icon>
          Add Team Member
        </v-btn>
      </v-flex>
    </v-layout>
    <div v-if="client && client.id">
      <v-layout wrap>
        <v-flex md10 class="d-flex align-center flex-wrap">
          <v-text-field
            prepend-inner-icon="search"
            label="Search Team Members"
            clearable
            outlined
            hide-details
            dense
            class="search-input search-input_members"
            v-model="search"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-data-table
        :loading="isLoading"
        class="elevation-1"
        :options.sync="pagination"
        :headers="userTableHeader"
        :items="filteredUserList"
        :custom-filter="customFilter"
        :search="search"
        sort-by="roles[0].name"
        :sort-desc="true"
        hide-default-footer
        disable-pagination
      >

        <template v-slot:item.lastname="{ item }">
          <div class="no-line-break">{{ item.nickname }} {{ item.lastname }} <v-icon class="mdi mdi-crown ma-1 mb-2" color="primary" v-if="item.team_owner"></v-icon></div>
        </template>

        <template v-slot:item.email="{ item }">
          <div class="no-line-break">{{ item.email }}</div>
        </template>

        <template v-slot:item.cell_phone="{ item }">
          <div class="no-line-break">{{ item.cell_phone }}</div>
        </template>

        <template v-slot:item.regions="{ item }">
          <div class="no-line-break" v-for="region in item.regions" :key="region.id">{{ region.name }}</div>
        </template>

        <template v-slot:item.active="{ item }">
          <div>
            <v-icon size="18" color="success" v-if="item.active">fa-check-circle</v-icon>
            <v-icon size="18" color="error" v-else>fa-times-circle</v-icon>
          </div>
        </template>

        <template v-slot:item.roles="{ item }">
          <v-chip v-if="item.roles && item.roles.length && item.roles[0].name == 'VIP' && !item.team_owner"
                  small
                  outlined
                  class="ma-2"
                  color="primary"
                  text-color="primary"
          >
            {{ item.roles[0].name == 'VIP' ? 'Admin' : item.roles[0].name }}
          </v-chip>
          <v-chip v-else-if="item.roles && item.roles.length && item.roles[0].name == 'Coordinator' && !item.team_owner"
                  small
                  outlined
                  class="ma-2"
                  color="orange"
                  text-color="orange"
          >
            {{ item.roles[0].name == 'VIP' ? 'Admin' : item.roles[0].name }}
          </v-chip>
          <v-chip v-else-if="item.team_owner"
                  small
                  outlined
                  class="ma-2"
                  color="success"
                  text-color="success"
          >
          Owner
          </v-chip>
        </template>

        <template v-slot:item.last_login="{ item }">
          <div class="no-line-break">{{ formatDate(item.last_login, 'MM/DD/YY h:mma') }}</div>
        </template>

        <template v-slot:no-data>
          <p class="text-xs-center mb-0">No team members yet! Click the big orange button top right.</p>
        </template>

        <template v-slot:item.action="{ item }">
          <div class="d-flex justify-space-between">
            <v-tooltip bottom v-if="!item.last_login">
              <template v-slot:activator="{ on }">
                <v-btn :disabled="userId == item.id" v-on="on" v-if="!item.last_login" class="mx-2"
                      @click="onResendInvite(item.id)" small color="orange" dark>Resend Invite
                </v-btn>
              </template>
              <span>Resend Invitation Email</span>
            </v-tooltip>
            <span v-else> </span>
            <v-menu
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    icon
                    small
                    dark
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                  <v-list>
                    <v-list-item
                      :disabled="!item.active"
                      @click="onAppointOwner(item)"
                      v-if="(item.roles && item.roles.length && item.roles[0].name == 'VIP' && item.active && userData.team_owner && userData.id !== item.id && item.last_login) 
                      || (isSuperAdmin && item.roles && item.roles.length && item.roles[0].name == 'VIP' && item.active && !item.team_owner && item.last_login)"
                      >
                        <v-list-item-title><v-icon size="22" class="mdi mdi-crown ma-1 mb-2" color="primary"></v-icon> Transfer Ownership</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="$refs.editUserModal.toggle(item)"
                      >
                        <v-list-item-title><v-icon size="22" color="primary" class="ma-1 mb-2">mdi mdi-pencil</v-icon> Edit Profile</v-list-item-title>
                    </v-list-item>                                                                                                                                                        
                    <v-list-item
                      v-if="userId !== item.id || !item.team_owner" 
                      @click="onDeleteUser(item.id)"
                      >
                        <v-list-item-title><v-icon size="22" color="error" class="ma-1 mb-2">mdi mdi-trash-can-outline</v-icon> <span class="text-red">Delete</span></v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.last_login && (isSuperAdmin || (isVIP && userData.email !== item.email))" 
                      @click="onResendPassword(item)"
                      >
                        <v-list-item-title><v-icon size="22" color="error" class="ma-1 mb-2">fa-solid fa-lock</v-icon> <span class="text-red">Reset Password</span></v-list-item-title>
                    </v-list-item>
                  </v-list>
              </v-menu>
            </div>
        </template>
      </v-data-table>

      <edit-user-modal
        ref="editUserModal"
        :client="client"
        :member-list="memberList"
        @refresh="refresh"
      ></edit-user-modal>
      <new-user-modal
        ref="newUserModal"
        :client="client"
        :member-list="memberList"
        @refresh="refresh"
      ></new-user-modal>
    </div>
  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import ClientService from '@/services/ClientService'
import UserService from '@/services/UserService'

import editUserModal from '@/components/users/edit-user-modal'
import newUserModal from '@/components/users/new-user-modal'
import teamPageHelpModal from '@/components/help-modals/team-page-help-modal'
import { mapGetters } from "vuex";
import AuthService from "@/services/AuthService";

export default {
  name: 'UsersPage',
  mixins: [ClientService, UserService, AuthService],
  components: {
    'edit-user-modal': editUserModal,
    'new-user-modal': newUserModal,
    'team-page-help-modal': teamPageHelpModal,
  },
  data() {
    return {
      isLoading: false,
      search: '',
      memberList: [],
      userList: [],
      pagination: {
        itemsPerPage: 10,
      }
    }
  },
  filters: {
    dateFormatter(value, format) {
      if (!value) return ''
      if (!format) format = 'MMM D, YYYY'
      const valueToFormat = value.replace(' ', 'T')

      const jsDate = new Date(valueToFormat)
      return moment(jsDate).format(format)
    },
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    userTableHeader() {
      return [
        { text: 'Name', value: 'lastname', align: 'left', width: "180px" },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Cell Phone', value: 'cell_phone', align: 'left', width: "180px" },
        { text: 'Region', value: 'regions', align: 'left', width: "150px" },
        { text: 'Status', value: 'active', align: 'center' },
        { text: 'Role', value: 'roles', align: 'left' },
        { text: 'Last Login', value: 'last_login', align: 'left', width: "160px" },
        { text: 'Action', value: 'action', align: 'center', sortable: false },
      ]
    },
    filteredUserList() {
      return this.userList
    }
  },
  async mounted() {
    await this.refresh()
  },
  methods: {
    click() {
      this.$refs.newUserModal.toggle()
    },
    async refresh() {
      if (!(this.client && this.client.id)) return

      this.isLoading = true
      this.userList = (await this.getClientUsers(this.client.id)).data
      this.memberList = (await this.getClientMembers(this.client.id)).data
        .filter(member => member.active)
        .filter(member => member.regions && member.regions.length)
      this.isLoading = false
    },
    customFilter(value, search, item) {
      search = search.toString().toLowerCase()
      return item.nickname.toLowerCase().includes(search) || item.lastname.toLowerCase().includes(search)
    },

    onResendPassword(user) {
      this.onCallToastr('warning', `Processing...`, 'Info!')
      this.remember({ email: user.email })
        .then((res) => {
          this.onCallToastr('success', `Reset password link has been sent to ${ user.email } address.`, 'Success!')
        })
        .catch((err) => {
          this.handleError(err)
        })

    },
    onAppointOwner(user) {
      swal({
        title: 'Are you sure? There can only be one owner. This action will also revoke the previous owner.',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          user.team_owner = true
          user.role =  user.roles.length ? user.roles[0].name : 'Coordinator'
          if(this.userData.id === user.id) {
            this.$store.dispatch('auth/setUserData', {...this.userData, team_owner: true})
          } else {
            this.$store.dispatch('auth/setUserData', {...this.userData, team_owner: false})
          }
          this.updateUser(user)
            .then(() => {
              this.onCallToastr('success', 'The Owner has been changed.', 'Success!')
              this.refresh()
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })
    },
    onDeleteUser(userId) {
      swal({
        title: 'Are you sure you want to delete this team member\'s account? This will not delete the member\'s profile.',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteUser(userId)
            .then(() => {
              this.onCallToastr('success', 'Account deleted.', 'Success!')
              this.refresh()
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })
    },
    onResendInvite(userId) {
      swal({
        title: 'Resend the invitation email to this team member?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: this.appTheme.accent,
        cancelButtonColor: this.appTheme.error,
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.resendInvite(userId)
            .then(() => {
              this.onCallToastr('success', 'The invite has been resent.', 'Success!')
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })
    }
  },
  watch: {
    'client': function () {
      this.refresh()
    },
  }
}
</script>
