<template>
  <div class="alert-message" v-if="list && list.length">
    <v-alert
      v-for="message in list"
      :key="message.id"
      :type="message.style === 'none' ? 'info' : message.style"
      :color="message.style === 'none' ? 'primary' : ''"
      outlined
    >
      <span v-html="message.content"></span>
    </v-alert>
  </div>
</template>

<style>
  .alert-message {
    overflow: auto;
    max-height: 30vh;
  }

  .alert-message .v-alert__content>span p:last-child {
    margin-bottom: 0;
  }
</style>

<script>
export default {
  name: "SystemMessageAlert",
  props: {
    list: {
      type: Array,
      default: function (){
        return []
      }
    }
  }
}
</script>
