import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"750"},model:{value:(_vm.isTestMessageModalOpen),callback:function ($$v) {_vm.isTestMessageModalOpen=$$v},expression:"isTestMessageModalOpen"}},[_c(VCard,{attrs:{"loading":_vm.isTestMessageLoading}},[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Send test to "+_vm._s(!_vm.email.is_sms ? _vm.userData.email : _vm.userData.cell_phone))])]),_c(VDivider),_c(VCardText,{staticClass:"mt-3"},[_c('div',{staticClass:"mb-6"},[(_vm.formattedMessage)?_c('editor',{attrs:{"value":_vm.formattedMessage,"disabled":"","api-key":_vm.TINY_MCE_API_KEY,"init":_vm.tinyInit,"toolbar":"false"}}):_vm._e()],1),_c(VBtn,{staticClass:"mr-4",attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.isTestMessageLoading || _vm.loading,"loading":_vm.loading},nativeOn:{"click":function($event){return _vm.onSend.apply(null, arguments)}}},[_vm._v(" Send Test ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }