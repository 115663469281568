<template>
  <v-navigation-drawer class="legislator-info_modal legislator-donation_modal pa-2" fixed temporary right touchless width="650" v-model="isModalOpen">
    <v-flex class="d-flex justify-space-between mt-4 align-center" style="max-height: 50px">
      <h1>Donations</h1>
      <div>
        <v-btn
          color="primary"
          class="my-1"
          small
          fab
          dark
          :disabled="!(isVIP || isCoordinator)"
          @click="$refs.addDonationsModal.open()"
        >
        +
      </v-btn>
      </div>
    </v-flex>
    <v-data-table
      class="elevation-1"
      :items="list.items"
      :headers="donationsHeader"
      :items-per-page="pagination.itemsPerPage"
      :page.sync="pagination.page"
      :server-items-length="pagination.totalItems"
      @update:page="getDonationsList"
      @update:items-per-page="(value) => updateItemsPerPage(value)"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex flex-nowrap align-center">
          <v-btn icon class="mx-0" small >
            <v-icon
              size="18"
              color="primary"
              :disabled="!(isVIP || isCoordinator)"
              @click="$refs.addDonationsModal.open(item)"
            >
              far fa-edit
            </v-icon>
          </v-btn>
          <v-btn icon class="mx-0">
            <v-icon
              size="18"
              color="error"
              :disabled="!(isVIP || isCoordinator)"
              @click="removeDonation(item)"
            >
              far fa-trash-alt
            </v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.comment="{ item }">
        <span>{{ item.comment }}</span>
      </template>

      <template v-slot:item.amount="{ item }">
        <span>${{ formattedPrice(item.amount) }}</span>
        <h6>{{ item.type }}</h6>
      </template>


      <template v-slot:item.donated_at="{ item }">
        <span>{{ getFormattedDate(item.donated_at, 'MM/DD/YYYY') }}</span>
      </template>

    </v-data-table>
    <add-donations-modal @updateList="getDonationsList" :legislator="legislator" :clientId="clientId" ref="addDonationsModal"></add-donations-modal>
    <v-divider></v-divider>
    <v-card-actions class="mt-4">
      <v-spacer></v-spacer>
      <v-btn outlined color="secondary" @click="isModalOpen = false">Close</v-btn>
    </v-card-actions>
  </v-navigation-drawer>
</template>

<script>

  import addDonationsModal from './add-donation-modal'

  import LegislatorService from '@/services/LegislatorService'
  import swal from "sweetalert2";

  export default {
    name: "DonationModal",
    mixins: [LegislatorService],
    props: {
      clientId: {
        default: null,
        type: Number
      }
    },
    data() {
      return {
        isModalOpen:false,
        legislator: {},
        donationsHeader: [
          {text: '', value: 'action', align: 'left', sortable: false, width: '75px'},
          {text: 'Note', value: 'comment', align: 'left'},
          {text: 'Donation', value: 'amount', align: 'center', sortable: false},
          {text: 'Date', value: 'donated_at', align: 'center'},
        ],
        list: {
          items: []
        },
        pagination: {
          page: 1,
          itemsPerPage: 10,
          totalItems: 0
        }
      }
    },
    methods: {
      async open(legislator) {
        this.isModalOpen = true
        this.legislator = legislator
        await this.getDonationsList()
      },
      async getDonationsList() {
        try {
          const param = {
            allDonation: true,
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage
          }
          const response = await this.getLegislatorDonationsList(this.clientId, this.legislator.id, param)
          this.list.items = response.data && response.data.data ? response.data.data : []
          this.pagination.totalItems = response.data && response.data.total ? response.data.total : 0
        }
        catch (e) {
          console.error(e)
        }
      },
      formattedPrice(amount) {
        amount = Number.prototype.toFixed.call(parseFloat(amount) || 0, 2)
        return amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
      },
      async removeDonation(donation) {
        let result = await swal({
          title: 'Are you sure you want to delete this donation?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete',
          reverseButtons: true,
        })
        if(result.value) {
          try {
            await this.removeLegislatorDonation(this.clientId, this.legislator.id, donation.id)
            this.onCallToastr('success', 'Donation has been deleted.', 'Success!')
            await this.getDonationsList()
          }
          catch (e) {
            this.error = 'Something went wrong!'
            console.error(e)
          }
        }
      },
      updateItemsPerPage(value) {
      this.pagination.itemsPerPage = value
        this.getDonationsList()
      },
    },
    components: {
      addDonationsModal
    }
  }
</script>
