<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">Sessions Help</v-card-title>
        <v-card-text>
          Sessions are time frames for which your organization is pushing a specific agenda. They typically start long before a legislative session begins and end after
          the final vote on your bill. If you have no bill, then it can end when your campaign is done. You may only have one active Session at a time.
          <br /><br />
          Each Session should be named to accurately represent your intent so that future teams can view the historical tasks with persepctive. Example, "2019 Membership
          Drive" or "2019 Telehealth Bill".  Also, you should choose what Committee(s) are relevant to each Session so they will appear in your Session Dashboard.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
