<template>
  <v-dialog persistent fullscreen v-model="isModalOpen">
    <v-container class="purple-background pb-12" style="display: -webkit-box; display: -ms-flexbox; -ms-flex-wrap: wrap; flex-wrap: wrap; height: 100%;" fluid>
      <v-flex class="d-flex fill-height align-center justify-center">
          <v-card class="text-center">
            <v-card-text >
              <v-avatar size="150">
                <img :src="client.avatar_url || '/images/client-avatar.png'" />
              </v-avatar>
              <h2>{{ client.association}}</h2>
              <h4 class="mt-5">All done!</h4>
              <h1>Thank You!</h1>
              <v-icon size="60" color="success">{{ svgPath }}</v-icon>
            </v-card-text>
          </v-card>
      </v-flex>
    </v-container>
  </v-dialog>
</template>

<script>

import MemberService from "@/services/MemberService";
import { mdiCheckboxOutline } from '@mdi/js';

export default {
  name: "LogoutMemberModal",
  mixins: [MemberService],
  props: {
    client: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isModalOpen: false,
      svgPath: mdiCheckboxOutline
    }
  },
  computed: {
  },
  methods: {
    toggle(member) {
      this.isModalOpen = true
      localStorage.removeItem('user')
      localStorage.removeItem('onlyProfileUpdate')
      document.removeEventListener('click', this.changeSessionWatcher)
      clearInterval(this.sessionTimerId)
      clearInterval(this.unseenMessagesTimerId)
    }
  },
}
</script>
