<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">Tasks Help</v-card-title>
        <v-card-text>
          Each Session should have tasks assigned.  Tasks are specific actions that you would like to track for each Session per Key Person/ Legislator. Examples, "Call Legislator",
          "Meet with Legisaltor", "Donate to Legislator", "Predicted Vote from Legislator".
          <br /><br />
          When setting up Tasks, you may choose from our predefined tasks, or create your own custom tasks. Each tasks should have a deadline and the Session Dashboard
          columns are ordered by Task deadline.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
