import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VForm,{attrs:{"loading":_vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.saveLegislatorNotes()}}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between py-2"},[_c('span',{staticClass:"title"},[_vm._v("Notes")]),_c(VIcon,{staticClass:"ml-3",nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,[_c(VTextarea,{staticClass:"mt-4",attrs:{"label":"Private/Sensitive Legislator Notes (Admins only)","rows":_vm.isMobile? 5 : 3,"outlined":"","hide-details":""},model:{value:(_vm.legislator.vip_notes),callback:function ($$v) {_vm.$set(_vm.legislator, "vip_notes", $$v)},expression:"legislator.vip_notes"}}),(this.legislator.vip_notes !== '')?_c('div',{staticClass:"d-flex justify-end mt-3"},[_c(VBtn,{attrs:{"small":"","outlined":"","color":"secondary"},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"ml-3",attrs:{"small":"","type":"submit","color":"primary","loading":_vm.loading}},[_vm._v("Save")])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }