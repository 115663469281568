import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/scss/v2/_variables.scss'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: '#9155FD',
          darken1: '#28243d',
          lighten1: '#312d4b',
          lighten2: '#3b355a',
        },
        accent: '#0d6efd',
        secondary: {
          base: '#8A8D93',
          bg_gray: '#f4f5fa',
        },
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
      dark: {
        primary: {
          base: '#9155FD',
          darken1: '#28243d',
          lighten1: '#312d4b',
          lighten2: '#3b355a',
        },
        accent: '#0d6efd',
        secondary: {
          base: '#8A8D93',
          bg_gray: '#f4f5fa',
        },
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
    },
  },
});
