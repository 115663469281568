import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VIcon,{staticClass:"d-flex justify-end pt-2 pr-2",nativeOn:{"click":function($event){return _vm.closeModal()}}},[_vm._v("close")]),_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("What type of message are you sending?")])]),_c(VDivider),_c(VCardText,[_c(VLayout,{staticClass:"mt-5 kp-tasks-page_actions",attrs:{"row":""}},[_c(VFlex,{staticClass:"pa-2",attrs:{"xs12":"","sm6":""}},[_c(VTooltip,{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c(VBtn,_vm._g({attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.$emit('onShowUnique')}}},on),[_vm._v(" ONE MESSAGE PER KP ")])],1)]}}])},[_c('span',[_vm._v("Use this if you are sending a simple message such as \"Hello {kp_first}, thanks for all you do\" to each KP selected and you are not including legislator variables such as legislator name, district, phone, etc. Each KP will get only one message regardless of how many legislators they are assigned. ")])])],1),_c(VFlex,{staticClass:"pa-2",attrs:{"xs12":"","sm6":""}},[_c(VTooltip,{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c(VBtn,_vm._g({attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.$emit('onShowDuplicate')}}},on),[_vm._v(" ONE MESSAGE PER KP/LEGISLATOR ")])],1)]}}])},[_c('span',[_vm._v("Use this option if you are including legislator variables such as \"Please call {leg_name} at {leg_phone}.\" KPs will get one message for each legislator row that is selected. ")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }