import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary","size":"24"},on:{"click":function($event){$event.stopPropagation();_vm.isModalOpen = true}}},[_vm._v("help")]),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Advocate Page Help")]),_c(VCardText,[_vm._v(" Please be sure to sync your organization's Advocate database with this database! If an Advocate is no longer active with your association, please deactivate here. Also, new Advocates should be added here. "),_c('br'),_c('br'),_vm._v(" The \"KP Eligible\" field should be unchecked if a Advocate has either retired from the KP structure or expressed no interest in volunteering. This will remove him or her from certain lists as not to bother them. "),_c('br'),_c('br'),_vm._v(" If an Advocate's cell or email bounces back with a permanent error indicating a bad address or number, their icons will turn red. You should contact these Advocates to obtain a valid email or cell to ensure they are receiving communications. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }