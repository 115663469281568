import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"550"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',[_vm._v("Notice...")])]),_c(VCardText,{staticClass:"py-3"},[_c('h3',[_vm._v(" Due to complex regulations regarding the content of emails and/or SMS, this messaging system may only be used for Advocacy purposes. ")]),_c('br'),_c('br'),_c(VCheckbox,{staticClass:"mt-0 mb-6 admins-copies_checkbox user-invitation_Checkbox",attrs:{"color":"primary","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" I certify that this message's contents are advocacy-related. ")]},proxy:true}]),model:{value:(_vm.isProceed),callback:function ($$v) {_vm.isProceed=$$v},expression:"isProceed"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.isProceed},on:{"click":_vm.proceed}},[_vm._v("Proceed")])],1)],1),_c('member-message-modal',{ref:"memberMessageModal",attrs:{"parentFilter":_vm.parentFilter,"legislators":_vm.legislators,"committeeList":_vm.committeeList,"clientRegions":_vm.clientRegions}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }