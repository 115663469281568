import axios from 'axios'

export default {
  methods: {
    getTasks () {
      return axios.get('/tasks')
    },
    createTask (payload) {
      return axios.post('/tasks', payload)
    },
    updateTask (payload) {
      return axios.put(`/tasks/${payload.id}`, payload)
    },
    deleteTask (taskId) {
      return axios.delete(`/tasks/${taskId}`)
    },
    getCommonTasks () {
      return axios.get('/tasks/common')
    },
    addTaskResult (payload) {
      return axios.post(`/tasks/${payload.task_id}/result`, payload)
    },
    updateTaskResult (payload, id) {
      return axios.put(`/task-results/${id}`, payload)
    },
    deleteTaskResult (id) {
      return axios.delete(`/task-results/${id}`)
    }
  }
}
