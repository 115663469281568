<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog max-width="550" v-model="isModalOpen">
    <v-card :loading="isLoading">
      <v-card-title>
        <span class="title">Send Status</span>
        <v-spacer />
        <v-icon class="ml-3" @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <v-text-field
          class="mt-2 mb-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          solo
        ></v-text-field>

        <v-data-table
          v-if="statsList !== false"
          class="elevation-1 stats-table"
          :options.sync="pagination"
          :headers="statsTableHeader"
          :items="statsList"
          :search="search"
        >

          <template v-slot:item.member.fullname="{ item }">
            <div style="white-space: nowrap">{{ item.member.fullname }}</div>
          </template>

          <template v-slot:item.advocacy_message.via_sms="{ item }">
            <v-icon v-if="!item.advocacy_message.via_sms" color="primary" size="35" class="mx-1 method_icon">mail_outlined</v-icon>
            <v-icon v-else color="primary" size="35" class="mx-1 method_icon">phone_iphone</v-icon>
          </template>

          <template v-slot:item.error_code="{ item }">
            <div>
              <v-tooltip
                v-if="item.advocacy_message.via_sms && item.error_code"
                :max-width="item.error_code !== 30006 ? '190' : '215'"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-chip
                    small
                    class="ma-2"
                    :color="item.error_code !== 30006 ? 'warning' : 'error'"
                    text-color="white"
                    v-on="on"
                  >
                    {{ item.error_code !== 30006 ? 'Filtered' : 'Failed' }}
                  </v-chip>
                </template>
                <span>{{ errorTooltip(item) }}</span>
              </v-tooltip>
              <span v-else>{{ '-' }}</span>
            </div>
          </template>

        </v-data-table>
        <v-skeleton-loader
          v-if="statsList == false"
          v-bind="attrs"
          type="table-heading, table-tbody, table-tfoot"
        ></v-skeleton-loader>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click.native="close()">Close</v-btn>
      </v-card-actions>
    </v-card>

    <sent-message-modal ref="sentMessageModal"></sent-message-modal>
  </v-dialog>
</template>

<script>
import AdvocacyMessageService from '@/services/AdvocacyMessageService'
import SentMessageModal from '@/components/advocacy/sent-message-modal'

import AdvocacyCompletedStatus from '@/entities/advocacy-completed-status'

export default {
  name: 'StatsModal',
  props: ['refresh'],
  mixins: [AdvocacyMessageService],
  data () {
    return {
      search: '',
      isModalOpen: false,
      isLoading: false,
      status: '',
      statsList: [],
      pagination: {
        itemsPerPage: 10
      },
      advocacyMessage: {},
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      completed: AdvocacyCompletedStatus,
    }
  },
  computed: {
    statsTableHeader () {
      return [
        { text: 'Member', value: 'member.fullname', align: 'left' },
        { text: 'Method', value: 'advocacy_message.via_sms', align: 'center' },
        { text: 'Error', value: 'error_code', align: 'center' },
      ]
    }
  },
  methods: {
    async toggle (advocacyMessage) {
      this.isLoading = true
      this.isModalOpen = true
      this.search = ''
      this.advocacyMessage = advocacyMessage
      this.statsList = (await this.getSendStat(advocacyMessage.id)).data
      this.isLoading = false
    },
    errorTooltip(item) {
      const failed = 'Landline or unreachable carrier. User cell blocked. Contact user for updated number.'
      const filtered = 'Filtered as possible spam, please see texting guidelines.'
      if (item.error_code === 30006) {
        return failed
      } else if (item.error_code === 30007) {
        return filtered
      } else {
        return item.error_text
      }
    },
    close() {
      this.statsList = []
      this.refresh()
      this.isModalOpen = false
    }
  },
  components: {
    'sent-message-modal': SentMessageModal
  }
}
</script>

<style>
.stats-table th {
  white-space: nowrap;
}
</style>
