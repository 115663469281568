<template>
  <v-container fluid grid-list-lg class="pa-0" style="max-width: 1600px">
    <v-card>
      <v-card-text class="pt-5">
        <v-layout wrap>
          <v-item-group v-model="window" class="flex xs12 md5 lg3">
            <v-item v-for="item in items" :key="item.title" class="pr-2" v-if="item.show">
              <div slot-scope="{ active, toggle }">
                <v-btn :input-value="active" @click="toggle" dark class="mx-0 mb-2 how-to_tab-link" block :color="active ? 'primary' : '#666'">
                  <v-icon class="mr-2">info</v-icon>
                  <span>{{ item.title }}</span>
                </v-btn>
              </div>
            </v-item>
          </v-item-group>

          <v-flex class="xs12 md7 lg9">
            <v-window v-model="window" class="elevation-0" vertical>
              <v-window-item transition="none" reverse-transition="none" v-for="(item, i) in items" :key="item.title">
                <v-card flat>
                  <v-card-text class="pa-0">
                    <div class="how-to_video" v-if="item.video">
                      <video class=" elevation-1" :src="item.video" controls></video>
                    </div>

                    <div v-if="item.images && item.images.length && window == i">
                      <v-carousel>
                        <v-carousel-item
                            v-for="(image, index) in item.images"
                            :key="index"
                            :src="image.src"
                        ></v-carousel-item>
                      </v-carousel>
                    </div>

                  </v-card-text>
                </v-card>
              </v-window-item>
            </v-window>
          </v-flex>

        </v-layout>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "HowToPage",
    data(){
      return {
        window: 0
      }
    },
    computed: {
      items() {
        return [
          {
            title: 'Getting Started',
            text: '',
            images: [],
            video: 'https://kpdmedia.s3.amazonaws.com/video/status_page.mp4',
            show: this.isSuperAdmin || this.isVIP
          },
          {
            title: 'Setting & Options',
            text: '',
            images: [],
            video: 'https://kpdmedia.s3.amazonaws.com/video/client_options.mp4',
            show: this.isSuperAdmin || this.isVIP
          },
          {
            title: 'Sessions & Tasks',
            text: '',
            images: [],
            video: 'https://kpdmedia.s3.amazonaws.com/video/sessions_tasks.mp4',
            show: this.isSuperAdmin || this.isVIP
          },
          {
            title: 'Assigning KPs',
            text: '',
            images: [],
            video: 'https://kpdmedia.s3.amazonaws.com/video/kp_assignments.mp4',
            show: this.isSuperAdmin || this.isVIP
          },
          {
            title: 'Committee Assignments',
            text: '',
            images: [],
            video: 'https://kpdmedia.s3.amazonaws.com/video/committee_assignments.mp4',
            show: this.isSuperAdmin || this.isVIP
          },
          {
            title: 'Messaging KPs',
            text: '',
            images: [],
            video: 'https://kpdmedia.s3.amazonaws.com/video/messaging_kps.mp4',
            show: this.isSuperAdmin || this.isVIP
          },
          {
            title: 'Advocacy Messaging',
            text: '',
            images: [],
            video: 'https://kpdmedia.s3.amazonaws.com/video/advocacy_messaging.mp4',
            show: this.isSuperAdmin || this.isVIP || this.isCoordinator
          }
        ]
      }
    },
    methods: {
      stopAllVideos() {
        let players = document.querySelectorAll("video")
        players.forEach(player => {player.pause()})
      }
    },
    watch: {
      window: function () {
        this.stopAllVideos()
      },
    }
  }
</script>

<style lang="scss">
  .how-to {
    &_tab-link {
      .v-btn__content {
        justify-content: flex-start;
      }
    }
    &_video {
      video {
        width: 100%;
        outline: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        max-height: calc(100vh - 97px);
        margin: 0 auto;
      }
    }
  }
</style>
