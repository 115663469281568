import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary","size":"24"},on:{"click":function($event){$event.stopPropagation();_vm.isModalOpen = true}}},[_vm._v("help")]),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Committees Page Help")]),_c(VCardText,[_c('p',[_vm._v(" This is where you will select all of the Committees that are relevent to your cause and/or industry. Afterwards, you will need to populate the Committee and/or the Subcommittee with appointed Legislators. It is your responsibility to keep these Committee appointments accurate as they may change frequently. You may then also choose these Committees for you Sessions. ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }