<template>
  <v-dialog persistent max-width="700" v-model="isModalOpen">
    <v-card>
      <v-icon
        class="d-flex ml-auto pt-2 pr-4"
        @click="removeAllAttachedFiles() + close()"
    >
      close
    </v-icon>
      <v-card-title>
        <span class="title">Send {{ isEmail ? 'an Email' : 'a Text' }} to {{ coordinatorsCount }} RC(s)</span>
        <v-spacer></v-spacer>
        <v-switch
          color="primary"
          v-model="isEmail"
          hide-details :label="!isEmail ? 'Email' : 'Text'"
          class="d-inline-block mt-0"
        ></v-switch>
      </v-card-title>
      <v-divider></v-divider>
      <div v-if="!isEmail">
        <text-coordinator-message
          :client="client"
          :clientId="clientId"
          :coordinators="coordinators"
          :legislators="legislators"
          :isSendNow='isSendNow'
          :isSendCopiesToAdmins="isSendCopiesToAdmins"
          :scheduledMessage="scheduledMessage"
          :isEmail="isEmail"
          @getFinalMessageLength="getFinalMessageLength"
          @changeCount="setNewCoordinatorsCount"
          @changeIsSendNow='changeIsSendNow'
          @changeIsBusy="changeIsBusy"
          @getDate="getDate"
          @getTime="getTime"
          @getMessage="getMessage"
          @close="close"
          @update="update"
          ref="textCoordinatorMessage"
        >
        </text-coordinator-message>
      </div>
      <div v-if="isEmail">
        <email-coordinator-message
          :client="client"
          :clientId="clientId"
          :coordinators="coordinators"
          :legislators="legislators"
          :isSendNow='isSendNow'
          :isSendCopiesToAdmins="isSendCopiesToAdmins"
          :scheduledMessage="scheduledMessage"
          :isEmail="isEmail"
          @changeCount="setNewCoordinatorsCount"
          @changeIsSendNow='changeIsSendNow'
          @changeIsBusy="changeIsBusy"
          @getDate="getDate"
          @getTime="getTime"
          @getMessage="getMessage"
          @close="removeAllAttachedFiles() + close()"
          @update="update"
          ref="emailCoordinatorMessage"
          @updateFilesSize="onFileSizeUpdate"
        >
        </email-coordinator-message>
      </div>
      <v-flex class="px-4">
        <v-checkbox
          color="primary"
          v-model="isSendNow"
          label="Send Now"
          hide-details
          class="mb-3 mt-3"
        >
        </v-checkbox>
        <div v-if="isEmail">
          <v-alert
            v-if="isSummaryUploadLimit"
            class="mt-3"
            dense
            type="error"
            outlined
            :value="true">
            The size of images and attached files is more than 25MB
          </v-alert>
        </div>
      </v-flex>
      <v-divider></v-divider>
      <v-card-actions v-if="!isEmail">
        <v-layout wrap class="justify-space-between">
        <v-tooltip
          v-if="client && client.send_mailgun_notifications  && clientAdmins && clientAdmins.length > 0"
          top
          class="pl-5"
        >
          <template v-slot:activator="{ on }">
            <v-checkbox
              color="primary"
              hide-details
              class="admins-copies_checkbox mt-3"
              v-model="isSendCopiesToAdmins"
              v-on="on"
            >
              <template v-slot:label>
                <p class="mb-0">Send copy to select Admins
                  <span v-if="clientAdmins.length" class="primary--text">({{ clientAdmins }})</span>
                </p>
              </template>
            </v-checkbox>
          </template>
          <span>This will send a copy of this message to all Admins who have their<br> "Receive copy of outgoing messages" in their Profile within your Team page.</span>
        </v-tooltip>
        <div class="mt-3">
          <v-btn outlined color="secondary" @click.native="close()" class="mr-2">Cancel</v-btn>
          <v-btn
            v-if="!isSendNow"
            @click.native="$refs.textCoordinatorMessage.onSendSchedule"
            color="primary"
            :loading="isBusy"
            :disabled="!time || !date || !message || maxFileSize || isSummaryUploadLimit"
          >
            Save & Schedule
          </v-btn>
          <v-btn v-else @click.native="$refs.textCoordinatorMessage.onSend" color="primary" :loading="isBusy" :disabled="finalMessageLength > 320 || !message || maxFileSize">Send</v-btn>
        </div>
        </v-layout>
      </v-card-actions>
      <v-card-actions v-if="isEmail">
        <v-layout wrap class="justify-space-between">
        <v-tooltip v-if="client && client.send_mailgun_notifications  && clientAdmins && clientAdmins.length > 0" top>
          <template v-slot:activator="{ on }">
            <v-checkbox
              color="primary"
              hide-details
              class="mt-3 admins-copies_checkbox"
              v-model="isSendCopiesToAdmins"
              v-on="on"
            >
              <template v-slot:label>
                <p class="mb-0">Send copy to select Admins
                <span v-if="clientAdmins.length" class="primary--text">({{ clientAdmins }})</span>
                </p>
              </template>
            </v-checkbox>
          </template>
          <span>This will send a copy of this message to all Admins who have their<br> "Receive copy of outgoing messages" in their Profile within your Team page.</span>
        </v-tooltip>
        <div class="mt-3">
        <v-btn
          outlined
          class="mr-2"
          color="secondary"
          @click="close()"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="!isSendNow"
          @click.native="$refs.emailCoordinatorMessage.onSendSchedule"
          color="primary"
          :loading="isBusy"
          :disabled="!time || !date || !message || maxFileSize"
        >
          Save & Schedule
        </v-btn>
        <v-btn
          v-else
          @click.native="$refs.emailCoordinatorMessage.onSend"
          color="primary"
          :loading="isBusy"
          :disabled="!message || maxFileSize || isSummaryUploadLimit"
        >Send</v-btn>
        </div>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import TextCoordinatorMessage from './coordinator-message-modal/text'
  import EmailCoordinatorMessage from './coordinator-message-modal/email'
  import AppService from '@/services/AppService'
  import ClientService from "@/services/ClientService";
  import TinyConfig from "@/mixins/tiny-mce-config"

  export default {
    name: "send-coordinator-message-modal",
    mixins: [TinyConfig],
    props: {
      client: {
        type: Object,
        default: null
      },
      legislators: {
        type: Array,
        default: function () {
          return []
        }
      },
      coordinators: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data() {
      return {
        clientId: 0,
        isModalOpen: false,
        isEmail: false,
        coordinatorsCount: 0,
        isSendNow: true,
        finalMessageLength: 0,
        isBusy: false,
        date: null,
        time: null,
        isSendCopiesToAdmins: false,
        message: '',
        scheduledMessage: null,
        updatedImgSize: 0,
        imgSizeLimit: 26214400,
      }
    },
    components: {
      TextCoordinatorMessage,
      EmailCoordinatorMessage,
      AppService,
      ClientService
    },
    computed: {
    isSummaryUploadLimit() {
      return (this.updatedImgSize + this.updatedFilesSize) > this.imgSizeLimit
    },
    },
    methods: {
      toggle(clientId) {
        this.clientId = clientId
        this.isModalOpen = true
      },
      toggleOnScheduleMessagePage(clientId, scheduledMessage) {
        this.clientId = clientId
        this.scheduledMessage = scheduledMessage
        this.isModalOpen = true
        this.isEmail = !scheduledMessage.is_sms
      },
      close() {
        this.isModalOpen = false
        this.currentImgsSize = 0
        this.updatedImgSize = 0
        this.clearFileInfo()
        this.removeAllAttachedFiles()
      },
      setNewCoordinatorsCount(count) {
        this.coordinatorsCount = count
      },
      changeIsSendNow() {
        this.isSendNow = !this.isSendNow
      },
      getFinalMessageLength(length) {
        this.finalMessageLength = length
      },
      changeIsBusy(value) {
        this.isBusy = value
      },
      getDate(value) {
        this.date = value
      },
      getTime(value) {
        this.time = value
      },
      getMessage(value) {
        this.message = value
      },
      update() {
        this.$emit('update')
      },
    }
  }
</script>
