import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary","size":"24"},on:{"click":function($event){$event.stopPropagation();_vm.isModalOpen = true}}},[_vm._v("help")]),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Relationships Help")]),_c(VCardText,[_c('p',[_vm._v(" Relationships refer to the Legislator-KP relationship. You may wish to keep these simple such as \"Good\" and \"Bad\"; however, you can also get very specific. Different types of organizations may have different relationships. ")]),_c('p',[_vm._v("Some example relationships are below.")]),_c('ul',[_c('li',[_vm._v("No relationship yet")]),_c('li',[_vm._v("Legislator is a patient of the KP")]),_c('li',[_vm._v("Family member has relationship")]),_c('li',[_vm._v("Personal friends")]),_c('li',[_vm._v("Professional Relationship")]),_c('li',[_vm._v("Attend same church")]),_c('li',[_vm._v("Has Legislator cell")])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }