<template>
  <v-layout align-center>
      <v-tooltip bottom v-if="rowData.vip == 'VIP'">
        <template v-slot:activator="{ on, attrs }">
          <v-chip color="#ffd700" small light v-on="on">VIP</v-chip>
        </template>
        <span>{{ vipNote }}</span>
      </v-tooltip>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import vipChip from '@/components/legislators/vip-setting/vip-chip'

export default Vue.extend({
  components: {
    'vip-chip': vipChip
  },
  computed: {
    rowData () {
      return this.params.node.data || {}
    },
    vipNote () {
      let vip = this.rowData.vip_status.filter(item => item.end_date === null)
      return vip && vip.length > 0 ? vip[0].note : null
    }
  }
})
</script>
