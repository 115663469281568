import axios from 'axios'

export default {
  list() {
    return axios.get('/users/notifications', ).then(response => {
      return response.data
    }, response => {
      throw { success: false, errors: response.errors }
    })
  },
  mark(id) {
    return axios.post('/users/notifications/' + id + '/mark-as-read').then(response => {
      return response.data
    }, response => {
      throw { success: false, errors: response.errors }
    })
  },
  markAll() {
    return axios.post('/users/notifications/mark-all-as-read').then(response => {
      return response.data
    }, response => {
      throw { success: false, errors: response.errors }
    })
  },
}
