<template>
  <v-dialog max-width="500"  persistent v-model="isModalOpen" @click:outside="onClose">
    <v-form @submit.prevent="onHandleSubmit()" ref="twoFactorVerification">
      <v-card v-if="user">
        <v-card-text>
          <v-alert type="warning" outlined :value="true" >{{ "2FA requires the free Authy app to be installed on your phone." }}</v-alert>
          <v-alert type="error" outlined :value="true" v-if="error && typeof error !== 'boolean'">{{ error }}</v-alert>
          <v-layout wrap class="py-3" v-if="!passwordVerified">
            <v-flex >
              <v-text-field label="Enter password" :rules="passwordIsRequired" type="password" v-model="user.password" @input="error = ''"/>
            </v-flex>
          </v-layout>
          <v-layout wrap class="py-3" v-else-if="passwordVerified && !verificationCodeSent">
            <v-flex >
              <v-text-field label="Enter Phone Number" :rules="phoneNumberRules" v-model="user.cell_phone"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout wrap class="py-3" v-if="verificationCodeSent">
            <v-flex >
              <v-text-field
                label="Verification code "
                v-model="user.verification_code"
                :error-messages="error?[message]: []"
                @input="error = ''"/>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="onClose">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">{{btnLabel}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import UserService from "@/services/UserService"

  export default {
    name: "TwoFactorVerification",
    mixins: [UserService],
    data() {
      return {
        isModalOpen: false,
        isBusy: false,
        passwordVerified: false,
        verificationCodeSent: false,
        user: {},
        error: null,
        message: null
      }
    },
    computed: {
      passwordIsRequired() {
        return [
          v => !!v || 'Password is required.',
        ]
      },
      phoneNumberRules() {
        return [
          v => !!v || 'Invalid phone number',
        ]
      },
      btnLabel() {
        if (!this.passwordVerified) {
          return 'Save'
        } else if (this.passwordVerified && !this.verificationCodeSent) {
          return 'Confirm'
        } else if (this.passwordVerified && this.verificationCodeSent) {
          return 'Verify and Enable 2FA'
        } else {
          return 'Save'
        }
      },
    },
    methods: {
      toggle(user) {
        if(this.$refs.twoFactorVerification) {
          this.$refs.twoFactorVerification.reset()
        }
        this.isModalOpen = true;
        this.user = user
      },
      initState() {
        this.verificationCodeSent = false;
        this.passwordVerified = false;
        this.message = null;
        this.error = null;
      },
      async verifyUserPassword() {
        try {
          this.isBusy = true;
          const response = await this.verifyPassword({password: this.user.password});
          if (response.data.verified !== undefined && response.data.verified) {
            this.passwordVerified = true
          } else {
            this.error = 'Password is not verified';
          }
          this.isBusy = false
        } catch (e) {
          this.isBusy = false;
          this.error = e;
        }
      },
      async sendVerificationCode() {
        try {
          this.isBusy = true;
          this.error = null;
          const response = await this.verifyPhone({phone: this.user.cell_phone});
          if (response.status === 200 && response.data.message) {
            this.verificationCodeSent = true;
            this.message = response.data.message
          } else {
            this.error = 'Something went wrong';
          }
          this.isBusy = false
        } catch (e) {
          this.isBusy = false;
          this.error = e;
        }
      },
      async verifyTwoFactorCode() {
        try {
          if (!this.user.verification_code) {
            this.error = true;
            this.message = 'Verification code is required';
            return false;
          }
          this.isBusy = true;
          this.error = null;
          const response = await this.verifyCode({verification_code: this.user.verification_code});
          if (response.data.verified !== undefined && response.data.verified) {
            this.onCallToastr('success', 'Your two factor authentication successfully enabled', 'Success!')
            this.user.two_factor_verified = true;
            await this.$store.dispatch('auth/setUserData', this.user);

            this.isModalOpen = false;
            this.initState();
            let self = this;
            setTimeout(function () {
              self.$store.dispatch('app/setProfileSidebarOpen', false)
            }, 1500);
            this.$refs.twoFactorVerification.reset();
          } else {
            this.error = true;
            this.message = 'Verification code is invalid';
          }
          this.isBusy = false
        } catch (e) {
          this.isBusy = false;
          this.onCallToastr('error', 'Something went wrong, please try again later', 'Failed!')
        }
      },
      async onHandleSubmit() {
        if (!this.$refs.twoFactorVerification.validate()) {
          return false;
        }
        if (!this.passwordVerified) {
          this.verifyUserPassword()
        } else if (this.passwordVerified && this.verificationCodeSent) {
          this.verifyTwoFactorCode();
        }
        if (this.passwordVerified && !this.verificationCodeSent) {
          this.sendVerificationCode();
        }
      },
      onClose() {
        this.initState()
        this.isModalOpen = false
      }
    },
  }
</script>
