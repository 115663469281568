<template>
  <v-dialog persistent max-width="700" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-icon class="d-flex justify-space-around" @click="closeModal()">close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center mb-5">
          <h1>Upgrade for this functionality!</h1>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'UpgradeFunctionalityModal',
  data() {
    return {
      isModalOpen: false
    }
  },
  methods: {
    closeModal() {
      this.isModalOpen = false
      this.$emit('closeModal')
    }
  }
}
</script>
