<template>
  <v-dialog max-width="500" v-model="isModalOpen">
    <v-card>
      <v-card-title>
      <!--Adam changed title 5.3.24 -->
        <!--<span class="title">{{ sessionTask.name }}</span>-->
        <span class="title">Add/Edit Donation</span>
        <v-spacer />
        <v-icon @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-form ref="donateForm" @submit.prevent="addDonation()">
        <v-card-text class="mt-3">
          <v-text-field
            v-model="task.result"
            :error-messages="isValidatedAmount"
            label="Amount"
            :rules="resultRules"
            prefix="$"
            :counter="25"
          >
          </v-text-field>
          <v-select
            label="Type"
            :rules="typeRules"
            item-text="displayText"
            item-value="actualValue"
            :items="types"
            v-model="task.type"
          >
          </v-select>
          <v-autocomplete
             label="Donor"
             item-text="fullname"
             item-value="id"
             :items="advocates"
             v-model="task.donor_id"
             v-if="task.type === 'Other' && advocates && advocates.length > 0">
             <template v-slot:selection="props">
               <v-tooltip bottom>
                 <template v-slot:activator="{ on }">
                     {{ props.item.fullname }}
                 </template>
                 <span>{{ props.item.fullname }}</span>
               </v-tooltip>
             </template>
           </v-autocomplete>
          <v-text-field
            v-model="task.note"
            label="Note"
            hint="A general note to remember reason for donation"
            persistent-hint
          >
          </v-text-field>
          <v-menu
            ref="dateMenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :return-value.sync="task.donated_at"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formatDate"
                label="Date of donation"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="task.donated_at"
              no-title
              scrollable
              @change="changeDate"
            >
            </v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-flex class="d-flex justify-end py-4">
            <v-btn
              outlined
              color="secondary"
              @click="close()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="ml-3"
              @click="addDonation"
            >
              Save
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import LegislatorService from '@/services/LegislatorService'
import MemberService from "@/services/MemberService";
import ClientService from "@/services/ClientService";


export default {
  name: "addKpDonationModal",
  mixins: [LegislatorService, MemberService, ClientService],
  props: {
    legislator: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sessionTask: {
      type: Object,
      default: () => {
        return {}
      }
    },
    memberId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isModalOpen: false,
      dateMenu: false,
      loading: false,
      disabled: true,
      isUpdate:false,
      taskResultId: null,
      formatDate: '',
      task: {
        type: '',
      },
      names: [],
      advocates: [],
    }
  },
  computed: {
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    types() {
      let options = []
      if (this.legislator.active_kps) {
        this.legislator.active_kps.filter((kp, index) => {
          switch (kp.kp_position) {
            case "1":
                options[0] = {displayText: `KP1 - ${kp.member.fullname}`, actualValue: 'KP1'}
              break;
          
            case "2":
                options[1] = {displayText: `KP2 - ${kp.member.fullname}`, actualValue: 'KP2'}
              break;
          
            case "3":
              if(this.legislator.active_kps.length == 2) {
                options[1] = {displayText: `KP3 - ${kp.member.fullname}`, actualValue: 'KP3'}
              } else {
                options[2] = {displayText: `KP3 - ${kp.member.fullname}`, actualValue: 'KP3'}
              }
              break; 
            default:
              break;
          }
        })
      }
      options = options.concat([{displayText: 'Other', actualValue: 'Other'}, {displayText: 'PAC', actualValue: 'PAC'}]);
      return options    
    },
    typeTask() {
      const name = this.sessionTask.name.toLowerCase()
      if (name.includes('kp')) {
        return 'KP1'
      } else if (name.includes('other')) {
        return 'Other'
      } else if (name.includes('pac')) {
        return 'PAC'
      }
      return false
    },
    NameRules () {
      return [
        v => !!v || 'Name is required.',
      ]
    },
    resultRules () {
      return [
        v => v > 0 || 'Amount must be more than 0.',
      ]
    },
    typeRules () {
      return [
        v => !!v || 'Type is required.',
      ]
    },
    isValidatedAmount() {
      return (!(+this.task.result) && !!this.task.result) ? 'Only numeric values are valid for this field' : ''
    }
  },
  methods: {
    open(task) {
      this.isUpdate = false
      this.taskResultId = null
      this.isModalOpen = true
      if (task) {
        const valueToFormat = task.donated_at.replace(' ', 'T')
        const jsDate = new Date(valueToFormat)
        const utc = new Date(jsDate.getTime() + jsDate.getTimezoneOffset() * 60000);

        this.disabled = false
        this.isUpdate = true
        this.taskResultId = task.task_result.id
        this.task.id = task.task_result.session_task.id
        this.task.tooltip = task.task_result.session_task.tooltip
        this.task.result = task.amount
        this.task.type = task.type
        this.task.note = task.comment
        this.task.donated_at = moment(utc).format('YYYY-MM-DD')
        this.disabled = true
      } else {
        this.task.id = null
        this.task.name = this.sessionTask.name
        this.task.tooltip = this.sessionTask.tooltip
        this.task.result = ''
        this.task.type = this.typeTask
        this.task.donated_at = moment().format('YYYY-MM-DD')
        this.task.note = ''
      }
      this.formatDate = this.getFormattedDate(this.task.donated_at, 'MM/DD/YYYY')
    },
    changeDate() {
      this.$refs.dateMenu.save(this.task.donated_at)
      this.formatDate = this.task.donated_at ? this.getFormattedDate(this.task.donated_at, 'MM/DD/YYYY') : ''
    },
    async addDonation() {
      this.loading = true
      if (!this.$refs.donateForm.validate()) {
        return false;
      }

      const payload = {
        "kp_id": this.memberId ? this.memberId : this.userMemberId,
        "donor_id": this.task.donor_id,
        "task_id":  this.sessionTask.id,
        "result":  this.task.result,
        "note":  this.task.note,
        "type":  this.task.type,
        "date":  this.task.donated_at,
      }

      if(this.taskResultId !== null) {
        payload.task_result_id = this.taskResultId
      }

      this.storeCurrencyTaskResult(this.legislator.id, payload)
        .then(() => {
          this.onCallToastr('success', 'Task Result has been created.', 'Success!')
          this.$emit('refresh')
          this.isModalOpen = false
        })
        .catch((e) => {
          console.log('e', e)
          this.onCallToastr('error', 'Something went wrong.', 'Error!')
        })
    },
    close() {
      this.task = {
        type: '',
      }
      this.names = []
      this.advocates = []
      this.isModalOpen = false
    },
    getAdvocates() {
      this.isLoading = true
      const clientId = this.client && this.client.id || '';
      this.getClientMembersWithPagination(clientId, { unlimit: 1 })
        .then((response) => {
          this.advocates = response.data.data ? response.data.data : response.data
          this.isLoading = false
        })
        .catch(err => {
          this.isLoading = false
          this.handleError(err)
        })
    }
  },
  watch: {
    'task.type'(value) {
      if(value) {
        this.advocates = []
        this.task.donor_id = null
        if(value === 'Other' && this.advocates.length === 0) {
          this.getAdvocates()
        } else if (value.includes("KP")) {
          const position = value.split('KP')[1]
          this.legislator.active_kps.filter(kp => {
            if (kp.kp_position == position) {
              this.advocates = [kp.member]
              this.task.donor_id = kp.member.id
              return
            }
          })
        }
      }
    }
  }
}
</script>
