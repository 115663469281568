import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"my-1",attrs:{"color":"primary","dark":"","min-width":_vm.isMobile ? '100%' : '110px',"disabled":!_vm.disabled,"small":"","loading":_vm.isBusy}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("cloud_download")]),_vm._v(" Export KPs ")],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":"","small":""}},[_c(VListItemTitle,{staticClass:"text-center",on:{"click":function($event){return _vm.onDownloadTemplate('xlsx')}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" mdi-file-excel ")]),_vm._v("Excel ")],1)],1),_c(VDivider),_c(VListItem,{attrs:{"link":"","small":""}},[_c(VListItemTitle,{staticClass:"text-center",on:{"click":function($event){return _vm.onDownloadTemplate('csv')}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" mdi-file-download ")]),_vm._v("CSV ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }