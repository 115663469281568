import Vue from 'vue'

Vue.mixin({
	data() {
		return {
			innerWidth: window.innerWidth
		}
	},
	computed: {
		isDesktop() {
			return this.innerWidth >= 1264
		},
		isMobile() {
			return this.innerWidth <= 1024
		},
		isDevice() {
			return this.innerWidth >= 1025  && this.innerWidth <= 1263
		}
	},
	mounted() {
		window.addEventListener('resize', () => {
			this.innerWidth = window.innerWidth
		})
	},
})
