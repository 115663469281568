<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-navigation-drawer class="legislator-assignments_modal" fixed temporary right touchless width="650"
                       v-model="isModalOpen">
    <v-layout class="mx-0">
      <v-form ref="clientForm" @submit.prevent="onUpdateClient(false)">
        <v-card style="box-shadow: none !important;">
          <v-card-title>
            <span class="title">Options</span>
            <v-spacer/>
            <v-icon @click="isModalOpen = false">close</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-2">
            <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
            <v-layout wrap>
              <v-layout wrap class="px-2">
                <v-flex xs12 class="mb-2 px-2 d-flex align-center">
                  <div class="d-inline-block">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-avatar size="80" tile class="cursor-pointer" @click="$refs.avatarUploader.click()" v-on="on">
                          <img :src="client.avatar_url || '/images/client-avatar.png'"/>
                        </v-avatar>
                      </template>
                      <span>Click to upload a new logo.</span>
                    </v-tooltip>
                  </div>
                  <input ref="avatarUploader" type="file" accept="image/*" class="d-none" @change="onUploadAvatar()">
                  <div class="d-inline-block ml-10">
                    <div><b>Plan level:</b> {{userClient.tariff.name}}</div>
                    <span class="font-italic">Please contact Sales if you’d like to change your plan level.</span>
                    <div class="mb-2 mt-2"><b>Official {{client.assoc_abbrev}} email:</b> {{client.mailgun_email}}</div>
                    <div><b>Official {{client.assoc_abbrev}} text number:</b> {{client.twilio_number}}</div>
                  </div>
                </v-flex>
                <v-flex xs12 md8 class="px-2">
                  <v-text-field label="Full Association Name*" outlined dense :rules="associationRules"
                                v-model="client.association"
                                @input="error = ''"></v-text-field>
                </v-flex>
                <v-flex xs12 md4 class="px-2">
                  <v-text-field label="Association Abbr*" outlined dense :rules="associationAbbrevRules"
                                v-model="client.assoc_abbrev"></v-text-field>
                </v-flex>
                <v-flex xs12 md8 class="px-2">
                  <v-select label="Time zone" outlined dense :items="getTimeZonesList()"
                            v-model="client.timezone"></v-select>
                </v-flex>
                <v-flex xs12 md4 class="px-2">
                  <v-select label="Max KPs" outlined dense :items="kpsList"
                            v-model="client.uses_kps"></v-select>
                </v-flex>
                <v-flex v-if="isSuperAdmin" xs12 md4 class="px-2">
                  <v-checkbox
                    color="primary"
                    label="Use regions?"
                    v-model="client.uses_regions"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 class="px-2 mb-8">
                  <v-switch
                    color="primary"
                    v-model="client.send_mailgun_notifications"
                    :label="`Send email notifications to${client.uses_regions ? ' Coordinators and ' : ' '}selected Admins when Message received`"
                  >
                  </v-switch>
                </v-flex>
                <v-flex xs12 md6 class="px-2">
                  <v-switch
                    color="primary"
                    label="Use Regional Coordinators?"
                    v-model="client.uses_coordinators"
                    @change="client.show_all = false"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md6 class="px-2">
                  <v-switch
                    color="primary"
                    label="Show All Task Rows for Coordinators"
                    v-model="client.show_all"
                    v-if="client.uses_coordinators"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md6 class="px-2">
                  <v-switch
                    color="primary"
                    label="Uses DOB?"
                    v-model="client.uses_dob"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md6 class="px-2">
                  <v-switch
                    color="primary"
                    label="Uses grad year?"
                    v-model="client.uses_grad_year"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md6 class="px-2">
                  <v-switch
                    color="primary"
                    label="Uses Political Leanings?"
                    v-model="client.uses_political_leaning"
                  ></v-switch>
                </v-flex>
                <!--hidden by adam as we will probably not use this field
                <v-flex xs12 md6 class="px-2">
                  <v-switch color="primary" label="Uses Political Issues?" v-model="client
                  .uses_political_issues"></v-switch>
                </v-flex>-->
                <v-flex xs12 md6 class="px-2">
                  <v-switch
                    color="primary"
                    label="Uses Hobbies/Interests?"
                    v-model="client.uses_interests"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md6 class="px-2">
                  <v-switch
                    color="primary"
                    label="Use 2nd work address?"
                    v-model="client.uses_2nd_work"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md6 class="px-2">
                  <v-switch
                    color="primary"
                    label="Use 3rd work address?"
                    v-model="client.uses_3rd_work"
                  ></v-switch>
                </v-flex>
                <v-flex  xs12 md6 class="px-2">
                  <v-switch
                    color="primary"
                    label="Use 4th work address?"
                    v-model="client.uses_4th_work"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 class="px-2">
                  <v-layout align-baseline>
                    <v-text-field
                      label="How to formally address a House Legislator in correspondence"
                      outlined
                      dense
                      v-model="client.formal_house"
                    ></v-text-field>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon size="20" v-on="on">help</v-icon>
                      </template>
                      <span>example: Delegate, Del, Representative, Rep., Honorable, etc. This will be used before last name when sending Adovacy messages to legislators.</span>
                    </v-tooltip>
                  </v-layout>
                </v-flex>
                <v-flex xs12 class="px-2">
                  <v-layout align-baseline>
                    <v-text-field
                      label="How to formally address a Senator in correspondence"
                      outlined
                      dense
                      v-model="client.formal_senate"
                    ></v-text-field>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon size="20" v-on="on">help</v-icon>
                      </template>
                      <span>example: Representative, Rep., Honorable, etc. This will be used before last name when Adovacy messages to legislators.</span>
                    </v-tooltip>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-4">
            <v-spacer></v-spacer>
            <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
            <v-btn type="submit" color="primary" :loading="isBusy">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import ClientService from '@/services/ClientService'
import CloudinaryService from '@/services/CloudinaryService'
import StateService from '@/services/StateService'

export default {
  name: "ClientSettingsModal",
  mixins: [ClientService, CloudinaryService, StateService],
  data () {
    return {
      committeeList: [],
      client: {},
      error: '',
      isBusy: false,
      tabs: [
        {
          icon: 'account_box',
          title: 'General',
        }
      ],
      activeKpsPosition: null,
      kpsPositionList: [0, 1, 2, 3, 4, 5]
    }
  },
  computed: {
    isModalOpen: {
      get () {
        return this.$store.state.app.isClientSettingsSidebarOpen
      },
      set (newValue) {
        this.$store.dispatch('app/setClientSettingsSidebarOpen', newValue)
      }
    },
    associationRules () {
      return [
        v => !!v || 'Association is required.',
      ]
    },
    associationAbbrevRules () {
      return [
        v => !!v || 'Association abbr is required.',
      ]
    },
    kpsList () {
      return this.kpsPositionList.map((kp) => ({
        text: kp.toString(),
        value: kp,
        disabled: kp < this.activeKpsPosition,
      }));
    },
  },
  methods: {
    async getClientActiveKpsPostion(){
      if(this.client && this.client.id) {
        const data = (await this.getActiveKpsPostion(this.client.id)).data;
        this.activeKpsPosition = data || 0;
      }
    },
    onUploadAvatar () {
      const file = this.$refs.avatarUploader.files[0]
      if (!file) return
      this.uploadToCloudinary(file)
        .json((res) => {
          this.client.avatar_url = res.secure_url
          this.client = {...this.client}
          this.onUpdateClient(true)
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
        })
    },
    onUpdateClient (isModalOpen) {
      if (!this.$refs.clientForm.validate()) return
      this.isBusy = true
      this.updateClient(this.client)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'Client info has been updated.', 'Success!')
          const userData = _.cloneDeep(this.userData)
          userData.client = this.client
          this.$store.dispatch('auth/setUserData', userData)
          this.isModalOpen = isModalOpen
        })
        .catch((err) => {
          this.isBusy = false
          if (err && err.response && err.response.data && err.response.data.message) {
            // Display the error message using your custom function (e.g., onCallToastr)
            this.onCallToastr('error', err.response.data.message, 'Failed!');
          } else {
            // Handle other types of errors or show a generic error message
            this.error = 'Something went wrong!'
          }
        })
    }
  },
  watch: {
    async isModalOpen (value) {
      if (value) {
        this.error = ''
        this.committeeList = (await this.getStateCommittees(this.userClient.state_id)).data
        this.client = _.cloneDeep(this.userClient)
        if (!this.client.house_committees) this.client.house_committees = []
        if (!this.client.senate_committees) this.client.senate_committees = []
        if (!this.client.joint_committees) this.client.joint_committees = []
        this.client = _.cloneDeep(this.client)

        //Getting client's active kps position from BE
        this.getClientActiveKpsPostion();
      }
    }
  }
}
</script>
