<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">Resources Page Help</v-card-title>
        <v-card-text>
        <p>
        Resources are essentially web pages within the Dashboard that your team and KPs can access for reference. Add as many as you'd like. 
        You can set each Resource to be viewable by different user types. 
        </p>
        <p>
        You can also securely add private attachments to each Resource.
        </p>
        <p>Some example Resources might be:</p>
        <ul>
        <li>Legislator Talking Points</li>
        <li>How to be a KP</li>
        <li>Coordinator Resources</li>
        <li>Admin-Only Notes</li>
        <li>Phone Scripts</li>
        <li>Contact List</li>
        <li>Marketing Materials (images and videos)</li>
        </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
