import axios from "axios";

export default {
  methods: {
    getApiSources(params = {}) {
      return axios.get('/api-sources', params)
    },
    switchApiSource(apiSourceId) {
      return axios.put(`/api-sources/${apiSourceId}`)
    },
    getApiSourceMappings(params = {}) {
      return axios.get('/api-sources/mappings', params)
    },
    editApiSourceMapping(apiSourceId, payload) {
      return axios.put(`/api-sources/mappings/${apiSourceId}`, payload)
    },
    createApiSourceMapping(payload) {
      return axios.post(`/api-sources/mappings`, payload)
    },
    deleteApiSourceMapping(apiSourceId, payload) {
      return axios.delete(`/api-sources/mappings/${apiSourceId}`, payload)
    }
  }
}