export default {
  methods: {
    politicalLearningsBorder(member, width = `2px`, side = '') {
      if (!!member.political_leaning_id) {
        const item = this.politicalLearnings.find(item => item.id === member.political_leaning_id)
        if (this.isVIP || this.isCoordinator) {
          return `border${side}: ${width} solid ${item.color} !important`
        }
      }
      return `border${side}: ${width} solid transparent !important`
    },
    politicalLearningsColor(member) {
      if (!!member.political_leaning_id) {
        const item = this.politicalLearnings.find(item => item.id === member.political_leaning_id)
        if ((this.isVIP || this.isCoordinator) && item && item.name) {
          return `${item.color}`
        }
      }
      return ''
    },
    politicalLearningsTitle(member) {
      if (!!member.political_leaning_id) {
        const item = this.politicalLearnings.find(item => item.id === member.political_leaning_id)
        if ((this.isVIP || this.isCoordinator) && item && item.name) {
          return `${item.name}`
        }
      }
      return ''
    }
  }
}
