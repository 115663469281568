<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-layout
    align-center
    v-if="rowData.member_legislators.length && rowData.primary_kp.email"
  >
    <span>
      {{ rowData.primary_kp.email }}
    </span>
  </v-layout>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  computed: {
    rowData () {
      return this.params.node.data || {}
    }
  }
})
</script>
