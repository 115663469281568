<template>
  <v-dialog max-width="500" v-model="dialog" @click:outside="close">
    <v-card class="message-modal-content">
      <v-card-title>
        <span class="title">
          Edit Political Leanings
          <v-progress-circular
            indeterminate
            color="primary"
            size="20"
            width="2"
            v-if="loading"
          ></v-progress-circular>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-3">
        <v-text-field
          v-model="form.name"
          class="ma-0"
          label="Political Leanings name"
          :value="form.name"
        ></v-text-field>
        <div class="committee-colors_item">
          <input type="color" id="committee_color" v-model="form.color" @change="changeColor($event)">
          <label for="committee_color">
            <v-chip label dark :color="form.color">Color</v-chip>
          </label>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="loading" :disabled="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import MemberService from '@/services/MemberService'
  import kpPreference from '@/entities/kp-preference'

  export default {
    name: "PoliticalLeaningsModal",
    mixins: [MemberService],
    props: ['selectedClientId'],
    data() {
      return {
        dialog: false,
        errors: false,
        loading: false,
        hasPoliticalLeanings: false,
        kpPreference: kpPreference,
        kpPreferenceResults: [],
        form: {
          id: null,
          name: '',
          color: '',
        },
      }
    },
    computed: {
      valid() {
        return this.form.name && this.form.type
      },
      clientId() {
        return this.selectedClientId ? this.selectedClientId : this.userClientId
      },
    },
    methods: {
      open(item) {
        if(item) {
          this.form.id = item.id
          this.form.name = item.name
          this.form.color = item.color
          this.hasPoliticalLeanings = true
        }
        this.dialog = true
      },
      close() {
        this.form = {
          id: null,
          name: '',
          color: '',
        }
        this.$emit('update')
        this.dialog = false
      },
      save() {
        this.loading = true

        let params = {
          id: this.form.id,
          name: this.form.name,
          color: this.form.color
        }

        this.updatePoliticalLeanings(params).then(()=> {
          this.onCallToastr('success', 'Political leanings have been updated.', 'Success!')
          this.close()
          this.loading = false
          this.errors = false
        }, err => {
          this.errors = err.response.data.errors
          this.loading = false
        })
      },
      editKpPreferenceResults(event, value) {
        if (event) {
          this.kpPreferenceResults.push(value)
        } else {
          const index = this.kpPreferenceResults.indexOf(value)
          if (index >= 0) {
            this.kpPreferenceResults.splice(index,1)
          }
        }
      },
      changeColor(event) {
        let hex = event.target.value
        this.form.color = hex

        this.colorLuminance(hex, -0.5)
      },
      colorLuminance(hex, lum) {
        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
          hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        let rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
          c = parseInt(hex.substr(i*2,2), 16);
          c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
          rgb += ("00"+c).substr(c.length);
        }
      }
    }
  }
</script>
