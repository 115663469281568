<template>
  <v-dialog persistent max-width="700" :fullscreen="isMobile" v-model="isModalOpen">
    <v-card>
      <v-card-title class="pb-0">
        <span class=" mb-3">{{ member.fullname }} notes</span>
        <v-spacer/>
        <v-icon class="ml-3" @click="close">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout row wrap class="card-content mt-3">
          <v-flex xs12>
            <v-textarea
              class="ma-1"
              outlined
              v-model="member.notes"
            >
            </v-textarea>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click="close">Cancel</v-btn>
        <v-btn color="primary" :loading="isLoading" @click="onSave()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MemberService from "@/services/MemberService";

export default {
  name: "MemberNotesModal",
  mixins: [MemberService],
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      member: {},
      note: ''
    }
  },
  methods: {
    toggle(member) {
      this.isModalOpen = true
      this.member = member
    },
    onSave() {
      this.isLoading = true
      this.updateMember(this.member).then(() => {
        this.onCallToastr('success', 'Member\'s note has been updated.', 'Success!')
        this.$emit('updateKps')
        this.isLoading = false
        this.close()
      }, () => {
        this.onCallToastr('error', 'Something went wrong.', 'Error!')
        this.isLoading = false
      })
    },
    close() {
      this.isModalOpen = false
    }
  }
}
</script>
