import axios from 'axios'

export default {
  methods: {
    getSystemMessages(params) {
      return axios.get('/system-messages', { params })
    },
    storeSystemMessage(data) {
      return axios.post('/system-messages/store', data)
    },
    deleteSystemMessage(messageId) {
      return axios.post(`/system-messages/${messageId}/delete`)
    },
    sendMessage(data) {
      return axios.post('/system-messages/send-message', data)
    },
  }
}
