<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog max-width="1050" v-model="isModalOpen">
    <v-card :loading="isLoading">
      <v-card-title>
        <span class="title">Stats</span>
        <v-spacer />
        <v-icon class="ml-3" @click="close()">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <v-text-field
          class="mt-2 mb-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          solo
        ></v-text-field>

        <v-data-table
          v-if="statsList.items !== false"
          class="elevation-1 stats-table"
          :options.sync="statsList.pagination"
          :headers="statsTableHeader"
          :footer-props="tableFooter"
          :items="statsList.items"
          :search="search"
          @pagination="getStatsPaginateList"
        >
          <template v-slot:item.member_name="{ item }">
            <div class="d-flex align-center">
              <a style="white-space: nowrap; cursor: pointer;" class="mr-1" @click="toggleMemberModal(item)">{{ item.member_name }}</a>
              <sup v-if="item.is_kp1 && item.activeKP1">KP1</sup>
              <v-tooltip bottom v-if="item.kp_preference !== 'unknown'">
                <template v-slot:activator="{ on }">
                  <v-avatar v-on="on" :color="kpPreferenceColor(item)" size="10"></v-avatar>
                </template>
                <span>{{ kpPreferenceTitle(item) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.political_leaning_id !== null">
                <template v-slot:activator="{ on }">
                  <v-avatar class="mx-1" v-on="on" :color="politicalLearningsColor(item)" size="10"></v-avatar>
                </template>
                <span>{{ politicalLearningsTitle(item) }}</span>
              </v-tooltip>
            </div>

          </template>

          <template v-slot:item.legislator_name="{ item }">
            <div style="white-space: nowrap">{{ item.legislator_name }}</div>
          </template>

          <template v-slot:item.sent_at="{ item }">
            <div>
              <span v-if="item.sent_at">{{ item.sent_at | dateTimeFormatterWithoutUTC }}</span>
              <v-icon color="error" v-else>cancel</v-icon>
            </div>
          </template>

          <template v-if="isVIP" v-slot:item.completed="{ item }">
            <div>
              <span v-if="item.completed">
                {{ item.completed[0].toUpperCase() + item.completed.slice(1) }}
              </span>
              <v-select
                v-else
                v-model="item.status"
                :items="completed"
                full-width
                hide-details
                flat
                outlined
                dense
                label="Select method"
                style="min-width: 200px"
              >
                <template v-slot:append-outer>
                  <v-btn
                    color="primary"
                    @click="setComplete(item)"
                    :disabled="!item.status"
                    :loading="isLoading"
                    x-small
                    fab
                    depressed
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </template>
              </v-select>
            </div>
          </template>

          <template v-slot:item.message="{ item }" v-if="isVIP">
            <div v-if="item.message != null" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a @click="onOpenSentMessageModal(item.message)" v-on="on">
                    <v-icon color="primary" size="35" class="mx-1">drafts</v-icon>
                  </a>
                </template>
                <span>Read full message</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.opened_at="{ item }">
            <div>
              <span v-if="item.opened_at">{{ item.opened_at | dateTimeFormatterWithoutUTC }}</span>
              <v-icon color="error" v-else>cancel</v-icon>
            </div>
          </template>

          <template v-slot:item.action="{ item }">
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="warning"
                    :loading="status === ('REMINDING_' + item.member_id + '_' + item.legislator_id)"
                    @click="onRemind(item)"
                    v-if="!item.sent_at"
                    v-on="on"
                    :disabled="!isActive()"
                    small
                  >
                    Remind
                  </v-btn>
                </template>
                <span>Remind {{ item.member_name }} about this message</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:no-data>
            <p class="text-xs-center mb-0">No stats yet.</p>
          </template>
        </v-data-table>
        <v-skeleton-loader
          v-if="statsList.items == false"
          v-bind="attrs"
          type="table-heading, table-tbody, table-tfoot"
        ></v-skeleton-loader>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click.native="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
    <member-detail-modal ref="memberDetailModal" :selected-client="selectedClient"></member-detail-modal>
    <sent-message-modal ref="sentMessageModal"></sent-message-modal>
  </v-dialog>
</template>

<script>
import AdvocacyMessageService from '@/services/AdvocacyMessageService'
import SentMessageModal from '@/components/advocacy/sent-message-modal'

import AdvocacyCompletedStatus from '@/entities/advocacy-completed-status'
import politicalLeaningsBorder from "@/mixins/politicalLeaningsBorder";
import MemberService from "@/services/MemberService";
import MemberDetailModal from '@/components/members/member-detail-modal';

export default {
  name: 'StatsModal',
  props: ['refresh'],
  components: {
    'member-detail-modal': MemberDetailModal,
  },
  mixins: [AdvocacyMessageService, MemberService, politicalLeaningsBorder],
  data () {
    return {
      search: '',
      isModalOpen: false,
      isLoading: false,
      status: '',
      politicalLearnings: [],
      statsList: {
          pagination: {
            page: 1,
            itemsPerPage: 10,
            totalItems: 0,
            current_page: 0,
            per_page: 0,
            last_page: 0,
            sortBy: ['sent_at'],
            sortDesc: ['asc']
          },
          items: [],
        },
      tableFooter: {
          'items-per-page-options': [10, 50, 100]
        },
      advocacyMessage: {},
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      completed: AdvocacyCompletedStatus,
    }
  },
  mounted() {
    this.getPoliticalLeanings()
        .then(response => {
          this.politicalLearnings = response.data
        })
  },
  computed: {
    statsTableHeader () {
      if(this.isVIP) {
        return [
          { text: 'Member', value: 'member_name', align: 'left', width: '16%' },
          { text: 'Legislator', value: 'legislator_name', align: 'left', width: '16%' },
          { text: 'Sent At', value: 'sent_at', align: 'center', width: '15%' },
          { text: 'Completed?', value: 'completed', align: 'center', width: '19%' },
          { text: 'Message', value: 'message', align: 'center', width: '13%', sortable: false },
          { text: 'Opened At', value: 'opened_at', align: 'center', width: '16%' },
          { text: '', value: 'action', width: '5%' },
        ]
      }
      return [
        { text: 'Member', value: 'member_name', align: 'left' },
        { text: 'Legislator', value: 'legislator_name', align: 'left' },
        { text: 'Sent At', value: 'sent_at', align: 'center' },
        { text: 'Opened At', value: 'opened_at', align: 'center' },
        { text: '', value: 'action' },
      ]
    },
    selectedClient() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
  },
  methods: {
    async toggle (advocacyMessage) {
      this.isLoading = true
      this.isModalOpen = true
      this.search = ''
      this.advocacyMessage = advocacyMessage
      this.getStatsPaginateList().then(() => {
        this.isLoading = false
      })
    },
    getStatsPaginateList() {
      let params = { 
        page: this.statsList.pagination.page,
        limit: this.statsList.pagination.itemsPerPage,
        sort: this.statsList.pagination.sortBy[0],
        direction: this.statsList.pagination.sortDesc[0] ? 'asc' : 'desc',
      }
      return this.getStats(this.advocacyMessage.id, params).then((response) => {
          this.statsList.items = response.data.data ? response.data.data : response.data
          this.statsList.pagination.totalItems = response.data.total ? response.data.total : response.data.length
          this.statsList.pagination.current_page = response.data.current_page
          this.statsList.pagination.per_page = response.data.per_page
          this.statsList.pagination.last_page = response.data.last_page
        })
      },
    onRemind (message) {
      this.status = `REMINDING_${message.member_id}_${message.legislator_id}`
      const payload = {
        member_id: message.member_id
      }
      this.remindAdvocacyMessage(this.advocacyMessage.id, payload)
        .then(() => {
          this.status = ''
          this.onCallToastr('success', `Remind message has been sent to ${message.member_name}.`, 'Success!')
        })
        .catch((err) => {
          this.status = ''
          this.handleError(err)
        })
    },
    onOpenSentMessageModal(message) {
      this.$refs.sentMessageModal.open(message)
    },
    isActive () {
      if (!this.advocacyMessage.active) return false
      if (this.advocacyMessage.deadline < moment().format('YYYY-MM-DD')) return false
      return true
    },
    setComplete(item) {
      this.isLoading = true
      item.completed = item.status.toLowerCase()
      this.completeAdvocacyMessage(this.advocacyMessage.id, item).then((res) => {
        this.getStatsPaginateList().then(() => {
          this.isLoading = false
        })
      }, (e) => {
        this.isLoading = false
        console.error(e)
      })
    },
    toggleMemberModal(item) {
      this.$refs.memberDetailModal.toggle(item)
    },
    close() {
      this.statsList.items = []
      this.statsList.pagination.page=1;
      this.refresh()
      this.isModalOpen = false
    }
  },
  components: {
    'sent-message-modal': SentMessageModal
  }
}
</script>

<style>
.stats-table th {
  white-space: nowrap;
}
</style>
