export default [
  {
    title: 'Success',
    value: 'success'
  },
  {
    title: 'Warning',
    value: 'warning'
  },
  {
    title: 'Error',
    value: 'error'
  },
  {
    title: 'None',
    value: 'none'
  }
]
