<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog max-width="850" v-model="isModalOpen">
    <!-- Sub Sidebar -->
    <v-card class="flex-1 legislator-detail_modal" :loading="loading">
      <v-flex class="d-flex align-center">
        <h5 v-if="legislator.vip" class="ml-6">VIP since {{ formatDate(legislator.vip_start) }}</h5>
        <v-icon class="d-flex ml-auto pt-2 pr-4" @click="isModalOpen = false">close</v-icon>
      </v-flex>
      <v-card-title class="d-flex flex-wrap">
        <span class="title">{{ legislator.nickname }} {{ legislator.lastname }} -</span>
        <span class="label-party"
              :style="{'background-color': partyColorList[legislator.party], color: $vuetify.theme.dark ? 'inherit' :
              '#fff'}">{{
            legislator.party }}</span>
        <v-spacer />
        <div class="d-flex" v-if="isVIP && (legislator.leg_support || (legislator.legData && legislator.legData[0] && legislator.legData[0].leg_support))">
          <v-icon size="16" color="orange lighten-3" v-for="value in [1, 2, 3, 4, 5]" :key="value" v-if="value <=
          (legislator.leg_support || (legislator.legData && legislator.legData[0] && legislator.legData[0].leg_support))">mdi-star</v-icon>
        </div>
      </v-card-title>
        <!-- Main Sidebar -->
      <v-tabs :vertical="$vuetify.breakpoint.smAndUp" v-model="tabs" show-arrows>
        <v-tooltip bottom v-for="(item, index) in tabItemList" :key="index">
          <template v-slot:activator="{ on }">
            <v-tab :class="{'active': index === activeTabIndex}" @click="onSelectTab(index)" v-on="on">
              <v-icon size="25" v-if="item.icon">{{ item.icon }}</v-icon>
              <div class="tab-item" v-else><strong>{{ item.text }}</strong></div>
            </v-tab>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>

        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="(item, index) in tabItemList" :key="index">
            <v-card flat v-if="activeTabIndex === 0">
              <v-card-text class="d-flex" :class="$vuetify.breakpoint.xs ? 'flex-wrap' : ''">
                <div class="photo-container">
                  <div class="legislator_card__image">
                    <img
                        v-if="legislator.official_image"
                        :src="getThumbImageLarge(legislator.official_image)"
                        :style="{ 'border-left': `4px solid ${partyColorList[legislator.party]}` }"
                        :alt="legislator.nickname + ' ' + legislator.lastname"
                        height="200"
                    >
                    <v-flex class="legislators_list__card--img--icons">
                      <vip-chip
                        v-if="legislator.vip"
                        :legislator="legislator"
                        :show="'image_code'"
                      >
                      </vip-chip>
                    </v-flex>
                      <v-icon v-if="legislator.lame_duck" class="legislators_icon--faded" color="#EECB23">fa-solid fa-duck</v-icon>
                      <div
                        v-if="legislator.membership"
                        class="legislators_list__card--label"
                        :style="{ background: legislator.membership.color}"
                      >
                        <span>
                          {{ legislator.committee.shortname }}
                          <br>{{ legislator.membership.name }} {{ legislator.committee.subcommittee >= 1 && client && client.state && client.state.has_subcommittees ? '(sub)' : '' }}
                        </span>
                      </div>
                      <div v-if="legislator.abdicated_at" class="legislators_list__card--label is_archived">
                        <span>Archived</span>
                      </div>
                  </div>
                  <v-layout wrap class="legislators-detail_social">

                    <v-flex class="pa-2 align-content-center" xs4 sm6 v-if="legislator.website_url">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn tile elevation="2" large v-on="on" icon color="grey" class="pa-2" :href="legislator.website_url" target="_blank">
                            <v-icon>fa-university</v-icon>
                          </v-btn>
                        </template>
                        <span>Official Website</span>
                      </v-tooltip>
                    </v-flex>

                    <v-flex class="pa-2 align-content-center" xs4 sm6 v-if="legislator.ballotpedia_url">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn tile elevation="2" large class="pa-2" icon dark :href="legislator.ballotpedia_url"
                                 target="_blank" v-on="on" color="grey">
                            <h2 class="blue--text">B</h2><h2 class="orange--text">P</h2>
                          </v-btn>
                        </template>
                        <span>Ballotpedia</span>
                      </v-tooltip>
                    </v-flex>

                    <v-flex class="pa-2 align-content-center" xs4 sm6 v-if="legislator.twitter_url">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn tile elevation="2" large icon v-on="on" :href="legislator.twitter_url" class="pa-2" target="_blank" color="light-blue">
                            <v-icon>fab fa-twitter-square</v-icon>
                          </v-btn>
                        </template>
                        <span>Twitter</span>
                      </v-tooltip>
                    </v-flex>

                    <v-flex class="pa-2 align-content-center" xs4 sm6 v-if="legislator.facebook_url">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn tile elevation="2" large icon v-on="on" :href="legislator.facebook_url" class="pa-2" target="_blank" color="primary">
                            <v-icon>fab fa-facebook-square</v-icon>
                          </v-btn>
                        </template>
                        <span>Facebook</span>
                      </v-tooltip>
                    </v-flex>

                    <v-flex class="pa-2 align-content-center" xs4 sm6 v-if="legislator.campaign_url">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn tile elevation="2" large icon v-on="on" :href="legislator.campaign_url" class="pa-2" target="_blank" color="primary">
                          <v-icon>fa-thin fa-globe</v-icon>
                          </v-btn>
                        </template>
                        <span>Campaign Website</span>
                      </v-tooltip>
                    </v-flex>

                    <v-flex class="pa-2 align-content-center" xs4 sm6 v-if="legislator.instagram_url">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn tile elevation="2" large icon class="pa-2" :href="legislator.instagram_url"
                                 target="_blank" v-on="on" color="error">
                            <v-icon>fab fa-instagram-square</v-icon>
                          </v-btn>
                        </template>
                        <span>Instagram</span>
                      </v-tooltip>
                    </v-flex>

                    <v-flex class="pa-2 align-content-center" xs4 sm6 v-if="legislator.linkedin_url">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn tile elevation="2" large icon class="pa-2" :href="legislator.linkedin_url" target="_blank" v-on="on" color="primary">
                            <v-icon>fab fa-linkedin-square</v-icon>
                          </v-btn>
                        </template>
                        <span>LinkedIn</span>
                      </v-tooltip>
                    </v-flex>

                    <v-flex class="pa-2 align-content-center" xs3 sm6 v-if="legislator.youtube_url">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn tile elevation="2" large icon v-on="on" class="pa-2" :href="legislator.youtube_url" target="_blank" color="red">
                            <v-icon>fab fa-youtube-square</v-icon>
                          </v-btn>
                        </template>
                        <span>YouTube</span>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                </div>

                <v-flex :class="$vuetify.breakpoint.smAndUp ? 'ml-4' : ''">
                  <v-layout wrap>
                  <v-flex xs6>
                    <div v-if="!legislator.abdicated_at">
                      <div class="detail-modal_info-item" v-if="legislator.official_phone">
                        <h4>Capitol Phone:</h4>
                        <div><a @click="onRequestCall(legislator.official_phone)">{{ legislator.official_phone }}</a></div>
                      </div>
                      <div class="detail-modal_info-item" v-if="legislator.local_phone">
                        <h4>Local phone:</h4>
                        <div><a @click="onRequestCall(legislator.local_phone)">{{ legislator.local_phone }}</a></div>
                      </div>
                      <div class="detail-modal_info-item" v-if="legislator.cell_phone || (legislator.legData && legislator.legData[0] && legislator.legData[0].cell_phone)">
                        <h4>Cell:</h4>
                        <div><a @click="onRequestCall(legislator.legData && legislator.legData[0] && legislator.legData[0].cell_phone ? legislator.legData && legislator.legData[0] && legislator.legData[0].cell_phone : legislator.cell_phone)">{{ legislator.legData && legislator.legData[0] && legislator.legData[0].cell_phone ? legislator.legData && legislator.legData[0] && legislator.legData[0].cell_phone : legislator.cell_phone }}</a></div>
                      </div>
                      <div class="detail-modal_info-item" v-if="legislator.home_phone">
                        <h4>Home:</h4>
                        <div><a @click="onRequestCall(legislator.home_phone)">{{ legislator.home_phone }}</a></div>
                      </div>
                      <div class="detail-modal_info-item" v-if="legislator.work_phone">
                        <h4>Work:</h4>
                        <div><a @click="onRequestCall(legislator.work_phone)">{{ legislator.work_phone }}</a></div>
                      </div>
                      <div class="detail-modal_info-item" v-if="legislator.official_email">
                        <h4><v-icon color="secondary">mail_outline</v-icon>:</h4>
                        <div><a @click="onSendEmail">{{ legislator.official_email }}</a></div>
                      </div>
                    </div>
                  </v-flex>
                  <!-- Start map  -->
                  <v-flex xs6>
                   <template v-if="isMapLoad">
                      <map-openlayer
                        :legislator="legislator"
                        :districtId="legislator.district_id"                 
                        :client="selectedClient"
                      />
                    </template>
                  </v-flex>
                  <!-- End map  -->
                </v-layout>
                  <div class="detail-modal_info-item detail-modal_info-item__column" v-if="legislator.occupation">
                    <h4>Occupation / Bio:</h4>
                    <div>
                      <span>{{ legislator.occupation }}</span>
                    </div>
                  </div>
                  <div class="detail-modal_info-item detail-modal_info-item__column" v-if="legislator.leg_notes">
                    <h4>Notes:</h4>
                    <div>
                      <span>{{ legislator.leg_notes }}</span>
                    </div>
                  </div>
                  <div class="detail-modal_info-item detail-modal_info-item__column" v-if="(isSuperAdmin || isVIP) && legislator.vip_notes">
                    <h4>Sensitive, Admin-Only Legislator Notes:</h4>
                    <div>
                      <span>{{ legislator.vip_notes }}</span>
                    </div>
                  </div>
                  <div v-if="committeeList && committeeList.length" class="detail-modal_info-item detail-modal_info-item__column">
                    <h4>Committees:</h4>
                    <div v-for="item in committeeList" :key="item.id">
                      <!--<div>-->
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" style="font-weight:bold;">{{ item.shortname }}</span>
                          </template>
                          <span>{{ item.fullname }}</span>
                        </v-tooltip>
                      <!--</div>-->
                      <!-- list committees -->
                      <span>: {{ item.fullCommitteeMembership ? item.fullCommitteeMembership : 'Not on this committee' }}</span>
                      <span v-if="item.subCommitteeMembership && selectedClient && selectedClient.state && selectedClient.state.has_subcommittees">, {{ item.subCommitteeMembership }}</span>
                      <!-- adam removed 3.27.2024
                      <ul>
                        <li>{{ item.fullCommitteeMembership ? item.fullCommitteeMembership : 'Not on this committee' }}</li>
                        <li v-if="item.subCommitteeMembership && selectedClient && selectedClient.state && selectedClient.state.has_subcommittees">{{ item.subCommitteeMembership }}</li>
                      </ul>
                      -->
                    </div>
                    <div v-if="isCommitteesLoading">
                      <v-progress-circular
                          indeterminate
                          color="primary"
                          size="20"
                          width="2"
                      ></v-progress-circular>
                    </div>
                  </div>
                </v-flex>
              </v-card-text>
            </v-card>
            <v-card flat v-if="activeTabIndex === 1">
              <v-flex xs12 class="text-xs-center">
                <v-data-table
                    class="elevation-1"
                    :headers="voteHistoryTableHeader"
                    :items="voteHistory"
                >
                  <template v-slot:item.name="{ item }">
                    <div>{{ item.name }}</div>
                  </template>

                  <template v-slot:item.notes="{ item }">
                    <div>{{ item.notes }}</div>
                  </template>

                  <template v-slot:item.pvote="{ item }">
                    <div>{{ item.pvote }}</div>
                  </template>

                  <template v-slot:item.fvote="{ item }">
                    <div>{{ item.fvote }}</div>
                  </template>
                </v-data-table>
              </v-flex>
            </v-card>
            <v-card flat v-if="activeTabIndex === 2">
                <v-flex xs12 class="text-xs-center">
                  <v-data-table
                      class="elevation-1"
                      :headers="kpHistoryTableHeader"
                      :items="kpHistory"
                  >
                    <template v-slot:item.fullname="{ item }">
                      <div class="d-flex align-center">
                        <span class="no-line-break">{{ item.fullname }}</span>
                        <sup v-if="item.is_kp1 && item.activeKP1">KP1</sup>
                        <v-tooltip bottom v-if="item.kp_preference !== 'unknown'">
                          <template v-slot:activator="{ on }">
                            <v-avatar class="ml-1" v-on="on" :color="kpPreferenceColor(item)" size="10"></v-avatar>
                          </template>
                          <span>{{ kpPreferenceTitle(item) }}</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.isKpHomeInLegDistrict" max-width="450">
                          <template v-slot:activator="{ on }">
                            <v-icon class="ml-1" color="success" size="18" v-on="on">fa-light fa-location-check</v-icon>
                          </template>
                          <span>Constituent</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="!item.isKpHomeInLegDistrict" max-width="450">
                          <template v-slot:activator="{ on }">
                            <v-icon class="ml-1" color="error" size="18" v-on="on">fa-light fa-location-xmark</v-icon>
                          </template>
                          <span>Not a Constituent</span>
                        </v-tooltip>

                        <v-tooltip bottom v-for="(relationship, index) in item.relationships" :key="index">
                          <template v-slot:activator="{ on }">
                            <v-avatar size="20" :color="relationship.color" class="ml-1" v-on="on">
                              <v-icon size="14" color="white" v-if="relationship.icon == 'phone'">{{ relationship.icon }}</v-icon>
                              <v-icon size="14" color="white" v-else-if="relationship.icon == 'fa-brands fa-twitter'">{{ relationship.icon }}</v-icon>
                              <small v-else class="white--text">{{ relationship.icon }}</small>
                            </v-avatar>
                          </template>
                          <span>{{ relationship.name }}</span>
                        </v-tooltip>
                      </div>
                    </template>

                    <template v-slot:item.position="{ item }">
                      <div>{{ item.position }}</div>
                    </template>

                    <template v-slot:item.notes="{ item }">
                      <div>
                        <v-menu open-on-hover auto bottom offset-y max-width="300">
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="!item.notes" icon small text v-on="on">
                              <v-icon color="primary">note</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-text>
                              {{ item.notes }}
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </template>

                    <template v-slot:item.period="{ item }">
                      <div>{{ item.period }}</div>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-card>
            <v-card flat v-if="activeTabIndex === 3">
              <v-flex xs12 class="text-xs-center">
                <v-card-title>
                Total = ${{ totalDonation }}
                </v-card-title>
                <v-data-table
                  ref="DonateTable"
                  class="elevation-1"
                  :headers="donationHistoryTableHeader"
                  group-by="session_index"
                  :items="donationHistory"
                  :loading="loading"

                >
                  <template v-slot:group.header="{items, isOpen = false, toggle}">
                    <th colspan="3">
                      <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                      {{ items[0].session_name }}
                    </th>
                  </template>

                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.comment }}</td>
                      <td class="d-flex flex-wrap">
                        <div class="d-flex full-width justify-center">${{ formattedPrice(item.total) }}</div>
                        <div class="d-flex full-width justify-center"><strong>{{ item.type }}</strong></div>
                      </td>
                      <td>{{ item.donated_at }}</td>
                    </tr>
                  </template>

                </v-data-table>
              </v-flex>
              </v-card>
            <v-card flat v-if="activeTabIndex === 4">
                <v-flex xs12>
                  <v-card-title>
                    Latest news
                    <v-progress-circular
                        v-if="isNewsLoading"
                        :size="20"
                        :width="3"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                  </v-card-title>
                  <div v-if="legislatorNews && legislatorNews.length > 0">
                    <v-card class="mb-2 news_item" v-for="item in legislatorNews" :key="item.title">
                      <div class="news_item__body">
                        {{ item.title }}
                      </div>
                      <div class="news_item__footer">
                        <div class="grey--text">{{ item.pubDate }}</div>
                        <a :href="item.link" target="_blank">Read more</a>
                      </div>
                    </v-card>
                  </div>
                  <v-alert v-else color="info" icon="info" outlined :value="true">There is no news</v-alert>
                </v-flex>
            </v-card>
            <v-card v-if="activeTabIndex === 5" class="legislators_list__card">
              <v-flex xs12>
                  <v-card-title>
                     Connections
                    <v-progress-circular
                        v-if="isNewsLoading"
                        :size="20"
                        :width="3"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <v-spacer></v-spacer>
                    <h4>{{ legislator.nickname }} is friends with these other Legislators</h4>
                </v-card-title>
              </v-flex>
              <transition-group
                tag="ul"
                class="legislators_list"
                name="fade-in"
                :css="false"
                v-on:before-enter="cardBeforeEnter"
                v-on:enter="cardEnter"
                v-on:leave="cardLeave"
                appear
              >
              <li v-for="(item, index) in connections" :key="item.id" class="legislators_list__item" style="width: 25%;">
                <v-card class="legislators_list__card">
                  <v-app-bar
                    dense
                    elevation="1"
                    max-height="48"
                  >
                    <v-app-bar-title class="connections_title">{{ item.fullname }}
                    </v-app-bar-title>
                  </v-app-bar>
                    <div class="legislators_list__card--img" style="width: 100%; display: block;">
                      <img
                        v-if="item.official_image"
                        :src="item.official_image"
                        :style="{ 'border-left': `4px solid ${partyColorList[item.party]}` }"
                        :alt="item.nickname + ' ' + item.lastname"
                      >
                      <img v-else src="https://via.placeholder.com/300" alt="No image">
                      <v-flex class="legislators_list__card--img--icons">
                        <vip-chip
                          v-if="item.vip_status && item.vip_status[0]"
                          :legislator="item"
                          :show="'image_code'"
                        >
                        </vip-chip>
                        <div
                            v-if="item.legData && (item.legData[0] && item.legData[0].leg_support || item.leg_support) && !isSuperAdmin"
                            class="legislator_card__title--stars"
                        >
                          <v-icon
                            v-if="value <= (item.legData[0] && item.legData[0].leg_support || item.leg_support)"
                            size="18"
                            color="orange lighten-3"
                            v-for="value in [1, 2, 3, 4, 5]" :key="value"
                          >
                            mdi-star
                          </v-icon>
                        </div>
                      </v-flex>
                      <v-icon v-if="item.lame_duck" class="legislators_icon--faded" color="#EECB23">fa-solid fa-duck</v-icon>
                      <div
                        v-if="item.membership"
                        class="legislators_list__card--label"
                        :style="{ background: item.membership.color}"
                      >
                        <span>
                          {{ item.committee.shortname }}{{ item.committee.subcommittee > 1 && client && client.state && client.state.has_subcommittees ? '(sub)' : '' }}
                          <br>{{ item.membership.name }}
                        </span>
                      </div>
                      <div v-if="item.abdicated_at" class="legislators_list__card--label is_archived">
                        <span>Archived</span>
                      </div>
                    </div>
                </v-card>
              </li>
              </transition-group>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

      <v-card-actions :class="$vuetify.breakpoint.smAndUp ? '' : 'd-flex flex-column align-end'">
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        <v-btn
          v-if="isVIP || isSuperAdmin"
          class="ma-2"
          outlined small
          color="primary"
          @click="openAssignmentsModal"
          :disabled="loading || legislator.abdicated_at != null"
        >
          Assignments
          <v-icon small right>group_add</v-icon>
        </v-btn>
        <div v-if="legislator.abdicated_at != null && isSuperAdmin">
          <v-btn
              class="ma-2"
              outlined small
              color="primary"
              @click="openEditModal"
              :disabled="loading"
          >
            Edit Legislator Profile
            <v-icon small right>edit</v-icon>
          </v-btn>
        </div>
        <div v-else-if="!legislator.abdicated_at && (isVIP || isSuperAdmin)">
          <v-btn
              class="ma-2"
              outlined small
              color="primary"
              @click="openEditModal"
              :disabled="loading"
          >
            Edit
            <v-icon small right>edit</v-icon>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <edit-legislator-modal
      ref="editLegislatorModal"
      :state-list="stateList"
      :committee-list="editCommitteeList"
      :clientRegions="clientRegions"
      :occupation-list="occupationList"
      :refresh="load"
    >
    </edit-legislator-modal>
    <assignment-modal
      ref="assignmentModal"
      @getRefreshedAssignment="load"
      :client="selectedClient"
      :member-list="memberList"
      :loading="loading"
    ></assignment-modal>
  </v-dialog>
</template>

<script>
import LegislatorService from '@/services/LegislatorService'
import StateService from '@/services/StateService'
import CommitteeService from '@/services/CommitteeService'
import OccupationService from '@/services/OccupationService'
import DistrictService from '@/services/DistrictService'
import ClientService from '@/services/ClientService'

import editLegislatorModal from '@/components/legislators/edit-legislator-modal'
import assignmentModal from '@/components/legislators/assignment-modal'
import {mapGetters} from "vuex";
import { partyColorList } from '@/entities/party-color-list'
import vipChip from '@/components/legislators/vip-setting/vip-chip'
import mapOpenlayer from '@/components/common/map-ol'

export default {
  mixins: [
    LegislatorService,
    StateService,
    CommitteeService,
    ClientService,
    OccupationService,
    DistrictService
  ],
  props: ['refresh'],
  data () {
    return {
      isModalOpen: false,
      activeTabIndex: 0,
      legislator: {
        committee: {},
      },
      voteHistory: [],
      kpHistory: [],
      donationHistory: [],
      totalDonation: 0,
      legislatorNews: [],
      isDonationLoaded: false,
      legislatorState: null,
      isNewsLoading: false,
      isCommitteesLoading: false,
      committeeList: [],
      editCommitteeList: [],
      selectedStateId: '',
      stateList: [],
      clientRegions: [],
      occupationList: [],
      listVacantDistricts: [],
      unelectedDistricts: [],
      staticMapUrl: '',
      apiKey: 'AIzaSyD58CUUhj-TaJbgerBbTkEpJuQJQwNGNK4',
      map: null,
      view: null,
      list: {
        pagination: {
          page: 1,
          itemsPerPage: 24,
        },
        items: [],
        republicans: 0,
        democrats: 0,
        rating: 0,
      },
      selected_committees: [],
      loading: false,
      memberList: [],
      tabs: null,
      partyColorList: partyColorList(),
      connections: [],
      isMapLoad: false
    }
  },
  components: {
    'edit-legislator-modal': editLegislatorModal,
    'assignment-modal': assignmentModal,
    'vip-chip': vipChip,
    'map-openlayer': mapOpenlayer,
  },
  computed: {
    ...mapGetters('app', [
      'superAdminSelectedClient'
    ]),
    selectedClient() {
      if(this.isSuperAdmin) {
        return this.superAdminSelectedClient
      }

      return this.userClient
    },
		tabItemList () {
			return [
				{
					icon: 'account_box',
					title: 'General',
				},
				{
					icon: 'check_box',
					title: 'Vote History',
				},
				{
					text: 'KP',
					title: 'KP History',
				},
				{
					icon: 'attach_money',
					title: 'Donations',
				},
        {
          icon: 'rss_feed',
          title: 'News',
        },
        {
          icon: 'group',
          title: 'Connections',
        },
			]
		},
    voteHistoryTableHeader () {
      return [
        { text: 'Session', value: 'name', align: 'center'},
        { text: 'Notes', value: 'notes', align: 'center'},
        { text: 'P-Vote', value: 'pvote', align: 'center'},
        { text: 'F-Vote', value: 'fvote', align: 'center'},
      ]
    },
    kpHistoryTableHeader () {
		  if(this.isKP)  return [
        { text: 'KP', value: 'fullname', align: 'center'},
        { text: 'Position', value: 'position', align: 'center'},
        { text: 'From - To', value: 'period', align: 'center'},
      ]
      return [
        { text: 'KP', value: 'fullname', align: 'center'},
        { text: 'Position', value: 'position', align: 'center'},
        { text: 'Notes', value: 'notes', align: 'center'},
        { text: 'From - To', value: 'period', align: 'center'},
      ]
    },
    donationHistoryTableHeader () {
      return [
        { text: 'Note', value: 'comment',},
        { text: 'Donation', value: 'result'},
        { text: 'Date', value: 'donated_at'},
      ]
    },
  },
  methods: {
    cardBeforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'scale(90%)';
      el.style.height = 0;
    },
    cardEnter(el, done) {
        let delay = 200;
        setTimeout(() => {
          Velocity(
            el,
            { opacity: 1, height: '100%', scale: '100%' },
            { complete: done }
          );
        }, delay);
    },
    cardLeave(el, done) {
        let delay = 200;
        setTimeout(() => {
          Velocity(
            el,
            { opacity: 0, height: 0, scale: '90%' },
            { complete: done }
          );
        }, delay);
    },
    async toggle (item, isSetDefaultCommittee = true) {
      let legislator = item && item.legislator ? item.legislator : item
      this.isDonationLoaded = false
      this.tabs = null
      this.activeTabIndex = 0

      if(legislator.defaultCommittes && legislator.defaultCommittes.length && isSetDefaultCommittee) {
        legislator.committees = legislator.defaultCommittes
      }
      
      this.isModalOpen = true
      this.loading = true
      this.committeeList = []
      let params = {
        id: legislator.id
      }

      if (legislator.abdicated_at) {
        params.archived = 1
      }


      await this.getClientLegislatorsWithPagination(this.selectedClient.id, params).then((response) => {
        this.legislator = response.data.data ? response.data.data[0] : response.data[0]
        this.legislator.committee = item && item.committee ? item.committee : {}
        this.legislator.membership = item && item.membership ? item.membership : {}
        let vip =  this.legislator.vip_status.filter(item => item.end_date === null)
        this.legislator.vip_start = vip && vip.length > 0 ? vip[0].created_at : null
        this.legislator.vip_notes = vip && vip.length > 0 ? vip[vip.length - 1].note : null
        this.loading = false
        this.getMembersList()
        if (!legislator.abdicated_at) {
          this.getCommitteesByClientId()
        }

        this.legislatorState = null
        this.legislatorNews = []

        this.getLegislatorState().then(()=> {this.onLoadNews()})

        this.selectedStateId = legislator.state_id
        this.getOccupationsList()
        this.getStatesList()
        this.getCommitteesList()
        this.getClientRegionsList()
        this.fetchKPHistory()

        if (!this.isSuperAdmin) {
          this.fetchVoteHistory()
        }
      }, () => {
        this.legislator = legislator
        this.loading= false

        this.getMembersList()
        if (!legislator.abdicated_at) {
          this.getCommitteesByClientId()
        }

        this.legislatorState = null
        this.legislatorNews = []
        this.getLegislatorState().then(()=> {this.onLoadNews()})

        this.selectedStateId = legislator.state_id
        this.getOccupationsList()
        this.getStatesList()
        this.getCommitteesList()
        this.getClientRegionsList()
        this.fetchKPHistory()

        if (!this.isSuperAdmin) {
          this.fetchVoteHistory()
        }
      })
      this.getConnectionsList()
    },
    //This method for collapse Donation table groups
    awaitTable(count) {
      count++
      // Emergency stop the recursion
      if(count > 200) {
        return false;
      }

      let donateTable = this.$refs.DonateTable;
      if (
        this.isDonationLoaded
        && donateTable
        && donateTable.length > 0
        && donateTable[0].openCache
        && Object.keys(donateTable[0].openCache).length
      ) {
        donateTable.forEach(table => {
          for (const name of Object.keys(table.openCache)) {
            table.openCache[name] = false;
          }
        })
      } else {
        if ( this.isDonationLoaded && !this.donationHistory.length) return false;
        setTimeout(() => {
          this.awaitTable(count)
        }, 10)
      }
    },
    defaultCloseGroupDonationTable() {
      let count = 0
        this.awaitTable(count)
    },
    formattedPrice(amount) {
      amount = Number.prototype.toFixed.call(parseFloat(amount) || 0, 2)
      return amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    },
    async fetchVoteHistory () {
      this.voteHistory = []
      const voteHistoryObject = {}
      const voteHistory = (await this.getLegislatorVoteHistory({
        id: this.legislator.id,
        client_id: this.selectedClient.id,
      })).data
      voteHistory.forEach((vote) => {
        const item = voteHistoryObject[vote.id] || {
          name: vote.session_name,
          notes: vote.notes,
        }
        if (vote.task_name === 'P-Vote') {
          item.pvote = vote.result
        } else {
          item.fvote = vote.result
        }
        voteHistoryObject[vote.id] = item
      })
      _.forEach(voteHistoryObject, (vote) => {
        this.voteHistory.push(vote)
      })
    },
    async fetchKPHistory () {
      const kpHistory = (await this.getLegislatorKPHistory({
        id: this.legislator.id,
        client_id: this.selectedClient.id,
      })).data
      this.kpHistory = kpHistory.map((kp) => {
        const startDate = kp.start_date ? moment(kp.start_date).format('MMM DD, YYYY') : ''
        const endDate = kp.end_date ? moment(kp.end_date).format('MMM DD, YYYY') : 'Present'
        let isKpHomeInLegDistrict = false

        if (kp.legislator.district.district === kp.member.home_house_district || kp.legislator.district.district === kp.member.home_sen_district) {
          isKpHomeInLegDistrict = true
        }

        const item = {
          fullname: `${kp.member.nickname} ${kp.member.lastname}`,
          relationships: kp.relationships,
          position: `KP${kp.kp_position}`,
          isKpHomeInLegDistrict: isKpHomeInLegDistrict,
          notes: kp.notes,
          period: `${startDate} - ${endDate}`,
          kp_preference: kp.member.kp_preference
        }
        return item
      })
    },
    async fetchDonationHistory () {
      this.loading = true
      const result = (await this.getLegislatorDonationHistory({
        id: this.legislator.id,
        client_id: this.selectedClient.id,
      })).data
      this.totalDonation = 0
      const sessionTotals = {};
      const sessionIniecies = {};

      result.map((donation, index) => {
        this.totalDonation += +donation.total
        if (!sessionTotals[donation.session_id]) {
          sessionTotals[donation.session_id] = donation.total
        } else {
          sessionTotals[donation.session_id] += donation.total
        }
        if (!sessionIniecies[donation.session_id]) {
          sessionIniecies[donation.session_id] = index
        }
      })
      this.donationHistory = result
        .map(session => {
          session.session_name = session.session_name + ': $' + (sessionTotals[session.session_id]).toLocaleString()
          session.session_index = sessionIniecies[session.session_id]
          return session
        })
      this.isDonationLoaded = true
      this.loading = false
    },
    onSelectTab (tabIndex) {
      this.activeTabIndex = tabIndex
      if (this.activeTabIndex === 3) {
        this.defaultCloseGroupDonationTable()
      }
    },
    onRequestCall (phone) {
      window.location.href = `tel:${phone}`
    },
    onSendEmail () {
      window.location.href = `mailto:${this.legislator.official_email}`
    },
    getLegislatorState() {
      if(this.legislator.state_id) {
        this.isNewsLoading = true

        return this.getStates().then(response => {
          this.legislatorState = response.data.find(state => state.id == this.legislator.state_id)
        })
      }
    },
    onLoadNews() {
      if(this.legislator.fullname) {
        this.getLegislatorNews(this.legislator.id).then(response => {
          let parser = new DOMParser();
          let resultArray = Array.from(parser.parseFromString(response.data,"text/xml").getElementsByTagName("item"))
          if(resultArray.length > 0) {
            resultArray.slice(0, 5).map(item => {
              this.legislatorNews.push({
                title: item.childNodes[0].innerHTML,
                link: item.childNodes[1].innerHTML,
                pubDate: item.childNodes[3].innerHTML,
                description: item.childNodes[4].innerHTML,
              })
            })
          }

          this.isNewsLoading = false
        })
      }
    },
    getFilteredByTypeCommittee(committees) {
      return committees.filter(committee => {
        const district = this.legislator.district.district ? this.legislator.district.district : this.legislator.district
          if (district && district[0] == 'H') {
            return committee.type == 0 || committee.type == 2 ? committee : null
          }
          if (district && district[0] == 'S') {
            return committee.type == 1 || committee.type == 2 ? committee : null
          }
      })
    },
    getCommitteesByClientId() {
      if (!this.legislator || !this.legislator.client_id) return

      this.committeeList = []
      this.isCommitteesLoading = true

      this.getClientCommittees(this.legislator.client_id).then(response => {
        this.committeeList = this.getFilteredByTypeCommittee(response.data).map(item => ({
          ...item,
          fullCommitteeMembership: this.isLegislatorInCommittee(item.id) ? this.getFullCommitteeMembership(item.id) : null,
          subCommitteeMembership: this.isLegislatorInCommittee(item.id) ? this.getSubCommitteeMembership(item.id) : null,
        }))
        this.isCommitteesLoading = false
      }, () => {
        this.isCommitteesLoading = false
      })
    },
    isLegislatorInCommittee(committeeId) {
      return this.legislator.legislator_committees.some(committee => committee.committee_id === committeeId)
    },
    getLegislatorCommitteeById(committeeId) {
      return this.legislator.legislator_committees.find(committee => committee.committee_id === committeeId)
    },
    getFullCommitteeMembership(committeeId) {
      let membershipStates = this.getLegislatorCommitteeById(committeeId).membership_states
      if (membershipStates) {
        return `Full Committee: ${membershipStates[0].membership.name}`
      }
    },
    getSubCommitteeMembership(committeeId) {
      let membershipStates = this.getLegislatorCommitteeById(committeeId).membership_states
      if (membershipStates && membershipStates.some(membershipState => membershipState.pivot.subcommittee === 1)) {
        const membershipState = membershipStates.find(membershipState => membershipState.pivot.subcommittee === 1)
        return `Subcommittee: ${membershipState.membership.name}`
      }
    },
    openEditModal() {
      this.$refs.editLegislatorModal.toggle(this.legislator)
    },
    getCommitteesList() {
      this.loading = true
      return this.getStateCommittees(this.selectedStateId).then(response => {
        this.loading = false
        this.editCommitteeList = response.data
      })
    },
    getStatesList() {
      this.loading = true
      return this.getStates().then(response => {
        this.loading = false
        this.stateList = response.data
      })
    },
    getClientRegionsList() {
      this.loading = true
      this.clientRegions = []

      if (this.legislator.leg_region) {
        return this.getClientRegions(this.legislator.id).then(response => {
          this.loading = false
          if (response.data && response.data.length) {
            this.clientRegions = response.data
          }
        })
      } else {
        this.loading = false
        this.clientRegions = []
      }
    },
    getOccupationsList() {
      this.loading = true
      this.getOccupations().then(response => {
        this.loading = false
        this.occupationList = response.data
      })
    },
    getDistricts() {
      this.loading = true
      this.getStateUnelectedDistricts(this.selectedStateId).then(response => {
        this.loading = false
        this.unelectedDistricts = response.data
      })
    },
    load() {
      this.isModalOpen = false
      this.refresh()
    },
    openAssignmentsModal() {
      this.$refs.assignmentModal.toggle(this.legislator)
    },
    async getMembersList() {
      let params = {
        unlimit: 1,
        showInactive: 0,
      }
      try {
        this.memberList = (await this.getClientMembersWithPagination(this.selectedClient.id, params)).data.data
      }
      catch (e) {
        console.error(e)
      }
    },
    getConnectionsList() {
      this.getConnections(this.selectedClient.id, this.legislator.id).then((response) => {
        this.connections = response.data.data ? response.data.data : response.data
        this.connections = this.connections.map(item => {
          if(item.legislator_committees && item.legislator_committees.length > 0) {
            let membership = {}
            let committee = {}
            item.legislator_committees.forEach(legislatorCommittee => {
              legislatorCommittee.membership_states.forEach(membershipState => {
                if (membershipState.committee_membership_id !== 4 ) {
                  membership = membershipState.membership
                  committee = {...legislatorCommittee.committee, subcommittee: membershipState.pivot.subcommittee }
                }
              })
            })
            return {
              ...item,
              membership: membership,
              committee: committee
            }
          } else {
            return item
          }
        })
      })
    },
  },
  watch: {
    selectedMembers: function () {
      if (this.selectedMembers.length === this.members.length) {
        this.allMembers = true
      } else {
        this.allMembers = false
      }
    },
    isModalOpen(newValue, oldValue) {
      if(newValue === false) {
        // this.$refs.map.innerHTML = null;
      }
    },
    'legislator.district_id'(newValue, oldValue){
      if(newValue !== oldValue) {
        this.isMapLoad = false;
        setTimeout(()=>{
          this.isMapLoad = true;
        }, 100);
      }
    },
    'activeTabIndex'(newValue){
      if(newValue === 3) {
          this.fetchDonationHistory()
      }
    },
  }
}
</script>

<style scopped>
.connections_title{
  font-size: 15px;
  margin-left: -10px;
}
.legislator_card__image {
  max-width: fit-content !important;
  overflow: hidden;
}
.legislator_card__image .legislators_icon--faded { 
  display: block;
  right: 10px !important;
  bottom: 0px !important;
}
</style>
