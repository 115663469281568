import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Inactive KPs")]),_c(VSpacer),_c(VIcon,{staticClass:"ml-3",on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("close")])],1),_c(VDivider),(_vm.isLoading)?_c(VSkeletonLoader,{attrs:{"type":"list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"}}):_c(VList,{staticClass:"py-0",attrs:{"two-line":""}},[_vm._l((_vm.list.items),function(item,index){return [_c(VListItem,{key:item.id + index},[(item.avatar_url)?_c(VListItemAvatar,{style:(_vm.kpPreferenceBorder(item))},[_c(VImg,{attrs:{"src":item.avatar_url}})],1):_c(VListItemAvatar,{staticClass:"justify-center",style:(_vm.kpPreferenceBorder(item)),attrs:{"color":_vm.getColor(item.fullname)}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(item.fullname[0]))])]),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(item.fullname)}}),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.member_legislator.map(legislator => legislator.legislator.lastname).join(', '))+" ")])],1)],1)]})],2),_c(VCardText,[_c(VPagination,{attrs:{"disabled":_vm.isLoading,"length":_vm.list.pagination.last_page,"total-visible":_vm.list.pagination.last_page >= 5 ? 5 : _vm.list.pagination.last_page},on:{"input":function($event){return _vm.updatePaginate(_vm.selectedSession, _vm.selectedClient)}},model:{value:(_vm.list.pagination.page),callback:function ($$v) {_vm.$set(_vm.list.pagination, "page", $$v)},expression:"list.pagination.page"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }