import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary","size":"24"},on:{"click":function($event){$event.stopPropagation();_vm.isModalOpen = true}}},[_vm._v("help")]),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Custom Advocate Fields Help")]),_c(VCardText,[_c('p',[_vm._v(" Custom Advocate fields are fields that you would like to add to your Advocate profiles. ")]),_c('p',[_vm._v("The different types of custom fields are...")]),_c('ul',[_c('li',[_vm._v("Text field - for any short text such as \"Member ID\". Can contain numbers or text.")]),_c('li',[_vm._v("Yes/No - a single checkbox such as \"[ ] Retired?\"")]),_c('li',[_vm._v("Checkboxes - for multi-select options such as \"Hobbies: Golf, Fishing, Running\"")]),_c('li',[_vm._v("Dropdown - for single select options such as \"Local Society: East, West, North, South\"")])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }