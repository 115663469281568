<template>
  <v-dialog max-width="500" v-model="isModalOpen">
    <v-card class="legislator-timeline">
      <v-progress-linear
        color="warning"
        indeterminate
        rounded
        height="3"
        v-if="isLoading"
      ></v-progress-linear>
      <v-card-title class="d-flex flex-nowrap">
        <v-avatar left>
          <v-img
            class="avatar_image"
            :src="legislator.official_image ? getThumbImage(legislator.official_image) : '/images/member-avatar.png'"
          ></v-img>
        </v-avatar>
        <v-spacer></v-spacer>
        <h3>{{ legislator.fullname }} Connections</h3>
        <v-spacer></v-spacer>
        <v-icon @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-flex xs12 class="px-2">
        <v-list dense class="pa-0">
          <template v-for="connection in connections">
            <v-list-item :key="connection.id" @click="openDetail(connection)">
              <v-list-item-avatar small class="avatar_image" v-if="connection.official_image">
                  <img
                      :alt="connection.fullname"
                      :src="getThumbImage(connection.official_image)"
                      :style="kpPreferenceBorder(connection)"
                  >
              </v-list-item-avatar>
              <v-list-item-avatar small
                  v-if="!connection.official_image"
                  :style="kpPreferenceBorder(connection)"
                  :color="getColor(connection.fullname)"
                  class="justify-center"
              >
                <span class="white--text headline">{{ connection.nickname }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                  <v-list-item-title>
                      {{ connection.fullname }}
                  </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-flex>
    </v-card>
    <legislator-detail-modal ref="legislatorDetailModal"></legislator-detail-modal>
  </v-dialog>
</template>

<script>
import LegislatorService from '@/services/LegislatorService'
import legislatorDetailModal from '@/components/legislators/legislator-detail-modal'


export default {
  name: 'legConnectionsSmallModal',
  mixins: [LegislatorService],
  components: {
    'legislator-detail-modal': legislatorDetailModal,
  },
  props: {
    clientId: {
      default: null,
      type: Number
    }
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      legislator: {},
      connections: []
    }
  },
  methods: {
    async toggle(legislator) {
      this.isModalOpen = true
      this.isLoading = true
      this.legislator = {...legislator}
      await this.getConnectionsList()
      this.isLoading = false
    },
    getConnectionsList() {
      this.getConnections(this.clientId, this.legislator.id).then((response) => {
        this.connections = response.data.data ? response.data.data : response.data
      })
    },
    openDetail(connection) {
      this.isModalOpen = false
      this.$refs.legislatorDetailModal.toggle(connection)
    }
  }
}
</script>
