import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VForm,{ref:"downloadForm",on:{"submit":function($event){$event.preventDefault();return _vm.onDownloadTemplate()}}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Download Advocate File Template")]),(_vm.isBusy)?_c(VProgressCircular,{staticClass:"ml-2",attrs:{"indeterminate":"","color":"primary","size":"20","width":"2"}}):_vm._e()],1),_c(VDivider),_c(VCardText,[_c(VLayout,{attrs:{"wrap":""}},_vm._l((_vm.cols),function(column,index){return _c(VFlex,{key:column,staticClass:"px-2",attrs:{"xs6":"","md4":""}},[_c(VCheckbox,{attrs:{"color":"primary","label":column,"value":_vm.fields[index],"readonly":index < 6},model:{value:(_vm.fields[index]),callback:function ($$v) {_vm.$set(_vm.fields, index, $$v)},expression:"fields[index]"}})],1)}),1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},nativeOn:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")]),_c(VBtn,{attrs:{"type":"submit","color":"primary","loading":_vm.isBusy}},[_vm._v("Download")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }