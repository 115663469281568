import axios from 'axios'

export default {
  methods: {
    getUsers () {
      return axios.get('/users')
    },
    createUser (payload) {
      return axios.post('/users', payload)
    },
    updateUser (payload) {
      return axios.put(`/users/${payload.id}`, payload)
    },
    deleteUser (userId) {
      return axios.delete(`/users/${userId}`)
    },
    verifyPassword (payload) {
      return axios.post('/users/verify-password', payload)
    },
    verifyPhone (payload) {
      return axios.post(`/users/verify-phone`, payload)
    },
    verifyCode (payload) {
      return axios.post(`/users/verify-code`, payload)
    },
    disable2fa (payload) {
      return axios.post(`/users/disable-2fa`, payload)
    },
    verifyUserTwoFactorCode(payload) {
      return axios.post('/users/verify-user-login-two-factor', payload)
    },
    sendDeactivateCode () {
      return axios.post(`/users/send-two-factor-deactivate-code`)
    },
    deactivateTwoFactorCode (payload) {
      return axios.post(`/users/deactivate-two-factor-code`, payload)
    },
    sawMessage (userId, payload) {
      return axios.post(`/users/${userId}/saw-message`, payload)
    },
    markMessageAsUnread(userId, payload) {
      return axios.post(`/users/${userId}/unsaw-message`, payload)
    },
    markAllMessagesAsRead(userId) {
      return axios.post(`/users/${userId}/saw-all-messages`)
    },
    resendInvite(userId) {
      return axios.post(`/users/${userId}/invite`)
    }
  }
}
