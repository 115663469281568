<template>
  <v-dialog persistent max-width="700" v-model="isModalOpen">
    <v-card>
      <v-icon class="d-flex justify-end pt-2 pr-2" @click.native="closeModal()">close</v-icon>
      <v-card-title>
        <span class="title">What type of message are you sending?</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout class="mt-5 kp-tasks-page_actions" row>
          <v-flex xs12 sm6 class="pa-2">
            <v-tooltip bottom max-width="400">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    v-on="on"
                    color="primary"
                    block
                    @click="$emit('onShowUnique')"
                  >
                    ONE MESSAGE PER KP
                  </v-btn>
                </div>
              </template>
              <span>Use this if you are sending a simple message such as "Hello {kp_first}, thanks for all you do" to each KP selected
                 and you are not including legislator variables such as legislator name,
                 district, phone, etc. Each KP will get only one message regardless of how
                 many legislators they are assigned.
              </span>
            </v-tooltip>
          </v-flex>
          <v-flex xs12 sm6 class="pa-2">
            <v-tooltip bottom max-width="400">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    v-on="on"
                    color="primary"
                    block
                    @click="$emit('onShowDuplicate')"
                  >
                    ONE MESSAGE PER KP/LEGISLATOR
                  </v-btn>
                </div>
              </template>
              <span>Use this option if you are including legislator variables such as "Please
                call {leg_name} at {leg_phone}." KPs will get one message
                 for each legislator row that is selected.
              </span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ShowingUniqueModal',
  data() {
    return {
      isModalOpen: false
    }
  },
  methods: {
    closeModal() {
      this.isModalOpen = false
      this.$emit('switchBeforeShowingUnique')
    }
  }
}
</script>
