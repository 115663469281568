<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">Custom Advocate Fields Help</v-card-title>
        <v-card-text>
        <p>
        Custom Advocate fields are fields that you would like to add to your Advocate profiles. 
        </p>
        <p>The different types of custom fields are...</p>
        <ul>
        <li>Text field - for any short text such as "Member ID". Can contain numbers or text.</li>
        <li>Yes/No - a single checkbox such as "[ ] Retired?"</li>
        <li>Checkboxes - for multi-select options such as "Hobbies: Golf, Fishing, Running"</li>
        <li>Dropdown - for single select options such as "Local Society: East, West, North, South"</li>
        </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
