<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-navigation-drawer fixed temporary right touchless width="600" v-model="isModalOpen">
    <v-form ref="sessionForm" @submit.prevent="onUpdateSession()">
      <v-card style="box-shadow: none !important;">
        <v-card-title>
          <span class="title">Edit Session</span>
          <v-spacer />
          <v-icon @click="isModalOpen = false">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout wrap>
            <v-flex xs12 class="px-2 mt-4 mb-3">
              <v-text-field
                label="Session Name*"
                outlined
                dense
                hide-details
                :rules="nameRules"
                v-model="session.name"
                @input="error = ''"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2 mb-3">
              <v-textarea label="Internal Notes" outlined dense hide-details v-model="session.notes"></v-textarea>
            </v-flex>
            <v-flex xs4 class="px-2 mb-3">
              Start Date & Time: <br /> 
              <strong>{{ sessionStartDateTime }}</strong>
            </v-flex>

            <template v-if="!(session && (session.isActive || session.isLast))">
              <v-flex xs4 class="px-2 mb-3">
                End Date & Time: <br /> 
                <strong>{{ getFormatDate(session.end_at, 'MMM D, YYYY hh:mm A') }}</strong>
              </v-flex>
            </template>
            <template v-if="session && (session.isActive || session.isLast)">
              <v-flex xs4 class="px-2 mb-3 mt-2">
                <v-date-picker2
                  :min="endMinDate"
                  label="End Date"
                  dense="dense"
                  :rules="endAtRules"
                  v-model="sessionEndDate"
                  :isNormalDate="true"
                ></v-date-picker2>
              </v-flex>
              <v-flex xs4 class="px-2 mb-3 mt-2">
                <v-menu
                  ref="timeMenu"
                  v-model="timeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="sessionEndtime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  :disabled="!session.end_at"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="getFormattedTime('hh:mm A')"
                      label="Select time"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!session.end_at"
                      hide-details
                      dense="dense"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeMenu"
                    v-model="sessionEndtime"
                    :min="endMinTime"
                    color="primary"
                    full-width
                    ampm-in-title
                    @click:minute="$refs.timeMenu.save(sessionEndtime)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
            </template>

            <v-flex xs12 class="px-2">
              <span>KP instructions</span>
              <editor
                :api-key="TINY_MCE_API_KEY"
                :plugins="tinyPlugins"
                :toolbar="tinyToolbars"
                :init="tinyInit"
                v-model="session.alert"
              />
              <span class="text-counter" :class="alertLength > 600 ? 'error--text' : ''">
                {{ alertLength }}/600
              </span>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-autocomplete
                multiple
                chips
                label="House committees to track this session"
                item-text="fullname"
                item-value="id"
                :items="houseCommitteeList"
                v-model="session.house_committees"
                v-if="client.state && client.state.house_districts">
                <template v-slot:selection="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                          v-on="on"
                          label
                          dark
                          close
                          :input-value="props.selected"
                          :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                          class="chip--select-multi"
                          @click:close="props.parent.selectItem(props.item)"
                      >
                        {{ props.item.shortname }}
                      </v-chip>
                    </template>
                    <span>{{ props.item.fullname }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
              <v-autocomplete
                multiple
                chips
                label="Senate committees to track this session"
                item-text="fullname"
                item-value="id"
                :items="senateCommitteeList"
                v-model="session.senate_committees"
                v-if="client.state && client.state.sen_districts">
                <template v-slot:selection="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-on="on"
                        label
                        dark
                        close
                        :input-value="props.selected"
                        :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                        class="chip--select-multi"
                        @click:close="props.parent.selectItem(props.item)"
                      >
                        {{ props.item.shortname }}
                      </v-chip>
                    </template>
                    <span>{{ props.item.fullname }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
              <v-autocomplete
                multiple
                chips
                label="Joint committees to track this session"
                item-text="fullname"
                item-value="id"
                :items="jointCommitteeList"
                v-model="session.joint_committees"
                v-if="client.state && client.state.has_joint_committees">
                <template v-slot:selection="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-on="on"
                        label
                        dark
                        close
                        :input-value="props.selected"
                        :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                        class="chip--select-multi"
                        @click:close="props.parent.selectItem(props.item)"
                      >
                        {{ props.item.shortname }}
                      </v-chip>
                    </template>
                    <span>{{ props.item.fullname }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
              <v-autocomplete
                multiple
                chips
                label="Legislators to ignore in this session"
                item-text="fullname"
                item-value="id"
                :items="legislators"
                v-model="session.ignored_legislators">
                <template v-slot:selection="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                          v-on="on"
                          label
                          dark
                          close
                          :input-value="props.selected"
                          :color="props.item.color ? props.item.color : getColorFromText(props.item.fullname)"
                          class="chip--select-multi"
                          @click:close="props.parent.selectItem(props.item)"
                      >
                        {{ props.item.fullname }}
                      </v-chip>
                    </template>
                    <span>{{ props.item.fullname }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-4">
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy" :disabled="alertLength > 600">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import swal from 'sweetalert2'
import ClientService from "@/services/ClientService";
import SessionService from '@/services/SessionService'

import VDatePicker2 from '@/components/common/date-picker'
import TinyConfig from "@/mixins/tiny-mce-config";
import Editor from "@tinymce/tinymce-vue";
import moment from 'moment';

export default {
  name: 'EditSessionModal',
  props: {
    activeSession: {
      type: Object,
      default: function () {
        return {}
      }
    },
    sessionList: {
      type: Array,
      default: function () {
        return []
      }
    },
    committeeList: {
      type: Array,
      default: function () {
        return []
      }
    },
    legislators: {
      type: Array,
      default: function () {
        return []
      }
    },
    refresh: {
      type: Function
    }
  },
  mixins: [SessionService, TinyConfig, ClientService],
  components: {
    VDatePicker2,
    'editor': Editor,
  },
  data () {
    return {
      step: 1,
      active:true,
      isModalOpen: false,
      client: {},
      session: {},
      error: '',
      isBusy: false,
      minDate: '',
      timeMenu: false,
      sessionStartDateTime: null,
      sessionEndDate: null,
      sessionEndtime: null,
    }
  },
  computed: {
    nameRules () {
      return [
        v => !!v || 'Name is required.',
      ]
    },
    startAtRules () {
      return [
        v => !!v || 'Start date is required.',
      ]
    },
    endAtRules () {
      if (!this.session.start_at) return []
      return [
        v => !!v || 'End date is required.',
        v => (!!v && v > this.session.start_at) || 'End date should be after the start date.'
      ]
    },
    alertLength() {
      return this.session && this.session.alert ? this.session.alert.length : 0
    },
    houseCommitteeList () {
      return this.committeeList.filter(committee => committee.type === 0)
    },
    senateCommitteeList () {
      return this.committeeList.filter(committee => committee.type === 1)
    },
    jointCommitteeList () {
      return this.committeeList.filter(committee => committee.type === 2)
    },
    endMinDate() {
      //If selected end_at is large from current date
      // Then timepicker should not be validation
      const now = new Date();
      return this.formatDate(now, 'YYYY-MM-DD');
    },
    endMinTime() {
      const timezone = this.getTimezone(this.client);
      //If selected end_at is large from current date
      // Then timepicker should not be validation
      const currentDate = moment().tz(timezone).format('YYYY-MM-DD');
      const endDate = moment(this.sessionEndDate).format('YYYY-MM-DD');
      if(currentDate === endDate){
        return moment().tz(timezone).add(10, 'minutes').format('HH:mm:ss');
      }
    },
  },
  methods: {
    toggle (client, session) {
      this.isModalOpen = true
      this.step = 1
      this.error = ''
      this.session = {};
      const clientCommitteeIds = this.committeeList && this.committeeList.map(committee => committee.id)
      this.$nextTick().then(() => {
        this.client = client
        if(!session && !session.length) {
          this.session = {
            ...session,
            alert: "",
            house_committees: [],
            senate_committees: [],
            joint_committees: [],
            ignored_legislators: [],
          }
        } else {
          this.session = {
            ...session,
            alert: (session.alert || ""),
            house_committees: (session.house_committees.filter(item => clientCommitteeIds.includes(item)) || []),
            senate_committees: (session.senate_committees.filter(item => clientCommitteeIds.includes(item)) || []),
            joint_committees: (session.joint_committees.filter(item => clientCommitteeIds.includes(item)) || []),
            ignored_legislators: (session.ignored_legislators || []),
          }
        }
     
        if (this.client && this.client.id) {
          this.getLastSession()
        }
      })
    },
    getFormatDate(date, format){
      return moment(date).format(format);
    },
    getFormattedTime(format) {
      return this.sessionEndtime ? moment(this.sessionEndtime, "hh:mm:ss").format(format? format:'HH:mm:ss') : ''
    },
    async getLastSession() {
      if(this.session && !this.session.isActive) {
        const data = (await this.getLastActiveSession(this.client.id)).data;
        if(this.session.id === data.id ){
          this.session = {
            ...this.session,
            isLast: true
          };
        }
      }
      // if(data) {
      //   let momentObj = moment(data.end_at);
      //   momentObj.add(1, 'days');
      //   this.minDate = momentObj.format('YYYY-MM-DD');
      // };
      this.sessionStartDateTime = this.getFormatDate(this.session.start_at, 'MMM D, YYYY hh:mm A');
      this.sessionEndDate = this.session.end_at;
      this.sessionEndtime = moment(this.session.end_at).format('HH:mm:ss');
    },
    validate () {
      let isValid = true
      if (this.activeSession && this.activeSession.id !== this.session.id && this.session.end_at > moment().format('YYYY-MM-DD')) {
        this.error = 'Can\'t create more active sessions.'
        isValid = false
        return isValid
      }
      const sessionList = this.sessionList.filter(session => session.id !== this.session.id)
      sessionList.forEach((session) => {
        if ((session.start_at <= this.session.start_at && session.end_at >= this.session.start_at)
          || (session.start_at <= this.session.end_at && session.end_at >= this.session.end_at)
          || (session.start_at >= this.session.start_at && session.end_at <= this.session.end_at)) {
          this.error = 'Session period can\'t be overlapped.'
          isValid = false
        }
      })
      return isValid
    },
    onUpdateSession () {
      if (!this.$refs.sessionForm.validate()) return
      if (!this.validate()) return
      this.isBusy = true
      
      const endDate = moment(this.sessionEndDate).format('YYYY-MM-DD');
      const updatedSessionData = { 
        ...this.session,
        start_at: moment(this.session.start_at).format('YYYY-MM-DD HH:mm:ss'),
        end_at: `${endDate} ${this.getFormattedTime()}`
      };

      this.updateSession(updatedSessionData)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'Session has been updated.', 'Success!')
          this.$emit('refresh')
          this.step = 1
          this.isModalOpen = false
          this.refresh()
        })
        .catch(() => {
          this.isBusy = false
          this.error = 'Something went wrong!'
        })
    },
    next() {
      let stepName = `step${this.step}`
      if (!this.$refs[stepName].validate()) return
      this.step = this.step + 1
    }
  },
  watch: {
    // 'client': function () {
    //   if (this.client && this.client.id ) {
    //     this.getLastSession()
    //   }
    // }
  }
}
</script>
