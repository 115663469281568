<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">Legislator Page Help</v-card-title>
        <v-card-text>
          Here you will find all of the current legislators for your state. You may save a star rating and notes for each Legislator by clicking the [+] icon. These
          ratings and notes are private and only seen by Admins/Coordinators - not KPs.
          <br /><br />
          Legislators occasionally leave office before an election. If you see a legislator that is no longer current, please notify us!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
