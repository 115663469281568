<template>
  <v-flex xs12 class="d-flex justify-center align-center px-2">
    <h1 style="color: #9155FD">{{ displayTime }}</h1>
  </v-flex>
</template>

<script>
export default {
  props: {
    countdownDuration: {
      type: Number,
      required: true,
    },
    startsAt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      displayTime: "00:00:00",
      countdownInterval: null,
    };
  },
  mounted() {
    this.startCountdown();
  },
  computed: {
    countdownEndDate() {
      return this.startsAt
        ? moment.utc(this.startsAt)
          .add(this.countdownDuration, 'minutes')
        : null
    }
  },
  methods: {
    startCountdown() {
      if (!this.startsAt) return;

      this.updateCountdown();
      this.countdownInterval = setInterval(this.updateCountdown, 1000);
    },
    updateCountdown() {
      const currentTime = moment.utc();
      const timeDifference = moment.duration(this.countdownEndDate.diff(currentTime));

      if (timeDifference.asMilliseconds() <= 0) {
        clearInterval(this.countdownInterval);
        this.displayTime = "00:00:00";
      } else {
        this.displayTime = moment.utc(timeDifference.asMilliseconds())
          .format("HH:mm:ss");
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  },
};
</script>
