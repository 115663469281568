<template>
  <v-dialog max-width="400px" v-model="isModalOpen">

    <v-card class="upload-avatar-modal">
      <v-avatar
        width="100%"
        height="auto"
        tile
        class="cursor-pointer bordered_avatar"
      >
        <img v-if="member.avatar_url" alt="Avatar" :src="member.avatar_url"/>
        <img v-else alt="Avatar" :src="'/images/member-avatar_old.png'"/>
      </v-avatar>
      <v-btn class="close-button" icon dark @click="close">
        <v-icon>close</v-icon>
      </v-btn>
      <div class="action">
        <v-btn color="primary action_button mr-2" @click="$refs.avatarUploader.click()">
          Upload
        </v-btn>
        <v-btn color="primary action_button" @click="$refs.WebCamModal.open()">
          Use Camera
        </v-btn>
      </div>
    </v-card>
    <input ref="avatarUploader" type="file" accept="image/*" class="d-none" @change="onUploadAvatar()">
    <web-cam-modal
      ref="WebCamModal"
      :member="member"
      @upload="uploadPhoto"
    ></web-cam-modal>
  </v-dialog>
</template>

<script>
import CloudinaryService from "@/services/CloudinaryService";
import WebCamModal from "@/components/my-kp/web-cam-modal";
import MemberService from "@/services/MemberService";

export default {
  name: 'UploadAvatarModal',
  mixins: [CloudinaryService, MemberService],
  components: {
    'web-cam-modal': WebCamModal
  },
  data() {
    return {
      isModalOpen: false,
      member: {}
    }
  },
  methods: {
    open(member) {
      this.member = member
      this.isModalOpen = true
    },
    onUploadAvatar() {
      const file = this.$refs.avatarUploader.files[0]
      this.onUpdateMember(file)
    },
    async onUpdateMember(file) {
      if (!file) return
      await this.uploadToCloudinary(file)
        .json((res) => {
          this.member.avatar_url = res.secure_url
          this.member = {...this.member}
          this.onUpdateMember()
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
        })

      this.updateMember(this.member)
        .then(() => {
          this.isBusy = false
          this.$emit('update')
          this.onCallToastr('success', 'Kp\'s avatar has been updated.', 'Success!')
          this.isModalOpen = false
        })
        .catch((err) => {
          this.handleError(err)
        })
      this.isModalOpen = false
    },
    uploadPhoto(file) {
      this.onUpdateMember(file)
    },
    close() {
      this.isModalOpen = false
    }
  }
}
</script>
