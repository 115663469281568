<template>
  <v-layout align-center>
    <span class="ag-grid-clickable-cell" >{{ params.value }}</span>
  </v-layout>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  //
})
</script>
