<template>
  <v-dialog persistent max-width="400" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <span class="title">Inactive KPs</span>
        <v-spacer />
        <v-icon class="ml-3" @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-skeleton-loader
        v-if="isLoading"
        type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
      ></v-skeleton-loader>

      <v-list v-else two-line class="py-0">
        <template v-for="(item, index) in list.items">
          <v-list-item :key="item.id + index">
            <v-list-item-avatar v-if="item.avatar_url" :style="kpPreferenceBorder(item)" >
              <v-img :src="item.avatar_url"></v-img>
            </v-list-item-avatar>

            <v-list-item-avatar v-else class="justify-center" :style="kpPreferenceBorder(item)" :color="getColor(item.fullname)">
              <span class="white--text headline">{{ item.fullname[0] }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.fullname"></v-list-item-title>
              <v-list-item-subtitle>
                {{ item.member_legislator.map(legislator => legislator.legislator.lastname).join(', ') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-card-text>
        <v-pagination
          :disabled="isLoading"
          v-model="list.pagination.page"
          :length="list.pagination.last_page"
          :total-visible="list.pagination.last_page >= 5 ? 5 : list.pagination.last_page"
          @input="updatePaginate(selectedSession, selectedClient)"
        ></v-pagination>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SessionService from '@/services/SessionService'

export default {
  mixins: [SessionService],
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      selectedSession: null,
      selectedClient: null,
      list: {
        pagination: {
          page: 1,
          itemsPerPage: 5,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
        },
        items: [],
      },
    }
  },
  methods: {
    open(sessionId, clientId) {
      this.list.items = []
      this.isModalOpen = true
      this.selectedSession = sessionId
      this.selectedClient = clientId

      this.updatePaginate(sessionId, clientId)
    },
    updatePaginate(session, client) {
      this.isLoading = true

      let params = new Object();

      params.page = this.list.pagination.page;
      params.limit = this.list.pagination.itemsPerPage;
      params.client_id = client;

      return this.inactiveKP(session, params).then((response) => {
        this.list.items = response.data.data ? response.data.data : response.data
        this.list.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.list.pagination.current_page = response.data.current_page
        this.list.pagination.per_page = response.data.per_page
        this.list.pagination.last_page = response.data.last_page

        this.isLoading = false
      }, () => {
        this.isLoading = false
      })
    }
  }
}
</script>
