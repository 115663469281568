import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"710","fullscreen":_vm.isMobile},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"messageForm",staticClass:"fill-height message-form-container"},[_c(VCard,{staticClass:"fill-height",attrs:{"tile":""}},[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Send Us Feedback")]),_c(VSpacer),_c(VIcon,{staticClass:"ml-3",nativeOn:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" close ")])],1),_c(VDivider),_c(VCardText,[(_vm.error)?_c(VAlert,{attrs:{"dense":"","type":"error","outlined":"","value":true}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c(VFlex,{staticClass:"px-2",attrs:{"xs12":"","id":"messageBlock"}},[_c(VLayout,{staticClass:"mb-2",attrs:{"align-end":""}},[_c(VFlex,{staticClass:"d-flex body_variables",attrs:{"xs12":""}},[_c('div',{staticClass:"caption grey--text",class:_vm.messageValidate ? 'error--text' : ''},[_vm._v("Message*")]),_c(VSpacer)],1)],1),_c('div',{staticClass:"tiny_editor"},[_c('div',{class:_vm.disabledEditor ? 'disabledEditor' : ''}),(!_vm.isBusy)?_c('editor',{attrs:{"api-key":_vm.TINY_MCE_API_KEY,"plugins":_vm.tinyPlugins,"toolbar":_vm.tinyToolbars,"init":_vm.tinyInit,"disabled":_vm.disabledEditor},on:{"input":_vm.inputEmail},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}):_vm._e()],1)],1)],1),_c(VDivider,{staticClass:"mt-3"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},nativeOn:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.isBusy,"disabled":this.message.length === 0},nativeOn:{"click":function($event){return _vm.onSend.apply(null, arguments)}}},[_vm._v(" Send ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }