import axios from 'axios'

export default {
  methods: {
    getThreadViewMessages(clientId, params) {
      return axios.get(`/clients/${clientId}/get-thread-view-messages`, { params })
    },
    getMessageGroups(clientId, params) {
      return axios.get(`/clients/${clientId}/get-message-groups`, { params })
    },
    getConversation(clientId, params) {
      return axios.get(`/clients/${clientId}/get-conversation`, { params })
    },
    toReadConversationMessage(clientId, payload) {
      return axios.post(`/clients/${clientId}/to-read-message`, payload)
    },
  }
}
