<template>
  <div style="margin-right:100px;" :class="isMobile ? 'mobile-search-container' : 'search-container'">
    <div class="d-flex align-center">
      <v-autocomplete
        :items="searchList"
        ref="searchAuto"
        outlined
        dense
        clearable
        placeholder="Search Advocates, KPs, or Legislators"
        item-text="fullname"
        item-value="id"
        :prepend-icon="isMobile? 'mdi-magnify' : ''"
        :append-icon="isMobile ? '' : ''"
        allow-overflow
        :full-width="isMobile"
        hide-details
        no-filter
        hide-no-data
        :search-input.sync="searchInputFull"
        no-data-text="No one found by that name"
        :menu-props="{ maxHeight: '100%', maxWidth: '400px' }"
      >
        <template v-slot:selection="{ item }">
          {{ searchInputFull }}
        </template>
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <div class="d-flex align-center" style="width: 100%" @click="openDetailModal(data.item)">
              <v-list-item-avatar v-if="data.item.official_image" class="avatar_image">
                <v-img
                  :style=colorsLegislatorsParties(data.item,data,2)
                  :src="getThumbImage(data.item.official_image)"
                  :alt="data.item.fullname"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-avatar v-else-if="data.item.avatar_url" class="avatar_image">
                <v-img
                  :src="getThumbImageMedium(data.item.avatar_url)"
                  :alt="data.item.fullname"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-avatar
                v-else
                :style="kpPreferenceBorder(data.item)"
                :color="getColor(data.item.fullname)"
                class="justify-center"
              >
                <span class="white--text headline">{{ data.item.fullname[0] }}</span>
              </v-list-item-avatar>
              <v-list-item-content  >
                <v-list-item-title v-html="" v-if="data.item.group === 'Advocates'">
                  {{ `${data.item.fullname}`}}
                  <sup>{{ data.item.is_kp1 && data.item.activeKP1 ? 'KP1' : '' }}</sup>
                  <v-tooltip bottom v-if="data.item.lame_duck">
                    <template v-slot:activator="{ on }">
                      <a v-on="on">
                        <v-icon color="#EECB23" size="16" class="mx-2">fa-solid fa-duck</v-icon>
                      </a>
                    </template>
                    <span>Lame Duck</span>
                  </v-tooltip>
                </v-list-item-title>
                <v-list-item-title v-html="" v-if="data.item.group === 'Legislators'">
                  {{ `${data.item.fullname}` }} {{
                    data.item.member_legislators && data.item.member_legislators.length > 0 ?
                      `(KP1: ${data.item.member_legislators[0].member.fullname})` : ''
                  }}
                  <v-tooltip bottom v-if="data.item.lame_duck">
                    <template v-slot:activator="{ on }">
                      <a v-on="on">
                        <v-icon color="#EECB23" size="16" class="mx-2">fa-solid fa-duck</v-icon>
                      </a>
                    </template>
                    <span>Lame Duck</span>
                  </v-tooltip>
                </v-list-item-title>
                <v-list-item-subtitle v-html="data.item.person">
                  {{ `${data.item.person}` }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </div>

          </template>
        </template>
      </v-autocomplete>
      <v-btn
        v-if="!isMobile"
        :class="isDarkTheme ? 'search-icon-dark' : 'search-icon'"><i
        class="fa-thin fa-magnifying-glass ma-auto"></i></v-btn>
    </div>

    <member-detail-modal
      ref="memberDetailModal"
      :selectedClient="userClient"
    ></member-detail-modal>
    <edit-member-modal ref="editMemberModal"></edit-member-modal>
    <legislator-detail-modal ref="legislatorDetailModal"></legislator-detail-modal>
    <edit-legislator-modal ref="editLegislatorModal"></edit-legislator-modal>
  </div>
</template>

<script>
import AppService from "../../services/AppService";
import memberDetailModal from '@/components/members/member-detail-modal.vue'
import editMemberModal from '@/components/members/edit-member-modal.vue'
import legislatorDetailModal from '@/components/legislators/legislator-detail-modal.vue'
import editLegislatorModal from '@/components/legislators/edit-legislator-modal.vue'

export default {
  name: 'SearchComponent',
  mixins: [AppService],
  components: {
    'member-detail-modal': memberDetailModal,
    'edit-member-modal': editMemberModal,
    'legislator-detail-modal': legislatorDetailModal,
    'edit-legislator-modal': editLegislatorModal,
  },
  data() {
    return {
      searchValue: '',
      searchList: [],
      searchInputFull: '',
      searchTimer: null,
    }
  },
  methods: {
    renderList() {
      if (this.searchInputFull) {
        return this.getList()
      } else {
        return []
      }
    },
    async getList() {
      if (this.searchInputFull) {
        let params = {search: this.searchInputFull}
        const response = (await this.search(this.userClientId, params))

        let members = []
        let legislators = []
        if (response.data && response.data.members && response.data.members.length > 0) {
          let res = response.data.members.map((item) => {
            return {...item, group: 'Advocates', person: 'Advocate' }
          })
          members = [{header: 'Advocates'}, ...res.slice(0, 3), {divider: true}]
        }
        if (response.data && response.data.legislators && response.data.legislators.length > 0) {
          let res = response.data.legislators.map((item) => {
            return {...item, group: 'Legislators', person: 'Legislator'}
          })
          legislators = [{header: 'Legislators'}, ...res.slice(0, 3)]
        }
        this.searchList = [...members, ...legislators]
      } else {
        this.searchList = []
      }
    },
    openMemberDetailModal(person) {
      this.$refs.memberDetailModal.toggle(person)
    },
    openLegislatorDetailModal(person) {
      this.$refs.legislatorDetailModal.toggle(person)
    },
    doSearch() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.getList()
      }, 500)
    },
    openDetailModal(item) {
      if (item.group === 'Advocates') {
        this.openMemberDetailModal(item)
      } else if (item.group === 'Legislators') {
        this.openLegislatorDetailModal(item)
      }
    }
  },
  watch: {
    searchInputFull: function (value) {
      if (value && (value.length > 2 || value.length === 0)) {
        this.doSearch()
      }
    }
  }
}
</script>

<style lang="scss">

</style>
