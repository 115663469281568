<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">Session Dashboard Help</v-card-title>
        <v-card-text>
          This is where you will monitor all Session Tasks, notes, donations, etc. This
          page will allow you to see everything that is relevant to your current Session in one page.
          <br /><br />
          If you do not have an active Session, either cextend the previous Session (by giving it a future end date), or create a new Session. 
          You must have an active Session at all times.
          <br /><br />
          To send Messages, click the "Send Message" button and then you may select recipients and filters. 
          <br /><br />
          Show or hide columns by using the "Edit Columns" button in the submenu (behind the 3-dot icon). You may also Export what you see using the "Export" button.
          <br /><br />
          <v-chip
            small
            class="ma-2"
            color="primary"
            label
            href="https://help.kpdashboard.com/en/articles/8867317-sending-messages"
            target="_blank"
          >
            How to send messages
            <v-icon right small>
              mdi-open-in-new
            </v-icon>
           </v-chip>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
