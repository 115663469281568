export default {
  methods: {
    loadCustomFields (selectedClientId, clear = true) {
        this.isLoading = true
        const clientId = selectedClientId ? selectedClientId : this.userClientId
        if (!clientId) return
        return this.getClientFields(clientId).then(response => {
          if (this.filter.customFields && this.filter.customFields[0] && this.filter.customFields[0].client_id === clientId && clear === false) return
          this.filter.customFields = response.data.map(item => {
            if (item.type == 'text') return { ...item, value: '' }
            if (item.type == 'checkbox') {
              const metadata = item.metadata.map(meta => { return { ...meta, type: 'default' }})
              return { ...item, metadata: metadata, value: [] }
            }
            if (item.type == 'dropdown') {
              const metadata = item.metadata.map(meta => { return { ...meta, checked: false }})
              return { ...item, metadata: metadata, value: [] }
            }
            if (item.type == 'toggle') return { ...item, value: false }
          })
          this.isLoading = false
        })
    },
    collectCustomFieldsFilter () {
      const result = []

      this.filter.customFields.forEach(item => {
        if (item.type == 'text' || item.type == 'toggle')
          if (item.value)
            result.push({ id: item.id, value: item.value })

        if (item.type == 'checkbox') {
          const values = item.metadata.filter(meta => typeof meta.type === 'number')
          if (values && values.length) {
            result.push({
              id: item.id,
              value: values.map(value => value.name),
              type: values.map(value => value.type)
            })
          }
        }

        if (item.type == 'dropdown') {
          const value = item.metadata.filter(meta => meta.checked).map(meta => meta.name)
          if (value && value.length)
            result.push({ id: item.id, value: value })
        }
      })
      return result
    }
  }
}
