import Vue from 'vue'

Vue.mixin({
  data() {
    return {
      latestArticle: {}
    }
  },
  methods: {
    getArticles() {
      if (!(this.client && this.client.id)) return
      this.isLoading = true
      this.getResourcesList(this.client.id).then(response => {
        this.articlesList = response.data
        let today = new Date();
        this.articlesList.filter(item => {
          if (!item.allowed_to_coordinators && this.userData.role == 'Coordinator') {
            let position = this.articlesList.indexOf(item)
            this.articlesList.splice(position, 1)
          } else if (!item.allowed_to_kps && this.userData.role == 'KP') {
            let position = this.articlesList.indexOf(item)
            this.articlesList.splice(position, 1)
          }
        })
        let newArr = this.articlesList
        newArr.forEach(item => {
          let d1 = new Date(item.created_at)
          let d2 = new Date(item.updated_at);
          let delta = Math.max(d1.getTime(), d2.getTime())
          item['delta'] = delta
        })
        this.latestArticle = newArr.sort((a, b) => Math.abs(today.getTime() - a.delta) - Math.abs(today.getTime() - b.delta))[0];
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    getBadge(item) {
      let dateCreate = new Date(item.created_at),
        dateUpdate = new Date(item.updated_at),
        today = new Date(),
        deltaCreate = today.getTime() - dateCreate.getTime(),
        deltaUpdate = today.getTime() - dateUpdate.getTime(),
        resultCreate = Math.floor(deltaCreate/1000/60/60/24),
        resultUpdate = Math.floor(deltaUpdate/1000/60/60/24);

      if (resultCreate <= 90) {
        return 'New'
      } else if (deltaUpdate != deltaCreate && resultUpdate <= 60) {
        return 'Updated'
      } else {
        return
      }
    }
  }
})
