<template>
  <v-dialog max-width="550" v-model="dialog" @click:outside="close">
    <v-card>
      <v-card-title>
      <span>Notice...</span>
      </v-card-title>
      
      <v-card-text class="py-3">
      <h3>
          Due to complex regulations regarding the content of emails and/or SMS, this messaging system may only be used for Advocacy purposes.
      </h3><br /><br />
        <v-checkbox
          color="primary"
          hide-details
          class="mt-0 mb-6 admins-copies_checkbox user-invitation_Checkbox"
          v-model="isProceed"
        >
          <template v-slot:label>
            I certify that this message's contents are advocacy-related.
          </template>
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click="close">Cancel</v-btn>
        <v-btn color="primary" :disabled="!isProceed" @click="proceed">Proceed</v-btn>
      </v-card-actions>
    </v-card>
    <member-message-modal
      ref="memberMessageModal"
      :parentFilter="parentFilter"
      :legislators="legislators"
      :committeeList="committeeList"
      :clientRegions="clientRegions"
    ></member-message-modal>
  </v-dialog>
</template>

<script>
import MemberMessageModal from '@/components/members/member-message-modal'

export default {
  name: 'ConfirmationDialog',
  components: {
    'member-message-modal': MemberMessageModal
  },
  props: {
    parentFilter: {
      type: Object,
      default: function () {
        return {
          search: '',
          showInactive: null,
          showInvalid: null,
          districtNotSet: null,
          activeKP1: null,
          kpPreferenceResults: [],
          customFields: [],
          committees: [],
          commiteeTypeId: null,
          subcommitee: false,
        }
      }
    },
    committeeList: {
      type: Array,
      default: function () {
        return []
      }
    },
    legislators: {
      type: Array,
      default: function () {
        return []
      }
    },
    clientRegions: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      dialog:false,
      isProceed: false
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    proceed() {
      this.$refs.memberMessageModal.toggle()
      this. close()
    },
    close() {
      this.isProceed = false
      this.dialog = false
    }
  }
}
</script>
