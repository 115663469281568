<template>
  <v-container fluid grid-list-lg class="pa-5">
    <h1>KP Help Page</h1>
    <p>Use the following diagram as a quick and dirty reference; however, see below for individual explanations of how to perform specific functions as a Key Person (KP).</p>
            <v-card
          class="mx-auto pa-16"
          max-width="850"
          justify-center
          >
                  <v-card-text>
                    <v-img 
                    contain
                    style="max-width: 750px;" 
                    src="https://downloads.intercomcdn.com/i/o/263594000/3c9febf7fb03e71cade9d93d/image.png" 
                    alt=""
                    >
                    </v-img>
                    <br />
                    <h2 id="faq" data-post-processed="true">FAQ</h2>
                    <h3 data-post-processed="true">How do I...</h3>
                    <br />
            <ol>
              <li><b>Log into the Dashboard?</b><br />KPs do not have a traditional username/password to log into KP Dashboard. KPs instead use ez-login links which will automatically log you directly into the Dashboard. See #2 for details.</li><br />
              <li><b>Get an ez-login link? </b><br />There are 2 ways to request an ez-login link:<br />1. You may go to kpdashboard.com and click "Sign In". BE SURE TO CLICK THE "KP LOGIN" TAB. There you will input your cell phone number or email address to request an ez-login link. If you run into problems, please contact your Admin or Regional Coordinator<br />2. Simply contact either your Regional Coordinator or an Admin to have them send you an ez-login link.</li><br />
              <li><b>See my legislators' details?</b><br />Click on the legislators button to bring up a window with more details. Inside this detail window, you will find pertinent notes, social media links, biography, email, phone number, and other helpful information.</li><br />
              <li><b>Switch between legislators?</b><br />If you have been assigned more than 1 legislator (look at you!), then you will have multiple legislator tabs at the top (Legislator 1, Legislator 2, etc). Just click through each to move between your legislators.</li><br />
              <li><b>Contact my backup KPs (KP2,3,4 etc)?</b><br />If your legislator has been assigned a secondary, tertiary, or other KPs, you will see the list just below your name. You can use the email and phone icons to contact them. It is always good practice to keep your other KPs in the loop as they may one day be KP1.</li><br />
              <li><b>See the list of constituents (Advocates who live or work in this district)?</b><br />Click on the "View Constituents" button which will bring up a window showing you all of the Advocates of your organization that live or work in this district. These people are important to the legislator!</li><br />
              <li><b>Contact a single constituent?</b><br />Just hover over or click on the Mail or Phone icons to contact an Advocate. You will need to use your phone or your own email program to make this contact.</li><br />
              <li><b>Solicit donations from this constituent list?</b><br />Click on the "View Constituents" button and then click on the "Sample Donation Request" button. You can leave the pre-formatted email as is or click "Edit" to make changes. This will send an email requesting a $100 donation from each constituent which is important to bring to any future meeting with a legislator!</li><br />
              <li><b>See my tasks and their deadlines?</b><br />If your Admins have assigned tasks, you will see them below in the main area. You can hover over the (?) icons to read details about each task. Also, each task that has a deadline (some may not) will show the deadline date next to it.</li><br />
              <li><b>Check off my tasks after I complete them?</b><br />If your Admins have assigned tasks, you will see them as checkboxes in the main body of the KP Tasks page. Simply check off each completed task. If you have no pertinent notes about the current legislative push (conversations with the legislator, meeting notes, etc) then you can click Save and be done. BE SURE TO CLICK SAVE FOR EACH LEGISLATOR.</li><br />
              <li><b>Save my donations or other constituent donations into the Dashboard?</b><br />If your Admins have set up Donation fields, you will see them under the task checkboxes. There may be 2 fields, 1 for your personal donations to the legislator and a 2nd for any other donations you may have brought with you to a meeting using the "Sample Donation Request" button as seen in #8 above.</li><br />
              <li><b>Save pertinent Notes about conversations or anything else?</b><br />In the "Pertinent Session Notes" area, input notes such as:<br />- "meeting went well, Senator Smith is on our side"<br />- "could not get ahold of legislator, but I donated $200 and left a message"<br />- "legislator would like to learn more about our cause over coffee next week"<br />- "legislator came to my office and we had a great conversation. I gave her the PAC check"</li><br />
            </ol>
                  </v-card-text>
            </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "KPHelpPage"
  }
</script>

