<template>
  <v-dialog persistent max-width="750" v-model="isTestMessageModalOpen">
    <v-card :loading="isTestMessageLoading">
      <v-card-title>
        <span class="title">Send test to {{ !email.is_sms ? userData.email : userData.cell_phone}}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <div class="mb-6">
          <editor
            v-if="formattedMessage"
            :value="formattedMessage"
            disabled
            :api-key="TINY_MCE_API_KEY"
            :init="tinyInit"
            toolbar="false"
          />
        </div>
        <v-btn
          class="mr-4"
          outlined
          color="secondary"
          @click="closeModal"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isTestMessageLoading || loading"
          :loading="loading"
          @click.native="onSend"
        >
          Send Test
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import TinyConfig from "@/mixins/tiny-mce-config"
import ClientService from '@/services/ClientService'
import he from "he";
import {mapGetters} from "vuex";

export default {
  name: 'TestMessageModal',
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE
  },
  mixins: [TinyConfig, ClientService],
  props: {
    isEmail: {
      type: Boolean,
      default: false
    },
    email: {
      type: Object,
      default: function (){
        return {}
      }
    },
    onlyMembers: {
      type: Boolean,
      default: false
    },
    legislatorId: {
      type: Number,
      default: 0
    },
    memberId: {
      type: Number,
      default: 0
    },
    message: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isTestMessageModalOpen: false,
      isTestMessageLoading: false,
      loading: false,
      payload: {},
      formattedMessage: '',
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
  },
  methods: {
    open() {
      this.isTestMessageModalOpen = true
      this.formatTestMessage()
    },
    closeModal() {
      this.isTestMessageModalOpen = false
      this.formattedMessage = ''
    },
    async formatTestMessage() {
      if(this.message === '') {
        this.isTestMessageLoading = false
        return
      }
      this.isTestMessageLoading = true

      let payload = {
        member_id: this.memberId,
        message: this.message,
      }

      if(this.legislatorId) {
        payload.legislator_id = this.legislatorId
      }
      try {
        await this.getAppFormattedMessage(payload).then((response) => {
          this.formattedMessage = response.data
        })
        this.isTestMessageLoading = false
      } catch(e) {
        this.onCallToastr('error', 'Something went wrong, please try again later!', 'Failed!')
        this.isTestMessageLoading = false
        this.isTestMessageModalOpen = false
      }
    },
   async onSend() {
    this.loading = true

     this.payload = _.clone(this.email)

     if (typeof(this.payload.files) === "undefined") {
       this.payload.files = []
     }
     if(this.payload.subject) {
       this.payload.subject = `(Test message) ${this.email.subject}`
     } else {
       this.payload.subject = 'Test message'
     }
     if(this.payload.is_sms) {
       this.payload.message = this.html2Text(this.message)
     } else {
       this.payload.message = this.message
     }

      if(this.legislatorId){
        this.payload.member_legislator = {member_id: this.memberId, legislator_id: this.legislatorId}
      } else {
        this.payload.member = this.memberId
      }
      this.payload.clientId = this.client.id

      try {
        await this.sendTestMessage(this.payload.clientId, this.packageData(this.payload))
        this.onCallToastr('success', 'Test message sent!', 'Success!')
      } catch(e) {
        this.onCallToastr('error', 'Something went wrong, please try again later!', 'Failed!')
      } finally {
        this.loading = false
      }
     this.isTestMessageModalOpen = false
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
          re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    }
  }
}
</script>
