import axios from 'axios'
import qs from 'qs'

export default {
  methods: {
    getMembers (params = '') {
      return axios.get('/members', { params })
    },
    createMember (payload) {
      return axios.post('/members', payload)
    },
    updateMember (payload) {
      return axios.put(`/members/${payload.id}`, payload)
    },
    updateMemberLegislator (memberId, legislatorId, payload) {
      return axios.put(`/members/${memberId}/assigned-legislators/${legislatorId}`, payload)
    },
    deleteMember (memberId) {
      return axios.delete(`/members/${memberId}`)
    },
    getMemberKPHistory (params) {
      return axios.get(`/members/${params.id}/kp-history`, { params })
    },
    searchMembersByDistrict (payload) {
      return axios.post('/members/search-by-district', payload)
    },
    searchMembersByMultiDistrict (payload) {
      return axios.post('/members/search-by-multi-district', payload)
    },
    getKPTasks (memberId) {
      return axios.get(`/members/${memberId}/tasks`)
    },
    getMemberAssignedLegislators (memberId) {
      return axios.get(`/members/${memberId}/assigned-legislators`)
    },
    addKPTaskResults (payload) {
      return axios.post(`/members/${payload.id}/task-results`, payload)
    },
    getMemberAdvocacyMessages (memberId) {
      return axios.get(`/members/${memberId}/advocacy-messages`)
    },
    getMemberAdvocacyMessageHistory (memberId) {
      return axios.get(`/members/${memberId}/advocacy-message-history`)
    },
    sendMessageToClient (payload) {
      return axios.post(`/members/${payload.id}/send-message`, payload)
    },
    getPoliticalLeanings (params = '') {
      return axios.get(`/get-member-political-leanings`, {params})
    },
    updatePoliticalLeanings(params) {
      return axios.post(`/member-political-leanings`, params)
    },
    getPoliticalIssues (params = '') {
      return axios.get(`/get-member-political-issues`, {params})
    },
    getOldMemberToken (memberId, params = '') {
      return axios.get(`/members/${memberId}/get-old-token`, {params})
    },
    updateMembersDistricts(clientId, payload) {
      return axios.post(`/clients/${clientId}/update-members-district`, payload)
    },
    cancelImport(clientId, payload = '') {
      return axios.post(`/clients/${clientId}/cancel-migration`, payload)
    },
    storeCurrencyTaskResult(legislatorId, payload = '') {
      return axios.post(`/legislators/${legislatorId}/store-currency-task-result`, payload)
    },
    getImportRows(clientId, params = '') {
      return axios.get(`/clients/${clientId}/get-import-rows`, {params})
    },
    subscribeToLegislatorNews(authUserId, payload) {
      return axios.post(`/members/${authUserId}/subscribe-to-legislator-news`, payload)
    },
    preImportAnalyze(clientId, payload) {
      return axios.post(`/clients/${clientId}/pre-analyze-import`, payload)
    },
    getFirstKps(clientId, params = '') {
      return axios.get(`/clients/${clientId}/get-kps`, {params})
    },
    getOneOfKps(clientId, params = '') {
      return axios.get(`clients/${clientId}/one-of-kps`, {params})
    },    
    getMemberProfileUpdates(clientId, params) {
      return axios.get(`/clients/${clientId}/member-profile-updates`, {
        params: params, paramsSerializer: params => qs.stringify(params)
      })
    },
    getDistrictLegislator(clientId, payload) {
      return axios.post(`/clients/${clientId}/district-legislator`, payload)
    },
  }
}
