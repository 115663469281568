<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg>
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>User Sessions</h1>
        <v-spacer></v-spacer>
        <v-btn color="primary" small :loading="isLoading" @click="refresh">
          Refresh
        </v-btn>
        <v-btn v-if="client && client.id" color="primary" small class="ml-2"  @click="reloadPage">
          Reload All Client
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-spacer></v-spacer>
      <v-flex>
        <v-text-field append-icon="search" label="Search" single-line clearable hide-details class="search-input" v-model="search"></v-text-field>
      </v-flex>
    </v-layout>

    <v-data-table
      :loading="isLoading"
      :headers="userSessionTableHeader"
      :options.sync="pagination"
      :server-items-length="pagination.totalItems"
      :items="userSessionList"
      @update:options="getUserSessionList"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:item.client.assoc_abbrev="{ item }">
        <div>{{ item.client ? item.client.assoc_abbrev : '' }}</div>
      </template>

      <template v-slot:item.user.lastname="{ item }">
        <div>{{ item.user.nickname }} {{ item.user.lastname }}</div>
      </template>

      <template v-slot:item.user.role="{ item }">
        <div>{{ item.user.role }}</div>
      </template>

      <template v-slot:item.user.email="{ item }">
        <div>{{ item.user.email }}</div>
      </template>

      <template v-slot:item.created_at="{ item }">
        <div>{{ item.created_at | dateTimeFormatter }}</div>
      </template>

      <template v-slot:item.created_at_time_ago="{ item }">
        <div>{{ item.created_at_time_ago }}</div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No user session yet.</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ClientService from '@/services/ClientService'
import UserSessionService from '@/services/UserSessionService'
import {mapGetters} from "vuex";

export default {
  name: 'UserSessionsPage',
  mixins: [ClientService, UserSessionService],
  data () {
    return {
      isLoading: false,
      search: '',
      userSessionList: [],
      pagination: {
          page: 1,
          itemsPerPage: 10,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
          sortBy: [],
          sortDesc: [],
        }
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    userSessionTableHeader () {
      return [
        { text: 'Client Abbrev', value: 'client.assoc_abbrev', align: 'left', sortable: false },
        { text: 'User', value: 'user.lastname', align: 'center', sortable: false },
        { text: 'Role', value: 'user.role', align: 'center', sortable: false },
        { text: 'Email', value: 'user.email', align: 'center', sortable: false },
        { text: 'Logged In At', value: 'created_at', align: 'center' },
        { text: 'Since Last Login', value: 'created_at_time_ago', align: 'center', sortable: false },
      ]
    },
  },
  mounted () {
    this.getUserSessionList()
  },
  methods: {
    refresh(){
      this.pagination.userSessionList = []
      this.pagination.page = 1
      this.pagination.current_page = 0
      this.getUserSessionList()
    },
    getUserSessionList () {
      this.isLoading = true

      let params = {
        clientId: this.client && this.client.id ? this.client.id : null,
        page: this.pagination.page,
        limit: this.pagination.itemsPerPage,
        order: this.pagination.sortBy[0],
        direction: this.pagination.sortDesc[0] || !this.pagination.sortDesc.length ? 'desc' : 'asc',
        search: this.search
      }

      this.getUserSessions(params).then((response) => {
        this.userSessionList = response.data.data ? response.data.data : response.data
        this.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.pagination.current_page = response.data.current_page
        this.pagination.per_page = response.data.per_page
        this.pagination.last_page = response.data.last_page

        this.isLoading = false
      }, () => {
        this.isLoading = false
      })
    },

    reloadPage() {
      window.location.reload();
    }
  },
  watch: {
      'search': function() {
        clearTimeout(this.timer)
        this.timer = setTimeout(()=> {
          this.refresh()
        }, 500)
      },
      'client': function () {
        this.refresh()
      }
    },
  
}
</script>
