<template>
  <div>
    <!-- Vip image code -->
    <div v-if="show === 'image_code'">
      <v-icon :color="iconColor" class="fa-solid fa-star mr-1" size="19px"></v-icon>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip :color="iconColor" small light v-on="on">VIP</v-chip>
        </template>
        <span>{{ vipNote }}</span>
      </v-tooltip>
      <v-icon  :color="iconColor" class="fa-solid fa-star ml-1" size="19px"></v-icon>
    </div>
    <!-- Vip table code -->
    <div v-if="show === 'table_code'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip :color="iconColor" small light v-on="on">VIP</v-chip>
        </template>
        <span>{{ vipNote }}</span>
      </v-tooltip>
    </div>
    <!-- Vip SD greed code -->
    <v-tooltip bottom v-if="show === 'chip_code'">
      <template v-slot:activator="{ on, attrs }">
        <v-chip :color="iconColor" style="height: 12px" x-small light v-on="on"><span>VIP</span></v-chip>
      </template>
      <span>{{ vipNote }}</span>
    </v-tooltip>
    <!-- Vip my legislators code -->
    <v-tooltip bottom v-if="show === 'badge_code'">
      <template v-slot:activator="{ on, attrs }">
        <v-chip :color="iconColor" small light v-on="on"><span>VIP</span></v-chip>
      </template>
      <span>{{ vipNote }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "VipChip",
  props: {
    legislator: {
      type: Object,
      default: () => {
        return {}
      }
    },
    show: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconColor: '#ffd700'
    }
  },
  computed: {
    vipNote () {
      let vip = this.legislator.vip_status.filter(item => item.end_date === null)
      return vip && vip.length > 0 ? vip[0].note : null
    }
  },
  methods: {
  }
}
</script>
