import Vue from 'vue'
import App from '@/App.vue'

import '@/plugins/vuex'
import store from '@/store'

import '@/plugins/moment'
import '@/plugins/ps'
import '@/plugins/axios'
import '@/plugins/lodash'
import '@/plugins/toastr'
import '@/plugins/vue-meta'
import '@/plugins/google-maps'
import '@/plugins/ag-grid'
import '@/plugins/currency-filter'
import '@/plugins/intercom'
import '@/plugins/mask'
import '@/plugins/router'
import '@/plugins/laravel-echo'

import router from '@/router/index'

import vuetify from '@/plugins/vuetify'

import '@/helpers/app'
import '@/mixins/api'
import '@/mixins/modals'
import '@/mixins/app'
import '@/mixins/resize'
import '@/mixins/files'
import '@/mixins/resources'
import '@/mixins/members-functions'
import '@/mixins/kpPreferenceBorder'

import '@/mixins/filters'

import '@/scss/main.scss'
import '@/scss/v2/main.scss'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-pro/css/all.css'

import * as Sentry from "@sentry/vue";

Vue.use(vuetify, {
  iconfont: 'fa'
})

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT ? process.env.VUE_APP_SENTRY_ENVIRONMENT : 'local',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^\//],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
