<template>
  <div style="height: 100%">
    <client-settings-modal v-if="userClient"></client-settings-modal>
    <profile-modal></profile-modal>

    <app-toolbar class="header"></app-toolbar>

    <app-sidebar v-if="!isMember && !isProfileUpdate"></app-sidebar>
    <v-main app>
      <transition name="fade" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </v-main>
  </div>
</template>

<script>
  import toolbar from '@/components/global/toolbar'
  import clientSettingsModal from '@/components/global/client-settings-modal'
  import profileModal from '@/components/global/profile-modal'
  import sidebar from '@/components/global/sidebar'

  export default {
    components: {
      'app-toolbar': toolbar,
      'client-settings-modal': clientSettingsModal,
      'profile-modal': profileModal,
      'app-sidebar': sidebar,
    },
    computed: {
      isProfileUpdate(){
        return localStorage.getItem('onlyProfileUpdate')
      },
    },
    mounted () {
      if(this.isCoordinator || this.isVIP) {
        this.$intercom.boot({
          name: this.userName,
          email: this.userEmail,
          user_type: this.userRole,
          assoc_abbrev: this.userClient ? this.userClient.assoc_abbrev : '',
          assoc_profession: this.userClient ? this.userClient.profession : '',
          state: this.userClient ? this.userClient.state.state : '',
        })
      }
    }
  }
</script>
