<template>
  <v-dialog :fullscreen="isMobile" :max-width="isDesktop || isDevice ? '600px' : ''" v-model="isModalOpen">
    <div class="web-cam">
      <div class="video">
        <vue-web-cam
          v-show="!isCapturePressed"
          class="video_cam"
          :deviceId="deviceId"
          ref="webcam"
          @cameras="getCamerasList"
        />
        <div v-if="isCapturePressed" class="web-cam-screenshot">
          <img :src="imgSrc" alt="">
        </div>
        <v-progress-circular
            indeterminate
            color="primary"
            size="50"
            width="2"
            v-if="loading"
        ></v-progress-circular>
      </div>
      <div v-if="!isCapturePressed" class="action">
        <v-btn class="action_button mr-2" color="primary" :block="isMobile" @click="toggleCapture">
          Capture
        </v-btn>
        <v-btn class="action_button" color="primary" :block="isMobile" @click="cancel">
          Cancel
        </v-btn>
      </div>
      <div v-else class="action">
        <v-btn class="action_button mr-2" color="primary" :block="isMobile" @click="onUpload">
          Use Photo
        </v-btn>
        <v-btn class="action_button mr-2" color="primary" :block="isMobile" @click="toggleCapture">
          Retake
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
  import {WebCam} from "vue-web-cam";

  export default {
    name: 'WebCamModal',
    components: {
      'vue-web-cam': WebCam,
    },
    props: {
      member: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
        isModalOpen: false,
        isCapturePressed: false,
        loading: false,
        imgSrc: '',
        deviceId: null
      }
    },
    methods: {
      open() {
        this.loading = true
        this.isModalOpen = true
        this.isCapturePressed = false
      },
      toggleCapture() {
        this.loading = false
        this.capture()
        this.isCapturePressed = !this.isCapturePressed
      },
      capture() {
        this.imgSrc = this.$refs.webcam.capture()
      },
      onUpload() {
        const file = this.imgSrc
        this.urlToFile(file, 'user_avatar.jpg', 'image/jpg')
          .then((file) => {
            this.$emit('upload', file)
          });
      },
      urlToFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) {
              return res.arrayBuffer();
            })
            .then(function (buf) {
              return new File([buf], filename, {type: mimeType});
            })
        );
      },
      cancel() {
        this.isModalOpen = false
      },
      getCamerasList(data) {
        this.deviceId = data[0].deviceId
        this.$refs.webcam.start()
        this.loading = false
      }
    },
  }
</script>
