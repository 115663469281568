<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog max-width="600" v-model="isModalOpen">
    <v-form ref="newUserForm" @submit.prevent="onAddUser()">
      <v-card>
        <v-card-title>
          <span class="title">Add New Team Member</span>
          <v-spacer />
          <v-icon @click="close()">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="error" outlined class="mb-2" :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout wrap class="mt-4">
            <v-flex xs6 class="px-2">
              <v-layout align-baseline >
                <v-select
                  outlined
                  dense
                  label="Role*"
                  :items="roleList"
                  item-value="value"
                  item-text="title"
                  :error-messages="getErrorText('role')"
                  v-model="newUser.role"
                >
                </v-select>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon size="20" v-on="on">help</v-icon>
                  </template>
                  <ul>
                    <li>
                      Admin: All-Powerful Admins
                      <ul>
                        <li>Can be connected to an active Advocate or not</li>
                        <li>Can see everything</li>
                        <li>Can add new Advocates and team members</li>
                        <li>Can edit options</li>
                      </ul>
                    </li>
                    <li v-if="false">
                      KP - Individual Key Persons
                      <ul>
                        <li>Always connected to an Active Advocate</li>
                        <li>Can only see “KP Task Results”, “District Lookup”, and Advocacy Message menu items</li>
                        <li>Can only see the Legislators for which they are responsible in the “KP Task Results” menu item</li>
                      </ul>
                    </li>
                  </ul>
                </v-tooltip>
              </v-layout>
            </v-flex>
            <v-flex xs6 v-if="newUser.role" class="px-2">
              <v-checkbox
                class="mt-0"
                color="primary"
                label="Active"
                v-model="newUser.active"
              ></v-checkbox>
            </v-flex>
            <div class="d-flex flex-wrap" v-if="newUser.role">
              <v-flex xs12 class="px-2 py-0" v-if="newUser.role == 'VIP'">
                <v-checkbox
                  color="primary"
                  v-model="clientMemberActive"
                  label="Connect this user to an Advocate"
                ></v-checkbox>
              </v-flex>
              <v-flex v-if="(clientMemberActive && newUser.role == 'VIP')" xs12
                      class="px-2">
                <v-autocomplete
                  label="Member*"
                  outlined
                  dense
                  :item-text="item => `${item.nickname} ${item.lastname} ${hasCoordinator(item)}`"
                  item-value="id"
                  :items="newUser.role === 'Super Admin' || newUser.role === 'VIP' ? clientMembers : memberList"
                  :rules="memberRules"
                  v-model="newUser.member_id"
                  @input="newUser.role === 'Super Admin' || newUser.role === 'VIP' ? onChangeMember(clientMembers) : onChangeMember(memberList)"
                />

              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  outlined
                  dense
                  label="Nick Name*"
                  :error-messages="getErrorText('nickname')"
                  v-model="newUser.nickname"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  outlined
                  dense
                  label="Last Name*"
                  :error-messages="getErrorText('lastname')"
                  v-model="newUser.lastname"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  :key="'email'"
                  outlined
                  dense
                  v-model="newUser.email"
                  label="Email*"
                  :error-messages="errors && errors.email && errors.email[0] ? errors.email[0] : ''"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  outlined
                  dense
                  v-model="newUser.cell_phone"
                  label="Cell Phone"
                  :rules="phoneNumberRules"
                  v-mask="'(###) ###-####'"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 class="px-2">
                <address-input
                  dense="dense"
                  placeholder="Mailing Address for Donation Requests"
                  v-model="newUser.mailing_address"
                ></address-input>
              </v-flex>
              <v-flex xs12 class="px-2" v-if="newUser.role == 'VIP'">
                <v-switch
                  color="primary"
                  hide-details
                  label="Receive completed KP task notifications"
                  v-model="newUser.receive_task_notifications"
                ></v-switch>
              </v-flex>
              <v-flex xs12 class="px-2" v-if="newUser.role == 'VIP'">
                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on }">
                    <v-switch
                      color="primary"
                      hide-details
                      label="Receive copies of selected outgoing messages"
                      v-model="newUser.receive_copy_messages"
                      v-on="on"
                    ></v-switch>
                  </template>
                  <span>Checking this will allow this Admin to receive copies of outgoing messages if the box is checked before sending.</span>
                </v-tooltip>
              </v-flex>
              <v-flex xs12 class="px-2" v-if="newUser.role == 'VIP'">
                <v-switch
                  color="primary"
                  hide-details
                  label="Receive all incoming message notifications"
                  v-model="newUser.receive_message_notifications"
                ></v-switch>
              </v-flex>
              <v-flex xs12 class="px-2" v-if="newUser.role == 'VIP'">
                <v-switch
                  color="primary"
                  hide-details
                  label="Receive all incoming system notifications"
                  v-model="newUser.receive_system_notifications"
                ></v-switch>
              </v-flex>
            </div>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="close()">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">Send Invitation</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import UserService from '@/services/UserService'
import ClientService from '@/services/ClientService'

import AddressInput from '@/components/common/address-input.vue'

export default {
  props: {
    client: {
      type: Object,
      default: () => {
        return {}
      }
    },
    memberList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mixins: [UserService, ClientService],
  components: {
    AddressInput,
  },
  data () {
    return {
      isModalOpen: false,
      newUser: {},
      error: '',
      errors: {},
      isBusy: false,
      clientMembers: [],
      clientMemberActive: false,
    }
  },
  computed: {
    memberRules () {
      return [
        v => !!v || 'Associated member is required.',
      ]
    },
    roleList () {
      let roleList = [
        { title: 'Super Admin', value: 'Super Admin' },
        { title: 'Admin', value: 'VIP' },
        { title: 'KP', value: 'KP' },
        { title: 'Leg Support', value: 'Leg Support' }
      ]
      if (this.isVIP) {
        roleList = [
          { title: 'Admin', value: 'VIP' },
        ]
      }
      if (this.isSuperAdmin) {
        roleList = [
          { title: 'Admin', value: 'VIP' },
          { title: 'Leg Support', value: 'Leg Support' }
        ]
      }
      if (!(this.client && this.client.uses_regions)) roleList = roleList.filter(role => role.value !== 'Coordinator')
      return roleList
    },
  },
  methods: {
    hasCoordinator (user) {
      if (user && user.coordinator) return ` (Region ${user.coordinator.toString().padStart(2, '0')})`
      return ``
    },
    toggle () {
      this.isModalOpen = true
      this.error = ''
      this.$nextTick().then(() => {
        this.newUser = {
          active: true,
        }
      })
      if(!!this.client) {
        this.getClientMembers(this.client.id).then(response => {
          this.clientMembers = response.data
        })
      }
    },
    onChangeMember (list) {
      if (!this.newUser.member_id) return
      const member = list.find(member => member.id === this.newUser.member_id)
      this.newUser = {
        role: this.newUser.role,
        active: true,
        member_id: this.newUser.member_id,
        lastname: member.lastname,
        nickname: member.nickname,
        email: member.email,
        mailing_address: member.work_address,
        cell_phone: member.cell,
        receive_task_notifications: this.newUser.receive_task_notifications,
        receive_copy_messages: this.newUser.receive_copy_messages,
        receive_message_notifications: this.newUser.receive_message_notifications,
        receive_system_notifications: this.newUser.receive_system_notifications
      }
    },
    close() {
      this.errors = {}
      this.isModalOpen = false
    },
    onAddUser () {
      this.errors = false
      if (!this.$refs.newUserForm.validate()) return
      this.isBusy = true
      const payload = {
        ...this.newUser,
        client_id: !!this.client ? this.client.id : null,
        member_id: ['VIP', 'KP'].includes(this.newUser.role) ? this.newUser.member_id : null,
      }
      this.createUser(payload)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'Invitation sent!', 'Success!')
          this.$emit('refresh')
          this.errors = {}
          this.isModalOpen = false
        })
        .catch((err) => {
          this.errors = err.response.data.errors
          this.isBusy = false
        })
    }
  }
}
</script>
