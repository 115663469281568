import axios from 'axios'

export default {
  methods: {
    getAppFormattedMessage (params) {
      return axios.post('/app/formatted-message', params)
    },
    uploadImage(payload) {
      return axios.post(`/mailgun/upload`, payload, {headers: {'Content-Type': 'multipart/form-data'}})
    },
    search(clientId, params) {
      return axios.get(`/clients/${clientId}/get-persons`, {params})
    }
  }
}
