<template>
  <div>
    <v-autocomplete
      label="Favorite Team(s)"
      color="blue-grey lighten-2 mt-3"
      :menu-props="{maxHeight: '40vh'}"
      :value="memberFavoriteTeams"
      @change="setValue"
      :search-input.sync="search"
      :items="favoriteTeam"
      item-text="name"
      item-value="id"
      :loading="loading"
      hide-details
      return-object
      outlined
      multiple
      filled
      dense
    >
      <template v-slot:selection="{item}"></template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <div class="mb-2 pt-1" >
      <div class="d-flex flex-wrap">
        <div v-for="(item, index) of memberFavoriteTeams" class="mr-2">
          <v-menu class="my-1"
            v-model="displaySelected[getIndex(item.id, memberFavoriteTeams)]"
            :key="index"
            bottom
            left
            :close-on-content-click="false"
            origin="top left"
            content-class="role_card"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                class="my-1"
                v-on="on"
                close
                outlined
                color="primary"
                :input-value="item.name"
                @click:close="$emit('remove', item)"
              >
                {{ item.name }}
              </v-chip>
            </template>
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InterestService from "@/services/InterestService";

export default {
  name: "EducationAutocomplete",
  mixins: [InterestService],
  props: {
    memberFavoriteTeams: {
      type: Array,
      default: function() {
        return []
      }
    },
    clientId: {
      type: Number,
      default: 0
    }
  },
  data: () => {
    return {
      search: '',
      favoriteTeam: [],
      displaySelected: {},
      loading: false,
      timer: null
    }
  },
  mounted() {
    this.getFavoriteTeamList()
  },
  methods: {
    getIndex(id, array) {
      let index = array.findIndex(item => item.id === id)
      return index
    },
    setValue(value) {
      this.search = ''
      this.$emit('response', value)
    },
    getFavoriteTeamList() {
      this.loading = true
      const params = {
        'search': this.search,
        'total': 50
      }
      this.getFavoriteTeam(this.clientId, params).then(response => {
        this.favoriteTeam = response.data
        this.loading = false
      })
    },
  },
  watch: {
    search: function () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getFavoriteTeamList()
      }, 500)
    },
  }
}
</script>
