<template>
  <v-layout align-center>
    <span v-if="!isFullNameHidden" class="no-line-break cursor-pointer">
      {{ rowData.fullname }}
    </span>
    <span v-else class="no-line-break">---</span>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import vipChip from '@/components/legislators/vip-setting/vip-chip'

export default Vue.extend({
  components: {
    'vip-chip': vipChip
  },
  computed: {
    rowData () {
      return this.params.node.data || {}
    },
    isFullNameHidden() {
      return this.params.node.data.hideFullName !== undefined && this.params.node.data.hideFullName === true
    }
  }
})
</script>
