<template>
  <v-btn
      color="primary"
      :min-width="isMobile ? '100%' : '110px'"
      class="my-1"
      small
      @click="onDownloadTemplate"
      :loading="isBusy"
  >
    {{ allConstituents ? 'Export All Constituents' : 'Export Home Constituents Only' }}
    <v-icon dark class="ml-2">cloud_download</v-icon>
  </v-btn>
</template>

<script>
import ClientService from '@/services/ClientService'
import {mapGetters} from "vuex";

export default {
  name: "ExportLegislatorsButton",
  mixins: [ClientService],
  props: {
    allConstituents: {
      type: Boolean,
      default: true
    },
    committees: {
      type: Array,
      default: function () {
        return []
      }
    },
    subcommittee: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isBusy: false
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
  },
  methods: {
    onDownloadTemplate() {
      this.committees.forEach(committee => {
        this.isBusy = true
        const params = {
          committee_id: committee.id,
          subcommittee: +this.subcommittee,
          all_constituents: +this.allConstituents
        }
        this.downloadConstituents(this.client.id, params).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          let abbr = this.client ? this.client.assoc_abbrev : this.client.assoc_abbrev

          let fileName
          if (this.allConstituents) {
            fileName = this.subcommittee
                ? `KPD_${abbr}_${committee.shortname}_SUB_HOMEandWORK_constituents_${moment().format('MM-DD-YYYY')}.xlsx`
                : `KPD_${abbr}_${committee.shortname}_HOMEandWORK_constituents_${moment().format('MM-DD-YYYY')}.xlsx`;
          } else {
            fileName = this.subcommittee
                ? `KPD_${abbr}_${committee.shortname}_SUB_HOME_constituents_${moment().format('MM-DD-YYYY')}.xlsx`
                : `KPD_${abbr}_${committee.shortname}_HOME_constituents_${moment().format('MM-DD-YYYY')}.xlsx`;
          }

          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          this.isBusy = false
        }, error => {
          this.isBusy = false
          console.error(error)
        })
      })
    },
  }
}
</script>

