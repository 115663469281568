<template>
  <v-dialog max-width="500" v-model="dialog" @click:outside="close">
    <v-card class="message-modal-content">
      <v-card-title>
        <span class="title">
         Copy to what client?
          <v-progress-circular
              indeterminate
              color="primary"
              size="20"
              width="2"
              v-if="loading"
          ></v-progress-circular>
        </span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-3">
        <v-select
            v-if="isSuperAdmin"
            label="Client"
            item-text="assoc_abbrev"
            item-value="id"
            :items="clientList"
            v-model="form.selectedClientId"
        >
        </v-select>
        <div v-if="!form.id">
          <v-select
              v-model="form.type"
              :error-messages="errorMessages('type')"
              :items="fields"
              item-value="value"
              item-text="title"
              label="Select field type"
          >
          </v-select>
        </div>
        <div v-if="form.type == 'text'">
          <v-text-field label="Field label" v-model="form.name" :error-messages="errorMessages('name')"></v-text-field>
        </div>
        <div v-if="form.type == 'checkbox'">
          <v-text-field label="Field label" v-model="form.name" :error-messages="errorMessages('name')"></v-text-field>
          <div v-if="form.metadata && form.metadata.length">
            <div v-for="(item, index) in form.metadata" :key="index">
              <v-text-field
                v-if="!form[item.name]"
                v-model="item.name"
                append-outer-icon="close"
                @click:append-outer="removeOption(index)"
                :label="`Option #${index + 1}`"
              >
              </v-text-field>
              <v-text-field
                v-else
                v-model="item.name"
                disabled
                append-outer-icon="close"
                :label="`Option #${index + 1}`"
              >
              </v-text-field>
            </div>
          </div>
          <div>
            <v-btn @click="addOption" small block color="info">add option</v-btn>
          </div>
        </div>
        <div v-if="form.type == 'toggle'">
          <v-text-field label="Field label" v-model="form.name" :error-messages="errorMessages('name')"></v-text-field>
        </div>
        <div v-if="form.type == 'dropdown'">
          <v-text-field label="Field label" v-model="form.name" :error-messages="errorMessages('name')"></v-text-field>
          <div v-if="form.metadata && form.metadata.length">
            <div v-for="(item, index) in form.metadata" :key="index">
              <v-text-field
                v-if="!form[item.name]"
                v-model="item.name"
                append-outer-icon="close"
                @click:append-outer="removeOption(index)"
                :label="`Option #${index + 1}`"
              >
              </v-text-field>
              <v-text-field
                v-else
                v-model="item.name"
                disabled
                append-outer-icon="close"
                :label="`Option #${index + 1}`"
              >
              </v-text-field>
            </div>
          </div>
          <div>
            <v-btn @click="addOption" small block color="info">add option</v-btn>
          </div>
        </div>
        <v-row>
          <v-col>
            <v-switch
              color="primary"
              hide-details
              v-model="form.show_member"
              label="Show to user?"
              :error-messages="errorMessages('form.show_member')"
              class="mt-0"
            ></v-switch>
          </v-col>
          <v-col>
            <v-switch
              color="primary"
              v-show="form.show_member"
              hide-details
              v-model="form.edit_member"
              label="Allow user to edit"
              :error-messages="errorMessages('form.edit_member')"
              class="mt-0"
            ></v-switch>
          </v-col>
        </v-row>
        <v-switch
          color="primary"
          hide-details
          v-model="form.member_page"
          label="Show in Advocate Page Filter list?"
          :error-messages="errorMessages('form.member_page')"
        ></v-switch>
        <v-switch
          color="primary"
          hide-details
          v-model="form.legislator_page"
          label="Show in Legislator Page filter list?"
          :error-messages="errorMessages('form.legislator_page')"
        ></v-switch>
        <v-switch
          color="primary"
          hide-details
          v-model="form.message_page"
          label="Show in filters when sending Messages?"
          :error-messages="errorMessages('form.message_page')"
        ></v-switch>
        <v-switch
          color="primary"
          hide-details
          v-model="form.AM_page"
          label="Show in Advocacy Message window creation filter?"
          :error-messages="errorMessages('form.AM_page')"
        ></v-switch>
      </v-card-text>
      <v-card-text class="py-3">
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="loading" :disabled="!valid || loading">Copy</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ClientService from '@/services/ClientService'
  import fields from '@/entities/custom-fields'

  export default {
    name: "CustomFieldsFormModal",
    mixins: [ClientService],
    props: ['selectedClientId'],
    data() {
      return {
        dialog: false,
        errors: false,
        loading: false,
        fields: fields,
        clientList: [],
        fieldList: [],
        form: {
          name: '',
          type: '',
          selectedClientId: 0,
          show_member: false,
          edit_member: false,
          member_page: false,
          legislator_page: false,
          message_page: false,
          AM_page: false,
          metadata: []
        },
      }
    },
    computed: {
      valid() {
        return this.form.name && this.form.type && this.form.selectedClientId > 0
      },
      clientId() {
        return this.selectedClientId ? this.selectedClientId : this.userClientId
      }
    },
    methods: {
      async open(id) {
        this.selectedClient = {}
        this.getClientField(id)
        this.clientList = (await this.getClients()).data
        this.errors = false
        this.dialog = true
      },
      close() {
        this.form = {
          name: '',
          type: '',
          selectedClientId: 0,
          show_member: false,
          edit_member: false,
          member_page: false,
          legislator_page: false,
          message_page: false,
          AM_page: false,
          metadata: []
        }
        this.$emit('update')
        this.dialog = false
      },
      save() {
        this.loading = true
        this.form.copy = 1
        this.saveClientField(this.form.selectedClientId, this.form).then(()=> {
          this.onCallToastr('success', 'New field has been created.', 'Success!')
          this.close()
          this.loading = false
          this.errors = false
        }, err => {
          this.errors = err.response.data.errors
          this.loading = false
        })
      },
      getClientField(id) {
        this.loading = true
        this.getClientFieldById(this.clientId, id).then(response => {
          this.form = response.data
          this.loading = false
        })
      },
      errorMessages(field) {
        return (this.errors && this.errors[field]) ? this.errors[field][0] : []
      },
      addOption() {
        this.form.metadata.push({ name: '' })
      },
      removeOption(index) {
        this.form.metadata.splice(index, 1)
      }
    }
  }
</script>
