export default [
  {
    name: 'No relationship yet',
    icon: 'N',
    color: 'error'
  },
  {
    name: 'Acquaintance',
    icon: 'A',
    color: 'warning'
  },
  {
    name: 'Good Relationship',
    icon: 'GR',
    color: 'success'
  },
  {
    name: 'Personal Friend',
    icon: 'PF',
    color: 'success'
  },
  {
    name: 'Has Legislator Cell',
    icon: 'phone',
    color: 'success'
  },
  {
    name: 'Follows on Social Media',
    icon: 'fa-brands fa-twitter',
    color: 'success'
  },
]
