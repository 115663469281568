import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"content-class":"status_modal"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[(_vm.clientStatus)?_c(VCard,{staticClass:"br_none"},[_c(VCardTitle,{staticClass:"br_none"},[_c('span',{staticClass:"title"},[_vm._v("Setup")]),_c(VProgressCircular,{staticClass:"ml-4",attrs:{"rotate":-90,"size":30,"width":5,"value":_vm.success * 100 / _vm.total,"color":_vm.getProgressColor(_vm.success / _vm.total)}},[_c('div',{staticClass:"circular_text"},[_vm._v(_vm._s(Math.ceil(_vm.success * 100 / _vm.total))+"%")])]),_c(VSpacer),_c(VIcon,{staticClass:"ml-3",on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,{staticClass:"px-4 mt-3"},_vm._l((_vm.instructionList),function(instruction,index){return _c('div',{key:index,staticClass:"px-3"},[_c('div',{staticClass:"d-flex flex-nowrap"},[_c('h4',{staticClass:"mb-2"},[_vm._v(_vm._s(instruction.title))]),_c(VSpacer),_c(VBtn,{staticClass:"white--text",attrs:{"x-small":"","color":"primary","to":{name: instruction.buttonLink}}},[_vm._v(" "+_vm._s(instruction.buttonTitle)+" ")])],1),_c(VDivider),_c(VList,{staticClass:"pt-0",attrs:{"dense":""}},_vm._l((instruction.list),function(text,index){return _c(VListItem,{key:index,attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(text.title)}}),_c(VListItemSubtitle,{staticClass:"caption"},[_vm._v(_vm._s(text.body))])],1),_c(VListItemIcon,[(text.status)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"error"}},[_vm._v("cancel")])],1)],1)}),1)],1)}),0)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }