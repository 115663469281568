import Vue from 'vue'

Vue.mixin({
  data() {
    return {
      currentFilesSize: 0,
      isChild: false,
      updatedFilesSize: 0,
      fileSizeLimit: 15728640,
    }
  },
  computed: {
    fileRules() {
      return [
        files => !files || this.filesSize(files) < this.fileSizeLimit || 'Files are too big! Max for all is 15MB.' +
          ' Shrink them or consider linking instead within the email.'
      ]
    },
    maxFileSize() {
      return this.currentFilesSize > this.fileSizeLimit
    },
    updatedMaxFileSize() {
      return this.updatedFilesSize > this.fileSizeLimit
    }
  },
  methods: {
    filesSize(files) {
      let sum = 0;
      files.forEach(file => {
        sum += file.size;
      })
      this.currentFilesSize = sum
      if (this.isChild) {
        this.$emit('updateFilesSize', this.currentFilesSize)
      }
      return sum;
    },
    onFileSizeUpdate(fileSize) {
      this.updatedFilesSize = fileSize
    },
    clearFileInfo(){
      this.currentFilesSize = 0
      this.updatedFilesSize = 0
    }
  }
})
