<template>
  <v-layout column class="mt-4 mb-4">
    <v-card v-if="list.items.length > 0">
      <v-card-title>
        Legislators
      </v-card-title>
      <v-data-table
        class="elevation-1"
        :options.sync="list.pagination"
        :server-items-length="list.pagination.totalItems"
        :footer-props="tableFooter"
        :headers="legislatorTableHeader"
        :items="filteredList"
      >
        <template v-slot:item.action="{ item }">
          <div class="d-flex align-center" v-if="item && item.legislator">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click="edit(item.legislator)">
                  <v-icon size="18" color="primary">edit</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :icon="isDesktop || isDevice" :text="isDesktop || isDevice" small color="primary"
                       @click="$refs.donationModal.open(item)">
                  <v-icon size="20" class="mr-1">monetization_on</v-icon>
                </v-btn>
              </template>
              <span>Donations</span>
            </v-tooltip>
          </div>
          <v-layout v-else align-center>
            VACANT
          </v-layout>
        </template>

        <template v-slot:item.lastname="{ item }">
          <div class="d-flex align-center" v-if="item && item.legislator">
            <img
              class="ma-1"
              :style="{ 'padding-left': '1px', 'border-left': `3px solid ${partyColorList[item.legislator.party] || '#ccc'}` }"
              :src="getThumbImage(item.legislator.official_image)"
              :alt="(item.legislator.nickname || '') + ' ' + (item.legislator.lastname || '')"
              width="50"
              @click="detail(item.legislator)"
              v-if="item.legislator.official_image"
            />
            <vip-chip
              v-if="item.legislator.vip"
              :legislator="item.legislator"
              :show="'table_code'"
            ></vip-chip>
            <a class="ml-2 mr-1 no-line-break" @click="detail(item.legislator)">
              {{ item.legislator.nickname || '' }} {{ item.legislator.lastname || '' }}
            </a>
            <v-tooltip bottom v-if="item.legislator.official_phone">
              <template v-slot:activator="{ on }">
                <a :href="'tel:' + item.legislator.official_phone" v-on="on">
                  <v-icon color="primary" size="18" class="mx-2">phone</v-icon>
                </a>
              </template>
              <span>{{ item.legislator.official_phone }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.legislator.official_email">
              <template v-slot:activator="{ on }">
                <a :href="'mailto:' + item.legislator.official_email" v-on="on">
                  <v-icon color="primary" size="18" class="mx-2">email</v-icon>
                </a>
              </template>
              <span>{{ item.legislator.official_email }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.district="{ item }">
          <v-btn text small @click="districtModal(item.district)" v-if="item && item.legislator">
            <span class="clickable-cell">
              {{ item.district ? item.district : '' }}
            </span>
          </v-btn>
          <span v-else>{{ item.district }}</span>
        </template>

        <template v-if="!isSuperAdmin && userClient.uses_regions" v-slot:item.district.region="{ item }">
          <span v-if="item.region">
            <span>
              {{ item.region && item.region.name || '' }}
            </span>
          </span>
        </template>

        <template v-if="!isSuperAdmin" v-slot:item.leg_support="{ item }">
          <v-layout v-if="item && item.legislator && item.legislator.leg_data &&item.legislator.leg_data[0]">
            <v-icon
              size="18"
              color="orange lighten-3"
              v-for="value in [1, 2, 3, 4, 5]"
              :key="value"
              v-if="value <= item.legislator && item.legislator.leg_data && item.legislator.leg_data[0] && item.legislator.leg_data[0].leg_support"
            >mdi-star</v-icon>
          </v-layout>
        </template>

        <template v-slot:item.elected_at="{ item }">
          <span v-if="item && item.legislator">
            {{ item.legislator && item.legislator.elected_at ? item.legislator.elected_at.split('-')[0] : '' }}
          </span>
        </template>

        <template v-slot:item.assign="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon class="mx-0" v-on="on" :disabled="item && item.legislator && item.legislator.abdicated_at" @click="openAssignmentModal(item.legislator)">
                <v-icon size="40" color="primary">group_add</v-icon>
              </v-btn>
            </template>
            <span>Assignments</span>
          </v-tooltip>
        </template>

        <template v-slot:item.short_list="{ item, index }">
          <v-chip
            v-for="(i, index) in item && item.legislator && item.legislator.short_list_kps"
            class="mt-2"
            x-small
            pill
            :key="index + i.member.id"
            @click="memberDetailModal(i.member)"
          >
            <v-avatar left v-if="i.member && i.member.avatar_url">
              <v-img class="avatar_image" :src="i.member.avatar_url"></v-img>
            </v-avatar>
            {{ i.member && i.member.fullname || '' }}
          </v-chip>
        </template>

        <template v-slot:item.ruled_out="{ item, index }">
          <v-chip
            v-for="(i, index) in item && item.legislator && item.legislator.rules_out_kps"
            class="mt-2"
            x-small
            pill
            :key="index + i.member.id"
            @click="memberDetailModal(i.member)"
          >
            <v-avatar left v-if="i.member && i.member.avatar_url">
              <v-img class="avatar_image" :src="i.member.avatar_url"></v-img>
            </v-avatar>
            {{ i.member && i.member.fullname || '' }}
          </v-chip>
        </template>

        <template v-if="!isSuperAdmin" v-slot:item.vip_notes="{ item }">
          <div class="d-flex align-end justify-center">
            <v-tooltip bottom v-if="item.legislator && item.legislator.leg_data && item.legislator.leg_data[0] && item.legislator.leg_data[0].vip_notes" max-width="450">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on" @click="$refs.NoteModal.toggle(item)">note</v-icon>
              </template>
              <span>{{ item.legislator && item.legislator.leg_data && item.legislator.leg_data[0] && item.legislator.leg_data[0].vip_notes || '' }}</span>
            </v-tooltip>
            <v-icon v-else color="primary" @click="$refs.NoteModal.toggle(item.legislator)">add</v-icon>
          </div>
        </template>

        <template v-for="(val, index) in member_legislators_keys" v-slot:[val]="{ item }">
          <div v-if="item && item.legislator && item.legislator.member_legislators[index]" :key="index" class="d-flex align-center">
            <span class="no-line-break">
              <strong>KP{{ item.legislator && item.legislator.member_legislators[index].kp_position }}:</strong>
              <a @click="memberDetailModal(item.legislator && item.legislator.member_legislators[index].member)">
                {{ item.legislator && item.legislator.member_legislators[index] && item.legislator.member_legislators[index].member && item.legislator.member_legislators[index].member.nickname || '' }}
                {{ item.legislator && item.legislator.member_legislators[index] && item.legislator.member_legislators[index].member && item.legislator.member_legislators[index].member.lastname || '' }}
              </a>
            </span>
            <v-tooltip bottom v-if="item && item.legislator.member_legislators[index].notes" max-width="450">
              <template v-slot:activator="{ on }">
                <v-icon class="ml-1" size="18" v-on="on">note</v-icon>
              </template>
              <span>{{ item.legislator.member_legislators[index].notes }}</span>
            </v-tooltip>
            <v-tooltip bottom v-for="(relationship, j) in item.legislator.member_legislators[index].relationships" :key="j"
                       max-width="450">
              <template v-slot:activator="{ on }">
                <v-avatar size="20" :color="relationship.color" class="ml-1" v-on="on">
                  <v-icon size="14" color="white" v-if="relationship.icon == 'phone'">{{ relationship.icon }}</v-icon>
                  <v-icon size="14" color="white" v-else-if="relationship.icon == 'fa-brands fa-twitter'">{{
                      relationship.icon
                    }}
                  </v-icon>
                  <small v-else class="white--text">{{ relationship.icon }}</small>
                </v-avatar>
              </template>
              <span>{{ relationship.name }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:no-data>
          <p class="text-xs-center mb-0">No legislator registered yet.</p>
        </template>
      </v-data-table>
    </v-card>
    <donation-modal :clientId="clientId" ref="donationModal"></donation-modal>
    <note-model ref="NoteModal" @updateLegislators="updateLegislators"></note-model>
  </v-layout>
</template>

<script>
import donationModal from './donation-modal'
import LegislatorService from "@/services/LegislatorService";
import VipChip from "@/components/legislators/vip-setting/vip-chip";
import { partyColorList } from '@/entities/party-color-list'
import NoteModal from '@/components/legislators/notes-modal'

export default {
  props: {
    list: {
      default: () => {
        return {
          items: [],
          pagination: {
            last_page: 0
          }
        }
      },
      type: Object
    },
    clientId: {
      default: null,
      type: Number
    },
    initialLegislatorsArray: {
      default: () => { [] },
      type: Array
    },
    isLoading: {
      default: false,
      type: Boolean
    },
  },
  mixins: [LegislatorService],
  name: "UnassignedLegislatorsTable",
  data() {
    return {
      loading: false,
      partyColorList: partyColorList,
      member_legislators_keys: [],
      tableFooter: {
        'items-per-page-options': [24, 48, 72]
      },
      headers: [
        {text: 'District', value: 'district', align: 'center', sortable: false, width: '10%'},
        {text: 'District name', value: 'name', align: 'center', sortable: false, width: '70%'},
        {text: 'District ocd', value: 'ocd_id', align: 'center', sortable: false, width: '20%'},
      ]
    }
  },
  computed: {
    legislatorTableHeader() {
      let tableHeader = [
        {text: '', value: 'action', align: 'center', sortable: false, width: '10%'},
        {text: 'Legislator', value: 'lastname', align: 'left', sortable: false, width: '22%'},
        {text: 'District', value: 'district', align: 'center', sortable: false, width: '6%'},
        {text: 'Region', value: 'district.region', align: 'center', sortable: false, width: '6%'},
        {text: 'Support', value: 'leg_support', align: 'center', sortable: false, width: '6%'},
        {text: 'Elected', value: 'elected_at', align: 'center', sortable: false, width: '6%'},
        {text: 'KP Assignments', value: 'assign', align: 'center', sortable: false, width: '7%'},
        {text: 'Short List', value: 'short_list', align: 'center', sortable: false, width: '13%'},
        {text: 'Ruled Out', value: 'ruled_out', align: 'center', sortable: false, width: '13%'},
        {text: 'Notes', value: 'vip_notes', align: 'center', sortable: false},
      ]

      let hiddenColumns = [];
      if (this.isSuperAdmin) {
        hiddenColumns = ['Region', 'Notes', 'Support']
      } else if (!this.userClient.uses_regions) {
        hiddenColumns = ['Region']
      }

      tableHeader = tableHeader.filter((header) => !hiddenColumns.includes(header.text))
      
      let maxLength = 0
      let filteredLegislatorList = this.list.items.filter((item, index) => {
        return this.list.pagination.itemsPerPage == -1 || index <= this.list.pagination.itemsPerPage
      })

      filteredLegislatorList.forEach((legislator) => {
        if (legislator.legislator && legislator.legislator.member_legislators && legislator.legislator.member_legislators.length > maxLength) {
          maxLength = legislator.legislator && legislator.legislator.member_legislators.length
        }
      })

      return tableHeader
    },
    filteredList() {
      return this.list.items.map(item => {
          return {
            ...item,
            initialNotes: item.leg_data && item.leg_data[0] ? item.leg_data[0].vip_notes : '',
        }
      })
    }
  },
  methods: {
    edit(item) {
      this.$emit('edit', item)
    },
    addData(item) {
      this.$emit('add', item)
    },
    districtModal(item) {
      this.$emit('districtOpen', item)
    },
    updateLegislators(item) {
      this.$emit('updateLegislators', item)
    },
    detail(item) {
      this.$emit('detail', item)
    },
    memberDetailModal(item) {
      this.$emit('memberModalOpen', item)
    },
    openAssignmentModal(item) {
      this.$emit('assignment', item)
    },
  },
  watch: {
    'list.pagination.page'() {
      this.$emit('changePage', this.list.pagination.page)
    },
    'list.pagination.itemsPerPage'() {
      this.$emit('changePerPage', this.list.pagination.itemsPerPage)
    },
  },
  components: {
    donationModal,
    'vip-chip': VipChip,
    'note-model': NoteModal,
  }
}
</script>
