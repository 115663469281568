<template>
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isLoading"
    ></v-progress-linear>
    <v-card v-if="dialog">
      <v-card-text>
        <v-layout row wrap class="resources_page">
        <v-flex xs12 class="d-flex align-center flex-wrap">
          <h1>Resources</h1>
          <v-spacer></v-spacer>
          <v-icon v-if="dialog" class="ml-3" @click.native="$emit('closeModal')">close</v-icon>
        </v-flex>
        <transition name="slide-list">
          <v-flex xs12 md3 lg3 v-if="isDesktop || isDevice || (isMobile && !isShowOverview)">
            <v-card
              class="mx-auto resources_page_list"
              elevation="10"
            >
              <v-card-title>
                Resource List
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense class="pa-0">
                <template v-for="(item, index) in resourcesList">
                  <v-divider
                    v-if="index > 0"
                    :key="index"
                  ></v-divider>
                  <v-list-item
                    :key="item.title"
                    @click="showResource(item)"
                    :class="{ active: item.id == resource.id}"
                  >
                    <v-list-item-content class="d-flex">
                      <v-list-item-title v-html="item.title" style="flex:unset;"></v-list-item-title>
                      <v-badge
                        v-if="getBadge(item) == 'New'"
                        inline
                        color="success"
                        class="mt-0"
                        style="flex:unset;"
                      >
                        <template v-slot:badge>
                          <span>New</span>
                        </template>
                      </v-badge>
                      <v-badge
                        v-if="getBadge(item) == 'Updated'"
                        inline
                        color="primary"
                        class="mt-0"
                        style="flex:unset;"
                      >
                        <template v-slot:badge>
                          <span>Updated</span>
                        </template>
                      </v-badge>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-flex>
        </transition>
        <transition name="slide-resource">
          <v-flex xs12 md9 lg9 v-if="isDesktop || isDevice || isShowOverview">
            <v-card
              class="resources_page_item"
              elevation="10"
            >
              <v-card-title class="d-flex justify-space-between">
                <div class="resources_page_title d-flex">
                  <v-btn class="to_list_button" icon @click="isShowOverview = false" v-if="isMobile">
                    <v-icon size="24">chevron_left</v-icon>
                  </v-btn>
                  <div>{{ resource.title }}</div>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div v-html="resource.text" style="background-color: white; padding: 15px; color:black"></div>
                <div v-if="resource.files && resource.files.length > 0" class="mb-4">
                  <v-card-title
                    class="px-0"
                    :class="isDarkTheme ? '' : 'black--text'"
                  >
                    Secure Attachments
                  </v-card-title>
                  <ul class="articles_file-list">
                    <li v-for="(item, index) in resource.files" :key="index">
                      <a href="" @click.prevent="downloadFile(item)">
                        <v-icon color="primary" class="mr-2">mdi-{{ fileType(item.type) }}</v-icon>
                        <span>{{ item.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </transition>
      </v-layout>
      </v-card-text>
    </v-card>
    <v-layout v-else row wrap class="resources_page">
      <v-flex xs12 class="d-flex align-center flex-wrap">
        <h1>Resources</h1>
        <v-spacer></v-spacer>
        <v-icon v-if="dialog" class="ml-3" @click.native="$emit('closeModal')">close</v-icon>
      </v-flex>
      <transition name="slide-list">
        <v-flex xs12 md3 lg3 v-if="isDesktop || isDevice || (isMobile && !isShowOverview)">
          <v-card
              class="mx-auto resources_page_list"
              elevation="10"
          >
            <v-card-title>
              Resource List
            </v-card-title>
            <v-divider></v-divider>
            <v-list dense class="pa-0">
              <template v-for="(item, index) in resourcesList">
                <v-divider
                    v-if="index > 0"
                    :key="index"
                ></v-divider>
                <v-list-item
                    :key="item.title"
                    @click="showResource(item)"
                    :class="{ active: item.id == resource.id}"
                >
                  <v-list-item-content class="d-flex">
                    <v-list-item-title v-html="item.title" style="flex:unset;"></v-list-item-title>
                    <v-badge
                        v-if="getBadge(item) == 'New'"
                        inline
                        color="success"
                        class="mt-0"
                        style="flex:unset;"
                    >
                      <template v-slot:badge>
                        <span>New</span>
                      </template>
                    </v-badge>
                    <v-badge
                        v-if="getBadge(item) == 'Updated'"
                        inline
                        color="primary"
                        class="mt-0"
                        style="flex:unset;"
                    >
                      <template v-slot:badge>
                        <span>Updated</span>
                      </template>
                    </v-badge>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-flex>
      </transition>
      <transition name="slide-resource">
        <v-flex xs12 md9 lg9 v-if="isDesktop || isDevice || isShowOverview">
          <v-card
              class="resources_page_item"
              elevation="10"
          >
            <v-card-title class="d-flex justify-space-between">
              <div class="resources_page_title d-flex">
                <v-btn class="to_list_button" icon @click="isShowOverview = false" v-if="isMobile">
                  <v-icon size="24">chevron_left</v-icon>
                </v-btn>
                <div>{{ resource.title }}</div>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div v-html="resource.text" style="background-color: white; padding: 15px; color:black"></div>
              <div v-if="resource.files && resource.files.length > 0" class="mb-4">
                <v-card-title
                  class="px-0"
                  :class="isDarkTheme ? '' : 'black--text'"
                >
                  Secure Attachments
                </v-card-title>
                <ul class="articles_file-list">
                  <li v-for="(item, index) in resource.files" :key="index">
                    <a href="" @click.prevent="downloadFile(item)">
                      <v-icon color="primary" class="mr-2">mdi-{{ fileType(item.type) }}</v-icon>
                      <span>{{ item.name }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </transition>
    </v-layout>
  </v-container>
</template>

<script>
import ResourcesService from '@/services/ResourcesService'
import ClientService from '@/services/ClientService'

import {mapGetters} from "vuex";

export default {
  name: "ArticlesIndex",
  mixins: [ResourcesService, ClientService],
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      resourcesList: [],
      resource: {
        text:'',
        title: '',
        allowed_to_coordinators: true,
        allowed_to_kps: true,
        status: true
      },
      text: '',
      isLoading: false,
      isShowOverview: false
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      this.getResourcesList(this.client.id).then(response => {
        this.resourcesList = response.data
        this.resourcesList.filter(item => {
          if (!item.status) {
            let position = this.resourcesList.indexOf(item)
            this.resourcesList.splice(position, 1)
          } else if (!item.allowed_to_coordinators && this.userData.role == 'Coordinator') {
            let position = this.resourcesList.indexOf(item)
            this.resourcesList.splice(position, 1)
          } else if (!item.allowed_to_kps && this.userData.role == 'KP') {
            let position = this.resourcesList.indexOf(item)
            this.resourcesList.splice(position, 1)
          }
        })
        this.resourcesList.length ? this.resource = this.resourcesList[0] : this.resource = {text:'', title: ''}
        this.text = this.resource.text
        this.dragInit()
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    showResource(item) {
      if(this.isMobile) this.isShowOverview = true
      this.resource = _.cloneDeep(item)
      this.text = this.resource.text
    },
    init() {
      if (this.client && this.client.id) {
        this.getList()
      }
    },
    fileType(type) {
      let fileType

      if (type.search('video') == 0) {
        fileType = 'video'
        return fileType
      }

      if (type.search('image') == 0) {
        fileType = 'image'
        return fileType
      }

      if (type.search('application') == 0) {
        fileType = 'application'
        return fileType
      }

      if (!type && type.length == 0) {
        fileType = 'file-document'
        return fileType
      }
    },
    downloadFile(file) {
      this.onCallToastr('success', "Downloading(Please wait)...")
      this.getFile(this.client.id, this.resource.id, file.id).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a')
        link.href = fileURL;
        link.setAttribute('download', file.name)
        link.click()
        link.remove();
      }).catch(err => {
        this.onCallToastr('error', "Resource not found", 'Error!')
      })
    },
    getBadge(item) {
      let dateCreate = new Date(item.created_at),
        dateUpdate = new Date(item.updated_at),
        today = new Date(),
        deltaCreate = today.getTime() - dateCreate.getTime(),
        deltaUpdate = today.getTime() - dateUpdate.getTime(),
        resultCreate = Math.floor(deltaCreate/1000/60/60/24),
        resultUpdate = Math.floor(deltaUpdate/1000/60/60/24);

      if (resultCreate <= 90 && deltaUpdate == deltaCreate) {
        return 'New'
      } else if (deltaUpdate != deltaCreate && resultUpdate <= 60) {
        return 'Updated'
      } else {
        return
      }
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    'client': function () {
      this.init()
    }
  }
}
</script>
