export default [
  {
    title: 'Only Blue',
    value: 'only blue',
    color: '#3333ff'
  },
  {
    title: 'Prefers Blue',
    value: 'prefers blue',
    color: '#91a2fa'
  },
    {
    title: 'Either/Independent',
    value: 'either',
    color: '#9C27B0'
  },
  {
    title: 'Unknown',
    value: 'unknown',
    color: false
  },
  {
    title: 'Prefers Red',
    value: 'prefers red',
    color: '#FFC0CB'
  },
  {
    title: 'Only Red',
    value: 'only red',
    color: '#de0100'
  }
]
