<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg>
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>Find New Legislators</h1>
        <v-spacer></v-spacer>
        <v-menu offset-y v-if="isMobile">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon small dark v-on="on"><v-icon>more_vert</v-icon></v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-switch
                  v-model="isUpdate"
                  :label="isUpdate ? 'Update' : 'Replace'"
                  @click="refresh"
                  class="px-2"
                  :loading="isLoading"
              ></v-switch>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="my-1" block small v-on="on" :loading="isImporting" @click="onImportLegislatorsFromGoogle">
                    Import Legislators From {{ apiSourceName }}
                  </v-btn>
                </template>
                <span>This action start import in background and it will take some time, to get actual info about imported legislators use 'Find New Legislators' action</span>
              </v-tooltip>
              <v-btn color="primary" class="my-1" block small :loading="isLoading" :disabled="!selectedStateId" @click="refresh">
                Find New Legislators
              </v-btn>
              <v-btn color="primary" class="my-1" block small :disabled="selected.length === 0" @click="onReplaceWithNewLegislators">
                Replace/Import
              </v-btn>
              <v-btn color="primary" class="my-1" block small :disabled="selected.length === 0" @click="onIgnoreLegislators">
                Ignore
              </v-btn>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
          <v-switch
              v-model="isUpdate"
              :label="isUpdate ? 'Update' : 'Replace'"
              @click="refresh"
              class="px-2"
              :loading="isLoading"
          ></v-switch>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" small v-on="on" :loading="isImporting" @click="onImportLegislatorsFromGoogle">
              Import Legislators From {{ apiSourceName }}
            </v-btn>
          </template>
          <span>This action start import in background and it will take some time, to get actual info about imported legislators use 'Find New Legislators' action</span>
        </v-tooltip>

        <v-btn color="primary" class="ml-2" small :loading="isLoading" :disabled="!selectedStateId" @click="refresh">
          Find New Legislators
        </v-btn>
        <v-btn color="primary" class="ml-2" small :disabled="selected.length === 0" @click="onReplaceWithNewLegislators">
          {{isUpdate ? 'Update' : 'Replace/Import'}}
        </v-btn>
        <v-btn color="primary" class="ml-2" small :disabled="selected.length === 0" @click="onIgnoreLegislators">
          Ignore
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex class="d-flex align-center flex-wrap">
        <v-select
            label="State"
            hide-details
            class="state-selector mr-2"
            item-text="state"
            item-value="id"
            :items="stateList"
            v-model="selectedStateId"
            @input="refresh"
            v-if="isSuperAdmin"
        >
        </v-select>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <v-data-table
      show-select
      v-model="selected"
      :loading="isLoading"
      class="elevation-1"
      :options.sync="pagination"
      :headers="legislatorTableHeader"
      :items="legislatorList"
      item-key="new.id"
    >
      <template v-slot:item.new.fullname="{ item }">
        <div class="d-flex align-center mb-1" v-if="item.old">
          <div class="mr-1 no-line-break error--text">{{ item.old.nickname }} {{ item.old.lastname }}</div>
          <div class="table_item__avatar">
            <img
              :src="getThumbImage(item.old.official_image)"
              :alt="item.old.nickname + ' ' + item.old.lastname"
              v-if="item.old.official_image"
            />
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="mr-1 no-line-break success--text">{{ item.new.nickname }} {{ item.new.lastname }}</div>
          <div class="table_item__avatar">
            <img
              :src="getThumbImage(item.new.official_image)"
              :alt="item.new.nickname + ' ' + item.new.lastname"
              v-if="item.new.official_image"
            />
          </div>
        </div>
      </template>

      <template v-slot:item.new.official_email="{ item }">
        <div class="d-flex align-center error--text" v-if="item.old">
          {{ item.old.official_email }}
        </div>
        <div class="d-flex align-center success--text">
          {{ item.new.official_email }}
        </div>
      </template>

      <template v-slot:item.new.district.district="{ item }">
        <div>{{ (item.old || item.new).district.ocd_id }}</div>
      </template>

      <template v-slot:item.old.kp_count="{ item }">
        <div>{{ item.old ? item.old.kp_count : 0 }}</div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No legislator registered yet.</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import ClientService from '@/services/ClientService'
import LegislatorService from '@/services/LegislatorService'
import StateService from '@/services/StateService'
import { partyColorList } from '@/entities/party-color-list'
import ApiSourcesService from "@/services/ApiSourcesService";

export default {
    name: 'LegislatorsPage',
    mixins: [ClientService, LegislatorService, StateService, ApiSourcesService],
    data() {
      return {
        isLoading: false,
        isImporting: false,
        isIgnoring: false,
        stateList: [],
        selectedStateId: null,
        legislatorList: [],
        isUpdate: true,
        selected: [],
        pagination: {
          itemsPerPage: 10,
        },
        partyColorList: partyColorList(),
        apiSourceName: ''
      }
    },
    computed: {
      legislatorTableHeader() {
        return [
          {text: '', value: '', align: 'left', sortable: false},
          {text: 'Name', value: 'new.fullname', align: 'left'},
          {text: 'Email', value: 'new.official_email', align: 'center'},
          {text: 'Division ID', value: 'new.district.district', align: 'center'},
          {text: '# of KPs', value: 'old.kp_count', align: 'center'},
        ]
      },
    },
    async mounted() {
      if (!this.isSuperAdmin) {
        this.selectedStateId = this.userClient.state_id
      }
      this.stateList = (await this.getStates()).data
      this.refresh()
    },
    methods: {
      async refresh() {
         this.getApiSources().then(res => {
           this.apiSourceName = res.data.filter(val => {return val.enabled}).shift().api_client_name
         })
        if (!this.selectedStateId) return
        this.isLoading = true
        this.isSelected = []
        this.selected = []
        this.legislatorList = (await this.getNewStateLegislators(this.selectedStateId, {isUpdate: this.isUpdate})).data
        this.isLoading = false
      },
      async onImportLegislatorsFromGoogle() {
        this.isImporting = true
        await this.importLegislatorsFromGoogle().data
        swal({
          title: 'Legislators import from google API in progress',
          type: 'info',
          html: `You've started import of legislators from google API. Task will run in the background`,
          confirmButtonColor: '#3085d6',
        })
        this.$modals.get('notifications').openAndLoad()

        this.isImporting = false
      },
      async onReplaceWithNewLegislators() {
        const action = this.isUpdate ? 'update' : 'replace'
        swal({
          title: `Are you sure to ${action} legislators with new ones?`,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.isReplacing = true
            const payload = {
              isUpdate: this.isUpdate,
              replaces: this.selected.map(item => ({
                old: item.old ? item.old.id : null,
                new: item.new ? item.new.id : null
              }))
            }

            this.replaceWithNewLegislators(payload)
              .then(() => {
                this.onCallToastr('success', 'Legislators have been updated.', 'Success!')
                this.refresh()
              })
              .catch((err) => {
                this.handleError(err)
              })
            this.isReplacing = false
          }
        })
      },
      async onIgnoreLegislators() {
        swal({
          title: 'Are you sure to ignore these legislators permanently?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.isIgnoring = true
            const payload = {
              state_id: this.selectedStateId,
              ignores: this.selected.map(item => {
                return {
                  lastname: item.new.lastname,
                  district_id: item.new.district_id
                }
              }),
            }

            this.ignoreLegislators(payload)
              .then(() => {
                this.onCallToastr('success', 'Legislators have been ignored.', 'Success!')
                this.refresh()
              })
              .catch((err) => {
                this.handleError(err)
              })
            this.isIgnoring = false
          }
        })
      },
    }
  }
</script>
