import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,[_c(VSpacer),_c(VIcon,{staticClass:"d-flex justify-space-around",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("close ")])],1),_c(VCardText,[_c('div',{staticClass:"d-flex justify-center mb-5"},[_c('h1',[_vm._v("Upgrade for this functionality!")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }