import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFlex,{staticClass:"register-page",attrs:{"xs12":"","sm11":"","md9":""}},[_c('div',{staticClass:"register-page_img"},[_c('img',{attrs:{"src":"/images/register_img.jpg","alt":"Register Your Account"}})]),_c('div',{staticClass:"register-page_form"},[_c('div',{staticClass:"register-page_form__inner"},[_c('h1',[_vm._v("Welcome to"),_c('br'),_vm._v(" KP Dashboard")]),_c('p',[_vm._v("Register Your Account")]),(!_vm.user.email)?_c(VAlert,{staticClass:"mb-3",attrs:{"type":"error","value":true,"outlined":""}},[_vm._v("Seems you entered wrong link.")]):_vm._e(),_c(VForm,{ref:"registerForm",on:{"submit":function($event){$event.preventDefault();return _vm.onRegister.apply(null, arguments)}}},[_c(VTextField,{attrs:{"type":"email","outlined":"","prepend-icon":"email","label":"E-mail ID*","value":_vm.user.email,"readonly":""}}),_c(VTextField,{attrs:{"type":"password","outlined":"","prepend-icon":"lock","label":"Password*","rules":_vm.passwordRules},on:{"input":function($event){_vm.error = ''}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c(VTextField,{attrs:{"type":"password","outlined":"","prepend-icon":"lock","label":"Password Confirmation*","rules":_vm.passwordConfirmationRules},on:{"input":function($event){_vm.error = ''}},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}}),_c('div',{staticClass:"d-flex"},[_c(VSpacer),_c(VBtn,{attrs:{"type":"submit","color":"primary","loading":_vm.isBusy,"disabled":!_vm.user.email}},[_vm._v("Register and Log in")])],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }