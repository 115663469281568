<template>
  <v-btn
    color="primary"
    :block="block"
    :class="customClass"
    :disabled="!disabled"
    small @click="onDownloadTemplate"
    :loading="isBusy"
  >
    {{ title }}
    <v-icon dark class="ml-2">cloud_download</v-icon>
  </v-btn>
</template>

<script>
  import ClientService from '@/services/ClientService'

  export default {
    name: "export-button",
    props: {
      client: {
        type: Object,
        required: false,
        default: null
      },
      disabled: {
        type: Boolean,
        default: true
      },
      params: {
        type: Object,
        required: false,
        default: null
      },
      district: {
        type: [Array, String],
        required: false,
        default: ''
      },
      block: {
        type: Boolean,
        default: true
      },
      title : {
        type: String,
        default: 'Export'
      },
      customClass : {
        type: String,
        default: 'my-1'
      }
    },
    mixins: [ClientService],
    data() {
      return {
        isBusy: false,

      }
    },
    methods: {
      onDownloadTemplate () {
        this.isBusy = true
        if(this.district) this.params.district = this.district;
        if(/^CD/.test(this.district)) this.params.withFederal = 1;
        this.downloadMembers(this.client.id, this.params).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          let abbr = this.userClient ? this.userClient.assoc_abbrev : this.client.assoc_abbrev
          link.setAttribute('download', `${abbr}_KPD_member_export_${moment().format('MM-DD-YYYY_HH-mm-ss')}.xlsx`)
          document.body.appendChild(link)
          link.click()
          this.isBusy = false
        },error => {
          this.isBusy = false
          console.error(error)
        })
      },
    }
  }
</script>

