<template>
  <v-btn
      color="primary"
      :min-width="isMobile ? '100%' : '110px'"
      class="my-1"
      small
      @click="onDownloadTemplate"
      :loading="isBusy"
  >
    Export Selected Legislators
    <v-icon dark class="ml-2">cloud_download</v-icon>
  </v-btn>
</template>

<script>
import ClientService from '@/services/ClientService'
import {mapGetters} from "vuex";

export default {
  name: "ExportSelectedLegislatorsButton",
  mixins: [ClientService],
  props: {
    legislators: {
      type: Array,
      default: function () {
        return [1]
      }
    }
  },
  data() {
    return {
      isBusy: false
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
  },
  methods: {
    onDownloadTemplate() {
      this.isBusy = true
      this.downloadSelectedLegislators(this.client.id, {legislators: this.legislators}).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let abbr = this.client ? this.client.assoc_abbrev : this.client.assoc_abbrev

        const fileName = `KPD_${abbr}_legislator_export_${moment().format('MM-DD-YYYY')}.xlsx`

        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        this.isBusy = false
      }, error => {
        this.isBusy = false
        console.error(error)
      })
    },
  }
}
</script>

