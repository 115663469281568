<template>
  <v-layout align-center justify-center>
    <v-tooltip bottom v-if="rowData.lame_duck">
      <template v-slot:activator="{ on }">
        <a v-on="on">
          <v-icon color="#EECB23" size="18" class="mx-2">fa-solid fa-duck</v-icon>
        </a>
      </template>
      <span>Lame Duck</span>
    </v-tooltip>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import vipChip from '@/components/legislators/vip-setting/vip-chip'

export default Vue.extend({
  computed: {
    rowData () {
      return this.params.node.data || {}
    }
  }
})
</script>
