<template>
  <v-navigation-drawer fixed temporary right touchless width="600" v-model="isModalOpen">
    <v-form ref="taskForm" @submit.prevent="onUpdateTask()">
      <v-card style="box-shadow: none !important;">
        <v-card-title>
          <span class="title">Edit Task</span>
          <v-spacer />
          <v-icon @click="isModalOpen = false">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="!!task">
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout wrap>
            <v-flex xs12 class="px-2">
              <v-checkbox
                color="primary"
                label="Hide from KP"
                v-model="task.hide_from_kp"
                hide-details
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 sm6 class="px-2">
              <v-text-field
                label="Short Label*"
                outlined
                dense
                hide-details
                counter="15"
                :rules="nameRules"
                v-model="task.name"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 class="px-2">
              <v-select
                label="Type*"
                outlined
                dense
                item-text="name"
                item-value="data_type"
                :items="taskTypeList"
                v-model="task.data_type"
                persistent-hint
                disabled
                hint="Cannot be changed after creation"
              >
              </v-select>
            </v-flex>
            <v-flex xs12 class="px-2" v-if="task.data_type === 'Select'">
              <v-combobox label="Options*" outlined dense="dense" hide-selected multiple small-chips
                          v-model="task.options"
                          readonly></v-combobox>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field label="Longer explanation for KPs" outlined dense hide-details
                            v-model="task.tooltip"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-textarea label="Internal Notes" outlined dense hide-details v-model="task.notes"></v-textarea>
            </v-flex>
            <v-flex xs12 class="px-2 pb-0">
              <v-date-picker2
                v-model="task.deadline"
                label="Deadline"
                dense="dense"
                :max="formattedEndDate"
              />
            </v-flex>
          </v-layout>

          <v-layout wrap>
            <v-flex xs12 class="px-2">
              <v-checkbox
                color="primary"
                label="Assign to all legislators"
                hide-details
                dense
                v-model="messageData.isSpecificLegislatorsOnly"
                @change="assignToAllLegislators"
              ></v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs4 class="px-2" v-if="!messageData.isSpecificLegislatorsOnly && selectedClient.state && selectedClient.state.house_districts > 0">
              <v-checkbox
                color="primary"
                label="House"
                hide-details
                dense
                v-model="messageData.isHouseDistrictsOnly"
                @change="onSelectDistrictType"
              ></v-checkbox>
            </v-flex>
            <v-flex xs4 class="px-2" v-if="!messageData.isSpecificLegislatorsOnly && selectedClient.state && selectedClient.state.sen_districts > 0">
              <v-checkbox
                color="primary"
                label="Senate"
                hide-details
                dense
                v-model="messageData.isSenateDisctrictsOnly"
                @change="onSelectDistrictType"
              ></v-checkbox>
            </v-flex>
            <v-flex xs4 class="px-2" v-if="!messageData.isSpecificLegislatorsOnly && selectedClient.state && selectedClient.state.has_joint_committees">
              <v-checkbox
                color="primary"
                class="mt-1"
                label="Joint"
                hide-details
                v-model="messageData.isJointDisctrictsOnly"
                @change="onSelectDistrictType"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 class="px-2" v-if="!messageData.isSpecificLegislatorsOnly">
              <v-layout
                align-center
                class="mt-3"
                @click="onSelectCommittee(committee.id)"
                v-for="committee in selectedCommitteeList"
                :key="committee.id"
              >
                <v-icon
                  color="primary"
                  class="cursor-pointer"
                  v-if="selectedCommitteeIdList.includes(committee.id)"
                >
                  check_box
                </v-icon>
                <v-icon class="cursor-pointer" v-else>check_box_outline_blank</v-icon>
                <label class="v-label theme--light ml-2 cursor-pointer">{{ committee.label }}</label>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="mt-3 px-2" v-if="!messageData.isSpecificLegislatorsOnly">
              <v-expansion-panels v-model="isLegsOpen">
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ 'Legislator(s) (' + (messageData.selectedLegislators || []).length + ')' }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-autocomplete
                      v-model="messageData.selectedLegislators"
                      :items="totalLegislators"
                      outlined
                      dense
                      color="blue-grey lighten-2"
                      label="Selected Legislator(s)"
                      item-text="fullname"
                      item-value="member_legislators[0].id"
                      multiple
                      return-object
                      :disabled="isBusy"
                      :loading="isBusy"
                      :search-input.sync="searchInputFull"
                      @change="searchInputFull=''"
                      hide-details
                      :menu-props="{maxHeight: 'calc(100% - 320px)'}"
                    >
                      <template v-slot:selection="{ item }">
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-avatar class="avatar_image">
                            <img :src="getThumbImage(data.item.official_image)">
                            <v-icon v-if="data.item.lame_duck" color="#EECB23">fa-solid fa-duck</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.fullname }}
                              <v-tooltip bottom v-if="data.item.lame_duck">
                                <template v-slot:activator="{ on }">
                                  <a v-on="on">
                                    <v-icon color="#EECB23" size="16" class="mx-2">fa-solid fa-duck</v-icon>
                                  </a>
                                </template>
                                <span>Lame Duck</span>
                              </v-tooltip>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-expansion-panel-content>
                  <div class="mb-2 pt-1" v-if=" messageData.selectedLegislators &&  messageData.selectedLegislators.length">
                    <div class="d-flex flex-wrap">
                      <div v-for="(item, index) of messageData.selectedLegislators" :key="index" class="mr-2">
                        <v-menu
                          v-model="displaySelected[getIndex(item, legislators)]"
                          bottom
                          left
                          :close-on-content-click="false"
                          origin="top left"
                          content-class="role_card"
                        >
                          <template v-slot:activator="{ on }">
                            <v-chip
                              close
                              class="mt-2"
                              pill
                              v-on="on"
                              :style="colorsLegislatorsParties(item, legislators)"
                              @click:close="removeLegislator(messageData.selectedLegislators.findIndex(id => id === item), messageData.selectedLegislators)"
                            >
                              <v-avatar left>
                                <v-img class="avatar_image" :src="getThumbImage(getSelectedLegislator(item).official_image)"></v-img>
                              </v-avatar>
                              <vip-chip
                                v-if="item.vip"
                                class="mr-2 mb-1"
                                :legislator="item"
                                :show="'chip_code'"
                              >
                              </vip-chip>
                              {{ getSelectedLegislator(item).fullname }}
                              <v-tooltip bottom v-if="getSelectedLegislator(item).lame_duck">
                                <template v-slot:activator="{ on }">
                                  <a v-on="on">
                                    <v-icon color="#EECB23" size="16" class="ml-1">fa-solid fa-duck</v-icon>
                                  </a>
                                </template>
                                <span>Lame Duck</span>
                              </v-tooltip>
                            </v-chip>
                          </template>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-flex>
          </v-layout>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-4">
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import TaskService from '@/services/TaskService'
import StateService from '@/services/StateService'

import VDatePicker2 from '@/components/common/date-picker'
import VipChip from "@/components/legislators/vip-setting/vip-chip";
import ClientService from "@/services/ClientService";

export default {
  name: 'EditTaskModal',
  props: {
    activeSession: {
      type: Object,
      default: function () {
        return {}
      }
    },
    selectedClient: {
      type: Object,
      default: function () {
        return {}
      }
    },
    committeeList: {
      type: Array,
      default: function () {
        return []
      }
    },
    refresh: {
      type: Function
    }
  },
  mixins: [TaskService, StateService, ClientService],
  components: {
    VDatePicker2,
    'vip-chip': VipChip,
  },
  data () {
    return {
      isModalOpen: false,
      task: null,
      error: '',
      displaySelected: {},
      searchInputFull: '',
      isBusy: false,
      isLegsOpen: false,
      legislators: [],
      messageData: {
        isHouseDistrictsOnly: false,
        isSenateDisctrictsOnly: false,
        isJointDisctrictsOnly: false,
      },
      totalLegislators: [],
      selectedCommitteeIdList: [],
      additional_filters: {}
    }
  },
  computed: {
    formattedEndDate() {
      return this.activeSession.end_at
        ? moment.utc(this.activeSession.end_at).toISOString()
        : ''
    },
    nameRules () {
      return [
        v => !!v || 'Name is required.',
        v => (!!v && v.length <= 15) || 'Name cannot exceed 15 characters.'
      ]
    },
    taskTypeList () {
      return [
        {
          name: 'Yes/No',
          data_type: 'Boolean',
        },
        {
          name: 'Custom Text',
          data_type: 'String',
        },
        {
          name: 'Notes',
          data_type: 'Notes',
        },
        {
          name: 'Currency',
          data_type: 'Currency',
        },
        {
          name: 'Dropdown List',
          data_type: 'Select',
        },
      ]
    },
    selectedCommitteeList () {
      let selectedCommitteeList = []
      if (this.messageData && this.messageData.isHouseDistrictsOnly) {
        selectedCommitteeList = [...this.committeeList.filter(committee => committee.type === 0 && (this.selectedClient.house_committees || []).includes(committee.id))]
      }

      if (this.messageData && this.messageData.isSenateDisctrictsOnly) {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList.filter(committee => committee.type === 1 && (this.selectedClient.senate_committees || []).includes(committee.id))
        ]
      }

      if (this.messageData && this.messageData.isJointDisctrictsOnly) {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList.filter(committee => committee.type === 2 && (this.selectedClient.joint_committees || []).includes(committee.id))
        ]
      }

      const committeeTypeList = ['H', 'S', 'J']
      selectedCommitteeList.forEach((committee) => {
        committee.label = `${committee.fullname} (${committeeTypeList[committee.type]})`
      })
      return selectedCommitteeList
    }
  },
  methods: {
    toggle (task, legislators, selectedLegislators) {
      this.totalLegislators = legislators;
      this.isLegsOpen = false
      this.error = ''
      this.messageData.selectedLegislators = selectedLegislators
      this.legislators = legislators
      this.task = _.cloneDeep(task)
      const taskType = task.task_type
      this.task.data_type = (taskType.name === 'Notes' && taskType.data_type === 'String') ? 'Notes' : taskType.data_type
      this.task.options = taskType.options
      this.task = _.cloneDeep(this.task)
      this.isModalOpen = true
	    const messageData = this.task && this.task.additional_filters && this.task.additional_filters.messageData;

      if(messageData) {
        this.messageData.isSpecificLegislatorsOnly = messageData.isSpecificLegislatorsOnly;
      }

      if (!(messageData && messageData.isSpecificLegislatorsOnly)) {
        this.messageData.isHouseDistrictsOnly = messageData && messageData.isHouseDistrictsOnly;
        this.messageData.isJointDisctrictsOnly =  messageData && messageData.isJointDisctrictsOnly;
        this.messageData.isSenateDisctrictsOnly = messageData && messageData.isSenateDisctrictsOnly;
        this.selectedCommitteeIdList = this.task && this.task.additional_filters && this.task.additional_filters && this.task.additional_filters.selectedCommitteeIdList;
      }
    },
    onUpdateTask () {
      if (!this.$refs.taskForm.validate()) return
      this.isBusy = true
      const payload = _.pick(this.task, ['id', 'name', 'tooltip', 'notes', 'deadline', 'hide_from_kp'])
      
      this.additional_filters.messageData = this.messageData || null;
      this.additional_filters.selectedCommitteeIdList = this.selectedCommitteeIdList;

		  payload.additional_filters = this.additional_filters
      //payload.legislators = this.messageData && this.messageData.selectedLegislators || []
      
      if(this.messageData && this.messageData.selectedLegislators.length && this.messageData.selectedLegislators.some(item => item.fullname)) {
        payload.legislators = this.messageData && this.messageData.selectedLegislators.map(legislator => { return legislator.id}) || []
      } else {
        payload.legislators = this.messageData && this.messageData.selectedLegislators || []
      } 

      if(payload && payload.additional_filters && payload.additional_filters.messageData && payload.additional_filters.messageData.selectedLegislators.length) {
        payload.additional_filters.messageData.selectedLegislators = null; 
      }

      this.updateTask(payload)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'Task has been updated.', 'Success!')
          this.refresh()
          this.isModalOpen = false
        })
        .catch((err) => {
          this.isBusy = false          
          this.handleError(err)
        })
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    getSelectedLegislator(item) {
      if (typeof item === 'object' && item.id) {
        item = item.id
      }
      return this.legislators.find(legislator => item == legislator.id);
    },
    removeLegislator(index, array) {
      array.splice(index, 1)
      this.$forceUpdate();
    },
    assignToAllLegislators() {
      if (!(this.messageData && this.messageData.isSpecificLegislatorsOnly)) {
        this.messageData.isHouseDistrictsOnly = false
        this.messageData.isSenateDisctrictsOnly = false
        this.messageData.isJointDisctrictsOnly = false
        this.messageData.selectedLegislators = []
        this.selectedCommitteeIdList = []
		    this.additional_filters = {}
      } else {
        this.messageData.selectedLegislators = []
        for (let i = 0; i < this.legislators.length; i++) {
          if (this.legislators[i].member_legislators.length && this.legislators[i].member_legislators[0].kp_position == 1) {
            this.messageData.selectedLegislators.push(this.legislators[i].member_legislators[0].leg_id)
          }
        }
      }
      this.messageData = _.cloneDeep(this.messageData)
    },
    onSelectDistrictType () {
      let selectedCommitteeIdList = []
      if (this.messageData && this.messageData.isHouseDistrictsOnly) {
        selectedCommitteeIdList = [
          ...this.committeeList.filter(committee => committee.type === 0 && (this.selectedClient.house_committees || []).includes(committee.id)).map(committee => committee.id)
        ]
      }
      if (this.messageData && this.messageData.isSenateDisctrictsOnly) {
        selectedCommitteeIdList = [
          ...selectedCommitteeIdList,
          ...this.committeeList.filter(committee => committee.type === 1 && (this.selectedClient.senate_committees || []).includes(committee.id)).map(committee => committee.id)
        ]
      }
      if (this.messageData && this.messageData.isJointDisctrictsOnly) {
        selectedCommitteeIdList = [
          ...selectedCommitteeIdList,
          ...this.committeeList.filter(committee => committee.type === 2 && (this.selectedClient.joint_committees || []).includes(committee.id)).map(committee => committee.id)
        ]
      }
      this.selectedCommitteeIdList = selectedCommitteeIdList || [];
      this.onFilterLegislators()
    },
    onFilterLegislators () {
      const selectedLegislators = this.legislators.filter((legislator) => {
        if (!legislator.legislator_committees || legislator.legislator_committees.length < 1) return false
        const committeeIds = legislator.legislator_committees
          .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 0))
          .map(legislatorCommittee => legislatorCommittee.committee_id)
        if (committeeIds.length === 0) return false
        return !!this.selectedCommitteeIdList.find(committeeId => committeeIds.includes(committeeId))
      })
      this.messageData.selectedLegislators = []
      for (let i = 0; i < selectedLegislators.length; i++) {
        if (selectedLegislators[i].member_legislators.length && selectedLegislators[i].member_legislators[0].kp_position == 1) {
          this.messageData.selectedLegislators.push(selectedLegislators[i].member_legislators[0].leg_id)
        }
      }
      this.messageData = _.cloneDeep(this.messageData)
    },
    onSelectCommittee (committeeId) {
      if (this.selectedCommitteeIdList && this.selectedCommitteeIdList.length && this.selectedCommitteeIdList.includes(committeeId)) {
        this.selectedCommitteeIdList = this.selectedCommitteeIdList.filter(_committeeId => _committeeId !== committeeId)
      } else {
        this.selectedCommitteeIdList.push(committeeId)
      }
      this.onFilterLegislators()
    },
  }
}
</script>
