import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFlex,{staticClass:"my-auto",attrs:{"xs12":"","sm8":"","lg4":"","xl3":""}},[_c(VForm,{ref:"forgotPasswordForm",on:{"submit":function($event){$event.preventDefault();return _vm.onSendEmail.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,[_c('p',{staticClass:"mb-4",style:({fontSize: _vm.isMobile ? '26px' : '36px'})},[_vm._v("FORGOT PASSWORD?")])]),_c(VCardText,[(_vm.error)?_c(VAlert,{staticClass:"mb-3",attrs:{"type":"error","color":"error","value":true,"outlined":""}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(_vm.message)?_c(VAlert,{staticClass:"mb-3",attrs:{"type":"success","color":"primary","value":true,"outlined":""}},[_vm._v(_vm._s(_vm.message))]):_vm._e(),_c(VTextField,{attrs:{"type":"email","prepend-inner-icon":"person","label":"E-mail Address*","rules":_vm.emailRules},on:{"input":function($event){_vm.error = ''}},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('div',{staticClass:"text-xs-right mb-3"},[_c('router-link',{attrs:{"to":{name: 'login'}}},[_vm._v("Back to login")])],1),_c(VBtn,{staticClass:"mt-2 btn-primary",attrs:{"type":"submit","loading":_vm.isBusy}},[_vm._v("Send Reset Password Link")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }