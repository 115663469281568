<template>
  <v-navigation-drawer class="legislator-info_modal" fixed temporary right stateless touchless width="650" v-model="isModalOpen">
    <v-form ref="profileForm" @submit.prevent="onUpdateProfile()" style="width: 100%;">
      <v-card class="d-flex flex-column" style="box-shadow: none !important; height: 100vh;">
        <v-card-title>
          <span class="title">Profile</span>
          <v-spacer></v-spacer>
          <v-icon @click="isModalOpen = false">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          <v-alert type="error" outlined class="mb-2" :value="true" v-if="error">{{ error }}</v-alert>
          <!-- Profile -->
          <v-layout v-if="user.role == 'KP'" wrap>
            <v-flex xs4 class="mb-6 px-2">
              <v-avatar
                size="100"
                class="cursor-pointer bordered_avatar"
                style="border: 2px solid #ccc"
              >
                <img v-if="member.avatar_url" :src="getThumbImageMedium(member.avatar_url)" />
                <v-icon v-else size="50">
                  perm_identity
                </v-icon>
                <v-btn
                  icon
                  class="avatar_overlay"
                >
                  <v-icon
                  >
                    mdi-camera
                  </v-icon>
                </v-btn>
              </v-avatar>
            </v-flex>
            <v-flex xs7 class="upload-btn pa-2" :class="isMobile? '' : 'ml-4'">
              <v-btn large color="primary" @click="$refs.avatarUploader.click()">Upload new photo</v-btn>
              <p class="mt-3">Allowed JPG, GIF or PNG. Max size of 800K</p>
              <input
                id="avatar_upload"
                ref="avatarUploader"
                type="file"
                accept="image/*"
                class="d-none"
                @change="onUploadAvatar()"
              >
            </v-flex>
          </v-layout>
          <v-layout v-else wrap>
            <v-layout wrap>
            <v-flex
              v-if="user.member_id"
              xs4
              class="mb-6 px-2"
            >
              <v-avatar
                size="100"
                class="cursor-pointer bordered_avatar"
                style="border: 2px solid #ccc"
                @click="$refs.avatarUploader.click()"
              >
                <img v-if="member.avatar_url" :src="getThumbImageMedium(member.avatar_url)" />
                <v-icon v-else size="50">
                  perm_identity
                </v-icon>
              </v-avatar>
            </v-flex>
            <v-flex xs7 class="px-2" :class="isMobile? '' : 'ml-4'">
              <v-btn large color="primary" @click="$refs.avatarUploader.click()">Upload new photo</v-btn>
              <p class="mt-4">Allowed JPG, GIF or PNG. Max size of 800K</p>
              <input
                id="avatar_upload"
                ref="avatarUploader"
                type="file"
                accept="image/*"
                class="d-none"
                @change="onUploadAvatar()"
              >
            </v-flex>
            </v-layout>
            <v-layout wrap>
            <v-flex xs12 sm6 class="px-2">
              <v-select
                label="Role*"
                :items="roleList"
                item-value="value"
                item-text="title"
                :rules="roleRules"
                v-model="user.role"
                disabled
                outlined
                dense
              >
              </v-select>
            </v-flex>
            <v-flex xs6 class="px-2">
            </v-flex>
            <v-flex xs12 sm6 class="px-2">
              <v-text-field label="First Name*" outlined dense :rules="nickNameRules"
                            v-model="user.nickname"></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 class="px-2">
              <v-text-field label="Last Name*" outlined dense :rules="lastNameRules"
                            v-model="user.lastname"></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 class="px-2">
              <v-text-field prepend-icon="email" outlined dense label="Email*" :rules="emailRules" v-model="user.email"
                            readonly></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 class="px-2">
              <v-text-field prepend-icon="phone_iphone" outlined dense label="Cell Phone" v-mask="'(###) ###-####'"
                            :rules="phoneNumberRules" v-model="user.cell_phone"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
                  <address-input placeholder="Mailing Address For Donation Requests" dense="dense"
                                 v-model="user.mailing_address"></address-input>
            </v-flex>
            <v-flex xs12 class="px-2" v-if="user.role == 'VIP'">
               <v-tooltip top max-width="300px">
                <template v-slot:activator="{ on }">
                  <v-switch color="primary" v-on="on" hide-details v-model="user.receive_task_notifications">
                  <span slot="label" v-on="on">Receive email notifications of all completed tasks</span>
                  </v-switch>
                </template>
                <span>For Admins: Check box to receive email notifications of ALL completed tasks. Coordinators automatically receive these as well from their respective KPs.</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs12 class="px-2" v-if="user.role == 'VIP'">
              <v-tooltip top max-width="300px">
                <template v-slot:activator="{ on }">
                    <v-switch color="primary" v-on="on" hide-details v-model="user.receive_copy_messages">
                    <span slot="label" v-on="on">Receive copies of outgoing email messages</span>
                    </v-switch>
                </template>
                <span>For Admins: Checking this will allow you to receive copies of outgoing messages, but only if sender checks the box before sending.</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs12 class="px-2" v-if="user.role == 'VIP'">
              <v-tooltip top max-width="300px">
                <template v-slot:activator="{ on }">
                  <v-switch color="primary" v-on="on" hide-details v-model="user.receive_message_notifications">
                  <span slot="label" v-on="on">Receive incoming email message notifications</span>
                  </v-switch>
                </template>
                <span>For Admins: Checking this will allow you to receive copies of ALL incoming email messages. Coordinators automatically receive these as well from their respective KPs.</span>
              </v-tooltip>
            </v-flex>
              <v-flex xs12 class="px-2" v-if="user.role == 'VIP'">
                <v-tooltip top max-width="300px">
                  <template v-slot:activator="{ on }">
                    <v-switch color="primary" v-on="on" hide-details v-model="user.receive_system_notifications">
                      <span slot="label" v-on="on">Receive important system notification emails</span>
                    </v-switch>
                  </template>
                  <span>For Admins and Coordinators: Checking this will allow you to receive system email messages.</span>
                </v-tooltip>
              </v-flex>
            <v-flex xs12 class="px-2">
              <v-checkbox
                color="primary"
                label="Change Password?"
                v-model="user.need_to_change_password"
              ></v-checkbox>
            </v-flex>
            <v-layout wrap v-if="user.need_to_change_password">
              <v-flex xs12 class="px-2">
                <v-text-field type="password" label="Current Password*" :rules="currentPasswordRules" v-model="user.current_password"></v-text-field>
              </v-flex>
              <v-flex xs12 class="px-2">
                <v-text-field type="password" label="New Password*" :rules="newPasswordRules" v-model="user.new_password"></v-text-field>
              </v-flex>
              <v-flex xs12 class="px-2">
                <v-text-field type="password" label="Password Confirmation*" :rules="passwordConfirmationRules" v-model="user.password_confirmation"></v-text-field>
              </v-flex>
            </v-layout>

            <v-flex xs12 class="px-2" v-if="!user.two_factor_verified">
              <v-btn  color="primary"  @click="$refs.twoFactorVerification.toggle(user)">
              <v-icon left>security</v-icon>
              Enable 2-Factor Authentication
              </v-btn>
            </v-flex>
            <v-flex xs12 class="px-2" v-else>
              <v-btn  color="primary" :loading="isBusy"  @click.prevent="sendVerificationCode">
              <v-icon left>security</v-icon>
              Disable 2-Factor Authentication
              </v-btn>
            </v-flex>
            </v-layout>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>

        </v-card-text>
        <v-spacer style="flex: 1;"></v-spacer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Close</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <two-factor-verification ref="twoFactorVerification"></two-factor-verification>
    <disable-two-factor-modal ref="disableTwoFactorModal"></disable-two-factor-modal>
  </v-navigation-drawer>
</template>

<script>
import UserService from '@/services/UserService'
import ClientService from '@/services/ClientService'
import CloudinaryService from '@/services/CloudinaryService'
import MemberService from '@/services/MemberService'

import AddressInput from '@/components/common/address-input.vue'
import TwoFactorVerification from "@/components/common/two-factor-verification"
import DisableTwoFactorModal from "@/components/common/disable-two-factor-modal"

export default {
  mixins: [UserService, ClientService, CloudinaryService, MemberService],
  components: {
    DisableTwoFactorModal,
    TwoFactorVerification,
    AddressInput,
  },
  data () {
    return {
      user: {},
      member: {},
      error: '',
      isBusy: false,
    }
  },
  computed: {
    isModalOpen: {
      get () {
        return this.$store.state.app.isProfileSidebarOpen
      },
      set (newValue) {
        this.$store.dispatch('app/setProfileSidebarOpen', newValue)
      }
    },
    roleRules () {
      return [
        v => !!v || 'Role is required.',
      ]
    },
    lastNameRules () {
      return [
        v => !!v || 'Last name is required.',
      ]
    },
    nickNameRules () {
      return [
        v => !!v || 'Nickname is required.',
      ]
    },
    currentPasswordRules () {
      return [
        v => !!v || 'Current password is required.',
      ]
    },
    newPasswordRules () {
      return [
        v => !!v || 'New password is required.',
      ]
    },
    emailRules () {
      return [
        v => !!v || 'E-mail address is required.',
        v => this.validateEmail(v) || 'E-mail address must be valid.',
      ]
    },
    passwordConfirmationRules () {
      return [
        v => v === this.user.new_password || 'Password doesn\'t match.'
      ]
    },
    roleList () {
      return [
        { title: 'Super Admin', value: 'Super Admin' },
        { title: 'Admin', value: 'VIP' },
        { title: 'Coordinator', value: 'Coordinator' },
        { title: 'KP', value: 'KP' }
      ]
    },
  },
  methods: {
    onUpdateProfile () {
      if (!this.$refs.profileForm.validate()) return
      this.isBusy = true
      this.updateUser(this.user)
        .then(() => {
          if (!this.isSuperAdmin) {
            this.updateUserMember()
          }
          this.isBusy = false
          this.onCallToastr('success', 'Profile has been updated.', 'Success!')
          delete this.user.need_to_change_password
          delete this.user.current_password
          delete this.user.new_password
          delete this.user.password_confirmation
          this.$store.dispatch('auth/setUserData', this.user)
          this.isModalOpen = false
        })
        .catch((err) => {
          this.handleError(err)
        })
    },
    async sendVerificationCode () {
      try {
        this.isBusy = true
        this.error = null
        const response = await this.verifyPhone({phone: this.user.cell_phone})
        if(response.status === 200 && response.data.message){
          this.$refs.disableTwoFactorModal.toggle(this.user)
        }
        else {
          this.error = 'Something went wrong';
        }
        this.isBusy = false
      } catch (e) {
        this.isBusy = false
        this.error = e;
      }
    },
    onUploadAvatar () {
      const file = this.$refs.avatarUploader.files[0]
      if (!file) return
      this.uploadToCloudinary(file)
        .json((res) => {
          this.member.avatar_url = res.secure_url
          this.member = {...this.member}
        })
        .then(res => {
          this.onCallToastr('success', 'Your member avatar has been changed!', 'Success!')
        })
        .catch(err => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
        })
    },
    getMember () {
      this.getClientMember(this.user.client_id, this.user.member_id).then(response => {
        this.member = response.data
      })
    },
    updateUserMember () {
      this.updateMember(this.member).then(response => {
        this.member = response.data
      })
    },
  },
  watch: {
    isModalOpen () {
      if (this.isModalOpen) {
        this.error = ''
        this.user = _.cloneDeep(this.userData)
        if (!this.isSuperAdmin && this.userData.member_id) {
          this.getMember()
        }
      }
    }
  }
}
</script>
