<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>Committees</h1>
        <v-spacer></v-spacer>
        <v-menu offset-y v-if="isMobile">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon small dark v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-btn
                  color="warning"
                  class="my-1"
                  block
                  small
                  @click="$refs.newCommitteeModal.toggle()"
                  :disabled="!selectedStateId"
              >
                New Committee
              </v-btn>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
        <v-btn
            color="warning"
            small
            @click="$refs.newCommitteeModal.toggle()"
            :disabled="!selectedStateId"
        >
          New Committee
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex class="d-flex align-center flex-wrap">
        <v-select
            label="State"
            hide-details
            class="state-selector mr-2"
            item-text="state"
            item-value="id"
            :items="stateList"
            v-model="selectedStateId"
            @input="refresh"
            v-if="isSuperAdmin"
        >
        </v-select>
        <v-autocomplete
            v-if="selectedStateId"
            label="Committee Member Types:"
            auto-select-first
            multiple
            hide-details
            class="membership-selector mr-2"
            item-text="name"
            item-value="id"
            :items="memberships"
            v-model="selectMembershipIds"
            :disabled="isLoading"
        >
          <template v-slot:selection="{ item }">
            <v-chip
                :input-value="item"
                small
                class="chip--select-multi"
                :key="item.id"
            >{{ item.name }}
            </v-chip>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item
                :disabled="item.id === 4"
                v-on="on"
                v-bind="attrs"
                #default="{ active }"
                @click="onUpdateMemberships(!selectMembershipIds.some(id => id === item.id), item.id)"
            >
              <v-list-item-action>
                <v-checkbox
                    :input-value="active"
                    :disabled="item.id === 4"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-checkbox
            v-if="selectedStateId"
            color="primary"
            label="Has Joint Committees?"
            hide-details
            @change="onUpdateState"
            v-model="hasJointCommittees"
        ></v-checkbox>
        <v-checkbox
            v-if="selectedStateId"
            class="mx-2"
            color="primary"
            label="Has Sub Committees?"
            hide-details
            @change="onUpdateState"
            v-model="hasSubCommittees"
        ></v-checkbox>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex>
        <v-text-field append-icon="search" label="Search" single-line hide-details class="search-input"
                      v-model="search"></v-text-field>
      </v-flex>
    </v-layout>

    <v-data-table
        :loading="isLoading"
        class="elevation-1"
        :options.sync="pagination"
        :headers="committeeTableHeader"
        :items="committeeList"
        :search="search"
    >
      <template v-slot:item.action="{ item }">
        <v-btn icon class="mx-0" @click="$refs.editCommitteeModal.toggle(item)">
          <v-icon size="18" color="primary">edit</v-icon>
        </v-btn>
        <v-btn icon class="mx-0" @click="onDeleteCommittee(item.id)">
          <v-icon size="18" color="error">delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ committeeTypeList[item.type] }}</div>
      </template>

      <template v-slot:item.shortname="{ item }">
        <div>{{ item.shortname }}</div>
      </template>

      <template v-slot:item.fullname="{ item }">
        <div>{{ item.fullname }}</div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No committee registered yet.</p>
      </template>
    </v-data-table>


    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>Legislators Urls</h1>
        <v-spacer></v-spacer>
        <v-menu offset-y v-if="isMobile">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon small dark v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-btn
                  color="warning"
                  class="my-1"
                  block
                  small
                  @click="$refs.newUrlModal.toggle()"
                  :disabled="!selectedStateId"
              >
                New url
              </v-btn>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
        <v-btn
            color="warning"
            small
            @click="$refs.newUrlModal.toggle()"
            :disabled="!selectedStateId"
        >
          New url
        </v-btn>
      </v-flex>
    </v-layout>
    <v-data-table
        :loading="isLoading"
        class="elevation-1"
        :options.sync="urlPagination"
        :headers="urlTableHeader"
        :items="urlList"
        :search="urlSearch"
    >
      <template v-slot:item.action="{ item }">
        <v-btn icon class="mx-0" @click="$refs.newUrlModal.toggle(item)">
          <v-icon size="18" color="primary">edit</v-icon>
        </v-btn>
        <v-btn icon class="mx-0" @click="onDeleteUrl(item.id)">
          <v-icon size="18" color="error">delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ committeeTypeList[item.type] }}</div>
      </template>

      <template v-slot:item.shortname="{ item }">
        <div>{{ item.shortname }}</div>
      </template>

      <template v-slot:item.fullname="{ item }">
        <div>{{ item.fullname }}</div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No legislator urls yet.</p>
      </template>
    </v-data-table>


    <edit-committee-modal ref="editCommitteeModal" :state-list="stateList" :refresh="refresh"></edit-committee-modal>
    <new-committee-modal ref="newCommitteeModal" :state-list="stateList" :selected-state-id="selectedStateId"
                         :refresh="refresh"></new-committee-modal>
    <new-url-modal ref="newUrlModal" :stateId="selectedStateId" @refresh="refreshLegislatorUrls"></new-url-modal>
  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import CommitteeService from '@/services/CommitteeService'
import StateService from '@/services/StateService'

import editCommitteeModal from '@/components/committees/edit-committee-modal'
import newCommitteeModal from '@/components/committees/new-committee-modal'
import newUrlModal from '@/components/committees/new-url-modal'

export default {
  name: 'CommitteesPage',
  mixins: [CommitteeService, StateService],
  components: {
    'edit-committee-modal': editCommitteeModal,
    'new-committee-modal': newCommitteeModal,
    'new-url-modal': newUrlModal,
  },
  data() {
    return {
      isLoading: false,
      hasJointCommittees: false,
      hasSubCommittees: false,
      search: '',
      stateList: [],
      selectedStateId: null,
      committeeList: [],
      memberships: [],
      urlList: [],
      urlSearch: '',
      selectMembershipIds: [],
      urlPagination: {
        itemsPerPage: 10
      },
      pagination: {
        itemsPerPage: 10
      }
    }
  },
  computed: {
    committeeTableHeader() {
      return [
        {text: '', value: 'action', align: 'center', width: '105px', sortable: false},
        {text: 'Type', value: 'type', align: 'center'},
        {text: 'Short Name', value: 'shortname', align: 'center'},
        {text: 'Full Name', value: 'fullname', align: 'center'},
      ]
    },
    urlTableHeader() {
      return [
        {text: '', value: 'action', align: 'center', width: '105px', sortable: false},
        {text: 'Name', value: 'name', align: 'center'},
        {text: 'Url', value: 'url', align: 'center'},
      ]
    },
    committeeTypeList() {
      return [
        'House',
        'Senate',
        'Joint',
      ]
    },
  },
  async mounted() {
    this.isLoading = true
    this.stateList = (await this.getStates()).data
    this.memberships = (await this.getMemberships()).data
    this.refresh()
  },
  methods: {
    async refresh() {
      if (!this.selectedStateId) return
      this.refreshLegislatorUrls()
      this.selectMembershipIds = (await this.getCommitteeMemberships(this.selectedStateId)).data.map(item => item.id)
      this.hasJointCommittees = this.stateList.find(state => state.id === this.selectedStateId).has_joint_committees
      this.hasSubCommittees = this.stateList.find(state => state.id === this.selectedStateId).has_subcommittees
      this.committeeList = (await this.getStateCommittees(this.selectedStateId)).data
      this.isLoading = false
    },
    async refreshLegislatorUrls() {
      this.urlList = (await this.getLegislatorsUrls(this.selectedStateId)).data

    },
    async onUpdateState() {
      const payload = {
        has_joint_committees: this.hasJointCommittees,
        has_subcommittees: this.hasSubCommittees
      }
      await this.updateState(this.selectedStateId, payload)
      this.stateList = (await this.getStates()).data
      this.onCallToastr('success', 'Committee has been updated.', 'Success!')
    },
    onDeleteMembership(id) {
      swal({
        title: 'Are you sure?',
        html: 'This action will delete all relationships between legislators and the membership.',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.onSaveMemberships()
        } else {
          this.selectMembershipIds.push(id)
        }
      })
    },
    onUpdateMemberships(event, id) {
      if (event === false) {
        this.onDeleteMembership(id)
      } else {
        this.onSaveMemberships()
      }
    },
    onSaveMemberships() {
      setTimeout(() => {
        const payload = {
          membership_ids: this.selectMembershipIds
        }
        this.saveCommitteeMemberships(this.selectedStateId, payload)
            .then(() => {
              this.onCallToastr('success', 'Memberships have been updated.', 'Success!')
              this.refresh()
            })
            .catch((err) => {
              this.status = ''
              this.handleError(err)
            })
      }, 0)
    },
    onDeleteUrl(urlId) {
      swal({
        title: 'Are you sure to delete this url?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteLegislatorsUrls(this.selectedStateId, urlId)
              .then(() => {
                this.onCallToastr('success', 'Url has been deleted.', 'Success!')
                this.refresh()
              })
              .catch((err) => {
                this.handleError(err, true)
              })
        }
      })
    },
    onDeleteCommittee(committeeId) {
      swal({
        title: 'Are you sure to delete this committee?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteCommittee(committeeId)
              .then(() => {
                this.onCallToastr('success', 'Committee has been deleted.', 'Success!')
                this.refresh()
              })
              .catch((err) => {
                this.handleError(err, true)
              })
        }
      })
    }
  }
}
</script>
