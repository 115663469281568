<template>
  <v-dialog max-width="500" v-model="dialog" @click:outside="close">
    <v-card class="message-modal-content">
      <v-card-title>
        <span class="title">
          {{ form.id > 0 ? 'Edit relationship' : 'Create relationship' }}
          <v-progress-circular
              indeterminate
              color="primary"
              size="20"
              width="2"
              v-if="loading"
          ></v-progress-circular>
        </span>
      </v-card-title>
      <v-divider></v-divider>

      <div v-if="relationships && relationships.length && !form.id" class="relationships_predefined">
        <v-expansion-panels flat hover>
          <v-expansion-panel>
            <v-expansion-panel-header>Choose from predefined list</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="d-flex flex-wrap">
                <v-chip
                    v-for="relationship  in filteredRelationshipsList"
                    avatar
                    @click="setPredefined(relationship)"
                    class="py-2 mr-2 my-2"
                    :key="relationship.name"
                >
                  <v-avatar left :color="relationship.color">
                    <v-icon size="14" color="white" v-if="relationship.icon == 'phone'">{{ relationship.icon }}</v-icon>
                    <v-icon size="14" color="white" v-else-if="relationship.icon == 'fa-brands fa-twitter'">{{ relationship.icon }}</v-icon>
                    <span v-else>{{ relationship.icon }}</span>
                  </v-avatar>
                  {{ relationship.name }}
                </v-chip>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-divider></v-divider>

      <v-card-text class="py-3">
        <v-text-field
            v-model="form.name"
            :disabled="saveOnly"
            :error-messages="errorMessages('name')"
            label="Label"
            counter="50"
            hint="Max 50 characters"
            :error="!isValidName"
            :hint="saveOnly ? '' : 'Max 50 characters'"
            :counter="saveOnly ? false : 50"
        >
        </v-text-field>
        <v-text-field
            v-model="form.icon"
            :disabled="saveOnly"
            @input="validateIcon()"
            :error="iconLimit"
            :error-messages="errorMessages('icon')"
            label="Abbreviation"
            :hint="saveOnly ? '' : 'Max 2 characters'"
            :counter="saveOnly ? false : 2"
        >
        </v-text-field>
        <v-select
            v-model="form.color"
            :items="colors"
            :error-messages="errorMessages('color')"
            item-text="title"
            item-value="value"
            label="Relationship Quality"
        >
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="clearForm" color="warning">Clear form</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="loading" :disabled="iconLimit || !isValidName">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ClientService from '@/services/ClientService'
  import relationships from '@/entities/relationships'

  export default {
    name: "RelationshipForm",
    mixins: [ClientService],
    props: {
      list: {
        type: Array,
        default: function () {
          return []
        }
      },
      client: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    data() {
      return {
        dialog: false,
        errors: false,
        loading: false,
        form: {
          name: '',
          icon: '',
          color: ''
        },
        colors: [
          { title: 'Good', value: 'success' },
          { title: 'Average', value: 'warning' },
          { title: 'Bad', value: 'error' },
          { title: 'Neutral', value: 'blue' }
        ],
        relationships: relationships,
        iconLimit: false
      }
    },
    computed: {
      saveOnly() {
        return this.relationships.some(item => item.name == this.form.name)
      },
      isValidName() {
        return this.form.name ? this.form.name.length <= 50 : true
      },
      filteredRelationshipsList() {
        return this.relationships.filter(item => !this.list.map(rel => rel.name).includes(item.name))
      },
    },
    methods: {
      open(id) {
        if (id > 0) {
          this.getClientRelationship(id)
        }
        this.errors = false
        this.dialog = true
      },
      close() {
        this.form = {}
        this.$emit('update')
        this.dialog = false
      },
      save() {
        this.loading = true
        this.saveClientRelationships(this.client.id, this.form).then(()=> {
          this.onCallToastr('success', 'New relationship has been created.', 'Success!')
          this.close()
          this.loading = false
          this.errors = false
        }, err => {
          this.errors = err.response.data.errors
          this.loading = false
        })
      },
      getClientRelationship(id) {
        this.loading = true
        this.getClientRelationshipById(this.client.id, id).then(response => {
          this.form = response.data
          this.loading = false
        })
      },
      errorMessages(field) {
        return (this.errors && this.errors[field]) ? this.errors[field][0] : []
      },
      clearForm() {
        this.form = { name: '', icon: '', color: '' }
        this.errors = false
      },
      setPredefined(relationship) {
        this.form = relationship
        this.errors = false
      },
      validateIcon() {
        if (!this.saveOnly) this.iconLimit =  this.form.icon.length > 2
      }
    }
  }
</script>
