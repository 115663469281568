export default {
  get() {
    return window.localStorage.getItem('onlyProfileUpdate')
  },
  set(param) {
    window.localStorage.setItem('onlyProfileUpdate', param)
  },
  remove() {
    window.localStorage.removeItem('onlyProfileUpdate')
  }
}
