<template>
  <v-dialog persistent max-width="750" v-model="isModalOpen">
    <v-card :loading="isLoading">
      <v-card-title>
        <span class="title">Filters Applied</span>
        <!-- <span class="title">Send test to {{ isEmail ? userData.email : userData.cell_phone }}</span> -->
      </v-card-title>
      <v-divider></v-divider>
      <div style="padding:10px">
        <!-- Search Keyword -->
        <p v-if="filterData.from"><b>From: </b> {{ filterData.from }} </p>
        <p v-if="filterData.title"><b>Title: </b> {{ filterData.title }} </p>
        <p v-if="filterData.recipientType"><b>Recipient Type: </b> {{ filterData.recipientType }} 
        <span v-if="filterData.recipientType =='KPs'">{{ filterData.unique == true ? ': 1 Per Unique KP' : ': 1 Per Legislator' }}</span></p>
        <p v-if="filterData && filterData.filter && filterData.filter.search"><b>Search Keyword: </b> {{ filterData.filter.search }}</p>
        <!-- Filters -->
        <p v-if="filterData && filterData.filter && filterData.filter.showInactive"><b>Show Inactive: </b> {{ filterData.filter.showInactive == true ? "Yes" : 'No' }}</p>
        <p v-if="filterData && filterData.filter && filterData.filter.showInvalid"><b>No valid email or cell: </b> {{ filterData.filter.showInvalid == true ? "Yes" : 'No' }}</p>
        <p v-if="filterData && filterData.filter && filterData.filter.districtNotSet"><b>Districts not set: </b> {{ filterData.filter.districtNotSet == true ? "Yes" : 'No' }}</p>
        <p v-if="filterData && filterData.filter && filterData.filter.activeKP1"><b>Active KP1: </b> {{ filterData.filter.activeKP1 == true ? "Yes" : 'No' }}</p>
        <p v-if="filterData && filterData.filter && filterData.filter.isVerified"><b>Is Verified: </b> {{ filterData.filter.isVerified == true ? "Yes" : 'No' }}</p>
        <!-- KP Preference -->
        <p v-if="filterData && filterData.filter && filterData.filter.kpPreferenceResults"><b>KP Preferences: </b> {{ formattedKPPreferences }}</p>
        <!-- Legislators: -->
        <p v-if="filterData && filterData.filter && filterData.filter.isSpecificLegislatorsOnly && filterData.filter.legislators"><b>Specific Legislator(s) Only: </b> {{ filterData.filter.isSpecificLegislatorsOnly == true ? 'Yes' : 'No' }}</p>
        
        <p v-if="filterData && filterData.filter && filterData.filter.committeeType"><b>Committee Type: </b> {{ formattedCommitteeType }}</p>
        <p v-if="filterData && filterData.filter && filterData.filter.selectedCommitteeIdList"><b>Selected Committees: </b> {{ formattedSelectedCommittees }}</p>
        <p v-if="filterData && filterData.filter && filterData.filter.selectedSubCommitteeIdList"><b>Selected Sub-Committees: </b> {{ formattedSelectedSubCommittees }}</p>
        <p v-if="filterData && filterData.filter && filterData.filter.vip"><b>VIP: </b> {{ filterData.filter.vip == true ? 'Yes' : 'No' }}</p>
        <p v-if="filterData && filterData.filter && filterData.filter.lameDuck"><b>Lame Duck: </b> {{ filterData.filter.lameDuck == true ? 'Yes' : 'No' }}</p>
        <p v-if="filterData && filterData.filter && filterData.filter.legislators"><b>Selected Legislators: </b> {{ formattedLegislators }}</p>
        <p v-if="filterData && filterData.filter && filterData.filter.tasks"><b>Tasks: </b> {{ formattedTasks }}</p>
        <!-- Custom Fields -->
        <p v-if="filterData && filterData.filter && filterData.filter.customFields"><b>Custom Fields: </b> {{ formattedCustomFields? formattedCustomFields : 'No custom profile field filters have been applied.' }}</p>
      </div>
      <v-divider></v-divider>
      <v-card-text class="mt-3 text-right">
        <v-btn
          class="mr-4"
          outlined
          color="secondary"
          @click="closeModal"
        >
          Close Filters
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import he from "he";
import SuperAdminService from "@/services/SuperAdminService";

export default {
  name: 'ShowMessageFilterListModal',
  mixins: [SuperAdminService],
  props: {
    filterData: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
    }
  },
  methods: {
    open() {
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
    },
  },
  computed: {
    formattedKPPreferences() {
      // Capitalize the first letter of each string, replace "either" with "Either/Independent", and join the array into a comma-separated string
      return this.filterData.filter.kpPreferenceResults
        .map(item => {
          if (item.toLowerCase() === "either") {
            return "Either/Independent";
          }
          return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        })
        .join(', ');
    },
    formattedLegislators() {
      // Map the legislators array and concatenate nickname and lastname, then join as comma-separated string
      return this.filterData.filter.legislators
        .map(legislator => `${legislator.nickname} ${legislator.lastname}`)
        .join(', ');
    },
    formattedTasks() {
      return this.filterData.filter.tasks
        .map(task => `${task.name} (${task.value})`)
        .join(', ');
    },
    formattedCommitteeType() {
      let type = this.filterData.filter.committeeType;
      switch (type) {
        case '0':
          return "House";
        case '1':
          return "Senate";
        case '2':
          return "Joint";
        default:
          return "-";  // Fallback value if type is not 0, 1, or 2
      }
    },

    formattedSelectedCommittees() {
      return this.filterData.filter.selectedCommitteeIdList
        .map(legislator => `${legislator.fullname}`)
        .join(', ');
    },
    formattedSelectedSubCommittees() {
      return this.filterData.filter.selectedSubCommitteeIdList
        .map(legislator => `${legislator.fullname}`)
        .join(', ');
    },
    formattedCustomFields() {
      return this.filterData.filter.customFields
        .map(item => {
          if (item.metadata && item.metadata.length > 0) {
            const metadataFormatted = item.metadata.map(meta => {
              let typeValue = "";
              switch (meta.type) {
                case 0:
                  typeValue = "No";
                  break;
                case 1:
                  typeValue = "Yes";
                  break;
                default:
                  typeValue = "All";
                  break;
              }
              return `${meta.name}: ${typeValue}`;
            }).join(", ");
            return `${item.name} (${metadataFormatted})`;
          } else {
            if(item.value === true){
              return `${item.name} (Yes)`;
            }
          }
        })
        .join(', ');
    }


  }
}
</script>
