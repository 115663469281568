<template>
  <kp-tasks-component></kp-tasks-component>
</template>

<script>
import KpTasksComponent from '@/components/kp-tasks/kp-tasks-component';

export default {
  name: 'MyTasksPage',
  components: {
    'kp-tasks-component' : KpTasksComponent
  }
}
</script>
