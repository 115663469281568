<template>
  <div>
    <div class="register-page_form my-auto elevation-24" :class="isDarkTheme ? 'card-dark' : 'card-light'">
        <auth-logo></auth-logo>
          <v-alert color="error" :value="true" class="mb-3 mt-5" outlined type="error" v-if="error && !errors">
            {{ error }}
          </v-alert>
          <div class="d-flex align-center justify-center text-center register-page_form__inner mt-8">
            <transition :name="position">
              <div
                v-if="isKeyPerson === 0"
              >
                  <div v-if="!isBusy && dataConfirmation">
                    <!-- no need for this nested card
                    <v-card>
                      <v-card-title class="justify-center">
                        <auth-logo></auth-logo>
                      </v-card-title>
                      -->
                      <v-card-text>
                      <h2>User Verification</h2>
                        <v-alert v-if="!errorMessage" icon="fa-thin fa-shield-exclamation" type="info" dense :value="true" outlined class="mb-3">
                          Hey! Let's make sure it's really you...
                        </v-alert>
                        <v-alert type="error" color="primary" :value="true" outlined class="mb-3" v-if="errorMessage">
                        {{ errorMessage }}
                        </v-alert>
                        <v-form ref="form" @submit.prevent="onMemberCheck">
                          <v-layout row wrap class="mt-2">
                            <v-flex v-if="!isDob" xs12 class="px-2" >
                              <v-text-field
                                  outlined
                                  v-model="lastname"
                                  label="Last Name"
                                  :rules="lastnameRules"
                              ></v-text-field>
                            </v-flex>
                            <v-flex v-else xs12 class="px-2">
                            <span>Please verify your date of birth</span><br />
                              <v-text-field
                                  outlined
                                  v-model="dob"
                                  label="mm/dd/yyyy"
                                  v-mask="'##/##/####'"
                                  :rules="dobRules"
                              ></v-text-field>
                            </v-flex>
                            <v-btn block type="submit" color="success">Submit</v-btn>
                          </v-layout>
                        </v-form>
                      </v-card-text>
                    <!--</v-card>-->
                  </div>
                  <div v-if="!isBusy && !isProfileUpdate && !dataConfirmation">
                    <v-alert type="info" color="primary" :value="true" outlined class="mb-3" v-if="user && isKP && serviceType !== 'adv'">
                          Hi, {{ user.nickname }}, please update the tasks for your assigned legislator(s) and then press Save for each. This link will expire in 2 hours.
                    </v-alert>
                    <v-alert type="info" color="primary" :value="true" outlined class="mb-3" v-if="isMember || (isKP &&
                        serviceType
                        ===
                        'adv')">
                          Hi {{ user.nickname }}, please review this message and edit to your liking before sending to your legislator. This link will expire in 2 hours. Thank you!
                    </v-alert>
                    <v-alert type="error" color="primary" :value="true" outlined class="mb-3" v-if="!user && !errorMessage">
                          This link is invalid or has expired. Please click below to request new login link using the KP LOGIN tab.
                    </v-alert>
                    <v-alert type="error" color="primary" :value="true" outlined class="mb-3" v-if="!user && errorMessage">
                      {{ errorMessage }}
                    </v-alert>
                    <v-alert v-if="user">
                      <countdown-timer :countdownDuration="120" :startsAt="user.token_used_at"></countdown-timer>
                    </v-alert>
                    <v-btn block color="success" @click="() => !user ? toKeyPerson() : onNext()">{{ user ? 'Proceed' : 'Request New Login Link' }}</v-btn>
                  </div>
                  <div v-if="!isBusy && isProfileUpdate && !dataConfirmation">
                      <v-alert type="info" color="primary" :value="true" outlined class="mb-3" v-if="user && serviceType !== 'adv'">
                          Hi, {{ user.nickname }}, please confirm or update your {{ userClient.assoc_abbrev }} profile so that we can
                          ensure we
                          have the most up-to-date information. Thanks!
                      </v-alert>
                      <v-alert type="info" color="primary" :value="true" outlined class="mb-3" v-if="!user && profUpdate && serviceType !== 'adv'">
                          This URL is not valid. Please check again or contact your Administrator!
                      </v-alert>
                      <v-btn block color="success" @click="() => !user ? toKeyPerson() : onNext()">{{ user ? 'Continue' : 'Request New Login Link' }}</v-btn>
                  </div>
                  <div v-if="isBusy" class="d-flex justify-center align-center">
                    <v-progress-circular
                        :size="70"
                        :width="5"
                        color="#fff"
                        indeterminate
                    ></v-progress-circular>
                  </div>
              </div>
            </transition>
            <transition :name="'right'">
              <div
                v-if="isKeyPerson === 2"
                class="register-page_form__inner_major"
                :class="isDarkTheme ? 'card-dark' : 'card-light'"
              >
                <div class="d-flex align-center justify-space-between my-4">
                  <p class="card-title mb-0">
                    Choose where to send auto-login link
                  </p>
                  <v-tooltip bottom max-width="200px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          medium
                          color="primary"
                          v-on="on"
                        >
                          fa-circle-question
                        </v-icon>
                      </template>
                      <span>Contact your Admin if you are unsure of the email or cell phone # we have on file for you.
                        They can also send you a link directly.
                      </span>
                    </v-tooltip>
                  <v-btn
                    fab
                    x-small
                    outlined
                    right
                    color="primary"
                    @click="backToMenuFromKeyPerson"
                    :class="isMobile ? 'ml-1' : ''"
                    class="back-btn"
                  >
                    <v-icon large>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </div>
                <!-- removed to make room on mobile
                <v-card-subtitle>
                  Contact your Admin if you are unsure of the email or cell phone # we have on file for you.
                  They can also send you a link directly.
                </v-card-subtitle>-->
                <v-card-text class="pa-0">
                  <v-form ref="loginForm" @submit.prevent="getKPLoginLink" v-if="!twoFactorCodeSent">
                    <v-text-field
                      label="Send to my cell phone"
                      outlined
                      prepend-inner-icon="phone_iphone"
                      v-mask="'(###) ###-####'"
                      :rules="phoneNumberRules"
                      v-model="cellPhone"
                      clearable
                      @input="clearError"
                      :error-messages="errorMessages('phone')"
                      :disabled="disabledField(fastEmail)"
                      :hide-details="errors == null || errors && !errors['phone']"
                      hint="Enter your cell phone number"
                    ></v-text-field>
                    <div class="text-center"
                        :class="errors == null || errors && !errors['phone'] ? 'my-3' : 'mb-4'">
                      <h3>or</h3>
                    </div>
                    <v-text-field
                      outlined
                      label="Send to my email"
                      prepend-inner-icon="email"
                      type="email"
                      v-model="fastEmail"
                      clearable
                      :rules="restoreEmailRules"
                      @input="clearError"
                      :error-messages="errorMessages('email')"
                      :disabled="disabledField(cellPhone)"
                      hint="Enter your email address"
                    ></v-text-field>
                    <v-btn
                      type="submit"
                      :dark="disabledField(cellPhone) || disabledField(fastEmail)"
                      :disabled="!disabledField(cellPhone) && !disabledField(fastEmail)"
                      class="mt-2"
                      block
                      large
                      color="primary"
                      :loading="isBusy"
                    >
                      Send My Login Link
                    </v-btn>
                  </v-form>
                </v-card-text>
              </div>
            </transition>
            <!-- alert was here -->
          </div>
          <system-message-alert :list="systemMessages" class="mt-10"></system-message-alert>
    </div>
  </div>

</template>

<script>
import AuthService from '@/services/AuthService'
import profileUpdateStorage from '@/utils/storage/profile-update'
import defaultRoutes from '@/router/default-routes'
import VDatePicker2 from "@/components/common/date-picker";
import CountdownTimer from "@/components/common/countdown-timer";
import systemMessageAlert from '@/components/auth/system-message-alert'
import logo from '../../components/common/auth-logo'
import swal from "sweetalert2";
import SystemMessageService from '@/services/SystemMessageService';

export default {
  metaInfo: {
    title: 'Key Person Login',
    meta: [
      {
        property: 'og:title',
        content: 'Key Person Login'
      },
      {
        property: 'og:image',
        content: `${window.location.origin}/favicon_ios_white.png`
      },
    ]
  },
  mixins: [AuthService, SystemMessageService],
  components: {
    VDatePicker2,
    'auth-logo': logo,
    'countdown-timer': CountdownTimer,
    'system-message-alert': systemMessageAlert,
  },
  data() {
    return {
      defaultRoutes: defaultRoutes,
      isBusy: true,
      user: null,
      errorMessage: null,
      token: '',
      profUpdate: false,
      dataConfirmation: false,
      lastname: null,
      dob: null,
      isDob: false,
      position: null,
      isKeyPerson: 0,
      cellPhone: null,
      fastEmail: null,
      message: null,
      showSnackbar: false,
      error: '',
      errors: null,
      twoFactorCodeSent: false,
      systemMessages: [],
      sufix: null,
    }
  },
  computed: {
    lastnameRules() {
      return [
        v => !!v || 'Lastname is required.',
      ]
    },
    dobRules() {
      return [
        v => !!v || 'Date of Birth is required.',
      ]
    },
    serviceType () {
      return this.$route.query.s ? this.$route.query.s : ''
    },
    isKP () {
      if (!this.user) return false
      return this.user.role === 'KP'
    },
    isMember () {
      if (!this.user) return false
      return this.user.role === 'Member'
    },
    isProfileUpdate () {
      return this.$route.query.prof === 'true'
    },
    emailRules() {
        return [
          v => !!v || 'E-mail is required.',
        ]
      },
      restoreEmailRules() {
        return [
          v => (!v || !!v) || 'E-mail is required.',
        ]
      },
      passwordRules() {
        return [
          v => !!v || 'Password is required.',
        ]
      },
  },
  mounted () {
    this.dataConfirmation = false
    this.profUpdate = false
    this.lastname = null
    this.dob = null
    this.token = this.$route.params.token
    this.onLogin()
    this.sufixes()
  },
  methods: {
      onLogin () {
      const payload = { token: this.token }

      this.loginAsMember(payload)
        .then((res) => {
          let data = res.data
          data.user = {
            ...data.user,
            serviceType: this.serviceType
          }
          this.$store.dispatch('auth/setUserData', data)
          this.$store.dispatch('auth/refreshSession')
          this.user = res.data.user
          this.isBusy = false
        })
        .catch(error => {
          this.isBusy = false

          if (this.isProfileUpdate) {
            this.profUpdate = true
          }
          if (error.response.status == 429 || error.response.status == 410) {
            this.errorMessage = error.response.data.message
          }
          if (error.response.status == 403) {
            this.dataConfirmation = true
            this.isDob =  error.response.data.dob
          }
        })
    },
    onMemberCheck() {
      if (!this.$refs.form.validate()) return
      this.isBusy = true
      this.errorMessage = null
      const payload = {
        token: this.token,
      }

      this.isDob ? payload.dob = this.dob : payload.lastname = this.lastname

      this.memberCheck(payload)
          .then(async() => {
            this.isBusy = false
            swal({
              title: 'Verified!',
              text: 'Thanks! We will verify again in 6 months.',
              showCancelButton: false,
              cancelButtonColor: '#d33',
              confirmButtonText: 'Continue...',
              reverseButtons: true,
            }).then(async (result) => {
              if (result.value) {
                this.dataConfirmation = false
                await this.onLogin()
              }
            })
          })
          .catch((error) => {
            if (error.response.status == 403) {
              this.errorMessage = error.response.data.message
            }
            if (error.response.status == 401|| error.response.status == 423) {
              this.dataConfirmation = false
            }
            this.isBusy = false
          })
    },
    onNext () {
      if (this.isProfileUpdate && this.user) {
        profileUpdateStorage.set(true)
        return this.$router.push('/profile-update')
      }

      if(this.user) {
        this.setDefaultRoute()
      } else {
        this.logout()
      }
    },
    setDefaultRoute() {
      let userRole = this.$store.state.auth.userData ? this.$store.state.auth.userData.role : false
      let serviceType = this.$store.state.auth.userData ? this.$store.state.auth.userData.serviceType : false
      if (this.user && (userRole == 'KP' || userRole == 'Member')) {
        let route = serviceType == 'adv' ? '/assigned-tasks' : '/my-tasks'
        return this.$router.push(route)
      } else {
        this.logout()
      }

      this.$router.push(this.defaultRoutes[userRole])
    },
    toKeyPerson() {
        this.position = 'left'
        setTimeout(()=> {
          this.isKeyPerson = 2
        }, 0)
      },
    backToMenuFromKeyPerson() {
        this.position = 'left'
        setTimeout(()=> {
          this.isKeyPerson = 0
        }, 0)
    },
    errorMessages(field) {
        return (this.errors && this.errors[field]) ? this.errors[field][0] : []
    },
    disabledField(field) {
      return field && field.length && field !== null
    },
    async getKPLoginLink() {
        if (!this.$refs.loginForm.validate()) return
        this.isBusy = true;
        let data = {
          phone: this.cellPhone
        }
        if (this.fastEmail && this.fastEmail.length && this.fastEmail !== null) {
          data = {
            email: this.fastEmail
          }
        }
        if (this.sufix) {
          data['sufix'] = this.sufix
        }
        try {
          const response = await this.sendKPLoginLink(data);
          if (response.status === 200 && response.data.message !== undefined) {
            this.message = response.data.message
            this.showSnackbar = true
          }
          if (response.status !== 200 && response.data.message !== undefined) {
            this.error = response.data.message
            this.showSnackbar = true
          }
          swal({
            title: 'Success',
            type: 'success',
            html: `Please check your email/phone for your link`,
            confirmButtonColor: '#3085d6',
          }).then(() => {
            this.cellPhone = null
            this.fastEmail = null
          })
          this.isBusy = false
        } catch (e) {
          this.isBusy = false
          const responseData = e && e.response && e.response.data ? e.response.data:'';
          this.error = responseData && responseData.message || '';
          this.errors =  responseData && responseData.errors || null;
        }
    },
    clearError() {
      this.errors = null
      this.error = ''
    },
    loadSystemMessages() {
      this.getSystemMessages({active: 1}).then(response => {
        this.systemMessages = response.data.data
      })
    },
    sufixes() {
      if (this.$route.query.prof && this.$route.query.prof === 'true') {
        this.sufix = 'prof'
      } else if (this.$route.query.s && this.$route.query.s === 'adv') {
        this.sufix = 's'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-light {
    background-color: #f4f5fa!important;
    .register-page_person button {
      box-shadow: 0 2px 6px -2px rgb(49, 45, 75) !important;
      color: rgba(94, 86, 105, 0.87);
    }
  }
  .card-dark {
    background-color: var(--v-primary-lighten1);
    .register-page_person button {
      box-shadow: 0 2px 6px -2px #f4f5fa !important;
    }
  }
  .lost-device {
    font-size: 18px;
    font-weight: 400;
  }
  .v-card__title {
    word-break: break-word;
  }

  .card-title {
    font-size: 16px!important;
    font-weight: 500;
    line-height: 1.2;
    @media (min-width: 380px) {
      font-size: 20px!important;
      font-weight: 500;
    }
  }
  .back-btn {
    @media (max-width: 380px) {
      height: 24px;
      width: 24px;
    }
  }

  .left-leave-active {
    transition: 0.5s;
  }
  .left-enter-active {
    transition: 0.3s;
  }

  .left-enter {
    transform: translate(120%, 0);
  }

  .left-leave-to {
    transform: translate(120%, 0);
  }

  .right-leave-active {
    transition: 0.5s;
  }
  .right-enter-active {
    transition: 0.3s;
  }

  .right-enter {
    transform: translate(-120%, 0);
  }

  .right-leave-to {
    transform: translate(-120%, 0);
  }
</style>
