<template>
  <div>
    <v-flex class="d-flex align-center flex-wrap">
      <v-text-field
        prepend-inner-icon="search"
        label="Search"
        v-model="search"
        clearable
        class="search-input search-input_members"
        persistent-hint
      >
      </v-text-field>
    </v-flex>
    <v-data-table
      :loading="isLoading"
      :headers="systemActionsTableHeader"
      :options.sync="list.pagination"
      :server-items-length="list.pagination.totalItems"
      :items="list.items"
      @update:options="getSystemActionList"
      :disable-sort="isMobile"
      :search="search"
    >
      <template v-slot:item.content="{ item }">
        <div>{{ item.content }}</div>
      </template>

      <template v-slot:item.created_at="{ item }">
        <div>{{ formatDate(item.created_at) }}</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import ClientService from "@/services/ClientService";

export default {
  name: "SystemActionComponent",
  mixins: [ClientService],
  props: {
    clientId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isLoading: false,
      search: '',
      list: {
        pagination: {
          page: 1,
          itemsPerPage: 10,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
          sortBy: [],
          sortDesc: [],
        },
        items: [],
      },
    }
  },
  computed: {
    systemActionsTableHeader() {
      return [
        { text: 'Action', value: 'content'},
        { text: 'Date', value: 'created_at'},
      ]
    }
  },
  methods: {
    goTo (rout) {
      this.$router.push({ name: rout }).catch(err => {})
    },
    getSystemActionList() {
      this.isLoading = true

      let params = {
        page: this.list.pagination.page,
        limit: this.list.pagination.itemsPerPage,
        order: this.list.pagination.sortBy[0],
        direction: this.list.pagination.sortDesc[0] || !this.list.pagination.sortDesc.length ? 'desc' : 'asc',
        search: this.search
      }
      return this.getSystemActions(this.clientId, params).then((response) => {
        this.list.items = response.data.data ? response.data.data : response.data
        this.list.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.list.pagination.current_page = response.data.current_page
        this.list.pagination.per_page = response.data.per_page
        this.list.pagination.last_page = response.data.last_page

        this.isLoading = false
      }, () => {
        this.isLoading = false
      })
    },
  },
  mounted() {
    this.getSystemActionList()
  },
  watch: {
      'search'() {
        clearTimeout(this.timer)
        this.timer = setTimeout(()=> {
          this.list.items = []
          this.list.pagination.page = 1
          this.getSystemActionList()
        }, 500)
      }
    },
}
</script>
