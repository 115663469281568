import ScheduleMessageService from "@/services/ScheduleMessageService";
import logger from "vuex/dist/logger";

export default {
  mixins: [ScheduleMessageService],
  data() {
    return {
      urls: [],
      badVariables: false,
      shortDns : 'kpda.sh',
      disabledEditor: false,
    }
  },
  methods: {
    removeHighlight(message) {
      let text = message.replace(/<strong style="color: red;">/gi, '');
      text = text.replace(/<\/strong>/gi, '');
      return text
    },
    checkVariablesForEditor(message, variables) {
      if (!message) return false;
      message = this.removeHighlight(message)

      let regexp = /\{.*?[^}]\}/gi;
      let matches_array = message.match(regexp) || [];
      for(let item of matches_array) {
        if (!variables.some(variable => variable == item)) {
          this.badVariables = true
          let wrongVariable = `<strong style="color: red">${item}</strong>`
          message = message.replace(item, wrongVariable);
        }
      }
      this.$nextTick(async () => {
        if (tinymce && tinymce.activeEditor) {
          tinymce.activeEditor.setContent(message)
          document.activeElement.blur()
        }
      })
    },
    searchIncorrectVariables(message, variables) {
      this.badVariables = false
      if (!message) return false;
      let regexp = /\{.*?[^}]\}/gi;
      let matches_array = message.match(regexp) || [];
      for(let item of matches_array) {
        if (!variables.some(variable => variable == item)) {
          this.badVariables = true
        }
      }
    },
    async parsUrl(message) {
      if (!message) return message

      let messageWithoutImg = message.replace(/<img .+>/g, '')

      let urls = messageWithoutImg.match(/(https?:\/\/[^\s"<]+)/g)
      urls = urls ? urls : []

      if (_.isEqual(urls, this.urls)) return message

      for (let url of urls) {
        if (url.indexOf(this.shortDns) === -1) {
          url = url.replace('&nbsp;', '')
          if (url.length < 27) {
            return message
          } else {
            this.disabledEditor = true
            this.$emit('checkingText', true)

            const payload = {
              url: url
            }
            await this.convertToShort(payload)
              .then(response => {
                this.$emit('checkingText', false)
                message = message.replace(url, response.data)
              })
              .catch((err) => {
                this.$emit('checkingText', false)
                this.handleError(err)
              })
            return message
          }
        }
      }
      this.urls = urls;

      return message
    },
  }
}
