<template>
  <v-dialog persistent max-width="600" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <span class="title">Silence this Advocate from all communications</span>
        <v-spacer/>
        <v-icon class="ml-3" @click="close">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="silenceForm">
          <v-layout wrap>
            <v-flex xs12 class="px-2">
              <v-textarea
                label="Reason to silence this member"
                auto-grow
                rows="3"
                counter
                :rules="reasonRules"
                v-model="reason"
              ></v-textarea>
            </v-flex>
            <v-card-subtitle>Select a date to automatically unsilence advocate (optional)</v-card-subtitle>
            <v-flex xs4 class="px-2">
              <v-menu
                ref="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Select date"
                    prepend-icon="mdi-calendar"
                    readonly
                    :clearable="!!date"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :min="currentDate"
                  no-title
                  scrollable
                  @change="$refs.dateMenu.save(date)"
                >
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click="close">Cancel</v-btn>
        <v-btn color="primary" @click.native="onSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClientService from '@/services/ClientService'
import MemberService from '@/services/MemberService'
import swal from 'sweetalert2'

export default {
  name: "EditSilenceModal",
  mixins: [ClientService, MemberService],
  props: {
    clientId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isModalOpen: false,
      reason: null,
      date: null
    }
  },
  computed: {
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    reasonRules() {
      return [
        v => !!v || 'Reason is required.',
      ]
    }
  },
  methods: {
    onSave() {
      if (!this.$refs.silenceForm.validate()) return
      this.$emit('onSave', [this.reason, this.date])
      this.isModalOpen = false
    },
    close() {
      this.reason = null
      this.date = null
      this.isModalOpen = false
      this.$emit('cancel')
    }
  }
}
</script>
