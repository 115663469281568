<template>
  <v-container fluid grid-list-lg class="pa-0">
    <v-layout  v-if="progressCircle" class="justify-center align-center" style="height: 100vh">
      <v-progress-circular
          indeterminate
          color="primary"
          size="50"
      ></v-progress-circular>
    </v-layout>
    <v-layout v-else  class="justify-center align-center" style="height: 100vh">
      <v-alert type="error" color="error" :value="true" outlined >
        This link is invalid or has expired.
      </v-alert>
    </v-layout>
  </v-container>
</template>

<script>

import ScheduleMessageService from "@/services/ScheduleMessageService";

export default {
  name: 'Shorter',
  mixins: [ScheduleMessageService],
  data() {
    return {
      progressCircle: true
    }
  },
  async created() {
    const params = new URLSearchParams(window.location.search)
    if (params.has('l')) {
      await this.getShorterUrl({link: params.get('l')})
          .then(response => {
            if (response.data && response.data.url) {
              document.location.href = response.data.url
            } else {
              this.progressCircle = false
            }
          })
          .catch((err) => {
            this.handleError(err)
          })
    }
  },
};
</script>
