import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"offset-y":"","disabled":_vm.disabled,"max-width":"290px","min-width":"290px","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VTextField,_vm._g({attrs:{"prepend-icon":"event","readonly":"","outlined":"","label":_vm.label,"value":_vm.fieldValue,"rules":_vm.rules,"dense":_vm.dense}},on))]}}])},[_c(VDatePicker,{attrs:{"color":"primary","scrollable":"","value":_vm.value,"min":_vm.minDate,"max":_vm.maxDate},on:{"input":_vm.onChangeDate}},[(!_vm.required)?_c(VSpacer):_vm._e(),(!_vm.required)?_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.onReset}},[_vm._v("Reset")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }