import Vue from 'vue'
import kpPreference from '@/entities/kp-preference'

Vue.mixin({
  data() {
    return {
      kpPreference: kpPreference
    }
  },
  methods: {
    kpPreferenceBorder(member, width = `2px`, side = '') {
      if(!!member.kp_preference) {
        const item = this.kpPreference.find(item => item.value === member.kp_preference)
        if (this.isVIP || this.isCoordinator) {
          return `border${side}: ${width} solid ${item.color} !important`
        }
      }
      return `border${side}: ${width} solid transparent !important`
    },
    kpPreferenceColor(member) {
      if(!!member.kp_preference) {
        const item = this.kpPreference.find(item => item.value === member.kp_preference)
        if (this.isVIP || this.isCoordinator) {
          return `${item.color}`
        }
      }
      return ''
    },
    kpPreferenceTitle(member) {
      if(!!member.kp_preference) {
        const item = this.kpPreference.find(item => item.value === member.kp_preference)
        if (this.isVIP || this.isCoordinator) {
          return `${item.title}`
        }
      }
      return ''
    }
  }
})
