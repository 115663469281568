<template>
  <v-dialog width="650" v-model="isModalOpen">
    <v-form ref="editClientOptionsForm" @submit.prevent="onUpdateClient(false)" style="width: 100%;">
      <v-card class="d-flex flex-column">
        <v-card-title>
          <span class="title">Edit client options</span>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout wrap>
            <v-layout wrap class="px-2">
              <v-flex xs5 sm4 class="mb-2 px-2 d-flex align-center">
                <v-avatar size="120" tile class="cursor-pointer">
                  <img :src="client.avatar_url || '/images/client-avatar.png'"/>
                </v-avatar>
              </v-flex>
              <v-flex xs7 sm8 class="upload-btn px-2">
                <v-btn large color="primary" @click="$refs.avatarUploader.click()">Upload new logo</v-btn>
                <p class="mt-4 mb-0">Square logo is best</p>
                <input ref="avatarUploader" type="file" accept="image/*" class="d-none" @change="onUploadAvatar()">
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  label="Association Abbr*"
                  outlined
                  dense
                  :disabled="!isSuperAdmin"
                  :rules="associationAbbrevRules"
                  v-model="client.assoc_abbrev"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  label="Official email"
                  outlined
                  dense
                  :disabled="!isSuperAdmin"
                  v-model="client.mailgun_email"
                  @input="error = ''"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  label="Official text number"
                  outlined
                  dense
                  :disabled="!isSuperAdmin"
                  v-model="client.twilio_number"
                  @input="error = ''"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card-text>
        <v-spacer style="flex: 1;"></v-spacer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Close</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <upload-avatar-modal
        ref="UploadAvatar"
    ></upload-avatar-modal>
  </v-dialog>
</template>

<script>
import ClientService from '@/services/ClientService'
import CloudinaryService from '@/services/CloudinaryService'
import StateService from '@/services/StateService'
import UploadAvatar from "@/components/my-kp/upload-avatar-modal";

export default {
  name: "EditClientOptionsModal",
  mixins: [ClientService, CloudinaryService, StateService],
  components: {
    'upload-avatar-modal': UploadAvatar
  },
  props: {
    client: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      committeeList: [],
      error: '',
      isBusy: false,
      isModalOpen: false
    }
  },
  computed: {
    associationAbbrevRules () {
      return [
        v => !!v || 'Association abbr is required.',
      ]
    },
    kpsList () {
      return [0, 1, 2, 3, 4, 5]
    },
  },
  methods: {
    onUploadAvatar () {
      const file = this.$refs.avatarUploader.files[0]
      if (!file) return
      this.uploadToCloudinary(file)
          .json((res) => {
            this.client.avatar_url = res.secure_url
            this.client = {...this.client}
            this.onUpdateClient(true)
          })
          .catch(() => {
            this.onCallToastr('error', 'Something went wrong!', 'Error!')
          })
    },
    openUploadImagePopup(client) {
      this.$refs.UploadAvatar.open(client)
    },
    onUpdateClient (isModalOpen) {
      if (!this.$refs.editClientOptionsForm.validate()) return
      this.isBusy = true
      this.updateClient(this.client)
          .then(() => {
            this.isBusy = false
            this.onCallToastr('success', 'Client info has been updated.', 'Success!')
            const userData = _.cloneDeep(this.userData)
            userData.client = this.client
            this.$store.dispatch('auth/setUserData', userData)
            this.isModalOpen = isModalOpen
          })
          .catch(() => {
            this.isBusy = false
            this.error = 'Something went wrong!'
          })
    }
  },
  watch: {
    async isModalOpen (value) {
      if (value) {
        this.error = ''
        this.committeeList = (await this.getStateCommittees(this.client.state_id)).data
        if (!this.client.house_committees) this.client.house_committees = []
        if (!this.client.senate_committees) this.client.senate_committees = []
        if (!this.client.joint_committees) this.client.joint_committees = []
      }
    }
  }
}
</script>