<template>
  <v-container fluid grid-list-lg class="pa-0">
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isLoading"
    ></v-progress-linear>
    <v-card class="mb-4">
      <v-card-text :class="{ 'pt-0': isSuperAdmin }">
        <v-layout wrap>
          <v-flex class="d-flex align-center">
            <h1>Political leanings</h1>
          </v-flex>
        </v-layout>

        <v-data-table
          class="elevation-1 custom-fields_table"
          hide-default-footer
          :headers="tableHeader"
          :items="readyList"
        >
          <template style="max-width: 70px" v-slot:item.action="{ item }">
            <div>
              <v-btn icon class="mx-0" @click="openPoliticalLeaningsModal(item)">
                <v-icon size="18" color="primary">edit</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.name="{ item }">
            <div>
              {{ item.name }}
            </div>
          </template>

          <template v-slot:item.color="{ item }">
            <v-btn :color="item.color" small>
            </v-btn>
          </template>

          <template v-slot:no-data>
            <p class="text-xs-center mb-0">
              No political leanings created yet.
            </p>
          </template>
        </v-data-table>
      </v-card-text>

      <political-leanings-modal
        ref="form"
        @update="load"
        :politicalLeaningsList="politicalLeaningsList"
      ></political-leanings-modal>
    </v-card>
  </v-container>
</template>

<script>
import ClientService from '@/services/ClientService'
import modal from "../../components/political-leanings/political-leanings-modal"
import MemberService from "../../services/MemberService";

export default {
  name: "PoliticalLeaningsPage",
  mixins: [ClientService, MemberService],
  data() {
    return {
      isLoading: false,
      politicalLeaningsList: [],
      tableHeader: [
        { text: '', value: 'action', align: 'center', sortable: false },
        { text: 'Leanings', value: 'name', align: 'left', sortable: false },
        { text: 'Color', value: 'color', align: 'left', sortable: false}
      ],
      selectedClientId: null
    }
  },
  computed: {
    readyList() {
      return this.politicalLeaningsList.map(item => {return {...item, action: ''}})
    },
  },
  methods: {
    load() {
      this.isLoading = true
      this.getPoliticalLeanings().then(response => {
        this.politicalLeaningsList = response.data
        this.isLoading = false
      })
    },
    openPoliticalLeaningsModal(item) {
      this.$refs.form.open(item)
    },
    onSelectClient() {
      if (this.isSuperAdmin) {
        this.$store.dispatch('app/setSuperAdminSelectedClient', this.selectedClient)
      }
      this.load()
    },
  },
  mounted() {
    this.load()
  },
  components: {
    'political-leanings-modal': modal
  }
}
</script>