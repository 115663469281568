<template>
  <v-dialog persistent max-width="500" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <span class="title">Silenced Advocates</span>
        <v-spacer />
        <v-icon class="ml-3" @click="close">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :loading="isLoading"
          class="elevation-1 pa-2 mt-4"
          :headers="tableHeader"
          :options.sync="list.pagination"
          :server-items-length="list.pagination.totalItems"
          :items="list.items"
          @update:options="getMembers()"
        >
          <template v-slot:item.nickname="{ item }">
            <div>
              {{ item.fullname }}
              <sup v-if="item.is_kp1 && item.activeKP1">KP1</sup>
              <v-tooltip bottom v-if="item.kp_preference !== 'unknown'">
                <template v-slot:activator="{ on }">
                  <v-avatar v-on="on" :color="kpPreferenceColor(item)" size="10"></v-avatar>
                </template>
                <span>{{ kpPreferenceTitle(item) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.kp_preference !== null">
                <template v-slot:activator="{ on }">
                  <v-avatar class="mx-1" v-on="on" :color="politicalLearningsColor(item)" size="10"></v-avatar>
                </template>
                <span>{{ politicalLearningsTitle(item) }}</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.silence_reason="{ item }">
            <v-tooltip bottom v-if="!!item.silence_reason" max-width="300">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on">help</v-icon>
              </template>
              <span>{{ item.silence_reason }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.silence="{ item }">
            <v-icon color="success"  @click="unsilenceMember(item)">check_circle</v-icon>
          </template>

          <template v-slot:item.unsilence_date="{ item }">
            <div>{{ formatDate(item.unsilence_date) }}</div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ClientService from '@/services/ClientService'
import MemberService from '@/services/MemberService'
import swal from 'sweetalert2'
import politicalLeaningsBorder from "@/mixins/politicalLeaningsBorder";

export default {
  name: "SilencedModal",
  mixins: [ClientService, MemberService, politicalLeaningsBorder],
  props: {
    clientId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      politicalLearnings: [],
      list: {
        pagination: {
          page: 1,
          itemsPerPage: 5,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
          sortBy: [],
          sortDesc: [],
        },
        items: [],
      },
    }
  },
  computed: {
    tableHeader() {
      return [
        { text: 'Advocate', value: 'nickname'},
        { text: 'Reason', value: 'silence_reason', align: 'center'},
        { text: 'Silenced', value: 'silence', align: 'center'},
        { text: 'Unsilence\'s Date', value: 'unsilence_date', align: 'center'},
      ]
    }
  },
  mounted() {
    this.getPoliticalLeanings()
        .then(response => {
          this.politicalLearnings = response.data
        })
  },
  methods: {
    open() {
      this.isModalOpen = true
      this.getMembers()

    },
    getMembers() {
      this.isLoading = true
      let param = {
        page: this.list.pagination.page,
        limit: this.list.pagination.itemsPerPage,
        order: this.list.pagination.sortBy[0],
        direction: this.list.pagination.sortDesc[0] || !this.list.pagination.sortDesc.length ? 'desc' : 'asc',
      }

      this.getSilencedMembers(this.clientId, param).then((response) => {
        this.list.items = response.data.data ? response.data.data : response.data
        this.list.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.list.pagination.current_page = response.data.current_page
        this.list.pagination.per_page = response.data.per_page
        this.list.pagination.last_page = response.data.last_page
        this.isLoading = false
      }, (err) => {
        this.isLoading = false
        this.onCallToastr('error', 'Something went wrong!', 'Error!')
        console.error(err)
      })
    },
    unsilenceMember(member) {
      swal({
        title: `Unsilence ${member.fullname}?`,
        type: 'question',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true
          member.silence = false
          member.silence_reason = null
          member.unsilence_date = null

          this.updateMember(member).then(() => {
            this.getMembers()
          })
        }
      })
    },
    close() {
      this.isModalOpen = false
    }
  }
}
</script>
