<template>
  <v-dialog max-width="500" v-model="dialog" @click:outside="close">
    <v-card class="message-modal-content">
      <v-card-title>
        <span class="title">
          {{ form.id > 0 ? 'Edit region' : 'Create region' }}
          <v-progress-circular
              indeterminate
              color="primary"
              size="20"
              width="2"
              v-if="loading"
          ></v-progress-circular>
        </span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="py-3">
        <v-text-field
            v-model="form.name"
            :error-messages="errorMessages('name')"
            label="Region name"
            :counter="25"
        >
        </v-text-field>
        <v-autocomplete
            label="Coordinator"
            clearable
            :items="members"
            v-model="form.member_id"
            :loading="isMembersLoading"
            item-text="nickNameAndLastName"
            item-value="id"
            :error-messages="errorMessages('member_id')"
            @change="dropEmailErrors()"
        >
        </v-autocomplete>
        <div v-if="form.member_id">
        <v-tooltip top v-if="!isUser">
          <template v-slot:activator="{ on }">
            <v-checkbox
              color="primary"
              hide-details
              class="mt-0 mb-6 admins-copies_checkbox user-invitation_Checkbox"
              v-model="form.is_send_invitation"
              v-on="on"
              @change="dropEmailErrors()"
            >
              <template v-slot:label>
                Send Team member account invitation. If unchecked, you will have to do this manually in the Team area.
              </template>
            </v-checkbox>
          </template>
          <span >This will create the Team member account and send the invitation email.</span>
        </v-tooltip>
        <div class="mb-6" v-if="!!isUser">
          <span>User already has active account</span>
        </div>
          <v-alert
            dense
            outlined
            type="error"
            v-if="errorMessages('email').length > 0"
          >
            {{ errorMessages('email') }}
          </v-alert>
        </div>
        <v-autocomplete
          v-model="selectedDistricts"
          :items="disabledDistricts"
          :item-text="item => item.region ? (item.district + ' - ' + item.region.name) : item.district"
          item-value="id"
          label="Districts"
          return-object
          multiple
          chips
          small-chips
          :disabled="disabled"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index == 0">
              <span>{{item.region ? (item.district + ' - ' + item.region.name) : item.district }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ selectedDistricts.length - 1 }} districts)</span>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="loading" :disabled="loading || isSaveNotAllowed">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ClientService from '@/services/ClientService'

  export default {
    name: "RegionsFormModal",
    mixins: [ClientService],
    props: ['members', 'selectedClientId'],
    data() {
      return {
        dialog: false,
        errors: {
          email: []
        },
        loading: false,
        isMembersLoading: false,
        districtList: [],
        disabledItems: [],
        selectedDistricts: [],
        form: {
          id: '',
          name: '',
          member_id: '',
          districts: [],
          is_send_invitation: false,
        }
      }
    },
    computed: {
      isUser() {
        let member = this.members.find(member => member.id == this.form.member_id)
        if (member) {
          return member.user
        }
      },
      isSaveNotAllowed () {
        return this.form.name.length > 25
      },
      disabledDistricts() {
        const list = this.districtList.map(item => {
          return {
            ...item,
            disabled: item.region_id > 0 && !this.checkAvailableDistrict(item),
            region: this.checkRegionDistrict(item) ? this.checkRegionDistrict(item) : item.region,
          }
        });

        return list;
      },
      disabled() {
        return !this.form.name
      },
      clientId() {
        return this.selectedClientId ? this.selectedClientId : this.userClientId
      }
    },
    methods: {
      open(id) {
        this.selectedDistricts = []
        this.disabledItems = []
        if (id > 0) {
          this.getClientRegion(id)
        } else {
          this.getDistricts()
        }
        this.errors = false
        this.dialog = true
      },
      checkAvailableDistrict(item) {
        let available
        for (let checked of this.disabledItems) {
          available = item.region_id === checked.pivot.region_id
        }
        return available
      },
      checkRegionDistrict(item) {
        let region
        for (let selected of this.selectedDistricts) {
          if (item.id === selected.id){
            region = { name: this.form.name }
          }
        }
        return region
      },
      async getDistricts() {
        // this.districtList = (await this.getClientDistricts(this.clientId)).data
        this.districtList = (await this.getClientStateDistricts(this.clientId)).data
      },
      async getRegionDistricts() {
        let regionId = this.form.id
        this.disabledItems = (await this.getClientRegionDistricts(this.clientId, regionId)).data.districts
        if(regionId) {
          for(let district of this.disabledItems) {
            this.selectedDistricts.push(district)
          }
        }
      },
      dropEmailErrors() {
        this.errors = { email: [] }
      },
      close() {
        this.form = {
          id: '',
          name: '',
          member_id: '',
          districts: ''
        }
        this.$emit('update')
        this.dialog = false
      },
      save() {
        this.loading = true
        let data = {}
        this.form.districts = this.selectedDistricts

        if(this.form.id) {
          data = {
            id: this.form.id,
            name: this.form.name,
            districts: this.selectedDistricts
          }
        } else {
          data = { name: this.form.name, districts: this.selectedDistricts}
        }

        this.saveClientRegions(this.clientId, this.form.member_id ? this.form : data).then(()=> {
          this.onCallToastr('success', 'Regions list has been updated.', 'Success!')
          this.close()
          this.loading = false
          this.errors = false
        }, err => {
          this.errors = err.response.data.errors
          this.loading = false
          if(this.errorMessages('email').length > 0) {
            this.onCallToastr('error', this.errorMessages('email'), 'Error!')
          }
        })
      },
      getClientRegion(id) {
        this.loading = true
        this.getClientRegionById(this.clientId, id).then(response => {
          this.form = response.data
          this.form.is_send_invitation = false
          this.loading = false
          this.getDistricts()
          this.getRegionDistricts()
        })
      },
      errorMessages(field) {
        return (this.errors && this.errors[field] && this.errors[field].length > 0) ? this.errors[field][0] : []
      },
    }
  }
</script>
