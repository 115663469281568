<template>
  <div class="register-page_form my-auto elevation-24" :class="isDarkTheme ? 'card-dark' : 'card-light'">
    <auth-logo></auth-logo>
      <v-alert color="error" :value="true" class="mb-3 mt-5" outlined type="error" v-if="error && !errors">
        {{ error }}
      </v-alert>
      <div class="d-flex align-center justify-center register-page_form__inner mt-8">
        <transition :name="position">
          <div
            v-if="isKeyPerson === 0"
            class="register-page_form__inner_major"
            :class="isDarkTheme ? 'card-dark' : 'card-light'"
          >
            <p class="d-flex justify-center title my-4">Select your login type</p>
            <v-layout row class="ma-auto">
              <v-flex class="register-page_person mr-0" col-sm-6 col-md-6 col-12>
              <v-btn @click="toKeyPerson">
                <div class="text-wrap" style="width: min-content;">I'm a Key Person</div>
              </v-btn>
            </v-flex>
            <v-flex class="register-page_person ml-0" col-sm-6 col-md-6 col-12>
              <v-btn @click="toAdmin">
              <div class="text-wrap" style="width: min-content;">I'm an Admin or Coordinator</div>
              </v-btn>
            </v-flex>
            </v-layout>
          </div>
        </transition>

        <transition :name="'left'">
          <div
            v-if="isKeyPerson === 1"
            class="register-page_form__inner_admin"
            :class="isDarkTheme ? 'card-dark' : 'card-light'"
          >
            <div class="d-flex align-center justify-space-between my-4">
              <v-btn
                fab
                x-small
                outlined
                color="primary"
                @click="backToMenu"
                :class="isMobile ? 'mr-1' : ''"
                class="back-btn"
              >
                <v-icon large>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <p class="card-title mb-0" :class="!isMobile ? 'mr-2' : ''">Admin/Coordinator Login</p>
            </div>
            <v-card-text class="pa-0">
              <v-form ref="loginForm" @submit.prevent="handleSubmit" v-if="!twoFactorCodeSent">
                <v-text-field
                  label="Email Address*"
                  prepend-inner-icon="email"
                  type="email"
                  outlined
                  v-model="user.email"
                  :rules="emailRules"
                  @input="error = ''"
                ></v-text-field>
                <v-text-field
                  :rules="passwordRules"
                  @input="error = ''"
                  outlined
                  label="Password*"
                  prepend-inner-icon="lock"
                  type="password"
                  v-model="user.password"
                >
                </v-text-field>
                <v-snackbar
                  v-model="showSnackbar"
                  :top="true"
                  :timeout="2000"
                >
                  {{ error ? error : message }}
                </v-snackbar>
                <div class="text-right mb-4">
                  <router-link :to="{name: 'forgot-password-page'}">Forgot Password?</router-link>
                </div>
                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-btn type="submit" block large color="primary" :loading="isBusy">Sign in</v-btn>
                </div>
              </v-form>
              <v-form @submit.prevent="handleTwoFactorAuthorize" ref="twoFaForm"
                      v-if="twoFactorCodeSent && !isDeactivateTwoFactor">
                <v-card flat color="rgba(0,0,0,0)">
                  <v-card-title class="pl-0  mb-0 justify-center">Two factor authorization</v-card-title>
                  <v-alert :value="true" class="mb-3" color="primary" outlined type="success" v-if="message">
                    {{ message }}
                  </v-alert>
                  <v-alert :value="true" class="mb-3" color="primary" outlined type="error" v-else-if="error">
                    {{ error }}
                  </v-alert>
                  <v-text-field
                    :rules="TwoFactorCode"
                    @input="error = ''"
                    background-color="rgba(255, 255, 255, 0.2)"
                    filled
                    color="white"
                    label="Verification code"
                    prepend-inner-icon="lock"
                    :error-messages="error?['Verification code is invalid']: []"
                    type="text"
                    v-model="user.two_factor_code"
                  ></v-text-field>
                  <div class="text-xs-left mb-5">
                    <a @click="sendDeactivateTwoFactorCode" class="lost-device justify-center d-flex"> Lost the device? Use an
                      email</a>
                  </div>
                  <br/>
                  <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn :loading="isBusy" block large color="primary" type="submit">
                      {{ 'Verify' }}
                    </v-btn>
                  </div>
                </v-card>
              </v-form>
            </v-card-text>
          </div>
        </transition>
        <transition :name="'right'">
          <div
            v-if="isKeyPerson === 2"
            class="register-page_form__inner_major"
            :class="isDarkTheme ? 'card-dark' : 'card-light'"
          >
            <div class="d-flex align-center justify-space-between my-4">
              <p class="card-title mb-0">
                Choose where to send auto-login link
              </p>
              <v-tooltip bottom max-width="200px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="primary"
                      v-on="on"
                    >
                      fa-circle-question
                    </v-icon>
                  </template>
                  <span>Contact your Admin if you are unsure of the email or cell phone # we have on file for you.
                    They can also send you a link directly.
                  </span>
                </v-tooltip>
              <v-btn
                fab
                x-small
                outlined
                right
                color="primary"
                @click="backToMenuFromKeyPerson"
                :class="isMobile ? 'ml-1' : ''"
                class="back-btn"
              >
                <v-icon large>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>
            <!-- removed to make room on mobile
            <v-card-subtitle>
              Contact your Admin if you are unsure of the email or cell phone # we have on file for you.
              They can also send you a link directly.
            </v-card-subtitle>-->
            <v-card-text class="pa-0">
              <v-form ref="loginForm" @submit.prevent="handleSubmit" v-if="!twoFactorCodeSent">
                <v-text-field
                  label="Send to my cell phone"
                  outlined
                  prepend-inner-icon="phone_iphone"
                  v-mask="'(###) ###-####'"
                  :rules="phoneNumberRules"
                  v-model="cellPhone"
                  clearable
                  @input="clearError"
                  :error-messages="errorMessages('phone')"
                  :disabled="disabledField(fastEmail)"
                  hint="Enter your cell phone number"
                ></v-text-field>
                <div class="text-center"
                     :class="errors == null || errors && !errors['phone'] ? 'my-3' : 'mb-4'">
                  <h3>or</h3>
                </div>
                <v-text-field
                  outlined
                  label="Send to my email"
                  prepend-inner-icon="email"
                  type="email"
                  v-model="fastEmail"
                  clearable
                  :rules="restoreEmailRules"
                  @input="clearError"
                  :error-messages="errorMessages('email')"
                  :disabled="disabledField(cellPhone)"
                  hint="Enter your email address"
                ></v-text-field>
                <v-btn
                  type="submit"
                  :dark="disabledField(cellPhone) || disabledField(fastEmail)"
                  :disabled="!disabledField(cellPhone) && !disabledField(fastEmail)"
                  class="mt-2"
                  block
                  large
                  color="primary"
                  :loading="isBusy"
                >
                  Send My Login Link
                </v-btn>
              </v-form>
            </v-card-text>
          </div>
        </transition>
        <!-- alert was here -->
      </div>
      <system-message-alert :list="systemMessages" class="mt-10"></system-message-alert>
  </div>
</template>

<script>
  import swal from 'sweetalert2'

  import AuthService from '@/services/AuthService'
  import UserService from "@/services/UserService"
  import ClientService from '@/services/ClientService'
  import SystemMessageService from '@/services/SystemMessageService'

  import defaultRoutes from '@/router/default-routes'

  import systemMessageAlert from '@/components/auth/system-message-alert'
  import logo from '../../components/common/auth-logo'

  export default {
    mixins: [AuthService, UserService, ClientService, SystemMessageService],
    components: {
      'system-message-alert': systemMessageAlert,
      'auth-logo': logo,
    },
    data() {
      return {
        user: {},
        error: '',
        errors: null,
        isBusy: false,
        message: null,
        position: null,
        isKeyPerson: 0,
        cellPhone: null,
        fastEmail: null,
        showSnackbar: false,
        show2FAfield: false,
        twoFactorCodeSent: false,
        isDeactivateTwoFactor: false,
        redirectTo: null,
        userLoginData: {},
        defaultRoutes: defaultRoutes,
        systemMessages: []
      }
    },
    computed: {
      emailRules() {
        return [
          v => !!v || 'E-mail is required.',
        ]
      },
      restoreEmailRules() {
        return [
          v => (!v || !!v) || 'E-mail is required.',
        ]
      },
      TwoFactorCode() {
        return [
          v => !!v || 'Verification code is required.',
        ]
      },
      passwordRules() {
        return [
          v => !!v || 'Password is required.',
        ]
      },
    },
    mounted() {
      if(this.$route.query.redirectTo) this.redirectTo = this.$route.query.redirectTo
      let script = document.createElement('script')
      script.setAttribute('src', 'https://r8pt9rywm60j.statuspage.io/embed/script.js')
      script.setAttribute('id', 'embed_script')
      document.head.appendChild(script)
      this.loadSystemMessages()
    },
    beforeDestroy() {
      let script = document.querySelector('#embed_script')
      script.remove()
    },
    methods: {
      loadSystemMessages() {
        this.getSystemMessages({active: 1}).then(response => {
          this.systemMessages = response.data.data
        })
      },
      backToMenu() {
        this.position = 'right'
        setTimeout(()=> {
          this.isKeyPerson = 0
        }, 0)

      },
      backToMenuFromKeyPerson() {
        this.position = 'left'
        setTimeout(()=> {
          this.isKeyPerson = 0
        }, 0)
      },
      toAdmin() {
        this.position = 'right'
        setTimeout(()=> {
          this.isKeyPerson = 1
        }, 0)
      },
      toKeyPerson() {
        this.position = 'left'
        setTimeout(()=> {
          this.isKeyPerson = 2
        }, 0)
      },
      clearError() {
        this.errors = null
        this.error = ''
      },
      disabledField(field) {
        return field && field.length && field !== null
      },
      onLogin() {
        if (!this.$refs.loginForm.validate()) return
        this.isBusy = true;
        this.login(this.user)
          .then((res) => {
            this.isBusy = false
            this.$store.dispatch('auth/setUserData', res.data)
            this.$store.dispatch('auth/refreshSession')
            if (res.data.user.two_factor_verified) {
              this.user.cell_phone = res.data.user.cell_phone;
              this.userLoginData = res.data.user
              this.send2FACode();
            } else {
              this.setDefaultRoute()
            }
          })
          .catch((err) => {
            if (err.response) {
              this.errors = err.response.data.errors
              this.handleError(err)
            } else {
              this.errors = err
            }
            this.isBusy = false
          })
      },
      async setDefaultRoute() {
        let userRole = this.$store.state.auth.userData ? this.$store.state.auth.userData.role : false
        let serviceType = this.$store.state.auth.userData ? this.$store.state.auth.userData.serviceType : false

        if (userRole == 'KP') {
          let route = serviceType == 'adv' ? '/assigned-tasks' : '/my-tasks'
          return this.$router.push(route)
        }

        if (userRole != 'Super Admin' && userRole != 'Leg Support') {
          if (!this.userClientId) {
            this.logout()
            return;
          }
            const result = (await this.getClientStatus(this.userClientId)).data
            this.$store.dispatch('app/setClientStatus', result)
            let isCompleted = true
            _.forEach(result, (value) => {
              isCompleted &= value
            })


          if (userRole == 'VIP' && this.redirectTo){
              this.$router.push({name: this.redirectTo})
              return;
          }
        }

        this.$router.push(this.defaultRoutes[userRole])

      },
      async getKPLoginLink() {
        if (!this.$refs.loginForm.validate()) return
        this.isBusy = true;
        let data = {
          phone: this.cellPhone
        }
        if (this.fastEmail && this.fastEmail.length && this.fastEmail !== null) {
          data = {
            email: this.fastEmail
          }
        }
        try {
          const response = await this.sendKPLoginLink(data);
          if (response.status === 200 && response.data.message !== undefined) {
            this.message = response.data.message
            this.showSnackbar = true
          }
          if (response.status !== 200 && response.data.message !== undefined) {
            this.error = response.data.message
            this.showSnackbar = true
          }
          swal({
            title: 'Success',
            type: 'success',
            html: `Please check your email/phone for your link`,
            confirmButtonColor: '#3085d6',
          }).then(() => {
            this.cellPhone = null
            this.fastEmail = ''
          })
          this.isBusy = false
        } catch (e) {
          this.isBusy = false;
          const responseData = e && e.response && e.response.data ? e.response.data:'';
          this.error = responseData && responseData.message || '';
          this.errors =  responseData && responseData.errors || '';
        }
      },
      handleSubmit() {
        if (this.isKeyPerson === 1) {
          this.onLogin();
        } else if (this.isKeyPerson === 2){
          this.getKPLoginLink();
        }
      },
      send2FACode() {
        this.isBusy = true;
        this.verifyPhone({phone: this.user.cell_phone})
          .then(() => {
            this.isBusy = false;
            this.twoFactorCodeSent = true;
            this.message = 'The verification code was sent to your phone number ' + this.user.cell_phone + ', please check and verify';
          })
          .catch(() => {
            this.error = 'Something went wrong, please try again later';
            this.isBusy = false
          })
      },
      sendDeactivateTwoFactorCode() {
        if (!this.user.email)
        {
          return false;
        }
        this.isBusy = true;
        this.sendDeactivateCode()
          .then(() => {
            this.isBusy = false;
            this.isDeactivateTwoFactor = true;
            this.message = 'The verification code was sent to your email ' + this.user.email + ', please check';
          })
          .catch(() => {
            this.error = 'Something went wrong, please try again later';
            this.isBusy = false
          })
      },
      verifyTwoFactorCode() {
        if (!this.$refs.twoFaForm.validate()) return
        this.isBusy = true;
        this.verifyUserTwoFactorCode({verification_code: this.user.two_factor_code})
          .then((res) => {
            this.isBusy = false;
            if (res.data.verified) {
              this.$store.dispatch('auth/setUserData', this.userLoginData)
              this.$store.dispatch('auth/refreshSession')
              this.setDefaultRoute()
            } else {
              this.error = "Verification code is invalid."
            }
          })
          .catch(() => {
            this.error = 'Something went wrong, please try again later';
            this.isBusy = false
          })
      },
      handleTwoFactorAuthorize() {
        if (this.twoFactorCodeSent) {
          this.verifyTwoFactorCode()
        }
      },
      handleDeactivateTwoFactor() {
        if (!this.user.deactivate_token)
        {
          this.error = true;
          this.message = 'Verification code is required';
          return
        }
        this.isBusy = true;
        this.error = false
        this.deactivateTwoFactorCode({deactivate_token: this.user.deactivate_token})
        .then((res) => {
          this.isBusy = false;
          if (res.data.success) {
            this.userLoginData.two_factor_verified = false
            this.$store.dispatch('auth/setUserData', this.userLoginData)
            this.$store.dispatch('auth/refreshSession')
            this.setDefaultRoute()
          } else {
            this.error = true;
            this.message = "Verification code is invalid.";
          }
        })
        .catch(() => {
          this.error = 'Something went wrong, please try again later';
          this.isBusy = false
        })
      },
      errorMessages(field) {
        return (this.errors && this.errors[field]) ? this.errors[field][0] : []
      },
    }
  }
</script>

<style lang="scss" scoped>
.card-light {
  background-color: #f4f5fa!important;
  .register-page_person button {
    box-shadow: 0 2px 6px -2px rgb(49, 45, 75) !important;
    color: rgba(94, 86, 105, 0.87);
  }
}
.card-dark {
  background-color: var(--v-primary-lighten1);
  .register-page_person button {
    box-shadow: 0 2px 6px -2px #f4f5fa !important;
  }
}
  .lost-device {
    font-size: 18px;
    font-weight: 400;
  }
  .v-card__title {
    word-break: break-word;
  }

  .card-title {
    font-size: 16px!important;
    font-weight: 500;
    line-height: 1.2;
    @media (min-width: 380px) {
      font-size: 20px!important;
      font-weight: 500;
    }
  }
  .back-btn {
    @media (max-width: 380px) {
      height: 24px;
      width: 24px;
    }
  }

  .left-leave-active {
    transition: 0.5s;
  }
  .left-enter-active {
    transition: 0.3s;
  }

  .left-enter {
    transform: translate(120%, 0);
  }

  .left-leave-to {
    transform: translate(120%, 0);
  }

  .right-leave-active {
    transition: 0.5s;
  }
  .right-enter-active {
    transition: 0.3s;
  }

  .right-enter {
    transform: translate(-120%, 0);
  }

  .right-leave-to {
    transform: translate(-120%, 0);
  }
</style>
