<template>
  <v-dialog persistent max-width="700" :fullscreen="isMobile" v-model="isModalOpen">
    <v-card>
      <v-card-title class="pb-0">
        <span class="title">KP Notes</span>
      <v-card-subtitle class="pl-6 mb-3">{{ member.fullname }}-{{ legislator.fullname }} relationship notes</v-card-subtitle>
        <v-spacer/>
        <v-icon class="ml-3" @click="close">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout row wrap class="card-content mt-3">
          <v-flex xs4>
            <v-avatar v-if="member.avatar_url" class="px-2 photo-container" tile size="220" :style="kpPreferenceBorder(member, '5px', '-left')">
              <img class="ma-0" :src="member.avatar_url" :alt="member.fullname"/>
            </v-avatar>
            <v-avatar v-else class="px-2 photo-container" size="220" :style="kpPreferenceBorder(member)">
              <img class="ma-0" :src="'./images/member-avatar_old.png'" :alt="member.fullname"/>
            </v-avatar>
          </v-flex>
          <v-flex xs12 sm8>
            <v-form ref="assignmentForm" class="flex-1">
              <v-flex xs12 class="px-2">
                <v-select
                  label="KP Relationship"
                  v-model="memberLegislator.relationships"
                  :items="kpRelationships"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                ></v-select>
              </v-flex>
              <v-flex xs12 class="px-2">
                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on }">
                    <v-textarea outlined label="Relationship Notes (Admin-only)"
                                v-model="memberLegislator.notes"></v-textarea>
                  </template>
                  <span>Notes about the relationship between this KP and the legislator.<br/>Examples: "next door neighbors" or "often see each other at same social events"</span>
                </v-tooltip>
              </v-flex>
              <v-flex xs12 class="px-2 mb-2 d-flex align-center">
                <span><em>Assigned {{ formatDate(memberLegislator.start_date, 'MM/DD/YYYY') }}</em></span>
              </v-flex>
              <v-flex xs12 v-if="memberLegislator && memberLegislator.created_by_user" class="px-2">
                Initially saved by {{ memberLegislator.created_by_user.nickname }} {{ memberLegislator.created_by_user.lastname }}
                on
                {{ formatDate(memberLegislator.created_at, 'MM/DD/YYYY') }}
              </v-flex>
              <v-flex xs12 v-if="memberLegislator && memberLegislator.updated_by_user" class="px-2">
                Updated by {{ memberLegislator.updated_by_user.nickname }} {{ memberLegislator.updated_by_user.lastname }} on
                {{ formatDate(memberLegislator.updated_at, 'MM/DD/YYYY') }}
              </v-flex>
            </v-form>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click="close">Cancel</v-btn>
        <v-btn color="primary" :loading="isLoading" @click="onSave()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LegislatorService from '@/services/LegislatorService'
import ClientService from '@/services/ClientService'
import {mapGetters} from "vuex";

export default {
  name: "KpNotesModal",
  mixins: [LegislatorService, ClientService],
  props: {
    legislator: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      memberLegislator: {},
      member: {},
      kpRelationships: [],
    }
  },
  computed: {
    ...mapGetters('app', [
      'superAdminSelectedClient'
    ]),
    selectedClient() {
      if (this.isSuperAdmin) {
        return this.superAdminSelectedClient
      }
      return this.client
    },
  },
  methods: {
    async toggle(member) {
      this.isModalOpen = true
      this.isLoading = true
      this.member = member
      let memberLegislator = member.member_legislator.filter(memberLegislator => memberLegislator.leg_id === this.legislator.id)
      this.memberLegislator = memberLegislator && memberLegislator.length ? _.clone(memberLegislator[0]) : {}
      await this.getKpRelationshipList()

      this.isLoading = false
    },
    getKpRelationshipList() {
      let client_id = this.isSuperAdmin ? this.selectedClient.id : this.userClientId
      this.getClientRelationships(client_id).then(response => {
        this.kpRelationships = response.data
      })
    },
    onSave() {
      this.isLoading = true
      if(this.memberLegislator.relationships && this.memberLegislator.relationships.length && this.memberLegislator.relationships[0].id) {
        this.memberLegislator.relationships = this.memberLegislator.relationships.map(relationship => relationship.id)
      }
      const payload = {
        relationships: this.memberLegislator.relationships,
        notes: this.memberLegislator.notes
      }

      this.updateMemberLegislatorNote(this.memberLegislator.id, payload).then(() => {
        this.onCallToastr('success', 'KP has been updated.', 'Success!')
        this.$emit('updateKps')
        this.isLoading = false
        this.$emit('updateLegislators')
        this.close()
      }, () => {
        this.onCallToastr('error', 'Something went wrong.', 'Error')
        this.isLoading = false
      })
    },
    close() {
      this.isModalOpen = false
    }
  }
}
</script>
