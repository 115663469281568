export default {
  get() {
    return JSON.parse(window.localStorage.getItem('sidebar-expand'))
  },
  set(sidebar) {
    window.localStorage.setItem('sidebar-expand', sidebar)
  },
  remove() {
    window.localStorage.removeItem('sidebar-expand')
  }
}