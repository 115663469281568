<template>
  <v-dialog max-width="650" v-model="isModalOpen">
    <v-form @submit.prevent="onSave()" :loading="loading">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span class="title">Notes</span>
          <v-icon class="ml-3" @click.native="close">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-textarea
            label="Pertinent session notes concerning this legislator"
            v-model="notes"
            :rows="isMobile? 5 : 3"
            outlined
            hide-details
          ></v-textarea>
          <div v-if="notes !== ''" class="d-flex justify-end mt-3">
            <v-btn small outlined color="secondary" @click="notes = ''">Cancel</v-btn>
            <v-btn small type="submit" color="primary" :loading="isBusy" class="ml-3">Save</v-btn>
          </div>
          <v-data-table
              :headers="notesTableHeader"
              :items="notesList"
              disable-sort
          >
            <template v-slot:item.created_at="{ item }">
              <div>{{ item.created_at ? getFormatDate(item.created_at) : '' }}</div>
            </template>
            <template v-slot:item.result="{ item }">
              <div v-if="item.result.length < 170">{{item.result}}</div>
              <div v-else>
                <span v-if="!readMoreActivated">{{item.result.slice(0, 170)}}</span>
                <a v-if="!readMoreActivated" @click="activateReadMore">
                  Read more...
                </a>
                <span v-if="readMoreActivated"> {{item.result}}</span>
                <a v-if="readMoreActivated" @click="deactivateReadMore">
                  Read less...
                </a>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import TaskService from '@/services/TaskService'
import _ from "lodash";

export default {
  mixins: [TaskService],
  props: {
    tasks: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  data () {
    return {
      isModalOpen: false,
      notes: '',
      aggridApi: null,
      isBusy: false,
      legislatorId: null,
      clientId: null,
      readMoreActivated: false,
      loading: false,
      notesList: []
    }
  },
  computed: {
    isNew () {
      return this.result && !this.result.result
    },
    notesTableHeader() {
      return [
        { text: 'Date', value: 'created_at', align: 'left' },
        { text: 'Note', value: 'result', align: 'left' },
      ]
    },
  },
  methods: {
    toggle (aggridApi) {
      this.legislatorId = aggridApi && aggridApi.data && aggridApi.data.id
      this.clientId = aggridApi && aggridApi.data && aggridApi.data.member_legislators && aggridApi.data.member_legislators[0].client_id
      this.notesList = this.getNotesList()
      this.isModalOpen = true
      this.aggridApi = aggridApi
    },
    onSave () {
      this.aggridApi.node.setDataValue(`task${this.aggridApi.colDef.taskId}`, this.notes)

      const payload = {
        legislator_id: this.aggridApi.data.id,
        kp_id: this.aggridApi.data.primary_kp.id,
        task_id: this.aggridApi.colDef.taskId,
        result: this.notes,
      }
      this.addTaskResult(payload)
          .then(() => {
            this.onCallToastr('success', 'Task Result has been updated.', 'Success!')
          })
          .catch(err => {
            console.log('err', err)
            this.onCallToastr('error', 'Something went wrong.', 'Error!')
          })
      this.isModalOpen = false
      this.notes = ''
      this.$emit('refresh')
    },
    getNotesList() {
      let tasksWithNotes = this.tasks.filter(task => task.name === 'Notes')
      let result = tasksWithNotes[0].task_results.filter(res => res.legislator_id === this.legislatorId)
      return _.orderBy(result, ['created_at'], ['desc', 'asc'])
    },
    getFormatDate(date) {
      return this.getFormattedDate(date, 'MM/DD/YYYY')
    },
    activateReadMore() {
      this.readMoreActivated = true;
    },
    deactivateReadMore() {
      this.readMoreActivated = false;
    },
    close() {
      this.isModalOpen = false
      this.notes = ''
    }
  }
}
</script>
