<template>
  <div class="modal">
    <div class="d-flex align-center">
      <search-component></search-component>
      <v-btn
        icon
        small
        @click="close"
        class="pr-2"
      >
        <v-icon class="ml-2">mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import SearchComponent from '../../components/global/search-component'

export default {
  data() {
    return {
      drawer: false,
    }
  },
  components: {
    'search-component': SearchComponent,
  },
  methods: {
    open() {
      const overlay = document.querySelector('.modal')
      overlay.classList.add('visible')
    },
    close() {
      const overlay = document.querySelector('.modal')
      overlay.classList.remove('visible')
    }
  },
}
</script>

<style lang="scss">
.modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 5px 5px;
  display: none;
  z-index: 100;
  overflow: hidden;
  height: 58px;
  width: 96%;
  background-color: #FFFFFF;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.visible {
  display: block;
}
</style>