import Vue from 'vue'
import { partyColorList, partyKmlColorList } from '@/entities/party-color-list'

Vue.mixin({
  data() {
    return {
      partyColorList: partyColorList(),
      partyKmlColorList: partyKmlColorList(),
    }
  },
  methods: {
    csrf () {
      return document.head.querySelector('meta[name="csrf-token"]').content
    },
    getAccessToken () {
      const token = localStorage.getItem('token')
      if (!token) return null
      return JSON.parse(token).accessToken
    },
    getUserId () {
      const user = localStorage.getItem('user')
      if (!user) return null
      return JSON.parse(user).id
    },
    getUserEmail () {
      const user = localStorage.getItem('user')
      if (!user) return null
      return JSON.parse(user).email
    },
    logout () {
      this.$store.dispatch('auth/logout', false)
      this.$router.push({name: 'login'})
    },
    validateEmail (email) {
      const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/
      return emailRegExp.test((email || '').trim().toLowerCase())
    },
    validateCell (cell) { 
       // Regular expression for a valid US phone number
      const phoneRegex = /^(?:\(?([2-9][0-9]{2})\)?[-.\s]?([2-9][0-9]{2})[-.\s]?([0-9]{4}))$/;
      return phoneRegex.test(cell);
    },
    getErrorText(field) {
      return (this.errors && this.errors[field]) ? this.errors[field] : []
    },
    colorsLegislatorsParties(legislator, legislators, borderSize = 1) {
      if(typeof legislator === 'number') {
        legislator = legislators.find(leg => leg.id === legislator)
      }

      return `border: ${borderSize}px solid ${this.partyColorList[legislator.party]}`
    },
  }
});
