export default [
  {
    title: 'Text Field',
    value: 'text'
  },
  {
    title: 'Multi-Select Checkboxes',
    value: 'checkbox'
  },
  {
    title: 'Single Checkbox',
    value: 'toggle'
  },
  {
    title: 'Single Select',
    value: 'dropdown'
  }
]
