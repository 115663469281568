import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,{staticClass:"legislator-timeline"},[(_vm.isLoading)?_c(VProgressLinear,{attrs:{"color":"warning","indeterminate":"","rounded":"","height":"3"}}):_vm._e(),_c(VCardTitle,{staticClass:"d-flex flex-nowrap"},[_c(VAvatar,{attrs:{"left":""}},[_c(VImg,{staticClass:"avatar_image",attrs:{"src":_vm.legislator.official_image ? _vm.getThumbImage(_vm.legislator.official_image) : '/images/member-avatar.png'}})],1),_c(VSpacer),_c('h3',[_vm._v(_vm._s(_vm.legislator.fullname)+" Connections")]),_c(VSpacer),_c(VIcon,{on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("close")])],1),_c(VDivider),_c(VFlex,{staticClass:"px-2",attrs:{"xs12":""}},[_c(VList,{staticClass:"pa-0",attrs:{"dense":""}},[_vm._l((_vm.connections),function(connection){return [_c(VListItem,{key:connection.id,on:{"click":function($event){return _vm.openDetail(connection)}}},[(connection.official_image)?_c(VListItemAvatar,{staticClass:"avatar_image",attrs:{"small":""}},[_c('img',{style:(_vm.kpPreferenceBorder(connection)),attrs:{"alt":connection.fullname,"src":_vm.getThumbImage(connection.official_image)}})]):_vm._e(),(!connection.official_image)?_c(VListItemAvatar,{staticClass:"justify-center",style:(_vm.kpPreferenceBorder(connection)),attrs:{"small":"","color":_vm.getColor(connection.fullname)}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(connection.nickname))])]):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(connection.fullname)+" ")])],1)],1)]})],2)],1)],1),_c('legislator-detail-modal',{ref:"legislatorDetailModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }