<template>
  <v-dialog max-width="400" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <span class="title">Who will receive this Message?</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div>Select the recipients which will decide in which part of the app this message will be available to load and also which variables will be available.
        </div>
        <v-layout row wrap>
          <v-radio-group
              row
              v-model="type"
              @change=""
          >
            <v-radio class="ma-2" value="dlu">
              <template v-slot:label>
                <div class="d-flex flex-wrap">
                  <span class="h6">District Constituents</span>
                  <span class="caption">Will be available when sending messages to district constituents</span>
                </div>
              </template>
            </v-radio>
            <v-radio class="ma-2" value="advocate">
              <template v-slot:label>
                <div class="d-flex flex-wrap">
                  <span class="h6">Advocates</span>
                  <span class="caption">Will be available when sending messages to your Advocates</span>
                </div>
              </template>
            </v-radio>
            <v-radio class="ma-2" value="coordinator">
              <template v-slot:label>
                <div class="d-flex flex-wrap">
                  <span class="h6">Coordinators</span>
                  <span class="caption">Will be available when sending messages to Coordinators</span>
                </div>
              </template>
            </v-radio>
            <v-radio class="ma-2" value="unique-kp">
              <template v-slot:label>
                <div class="d-flex flex-wrap">
                  <span class="h6">KPs - 1 message per unique KP</span>
                  <span class="caption">This option will NOT allow you to load Legislator variables!</span>
                </div>
              </template>
            </v-radio>
            <v-radio class="ma-2" value="kp">
              <template v-slot:label>
                <div class="d-flex flex-wrap">
                  <span class="h6">KPs - 1 message per KP-Legislator pair</span>
                  <span class="caption">This WILL allow you to load Legislator variables when sending messages to KPs</span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
        <v-btn color="primary" :loading="isBusy" :disabled="!type" @click="createMessage">Next</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ReceiveModal',
  data() {
    return {
      isModalOpen: false,
      type: null,
      isBusy: false
    }
  },
  computed: {},
  methods: {
    toggle() {
      this.isModalOpen = true
    },
    createMessage() {
      this.$emit('createMessage', this.type)
      this.isModalOpen = false
    }
  }
}
</script>
