import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VContainer,[_c(VCardText,{staticClass:"py-5"},[_c('div',{staticClass:"confirm-alert-icon"},[(_vm.question)?_c('div',{staticClass:"icon"},[_vm._v("?")]):_vm._e()]),(_vm.title && _vm.title !== null)?_c(VFlex,{staticClass:"headline text-center"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"dark":"","color":"blue"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.textCancelButton))]),_c(VBtn,{attrs:{"dark":"","color":"green"},on:{"click":_vm.onSuccess}},[_vm._v(_vm._s(_vm.textSuccessButton))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }