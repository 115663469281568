const state = {
  clientStatus: {},
  isMenuSidebarOpen: true,
  isProfileSidebarOpen: null,
  isClientSettingsSidebarOpen: null,
  unseenMessageCount: 0,
  setupErrorsCount: 0,

  //Tariq
  selectedModalDistrict : 'xc',
  districtModalEnabled : false,
  superAdminSelectedClient: null

}

const getters = {
  getSSS:(state)=>{
    return state.selectedModalDistrict
  },

  superAdminSelectedClient:(state)=>{
    return state.superAdminSelectedClient
  },
  sidebarState:(state)=>{
    return state.isMenuSidebarOpen
  }
}

const actions = {
  setClientStatus ({ commit }, payload) {
    commit('setClientStatus', payload)
  },
  setMenuSidebarOpen ({ commit }, payload) {
    commit('setMenuSidebarOpen', payload)
  },
  setProfileSidebarOpen ({ commit }, payload) {
    commit('setProfileSidebarOpen', payload)
  },
  setClientSettingsSidebarOpen ({ commit }, payload) {
    commit('setClientSettingsSidebarOpen', payload)
  },
  setUnseenMessageCount ({ commit }, payload) {
    commit('setUnseenMessageCount', payload)
  },
  setSetupErrorsCount ({ commit }, payload) {
    commit('setSetupErrorsCount', payload)
  },

  setSelectedModalDistrict ({ commit }, payload) {
    commit('setSelectedModalDistrict', payload)
  },
  setDistrictModalEnabled ({ commit }, payload) {
    commit('setDistrictModalEnabled', payload)
  },
  setSuperAdminSelectedClient ({ commit }, payload) {
    commit('setSuperAdminSelectedClient', payload)
  },
}

const mutations = {
  setClientStatus (state, payload) {
    state.clientStatus = payload
  },
  setMenuSidebarOpen (state, payload) {
    state.isMenuSidebarOpen = payload
  },
  setProfileSidebarOpen (state, payload) {
    state.isProfileSidebarOpen = payload
  },
  setClientSettingsSidebarOpen (state, payload) {
    state.isClientSettingsSidebarOpen = payload
  },
  setUnseenMessageCount (state, payload) {
    state.unseenMessageCount = payload
  },
  setSetupErrorsCount (state, payload) {
    state.setupErrorsCount = payload
  },
  setSelectedModalDistrict (state, payload) {
    state.selectedModalDistrict = payload.district
  },
  setDistrictModalEnabled (state, payload) {
    state.districtModalEnabled = payload.status
  },
  setSuperAdminSelectedClient (state, payload) {
    state.superAdminSelectedClient = payload
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
