export default {
  get() {
    return JSON.parse(window.localStorage.getItem('dark'))
  },
  set(theme) {
    window.localStorage.setItem('dark', theme)
  },
  remove() {
    window.localStorage.removeItem('dark')
  }
}