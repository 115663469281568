<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container v-if="client && client.id" fluid grid-list-lg class="pa-0">
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isLoading"
    ></v-progress-linear>
    <v-card>
      <v-card-text>
        <v-layout v-if="isDesktop || isDevice" wrap>
          <v-flex class="d-flex align-center mb-4">
            <h1>{{ $route.name === 'history-messages-page' ? 'Message History' : 'Scheduled Messages' }}</h1>
            <v-icon class="ml-2 cursor-pointer" color="primary" @click="getScheduleMessages()" >refresh</v-icon>
          </v-flex>
          <v-spacer></v-spacer>
          <div
            v-if="$route.name === 'history-messages-page'"
            class="legislators_filter__additional--filter"
            :class="{ 'w-100': isMobile }"
          >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              left
              offset-y
              :close-on-click="false"
              max-width="300"
            >
              <template v-slot:activator="{ on }">
                <v-btn small rounded :outlined="isDesktop || isDevice" color="primary" v-on="on">
                  <span>Filters</span>
                  <v-icon>filter_list</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text class="members_filter-overflow">
                  <v-layout wrap>
                    <v-flex xs6 sm6 class="mt-2">
                      <h4>Messages to:</h4>
                          <v-radio-group
                            v-model="filter.messages_to"
                          >
                            <v-radio color="primary" label="All" :value="null"></v-radio>
                            <v-radio color="primary" label="Advocates" :value="1"></v-radio>
                            <v-radio color="primary" label="KPs" :value="0"></v-radio>
                          </v-radio-group>
                    </v-flex>
                    <v-flex xs6 sm6 class="legislators_filter__additional--item_wrap mt-2">
                      <h4>Sent as:</h4>
                      <v-radio-group
                        v-model="filter.send_as"
                      >
                        <v-radio color="primary" label="All" :value="null"></v-radio>
                        <v-radio color="primary" label="Text/Email" :value="1"></v-radio>
                        <v-radio color="primary" label="Email Only" :value="0"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="error" text @click="clearFilter">Clear</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="secondary" @click="menu = false">Cancel</v-btn>
                  <v-btn color="primary" text @click="doFilter">Apply Filter</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
        </v-layout>
        <v-layout class="mx-0" v-if="isMobile" wrap>
          <v-flex class="d-flex flex-column align-center">
            <h1>Scheduled Messages</h1>
          </v-flex>
        </v-layout>
        <v-data-table
          v-if="schedule_messages.items && schedule_messages.items.length > 0"
          :loading="isLoading"
          :options.sync="schedule_messages.pagination"
          :server-items-length="schedule_messages.pagination.totalItems"
          :headers="messagesHeader"
          :items="schedule_messages.items"
          @update:options="getScheduleMessages"
          :disable-sort="isMobile"
        >

          <template v-slot:item.sender_name="{ item }">
            <div>
              {{ getSenderName(item) }}
            </div>
            <div class="d-flex justify-center">
              <h6 v-if="item.is_kps_send">KP</h6>
              <h6 v-if="item.is_coordinator_send">Coordinator</h6>
            </div>
          </template>

          <template v-slot:item.message="{ item }">
            <div
                @click="$refs.messageDetailModal.open(item.message)"
                class="cursor-pointer d-flex align-center"
            >
              <div>
                <v-chip v-if="item.is_sms" x-small dark color="indigo lighten-1">SMS</v-chip>
                <v-chip v-else x-small color="primary">Email</v-chip>
                <v-chip v-if="item.to_regional_coordinators" class="ml-2" x-small color="primary">RC</v-chip>
                <v-chip v-if="item.from_members_page" class="ml-2" x-small dark color="deep-purple darken-1">Advocates</v-chip>
                <v-chip v-if="item.from_dashboard_page" class="ml-2" x-small dark color="deep-purple darken-1">KP</v-chip>
              </div>
              <v-btn
                color="primary"
                small
                class="ml-2"
                :loading="item.loading"
              >
                <v-icon size="20" left dark class="ml-2" v-if="$vuetify.breakpoint.smAndUp">fa-eye</v-icon>
                View message
              </v-btn>
            </div>
          </template>

          <template v-slot:item.sent_messages="{ item }">
            <div  class="d-flex align-center justify-center schedule-circle">
              <v-progress-circular
                class="cursor-pointer"
                :rotate="-90"
                :size="65"
                :width="5"
                :value="item.sent_messages_count * 100 / item.members_count"
                :color="getProgressColor(item.sent_messages_count / item.members_count)"
                @click="$refs.statsModal.toggle(item)"
              >
                {{ item.sent_messages_count }} / {{ item.members_count }}
              </v-progress-circular>
            </div>
          </template>

          <template v-slot:item.opened_emails="{ item }">
            <div v-if="item.is_sms">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="error" size="42">fa-thin fa-ban</v-icon>
                </template>
                <span>Unfortunately, it is not possible to track SMS opens</span>
              </v-tooltip>
            </div>
            <div v-else class="d-flex align-center justify-center schedule-circle">
              <v-progress-circular
                class="cursor-pointer"
                :rotate="-90"
                :size="65"
                :width="5"
                :value="item.seen_messages_count * 100 / item.sent_messages_count"
                :color="getProgressColor(item.seen_messages_count / item.sent_messages_count)"
                @click="$refs.statsModal.toggle(item)"
              >
                {{ item.seen_messages_count }} / {{ item.sent_messages_count }}
              </v-progress-circular>
            </div>
          </template>

          <template v-slot:item.action="{ item }">
            <div class="d-flex justify-end">
              <v-btn
                v-if="!item.to_regional_coordinators"
                color="primary"
                small
                class="ml-2"
                @click="openMessageModal(item)"
                :disabled="!selectedContactList.contactCount || route === '/messages/history'"
                :loading="item.loading"
              >
                <v-icon size="20" left dark class="ml-2">fa-edit</v-icon>
                Edit/Send
              </v-btn>
              <v-btn
                v-if="!item.to_regional_coordinators"
                color="success"
                small
                class="ml-2"
                @click="onSendNowHandler(item)"
                :disabled="!selectedContactList.contactCount || route === '/messages/history'"
                :loading="item.loading"
              >
                <v-icon size="20" left dark class="ml-2">fa-send</v-icon>
                Send now
              </v-btn>
              <v-btn
                v-if="item.to_regional_coordinators"
                color="primary"
                small
                class="ml-2"
                @click="$refs.coordinatorMessageModal.toggleOnScheduleMessagePage(client.id, item)"
                :disabled="!selectedContactList.contactCount || route === '/messages/history'"
                :loading="item.loading"
              >
                <v-icon size="20" left dark class="ml-2">fa-edit</v-icon>
                Edit/Send
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    color="error"
                    @click="onDelete(item)"
                    v-on="on"
                    small
                    class="mx-3"
                    :loading="item.loading"
                  >
                    <v-icon size="20" left dark class="ml-2">fa-trash-alt</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.delivery_at="{ item }">
            <div>
              {{ item.is_approval ? '' : getFormattedDate(item.delivery_at, 'MM/DD/YY h:mm a') }}
            </div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div>
              {{ getFormattedDate(item.created_at, 'MM/DD/YY h:mm a') }}
            </div>
          </template>

          <template v-slot:no-data>
            <p class="text-xs-center mb-0">No messages yet.</p>
          </template>
        </v-data-table>
        <v-alert type="warning" outlined :value="true" v-show="!schedule_messages.items.length && isLoading">
          Loading messages...
        </v-alert>
        <v-alert type="info" outlined :value="true" v-show="!schedule_messages.items.length && !isLoading &&  route === '/messages/history'">
          There are no messages to display.
        </v-alert>
        <v-alert type="info" outlined :value="true" v-show="!schedule_messages.items.length && !isLoading &&  route !== '/messages/history'">
          There are currently no Scheduled messages.
        </v-alert>
      </v-card-text>
    </v-card>

    <send-message-modal ref="sendMessageModal" :unique="isShowingUnique" :client="client" @update="getScheduleMessages"></send-message-modal>
    <coordinator-message-modal
      :client="client"
      :legislators="legislatorsList"
      :coordinators="selectedCoordinatorsList"
      @update="getScheduleMessages"
      ref="coordinatorMessageModal" />
    <message-detail-modal ref="messageDetailModal" ></message-detail-modal>
    <stats-modal ref="statsModal" ></stats-modal>
  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import ClientService from '@/services/ClientService'
import messageDetailModal from '@/components/messages/message-detail-modal'
import statsModal from '@/components/messages/stats-modal'
import SendMessageModal from '@/components/kp-tasks/send-message-modal'
import CoordinatorMessageModal from '@/components/kp-tasks/coordinator-message-modal'
import {mapGetters} from "vuex";

export default {
  name: 'ScheduledMessages',
  mixins: [ClientService],
  components: {
    'message-detail-modal': messageDetailModal,
    SendMessageModal,
    statsModal,
    CoordinatorMessageModal
  },
  props: {
    route: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      taskList: [],
      legislatorsList: [],
      isShowingUnique:false,
      filter: {
        messages_to: null,
        send_as: null,
      },
      isLoading: false,
      error: false,
      search: '',
      menu: false,
      schedule_messages: {
        pagination: {
          page: 1,
          itemsPerPage: 15,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
          sortBy: [],
          sortDesc: []
        },
        items: []
      },
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    messagesHeader () {
      if(this.route === '/messages/history') {
        return [
          { text: 'Creator', width: '125px', value: 'sender_name', align: 'left' },
          { text: 'Message', width: '30%', value: 'message', align: 'left' },
          { text: 'Date Sent', value: 'delivery_at', align: 'center' },
          { text: 'Created', value: 'created_at', align: 'center' },
          { text: 'Delivered', value: 'sent_messages', align: 'center' },
          { text: 'Opened', value: 'opened_emails', align: 'center' },
          //adam commented out this column for History as it is not needed
          //{ text: '', value: 'action', align: 'right', sortable: false },
        ]
      } else if (this.route === '/messages/scheduled') {
        return [
          { text: 'Creator', width: '15%',value: 'sender_name', align: 'left' },
          { text: 'Message', width: '40%', value: 'message', align: 'left' },
          { text: 'Scheduled at', value: 'delivery_at', align: 'center' },
          { text: 'Created', value: 'created_at', align: 'center' },
          { text: '', value: 'action', align: 'right', sortable: false },
        ]
      }
    },
    selectedCoordinatorsList() {
      if (!this.legislatorsList || this.legislatorsList.length < 1) return []

      let array = this.legislatorsList.filter(legislator =>
        legislator.district &&
        legislator.district.region &&
        legislator.district.region.member
      ).map(legislator => legislator.district.region.member)

      return array.filter((coordinator, index, self) =>
        index === self.findIndex((item) => (
          item.id === coordinator.id && item.name === coordinator.name && item.id
        ))
      )
    },
    selectedEmailList () {
      return [this.userData.email]
    },
    selectedContactList () {
      const selectedContactList = {
        contactCount: 0,
        emailList: [],
        cellList: [],
      }

      selectedContactList.contactCount += 1
      selectedContactList.cellList.push(this.userData.cell_phone)
      selectedContactList.emailList.push(this.userData.email)

      return selectedContactList
    },
  },
  mounted () {
   this.init()
  },
  methods: {
    async init() {
      if (!(this.client && this.client.id)) return
      await this.getScheduleMessages()
      await this.getLegislatorList()
    },
    getSenderName(scheduleMessage) {
      if(!scheduleMessage.user) {
        return '-'
      }

      return scheduleMessage.member_like_sender ?
        `${scheduleMessage.member_like_sender.nickname} ${scheduleMessage.member_like_sender.lastname}` :
        `${scheduleMessage.user.nickname} ${scheduleMessage.user.lastname}`
    },
    openMessageModal(scheduleMessage) {
      this.isShowingUnique = !scheduleMessage.show_legislators,
      this.$refs.sendMessageModal.toggleOnScheduleMessagePage(scheduleMessage)
    },
    onSendNowHandler(scheduleMessage) {
      const isEmail = scheduleMessage && !scheduleMessage.is_sms;
      swal({
        title: 'Are you sure you want to send now, this scheduled message?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Send Now',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {

          scheduleMessage.loading = true;
          let instanceScheduleMessage = scheduleMessage;
          delete instanceScheduleMessage.delivery_at;

          if (isEmail) {
            
            this.onSendEmail(instanceScheduleMessage).then(() => {
              this.onCallToastr('success', 'Email has been sent.', 'Success!')
              scheduleMessage.loading = false;
              this.doFilter();
            }, (err) => {
              scheduleMessage.loading = false;
              console.error(err);
              this.onCallToastr('error', err && err.message, 'Failed!');
            });

          } else {
            instanceScheduleMessage.is_sms = 1;
            delete instanceScheduleMessage.subject;

            this.onSendText(instanceScheduleMessage).then(() => {
              this.onCallToastr('success', 'Text has been sent.', 'Success!');
              scheduleMessage.loading = false;
              this.doFilter();
            }, (err) => {
              scheduleMessage.loading = false;
              console.error(err);
              this.onCallToastr('error', err && err.message, 'Failed!');
            })

          }
        }
      })
    },
    async onSendEmail(email) {
      if (email && email.id) {
        await this.saveClientScheduleMessage(email)
      }
    },
    async onSendText(text) {
      await this.saveClientScheduleMessage(text)
    },
    async getLegislatorList() {
      let result = (await this.getClientKPTasks(this.client.id)).data
      this.result = result
      this.legislatorsList = result.legislators
    },
    getScheduleMessages() {
      if (!(this.client && this.client.id)) return
      this.isLoading = true

      let params = {
        client_id: this.client.id,
        page: this.schedule_messages.pagination.page,
        limit: this.schedule_messages.pagination.itemsPerPage,
        is_sent: this.route === '/messages/history' ? 1 : 0,
        is_approval: this.route === '/messages/history' ? 0 : 1,
        order: this.schedule_messages.pagination.sortBy[0],
        messages_to: this.filter.messages_to,
        send_as: this.filter.send_as,
        direction: this.schedule_messages.pagination.sortDesc[0] ? 'asc' : 'desc'
      }

      this.getClientScheduleMessages(params).then(response => {
        this.schedule_messages.items = response.data.data ? response.data.data.map(message => {
          message.loading = false
          return message
        }) : response.data
        this.schedule_messages.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.schedule_messages.pagination.current_page = response.data.current_page
        this.schedule_messages.pagination.per_page = response.data.per_page
        this.schedule_messages.pagination.last_page = response.data.last_page

        if(this.route === '/messages/scheduled') this.$emit('getScheduledMessagesCount', response.data.total)
        this.isLoading = false
      },() => {
        this.isLoading = false
        this.error = true
      })
    },
    customFilter(value, search, item) {
      search = search.toString().toLowerCase()
      return item.nickname.toLowerCase().includes(search) || item.lastname.toLowerCase().includes(search)
    },
    formatMessage(message) {
      if (message.length >= 200) {
        message = message.substr(0, 30)
        message += '...'
        return message
      }

       return message
    },
    clearFilter() {
      this.filter = {
        send_as: null,
        messages_to: null,
      }
      this.doFilter()
    },
    doFilter() {
      this.schedule_messages.pagination.current_page = 1
      this.menu = false
      this.getScheduleMessages()
    },
    onDelete (scheduleMessage) {
      swal({
        title: 'Are you sure you want to delete this scheduled message?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          scheduleMessage.loading = true
          const payload = { client_id: this.client.id }
          this.deleteClientScheduleMessage(scheduleMessage.id, payload).then(() => {
            scheduleMessage.loading = false
            this.onCallToastr('success', 'Scheduled message has been removed.', 'Success!')
            this.getScheduleMessages()
          }).catch((err) => {
            scheduleMessage.loading = false
            this.handleError(err)
          })
        }
      })
    }
  },
  watch: {
    'route'() {
      this.schedule_messages.items = []
      this.filter = {
        messages_to: null,
        send_as: null,
      }
      this.getScheduleMessages()
    },
    'client': function () {
      this.init()
    }
  }
}
</script>
