import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"700"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VForm,{ref:"textForm",on:{"submit":function($event){$event.preventDefault();return _vm.onSend()}}},[(_vm.kp)?_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Send "+_vm._s(_vm.isEmail ? 'an Email' : 'a Text')+" to "+_vm._s(_vm.kp.nickname)+" "+_vm._s(_vm.kp.lastname))])]),_c(VDivider),_c(VCardText,[(_vm.error)?_c(VAlert,{attrs:{"type":"error","outlined":"","value":true}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{staticClass:"px-2 tiny_editor",attrs:{"xs12":"","id":"editorContainer"}},[_c('div',{class:_vm.disabledEditor ? 'disabledEditor' : ''}),_c(VTextarea,{ref:"textarea",staticClass:"mt-4",attrs:{"label":"Message*","outlined":"","counter":!_vm.isEmail ? 320 : false,"rules":_vm.messageRules},on:{"input":_vm.onChangeText},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},nativeOn:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")]),_c(VBtn,{attrs:{"type":"submit","color":"primary","loading":_vm.isBusy,"disabled":_vm.isEmptyMessage < 1 || _vm.message.length > 320 && !_vm.isEmail}},[_vm._v(" "+_vm._s(_vm.isApproval ? 'Queue for Approval' : 'Send')+" ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }