<template>
  <v-container fluid grid-list-lg>
    <v-progress-linear
        style="z-index: 6"
        color="warning"
        indeterminate
        rounded
        height="3"
        fixed
        top
        v-if="isLoading"
    ></v-progress-linear>
        <v-layout wrap>
          <v-flex class="d-flex align-center">
            <h1>Districts</h1>
            <v-select
                label="State"
                class="client-selector ml-2"
                item-text="state"
                item-value="id"
                return-object
                :items="statesList"
                v-model="selectedState"
                :loading="statesLoading"
                @input="onSelectState"
                :disabled="statesLoading"
            >
            </v-select>
            <v-checkbox
              v-if="selectedState.id"
              color="primary"
              v-model="selectedState.few_districts"
              label="Extended districts"
              :disabled="statesLoading"
            ></v-checkbox>
            <v-spacer></v-spacer>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="d-flex align-center justify-end flex-wrap">
            <v-text-field
                v-model="filter.search"
                class="search-input search-input_members mt-0 pt-0 mr-3"
                label="Search"
                hide-details
                :disabled="!selectedState.id || statesLoading || isLoading"
                clearable
            ></v-text-field>
            <v-btn color="warning" small @click="openDistrictModal(0)" :disabled="!selectedState.id">
              New district
              <v-icon dark class="ml-2">playlist_add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>

        <v-data-table
          class="elevation-1"
          :headers="tableHeader"
          :options.sync="list.pagination"
          :server-items-length="list.pagination.totalItems"
          :items="list.items"
          :footer-props="tableFooter"
          @update:options="load"
        >
          <template v-slot:item.action="{ item }">
            <div class="d-flex align-center">
              <v-btn icon class="mx-0" @click="openDistrictModal(item.id)">
                <v-icon size="18" color="primary">edit</v-icon>
              </v-btn>
              <v-btn icon class="mx-0" @click="onDeleteDistrict(item.id)">
                <v-icon size="18" color="error">delete_forever</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.district="{ item }">
            <div>
              {{ item.district }}
            </div>
          </template>

          <template v-slot:item.name="{ item }">
            <div>
              {{ item.name }}
            </div>
          </template>

          <template v-slot:item.ocd_id="{ item }">
            <div>
              {{ item.ocd_id }}
            </div>
          </template>

          <template v-slot:item.ls_id="{ item }">
            <div>
              {{ item.ls_id }}
            </div>
          </template>

          <template v-slot:item.kml="{ item }">
            <div>
              <v-icon color="success" v-if="item.kml">check_circle</v-icon>
              <v-icon color="error" v-else>cancel</v-icon>
            </div>
          </template>

          <template v-slot:item.is_active="{ item }">
            <div>
              <v-icon color="success" v-if="item.is_active">check_circle</v-icon>
              <v-icon color="error" v-else>cancel</v-icon>
            </div>
          </template>

          <template v-slot:no-data>
            <p class="text-xs-center mb-0">
              No regions created yet.
            </p>
          </template>
        </v-data-table>

      <districts-form-modal ref="form" @update="load" :state_id="selectedState.id"></districts-form-modal>
  </v-container>
</template>

<script>
import swal from "sweetalert2"
import modal from '@/components/districts/districts-form-modal'
import DistrictService from "@/services/DistrictService"
import StateService from "@/services/StateService"

export default {
  name: "DistrictsPage",
  mixins: [DistrictService, StateService],
  data() {
    return {
      isLoading: false,
      statesLoading: false,
      timer: null,
      filter: {
        search: '',
      },
      list: {
        items: [],
        pagination: {
          page: 1,
          itemsPerPage: 15,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
          sortBy: [],
          sortDesc: []
        },
      },
      tableHeader: [
        { text: '', value: 'action', align: 'center', sortable: false },
        { text: 'District', value: 'district', align: 'left', sortable: true },
        { text: 'Name', value: 'name', align: 'left', sortable: true },
        { text: 'Ocd id', value: 'ocd_id', align: 'left', sortable: true, 'width': '30%' },
        { text: 'LS id', value: 'ls_id', align: 'left', sortable: true, 'width': '10%' },
        { text: 'KML', value: 'kml', align: 'left', sortable: true, 'width': '10%' },
        { text: 'Active?', value: 'is_active', align: 'left', sortable: false, 'width': '10%' },
      ],
      tableFooter: {
        'items-per-page-options': [15, 25, 50, 100]
      },
      statesList: [],
      selectedState: {
        few_districts: 0
      }
    }
  },
  components: {
    'districts-form-modal': modal
  },
  methods: {
    load() {
      if (!this.selectedState.id) return

      this.isLoading = true

      let params = {
        state_id: this.selectedState.id,
        page: this.list.pagination.page,
        limit: this.list.pagination.itemsPerPage,
        search: this.filter.search ? this.filter.search : null,
        order: this.list.pagination.sortBy[0],
        direction: this.list.pagination.sortDesc[0] || !this.list.pagination.sortDesc.length ? 'desc' : 'asc',
      }

      this.getStateDistricts(params).then(response => {
        this.list.items = response.data.data ? response.data.data : response.data
        this.list.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.list.pagination.current_page = response.data.current_page
        this.list.pagination.per_page = response.data.per_page
        this.list.pagination.last_page = response.data.last_page
        this.isLoading = false
      }, () => {
        this.isLoading = false
      })
    },
    openDistrictModal(id) {
      this.$refs.form.open(id)
    },
    deleteDistrict(id) {
      return this.deleteStateDistrict(id)
    },
    onDeleteDistrict (id) {
      swal({
        title: 'Delete this District?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.loading = true
          this.deleteDistrict(id).then(() => {
            this.loading = false
            this.onCallToastr('success', 'District has been deleted.', 'Success!')
            this.load()
          })
          .catch(() => {
            this.loading = false
            this.onCallToastr('error', 'Something went wrong.', 'Error!')
          })
        }
      })
    },
    getStatesList() {
      this.statesLoading = true

      return this.getStates().then(response => {
        this.statesList = response.data
        this.statesLoading = false
      }, () => {
        this.statesLoading = false
      })
    },
    onStateChange() {
      this.statesLoading = true

      let data = {
        ...this.selectedState,
        few_districts: this.selectedState.few_districts ? 1 : 0
      }
      return this.updateState(this.selectedState.id, data).then(() => {
        this.getStatesList()
        this.statesLoading = false
      }, () => {
        this.statesLoading = false
      })
    },
    init() {
      this.getStatesList()
    },
    onSelectState() {
      this.load()
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    'selectedState.few_districts'() {
      this.onStateChange()
    },
    'filter.search'() {
      clearTimeout(this.timer)
      this.timer = setTimeout(()=> {
        this.list.pagination.page = 1
        this.load()
      }, 500)
    }
  }
}
</script>
