import axios from 'axios'

export default {
  methods: {
    getInterests(clientId, params = '') {
      return axios.get(`client/${clientId}/get-interests`, {params})
    },
    getFavoriteTeam(clientId, params = '') {
      return axios.get(`client/${clientId}/get-favorite-teams`, {params})
    },
  }
}
