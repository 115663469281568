<template>
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isLoading"
    ></v-progress-linear>
    <v-card class="mb-4" v-if="client && client.id">
      <v-card-text :class="{ 'pt-0': isSuperAdmin }">
        <v-layout wrap>
          <v-flex class="d-flex align-center">
            <h1>Advocate Profile Fields</h1>
            <member-fields-page-help-modal></member-fields-page-help-modal>
            <v-spacer></v-spacer>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="d-flex align-center justify-end flex-wrap">
            <v-btn color="warning" small @click="openFieldModal(0)" :disabled="!(client && client.id)">
              New field
              <v-icon dark class="ml-2">playlist_add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>

        <v-data-table
            class="elevation-1 custom-fields_table"
            :options.sync="pagination"
            hide-default-footer
            :headers="tableHeader"
            :items="readyList"
        >
          <template style="max-width: 70px" v-slot:item.action="{ item }">
            <div class="d-flex">
              <v-btn icon class="mx-0" @click="openFieldModal(item.id)">
                <v-icon size="18" color="primary">edit</v-icon>
              </v-btn>
              <v-btn icon class="mx-0" @click="onDeleteField(item.id)">
                <v-icon size="18" color="error">delete_forever</v-icon>
              </v-btn>
              <v-btn v-if="isSuperAdmin" icon class="mx-0" @click="copy(item.id)">
                <v-icon size="18" color="warning">fa-solid fa-file-import</v-icon>
              </v-btn>
            </div>
          </template>

        <template v-slot:item.name="{ item }">
          <div>
            {{ item.name }}
          </div>
        </template>

        <template v-slot:item.type="{ item }">
          <div v-if="item.type == 'text'">Text field</div>
          <div v-if="item.type == 'checkbox'">Checkboxes</div>
          <div v-if="item.type == 'toggle'">Yes/No</div>
          <div v-if="item.type == 'dropdown'">Dropdown</div>
        </template>

        <template v-slot:item.show_member="{ item }">
          <div v-if="item.show_member == true"><v-icon color="success">check_circle</v-icon></div>
          <div v-else><v-icon color="error">cancel</v-icon>
          </div>
        </template>

        <template v-slot:item.edit_member="{ item }">
          <div v-if="item.edit_member == true"><v-icon color="success">check_circle</v-icon></div>
          <div v-else><v-icon color="error">cancel</v-icon>
          </div>
        </template>

        <template v-slot:item.show_on_filter="{ item }">
          <div class="d-flex">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="toggleShowOnPage(item, 'member_page')" icon v-on="on" small>
                  <v-icon v-if="item.member_page == true" color="success" small class="mx-1">fa-users</v-icon>
                  <v-icon v-else color="grey" small class="mx-1">fa-users</v-icon>
                </v-btn>
              </template>
              <span>Show on Member Page Filter</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="toggleShowOnPage(item, 'legislator_page')" icon v-on="on" small>
                  <v-icon v-if="item.legislator_page == true" color="success" v-on="on" small class="mx-1">
                    fa-user-tie
                  </v-icon>
                <v-icon v-else color="grey" small class="mx-1">fa-user-tie</v-icon>
                </v-btn>
              </template>
              <span>Show on Legislator Page Filter</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="toggleShowOnPage(item, 'message_page')" icon v-on="on" small>
                  <v-icon v-if="item.message_page == true" color="success" v-on="on" small class="mx-1">fa-comments</v-icon>
                  <v-icon v-else color="grey" small class="mx-1">fa-comments</v-icon>
                </v-btn>
              </template>
              <span>Show on Message Window Filter</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="toggleShowOnPage(item, 'AM_page')" icon v-on="on" small>
                  <v-icon v-if="item.AM_page == true" color="success" v-on="on" small class="mx-1">fa-landmark</v-icon>
                  <v-icon v-else color="grey" small class="mx-1">fa-landmark</v-icon>
                </v-btn>
              </template>
              <span>Show on Advocacy Message creation window</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:no-data>
          <p class="text-xs-center mb-0">
            No custom fields created yet.
          </p>
        </template>
      </v-data-table>
      </v-card-text>
      <custom-fields-form-modal
          ref="form"
          :selectedClientId="client.id"
          @update="load"
      ></custom-fields-form-modal>
      <copy-custom-fields-modal
          ref="copyModal"
          :selectedClientId="client.id"
      ></copy-custom-fields-modal>
    </v-card>
  </v-container>
</template>

<script>
  import ClientService from '@/services/ClientService'
  import modal from "@/components/custom-fields/custom-fields-form-modal"
  import CopyModal from "@/components/custom-fields/copy-custom-fields-modal"
  import swal from 'sweetalert2'
  import memberFieldsPageHelpModal from '@/components/help-modals/member-fields-page-help-modal'
  import {mapGetters} from "vuex";

  export default {
    name: "CustomFieldsPage",
    mixins: [ClientService],
    components: {
      'member-fields-page-help-modal': memberFieldsPageHelpModal,
      'custom-fields-form-modal': modal,
      'copy-custom-fields-modal': CopyModal
    },
    data() {
      return {
        isLoading: false,
        list: [],
        pagination: {
          itemsPerPage: -1
        },
        tableHeader: [
          { text: '', value: 'action', align: 'center', sortable: false },
          { text: 'Label', value: 'name', align: 'left', sortable: false },
          { text: 'Type', value: 'type', align: 'left', sortable: false },
          { text: 'Show to member?', value: 'show_member', align: 'left', sortable: false },
          { text: 'User edit?', value: 'edit_member', align: 'left', sortable: false },
          { text: 'Show on filter', value: 'show_on_filter', align: 'left', sortable: false },
        ],
      }
    },
    computed: {
      ...mapGetters('app', ['superAdminSelectedClient']),
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      readyList() {
        return this.list.map(item => {return {...item, action: ''}})
      },
    },
    methods: {
      load() {
        this.isLoading = true
        this.getClientFields(this.client.id).then(response => {
          this.list = response.data
          this.isLoading = false
        })
      },
      copy(id) {
        this.$refs.copyModal.open(id)
      },
      openFieldModal(id) {
        this.$refs.form.open(id)
      },
      deleteField(id) {
        return this.deleteClientField(this.client.id, id)
      },
      onDeleteField (id) {
        swal({
          title: 'Are you sure to delete this field?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.deleteField(id)
              .then(() => {
                this.onCallToastr('success', 'Field has been deleted.', 'Success!')
                this.load()
              })
              .catch(() => {
                this.onCallToastr('error', 'Something went wrong.', 'Error!')
              })
          }
        })
      },
      init() {
        this.load()
      },
      toggleShowOnPage(item, key) {
        item[key] = !item[key]
        this.saveClientField(this.client.id, item).then(() => {
          this.onCallToastr('success', 'Field has been updated.', 'Success!')
          this.isLoading = false
          this.errors = false
        }).catch(() => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
        })
      },
    },
    mounted() {
      if (!(this.client && this.client.id)) return
      this.init()
    },
    watch: {
      'client': function () {
        this.init()
      }
    }
  }
</script>
