import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"900","fullscreen":_vm.isMobile},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Map for "+_vm._s(_vm.legislator.fullname))]),_c(VSpacer),_c(VIcon,{staticClass:"ml-3",on:{"click":_vm.closeModal}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,[_c(VLayout,{staticClass:"card-content mt-3",attrs:{"row":"","wrap":""}},[_c('div',{staticClass:"map-container"},[_c('div',{ref:_vm.id,staticClass:"map-el",style:(`width: 880px; height: 400px;`),attrs:{"id":_vm.id+'_'+_vm.legislator.id}})])])],1),_c(VDivider),_c(VCardActions,[_c(VCardSubtitle,{staticClass:"pa-0 py-0"},[_vm._v("Total Advocates: "),(_vm.members.length)?_c(VTooltip,{attrs:{"bottom":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('a',_vm._g({},on),[_c('strong',[_vm._v(_vm._s(_vm.members.length))])])]}}],null,false,2796368103)},[_c('span',_vm._l((_vm.members),function(member,index){return (member && member.fullname)?_c('div',{attrs:{"k:ey":"index"}},[_vm._v(" "+_vm._s(member.fullname)+" ")]):_vm._e()}),0)]):_c('strong',[_vm._v(_vm._s(_vm.members.length))])],1),_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.closeModal}},[_vm._v("Close")])],1)],1)],1),(_vm.isMemberDetailModalLoaded)?_c('member-detail-modal',{ref:"memberDetailModal",attrs:{"selected-client":_vm.client},on:{"openDistrictModal":_vm.openDistrictModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }