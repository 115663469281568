import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VContainer,{staticClass:"purple-background pb-12",staticStyle:{"display":"-ms-flexbox","-ms-flex-wrap":"wrap","flex-wrap":"wrap","height":"100%"},attrs:{"fluid":""}},[_c(VFlex,{staticClass:"d-flex fill-height align-center justify-center"},[_c(VCard,{staticClass:"text-center"},[_c(VCardText,[_c(VAvatar,{attrs:{"size":"150"}},[_c('img',{attrs:{"src":_vm.client.avatar_url || '/images/client-avatar.png'}})]),_c('h2',[_vm._v(_vm._s(_vm.client.association))]),_c('h4',{staticClass:"mt-5"},[_vm._v("All done!")]),_c('h1',[_vm._v("Thank You!")]),_c(VIcon,{attrs:{"size":"60","color":"success"}},[_vm._v(_vm._s(_vm.svgPath))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }