import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",staticStyle:{"max-width":"1600px"},attrs:{"fluid":"","grid-list-lg":""}},[_c(VCard,[_c(VCardText,{staticClass:"pt-5"},[_c(VLayout,{attrs:{"wrap":""}},[_c(VItemGroup,{staticClass:"flex xs12 md5 lg3",model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},_vm._l((_vm.items),function(item){return (item.show)?_c(VItem,{key:item.title,staticClass:"pr-2",scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return _c('div',{},[_c(VBtn,{staticClass:"mx-0 mb-2 how-to_tab-link",attrs:{"input-value":active,"dark":"","block":"","color":active ? 'primary' : '#666'},on:{"click":toggle}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("info")]),_c('span',[_vm._v(_vm._s(item.title))])],1)],1)}}],null,true)}):_vm._e()}),1),_c(VFlex,{staticClass:"xs12 md7 lg9"},[_c(VWindow,{staticClass:"elevation-0",attrs:{"vertical":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},_vm._l((_vm.items),function(item,i){return _c(VWindowItem,{key:item.title,attrs:{"transition":"none","reverse-transition":"none"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"pa-0"},[(item.video)?_c('div',{staticClass:"how-to_video"},[_c('video',{staticClass:"elevation-1",attrs:{"src":item.video,"controls":""}})]):_vm._e(),(item.images && item.images.length && _vm.window == i)?_c('div',[_c(VCarousel,_vm._l((item.images),function(image,index){return _c(VCarouselItem,{key:index,attrs:{"src":image.src}})}),1)],1):_vm._e()])],1)],1)}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }