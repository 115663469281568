// export default {
//     N: '#cccccc',
//     R: '#c53632',
//     D: '#1844c1',
//     I: '#8F00FF',
//     L: '#FED105'
// }

export const partyColorList = () => {
    return  {
        N: '#cccccc',
        R: '#c53632',
        D: '#1844c1',
        I: '#8F00FF',
        L: '#FED105'
    }
};
  

export const partyKmlColorList = () => {
    return  {
        N: '#55C08070',
        R: '#551844C1',
        D: '#55C53632',
        I: '#558F0F00',
        L: '#5500FFFF'
    }
};
  