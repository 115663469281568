<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">KP Leaderboard Help</v-card-title>
        <v-card-text>
          Motivation waxes and wanes for any volunteer. At times you may have to replace a volunteer due to any number of situations. Also, you may want to reward your
          most-active KPs at the end of a successful session. The KP Leaderboard will allow you to see who is doing a great job and maybe those who could use a break.
          <br /><br />
          You may sort each column to determine who gave the most, accomplished the most tasks for the current Session, or over their KP lifetime.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
