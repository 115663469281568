<template>
  <v-dialog max-width="500"  persistent v-model="isModalOpen">
    <v-form @submit.prevent="disableTwoFactor" ref="disableTwoFactorVerification">
      <v-card v-if="user">
        <v-card-text>
          <v-alert type="info" outlined :value="true" >{{ "Verification code has sent to your phone, please check and deactivate 2FA" }}</v-alert>
          <v-alert type="error" outlined :value="true" v-if="error && typeof error !== 'boolean'">{{ error }}</v-alert>
          <v-layout wrap class="py-3">
            <v-flex >
              <v-text-field
                label="2FA code"
                :rules="verificationCodeRules"
                type="text" v-model="verificationCode"
                :error-messages="error?[message]: []"
                @input="error = ''"/>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">{{'Disable 2FA'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import UserService from "@/services/UserService"

  export default {
    mixins: [UserService],
    name: "DisableTwoFactorModal",
    data() {
      return {
        user: {},
        isModalOpen: false,
        isBusy: false,
        error: false,
        message: null,
        verificationCode: null,
      }
    },
    computed: {
      verificationCodeRules() {
        return [
          v => !!v || '2Fa code is required.',
        ]
      },
    },
    methods: {
      toggle(user) {
        this.user = user;
        this.isModalOpen = true;
        if(this.$refs.disableTwoFactorVerification) {
          this.$refs.disableTwoFactorVerification.reset()
        }
      },
      disableTwoFactor() {
        let self = this;
        if (!this.$refs.disableTwoFactorVerification.validate()) {
          return false;
        }
        this.isBusy = true;
        this.disable2fa({verification_code: this.verificationCode})
          .then((res) => {
            if (res.data.success) {
              this.isBusy = false;
              this.isModalOpen = false;
              this.onCallToastr('success', 'Successfully disabled', 'Success!')
              this.user.twilio_authy_id = null;
              this.user.two_factor_verified = false;
              this.$store.dispatch('auth/setUserData', this.user);
              setTimeout(function () {
                self.$store.dispatch('app/setProfileSidebarOpen', false)
              }, 1500);
            } else {
              this.isBusy = false;
              this.error = true;
              this.message = "2Fa code is invalid"
            }

          })
          .catch(() => {
            this.isBusy = false;
            this.isModalOpen = false;
            this.onCallToastr('error', 'Something went wrong, please try again later', 'Failed!')
            setTimeout(function () {
              self.$store.dispatch('app/setProfileSidebarOpen', false)
            }, 1500);
          })
      }
    }
  }
</script>
