<template>
  <v-item-group class="member-card">
    <v-list-item>
      <v-list-item-avatar :style="kpPreferenceBorder(member, '3px')" :color="getColor(member.nickname)" class="justify-center">
        <img :src="getThumbImageMedium(member.avatar_url)" v-if="member.avatar_url">
        <img src="images/member-avatar.jpg" v-else>
        <!-- adam removed these colored circles since the colors were confusing clients. :color="getColor(member.nickname)"-->
        <!--<span class="white--text headline" v-else>{{ member.nickname[0] }}</span>-->
      </v-list-item-avatar>
      <v-list-item-content :class="isNotKp ? 'not-kp' : ''">
        <div>
          <a v-if="isCoordinator" class="member-fullname" >{{ member.nickname }} {{ member.lastname }}</a>
          <a v-else class="member-fullname" @click="openDetailsModal(member, '3px')">{{ member.nickname }} {{ member.lastname }}</a>
          <v-tooltip class="mr-2" v-if="member.political_leaning " bottom max-width="450">
            <template v-slot:activator="{ on }">
              <v-avatar size="10" :color="member.political_leaning.color" class="ml-1" v-on="on"></v-avatar>
            </template>
            <span>{{ member.political_leaning.name }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!isNotKp && isKpHomeInLegDistrict" max-width="450">
            <template v-slot:activator="{ on }">
              <v-icon class="ml-1" color="success" size="18" v-on="on">fa-light fa-location-check</v-icon>
            </template>
            <span>Constituent</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!isNotKp && !isKpHomeInLegDistrict" max-width="450">
            <template v-slot:activator="{ on }">
              <v-icon class="ml-1" color="error" size="18" v-on="on">fa-light fa-location-xmark</v-icon>
            </template>
            <span>Not a Constituent</span>
          </v-tooltip>

          <v-tooltip v-if="relationships !== []" bottom v-for="(relationship, index) in relationships" :key="index" max-width="450">
            <template v-slot:activator="{ on }">
              <v-avatar size="20" :color="relationship.color" class="ml-1" v-on="on">
                <v-icon size="14" color="white" v-if="relationship.icon == 'phone'">{{ relationship.icon }}</v-icon>
                <v-icon size="14" color="white" v-else-if="relationship.icon == 'fa-brands fa-twitter'">{{ relationship.icon }}</v-icon>
                <small v-else class="white--text">{{ relationship.icon }}</small>
              </v-avatar>
            </template>
            <span>{{ relationship.name }}</span>
          </v-tooltip>

          <v-tooltip class="ml-3" bottom v-if="!isNotKp && notes && notes.length > 0" max-width="450">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-1"
                small
                @click="$refs.KpNotesModal.toggle(member)"
                v-on="on"
              >
                <v-icon size="16" color="primary" class="notes_height">fa-duotone fa-file</v-icon>
              </v-btn>
            </template>
            <span>{{ notes }}</span>
          </v-tooltip>
          <v-btn
            v-if="!isNotKp && !notes"
            icon
            class="ml-1"
            small
            @click="$refs.KpNotesModal.toggle(member)"
          >
            <v-icon size="18" color="primary">fa-light fa-file-circle-plus</v-icon>
          </v-btn>

        </div>
        <v-list-item-subtitle :class="isNotKp ? 'not-kp' : ''" v-html="getLiveAndWorkMember"></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action class="ml-0 my-0 align-center d-flex justify-center" v-if="!isNotKp">
        <div>
          <v-btn
              v-if="isDesktop || isDevice"
              active-class="color-white"
              class="mb-2"
              x-small
              color="primary"
              @click="$refs.KpNotesModal.toggle(member)"
          >
            KP Notes
          </v-btn>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon @click="removeKp(member)" v-on="on">fa-thin fa-person-circle-minus</v-icon>
          </template>
          <span>Remove this KP</span>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>
    <template v-if="isShowDetailModal">
      <member-detail-modal
        ref="MemberDetailModal"
        :selectedClient="this.userClient"
      ></member-detail-modal>
    </template>
    <kp-notes-modal
      ref="KpNotesModal"
      :legislator="legislator"
      @updateKps="$emit('updateKps')"
      @updateLegislators="$emit('updateLegislators')"
    ></kp-notes-modal>
  </v-item-group>
</template>

<script>
import KpNotesModal from '@/components/legislators/assignment/kp-notes-modal';

export default {
  name: "MemberCard",
  components: {
    KpNotesModal,
    MemberDetailModal: () => import('@/components/members/member-detail-modal')
  },
  props: {
    member: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isNotKp: {
      type: Boolean,
      default: true
    },
    legislator: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data(){
    return {
      isShowDetailModal: false
    }
  },
  computed: {
    isKpHomeInLegDistrict() {
      if (this.legislator.district.district === this.member.home_house_district || this.legislator.district.district === this.member.home_sen_district) {
        return true
      }
      return false
    },
    relationships() {
      return this.member.member_legislator.length ? this.member.member_legislator[0].relationships : []
    },
    notes() {
      return this.member.member_legislator.length ? this.member.member_legislator[0].notes : []
    },
    getLiveAndWorkMember() {
      let age = this.member.dob ? `${moment().diff(this.member.dob, 'years')} . ` : '';
      let graduated = this.member.grad_year ? `Graduated ${this.member.grad_year}.` : ''
      let live = this.member.home_address ? this.member.home_address.split(', ')[1] : ''
      let work = this.member.work_address ? this.member.work_address.split(', ')[1] : ''

      return `${age}${graduated} Lives in ${live}, works in ${work}`
    }
  },
  methods: {
    removeKp(member) {
      this.$emit('removeKp', member)
    },
    openDetailsModal(member) {
      this.isShowDetailModal = true;
      setTimeout(()=>{
        this.$refs.MemberDetailModal.toggle(member)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.notes_height {
  height: 13px !important;
}
</style>
