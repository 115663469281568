<template>
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isLoading"
    ></v-progress-linear>
      <v-layout wrap>
        <v-flex class="d-flex align-center">
          <h1>System Actions</h1>
        </v-flex>
      </v-layout>
      <system-actions v-if="client && client.id  && load" :clientId="client.id"></system-actions>
  </v-container>
</template>

<script>
import ClientService from '@/services/ClientService'
import SystemActions from '@/components/home/system-actions-component'
import {mapGetters} from "vuex";

export default {
  name: "SystemActionPage",
  mixins: [ClientService],
  components: {
    'system-actions': SystemActions,
  },
  data() {
    return {
      isLoading: false,
      load: false,
      timer: null,
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
  },
  methods: {
    init() {
      this.load = true
    },
  },
  mounted() {
    this.init()
  },
  watch: {
    'client': function () {
      this.load = false
      clearTimeout(this.timer)
      this.timer =  setTimeout(() => {
        this.init()
      }, 0)
    }
  }
}
</script>

