<template>
  <v-container fluid grid-list-lg class="pa-0">
    <v-card v-if="!isLoading">
      <v-card-text>
        <h1>Ok, let's do this!</h1>
        <v-alert outlined type="info" class="mt-3" :value="true">
          In order to use KP Dashboard to its maximum potential, you must complete all of the following steps before you can start checking off tasks.
        </v-alert>
        <div
          v-for="(instruction, index) in instructionList"
          :key="index"
        >
          <v-card class="mb-3" v-if="instruction.title === 'Setting'">
            <v-toolbar dark :color="instruction.status ? 'success' : 'error'" height="48">
              <v-icon v-if="instruction.status">check_circle</v-icon>
              <v-icon v-else>cancel</v-icon>
              <v-toolbar-title>STEP {{ index + 1 }} - {{ instruction.title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="white--text" :to="{name: instruction.buttonLink}">{{
                  instruction.buttonTitle
                }}
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-list dense>
                <v-list-item
                  v-for="(text, index) in instruction.list"
                  :key="index"
                >
                  <v-list-item-icon>
                    <v-icon v-if="text.status" color="success">check_circle</v-icon>
                    <v-icon v-else color="error">cancel</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="text.body"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>

          <v-card class="mb-3" v-else>
            <v-toolbar dark :color="instruction.status ? 'success' : 'error'" height="48">
              <v-icon v-if="instruction.status">check_circle</v-icon>
              <v-icon v-else>cancel</v-icon>
              <v-toolbar-title>STEP {{ index + 1 }} - {{ instruction.title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="white--text" :to="{name: instruction.buttonLink}">{{
                  instruction.buttonTitle
                }}
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <ul>
                <li v-for="text in instruction.list" :key="text">{{ text }}</li>
              </ul>
            </v-card-text>
          </v-card>
        </div>
        <v-card class="mb-3">
          <v-toolbar dark color="info" height="48">
            <v-icon>info</v-icon>
            <v-toolbar-title>HELPFUL THINGS TO KNOW</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <ul>
              <li>
                If you have a history of previous Legislator voting history, create Sessions with a start date in the past and future end date.
                Then create a Final Vote task and record those past votes in KP Task Results page.
                They will display for any current Legislators in their History tab sorted by date.
                Finally, end that task by saving a past end date.
              </li>
              <li>
                KP Dashboard keeps a permanent, running history of all your KPs, Sessions, Tasks, Results, and Legislators.
                Your Association will be able to call up this history in the future at any time.
              </li>
              <li>
                We take security very seriously.
                Every User must confirm their email address before entering site.
                Also, a change in email address requires another confirmation.
                2 Factor Authorization is available for your Admin and Coordinator users in their individual profiles.
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ClientService from '@/services/ClientService'

export default {
  mixins: [ClientService],
  data () {
    return {
      isLoading: true,
    }
  },
  computed: {
    clientStatus() {
      return this.$store.state.app.clientStatus
    },
    statusSettingRegion() {
      return this.userClient && this.userClient.uses_regions ? this.clientStatus.settings.regions : true;
    },
    statusSettings() {
      const settings = this.clientStatus.settings
      return settings.email && settings.texting && settings.committees && settings.relationships && this.statusSettingRegion
    },
    instructionList () {
      return [
        {
          status: this.statusSettings,
          title: 'Setting',
          buttonTitle: 'GO TO CLIENT OPTIONS',
          buttonLink: 'client-options-page',
          list: [
            {
              status: this.clientStatus.settings.email,
              body: 'Make sure your client has a functional Mailgun email address'
            },
            {
              status: this.clientStatus.settings.texting,
              body: 'Make sure your client has a functional Twilio number'
            },
            {
              status: this.clientStatus.settings.committees,
              body: 'Make sure your client has at least one committee is chosen in client settings'
            },
            {
              status: this.clientStatus.settings.relationships,
              body: 'Make sure your client has at least one active relationship'
            },
            {
              status: this.statusSettingRegion,
              body: 'Make sure your client has no orphaned regions'
            },
          ]
        },
        {
          status: this.clientStatus.legislators,
          title: 'REVIEW AND EDIT YOUR LEGISLATORS AS NECESSARY',
          buttonTitle: 'GO TO LEGISLATORS PAGE',
          buttonLink: 'legislators-page',
          list: [
            'Your State’s Legislators will be provided for you; however, there are certain fields you can update:'
          ]
        },
        {
          status: this.clientStatus.key_persons,
          title: 'ASSIGN YOUR ADVOCATES TO LEGISLATORS AND FILL THE KP POSITIONS',
          buttonTitle: 'GO TO ASSIGNMENTS PAGE',
          buttonLink: 'assignments-page',
          list: [
            `Once a Member is assigned into a KP position, they are considered “KPs”.
            The most-important KP is the KP1 – the primary Key Person is responsible for coordinating any KP2, KP3 for his or her legislator.`
          ]
        },
        {
          status: this.clientStatus.advocates,
          title: 'IMPORT YOUR ADVOCATES',
          buttonTitle: 'GO TO ADVOCATE PAGE',
          buttonLink: 'members-page',
          list: [
            'Your Advocatea should have legislative districts set for Home, Work, and any other extra work addresses you’d like to monitor.',
            'You can monitor up to 4 work address districts.',
            'Also, make sure you have a functional email address and cell phone number for Advocates and KPs. Reliable communication channels will be imperative!'
          ]
        },
        {
          status: this.clientStatus.active_session,
          title: 'CREATE A SESSION',
          buttonTitle: 'GO TO SESSIONS PAGE',
          buttonLink: 'sessions-page',
          list: [
            `Sessions are time frames of when you’d like your Key People to accomplish certain tasks.
            Start times are usually a few months after a state legislative session has ended, but early enough to accomplish all of your goals for the next legislative session.
            Example, in Virginia the Legislative session ends about March;
            however, we start the next “Session” internally in about August or so. Our KPs have from May 1 - July 1 to complete tasks for a typical session.`
          ]
        },
        {
          status: this.clientStatus.tasks_created,
          title: 'CREATE SESSION TASKS',
          buttonTitle: 'GO TO SESSION TASKS PAGE',
          buttonLink: 'sessions-page',
          list: [
            `Once you have an open Session, you will be able to create your tasks.
            These are tasks or items that you would like to have completed or monitored during the active Session by your Key People.
            These vary but generally include Contacting your legislator, meeting with them to have friendly conversation,
            explaining a certain political agenda, asking for their support, monitoring donations, etc.`
          ]
        },
        {
          status: this.clientStatus.team_members,
          title: 'ADVOCATES LOGGED IN',
          buttonTitle: 'GO TO ADVOCATE PAGE',
          buttonLink: 'members-page',
          list: [
            `Make sure your members at least one active team member who has logged in`
          ]
        },
      ]
    }
  },
  async mounted () {
    if (!this.userClientId) {
      this.logout()
      return;
    }
    const result = (await this.getClientStatus(this.userClientId)).data
    await this.$store.dispatch('app/setClientStatus', result)
    let isCompleted = 0
    for (let item in result) {
      if(item == 'settings' ) {
        for (let setting in result[item]) {
          if(!result[item][setting]) isCompleted++
        }
      } else {
        if(!result[item]) isCompleted++
      }
    }
    if (isCompleted === 0) {
      this.$router.replace({ name: 'home-page' })
    }
    this.isLoading = false
  }
}
</script>
