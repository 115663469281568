<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container v-if="client && client.id" fluid grid-list-lg style="max-width: 1600px">
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>Sessions</h1>
        <sessions-page-session-help-modal></sessions-page-session-help-modal>
        <v-spacer></v-spacer>
        <v-menu offset-y v-if="isMobile">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon small dark v-on="on"><v-icon>more_vert</v-icon></v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-tooltip bottom v-if="!client || !!activeSession || isLoading">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="my-1 disabled-button"
                    block
                    small
                    v-on="on"
                  >
                    New Session
                    <v-icon dark class="ml-2">playlist_add</v-icon>
                  </v-btn>
                </template>
                <span>Only one Session allowed at a time</span>
              </v-tooltip>
              <v-btn
                v-else
                color="warning"
                class="my-1"
                block
                small
                @click="$refs.newSessionModal.toggle(client)"
              >
                New Session
                <v-icon dark class="ml-2">playlist_add</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-flex>
      <v-spacer v-if="isDesktop || isDevice"></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop">
        <v-tooltip bottom v-if="!client || !!activeSession || isLoading">
          <template v-slot:activator="{ on }">
            <v-btn
              class="disabled-button"
              small
              v-on="on"
            >
              New Session
              <v-icon dark class="ml-2">playlist_add</v-icon>
            </v-btn>
          </template>
          <span>Only one Session allowed at a time</span>
        </v-tooltip>
        <v-btn
          v-else
          color="warning"
          small
          @click="$refs.newSessionModal.toggle(client)"
        >
          New Session
          <v-icon dark class="ml-2">playlist_add</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex class="d-flex align-center flex-wrap">
        <v-checkbox
          color="primary"
          hide-details
          label="Show Past Sessions"
          class="mt-0"
          v-model="showingPastSessions"
        ></v-checkbox>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-data-table
      :loading="isLoading"
      class="elevation-1"
      :options.sync="sessionTablePagination"
      :headers="sessionTableHeader"
      :items="filteredSessionList"
    >
      <template v-slot:item.action="{ item }">
        <div>
          <v-btn icon class="mx-0" @click="$refs.editSessionModal.toggle(client, item)">
            <v-icon size="18" color="primary">edit</v-icon>
          </v-btn>
          <template v-if="item && item.isActive">
            <v-tooltip bottom max-width="450">
              <template v-slot:activator="{ on }">
                <v-chip small class="mx-2" v-on="on" @click="onSessionCloseHandler(item)">
                End Current Session
                </v-chip>
              </template>
              <span>{{ "If the current Session and Tasks are complete, you may end the current Session so that you may start a new Session with new Tasks." }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <template v-slot:item.name="{ item }">
        <div :class="{'active': activeSession && item.id === activeSession.id}">
          {{ item.name }}
        </div>
      </template>

      <template v-slot:item.notes="{ item }">
        <div>
          <v-tooltip bottom v-if="!!item.notes" max-width="450">
            <template v-slot:activator="{ on }">
              <v-icon color="primary" v-on="on">note</v-icon>
            </template>
            <span>{{ item.notes }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.start_at="{ item }">
        <div>
          {{ getFormatDate(item.start_at, 'MMM D, YYYY hh:mm A') }}
        </div>
      </template>

      <template v-slot:item.end_at="{ item }">
        <div>
          {{ (item && item.end_at)? getFormatDate(item.end_at, 'MMM D, YYYY hh:mm A') : "Present" }}
        </div>
      </template>

      <template v-slot:item.result="{ item }">
        <div>
          <v-btn icon :to="{'name': 'kp-tasks-page', 'query': {'session': item.id}}">
            <v-icon color="primary">playlist_add_check</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">
          {{ showingPastSessions ? 'No session created yet.' : 'No active session.' }}
        </p>
      </template>
    </v-data-table>

    <v-layout wrap class="mt-4">
      <v-flex class="d-flex align-center">
        <h2>{{ activeSession ? `"${activeSession.name}"` : '' }} Tasks ({{taskList.length}})</h2>
        <sessions-page-task-help-modal></sessions-page-task-help-modal>
        <v-spacer></v-spacer>
        <v-menu offset-y v-if="isMobile">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon small dark v-on="on"><v-icon>more_vert</v-icon></v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-btn color="warning" class="my-1" block small @click="$refs.newTaskModal.toggle()" :disabled="!activeSession">
                New Task
                <v-icon dark class="ml-2">playlist_add_check</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-flex>
      <v-spacer v-if="isDesktop || isDevice"></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop">
        <v-btn color="warning" small @click="$refs.newTaskModal.toggle()" :disabled="!activeSession">
          New Task
          <v-icon dark class="ml-2">playlist_add_check</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-alert type="warning" outlined :value="true" v-if="!activeSession">
      You must have an active session to add tasks.
    </v-alert>

    <v-data-table
      :loading="isTaskListLoading"
      hide-default-footer
      disable-pagination
      class="elevation-1 tasks_table"
      :headers="taskTableHeader"
      :items="taskList"
      v-if="!!activeSession"
    >
      <template v-slot:item.handle="{ item }">
        <v-icon class="handle">drag_indicator</v-icon>
      </template>

      <template v-slot:item.action="{ item }">
        <div>
          <v-btn icon class="mx-0" :disabled="isLoading" @click="openEditTask(item)">
            <v-icon size="18" color="primary">edit</v-icon>
          </v-btn>
          <v-btn icon class="mx-0" :disabled="isLoading" @click="onDeleteTask(item.id)">
            <v-icon size="18" color="error">delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.name="{ item }">
        <div>{{ item.name }}</div>
      </template>

      <template v-slot:item.tooltip="{ item }">
        <div>{{ item.tooltip }}</div>
      </template>

      <template v-slot:item.notes="{ item }">
        <div>
          <v-tooltip bottom v-if="!!item.notes" max-width="450">
            <template v-slot:activator="{ on }">
              <v-icon color="primary" v-on="on">note</v-icon>
            </template>
            <span>{{ item.notes }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.hide_from_kp="{ item }">
        <div>
          <v-icon color="success" v-if="item.hide_from_kp">check_circle</v-icon>
          <v-icon color="error" v-else>cancel</v-icon>
        </div>
      </template>

      <template v-slot:item.deadline="{ item }">
        <div>{{ formatDate(item.deadline) }}</div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">
          {{ showingPastSessions ? 'No session created yet.' : 'No active session.' }}
        </p>
      </template>
    </v-data-table>

    <edit-session-modal ref="editSessionModal" :active-session="activeSession" :session-list="sessionList" :committee-list="committeeList" :refresh="refresh" :legislators="legislatorsList" ></edit-session-modal>
    <edit-task-modal
      ref="editTaskModal"
      :refresh="refreshTaskList"
      :selectedClient="client"
      :committee-list="committeeList"
      :activeSession="activeSession"
    ></edit-task-modal>
    <new-session-modal ref="newSessionModal" :active-session="activeSession" :session-list="sessionList" :committee-list="committeeList" :refresh="refresh"
    :legislators="legislatorsList"></new-session-modal>
    <new-task-modal
      ref="newTaskModal"
      :active-session="activeSession"
      :common-task-list="commonTaskList"
      :task-list="taskList"
      :refresh="refreshTaskList"
      :selectedClient="client"
      :committee-list="committeeList"
    >
    </new-task-modal>
  </v-container>
</template>

<script>
import swal from 'sweetalert2'
import Sortable from 'sortablejs'

import filterCommitteeList from '@/mixins/filterCommitteeList'

import ClientService from '@/services/ClientService'
import SessionService from '@/services/SessionService'
import StateService from '@/services/StateService'
import TaskService from '@/services/TaskService'

import editSessionModal from '@/components/sessions/edit-session-modal'
import editTaskModal from '@/components/sessions/edit-task-modal'
import newSessionModal from '@/components/sessions/new-session-modal'
import newTaskModal from '@/components/sessions/new-task-modal'
import sessionsPageSessionHelpModal from '@/components/help-modals/sessions-page-session-help-modal'
import sessionsPageTaskHelpModal from '@/components/help-modals/sessions-page-task-help-modal'
import {mapGetters} from "vuex";

export default {
  name: 'SessionsPage',
  mixins: [ClientService, SessionService, StateService, TaskService, filterCommitteeList],
  components: {
    'edit-session-modal': editSessionModal,
    'edit-task-modal': editTaskModal,
    'new-session-modal': newSessionModal,
    'new-task-modal': newTaskModal,
    'sessions-page-session-help-modal': sessionsPageSessionHelpModal,
    'sessions-page-task-help-modal': sessionsPageTaskHelpModal,
  },
  data () {
    return {
      isLoading: false,
      isTaskListLoading: false,
      showingPastSessions: false,
      sessionList: [],
      activeSession: null,
      commonTaskList: [],
      taskList: [],
      sessionTablePagination: {
        itemsPerPage: 10
      },
      error: null,
      legislatorsList: [],
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    sessionTableHeader () {
      return [
        { text: '', value: 'action', align: 'center', sortable: false },
        { text: 'Name', value: 'name', align: 'center' },
        { text: 'Notes', value: 'notes', align: 'center' },
        { text: 'Start At', value: 'start_at', align: 'center' },
        { text: 'End At', value: 'end_at', align: 'center' },
        { text: 'Result', value: 'result', align: 'center', sortable: false },
      ]
    },
    taskTableHeader () {
      return [
        { text: '', value: 'handle', align: 'center', sortable: false },
        {text: '', value: 'action', align: 'center', sortable: false},
        {text: 'Short Task Label', value: 'name', align: 'left', sortable: false},
        {text: 'Task Explanation', value: 'tooltip', align: 'left', sortable: false},
        {text: 'Notes', value: 'notes', align: 'center', sortable: false},
        {text: 'Hidden?', value: 'hide_from_kp', align: 'center', sortable: false},
        {text: 'Deadline', value: 'deadline', align: 'left', sortable: false},
      ]
    },
    filteredSessionList () {
      if (!this.showingPastSessions) {
        return this.activeSession ? [{...this.activeSession, isActive: true}] : []
      }
      return this.sessionList
    }
  },
  async mounted () {
    await this.getLegislatorsList()
    await this.refreshCommonTaskList()
    await this.refresh()
  },
  methods: {
    getFormatDate(date, format){
      return moment(date).format(format);
    },
    async refresh () {
      if (!(this.client && this.client.id)) return

      this.isLoading = true
     try {
       
        await this.filterCommitteeList();
        await this.getClientActiveSession(this.client.id).then(res => {
         if(res.data) {
           this.activeSession = res.data
         } else {
           this.activeSession = null
         }
       });

       const sessionList = (await this.getClientSessions(this.client.id)).data;
       if(sessionList && sessionList.length && this.activeSession && this.activeSession.id) {
          this.sessionList = sessionList.map((sessionInfo)=> {
            sessionInfo.isActive = !!(sessionInfo.id === this.activeSession.id);
            return sessionInfo;
          });
       } else {
        this.sessionList = sessionList;
       }

       await this.refreshTaskList();
       this.dragInit();
     } finally {
       this.isLoading = false
     }
    },
    async refreshCommonTaskList () {
      this.commonTaskList = (await this.getCommonTasks()).data
      this.commonTaskList.forEach((task) => {
        task.description = `${task.name} - ${task.tooltip}`
      })
    },
    async refreshTaskList () {
      this.taskList = []
      if (!this.activeSession) return
      this.isLoading = true
      this.taskList = (await this.getSessionTasks(this.activeSession.id)).data
      this.isLoading = false
    },
    dragInit() {
      let table = document.querySelector('.tasks_table div table tbody')
      if (!table) return
      let self = this
      Sortable.create(table, {
        handle: ".handle",
        onEnd({ newIndex, oldIndex}) {
          const rowSelected = self.taskList.splice(oldIndex, 1)[0]
          self.taskList.splice(newIndex, 0, rowSelected)

          let startFromIndex = oldIndex < newIndex ? oldIndex : newIndex
          let result = []

          for (let i = 0; i < self.taskList.length; i++) {
            if(i >= startFromIndex) {
              if(i == newIndex) {
                result.push({
                  id: self.taskList[i].id,
                  sort_order: newIndex
                })
              } else {
                result.push({
                  id: self.taskList[i].id,
                  sort_order: i
                })
              }
            }
          }
          self.saveSort(result)
        }
      })
    },
    saveSort(result) {
      this.sortSessionTasks(result).then(()=> {
        this.refreshCommonTaskList()
      })
    },
    onDeleteTask (taskId) {
      swal({
        title: 'Are you 100% sure about that?',
        text: 'Deleting a task will result in the permanent deletion of all results attached to this task.  This is not reversible!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete Task/Results Forever',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteTask(taskId)
            .then(() => {
              this.onCallToastr('success', 'Task has been deleted.', 'Success!')
              this.refreshTaskList()
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })
    },
    onSessionCloseHandler (sessionImtem) {
      swal({
        title: 'End this Session now?',
        text: `Stopping the ${sessionImtem && sessionImtem.name} session. This is not reversible!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Stop Session',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          const now = new Date();
          const formattedDate = this.formatDate(now, 'YYYY-MM-DD HH:mm:ss');
          const updateSession = {...sessionImtem, end_at: formattedDate};
          this.updateSession(updateSession)
            .then(() => {
              this.isBusy = false
              this.onCallToastr('success', 'Session has been updated.', 'Success!')
              this.$emit('refresh')
              this.step = 1
              this.isModalOpen = false
              this.refresh()
            })
            .catch(() => {
              this.isBusy = false
              this.error = 'Something went wrong!'
            })
        }
      })
    },
    openEditTask(item) {
      this.isTaskListLoading = true
      const param = {
        // kp1: 1,
        order: 'lastname'
      }
      return this.getClientLegislatorsWithCommittees(this.client.id, param).then((response) => {
        let legislators = response.data.data ? response.data.data : response.data
        return this.getClientLegislatorsWithCommittees(this.client.id, { kp1: 1, taskId: item.id,  order: 'lastname' }).then((res) => {
          this.isTaskListLoading = false
          let selectedLeg = res.data.data ? res.data.data : res.data
          this.$refs.editTaskModal.toggle(item, legislators, selectedLeg)
        })
      }, error => {
        this.isTaskListLoading = false
        this.error = error
      })
    },
    async getLegislatorsList() {
      let param = {
        order: 'lastname'
      }
      this.legislatorsList = (this.client && this.client.id) ? await this.getClientLegislators(this.client && this.client.id, param).then((response) => {
        return response.data.data ? response.data.data : response.data
      }) : []
    },
  },
  watch: {
    'client': function () {
      this.refresh()
    }
  }
}
</script>
