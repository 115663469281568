import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"400px"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,{staticClass:"upload-avatar-modal"},[_c(VAvatar,{staticClass:"cursor-pointer bordered_avatar",attrs:{"width":"100%","height":"auto","tile":""}},[(_vm.member.avatar_url)?_c('img',{attrs:{"alt":"Avatar","src":_vm.member.avatar_url}}):_c('img',{attrs:{"alt":"Avatar","src":'/images/member-avatar_old.png'}})]),_c(VBtn,{staticClass:"close-button",attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("close")])],1),_c('div',{staticClass:"action"},[_c(VBtn,{attrs:{"color":"primary action_button mr-2"},on:{"click":function($event){return _vm.$refs.avatarUploader.click()}}},[_vm._v(" Upload ")]),_c(VBtn,{attrs:{"color":"primary action_button"},on:{"click":function($event){return _vm.$refs.WebCamModal.open()}}},[_vm._v(" Use Camera ")])],1)],1),_c('input',{ref:"avatarUploader",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.onUploadAvatar()}}}),_c('web-cam-modal',{ref:"WebCamModal",attrs:{"member":_vm.member},on:{"upload":_vm.uploadPhoto}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }