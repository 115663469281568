<template>
  <div>
    <v-icon color="primary" size="24" class="ml-2" @click.stop="isModalOpen = true">help</v-icon>
    <v-dialog v-model="isModalOpen" max-width="600">
      <v-card>
        <v-card-title class="headline">Relationships Help</v-card-title>
        <v-card-text>
        <p>
        Relationships refer to the Legislator-KP relationship. You may wish to keep these simple such as "Good" and "Bad"; however, you can also get very specific. 
        Different types of organizations may have different relationships. 
        </p>
        <p>Some example relationships are below.</p>
        <ul>
        <li>No relationship yet</li>
        <li>Legislator is a patient of the KP</li>
        <li>Family member has relationship</li>
        <li>Personal friends</li>
        <li>Professional Relationship</li>
        <li>Attend same church</li>
        <li>Has Legislator cell</li>
        </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isModalOpen: false
    }
  }
}
</script>
