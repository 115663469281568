<template>
  <v-layout wrap>
    <v-flex xs12 sm6 class="px-1">
      <v-menu
        close-delay=""
        ref="dateMenu"
        v-model="fromDateMenu"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fromDate"
            label="From"
            prepend-icon="mdi-calendar"
            readonly
            :clearable="!!fromDate"
            v-bind="attrs"
            v-on="on"
            hide-details
          ></v-text-field>
        </template>
        <v-date-picker
          color="primary"
          v-model="fromDate"
          :max="currentDate"
          no-title
          scrollable
          @change="$refs.dateMenu.save(fromDate)"
        >
        </v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs12 sm6 class="px-1">
      <v-menu
        ref="dateMenu"
        v-model="toDateMenu"
        :return-value.sync="toDate"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="toDate"
            label="To"
            prepend-icon="mdi-calendar"
            readonly
            :clearable="!!toDate"
            v-bind="attrs"
            v-on="on"
            hide-details
          ></v-text-field>
        </template>
        <v-date-picker
          color="primary"
          v-model="toDate"
          :min="currentToDate"
          :max="currentDate"
          no-title
          scrollable
          @change="$refs.dateMenu.save(toDate)"
        >
        </v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs12 sm12 class="mx-3 mt-2 d-flex justify-space-between">
      <a class="filter_button" :class="today ? 'active' : ''" @click="onToday">Today</a>
      <a class="filter_button" :class="yesterday ? 'active' : ''" @click="onYesterday">Yesterday</a>
      <a class="filter_button" :class="lastWeek ? 'active' : ''" @click="onLastWeek">Last Week</a>
    </v-flex>
    <v-flex class="full-width">
      <v-layout
        v-if="isBusy"
        class="align-center column pa-5"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          size="44"
          width="4"
        ></v-progress-circular>
          {{ 'Loading messages...' }}
      </v-layout>
      <v-data-table
        ref="ThreadViewTable"
        class="elevation-1 message_list"
        :headers="ThreadViewTableHeader"
        :expanded.sync="expanded"
        group-by="subject"
        :items="messagesTopic.items"
        single-expand
        show-expand
        multi-sort
        :sort-by="['count_unseen_message', 'created_at']"
        :sort-desc="[true, true]"
        disable-pagination
        hide-default-header
        hide-default-footer
      >
        <template v-slot:group.header="{items, isOpen, toggle}">
          <th colspan="3">
            <div class="d-flex flex-wrap align-center" style="min-width: 285px">
              <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
              <span>{{ items[0].subject }}</span>
              <v-badge
                class="d-flex align-center ml-1"
                right
                overlap
                offset-x="10"
                offset-y="15"
                :value="items[0].count_unseen_message > 0"
                color="error"
              >
                <template v-slot:badge>
                  <span v-if="!!items[0].count_unseen_message">{{ items[0].count_unseen_message }}</span>
                </template>
                <v-tooltip max-width="450" bottom v-if="items[0].total_conversations > items.length">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="d-flex align-center">
                      <h5>({{ items[0].total_conversations }}/{{ items.length }})</h5>
                    </div>
                  </template>
                  <span>Uploaded {{ items.length }} of {{ items[0].total_conversations }} conversations. To download more, click "Load More".</span>
                </v-tooltip>
                <h5 v-else>({{ items[0].total_conversations }})</h5>
              </v-badge>
            </div>
          </th>
        </template>

        <template v-slot:item="{ item }">
          <tr>
            <td class="d-flex flex-nowrap align-center">
              <span style="min-width: fit-content">{{ item.nickname }} {{ item.lastname }} - </span>
              <v-tooltip max-width="900" bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <h5 class="character-width">{{ getTextFromHTML(item.message) }}</h5>
                  </div>
                </template>
                <span>{{ getTextFromHTML(item.message) }}</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
    <v-flex>
      <v-btn
        color="primary"
        text
        block
        small
        @click="onLoadMore"
        :disabled="messagesTopic.pagination.last_page <= messagesTopic.pagination.page"
        :loading="isBusy"
      >
        <span>Load more</span>
        <v-icon class="ml-2">keyboard_arrow_down</v-icon>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>

import {mapGetters} from "vuex";
import MessageService from "@/services/MessageService";

export default {
  name: "ThreadViewComponent",
  mixins: [MessageService],
  data() {
    return {
      fromDateMenu: false,
      fromDate: null,
      date: null,
      loadMoreCount: 1,
      toDateMenu: false,
      expanded: [],
      toDate: null,
      isBusy: false,
      filteringFromDate: null,
      filteringToDate: null,
      today: false,
      yesterday: false,
      lastWeek: false,
      timer: null,
      messagesTopic: {
        pagination: {
          page: 1,
          itemsPerPage: 10,
          last_page: 0,
        },
        items: []
      }
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    ThreadViewTableHeader() {
      return [
        {text: 'Subject', value: 'subject'},
        {text: 'Name', value: 'nickname'},
      ]
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    currentToDate() {
      return this.fromDate ? this.fromDate : new Date().toISOString().substr(0, 10)
    },
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.isBusy = true
      this.getMajorMessageList()
      this.defaultCloseGroupThreadViewTable()
    },
    defaultCloseGroupThreadViewTable() {
      let count = 0
      this.awaitTable(count)
    },
    getTextFromHTML(html) {
      const element = document.getElementById('htmlConvertContainer')
      element.innerHTML = html
      return element.textContent
    },
    getMajorMessageList(action = '') {
      this.isBusy = true
      if (action === 'loadMore') {
        this.messagesTopic.pagination.itemsPerPage = 10 * this.loadMoreCount
      }

      let data = {
        page: this.messagesTopic.pagination.page,
        limit: this.messagesTopic.pagination.itemsPerPage,
        from_date: this.filteringFromDate,
        to_date: this.filteringToDate
      }
      this.getThreadViewMessages(this.client.id, data)
        .then(response => {
          this.messagesTopic.items = response.data.data ? response.data.data : response.data
          this.messagesTopic.pagination.last_page = response.data.last_page

          this.$emit('isMessageTopic', this.messagesTopic.items.length > 0)
          this.isBusy = false
        })
        .catch((err) => {
          this.handleError(err)
        })
    },
    onLoadMore() {
      this.loadMoreCount++
      this.getMajorMessageList('loadMore')
    },
    removeFilterButtons() {
      this.today = false
      this.yesterday = false
      this.lastWeek = false
    },
    clearFilteringDate() {
      this.filteringFromDate = null
      this.filteringToDate = null
    },
    onToday() {
      this.isBusy = true
      this.fromDate = null
      this.toDate = null
      this.clearFilteringDate()
      if (this.today) {
        this.removeFilterButtons()
        this.getMajorMessageList()
        return;
      }
      this.removeFilterButtons()
      this.today = true
      this.filteringToDate = new Date().toJSON().slice(0, 10)
      this.filteringFromDate = new Date().toJSON().slice(0, 10)
      this.getMajorMessageList()
    },
    onYesterday() {
      this.isBusy = true
      this.fromDate = null
      this.toDate = null
      this.clearFilteringDate()
      if (this.yesterday) {
        this.removeFilterButtons()
        this.getMajorMessageList()
        return;
      }
      this.removeFilterButtons()
      this.yesterday = true
      this.filteringToDate = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24).toJSON().slice(0, 10) //1 days ago
      this.filteringFromDate = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24).toJSON().slice(0, 10) //1 days ago
      this.getMajorMessageList()
    },
    onLastWeek() {
      this.isBusy = true
      this.fromDate = null
      this.toDate = null
      this.clearFilteringDate()
      if (this.lastWeek) {
        this.removeFilterButtons()
        this.getMajorMessageList()
        return;
      }
      this.removeFilterButtons()
      this.lastWeek = true
      this.filteringToDate = new Date().toJSON().slice(0, 10)
      this.filteringFromDate = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24 * 7).toJSON().slice(0, 10) //7 days ago
      this.getMajorMessageList()
    },
    //This method for collapse ThreadView table groups
    awaitTable(count) {
      count++
      // Emergency stop the recursion
      if (count > 200) {
        clearTimeout(this.timer)
        return false;
      }

      let threadViewTable = this.$refs.ThreadViewTable;
      if (
        !this.isBusy
        && threadViewTable
        && threadViewTable.openCache
        && Object.keys(threadViewTable.openCache).length
      ) {
        clearTimeout(this.timer)
        for (const name of Object.keys(threadViewTable.openCache)) {
          threadViewTable.openCache[name] = false;
        }
      } else {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.awaitTable(count)
        }, 10)
      }
    },
  },
  watch: {
    'fromDate': function () {
      this.removeFilterButtons()
      if (!this.toDateMenu) {
        this.clearFilteringDate()
      }
      this.filteringFromDate = this.fromDate
      this.getMajorMessageList()
    },
    'toDate': function () {
      this.removeFilterButtons()
      if (!this.fromDate) {
        this.clearFilteringDate()
      }
      this.filteringToDate = this.toDate
      this.getMajorMessageList()
    },
  }
}
</script>

<style lang="scss" scoped>
.filter_button:hover {
  font-weight: bold;
}

.filter_button.active {
  font-weight: bold;
}

.message_list {
  overflow-y: auto;
  height: calc(100vh - 360px);
  min-height: calc(100vh - 360px);

}

.character-width {
  max-width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
