<template>
  <v-navigation-drawer fixed temporary right touchless stateless width="700" v-model="isModalOpen">
    <v-layout>
      <v-card width="100%" class="pa-3">
        <v-card-title class="px-2">
          <span class="title">{{ committee ? committee.fullname : 'Assign legislators' }}</span>
          <v-spacer/>
          <v-icon @click="close()">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout>
            <v-text-field
              v-model="committee.shortname"
              label="Short name"
              :counter="8"
              :disabled="loading"
            >
            </v-text-field>
          </v-layout>
          <div class="d-flex committee-colors" v-if="isColorInputsReady">
            <div class="committee-colors_item">
              <input type="color" id="committee_color" v-model="committee.color" @change="changeColor($event)">
              <label for="committee_color">
                <v-chip label dark :color="committee.color">Full Committee color</v-chip>
              </label>
            </div>
            <div class="committee-colors_item">
              <input type="color" id="subcommittee_color" v-model="committee.subcommittee_color" disabled>
              <label for="subcommittee_color">
                <v-chip label dark :color="committee.subcommittee_color">Subcommittee color</v-chip>
              </label>
            </div>
          </div>
          <v-layout wrap column>
            <div class="title mt-10">
              Full committee members:
              <span v-if="selectedLegislators && selectedLegislators.length">({{ selectedLegislators.length }})</span>
            </div>
            <v-autocomplete
              v-model="selectedLegislators"
              :items="sortLegislatorsList()"
              outlined
              dense
              color="blue-grey lighten-2"
              label="Select legislators"
              item-text="fullname"
              multiple
              return-object
              :disabled="loading"
              :loading="loading"
              :search-input.sync="searchInputFull"
              @change="searchInputFull=''"
              hide-details
              :menu-props="{maxHeight: 'calc(100% - 320px)'}"
            >
              <template v-slot:selection="{ item}">
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar class="avatar_image">
                    <img :src="getThumbImage(data.item.official_image)">
                  </v-list-item-avatar>
                  <v-icon v-if="data.item.lame_duck" color="#EECB23">fa-solid fa-duck</v-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.fullname }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <div class="mb-2 pt-1" v-if="selectedLegislators && selectedLegislators.length">
              <div class="d-flex flex-wrap">
                <div v-for="(item, index) of selectedLegislators" class="mr-2">
                  <v-menu
                    v-model="displaySelected[getIndex(item.id, selectedLegislators)]"
                    :key="index"
                    bottom
                    left
                    :close-on-content-click="false"
                    origin="top left"
                    content-class="role_card"
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip
                        :color="getLegislatorChipColor(item)"
                        class="mt-2"
                        :dark="getLegislatorChipColor(item) != 'default'"
                        pill
                        v-on="on"
                        :style=colorsLegislatorsParties(item)
                      >
                        <v-avatar left>
                          <v-img class="avatar_image" :src="getThumbImage(item.official_image)"></v-img>
                        </v-avatar>
                        <vip-chip
                          v-if="item.vip"
                          class="mr-2 mb-1"
                          :legislator="item"
                          :show="'chip_code'"
                        >
                        </vip-chip>
                        {{ item.fullname }}
                        <v-tooltip bottom v-if="item.lame_duck">
                          <template v-slot:activator="{ on }">
                            <a v-on="on">
                              <v-icon color="#EECB23" size="16" class="ml-1">fa-solid fa-duck</v-icon>
                            </a>
                          </template>
                          <span>Lame Duck</span>
                        </v-tooltip>
                      </v-chip>
                    </template>
                    <v-card width="400" height="auto" class="d-flex">
                      <v-flex class="legislators_list__card--img">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              @click="removeLegislator(getIndex(item.id, selectedLegislators), selectedLegislators, item)"
                              class="legislators_remove_btn"
                              color="error"
                              v-on="on"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Remove from {{ committee.fullname }}</span>
                        </v-tooltip>
                        <v-img class="avatar_image" :src="getThumbImageMedium(item.official_image)"></v-img>
                      </v-flex>
                      <v-flex>
                        <v-flex class="d-flex justify-space-between">
                          <h2 class="mt-2">{{ item.fullname }}</h2>
                        </v-flex>
                        <v-flex>
                          <span>Membership:</span>
                          <v-radio-group v-model="getSelectedLegislator(item).fullRole" class="mt-2" hide-details>
                            <v-flex class="radio_group_role mb-3">
                              <v-radio
                                v-for="membership in memberships"
                                :key="membership.id"
                                color="primary"
                                :label="membership.name"
                                :value="membership"
                                class="mb-2"
                              ></v-radio>
                            </v-flex>
                          </v-radio-group>
                        </v-flex>
                      </v-flex>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
            <div v-if="client && client.state && client.state.has_subcommittees">
              <v-divider></v-divider>
              <div class="title mt-10">
                Subcommittee members:
                <span
                  v-if="subSelectedLegislators && subSelectedLegislators.length">({{ subSelectedLegislators.length }})</span>
              </div>
            </div>
            <v-autocomplete
              v-if="client && client.state && client.state.has_subcommittees"
              v-model="subSelectedLegislators"
              :items="sortSubLegislatorsList()"
              outlined
              dense
              color="blue-grey lighten-2"
              label="Select legislators"
              item-text="fullname"
              multiple
              return-object
              :disabled="loading"
              :loading="loading"
              :search-input.sync="searchInputSub"
              @change="searchInputSub=''"
              hide-details
            >
              <template v-slot:selection="{item}"></template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar class="avatar_image">
                    <img :src="getThumbImage(data.item.official_image)">
                  </v-list-item-avatar>
                  <v-icon v-if="data.item.lame_duck" color="#EECB23">fa-solid fa-duck</v-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <div class="pt-1" v-if="subSelectedLegislators && subSelectedLegislators.length">
              <div class="d-flex flex-wrap">
                <div v-for="(item, index) of subSelectedLegislators">
                  <v-menu
                    v-model="displaySubSelected[getIndex(item.id, subSelectedLegislators)]"
                    :key="index"
                    bottom
                    left
                    :close-on-content-click="false"
                    origin="top left"
                    content-class="role_card"
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip
                        :color="getSubLegislatorChipColor(item)"
                        class="mt-2 mr-2"
                        :dark="getSubLegislatorChipColor(item) != 'default'"
                        pill
                        v-on="on"
                        :style=colorsLegislatorsParties(item)
                      >
                        <v-avatar left>
                          <v-img class="avatar_image" :src="getThumbImage(item.official_image)"></v-img>
                        </v-avatar>
                        <vip-chip
                          v-if="item.vip"
                          class="mr-2 mb-1"
                          :legislator="item"
                          :show="'chip_code'"
                        >
                        </vip-chip>
                        {{ item.fullname }}
                      </v-chip>
                    </template>
                    <v-card width="400" height="auto" class="d-flex">
                      <v-flex class="legislators_list__card--img">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              @click="removeLegislator(getIndex(item.id, subSelectedLegislators), subSelectedLegislators, item)"
                              class="legislators_remove_btn"
                              color="error"
                              v-on="on"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Remove from {{ committee.fullname }}</span>
                        </v-tooltip>
                        <v-img class="avatar_image" :src="getThumbImageMedium(item.official_image)"></v-img>
                      </v-flex>
                      <v-flex>
                        <v-flex class="d-flex justify-space-between">
                          <h2 class="mt-2">{{ item.fullname }}</h2>
                        </v-flex>
                        <v-flex>
                          <span>Membership:</span>
                          <v-radio-group v-model="getSubSelectedLegislator(item).subRole" class="mt-2" hide-details>
                            <v-flex class="radio_group_role mb-3">
                              <v-radio
                                v-for="membership in memberships"
                                :key="membership.id"
                                color="primary"
                                :label="membership.name"
                                :value="membership"
                                class="mb-2"
                                :disabled="checkSubChair(item, membership.id)"
                              ></v-radio>
                            </v-flex>
                          </v-radio-group>
                        </v-flex>
                      </v-flex>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="close()">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy" :disabled="isSaveNotAllowed"
                 @click="onUpdateCommittee">Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import ClientService from '@/services/ClientService'
import CommitteeService from '@/services/CommitteeService'

import swal from 'sweetalert2'
import VipChip from "@/components/legislators/vip-setting/vip-chip";
import _ from "lodash";

export default {
  name: "AssignLegislatorsModal",
  mixins: [CommitteeService, ClientService],
  components: {
    'vip-chip': VipChip,
  },
  props: ['client'],
  data() {
    return {
      legislatorList: [],
      isColorInputsReady: false,
      isModalOpen: false,
      committee: {
        shortname: '',
      },
      isBusy: false,
      selectedLegislators: [],
      subSelectedLegislators: [],
      loading: false,
      searchInputSub: '',
      searchInputFull: '',
      displaySelected: {},
      displaySubSelected: {},
      role: '',
      memberships: [],
      error: null
    }
  },
  computed: {
    getSelectedLegislators() {
      return this.selectedLegislators.sort((a, b) => a.lastname > b.lastname ? 1 : -1)
    },
    isSaveNotAllowed() {
      if (this.committee && this.committee.shortname) {
        return this.committee.shortname.length > 8
      }
      return false
    },
  },
  methods: {
    toggle(committee) {
      this.isModalOpen = true
      this.loading = true
      this.error = ''
      this.getMemberships()
      this.getList().then(() => {
        this.updateData(committee)
        this.filterLegislatorsList()
        this.formatLegislatorsList()
        this.getSelectedLegislatorsList()
        this.addFullRoleLegislator()
        this.getSubSelectedLegislatorsList()
        this.addSubRoleLegislator()
        this.formatCommitteesLegislatorsList()
        this.loading = false
      })
    },
    getMemberships() {
      this.getCommitteeMemberships(this.client.state_id).then(result => {
        this.memberships = result.data
      })
    },
    getIndex(id, array) {
      let index = array.findIndex(item => item.id === id)
      return index
    },
    checkSubChair(item, membershipId) {
      let result
      this.subSelectedLegislators.forEach(legislator => {
        if (legislator.subRole && legislator.subRole.id === 1 && membershipId === 1) {
          result = true
        }
      })
      if (item.memberships && item.memberships.some(membership => membership.pivot.subcommittee === 1 && membership.membership.id === 1)) {
        result = false
      }
      return result
    },
    close() {
      this.selectedLegislators = []
      this.subSelectedLegislators = []
      this.isColorInputsReady = false
      this.committee = {}
      this.isModalOpen = false
    },
    getList() {
      let clientId = this.isSuperAdmin ? this.client.id : this.userClientId

      let param = {
        order: 'lastname'
      }
      return this.getClientLegislators(clientId, param).then((response) => {
        this.legislatorList = response.data.data ? response.data.data : response.data
      })
    },
    updateData(committee) {
      this.committee.legislators = []
      this.subSelectedLegislators = []
      this.selectedLegislators = []
      this.committee = _.cloneDeep(committee)
      this.isColorInputsReady = true
    },
    filterLegislatorsList() {
      if (this.committee.type === 0) {
        this.legislatorList = this.legislatorList.filter(item => item.district.district.includes('H'))
      } else if (this.committee.type === 1) {
        this.legislatorList = this.legislatorList.filter(item => item.district.district.includes('S'))
      }
    },
    sortLegislatorsList() {
      let chairsArray = this.legislatorList
        .filter(legislator => legislator.memberships && legislator.memberships.some(membership => membership.id === 1 && membership.subcommittee === 0))
      let vicesArray = this.legislatorList
        .filter(legislator => (legislator.memberships && legislator.memberships.some(membership => membership.id === 2 && membership.subcommittee === 0)))
      let rankingArray = this.getSelectedLegislators
        .filter(legislator => (legislator.memberships && legislator.memberships.some(membership => membership.id === 3 && membership.subcommittee === 0)))
      let membersArray = this.legislatorList
        .filter(legislator => (legislator.memberships && legislator.memberships.some(membership => membership.id === 4 && membership.subcommittee === 0)))
      let othersArray = this.legislatorList.filter(legislator => (!legislator.memberships || legislator.memberships.length === 0))
      return [
        ...this.checkAndSortArray(chairsArray, 'Chair'),
        ...this.checkAndSortArray(vicesArray, 'Vice Chair'),
        ...this.checkAndSortArray(rankingArray, 'Ranking'),
        ...this.checkAndSortArray(membersArray, 'Member Only'),
        ...this.checkAndSortArray(othersArray, 'Others')
      ]
    },
    sortSubLegislatorsList() {
      let chairsArray = this.getSelectedLegislators.filter(legislator => legislator.subRole && legislator.subRole.id === 1)
      let vicesArray = this.getSelectedLegislators.filter(legislator => legislator.subRole && legislator.subRole.id === 2)
      let rankingArray = this.getSelectedLegislators.filter(legislator => legislator.subRole && legislator.subRole.id === 3)
      let membersArray = this.getSelectedLegislators.filter(legislator => legislator.subRole && legislator.subRole.id === 4)
      let othersArray = this.getSelectedLegislators.filter(legislator => !legislator.subRole)
      return [
        ...this.checkAndSortArray(chairsArray, 'Chair'),
        ...this.checkAndSortArray(vicesArray, 'Vice Chair'),
        ...this.checkAndSortArray(rankingArray, 'Ranking'),
        ...this.checkAndSortArray(membersArray, 'Member Only'),
        ...this.checkAndSortArray(othersArray, 'Others')
      ]
    },
    checkAndSortArray(array, name) {
      if (array.length) {
        array.sort((a, b) => a.lastname > b.lastname ? 1 : -1)
        array.push({ divider: true })
        array.unshift({ header: name })
      }
      return array
    },
    getLegislatorChipColor(item) {
      let legislator = this.selectedLegislators.find(legislator => legislator.id == item.id)
      return legislator.fullRole.color
    },
    getSubLegislatorChipColor(item) {
      let legislator = this.selectedLegislators.find(legislator => legislator.id == item.id)
      return legislator.subRole.color
    },
    formatLegislatorsList() {
      this.legislatorList.map(legislator => {
        return this.committee.legislator_committees.forEach(legislatorCommittees => {
          if (legislator.id == legislatorCommittees.legislator_id) {
            legislator.memberships = legislatorCommittees.membership_states.map(membershipState => {
              return { ...membershipState.membership, subcommittee: membershipState.pivot.subcommittee }
            })
          }
        })
      })
    },
    getSelectedLegislatorsList() {
      this.committee.legislator_committees.forEach(legislatorCommittee => {
        if (legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 0)) {
          const legislator = {
            ...legislatorCommittee.legislator,
            memberships: legislatorCommittee.membership_states
          }
          this.selectedLegislators.push(legislator)
        }
      })
    },
    addFullRoleLegislator() {
      this.selectedLegislators.map(legislator => {
        if (!legislator.fullRole) {
          legislator.fullRole = legislator.memberships.find(membership => membership.pivot.subcommittee === 0)
          if (legislator.fullRole) {
            legislator.fullRole = legislator.fullRole.membership
          }
        }
        return legislator
      })
      this.legislatorList.map(item => {
        item.memberships = []
        this.selectedLegislators.forEach(legislator => {
          if (item.id === legislator.id) {
            item.memberships = [legislator.fullRole]
          }
        })
      })
      this.sortLegislatorsList()
    },
    formatCommitteesLegislatorsList() {
      this.committee.legislators = this.committee.legislator_committees.map(item => {
        return {
          id: item.legislator_id,
          memberships: item.membership_states
        }
      })
    },
    getSelectedLegislator(item) {
      return this.selectedLegislators.find(legislator => item.id == legislator.id)
    },
    getSubSelectedLegislator(item) {
      return this.subSelectedLegislators.find(legislator => item.id == legislator.id)
    },
    checkSelectedLegislatorsList() {
      this.selectedLegislators.map(legislator => {
        if (!legislator.fullRole) {
          legislator.fullRole = this.memberships.find(membership => membership.id === 4)
        }
        return legislator
      })
    },
    checkSubSelectedLegislatorsList() {
      this.subSelectedLegislators.map(legislator => {
        if (!legislator.subRole) {
          legislator.subRole = this.memberships.find(membership => membership.id === 4)
        }
        return legislator
      })
    },
    getSubSelectedLegislatorsList() {
      this.selectedLegislators.forEach(item => {
        if (item.memberships && item.memberships.some(membership => membership.pivot.subcommittee === 1)) {
          this.subSelectedLegislators.push(item)
        }
      })
    },
    addSubRoleLegislator() {
      this.subSelectedLegislators.map(legislator => {
        if (!legislator.subRole) {
          legislator.subRole = legislator.memberships.find(membership => membership.pivot.subcommittee === 1)
          if (legislator.subRole) {
            legislator.subRole = legislator.subRole.membership
          }
        }
        return legislator
      })
      this.sortSubLegislatorsList()
    },
    removeLegislator(index, array, item) {
      swal({
        title: `Remove ${ item.fullname } from ${ this.committee.fullname }?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#9E9E9E',
        confirmButtonText: '&#10003 Remove',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          array.splice(index, 1)
        }
      })
    },
    formatLegislators() {
      this.committee.legislators = []
      const legislators = _.cloneDeep(this.selectedLegislators)
      legislators.map(legislator => {
        if (legislator.memberships.length < 1) {
          legislator.memberships = [this.memberships.find(membership => membership.id === 1)]
        } else {
          legislator.memberships = [{
            id: legislator.fullRole.id,
            sub: 0
          }]
        }
        this.subSelectedLegislators.forEach(item => {
          if (item.id === legislator.id) {
            let suRole = {
              id: item.subRole.id,
              sub: 1
            }
            legislator.memberships.push(suRole)
          }
        })
        return legislator
      })
      legislators.forEach(legislator => this.committee.legislators.push({
        id: legislator.id,
        memberships: legislator.memberships
      }))
    },
    onUpdateCommittee() {
      this.isBusy = true
      this.formatLegislators()
      this.updateCommittee(this.committee)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'Committee info has been updated.', 'Success!')
          this.isModalOpen = false
          this.selectedLegislators = []
          this.subSelectedLegislators = []
          this.committee = {}
          this.$emit('update')
        })
        .catch((err) => {
          this.isBusy = false
          this.handleError(err)
        })
    },
    updateSubSelectedLegislators() {
      this.subSelectedLegislators = this.subSelectedLegislators
        .filter(legislator => this.getSelectedLegislators
          .some(item => item.id == legislator.id))
        .sort((a, b) => a.lastname > b.lastname ? 1 : -1)
    },
    changeColor(event) {
      let hex = event.target.value
      this.committee.color = hex

      this.colorLuminance(hex, -0.5)
    },
    colorLuminance(hex, lum) {
      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, '');
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      let rgb = "#", c, i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }

      this.committee.subcommittee_color = rgb
    }
  },
  watch: {
    'selectedLegislators': {
      handler: function () {
        this.checkSelectedLegislatorsList()
        this.addFullRoleLegislator()
        this.updateSubSelectedLegislators()
      },
      deep: true
    },
    subSelectedLegislators: function () {
      this.checkSubSelectedLegislatorsList()
      this.addSubRoleLegislator()
    }
  },
}
</script>
