import Router from 'vue-router'

import tokenStorage from '@/utils/storage/token'
import userStorage from '@/utils/storage/user'
import profileUpdateStorage from '@/utils/storage/profile-update'

import * as pages from '@/pages'
import * as layouts from '@/layouts'

import defaultRoutes from '@/router/default-routes'

const routes = [
  {
    path: '/s',
    name: 'ShorterLayout',
    component: layouts.shorter,
    children: [
      {
        path: '/s',
        name: 'shorter',
        component: pages.dashboard.shorter,
      },
    ]
  },
  {
    path: '/login',
    name: 'LoginLayout',
    component: layouts.login,
    children: [
      {
        path: '/login',
        name: 'login',
        component: pages.auth.login,
      },
      {
        path: '/password/email',
        name: 'forgot-password-page',
        component: pages.auth.forgotPassword,
      },
      {
        path: '/password/reset/:token',
        name: 'reset-password-page',
        component: pages.auth.resetPassword,
      },
      {
        path: '/register/:token',
        name: 'register-page',
        component: pages.auth.register,
      },
      {
        path: '/ez/:token',
        name: 'login-as-member-page',
        component: pages.auth.loginAsMember,
      }
    ]
  },
  {
    path: '/messages',
    name: 'MessagesLayout',
    component: layouts.messages,
    children: [
      {
        path: '/messages',
        name: 'messages-page',
        component: pages.dashboard.messages,
        beforeEnter(to, from, next) {
          routeMiddleware(['VIP', 'Coordinator'], next, checkTariff(`messages`))
        }
      },
      {
        path: '/messages/scheduled',
        name: 'scheduled-messages-page',
        component: pages.dashboard.scheduled,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Coordinator'], next, checkTariff(`messages`))
        }
      },
      {
        path: '/messages/history',
        name: 'history-messages-page',
        component: pages.dashboard.scheduled,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Coordinator'], next, checkTariff(`messages`))
        }
      },
    ]
  },
  {
    path: '/',
    component: layouts.default,
    children: [
      {
        path: 'kp-help',
        name: 'kp-help-page',
        component: pages.dashboard.kpHelp,
        beforeEnter(to, from, next) {
          routeMiddleware(['KP'], next)
        }
      },
      {
        path: 'home',
        name: 'home-page',
        component: pages.dashboard.home,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'kp-leaderboard',
        name: 'kp-leaderboard-page',
        component: pages.dashboard.leaderboard,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'profile-updates',
        name: 'profile-updates-page',
        component: pages.dashboard.profileUpdates,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'clients',
        name: 'clients-page',
        component: pages.dashboard.clients,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin'], next)
        }
      },
      {
        path: 'level-admin',
        name: 'level-admin-page',
        component: pages.dashboard.levelAdmin,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin'], next)
        }
      },
      {
        path: 'admin-messages',
        name: 'admin-messages-page',
        component: pages.dashboard.adminMessages,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin'], next)
        }
      },
      {
        path: 'committees',
        name: 'committees-page',
        component: pages.dashboard.committees,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'client-committees',
        name: 'client-committees-page',
        component: pages.dashboard.clientCommittees,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'resources',
        name: 'resources-page',
        component: pages.dashboard.resources,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'articles',
        name: 'articles-page',
        component: pages.dashboard.articles,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Coordinator', 'KP'], next)
        }
      },
      {
        path: 'user-sessions',
        name: 'user-sessions-page',
        component: pages.dashboard.userSessions,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin'], next)
        }
      },
      {
        path: 'find-legislators',
        name: 'find-legislators-page',
        component: pages.dashboard.findLegislators,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin'], next)
        }
      },
      {
        path: 'users',
        name: 'users-page',
        component: pages.dashboard.users,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'sessions',
        name: 'sessions-page',
        component: pages.dashboard.sessions,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'my-kps',
        name: 'my-kps-page',
        component: pages.dashboard.myKP,
        beforeEnter(to, from, next) {
          routeMiddleware(['VIP', 'Coordinator'], next)
        }
      },
      {
        path: 'members',
        name: 'members-page',
        component: pages.dashboard.members,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'legislators',
        name: 'legislators-page',
        component: pages.dashboard.legislators,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Leg Support'], next)
        }
      },
      {
        path: 'dashboard',
        name: 'kp-tasks-page',
        component: pages.dashboard.kpTasks,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Coordinator'], next)
        }
      },
      {
        path: 'district-lookup',
        name: 'district-lookup-page',
        component: pages.dashboard.districtLookup,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'KP'], next)
        }
      },
      {
        path: 'advocacy',
        name: 'advocacy-page',
        component: pages.dashboard.advocacy,
        beforeEnter(to, from, next) {
          routeMiddleware(['VIP', 'Super Admin'], next, checkTariff(`advocacy_messages`))
        }
      },
      {
        path: 'my-tasks',
        name: 'my-tasks-page',
        component: pages.dashboard.myTasks,
        beforeEnter(to, from, next) {
          routeMiddleware(['VIP', 'Coordinator', 'KP'], next)
        }
      },
      {
        path: 'assigned-tasks',
        name: 'assigned-tasks-page',
        component: pages.dashboard.assignedTasks,
        beforeEnter(to, from, next) {
          routeMiddleware(['KP', 'Member'], next)
        }
      },
      {
        path: 'how-to',
        name: 'how-to-page',
        component: pages.dashboard.howTo,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Coordinator', 'KP'], next)
        }
      },
      {
        path: 'relationships',
        name: 'relationships-page',
        component: pages.dashboard.relationships,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Coordinator'], next)
        }
      },
      {
        path: 'custom-fields',
        name: 'custom-fields-page',
        component: pages.dashboard.customFields,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Coordinator'], next)
        }
      },
      {
        path: 'political-leanings',
        name: 'political-leanings-page',
        component: pages.dashboard.politicalLeanings,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin'], next)
        }
      },
      {
        path: 'regions',
        name: 'regions-page',
        component: pages.dashboard.regions,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Coordinator'], next)
        }
      },
      {
        path: 'command',
        name: 'general-page',
        component: pages.dashboard.command,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin'], next)
        }
      },
      {
        path: 'helpers',
        name: 'helpers-page',
        component: pages.dashboard.helpers,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin'], next)
        }
      },
      {
        path: 'districts',
        name: 'districts-page',
        component: pages.dashboard.districts,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin'], next)
        }
      },
      {
        path: 'system-actions',
        name: 'system-actions-page',
        component: pages.dashboard.systemActions,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Coordinator'], next)
        }
      },
      {
        path: 'client-options',
        name: 'client-options-page',
        component: pages.dashboard.clientOptions,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP', 'Coordinator', 'Leg Support'], next)
        }
      },
      {
        path: 'import-members',
        name: 'import-members-page',
        component: pages.dashboard.importMembers,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'custom-messages',
        name: 'custom-messages-page',
        component: pages.dashboard.customMessages,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
      {
        path: 'api-sources',
        name: 'api-sources',
        component: pages.dashboard.apiSources,
        beforeEnter(to, from, next) {
          routeMiddleware(['Super Admin', 'VIP'], next)
        }
      },
    ]
  },
  {
    path: '/',
    name: 'EzLoginLayout',
    component: layouts.ezLogin,
    children: [
      {
        path: '/profile-update',
        name: 'profile-update-page',
        component: pages.auth.profileUpdate,
      },
    ]
  },

];

const router = new Router({routes, mode: 'history', base: '/'});

let userRole = userStorage.get() ? userStorage.get().role : false
let serviceType = userStorage.get() ? userStorage.get().serviceType : false

const checkTariff = (variable) => {
  return userRole === 'Super Admin' || (userStorage.get() && userStorage.get().client && userStorage.get().client.tariff && userStorage.get().client.tariff[variable])
}

const routeMiddleware = (allowedRoleList, next, tariffVar = true) => {
  if (!tariffVar) {
    return next(defaultRoutes[userRole])
  }

  if (allowedRoleList.includes(userRole)) return next()

  if (userRole == 'KP') {
    let route = serviceType == 'adv' ? '/assigned-tasks' : '/my-tasks'
    return next(route)
  }

  return next(defaultRoutes[userRole])
}

const whiteList = ['shorter', 'login', 'forgot-password-page', 'reset-password-page', 'register-page', 'login-as-member-page']

router.beforeEach(async (to, from, next) => {
  let user = userStorage.get()
  let token = tokenStorage.get()
  let profileUpdate = profileUpdateStorage.get()

  if (profileUpdate) {
    if (to.name !== 'profile-update-page') {
      next({ name: 'profile-update-page' })
      return;
    }
  }

  if (to.name == undefined) {
    next(defaultRoutes[userRole])
    return;
  }

  if (whiteList.includes(to.name)) {
    if (user && to.name == 'login') {
      next(defaultRoutes[userRole])
      return;
    }
    next()
    return;
  }

  if (!token) {
    user = false
    userStorage.remove()
  }

  if (!user) {
    if (to.name === 'messages-page') {
      next({name: 'login', query: { redirectTo: 'messages-page'}})
      tokenStorage.remove()
      return;
    }
    next({name: 'login'})
    tokenStorage.remove()
    return;
  } else {
    userRole = user.role
    serviceType = user.serviceType
  }

  next()
})

export default router
