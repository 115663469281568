<template>
  <articles-index></articles-index>
</template>

<script>
 import ArticleIndex from '@/components/articles/articles-index'

 export default {
   name: 'ArticlesPage',
   components: {'articles-index': ArticleIndex}
 }
</script>
