import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"800px","max-height":"auto"},model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Support "),_c(VSpacer),_c(VBtn,{staticClass:"c-card-title-close--popover",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.closeModal()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")]),_c('span',{staticClass:"d-sr-only"},[_vm._v("Close")])],1)],1),_c('div',{staticClass:"d-flex align-center justify-center register-page_form__inner mt-8 overflow-hidden"},[_c('div',{staticClass:"register-page_form__inner_major",class:_vm.isDarkTheme ? 'card-dark' : 'card-light'},[(_vm.userClient && _vm.userClient.tariff && _vm.userClient.tariff.name === 'Professional')?_c(VLayout,{attrs:{"row":""}},_vm._l((_vm.items),function(item,index){return _c(VFlex,{key:index,staticClass:"register-page_person mr-0",attrs:{"col-sm-4":"","col-md-4":"","col-12":""}},[_c(VBtn,{on:{"click":function($event){return _vm.selectOption(item)}}},[_c('div',{staticClass:"text-wrap mt-4"},[_vm._v(_vm._s(item.title)),(item.cell)?_c('p',{staticClass:"mt-4"},[_vm._v("+1 888 970 1899")]):_vm._e()])])],1)}),1):(_vm.userClient && _vm.userClient.tariff && _vm.userClient.tariff.name === 'Premium')?_c(VLayout,{attrs:{"row":""}},_vm._l((_vm.items),function(item,index){return _c(VFlex,{key:index,staticClass:"register-page_person mr-0",attrs:{"col-sm-6":"","col-md-6":"","col-12":""}},[_c(VBtn,{on:{"click":function($event){return _vm.selectOption(item)}}},[_c('div',{staticClass:"text-wrap"},[_vm._v(_vm._s(item.title))])])],1)}),1):_vm._e()],1)]),_c(VCardActions,[_c(VBtn,{attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancel ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }