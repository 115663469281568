import axios from 'axios'

export default {
  methods: {
    getSessions () {
      return axios.get('/sessions')
    },
    createSession (payload) {
      return axios.post('/sessions', payload)
    },
    updateSession (payload) {
      return axios.put(`/sessions/${payload.id}`, payload)
    },
    deleteSession (sessionId) {
      return axios.delete(`/sessions/${sessionId}`)
    },
    getSessionTask (sessionTaskId, params) {
      return axios.get(`/task/${sessionTaskId}`, {params})
    },
    getSessionTasks (sessionId) {
      return axios.get(`/sessions/${sessionId}/tasks`)
    },
    getSessionDashboardData (sessionId) {
      return axios.get(`/sessions/${sessionId}/dashboard-data`)
    },
    addSessionTasks (payload) {
      return axios.post(`/sessions/${payload.sessionId}/tasks`, payload)
    },
    getLatestCompletedTasks (sessionId, params) {
      return axios.get(`/sessions/${sessionId}/tasks/latest-completed`, {params})
    },
    inactiveKP (sessionId, params) {
      return axios.get(`/sessions/${sessionId}/inactive-kps`, {params})
    },
    sortSessionTasks (payload) {
      return axios.post(`/sessions/tasks/sort`, payload)
    },
    getRegionsProgress (sessionId, params = '') {
      return axios.get(`/sessions/${sessionId}/region-progress`, {params})
    },
    getTasks (clientId, params = '') {
      return axios.get(`/clients/${clientId}/get-session-tasks`, {params})
    },
  }
}
