<template>
  <div>
    <v-form ref="textForm">
      <v-card-text class="pb-0">
        <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
        <v-alert type="error" outlined :value="true" v-if="badCells.length">
          Members with a red icon have an undeliverable phone number and will not receive this text message.
        </v-alert>

        <v-layout wrap>
          <v-flex xs12 class="mb-4">
            <v-expansion-panels v-model="isKpsOpen">
              <v-expansion-panel>
                <v-expansion-panel-header>Recipients ({{ selectedMembers.length }})</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-select
                    dense
                    outlined
                    return-object
                    :items="filteredCoordinators"
                    v-model="selectedMembers"
                    item-text="fullname"
                    multiple
                    label="Select Coordinator(s)"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip
                        :style="kpPreferenceBorder(item)"
                        :key="item.id"
                        close
                        @click:close="() => selectedMembers.splice(index, 1)"
                      >
                        <v-icon
                          v-if="item.bad_cell"
                          left
                          size="16"
                          color="error">
                          no_cell
                        </v-icon>
                        <v-icon
                          v-else
                          left
                          size="16"
                          color="primary"
                        >
                          phone_iphone
                        </v-icon>
                        {{ item.fullname }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
          <v-flex xs12 class="d-flex justify-space-between mb-2">
            <div class="pt-3 caption grey--text">Message*</div>
            <v-switch
              color="primary"
              hide-details
              :label="isPreview ? 'Edit' : 'Show Preview'"
              class="d-inline-block mt-0"
              @change="onPreviewMessage"
              :loading="isBusy"
              :disabled="isBusy"
              v-model="isPreview"
            >
            </v-switch>
          </v-flex>
          <v-flex xs12>
            <div class="tiny_editor" v-show="!isPreview">
              <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
              <v-textarea
                v-if="!isBusy"
                id="smsInput"
                ref="smsInput"
                v-model="email.message"
                label="Message*"
                hide-details
                outlined
                :rules="messageRules"
                @click="onChangeText"
                @input="onChangeText"
              ></v-textarea>
            </div>
            <div v-show="isPreview">
              <v-textarea
                v-if="!isBusy"
                :value="formattedMessage"
                label="Message*"
                hide-details
                disabled
                outlined
              ></v-textarea>
            </div>
            <div id="editorContainer" class="d-flex justify-end mt-2">
              <a v-if="email.message" @click="onOpenTestMessageModal" class="mr-4">
                Send test to {{ isEmail ? userData.email : userData.cell_phone }}
              </a>
              <span class="text-counter" :class="finalMessageLength > 320 ? 'red--text' : ''">{{ finalMessageLength }}/320</span>
            </div>
          </v-flex>
          <div v-if="!isSendNow" class="mt-2 px-2 full-width">
            <div class="caption grey--text">Schedule</div>
            <v-row>
              <v-col
                class="py-0"
                cols="12"
                sm="12"
                md="6"
              >
                <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="date"
                      label="Select date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="date = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    :min="currentDate"
                    no-title
                    scrollable
                    @change="$refs.dateMenu.save(date)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                sm="12"
                md="6"
              >
                <v-menu
                  ref="timeMenu"
                  v-model="timeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  :disabled="!date"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="getFormattedTime"
                      label="Select time"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click.clear="time = null"
                      :disabled="!date"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeMenu"
                    v-model="time"
                    :min="currentDate == date ? currentTime : ''"
                    full-width
                    ampm-in-title
                    @click:minute="$refs.timeMenu.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-layout>
      </v-card-text>
    </v-form>
    <TestMessageModal
      ref="TestMessageModal"
      :isEmail="isEmail"
      :legislatorId="legislatorId"
      :memberId="memberId"
      :email="email"
    >
    </TestMessageModal>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"

import AppService from '@/services/AppService'
import ClientService from '@/services/ClientService'
import he from "he";
import TestMessageModal from '@/components/global/test-message-modal'
import ShorterUrl from "@/mixins/shorterUrl";

export default {
  name: "TextCoordinatorMessage",
  props: {
    client: {
      type: Object,
      default: {}
    },
    clientId: {
      type: Number,
      default: ''
    },
    coordinators: {
      type: Array,
      default: []
    },
    legislators: {
      type: Array,
      default: []
    },
    isSendNow: {
      type: Boolean,
      default: true
    },
    isSendCopiesToAdmins: {
      type: Boolean,
      default: false
    },
    scheduledMessage: {
      type: Object,
      default: null
    },
    isEmail: {
      type: Boolean,
      default: false
    },
  },
  mixins: [AppService, ClientService, TinyConfig, ShorterUrl],
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE
    TestMessageModal
  },
  data() {
    return {
      text: {},
      email: {},
      message: '',
      isPreview: false,
      formattedMessage: '',
      error: '',
      isBusy: false,
      totalLegislatorsList: [],
      selectedMembers: [],
      selectedCoordinatorsContacts: [],
      time: null,
      timeMenu: false,
      dateMenu: false,
      date: null,
      isKpsOpen: false,
      formattedTime: '',
      filteredCoordinators: []
    }
  },
  computed: {
    timeValidate() {
      if (this.time) {
        let curTime = this.currentTime.split(':')
        let selectedTime = this.time ? this.time.split(':') : ''
        if (selectedTime[0] && selectedTime[0] < curTime[0]) {
          return false
        } else if (selectedTime[0] == curTime[0]) {
          if (selectedTime[1] <= curTime[1]) {
            return false
          } else {
            return true
          }
        }
      }
      return true
    },
    currentTime() {
      const timezone = this.getTimezone(this.client);
      return moment().tz(timezone).add(1, 'hour').format('HH:mm');
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    nextDay() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      return date.toISOString().substr(0, 10)
    },
    badCells() {
      return this.selectedMembers.filter(member => member.bad_cell)
    },
    formattedDate() {
      return this.date ? moment(this.date).format('dddd, MMMM Do YYYY') : ''
    },
    getFormattedTime: {
      get() {
        return this.time ? moment(this.time, "hh:mm").format('h:mm a') : ''
      },
      set(value) {
        this.formattedTime = value
      },
    },
    messageRules() {
      return [
        v => !!v || 'Message is required.',
        v => (v && this.finalMessageLength <= 320) || 'Message should be less than 320 characters.',
      ]
    },
    replyToEmailList() {
      const emailList = []
      if (this.client && this.client.reply_to_email) {
        emailList.push(this.client.reply_to_email)
      }
      emailList.push(this.userEmail)
      return emailList
    },
    getLegislatorList: {
      get: function () {
        return this.legislators
      },
      set: function (newValue) {
        this.totalLegislatorsList = newValue
      }
    },
    finalMessageLength() {
      let nickName = ''
      this.totalLegislatorsList.forEach((legislator) => {
        if (nickName.length < (legislator.fullDistrict.region.member.nickname || '').length)
          nickName = legislator.fullDistrict.region.member.nickname
      })
      let message = this.email.message || ''
      message = message.replace(/{nickname}/g, nickName)
      this.$emit('getFinalMessageLength', this.html2Text(message).length)

      return this.html2Text(message).length
    },
    isSms() {
      return this.email.is_sms
    },
    legislatorId() {
      return this.legislators[0].id
    },
    memberId() {
      return this.selectedMembers.length ? this.selectedMembers[0].id : 0
    }
  },
  methods: {
    toggle(clientId) {
      this.isPreview = false
      this.error = ''
      this.toFilterCoordinators()
      if (this.$refs.textForm) this.$refs.textForm.reset()

      this.$nextTick().then(() => {
        this.text = {
          clientId,
          is_kps_send: 0,
          tos: [],
        }

        this.getSelectedCoordinatorsContacts()
        this.onChangeText()
      })
    },
    getSelectedCoordinatorsContacts() {
      this.email.tos = []
      this.text.tos = []
      this.$emit('changeCount', this.selectedMembers.length)

      this.selectedMembers.forEach((coordinator) => {
        if (coordinator.cell) {
          this.text.tos.push(coordinator.cell)
        } else if (coordinator.email) {
          this.email.tos.push(coordinator.email)
        }
      })
    },
    toggleOnScheduleMessagePage(clientId, scheduledMessage) {
      this.isPreview = false
      this.error = ''
      this.filteredCoordinators = this.coordinators.filter(coordinator => coordinator.email)
      this.toFilterCoordinators()
      if (this.$refs.textForm) this.$refs.textForm.reset()

      this.$nextTick().then(() => {
        let deliveryAt = moment(scheduledMessage.delivery_at, 'YYYY-MM-DD HH:mm:ss')
        this.date = deliveryAt.format('YYYY-MM-DD')
        this.time = deliveryAt.format('HH:mm')
        this.email.message = scheduledMessage.message
        this.$emit('getMessage', this.email.message)

        this.text = {
          id: scheduledMessage.id,
          clientId,
          is_sms: true,
          is_kps_send: 0,
          send_copies_to_admins: scheduledMessage.is_admin_send,
          tos: scheduledMessage.tos,
          members: this.getArrayOfMembersIds(),
        }

        this.getSelectedCoordinatorsContacts()
      })

    },
    getArrayOfMembersIds() {
      return this.selectedMembers.filter(member => member.cell)
          .map(member => member.id)
    },
    toFilterCoordinators() {
      this.filteredCoordinators = this.coordinators
          .filter(coordinator => coordinator.cell)
          .filter(coordinator => !coordinator.silence)
      this.selectedMembers.push(...this.filteredCoordinators)
    },
    async onChangeText() {
      this.error = ''
      const $el = document.querySelector('#editorContainer .text-counter')
      $el.innerHTML = `${this.finalMessageLength} / 320`
      this.email.message = await this.parsUrl(this.email.message)
      this.disabledEditor = false
    },
    async onPreviewMessage() {
      if (this.email.message) {
        this.isBusy = true
        this.$emit('changeIsBusy', true)
        this.formattedMessage = ''
        const payload = {
          legislator_id: this.legislatorId,
          member_id: this.memberId,
          message: this.email.message,
        }

        this.formattedMessage = (await this.getAppFormattedMessage(payload)).data
        this.isBusy = false
        this.$emit('changeIsBusy', false)
      }
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
          re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    async sendMessage() {
      if (this.scheduledMessage && this.scheduledMessage.id) {
        await this.sendClientScheduleMessage(this.scheduledMessage.id, this.text)
      } else {
        await this.saveClientScheduleMessage(this.text)
      }
    },
    async onSend() {
      if (!this.$refs.textForm.validate()) return
      this.isBusy = true
      this.$emit('changeIsBusy', true)
      this.message = this.html2Text(this.email.message)
      this.text.is_sms = 1
      try {
        if (this.date && this.time) {
          this.text['delivery_at'] = `${this.date} ${this.time}`
        }
        this.text.message = this.message
        this.text.is_admin_send = this.isSendCopiesToAdmins ? +this.isSendCopiesToAdmins : 0
        await this.sendMessage()
        this.isBusy = false
        this.$emit('changeIsBusy', false)
        this.onCallToastr('success', 'Text has been sent.', 'Success!')
        this.$emit('update')
        this.closeModal()
      } catch (err) {
        this.isBusy = false
        this.$emit('changeIsBusy', false)
        this.error = 'Something went wrong!'
        return
      }
    },
    async onSendSchedule() {
      if (!this.$refs.textForm.validate()) return
      this.isBusy = true
      this.$emit('changeIsBusy', true)

      this.message = this.html2Text(this.email.message)
      try {
        this.text.delivery_at = `${this.date} ${this.time}`
        this.text.is_sms = true
        this.text.is_admin_send = this.isSendCopiesToAdmins ? +this.isSendCopiesToAdmins : 0
        this.text.message = this.message
        this.text.members = this.getArrayOfMembersIds()
        this.text.to_regional_coordinators = true
        await this.saveClientScheduleMessage(this.text)
        this.isBusy = false
        this.$emit('changeIsBusy', false)
        this.onCallToastr('success', 'Text has been scheduled.', 'Success!')
        this.closeModal()
      } catch (err) {
        this.isBusy = false
        this.$emit('changeIsBusy', false)
        this.error = 'Something went wrong!'
        return
      }
    },
    closeModal() {
      this.$emit('close')
    },
    onOpenTestMessageModal() {
      this.$refs.TestMessageModal.open()
    },
  },
  watch: {
    selectedMembers: function () {
      this.text.members = this.getArrayOfMembersIds()
      this.getSelectedCoordinatorsContacts()
    },
    time: function () {
      this.$emit('getTime', this.time)
    },
    date: function () {
      this.$emit('getDate', this.date)
    },
    'email.message': function () {
      this.$emit('getMessage', this.email.message)
    },
  },
  mounted() {
    if (this.scheduledMessage) {
      this.toggleOnScheduleMessagePage(this.clientId, this.scheduledMessage)
    } else {
      this.toggle(this.clientId)
    }
  }
}
</script>
