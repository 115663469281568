<template>
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-progress-linear
        style="z-index: 6"
        color="warning"
        indeterminate
        rounded
        height="3"
        fixed
        top
        v-if="isLoading"
    ></v-progress-linear>
    <v-layout>
      <v-flex xs12 class="d-flex align-center flex-wrap">
        <h1>Custom Messages</h1>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="client && client.id" class="resources_page">
      <transition name="slide-list">
        <v-flex class="resources_table" xs12 md5 lg4 v-if="isDesktop || isDevice || (isMobile && !isShowOverview)">
          <v-card
              class="mx-auto resources_page_list"
              elevation="10"
          >
            <div v-if="isSuperAdmin || isVIP">
              <v-data-table
                  class="elevation-1"
                  :options.sync="pagination"
                  hide-default-footer
                  :headers="tableHeader"
                  :items="messageList"
                  :hide-default-header="isDesktop || isDevice"
              >
                <template v-slot:header="{ props: { headers } }" v-if="isDesktop || isDevice">
                  <thead>
                  <tr>
                    <th
                        v-for="(header, index) in headers"
                        :key="index"
                        :style="{'text-align': header.align, 'width': header.width ? header.width : 'unset'}"
                    >
                      <span v-if="header.text && !header.description">
                        {{ header.text }}
                      </span>
                      <v-tooltip bottom v-if="header.description">
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ header.text }}</span>
                        </template>
                        <span>{{ header.description }}</span>
                      </v-tooltip>
                    </th>
                  </tr>
                  </thead>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                  <a
                      class="resources_page_list__table-link"
                      @click="showMessages(item)"
                      :class="{ active: item.id == message.id}"
                  >
                    {{ item.name }}
                  </a>
                </template>

                <template v-slot:[`item.on_dlu`]="{ item }">
                  <div class="d-flex">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                            :color="item.on_dlu ? 'success' : 'grey'"
                            v-on="on"
                            small
                            class="mx-1"
                        >fa fa-light fa-magnifying-glass-location</v-icon>
                      </template>
                      <span>District Constituents</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                          <v-icon
                              :color="item.on_advocate ? 'success' : 'grey'"
                              v-on="on"
                              small
                              class="mx-1"
                          >fa-users</v-icon>
                      </template>
                      <span>Advocates</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                          <v-icon
                              :color="item.on_coordinator ? 'success' : 'grey'"
                              v-on="on"
                              small
                              class="mx-1"
                          >
                            fa-user-tie
                          </v-icon>
                      </template>
                      <span>Coordinators</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                          <v-icon
                              :color="item.on_kp_unique ? 'success' : 'grey'"
                              v-on="on"
                              small
                              class="mx-1"
                          >fa-solid fa-user-graduate</v-icon>
                      </template>
                      <span>KPs - 1 message per unique KP</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                            :color="item.on_kp ? 'success' : 'grey'"
                            v-on="on"
                            small
                            class="mx-1"
                        >fa-solid fa-users-between-lines</v-icon>
                      </template>
                      <span>KPs - 1 message per KP-Legislator pair</span>
                    </v-tooltip>
                  </div>
                </template>


                <template v-slot:[`item.allowed_to_admins`]>
                  <v-icon color="primary">visibility</v-icon>
                </template>

                <template v-slot:[`item.allowed_to_coordinators`]="{ item }">
                  <v-icon :color="item.allowed_to_coordinators ? 'primary' : ''">
                    {{ item.allowed_to_coordinators ? 'visibility' : 'visibility_off'}}
                  </v-icon>
                </template>

                <template v-slot:[`item.allowed_to_kps`]="{ item }">
                  <v-icon :color="item.allowed_to_kps ? 'primary' : ''">
                    {{ item.allowed_to_kps ? 'visibility' : 'visibility_off'}}
                  </v-icon>
                </template>

                <template v-slot:[`item.active`]="{ item }">
                  <v-icon :color="item.active ? 'success' : 'error'">
                    {{ item.active ? 'done' : 'close' }}
                  </v-icon>
                </template>

                <template v-slot:no-data>
                  <p class="text-xs-center mb-0">
                    No templates created yet.
                  </p>
                </template>
              </v-data-table>
            </div>

            <v-card-actions v-if="client.id" class="my-2">
              <v-btn :loading="isLoading" block small color="primary" @click="$refs.receiveModal.toggle()">
                <v-icon>add</v-icon>
                Add message
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </transition>
      <transition name="slide-resource">
        <v-flex class="resource_card" xs12 md7 lg8 v-if="isShowOverview">
          <v-card
              class="resources_page_item"
              elevation="10"
          >
            <v-card-title class="d-flex justify-space-between">
              <div class="resources_page_title d-flex">
                <v-btn class="to_list_button" icon @click="isShowOverview = false" v-if="isMobile">
                  <v-icon size="24">chevron_left</v-icon>
                </v-btn>
                <div>{{ `Available when sending to: ${typeMessage}` }}</div>
              </div>
              <v-spacer></v-spacer>
              <div class="d-flex" v-if="client.id">
                <v-switch
                    color="primary"
                    class="mt-0 mr-2"
                    v-model="message.active"
                    label="Active"
                    hide-details
                ></v-switch>
                <v-btn
                    v-if="((isVIP || isSuperAdmin)) && messageList.length && !(message.name === 'Do you know {leg_fullname}?' || message.name === 'Sample Donation Request') && !newMessage"
                    small
                    color="error"
                    @click="onDeleteTemplate()"
                    class="ml-2"
                    outlined
                    :disabled="isLoadingSave"
                    :loading="isLoadingDelete"
                >
                  Delete
                </v-btn>
                <v-btn
                    v-if="newMessage && !isMobile"
                    small
                    color="error"
                    @click="isShowOverview = false"
                    class="ml-2"
                    outlined
                >
                  Cancel
                </v-btn>
                <v-tooltip bottom v-if="((isVIP || isSuperAdmin)) && (message.name === 'Do you know {leg_fullname}?' || message.name === 'Sample Donation Request')">
                  <template v-slot:activator="{ on }">
                    <v-btn
                        class="disabled-button"
                        small
                        outlined
                        v-on="on"
                    >
                      Delete
                    </v-btn>
                  </template>
                  <span>Default messages cannot be deleted</span>
                </v-tooltip>
                <v-btn
                    small
                    color="success"
                    @click="saveUpdateMessageTemplate()"
                    class="ml-2"
                    :loading="isLoadingSave"
                    :disabled="isLoadingDelete || checkEmptyMessage.length < 1"
                >
                  Save
                </v-btn>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="client.id && (message.id || newMessage)">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12 lg6 class="pb-0">
                  <v-text-field
                      :disabled="message.name === 'Do you know {leg_fullname}?'"
                      v-model="message.name"
                      :error-messages="errorMessages('name')"
                      label="Title"
                  ></v-text-field>
                </v-flex>
                <v-flex class="d-flex resources_access" xs12 sm12 md12 lg6>
                  <v-spacer></v-spacer>
                  <v-flex>
                    <v-checkbox
                        color="primary"
                        hide-details
                        :label="'Admins'"
                        disabled
                        v-model="fakeCheckbox"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex v-if="client.uses_regions">
                    <v-checkbox
                        color="primary"
                        hide-details
                        label="Coordinators"
                        v-model="message.allowed_to_coordinators"
                        :error-messages="errorMessages('allowed_to_coordinators')"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex v-if="message.name === 'Sample Donation Request'">
                    <v-checkbox
                        color="primary"
                        hide-details
                        label="KPs"
                        v-model="message.allowed_to_kps"
                        :error-messages="errorMessages('allowed_to_kps')"
                    ></v-checkbox>
                  </v-flex>
                </v-flex>
                <!-- Start email subject field  -->
                <v-flex xs12 sm12 md12 lg12 class="pb-0">
                  <v-text-field
                      v-model="message.subject"
                      label="Email Subject"
                  ></v-text-field>
                </v-flex>
                <!-- End email subject field  -->
                <v-flex xs12 sm12 md12 lg12 class="pb-0">
                  <v-text-field
                      :disabled="message.name === 'Do you know {leg_fullname}?'"
                      v-model="message.description"
                      :error-messages="errorMessages('description')"
                      label="Description"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm8 class="d-flex align-center preview-switch px-2">
                  <div class="caption grey--text">Message*</div>
                  <v-spacer/>
                  <v-btn
                      v-if="badVariables"
                      class="mr-2"
                      x-small
                      outlined
                      color="primary"
                      @click="onCheckVariables"
                  >
                    Show Error
                  </v-btn>
                  <v-switch
                      color="primary"
                      class="d-inline-block pt-0 mt-0"
                      v-model="isPreview"
                      :label="isPreview ? 'Edit' : 'Show Preview'"
                      :loading="isLoading"
                      hide-details
                      :disabled="isLoading || !memberLegislator.id"
                      @change="onPreviewMessage"
                  >
                  </v-switch>
                </v-flex>
                <v-flex xs12 sm4 class="px-2">
                  <v-select
                      outlined
                      v-model="bodyVariable"
                      :items="variableList"
                      label="Body Variables"
                      hide-details
                      dense
                  >
                    <template v-slot:item="data">
                      <v-list-item-content  @click="onAddVariable(data.item)" v-text="data.item"></v-list-item-content>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <div>
                <p v-if="badVariables" class="red--text mb-0">You have bad variables! Please click on 'Show Error' button and fix them.</p>
                <div class="tiny_editor" v-show="!isPreview">
                  <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
                  <editor
                      ref="messageEditor"
                      :api-key="TINY_MCE_API_KEY"
                      :plugins="tinyPlugins"
                      :toolbar="tinyToolbars"
                      :init="tinyInit"
                      v-model="message.body"
                      @input="inputEmail"
                  />
                </div>
                <div v-show="isPreview">
                  <editor
                      :api-key="TINY_MCE_API_KEY"
                      :plugins="tinyPlugins"
                      :toolbar="tinyToolbars"
                      :init="tinyInit"
                      :value="formattedMessage"
                      disabled
                  />
                </div>
                <div v-if="errorMessages('text').length" class="red--text mt-1">
                  {{ errorMessages('text') }}
                </div>
                <div>
                </div>
              </div>
            </v-card-text>
            <v-card-text v-else>
              <v-alert class="mt-4" type="info" outlined :value="true">There are no templates yet.</v-alert>
            </v-card-text>
          </v-card>
        </v-flex>
      </transition>
    </v-layout>
    <receive-modal
        ref="receiveModal"
        @createMessage="addMessage"
    >
    </receive-modal>
  </v-container>
</template>

<script>
import ClientService from '@/services/ClientService'
import TinyConfig from "@/mixins/tiny-mce-config"
import swal from "sweetalert2";
import {mapGetters} from "vuex";
import CustomMessageService from "@/services/CustomMessageService";
import Editor from '@tinymce/tinymce-vue';
import ShorterUrl from "@/mixins/shorterUrl";
import ReceiveModal from "@/components/custom-messages/receive-modal";
import MemberService from "@/services/MemberService";

export default {
  name: "CustomMessagePage",
  mixins: [CustomMessageService, ClientService, TinyConfig, ShorterUrl, MemberService],
  components: {
    'editor': Editor,
    'receive-modal': ReceiveModal,
  },
  data() {
    return {
      errors: false,
      messageList: [],
      memberLegislator: {},
      fakeCheckbox: true,
      message: {
        subject: '',
        body: ''
      },
      text: '',
      bodyVariable: '',
      isLoading: false,
      isLoadingSave: false,
      isLoadingDelete: false,
      isLoadingSort: false,
      isPreview: false,
      newMessage: false,
      formattedMessage: '',
      pagination: {
        itemsPerPage: -1
      },
      isSelecting: false,
      isShowOverview: false
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    checkEmptyMessage() {
      return this.message.body
          .replace(/[&]nbsp[;]/gi, '')
          .replace(/<p>[\s]*<\/p>/gi, '')
    },
    typeMessage() {
      if (this.message.on_dlu) {
        return 'District Constituents'
      } else if (this.message.on_advocate) {
        return 'Advocates'
      } else if (this.message.on_coordinator) {
        return 'Coordinators'
      } else if (this.message.on_kp_unique) {
        return 'KPs - 1 message per unique KP'
      } else if (this.message.on_kp) {
        return 'KPs - 1 message per KP-Legislator pair'
      }
      return ''
    },
    variableList() {
      if (this.message.on_advocate) {
        return ['{sender_firstname}', '{sender_lastname}', '{sender_address}', '{sender_cell}', '{assoc_abbrev}', '{assoc_fullname}',
          '{client_profession}', '{advocate_first}', '{advocate_last}',
          '{update_profile_link}', '{home_house_leg_nick}', '{home_house_leg_last}','{home_house_leg_fullname}',
           '{home_house_leg_salutation}', '{home_house_leg_email}', '{home_house_leg_ph}','{home_house_leg_local_phone}',
           '{home_senate_leg_nick}', '{home_senate_leg_last}', '{home_senate_leg_fullname}','{home_senate_leg_salutation}',
          '{home_senate_leg_email}', '{home_senate_leg_ph}', '{home_senate_leg_local_phone}',
          '{work_house_leg_nick}', '{work_house_leg_last}', '{work_house_leg_fullname}', '{work_house_leg_salutation}',
          '{work_house_leg_email}', '{work_house_leg_ph}', '{work_house_leg_local_phone}', '{work_senate_leg_nick}',
          '{work_senate_leg_last}', '{work_senate_leg_fullname}', '{work_senate_leg_salutation}', '{work_senate_leg_email}',
          '{work_senate_leg_ph}', '{work_senate_leg_local_phone}']
      } else if (this.message.on_dlu) {
        return ['{sender_firstname}', '{sender_lastname}', '{sender_address}', '{sender_cell}', '{assoc_abbrev}',
          '{assoc_fullname}', '{client_profession}', '{leg_official_image}', '{leg_formal_address}',
          '{advocate_first}', '{district}', '{live/work}', '{leg_nick}', '{leg_fullname}', '{leg_bio}',
          '{leg_salutation}', '{leg_email}', '{leg_ph}', '{leg_local_phone}',]
      } else if (this.message.on_coordinator) {
        return ['{sender_firstname}', '{sender_lastname}', '{sender_address}', '{sender_cell}', '{assoc_abbrev}', '{assoc_fullname}',
          '{client_profession}', '{advocate_first}', '{advocate_last}']
      } else if (this.message.on_kp_unique) {
        return ['{sender_firstname}', '{sender_lastname}', '{sender_address}', '{sender_cell}', '{assoc_abbrev}', '{assoc_fullname}',
          '{client_profession}', '{ez_login}', '{kp_first}', '{kp_last}'];
      } else if (this.message.on_kp) {
        let variables = ['{sender_firstname}', '{sender_lastname}', '{sender_address}', '{sender_cell}', '{assoc_abbrev}', '{assoc_fullname}',
          '{client_profession}', '{leg_official_image}', '{leg_formal_address}', '{ez_login}', '{kp_first}', '{kp_last}', '{leg_nick}',
          '{leg_fullname}', '{leg_salutation}', '{leg_email}', '{leg_ph}', '{leg_local_phone}', '{leg_cell}', '{district}', '{live/work}'];
        let nickVariable = '{kp1_first'
        for (let i = 2; i <= this.client.uses_kps; i++) {
          if (this.client.uses_kps === i) {
            nickVariable = nickVariable + `/kp${i}_first}`
          } else {
            nickVariable = nickVariable + `/kp${i}_first`
          }
        }
        variables.push(nickVariable)
        return variables
      }
    },
    tableHeader() {
      return [
        {text: 'Title', value: 'name', align: !this.isMobile ? 'left' : 'center', sortable: false, 'min-width': '150px'},
        {text: 'Type',  value: 'on_dlu', align: 'center', sortable: false, 'min-width': '140px !important', width: '140px !important'},
        { text: 'Admin', description: 'Admin can view', value: 'allowed_to_admins', align: 'center', sortable: false },
        { text: 'Coord.', description: 'Coordinators can view', value: 'allowed_to_coordinators', align: 'center', sortable: false },
        { text: 'KP', description: 'KP can view', value: 'allowed_to_kps', align: 'center', sortable: false },
        {text: 'Status', value: 'active', align: 'center', sortable: false}
      ]
    },
  },
  methods: {
    async init() {
      this.badVariables = false
      if (!(this.client && this.client.id)) return
      this.memberLegislator = (await this.getOneOfKps(this.client.id)).data
      this.getList()
    },
    addMessage(type) {
      this.badVariables = false
      this.message = {
        active: true,
        subject: '',
        body: '',
        name: '',
        on_dlu: false,
        on_advocate: false,
        on_coordinator: false,
        on_kp: false,
        on_kp_unique: false,
      }

      if (type === 'dlu') {
        this.message.on_dlu = true
      } else if (type === 'advocate') {
        this.message.on_advocate = true
      } else if (type === 'coordinator') {
        this.message.on_coordinator = true
      } else if (type === 'unique-kp') {
        this.message.on_kp_unique = true
      } else if (type === 'kp') {
        this.message.on_kp = true
      }
      this.isShowOverview = true
      this.newMessage = true
    },
    async onPreviewMessage() {
      if (!this.isPreview || this.checkEmptyMessage.length < 1 || !this.memberLegislator.id) {
        return
      }
      this.isBusy = true

      let message = {
        member_id: this.memberLegislator.member_id,
        legislator_id: this.memberLegislator.leg_id,
        message: this.message.body
      }

      this.formattedMessage = (await this.getAppFormattedMessage(message)).data
      this.isBusy = false
    },
    getList() {
      this.isLoading = true
      this.badVariables = false
      this.message = {
        active: true,
        subject: '',
        body: '',
        name: '',
        on_dlu: false,
        on_advocate: false,
        on_coordinator: false,
        allowed_to_kps: false,
        allowed_to_coordinators: false,
        on_kp: false,
        on_kp_unique: false,
      }

      this.getCustomMessages(this.client.id, {no_active: true}).then(response => {
        this.messageList = response.data
        if (this.messageList.length && !this.message.id) {
          this.message = this.messageList[0]
        }
        this.body = this.message.body
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    onAddVariable(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);
    },
    showMessages(item) {
      this.newMessage = false
      this.isShowOverview = true
      this.message = _.cloneDeep(item)
      this.subject = this.message.subject
      this.body = this.message.body
      this.isPreview = false
    },
    saveUpdateMessageTemplate() {
      this.message.subject = this.removeHighlight(this.message.subject)
      this.message.body = this.removeHighlight(this.message.body)
      this.isLoading = this.isLoadingSave = true
      this.isLoading = false
      this.badVariables = false
      this.storeCustomMessages(this.client.id, this.message)
          .then((res) => {
            if (!this.message.id) {
              this.message.id = res.data.id
            }
            this.onCallToastr('success', 'Message Template has been save.', 'Success!')
            this.getList()
            this.isLoadingSave = false
          })
          .catch(err => {
            this.errors = err.response.data.errors
            this.onCallToastr('error', 'Something went wrong.', 'Error!')
            this.isLoading = this.isLoadingSave = false
          })
    },
    onCheckVariables() {
      this.checkVariablesForEditor(this.message.body, this.variableList)
    },
    async inputEmail() {
      this.$nextTick(async () => {
        this.searchIncorrectVariables(this.message.body, this.variableList)
        const newContent = await this.parsUrl(this.message.body)

        if (newContent !== this.message.body) {

          this.message.body = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    onDeleteTemplate() {
      let id = this.message.id
      swal({
        title: 'Are you sure to delete this Message Template?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: this.appTheme.accent,
        cancelButtonColor: this.appTheme.error,
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = this.isLoadingDelete = true
          this.deleteCustomMessage(this.client.id, id)
              .then(() => {
                this.onCallToastr('success', 'Message Template has been deleted.', 'Success!')
                this.getList()
                if (this.messageList && this.messageList.length > 0) {
                  this.message = this.messageList[0]
                } else {
                  this.addMessage()
                }
                this.isLoadingDelete = false
              })
              .catch(() => {
                this.onCallToastr('error', 'Something went wrong.', 'Error!')
                this.isLoading = this.isLoadingDelete = false
              })
        }
      })
    },
    errorMessages(field) {
      return (this.errors && this.errors[field]) ? this.errors[field][0] : []
    },
  },
  mounted() {
    this.init()
  },
  watch: {
    'client': function () {
      this.init()
    },
    badVariables: function () {
      this.searchIncorrectVariables(this.message.body, this.variableList)
      if (!this.badVariables) {
        this.checkVariablesForEditor(this.message.body, this.variableList)
      }
    }
  }
}
</script>

<style>


</style>