<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-navigation-drawer
    v-model="isModalOpen"
    class="legislator-assignments_modal"
    fixed
    temporary
    right
    touchless
    width="650"
  >
    <v-layout class="mx-0">
      <v-form ref="clientForm" @submit.prevent="onUpdateClient">
        <v-card style="box-shadow: none !important;">
          <v-card-title>
            <span class="title">Select the Committee(s)</span>
            <v-spacer/>
            <v-icon @click="isModalOpen = false">close</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
            <v-layout wrap>
              <v-layout wrap class="px-2">
                <v-flex xs12 class="px-2">
                  <v-alert type="info" :value="true" outlined class="mb-2">
                    Please select the Committee(s) that are important to your cause.
                    These will be used throughout your Dashboard - all others will be hidden, but available if you
                    change your mind later.
                  </v-alert>
                  <v-autocomplete
                    multiple
                    chips
                    label="House Committees To Track"
                    item-text="fullname"
                    item-value="id"
                    :items="houseCommitteeList"
                    v-model="client.house_committees"
                    v-if="client && client.state && client.state.house_districts">
                    <template v-slot:selection="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            label
                            dark
                            close
                            :input-value="props.selected"
                            :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                            class="chip--select-multi"
                            @click:close="props.parent.selectItem(props.item)"
                          >
                            {{ props.item.shortname }}
                          </v-chip>
                        </template>
                        <span>{{ props.item.fullname }}</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    multiple
                    chips
                    label="Senate Committees To Track"
                    item-text="fullname"
                    item-value="id"
                    :items="senateCommitteeList"
                    v-model="client.senate_committees"
                    v-if="client && client.state && client.state.sen_districts">
                    <template v-slot:selection="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            label
                            dark
                            close
                            :input-value="props.selected"
                            :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                            class="chip--select-multi"
                            @click:close="props.parent.selectItem(props.item)"
                          >
                            {{ props.item.shortname }}
                          </v-chip>
                        </template>
                        <span>{{ props.item.fullname }}</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    multiple
                    chips
                    label="Joint Committees To Track"
                    item-text="fullname"
                    item-value="id"
                    :items="jointCommitteeList"
                    v-model="client.joint_committees"
                    v-if="client && client.state && client.state.has_joint_committees">
                    <template v-slot:selection="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            label
                            dark
                            close
                            :input-value="props.selected"
                            :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                            class="chip--select-multi"
                            @click:close="props.parent.selectItem(props.item)"
                          >
                            {{ props.item.shortname }}
                          </v-chip>
                        </template>
                        <span>{{ props.item.fullname }}</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
            <v-btn type="submit" color="primary" :loading="isBusy">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import ClientService from '@/services/ClientService'
import CloudinaryService from '@/services/CloudinaryService'
import StateService from '@/services/StateService'
import {mapGetters} from "vuex";

export default {
  mixins: [ClientService, CloudinaryService, StateService],
  data() {
    return {
      committeeList: [],
      isModalOpen: false,
      client: null,
      error: '',
      isBusy: false,
      tabs: [
        {
          icon: 'account_balance',
          title: 'Committees',
        }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    selectedClient() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    houseCommitteeList() {
      return this.committeeList.filter(committee => committee.type === 0)
    },
    senateCommitteeList() {
      return this.committeeList.filter(committee => committee.type === 1)
    },
    jointCommitteeList() {
      return this.committeeList.filter(committee => committee.type === 2)
    },
  },
  methods: {
    async init() {
      this.isModalOpen = true
      this.error = ''
      this.committeeList = (await this.getStateCommittees(this.selectedClient.state_id)).data
      this.client = _.cloneDeep(this.selectedClient)
      if (!this.client.house_committees) this.client.house_committees = []
      if (!this.client.senate_committees) this.client.senate_committees = []
      if (!this.client.joint_committees) this.client.joint_committees = []
      this.client = _.cloneDeep(this.client)
    },
    onUpdateClient() {
      if (!this.$refs.clientForm.validate()) return
      this.isBusy = true
      this.updateClient(this.client)
          .then(() => {
            this.isBusy = false
            this.onCallToastr('success', 'Client info has been updated.', 'Success!')
            const userData = _.cloneDeep(this.userData)
            if (this.isSuperAdmin) {
              this.$store.dispatch('app/setSuperAdminSelectedClient', this.client)
            } else {
              userData.client = this.client
              this.$store.dispatch('auth/setUserData', userData)
            }
            this.isModalOpen = false
          })
          .catch(() => {
            this.isBusy = false
            this.error = 'Something went wrong!'
          })
    }
  },
};
</script>
