import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("My Messages")])]),_c(VDivider),_c(VCardText,{staticClass:"mt-3"},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"sort-by":"","options":_vm.pagination,"headers":_vm.statsTableHeader,"items":_vm.statsList,"disable-pagination":"","disable-sort":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.fullname",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(item.fullname))])]}},{key:"item.sent_at",fn:function({ item }){return [_c('div',[(item.sent_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateTimeFormatter")(item.sent_at)))]):_c(VIcon,{attrs:{"color":"error"}},[_vm._v("cancel")])],1)]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"text-xs-center mb-0"},[_vm._v("No stats yet.")])]},proxy:true}])})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},nativeOn:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }