<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1 class="heading">Profile Updates</h1>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <div v-if="client && client.id">
      <v-layout wrap class="py-3">
        <v-flex md10 class="d-flex align-center flex-wrap">
          <v-text-field
            prepend-inner-icon="search"
            label="Search Advocates"
            clearable
            class="search-input search-input_members"
            v-model="filter.search"
            hide-details
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-data-table
        v-model:expanded="expanded"
        :options.sync="list.pagination"
        :server-items-length="list.pagination.total_items"
        :headers="kpLeaderboardTableHeader"
        :items="list.items"
        :footer-props="tableFooter"
        show-expand
        item-key="id"
        @update:options="refresh"
      >
        <template v-slot:item.nickname="{ item }">
          <div class="d-flex align-center">
            <a class="mr-1 no-line-break" @click="$refs.memberDetailModal.toggle(item.member)">
              {{ item.member.nickname }} {{ item.member.lastname }}
            </a>
          </div>
        </template>

        <template v-slot:item.updated_at="{ item }">
          <div class="d-flex align-center">
            {{ formatDate(item.updated_at) }}
          </div>
        </template>

        <template v-slot:item.fields="{ item }">
          <div class="d-flex align-center">
            {{ item.fieldsString }}
          </div>
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="kpLeaderboardTableHeader.length">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Old Data</th>
                    <th>New Data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="field in item.fields" :key="field.field_name">
                    <td>{{ field.field_name }}</td>
                    <td><em>{{ field.old_value }}</em></td>
                    <td><strong>{{ field.new_value }}</strong></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>

      <member-detail-modal ref="memberDetailModal" :selected-client="client" @openDistrictModal="openDistrictModal"></member-detail-modal>
    </div>
  </v-container>
</template>

<script>
import ClientService from '@/services/ClientService'
import DistrictService from '@/services/DistrictService'
import leaderboardHelpModal from '@/components/help-modals/leaderboard-help-modal'
import MemberDetailModal from "@/components/members/member-detail-modal";
import districtModal from "@/components/common/district-modal";
import CustomFieldsFilter from '@/mixins/custom-fields-filter'
import { mapGetters } from "vuex";
import politicalLeaningsBorder from "@/mixins/politicalLeaningsBorder";
import MemberService from "@/services/MemberService";

export default {
  name: 'ProfileUpdatesPage',
  mixins: [ClientService, CustomFieldsFilter, DistrictService, MemberService, politicalLeaningsBorder],
  components: {
    'kp-leaderboard-page-help-modal': leaderboardHelpModal,
    'member-detail-modal': MemberDetailModal,
    'district-modal': districtModal,
  },
  data() {
    return {
      isLoading: false,
      expanded: [],
      kpPreferenceResults: [],
      politicalLearnings: [],
      list: {
        pagination: {
          page: 1,
          last_page: '',
          itemsPerPage: 10,
          total_items: null,
          sortBy: [],
          sortDesc: [],
        },
        items: []
      },
      filter: {
        search: "",
      },
      searchTimer: null,
      tableFooter: {
        'items-per-page-options': [10, 20, 40]
      },
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    kpLeaderboardTableHeader() {
      return [
        { text: 'Advocate', value: 'nickname', key: 'name' },
        { text: 'Updated', value: 'updated_at', align: 'center', key: 'updated_at' },
        { text: 'Field(s)', value: 'fields', align: 'center', sortable: false, key: 'fields' },
        { text: '', value: 'data-table-expand', sortable: false }
      ]
    },
    detailHeaders() {
      return [
        { text: 'Field', value: 'field' },
        { text: 'Old Data', value: 'old_data' },
        { text: 'New Data', value: 'new_data' }
      ];
    }
  },
  async mounted() {
    if (!(this.client && this.client.id)) return
    await this.refresh()
  },
  methods: {
    async refresh() {
      this.isLoading = true
      let params = {
        search: this.filter.search,
        page: this.list.pagination.page,
        limit: this.list.pagination.itemsPerPage,
        per_page: this.list.pagination.itemsPerPage,
        order: this.list.pagination.sortBy[0],
        direction: this.list.pagination.sortDesc[0] ? 'desc' : 'asc',
      }

      try {
        this.saveData(await this.getMemberProfileUpdates(this.client.id, params))
        this.isLoading = false
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
    },
    saveData(data) {
      this.list.items = data.data.data.map(member => {
        member.basic_change_log = JSON.parse(member.basic_change_log);
        member.custom_fields_change_log = JSON.parse(member.custom_fields_change_log);
        member.fields = member.basic_change_log.concat(member.custom_fields_change_log).map(field => {
          return {
            field_name: field.field_name,
            old_value: this.parseFieldValue(field.old_value),
            new_value: this.parseFieldValue(field.new_value),
          };
        });
        member.fieldsString = member.fields.map(item => item.field_name).join(', ');
        return member;
      });
      this.list.pagination.total_items = data.data.total ? data.data.total : data.data.length;
      this.list.pagination.from = data.data.current_page;
      this.list.pagination.itemsPerPage = parseInt(data.data.per_page);
      this.list.pagination.last_page = data.data.last_page;
    },

    parseFieldValue(value) {
      try {
        let parsed = JSON.parse(value);
        if (Array.isArray(parsed)) {
          parsed = parsed.map(item => {
            if (typeof item === 'object' && item !== null) {
              return item.name;
            }
            return item;
          });
          return parsed.join(', ');
        }
        while (typeof parsed === 'object' && parsed !== null) {
          if (Array.isArray(parsed)) {
            parsed = parsed.map(item => {
              if (typeof item === 'object' && item !== null) {
                return item.name;
              }
              return item;
            }).join(', ');
          } else {
            parsed = Object.values(parsed).join(', ');
          }
          try {
            parsed = JSON.parse(parsed);
          } catch (e) {
            break;
          }
        }
        return parsed;
      } catch (e) {
        return value;
      }
    },
  },
  watch: {
    'client': function () {
      this.refresh()
    },
    'filter.search'() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.list.pagination.page = 1
        this.refresh()
      }, 700)
    },
  },
}
</script>

