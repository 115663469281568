<template>
  <div style="height: 100%">
    <app-toolbar class="header"></app-toolbar>
    <v-main>
      <transition name="fade" mode="out-in" appear>
        <v-flex class="d-flex fill-height align-md-center justify-center">
          <router-view></router-view>
        </v-flex>
      </transition>
    </v-main>
  </div>
</template>

<script>
  import toolbar from '@/components/global/toolbar'

  export default {
    name: "EzLoginLayout",
    components: {
      'app-toolbar': toolbar,
    },
  }
</script>
