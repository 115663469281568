import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"650"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VForm,{attrs:{"loading":_vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.onSave()}}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"title"},[_vm._v("Notes")]),_c(VIcon,{staticClass:"ml-3",nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,[_c(VTextarea,{attrs:{"label":"Pertinent session notes concerning this legislator","rows":_vm.isMobile? 5 : 3,"outlined":"","hide-details":""},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),(_vm.notes !== '')?_c('div',{staticClass:"d-flex justify-end mt-3"},[_c(VBtn,{attrs:{"small":"","outlined":"","color":"secondary"},on:{"click":function($event){_vm.notes = ''}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"ml-3",attrs:{"small":"","type":"submit","color":"primary","loading":_vm.isBusy}},[_vm._v("Save")])],1):_vm._e(),_c(VDataTable,{attrs:{"headers":_vm.notesTableHeader,"items":_vm.notesList,"disable-sort":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(item.created_at ? _vm.getFormatDate(item.created_at) : ''))])]}},{key:"item.result",fn:function({ item }){return [(item.result.length < 170)?_c('div',[_vm._v(_vm._s(item.result))]):_c('div',[(!_vm.readMoreActivated)?_c('span',[_vm._v(_vm._s(item.result.slice(0, 170)))]):_vm._e(),(!_vm.readMoreActivated)?_c('a',{on:{"click":_vm.activateReadMore}},[_vm._v(" Read more... ")]):_vm._e(),(_vm.readMoreActivated)?_c('span',[_vm._v(" "+_vm._s(item.result))]):_vm._e(),(_vm.readMoreActivated)?_c('a',{on:{"click":_vm.deactivateReadMore}},[_vm._v(" Read less... ")]):_vm._e()])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }