<template>
  <div>
    <v-form ref="emailForm">
      <v-flex xs12 class="mt-5">
        <v-text-field
            v-model="email.subject"
            label="Email subject"
            :rules="subjectRules"
            outlined
            dense
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-text-field
            v-model="email.sendFrom"
            :rules="fromRules"
            label="Send from name"
            outlined
            dense
        ></v-text-field>
      </v-flex>
      <v-flex xs12 v-if="!isKP">
        <v-select
            :items="replyToList"
            item-text="title"
            item-value="value"
            v-model="email.reply_to_email"
            label="Replies go to..."
            :rules="replyToEmailRules"
            outlined
            dense
        ></v-select>
      </v-flex>
      <v-layout align-center class="mb-2">
        <v-flex xs12 sm8 class="d-flex px-2 align-center">
          <div class="caption grey--text">Message text*</div>
          <v-spacer/>
          <v-btn
              v-if="badVariables"
              class="mr-1"
              x-small
              outlined
              color="primary"
              @click="onCheckVariables"
          >
            Show Error
          </v-btn>
          <v-switch
              color="primary"
              hide-details
              :label="isPreview ? 'Edit' : 'Show Preview'"
              class="d-inline-block px-2 pt-0 sms-preview-switch"
              :disabled="isBusy"
              @change="getFormatMessage()"
              v-model="isPreview"
              :loading="isBusy"
          ></v-switch>
        </v-flex>
        <v-flex xs12 sm4 class="d-flex px-2">
          <v-select
              :disabled="isPreview"
              v-model="variable"
              label="Variables"
              :items="variableList"
              hide-details
              outlined
              dense
              class="subject_variable-select text_subject_variable-select"
          >
            <template v-slot:item="data">
              <v-list-item-content @click="onAddVariable(data.item)" v-text="data.item"></v-list-item-content>
            </template>
          </v-select>
        </v-flex>
      </v-layout>
      <div>
        <p v-if="badVariables" class="red--text mb-0">You have bad variables! Please click on 'Show Error' button and
          fix them.</p>
        <div class="tiny_editor" v-show="!isPreview">
          <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
          <editor
              v-if="!isBusy"
              v-model="message"
              :api-key="TINY_MCE_API_KEY"
              :plugins="tinyPlugins"
              :toolbar="tinyToolbars"
              :init="tinyInit"
              :disabled="disabledEditor"
              @input="inputEmail"
          ></editor>
        </div>
        <div v-show="isPreview">
          <editor
              v-if="!isBusy"
              :value="formattedMessage"
              :api-key="TINY_MCE_API_KEY"
              :plugins="tinyPlugins"
              :toolbar="tinyToolbars"
              :init="tinyInit"
              disabled
          ></editor>
        </div>
        <span class="text-counter">
          {{ email.finalMessageLength }}
        </span>
        <div class="mb-2">
          <span class="error--text">{{ errorMessage }}</span>
        </div>
      </div>
      <em v-if="email.reply_to_email">
        Replies to this email will go to {{ email.reply_to_email }}
      </em>
      <div class="d-flex align-end">
        <div v-if="!isKP">
          <v-flex
              v-if="!isFilesInput"
              v-model="isFilesInput"
              @click="isFilesInput = !isFilesInput"
              class="d-flex align-baseline"
          >
            <v-icon color="primary" class="pt-4 icon_files">mdi-paperclip</v-icon>
            <a>Attach files</a>
          </v-flex>
          <v-flex
              v-if="isFilesInput && !isUploadingFiles"
              class="d-flex align-baseline"
              @click="removeAllAttachedFiles"
          >
            <v-icon class="pt-4 icon_files">mdi-paperclip</v-icon>
            <span class="v-label red--text">Cancel</span>
          </v-flex>
        </div>
        <v-spacer/>
        <a @click="onOpenTestMessageModal">
          Send test to {{ userData.email }}
        </a>
      </div>
      <div
          class="d-flex align-baseline"
          :class="$vuetify.breakpoint.xs ? 'flex-column justify-start' : 'justify-space-between'"
      >
        <div v-if="!isKP" class="full-width">
          <v-file-input
              v-if="isFilesInput && !isUploadingFiles"
              class="pt-4"
              v-model="email.files"
              color="primary"
              counter
              label="File input"
              multiple
              placeholder="Select your files"
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"
              :rules="fileRules"
              dense
              @click:clear="removeAllAttachedFiles"
              @change="filesInputChanged"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                  color="primary"
                  dark
                  label
                  small
                  close
                  @click:close="removeAttachedFile(index)"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <div v-if="maxFileSize">
            <v-alert
                outlined
                type="info"
                text
            >
              To reduce the size of the files, you can use
              <a href="https://shrinkpdf.com/">shrinkpdf.com</a> or
              <a href="https://imagecompressor.com/">imagecompressor.com</a>
            </v-alert>
          </div>
          <v-flex v-if="isUploadingFiles" class="pa-5 d-flex align-center justify-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <div><span class="ml-3">Please wait, uploading files...</span></div>
          </v-flex>
          <v-alert v-if="maxImgsSize" class="mt-3" dense type="error" outlined :value="true">
            The size of the image is more than 25MB
          </v-alert>
          <v-alert v-if="isSummaryUploadLimit && !maxImgsSize" class="mt-3" dense type="error" outlined :value="true">
            The size of images and attached files is more than 25MB
          </v-alert>
        </div>
      </div>
    </v-form>
    <TestMessageModal
        ref="TestMessageModal"
        :legislatorId="districtLegislator && districtLegislator.id ? districtLegislator.id : 0"
        :memberId="memberId"
        :isEmail="true"
        :email="emailToTest"
        :message="message"
    >
    </TestMessageModal>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import ShorterUrl from "@/mixins/shorterUrl";
import Editor from "@tinymce/tinymce-vue";
import TinyConfig from "@/mixins/tiny-mce-config";
import TestMessageModal from "@/components/global/test-message-modal";
import AppService from "@/services/AppService";
import he from "he";

export default {
  name: 'SendEmail',
  mixins: [AppService, ShorterUrl, TinyConfig],
  components: {
    'editor': Editor,
    TestMessageModal
  },
  props: {
    email: {
      type: Object,
      default: function () {
        return {}
      }
    },
    propMessage: {
      type:String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
    isUploadingFiles: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showPreview: {
      type: Boolean,
      default: false
    },
    memberId: {
      type: Number,
      default: 0
    },
    districtLegislator: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  data() {
    return {
      formattedMessage: '',
      currFiles: [],
      isFilesInput: false,
      isPreview: false,
      emailToTest: {},
      variable: '',
    }
  },
  computed: {
    ...mapGetters('app', [
      'superAdminSelectedClient'
    ]),
    selectedClient() {
      if (this.isSuperAdmin) {
        return this.superAdminSelectedClient
      }
      return this.userClient
    },
    message: {
      get() {
        return this.propMessage
      },
      set(newValue) {
        this.$emit('setMessage', newValue)
      }
    },
    validationForm() {
      return this.$refs.emailForm.validate()
    },
    subjectRules() {
      return [
        v => !!v || 'Subject is required.',
      ]
    },
    fromRules() {
      return [
        v => !!v || 'From name is required.',
      ]
    },
    replyToEmailRules() {
      return [
        v => !!v || 'Reply to email address is required.',
      ]
    },
    replyToList() {
      let replyToList = []
      if (!this.isKP) {
        replyToList.push({title: this.userEmail, value: this.userEmail})
        if (this.selectedClient && this.selectedClient.mailgun_email) {
          replyToList.push({title: 'KP Dashboard Messages Area', value: this.selectedClient.mailgun_email})
        }
      }
      return replyToList
    },
    variableList() {
      let variable = [
        '{advocate_first}',
        '{assoc_abbrev}',
        '{sender_firstname}',
        '{sender_lastname}',
        '{sender_fullname}',
        '{sender_address}',
        '{sender_cell}',
      ]

      if (this.districtLegislator && this.districtLegislator.id) {
        let legVariable = [
          '{district}',
          '{live/work}',
          '{leg_nick}',
          '{leg_fullname}',
          '{leg_formal_address}',
          '{leg_bio}',
          '{leg_salutation}',
          '{leg_salutation_fullname}',
          '{leg_email}',
          '{leg_ph}',
          '{leg_local_phone}',
          '{leg_official_image}',
        ]

        variable = variable.concat(legVariable)
      }

      return variable;
    },
    isSummaryUploadLimit() {
      return (this.currentImgsSize + this.currentFilesSize) > this.imgSizeLimit
    },
    isBusy: {
      get() {
        return this.isLoading
      },
      set(newValue) {
        this.$emit('isLoading', newValue)
      }
    }
  },
  methods: {
    init() {
      this.badVariables = false
      this.isPreview = false
    },
    onCheckVariables() {
      this.checkVariablesForEditor(this.message, this.variableList)
    },
    getFormatMessage() {
      this.isBusy = true

      let payload = {
        member_id: this.memberId,
        message: this.message,
      }

      if (this.districtLegislator && this.districtLegislator.id) {
        payload.legislator_id = this.districtLegislator.id
      }

      this.getAppFormattedMessage(payload)
          .then((response) => {
            this.formattedMessage = response.data
            this.isBusy = false
          })
          .catch((err) => {
            this.handleError(err)
          })
    },
    onAddVariable(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);
      this.finalMessageLength()
    },
    async inputEmail() {
      this.$nextTick(async () => {
        let msg= this.message
        this.searchIncorrectVariables(msg, this.variableList)
        const newContent = await this.parsUrl(msg)
        
        if (newContent !== msg) {
          this.message = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
        this.finalMessageLength()
      })
    },
    onOpenTestMessageModal() {
      this.emailToTest = {
        is_sms: false,
        clientId: this.selectedClient.id,
        files: this.email.files,
        message: this.message
      }
      this.$nextTick(() => {
        this.$refs.TestMessageModal.open()
      })
    },
    filesInputChanged() {
      this.currFiles = [
        ...this.currFiles,
        ...this.email.files
      ]
      this.currFiles = [...new Set(this.currFiles)]
      this.email.files = [...new Set(this.currFiles)]
      this.$nextTick().then(() => {
        this.$emit('maxFileSize', this.maxFileSize)
      })
    },
    removeAttachedFile(index) {
      this.email.files.splice(index, 1)
      this.currFiles.splice(index, 1)
    },
    removeAllAttachedFiles() {
      this.currFiles = []
      this.email.files = []
      this.currentImgsSize = 0
      this.updatedImgSize = 0
      this.isFilesInput = false
    },
    finalMessageLength() {
      const ezLogin = 'https://kpda.sh/ez/xxxxxxxx'
      let kpNick = ''
      let kpLast = ''
      if(this.members) {
        this.members.forEach(member => {
          if (kpNick.length < (member.nickname || '').length) kpNick = member.nickname
          if (kpLast.length < (member.lastname || '').length) kpLast = member.lastname
        })
      }
      let message = this.message || ''
      message = message.replace(/{ez_login}/g, ezLogin)
      message = message.replace(/{kp_first}/g, kpNick)
      message = message.replace(/{kp_last}/g, kpLast)

      message = this.html2Text(message)
      this.email.finalMessageLength = message.length
    },
    html2Text(html) {
      let find = /(<br>|<\/p><p>)/,
        re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
  },
  watch: {
    badVariables: function () {
      this.searchIncorrectVariables(this.message, this.variableList)
      if (!this.badVariables) {
        this.checkVariablesForEditor(this.message, this.variableList)
      }
      this.$emit('badVariables', this.badVariables)
    },
    showPreview: function () {
      if (this.showPreview) {
        this.isPreview = true
        this.getFormatMessage()
      }

    },
    'message': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isBusy = true
        this.finalMessageLength()
        this.$emit('finalMessageLength', this.email.finalMessageLength)
        this.isBusy = false
      }
    },
  }
}
</script>
