<template>
  <v-dialog max-width="400" v-model="isModalOpen">
    <v-form ref="newCommitteeForm" @submit.prevent="onAddCommittee()">
      <v-card>
        <v-card-title>
          <span class="title">Add New Committee</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout wrap>
            <v-flex xs12 class="px-2">
              <v-select label="Type" item-value="id" item-text="text" :items="committeeTypeList" :rules="typeRules" v-model="newCommittee.type"></v-select>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field label="Short Name" :rules="shortNameRules" v-model="newCommittee.shortname"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field label="Full Name" :rules="fullNameRules" v-model="newCommittee.fullname"></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import CommitteeService from '@/services/CommitteeService'

export default {
  name: 'NewCommitteeModal',
  props: ['stateList', 'selectedStateId', 'refresh'],
  mixins: [CommitteeService],
  data () {
    return {
      isModalOpen: false,
      newCommittee: {},
      error: '',
      isBusy: false,
    }
  },
  computed: {
    stateRules () {
      return [
        v => !!v || 'State is required.',
      ]
    },
    typeRules () {
      return [
        v => typeof v !== 'undefined' || 'Type is required',
      ]
    },
    shortNameRules () {
      return [
        v => !!v || 'Short name is required.',
        v => (v && v.length <= 8) || 'Short name should contain less than 8 characters.'
      ]
    },
    fullNameRules () {
      return [
        v => !!v || 'Full name is required.',
      ]
    },
    committeeTypeList () {
      return [
        {
          id: 0,
          text: 'House',
        },
        {
          id: 1,
          text: 'Senate',
        },
        {
          id: 2,
          text: 'Joint',
        },
      ]
    },
  },
  methods: {
    toggle () {
      this.isModalOpen = true
      this.error = ''
      if(this.$refs.newCommitteeForm) {
        this.$refs.newCommitteeForm.reset()
      }
    },
    onAddCommittee () {
      if (!this.$refs.newCommitteeForm.validate()) return
      this.isBusy = true
      this.newCommittee.state_id = this.selectedStateId
      this.createCommittee(this.newCommittee)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'New committee has been created.', 'Success!')
          this.refresh()
          this.isModalOpen = false
        })
        .catch((err) => {
          this.handleError(err)
        })
    }
  }
}
</script>
