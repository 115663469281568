<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog width="900" scrollable v-model="isModalOpen">
    <v-form ref="newMemberForm" @submit.prevent="onAddMember()">
      <v-card :loading="isBusy">
        <v-tabs
          v-model="tabs"
          :show-arrows="isMobile"
        >
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-square-user</v-icon>
            Account
          </v-tab>
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-house-building</v-icon>
            Addresses
          </v-tab>
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-id-card</v-icon>
            Custom fields
          </v-tab>
          <v-tab >
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-person</v-icon>
            Personal
          </v-tab>
        </v-tabs>
        <v-card-text>
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>

          <v-tabs-items v-model="tabs" class="full-height-tab">

            <v-tab-item class="pt-6">
              <v-layout wrap>
                <v-flex
                    xs3
                    sm4
                    md2
                    class="px-2 xs-mb-2"
                    :class="{ 'mb-2': isMobile }"
                >
                  <div>
                    <v-avatar
                        size="120"
                        class="bordered_avatar"
                    >
                      <img
                          v-if="newMember.avatar_url"
                          alt="New Member Avatar"
                          :src="getThumbImageMedium(newMember.avatar_url)"
                      />
                      <img v-else alt="New Advocate Photo" src="/images/member-avatar.jpg"/>
                    </v-avatar>
                  </div>
                </v-flex>
                <v-flex md4 class="upload-btn px-2" :class="isMobile? '' : 'ml-4'">
                  <v-btn large color="primary" @click="$refs.avatarUploader.click()">Upload new photo</v-btn>
                  <p class="mt-4">Allowed JPG, GIF or PNG. Max size of 800K</p>
                  <input ref="avatarUploader" type="file" accept="image/*" class="d-none" @change="onUploadAvatar()">
                </v-flex>
                <v-flex md5 class="mb-0 ml-auto px-2" :class="{ 'mt-2': isMobile }">
                  <v-textarea
                      outlined label="Advocate Notes (seen by Admins and Coordinators only)"
                      v-model="newMember.notes"
                      dense
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <!-- checkboxes row-->
              <v-layout wrap>
                <v-flex sm6 class="px-2" v-if="isSuperAdmin">
                  <v-select
                      label="Client"
                      item-text="association"
                      item-value="id"
                      :items="clientList"
                      :rules="clientRules"
                      v-model="newMember.client_id"
                      @input="onSelectClient"
                      dense
                      outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex sm3 xs12 class="px-2">
                  <v-checkbox
                      color="primary"
                      label="Active"
                      v-model="newMember.active"
                      :input-value="true"
                      dense
                  ></v-checkbox>
                </v-flex>
                <v-flex sm3 xs12 class="px-2">
                  <v-tooltip bottom max-width="300px">
                    <template v-slot:activator="{ on }">
                      <v-checkbox
                          dense
                          color="primary"
                          v-on="on"
                          v-model="newMember.eligible"
                          :input-value="true"
                      >
                        <span slot="label" v-on="on">KP Eligible?</span>
                      </v-checkbox>
                    </template>
                    <span>Check this if advocate has either quit the KP program or expressed that they have no interest. This will remove them from the Volunteer Request and KP Assignment lists.</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
              <!-- inputs row-->
              <v-layout wrap>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="First Name*"
                    :rules="nickNameRules"
                    v-model="newMember.nickname"
                    :error-messages="errorMessages('nickname')"
                    dense
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                      label="Last Name*"
                      :rules="lastNameRules"
                      v-model="newMember.lastname"
                      :error-messages="errorMessages('lastname')"
                      dense
                      outlined
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                      label="Email*"
                      prepend-icon="email"
                      :rules="emailRules"
                      v-model="newMember.email"
                      :error-messages="errorMessages('email')"
                      dense
                      outlined
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                      label="Cell Phone"
                      v-mask="'(###) ###-####'"
                      prepend-icon="no_cell"
                      :rules="phoneNumberRules"
                      v-model="newMember.cell"
                      :error-messages="errorMessages('cell')"
                      dense
                      outlined
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                      label="Office Phone"
                      prepend-icon="phone"
                      v-mask="'(###) ###-####'"
                      :error-messages="errorMessages('office_phone')"
                      :rules="phoneNumberRules"
                      v-model="newMember.office_phone"
                      dense
                      outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-tab-item>

            <!--Addresses-->
            <v-tab-item class="pt-6">
              <h3>Home Address</h3>
              <div class="d-flex flex-wrap align-center mb-5">
                 <span
                   v-if="isPoBoxesHome"
                   class="error--text text-caption"
                 >PO Boxes are not allowed as they cannot be properly geolocated. Please enter a physical address and choose an option from the dropdown.
                 </span>
                <div class="d-flex align-center full-width">
                  <address-input
                    placeholder="Enter Home Address"
                    v-model="newMember.home_address"
                    prepend-icon="home"
                    :dense="true"
                    @change="onInputAddress($event,0)"
                    @input="onChangeAddress(0)"
                  ></address-input>
                </div>
              </div>
              <v-layout row wrap>
                <v-flex xs12 sm4 class="px-3">
                  <v-select
                    label="Home House District"
                    append-outer-icon="close"
                    item-text="district"
                    item-value="district"
                    :items="houseDistrictList"
                    v-model="newMember.home_house_district"
                    @click:append-outer="onClear('home_house_district')"
                    outlined
                    dense
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm4 class="px-3">
                  <v-select
                    label="Home Senate District"
                    append-outer-icon="close"
                    item-text="district"
                    item-value="district"
                    :items="senDistrictList"
                    v-model="newMember.home_sen_district"
                    outlined
                    dense
                    @click:append-outer="onClear('home_sen_district')"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm4 class="px-3">
                  <v-select
                    label="Home Congr District"
                    append-outer-icon="close"
                    item-text="district"
                    item-value="district"
                    :items="conDistrictList"
                    v-model="newMember.home_con_district"
                    outlined
                    dense
                    @click:append-outer="onClear('home_con_district')"
                  >
                  </v-select>
                </v-flex>
              </v-layout>


              <h3>Office Address</h3>
              <div class="d-flex flex-wrap align-center mb-5">
                 <span
                   v-if="isPoBoxesWork"
                   class="error--text text-caption"
                 >PO Boxes are not allowed as they cannot be properly geolocated. Please enter a physical address and choose an option from the dropdown.
                 </span>
                <div class="d-flex align-center full-width">
                  <address-input
                    placeholder="Enter Home Address"
                    v-model="newMember.work_address"
                    prepend-icon="home"
                    :dense="true"
                    @change="onInputAddress($event,1)"
                    @input="onChangeAddress(1)"
                  ></address-input>
                </div>
              </div>
              <v-layout row wrap>
                <v-flex xs12 sm4 class="px-3">
                  <v-select
                    label="Work House District"
                    append-outer-icon="close"
                    item-text="district"
                    item-value="district"
                    :items="houseDistrictList"
                    v-model="newMember.work_house_district"
                    @click:append-outer="onClear('work_house_district')"
                    outlined
                    dense
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm4 class="px-3">
                  <v-select
                    label="Work Sen District"
                    append-outer-icon="close"
                    item-text="district"
                    item-value="district"
                    :items="senDistrictList"
                    v-model="newMember.work_sen_district"
                    @click:append-outer="onClear('work_sen_district')"
                    outlined
                    dense
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm4 class="px-3">
                  <v-select
                    label="Work Con District"
                    append-outer-icon="close"
                    item-text="district"
                    item-value="district"
                    :items="conDistrictList"
                    v-model="newMember.work_con_district"
                    @click:append-outer="onClear('work_con_district')"
                    outlined
                    dense
                  >
                  </v-select>
                </v-flex>
              </v-layout>

              <template v-if="client && client.uses_2nd_work">
                <h3>2nd Office Address</h3>
                <div class="d-flex flex-wrap align-center mb-5">
                   <span
                     v-if="isPoBoxesWork2"
                     class="error--text text-caption"
                   >PO Boxes are not allowed as they cannot be properly geolocated. Please enter a physical address and choose an option from the dropdown.
                 </span>
                  <div class="d-flex align-center full-width">
                    <address-input
                      placeholder="2rd Office Address"
                      v-model="newMember.work2_address"
                      prepend-icon="home"
                      :dense="true"
                      @change="onInputAddress($event, 2)"
                      @input="onChangeAddress(2)"
                    ></address-input>
                  </div>
                </div>
                <v-layout row wrap v-if="houseDistrictList.length">
                  <v-flex xs12 sm4 class="px-3">
                    <v-select
                      label="Work2 House District"
                      append-outer-icon="close"
                      item-text="district"
                      item-value="district"
                      :items="houseDistrictList"
                      v-model="newMember.work2_house_district"
                      @click:append-outer="onClear('work2_house_district')"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm4 class="px-3">
                    <v-select
                      label="Work2 Sen District"
                      append-outer-icon="close"
                      item-text="district"
                      item-value="district"
                      :items="senDistrictList"
                      v-model="newMember.work2_sen_district"
                      @click:append-outer="onClear('work2_sen_district')"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm4 class="px-3">
                    <v-select
                      label="Work2 Con District"
                      append-outer-icon="close"
                      item-text="district"
                      item-value="district"
                      :items="conDistrictList"
                      v-model="newMember.work2_con_district"
                      @click:append-outer="onClear('work2_con_district')"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </template>


              <template v-if="client && client.uses_3rd_work">
                <h3>3rd Office Address</h3>
                <div class="d-flex flex-wrap align-center mb-5">
                  <span
                    v-if="isPoBoxesWork3"
                    class="error--text text-caption"
                  >PO Boxes are not allowed as they cannot be properly geolocated. Please enter a physical address and choose an option from the dropdown.
                 </span>
                  <div class="d-flex align-center full-width">
                    <address-input
                      placeholder="3rd Office Address"
                      v-model="newMember.work3_address"
                      prepend-icon="home"
                      :dense="true"
                      @change="onInputAddress($event, 3)"
                      @input="onChangeAddress(3)"
                    ></address-input>
                  </div>
                </div>
                <v-layout row wrap v-if="houseDistrictList.length">
                  <v-flex xs12 sm4 class="px-3">
                    <v-select
                      label="Work3 House District"
                      append-outer-icon="close"
                      item-text="district"
                      item-value="district"
                      :items="houseDistrictList"
                      v-model="newMember.work3_house_district"
                      @click:append-outer="onClear('work3_house_district')"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm4 class="px-3">
                    <v-select
                      label="Work3 Sen District"
                      append-outer-icon="close"
                      item-text="district"
                      item-value="district"
                      :items="senDistrictList"
                      v-model="newMember.work3_sen_district"
                      @click:append-outer="onClear('work3_sen_district')"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm4 class="px-3">
                    <v-select
                      label="Work3 Con District"
                      append-outer-icon="close"
                      item-text="district"
                      item-value="district"
                      :items="conDistrictList"
                      v-model="newMember.work3_con_district"
                      @click:append-outer="onClear('work3_con_district')"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </template>


              <template v-if="client && client.uses_4th_work">
                <h3>4th Office Address</h3>
                <div class="d-flex flex-wrap align-center mb-5">
                   <span
                     v-if="isPoBoxesWork4"
                     class="error--text text-caption"
                   >PO Boxes are not allowed as they cannot be properly geolocated. Please enter a physical address and choose an option from the dropdown.
                 </span>
                  <div class="d-flex align-center full-width">
                    <address-input
                      placeholder="4th Office Address"
                      v-model="newMember.work4_address"
                      prepend-icon="home"
                      :dense="true"
                      @change="onInputAddress($event,4)"
                      @input="onChangeAddress(4)"
                    ></address-input>
                  </div>
                </div>
                <v-layout row wrap v-if="houseDistrictList.length">
                  <v-flex xs12 sm4 class="px-3">
                    <v-select
                      label="Work4 House District"
                      append-outer-icon="close"
                      item-text="district"
                      item-value="district"
                      :items="houseDistrictList"
                      v-model="newMember.work4_house_district"
                      @click:append-outer="onClear('work4_house_district')"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm4 class="px-3">
                    <v-select
                      label="Work4 Sen District"
                      append-outer-icon="close"
                      item-text="district"
                      item-value="district"
                      :items="senDistrictList"
                      v-model="newMember.work4_sen_district"
                      @click:append-outer="onClear('work4_sen_district')"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm4 class="px-3">
                    <v-select
                      label="Work4 Con District"
                      append-outer-icon="close"
                      item-text="district"
                      item-value="district"
                      :items="conDistrictList"
                      v-model="newMember.work4_con_district"
                      @click:append-outer="onClear('work4_con_district')"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </template>

            </v-tab-item>

            <!--Custom fields-->
            <v-tab-item class="pt-6">
              <v-layout wrap>
                <v-flex
                  sm6
                  class="pa-2"
                  v-if="clientFields && clientFields.length"
                  v-for="(field, index) in clientFields"
                  :key="field.type + index"
                >
                  <div v-if="field.type == 'text'" class="d-flex">
                    <v-tooltip bottom v-if="field.show_member && field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen and edited by Advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="field.show_member && !field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen but not edited by Advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by Admins - Advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && !field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by Admins - Advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-text-field
                      :label="field.name"
                      :value="field.pivot ? field.pivot.metadata.value : ''"
                      @input="(val) => fieldHandler(val, field)"
                      dense
                      outlined
                      hide-details
                    >
                    </v-text-field>
                  </div>
                  <div v-if="field.type == 'dropdown'" class="custom-selector d-flex">
                    <v-tooltip bottom v-if="field.show_member && field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen and edited by Advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="field.show_member && !field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen but not edited by Advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by Admins - Advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && !field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by Admins - Advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-select
                      :value="field.pivot ? field.pivot.metadata : []"
                      @input="(val) => fieldHandler(val, field)"
                      :items="field.metadata"
                      item-text="name"
                      item-value="name"
                      :label="field.name"
                      return-object
                      dense
                      outlined
                      hide-details
                    ></v-select>
                  </div>
                  <div v-if="field.type == 'checkbox'" class="custom-selector d-flex">
                    <v-tooltip bottom v-if="field.show_member && field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen and edited by Advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="field.show_member && !field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen but not edited by Advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by Admins - Advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && !field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by Admins - Advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-select
                      :value="field.pivot ? field.pivot.metadata : []"
                      @input="(val) => fieldHandler(val, field)"
                      :items="field.metadata"
                      item-text="name"
                      item-value="name"
                      :label="field.name"
                      multiple
                      return-object
                      dense
                      outlined
                      hide-details
                    >
                    </v-select>
                  </div>
                  <div v-if="field.type == 'toggle'" class="d-flex">
                    <v-tooltip bottom v-if="field.show_member && field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen and edited by Advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="field.show_member && !field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen but not edited by Advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by Admins - Advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && !field.edit_member">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by Admins - Advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-switch
                      dense
                      :label="field.name"
                      :input-value="field.pivot ? field.pivot.metadata.value : false"
                      @change="(val) => fieldHandler(val, field)"
                    ></v-switch>
                  </div>
                </v-flex>
              </v-layout>
            </v-tab-item>

            <v-tab-item class="py-4">
              <v-layout wrap>
                <v-flex sm6 class="px-2">
                  <v-select
                    label="KP Preference"
                    hint="Party preference, or the Legislator party this Advocate is willing to accept as a KP. Optional and hidden from all but Admins/Coordinators"
                    :items="kpPreference"
                    item-text="title"
                    item-value="value"
                    v-model="newMember.kp_preference"
                    dense
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex sm6 class="px-2" v-if="client.uses_political_leaning">
                  <v-select
                    label="Political Leanings"
                    hint=""
                    :items="politicalLeanings"
                    item-text="name"
                    item-value="id"
                    v-model="newMember.political_leaning_id"
                    outlined
                    dense
                  >
                  </v-select>
                </v-flex>
                <v-flex sm6 class="px-2" v-if="client.uses_political_issues">
                  <v-select
                    label="Political Issues"
                    hint=""
                    :items="politicalIssues"
                    item-text="name"
                    item-value="id"
                    v-model="newMember.political_issues"
                    multiple
                    outlined
                    dense
                  >
                  </v-select>
                </v-flex>
                <v-flex sm6 class="px-2" v-if="client && client.uses_dob">
                  <v-date-picker2
                    label="Date Of Birth"
                    v-model="newMember.dob"
                    :dense="true"
                  ></v-date-picker2>
                </v-flex>
                <v-flex sm6 class="px-2" v-if="client && client.uses_grad_year">
                  <v-select
                    label="Grad Year"
                    append-outer-icon="close"
                    :items="gradYearList"
                    v-model="newMember.grad_year"
                    @click:append-outer="onClear('grad_year')"
                    outlined
                    dense
                  >
                  </v-select>
                </v-flex>

                <v-flex col-12 class="px-2">
                  <h5 class="ml-1">You may enter as many as necessary</h5>
                  <v-autocomplete
                  label="Colleges or Universities Attended"
                  color="blue-grey lighten-2 mt-3"
                  :menu-props="{closeOnContentClick:true}"
                  :value="newMember.universities"
                  @change="setUniversity"
                  :search-input.sync="searchUniversities"
                  :items="universities"
                  item-text="name"
                  item-value="id"
                  :loading="isBusy"
                  hide-details
                  return-object
                  outlined
                  multiple
                  filled
                  dense
                >
                  <template v-slot:selection="{item}"></template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                  <div class="mb-2 pt-1">
                    <div class="d-flex flex-wrap">
                      <div v-for="(university, index) of newMember.universities" class="mr-2">
                        <v-menu class="my-1"
                                v-model="displaySelected[getIndex(university.id, newMember.universities)]"
                                :key="index"
                                bottom
                                left
                                :close-on-content-click="false"
                                origin="top left"
                                content-class="role_card"
                        >
                          <template v-slot:activator="{ on }">
                            <v-chip
                              class="my-1"
                              v-on="on"
                              close
                              outlined
                              color="primary"
                              :input-value="university.name"
                              @click:close="removeUniversityFromMember(index)"
                            >
                              {{ university.name }}
                            </v-chip>
                          </template>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 sm12 v-if="client && client.uses_interests">
                  <h4>Hobbies/Interests:</h4>
                  <div
                    v-for="interest in hobbiesInterests"
                    :key="interest.id"
                  >
                    <v-checkbox
                      v-if="interest.parent_id === null"
                      color="primary"
                      :label="interest.name"
                      class="mt-0"
                      :input-value="isEmptyInterest(interest)"
                      @change="editMemberInterests($event, interest)"
                    ></v-checkbox>
                    <div
                      class="ml-4"
                      v-for="childInterest in hobbiesInterests"
                      :key="childInterest.id"
                    >
                      <v-checkbox
                        v-if="interest.id === childInterest.parent_id
                            && isEmptyInterest(interest)
                            && childInterest.type !== 'favorite_team'
                            && childInterest.type !== 'note_hobby'"
                        color="primary"
                        :label="childInterest.name"
                        class="mt-0"
                        :input-value="isEmptyInterest(childInterest)"
                        @change="editMemberInterests($event, childInterest)"
                      ></v-checkbox>
                      <div
                        v-if="interest.id === childInterest.parent_id
                          && childInterest.type === 'watching_sports'
                          && isEmptyInterest(childInterest)
                          && isEmptyInterest(interest)"
                      >
                        <favorite-teams-autocomplete
                          :clientId="client.id"
                          :memberFavoriteTeams="memberFavoriteTeams"
                          @response="changeMemberFavoriteTeams"
                          @remove="removeMemberFavoriteTeam"
                        ></favorite-teams-autocomplete>
                      </div>
                      <div
                        v-if="childInterest.type === 'other'
                                  && interest.id === childInterest.parent_id
                                  && isEmptyInterest(interest)
                                  && isEmptyInterest(childInterest)"
                      >
                        <v-textarea
                          v-model="otherHobbies[childInterest.id]"
                          label="Your Hobby"
                          auto-grow
                          outlined
                          rows="1"
                          row-height="15">
                        </v-textarea>
                      </div>
                    </div>
                    <div
                      v-if="interest.parent_id === null
                          && interest.type === 'other'
                          && isEmptyInterest(interest)"
                    >
                      <v-textarea
                        v-model="otherHobbies[interest.id]"
                        label="Your Hobby"
                        auto-grow
                        outlined
                        rows="1"
                        row-height="15">
                      </v-textarea>
                    </div>
                  </div>
                </v-flex>
                </v-layout>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false" class="mt-2">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy" class="mr-3 mt-2">Save New Advocate</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ClientService from '@/services/ClientService'
import CloudinaryService from '@/services/CloudinaryService'
import DistrictService from '@/services/DistrictService'
import MemberService from '@/services/MemberService'

import AddressInput from '@/components/common/address-input.vue'
import VDatePicker2 from '@/components/common/date-picker'

import kpPreference from '@/entities/kp-preference'
import InterestService from "@/services/InterestService";
import FavoriteTeamsAutocomplete from "@/components/auth/favorite-teams-autocomplete";
import UniversityService from "@/services/UniversityService";

export default {
  name: "NewMemberModal",
  props: {
    clientList: {
      type: Array,
      default: () => { return []}
    },
    refresh: {
      type: Function,
    },
    selectedClient: {
      type: Object,
      default: () => { return {}}
    },
  },
  mixins: [ClientService, CloudinaryService, DistrictService,  MemberService, InterestService, UniversityService],
  components: {
    AddressInput,
    VDatePicker2,
    "favorite-teams-autocomplete": FavoriteTeamsAutocomplete,
  },
  data () {
    return {
      tabs: null,
      isModalOpen: false,
      districtList: [],
      displaySelected: {},
      newMember: {
        custom_fields: [],
        interests: [],
        political_issues: [],
        eligible: true,
      },
      error: '',
      homeAddressInputs: '',
      workAddressInputs: '',
      work2AddressInputs: '',
      work3AddressInputs: '',
      work4AddressInputs: '',
      universities: [],
      searchUniversities: '',
      isBusy: false,
      kpPreference: kpPreference,
      politicalLeanings: [],
      memberFavoriteTeams: [],
      otherHobbies: {},
      politicalIssues: [],
      hobbiesInterests: [],
      clientFields: [],
    }
  },
  computed: {
    clientRules () {
      return [
        v => !!v || 'Client is required.',
      ]
    },
    lastNameRules () {
      return [
        v => !!v || 'Last name is required.',
      ]
    },
    nickNameRules () {
      return [
        v => !!v || 'Nickname is required.',
      ]
    },
    emailRules () {
      return [
        v => !!v || 'E-mail address is required.',
        v => this.validateEmail(v) || 'E-mail address must be valid.',
      ]
    },
    gradYearList () {
      const thisYear = moment().year()
      return _.range(1940, thisYear + 1)
    },
    client () {
      return this.selectedClient
    },
    houseDistrictList () {
      return this.districtList.filter(district => !district.is_federal && /^H\d+\w*/.test(district.district))
    },
    senDistrictList () {
      return this.districtList.filter(district => !district.is_federal && /^S\d+\w*/.test(district.district))
    },
    conDistrictList () {
      return this.districtList.filter(district => district.is_federal && /^CD\d+\w*/.test(district.district))
    },
    federalSenateList () {
      return this.districtList.filter(district => district.is_federal && /^[^\d]{2}$/.test(district.district))
    },
    isPoBoxesHome() {
      return this.homeAddressInputs && this.homeAddressInputs.toUpperCase().includes('PO B')
    },
    isPoBoxesWork() {
      return this.workAddressInputs && this.workAddressInputs.toUpperCase().includes('PO B')
    },
    isPoBoxesWork2() {
      return this.work2AddressInputs && this.work2AddressInputs.toUpperCase().includes('PO B')
    },
    isPoBoxesWork3() {
      return this.work3AddressInputs && this.work3AddressInputs.toUpperCase().includes('PO B')
    },
    isPoBoxesWork4() {
      return this.work4AddressInputs && this.work4AddressInputs.toUpperCase().includes('PO B')
    }
  },
  methods: {
    async toggle () {
      this.isModalOpen = true
      this.tabs = null
      this.error = ''
      this.homeAddressInputs = ''
      this.workAddressInputs = ''
      this.work2AddressInputs = ''
      this.work3AddressInputs = ''
      this.work4AddressInputs = ''
      if(this.$refs.newMemberForm) this.$refs.newMemberForm.reset()
      if (this.client && this.client.id) {
        this.newMember.client_id = this.client.id
      }
      this.$nextTick(() => {
        this.newMember = {
          client_id: this.selectedClient.id,
          eligible: true,
          custom_fields: [],
          political_issues: [],
          interests: [],
          political_leaning_id: null,
          active: true,
          universities: [],
        }
      })
      await this.onSelectClient()
      await this.getUniversity()
      await this.getPoliticalLeaningsList()
      await this.getPoliticalIssuesList()
      await this.getInterestsList()
    },
    getUniversity() {
      const params = {
        'search': this.searchUniversities,
        'limit': 5
      }
      this.getUniversities(params).then(response => {
        this.universities = response.data.data
      })
    },
    setUniversity(value) {
      this.newMember.universities = value
    },
    removeUniversityFromMember(value) {
      this.newMember.universities.splice(value, 1)
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    onUploadAvatar () {
      const file = this.$refs.avatarUploader.files[0]
      if (!file) return
      this.uploadToCloudinary(file)
        .json((res) => {
          this.newMember.avatar_url = res.secure_url
          this.newMember = {...this.newMember}
        })
        .then(res => {
          this.onCallToastr('success', 'Advocate photo has been changed!', 'Success!')
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
        })
    },
    isEmptyInterest(interest) {
      const memberInterest = this.newMember.interests.findIndex(item => item.id === interest.id)
      return memberInterest >= 0
    },
    editMemberInterests(event, value) {
      if (event) {
        this.newMember.interests.push(value)
      } else {
        this.removeInterest(value)
      }
    },
    removeInterest(value) {
      const index = this.newMember.interests.findIndex(object => object.id === value.id);
      if (index >= 0) {
        const childInterests = this.newMember.interests.filter(object => object.parent_id === value.id)
        childInterests.forEach(object => this.removeInterest(object))
        this.newMember.interests.splice(index, 1)
      }
      if (value.type === 'watching_sports') {
        this.memberFavoriteTeams = [];
      }
    },
    changeMemberFavoriteTeams(value) {
      this.memberFavoriteTeams = value
    },
    removeMemberFavoriteTeam(value) {
      const index = this.memberFavoriteTeams.findIndex(object => object.id === value.id);
      this.memberFavoriteTeams.splice(index, 1)
    },
    async onSelectClient () {
      if (!this.newMember.client_id) return

      let districts = (await this.getClientDistricts(this.newMember.client_id)).data

      if (this.client && this.client.state && this.client.state.few_districts) {
        this.districtList = this.getArrayOfUniqDistricts(this.removeDuplicateDistricts(districts))
      } else {
        this.districtList = districts
      }

      this.getClientCustomFields()
    },
    removeDuplicateDistricts(array) {
      return array.map(district => ({
        ...district,
        district: district.district.match(/^[A-Z]\d+\w/i) ?
          district.district.match(/^[A-Z]\d+\w/i)[0] :
          district.district
      }))
    },
    getArrayOfUniqDistricts(array) {
      return _.uniqBy(array, function (e) {
        return e.district;
      });
    },
    onInputAddress(event, index) {
      this.homeAddressInputs = index === 0 ? event : ''
      this.workAddressInputs = index === 1 ? event : ''
      this.work2AddressInputs = index === 2 ? event : ''
      this.work3AddressInputs = index === 3 ? event : ''
      this.work4AddressInputs = index === 4 ? event : ''
      if (!this.client) {
        return
      }
      const prefixList = [
        'home_',
        'work_',
        'work2_',
        'work3_',
        'work4_',
      ]
      const prefix = prefixList[index]

      this.newMember[`${prefix}address`] = event
      this.newMember[`${prefix}lat`] = null
      this.newMember[`${prefix}lng`] = null
      this.newMember[`${prefix}house_district`] = null
      this.newMember[`${prefix}sen_district`] = null
      this.newMember[`${prefix}con_district`] = null
    },
    onChangeAddress (index) {
      if (!this.client) {
        return
      }
      const prefixList = [
        'home_',
        'work_',
        'work2_',
        'work3_',
        'work4_',
      ]
      const prefix = prefixList[index]
      const payload = {
        state_id: this.client.state_id,
        address: this.newMember[`${prefix}address`],
      }
      this.searchDistrictByAddress(payload)
        .then((res) => {
          if (!res.data.house_district && !res.data.sen_district && !res.data.con_district) {
            this.newMember[`${prefix}lat`] = null
            this.newMember[`${prefix}lng`] = null
            this.onCallToastr('error', 'Sorry, no valid district found for this address, please add manually', 'Error!')
            return
          }

          let houseDistrict = res.data.house_district
          let senateDistrict = res.data.sen_district
          let conDistrict = res.data.con_district
          if (this.client && this.client.state && this.client.state.few_districts) {
            houseDistrict.district = houseDistrict.district.match(/^[A-Z]\d+\w/i) ? houseDistrict.district.match(/^[A-Z]\d+\w/i)[0] : houseDistrict.district
            senateDistrict.district = senateDistrict.district.match(/^[A-Z]\d+\w/i) ? senateDistrict.district.match(/^[A-Z]\d+\w/i)[0] : senateDistrict.district
            conDistrict.district = conDistrict.district.match(/^[A-Z]\d+\w/i) ? conDistrict.district.match(/^[A-Z]\d+\w/i)[0] : conDistrict.district
          }

          if (this.houseDistrictList.length > 0 && houseDistrict) {
            this.newMember[`${prefix}house_district`] = houseDistrict.district
          } else {
            this.newMember[`${prefix}house_district`] = null
          }
          if (this.senDistrictList.length > 0 && senateDistrict) {
            this.newMember[`${prefix}sen_district`] = senateDistrict.district
          } else {
            this.newMember[`${prefix}sen_district`] = null
          }
          if (this.conDistrictList.length > 0 && conDistrict) {
            this.newMember[`${prefix}con_district`] = conDistrict.district
          } else {
            this.newMember[`${prefix}con_district`] = null
          }
          this.newMember[`${prefix}lat`] = res.data.location.lat
          this.newMember[`${prefix}lng`] = res.data.location.lng
          this.newMember = { ...this.newMember }
        })
        .catch((err) => {
          if (err.response.status === 424) {
            this.onCallToastr('error', `Warning. This address is outside ${this.selectedClient.assoc_abbrev}. It is OK to save; however, it will have blank districts and be excluded from all KP Dashboard systems.`, 'Error!')
          } else {
            this.newMember[`${prefix}lat`] = null
            this.newMember[`${prefix}lng`] = null
            this.handleError(err)
          }
        })
    },
    errorMessages(field) {
      return (this.errors && this.errors[field]) ? this.errors[field][0] : []
    },
    onClear (key) {
      this.newMember[key] = null
      this.newMember = { ...this.newMember }
    },
    onAddMember () {
      if (!this.$refs.newMemberForm.validate()) return
      this.isBusy = true
      this.newMember.other_interests = []
      for (const [id, hobby] of Object.entries(this.otherHobbies)) {
        this.newMember.other_interests.push({
          "parent_id": id,
          "name": hobby
        })
      }
      this.newMember.universities = this.newMember.universities.map(university => university.id)
      this.newMember.interests = this.newMember.interests.filter(item => item.type !== 'favorite_team')
      this.newMember.interests = this.newMember.interests.concat(this.memberFavoriteTeams)
      this.createMember(this.newMember)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'New Advocate has been created.', 'Success!')
          this.refresh()
          this.isModalOpen = false
        })
        .catch((err) => {
          this.errors = err.response.data.errors
          this.handleError(err)
        })
    },
    getPoliticalLeaningsList(){
      this.isBusy = true
      this.getPoliticalLeanings().then(response => {
        this.politicalLeanings = response.data
        this.isBusy = false
      })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
    },
    getPoliticalIssuesList(){
      this.isBusy = true
      this.getPoliticalIssues().then(response => {
        this.politicalIssues = response.data
        this.isBusy = false
      })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
    },
    getInterestsList(){
      this.isBusy = true
      this.getInterests(this.selectedClient.id).then(response => {
        this.hobbiesInterests = response.data
        this.isBusy = false
      })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
    },
    getClientCustomFields() {
      this.isBusy = true
      this.getClientFields(this.newMember.client_id).then(response => {
        this.clientFields = response.data
        this.isBusy = false
      })
    },
    addField(field, val) {
      let meta = val
      if (field.type === 'toggle') meta = { value: val }
      if (field.type === 'text') meta = { value: val }
      this.newMember.custom_fields.push({
        ...field,
        pivot: {
          metadata: meta
        }
      })
    },
    fieldHandler(val, field) {
      let test = this.newMember.custom_fields.some(item => item.id == field.id)
      if (test) {
        this.newMember.custom_fields = this.newMember.custom_fields.map(item => {
          let meta = val
          if (field.type === 'toggle') meta = { value: val }
          if (field.type === 'text') meta = { value: val }

          if (item.id == field.id) {
            return {
              ...field,
              pivot: {
                metadata: meta
              }
            }
          } else {
            return { ...item }
          }
        })
      } else {
        this.addField(field, val)
      }
    }
  },
  watch: {
    searchUniversities: function () {
      clearTimeout(this.timer)
      this.timer =  setTimeout(() => {
        this.getUniversity()
      }, 500)
    },
  }
}
</script>

<style scoped lang="scss">
.v-dialog {
  background-color: white;
}
.v-dialog--fullscreen .v-toolbar__title {
  padding-left: 0 !important;
}

.upload-btn {
  @media (min-width: 600px) {
    padding-top: 20px;
  }
}

.v-tabs-items.full-height-tab  .v-window-item {
  height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
