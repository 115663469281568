<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-layout
    align-center
    v-if="rowData.member_legislators.length"
  >
    <span>
      {{ formattedDate }}
    </span>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  computed: {
    rowData () {
      return this.params.node.data || {}
    },
    formattedDate() {
      return this.rowData.primary_kp.last_login_at && this.rowData.primary_kp.last_login_at != null
          ? moment(this.rowData.primary_kp.last_login_at).format('MM/DD/YY')
          : '---'
    }
  },
})
</script>
