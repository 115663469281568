<template>
  <v-layout>
    <template v-for="index in valueList">
      <v-tooltip v-if="showRating" bottom :key="'tooltip' + index">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="getStarColor(index)"
            class="ma-0 pa-0"
            @click="!readonly && onChange(index, $event)"
            v-bind="attrs"
            v-on="on"
          >
            {{ getStarType(index) }}
          </v-icon>
        </template>
        <span>{{ value }}</span>
      </v-tooltip>
      <v-icon
        v-else
        :color="getStarColor(index)"
        class="ma-0 pa-0"
        @click="!readonly && onChange(index, $event)"
        :key="'icon' + index"
      >
        {{ getStarType(index) }}
      </v-icon>
    </template>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    halfIncrements: {
      type: Boolean,
      default: false
    },
    showRating: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    valueList() {
      return Array.from({ length: 5 }, (_, i) => i + 1);
    },
  },
  methods: {
    getStarColor(index) {
      if (index <= this.value) {
        return '#ffcc80';
      } else if (this.halfIncrements && index - 0.5 <= this.value) {
        return '#ffcc80';
      } else {
        return 'grey';
      }
    },
    getStarType(index) {
      if (index <= this.value) {
        return 'mdi-star';
      } else if (this.halfIncrements && index - 0.5 <= this.value) {
        return 'mdi-star-half';
      } else {
        return 'mdi-star-outline';
      }
    },
    onChange(index, event) {
      let valueToEmit = index;
      if (this.halfIncrements) {
        const rect = event.target.getBoundingClientRect();
        const isHalf = event.clientX - rect.left < rect.width / 2;
        valueToEmit = isHalf ? index - 0.5 : index;
      }
      this.$emit('input', valueToEmit);
    },
  },
}
</script>