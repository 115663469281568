<template>
  <v-dialog max-width="600" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <span class="title">Full Message</span>
        <v-spacer />
        <v-icon class="ml-3" @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12>
            <v-textarea label="Subject" rows="3" v-model="message.subject" readonly></v-textarea>
          </v-flex>
          <v-flex xs12 class="mt-3">
            <div class="mb-1 caption grey--text">Message</div>
            <div class="message-container" v-html="message.message"></div>
          </v-flex>
        </v-layout>
      </v-card-text>
       <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click.native="isModalOpen = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FullMessageModal',
  data () {
    return {
      isModalOpen: false,
      message: {},
    }
  },
  methods: {
    toggle (message) {
      this.isModalOpen = true
      this.message = message
    }
  }
}
</script>
