import { render, staticRenderFns } from "./f-vote-editor.vue?vue&type=template&id=6d32e6b4&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform"
import script from "./f-vote-editor.vue?vue&type=script&lang=js"
export * from "./f-vote-editor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports