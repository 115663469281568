<template>
  <v-dialog max-width="500" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <span class="title">Edit Deadline or Deactivate</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12 class="px-2">
            <v-checkbox
              color="primary"
              label="Active"
              v-model="messageData.active"
            ></v-checkbox>
          </v-flex>
          <v-flex xs12 class="mt-2 px-2 mb-4 full-width">
            <v-row>
              <v-col
                class="py-0"
                cols="12"
                sm="12"
                md="6"
              >
                <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Select date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :clearable="!!date"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="primary"
                    v-model="date"
                    :min="currentDate"
                    no-title
                    scrollable
                    @change="$refs.dateMenu.save(date)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                sm="12"
                md="6"
              >
                <v-dialog
                  ref="timeMenu"
                  v-model="timeMenu"
                  :return-value.sync="time"
                  persistent
                  max-width="290px"
                  min-width="290px"
                  :disabled="!date"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="getFormattedTime"
                      label="Select time"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :clearable="!!time"
                      :disabled="!date"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    color="primary"
                    v-if="timeMenu"
                    v-model="time"
                    :min="currentDate == date ? currentTime : ''"
                    full-width
                    ampm-in-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="timeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.timeMenu.save(time)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-card-text>
       <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
        <v-btn color="primary" :loading="isBusy" @click.native="onSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VDatePicker2 from '@/components/common/date-picker'
import AdvocacyMessageService from '@/services/AdvocacyMessageService'

export default {
  name: 'DeadlineModal',
  props: ['refresh'],
  mixins: [AdvocacyMessageService],
  components: {
    VDatePicker2,
  },
  data () {
    return {
      isModalOpen: false,
      messageData: {},
      isBusy: false,
      dateMenu: false,
      timeMenu: false,
      time: null,
      date: null
    }
  },
  computed: {
    getFormattedTime() {
      return this.time ? moment(this.time, "hh:mm").format('h:mm a') : ''
    },
    currentDate () {
      return new Date().toISOString().substr(0, 10)
    },
    currentTime () {
      const timezone = this.getTimezone(this.client);
      return moment().tz(timezone).add(1, 'hour').format('HH:mm');
    }
  },
  methods: {
    saveDeadline() {
      this.messageData.deadline = this.date + ' ' + this.time
    },
    parseDate(date) {
      this.date = moment(date).format('YYYY-MM-DD')
    },
    parseTime(time) {
      this.time = moment(time).format('H:mm')
    },
    toggle (message) {
      this.isModalOpen = true
      this.messageData = _.cloneDeep(message)
      this.parseDate(message.deadline)
      this.parseTime(message.deadline)
    },
    onSave () {
      this.isBusy = true
      this.saveDeadline()
      this.updateAdvocacyMessage(this.messageData)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'Message has been updated.', 'Success!')
          this.refresh()
          this.isModalOpen = false
        })
        .catch((err) => {
          this.handleError(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
