<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-layout ref="container" column justify-center align-center pa-4>
    <div v-for="option in optionList" :key="option.text">
        <span @click="onSelect(option)">{{ option.text }}</span>
    </div>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import TaskService from "@/services/TaskService";

export default Vue.extend({
  mixins: [TaskService],
  data () {
    return {
      result: null,
    }
  },
  computed: {
    optionList () {
      return [
        {
          icon: 'thumb_up',
          color: 'green',
          text: 'Supporting',
        },
        {
          icon: 'thumb_up',
          color: '#81C784',
          text: 'Inclined to Support',
        },
        {
          text: 'Neutral',
        },
        {
          icon: 'thumb_down',
          color: '#E57373',
          text: 'Inclined to Oppose',
        },
        {
          icon: 'thumb_down',
          color: 'red',
          text: 'Opposing',
        },
      ]
    }
  },
  created () {
    this.result = this.params.value
  },
  mounted () {
    Vue.nextTick(() => {
      this.$refs.container.focus()
    })
  },
  methods: {
    getValue () {
      return this.result
    },
    isPopup () {
      return true
    },
    onSelect (option) {
      const payload = {
        legislator_id: this.params.data.id,
        kp_id: this.params.data.primary_kp.id,
        task_id: this.params.colDef.taskId,
        result: option.text,
      }
      this.addTaskResult(payload)
        .then(() => {
          this.onCallToastr('success', 'Task Result has been updated.', 'Success!')
        })
        .catch(err => {
          console.log('err', err)
          this.onCallToastr('error', 'Something went wrong.', 'Error!')
        })
      this.result = option.text
      this.params.api.stopEditing()
    }
  }
})
</script>
