<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog max-width="500" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <span class="title">My Messages</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <v-data-table
          sort-by
          class="elevation-1"
          :options.sync="pagination"
          :headers="statsTableHeader"
          :items="statsList"
          disable-pagination
          disable-sort
        >
          <template v-slot:item.fullname="{ item }">
            <div>{{ item.fullname }}</div>
          </template>

          <template v-slot:item.sent_at="{ item }">
            <div>
              <span v-if="item.sent_at">{{ item.sent_at | dateTimeFormatter }}</span>
              <v-icon color="error" v-else>cancel</v-icon>
            </div>
          </template>

          <template v-slot:no-data>
            <p class="text-xs-center mb-0">No stats yet.</p>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click.native="isModalOpen = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AdvocacyMessageService from '@/services/AdvocacyMessageService'

export default {
  name: 'ReplyStatsModal',
  mixins: [AdvocacyMessageService],
  data () {
    return {
      isModalOpen: false,
      statsList: [],
      pagination: {
        itemsPerPage: 10
      }
    }
  },
  computed: {
    statsTableHeader () {
      return [
        { text: 'Legislator', value: 'fullname', align: 'center' },
        { text: 'Sent?', value: 'sent_at', align: 'center' },
      ]
    }
  },
  methods: {
    async toggle (advocacyMessageId) {
      this.statsList = (await this.getReplyStats(advocacyMessageId, { member_id: this.userId })).data
      this.isModalOpen = true
    }
  }
}
</script>
