import { render, staticRenderFns } from "./stats-modal.vue?vue&type=template&id=33424688&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform"
import script from "./stats-modal.vue?vue&type=script&lang=js"
export * from "./stats-modal.vue?vue&type=script&lang=js"
import style0 from "./stats-modal.vue?vue&type=style&index=0&id=33424688&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports