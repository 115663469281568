<template>
  <v-layout align-center>
    <div class="text-xs-center" v-if="rowData.committees && rowData.committees.length > 0">
      <div class="ag-cell_custom_chips">
        <v-tooltip bottom v-for="committee in rowData.committees" :key="committee.label">
          <template v-slot:activator="{ on }">
            <v-chip
                v-on="on"
                small
                label
                text-color="white"
                :color="committee.color ? committee.color : getColorFromText(committee.shortname)"
            >
              {{ committee.shortname }}
              <v-avatar
                  class="darken-4 ml-1"
                  :color="committee.subcommittee_color ? committee.subcommittee_color : getColorFromText(committee.shortname)"
                  v-if="committee.memberships && committee.memberships.some(membership => membership.subcommittee === 1) && rowData.client && rowData.client.state && rowData.client.state.has_subcommittees"
              >
                sub
              </v-avatar>
            </v-chip>
          </template>
          <span>{{ committee.fullname }}</span>
        </v-tooltip>
      </div>
    </div>
  </v-layout>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  computed: {
    rowData () {
      return this.params.node.data || {}
    }
  }
})
</script>
