<template>
  <v-dialog max-width="500" v-model="isModalOpen">
    <v-form @submit.prevent="saveLegislatorNotes()" :loading="loading">
      <v-card>
        <v-card-title class="d-flex justify-space-between py-2">
          <span class="title">Notes</span>
          <v-icon class="ml-3" @click.native="close">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-textarea
              class="mt-4"
              label="Private/Sensitive Legislator Notes (Admins only)"
              v-model="legislator.vip_notes"
              :rows="isMobile? 5 : 3"
              outlined
              hide-details
          ></v-textarea>
          <div v-if="this.legislator.vip_notes !== ''" class="d-flex justify-end mt-3">
            <v-btn small outlined color="secondary" @click="isModalOpen = false">Cancel</v-btn>
            <v-btn small type="submit" color="primary" :loading="loading" class="ml-3">Save</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import LegislatorService from "@/services/LegislatorService";

export default {
  mixins: [LegislatorService],
  data() {
    return {
      isModalOpen: false,
      legislator: {},
      loading: false,
    }
  },
  methods: {
    toggle(legislator) {
      this.isModalOpen = true
      this.legislator = legislator
    },
    saveLegislatorNotes() {
      let payload = {
        id: this.legislator.id,
        leg_id: this.legislator.id,
        client_id: this.userClientId,
        vip_notes: this.legislator.vip_notes,
        leg_notes: this.legislator.leg_notes,
        leg_support: this.legislator.leg_support,
        leg_region: this.legislator.leg_region
      }
      this.loading = true
      this.updateLegData(payload)
          .then(() => {
            this.$emit('updateLegislators')
            this.onCallToastr('success', 'Successfully saved.', 'Success!')
            this.loading = false
            this.isModalOpen = false
          })
          .catch(() => {
            this.onCallToastr('error', 'Something went wrong!', 'Error!')
            this.loading = false
          })
    },
    close() {
      this.isModalOpen = false
      this.notes = ''
    }
  }
}
</script>
