import axios from 'axios'

export default {
  methods: {
    getSuperAdminDashboard (params) {
      return axios.get('/super-admin-dashboard', {params})
    },
    getLegSupportUsers() {
      return axios.get(`/leg-support`)
    },
    getRecipients(params) {
      return axios.get(`/get-recipients`, {params})
    },
    sendSuperAdminTestMessage(payload) {
      return axios.post(`/super-admin-test-messages`, this.packageData(payload))
    },
    remindMessage(messageId, payload) {
      return axios.post(`/message/${messageId}/super-admin-remind-message`, payload)
    }
  }
}
