<template>
  <v-container fluid grid-list-lg class="pa-0">
    <v-progress-linear
        style="z-index: 6"
        color="warning"
        indeterminate
        height="3"
        fixed
        top
        v-if="loading || loadingSystemMessage"
    ></v-progress-linear>

        <v-layout row wrap>
          <v-flex xs12 md3>
            <v-card elevation="4" class="mx-2 fill-height d-flex flex-column">
              <v-card-title class="headline">
                Clients
              </v-card-title>
              <v-card-text class="d-flex flex-1 px-5 pb-5">
                <v-layout row align-center>

                  <v-flex xs6 class="text-center">
                    <div class="card-title-small font-weight-medium">
                      {{ clientCard.countActiveClients }}
                    </div>
                    <span>Active</span>
                  </v-flex>

                  <v-flex xs6 class="text-right">
                    <v-chip class="mb-4" label small color="primary">
                      Total: {{ clientCard.countTotalClients }}
                    </v-chip>
                    <br />
                    <v-chip class="mb-4" label small :color="clientCard.countInactiveClients ? 'error' : 'success'">
                      Inactive: {{ clientCard.countInactiveClients }}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md3>
            <v-card elevation="4" class="mx-2 fill-height d-flex flex-column">
              <v-card-title class="headline">
                Users
              </v-card-title>
              <v-card-text class="d-flex flex-1 px-5 pb-5">
                <v-layout row align-center>

                  <v-flex xs6 class="text-center">
                    <div class="card-title-small font-weight-medium">
                      {{ usersCard.countActiveVipUsers }}
                    </div>
                    <span>Active Admins</span>
                  </v-flex>

                  <v-flex xs6 class="text-right">
                    <v-chip class="mb-4" label small color="primary">
                      Coordinators: {{ usersCard.countActiveCoordinatorUsers }}
                    </v-chip>
                    <br />
                    <v-chip class="mb-4" label small color="primary">
                      KP1s: {{ usersCard.countTotalKp1 }}
                    </v-chip>
                    <br />
                    <v-chip class="mb-4" label small color="primary">
                      Advocates: {{ usersCard.countAdvocacy }}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md6>
            <v-card elevation="4" class="mx-2 fill-height d-flex flex-column">
              <v-card-title class="headline">
                Message Clients
                <v-spacer></v-spacer>
                <v-btn color="warning" small @click="onSendClientsMessage">
                  New Message
                  <v-icon dark class="ml-2">playlist_add_check</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="d-flex flex-1 px-5 pb-5">
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 md6>
            <v-card elevation="4" class="mx-2 fill-height d-flex flex-column">
              <v-card-title class="headline">
                KPs
              </v-card-title>
              <v-card-text class="d-flex px-5 pb-5">
                <v-data-table
                    class="elevation-1 w-100"
                    :options.sync="list.pagination"
                    :server-items-length="list.pagination.totalItems"
                    :footer-props="tableFooter"
                    :headers="tableHeader"
                    :items="list.items"
                    :item-key="'id_' + getRandomInt()"
                    @update:options="loadKPsData"
                >
                  <template v-slot:top>
                    <div class="d-flex flex-wrap justify-md-end">
                      <div class="sa-page_table-filter">
                        <v-select
                          label="Client"
                          dense
                          hide-details
                          v-model="filter.filterClientId"
                          width="200"
                          :items="clients"
                          item-text="assoc_abbrev"
                          item-value="id"
                          :disabled="loading"
                          clearable
                        ></v-select>
                      </div>
                      <div class="sa-page_table-filter">
                        <v-select
                          label="KP Position"
                          dense
                          hide-details
                          v-model="filter.filterKpPosition"
                          max-width="200"
                          min-width="200"
                          :items="kp_positions"
                          :disabled="loading"
                          clearable
                        ></v-select>
                      </div>
                    </div>
                  </template>

                  <template v-slot:item.nickname="{ item }">
                    <div class="d-flex align-center">
                      <span class="mr-1 no-line-break">
                        {{ item.nickname }} {{ item.lastname }}
                      </span>
                      <v-tooltip bottom v-if="!!item.cell">
                        <template v-slot:activator="{ on }">
                          <a :href="'tel:' + item.cell" v-on="on">
                            <v-icon v-if="!item.bad_cell" color="primary" size="16" class="mx-1">
                              phone_iphone
                            </v-icon>
                            <v-icon v-else color="error" size="16" class="mx-1">
                              no_cell
                            </v-icon>
                          </a>
                        </template>
                        <span>{{ item.cell }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="!!item.email">
                        <template v-slot:activator="{ on }">
                          <a :href="'mailto:' + item.email" v-on="on">
                            <v-icon :color="isBad(item.email) ? 'error' : 'primary'" size="16" class="mx-1">email</v-icon>
                          </a>
                        </template>
                        <span>{{ item.email }}</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <template v-slot:item.kp_position="{ item }">
                    {{ item.kp_position }}
                  </template>

                  <template v-slot:item.client_id="{ item }">
                    <div class="d-flex align-center justify-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip small label v-on="on">{{ item.client.assoc_abbrev }}</v-chip>
                        </template>
                        <span>{{ item.client.association }}</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <template v-slot:no-data>
                    <p class="text-xs-center mb-0">{{ loading ? 'Loading data...' : 'No member yet.' }}</p>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 md6 >
            <v-card
              elevation="4"
              class="mx-2 fill-height d-flex flex-column"
            >
              <v-card-title class="headline">
                System Messages
                <v-spacer></v-spacer>
                <v-btn color="warning" small @click="$refs.messageModal.open(0)">
                  New Message
                  <v-icon dark class="ml-2">playlist_add_check</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text class="d-flex px-5 pb-5">
                <v-data-table
                  :loading="loadingSystemMessage"
                  class="elevation-1 w-100"
                  :options.sync="systemMessagesList.pagination"
                  :server-items-length="systemMessagesList.pagination.totalItems"
                  :headers="systemMessagesTableHeader"
                  :items="systemMessagesList.items"
                  :footer-props="tableFooter"
                  :item-key="'id'"
                  @update:options="listSystemMessages"
                >

                  <template v-slot:item.action="{ item }">
                    <div class="d-flex">
                      <v-btn icon class="mx-0" :disabled="loadingSystemMessage" @click="$refs.messageModal.open(item.id)">
                        <v-icon size="18" color="primary">edit</v-icon>
                      </v-btn>
                      <v-btn icon class="mx-0" :disabled="loadingSystemMessage" @click="onDeleteMessage(item.id)">
                        <v-icon size="18" color="error">delete</v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <template v-slot:item.content="{ item }">
                    <span v-html="item.content"></span>
                  </template>

                  <template v-slot:item.active="{ item }">
                    <v-icon color="success" v-if="item.active">check_circle</v-icon>
                    <v-icon color="error" v-else>cancel</v-icon>
                  </template>

                  <template v-slot:item.style="{ item }">
                    <div>{{ getStyleTitleText(item.style) }}</div>
                  </template>

                  <template v-slot:item.created_at="{ item }">
                    <div>{{ formatDate(item.created_at) }}</div>
                  </template>

                </v-data-table>
              </v-card-text>
            </v-card>
          </v-flex>

        </v-layout>

    <system-message-modal
      ref="messageModal"
      :stylesList="styles"
      @update="listSystemMessages"
    >
    </system-message-modal>

    <clients-messages-modal
      ref="clientsMessagesModal"
    />
  </v-container>
</template>

<script>
import SuperAdminService from '@/services/SuperAdminService'
import ClientService from '@/services/ClientService'
import SystemMessageService from '@/services/SystemMessageService'
import SystemMessageModal from '@/components/command/system-message-modal'
import ClientsMessagesModal from '@/components/command/clients-message-modal'
import styles from '@/entities/system-messages'
import swal from "sweetalert2";

export default {
  name: "CommandPage",
  mixins: [SuperAdminService, ClientService, SystemMessageService],
  components: {
    'system-message-modal': SystemMessageModal,
    'clients-messages-modal': ClientsMessagesModal
  },
  data() {
    return {
      list: {
        pagination: {
          page: 1,
          itemsPerPage: 10,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
          sortBy: [],
          sortDesc: []
        },
        items: [],
      },
      tableFooter: {
        'items-per-page-options': [10, 20, 40]
      },
      filter: {
        filterClientId: null,
        filterKpPosition: null,
      },
      tableHeader: [
        {text: 'Member', value: 'nickname', align: 'left'},
        {text: 'KP Position', value: 'kp_position', align: 'center'},
        {text: 'Client', value: 'client_id', align: 'center'},
      ],
      loading: false,
      loadingSystemMessage: false,
      clientCard: {
        countActiveClients: 0,
        countInactiveClients: 0,
        countTotalClients: 0
      },
      usersCard: {
        countActiveCoordinatorUsers: 0,
        countActiveVipUsers: 0,
        countTotalKp1: 0
      },
      clients: [],
      kp_positions: [1, 2, 3, 4, 5],
      systemMessagesList: {
        pagination: {
          page: 1,
          itemsPerPage: 10,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
          order: [],
          direction: []
        },
        items: [],
      },
      styles: styles
    }
  },
  computed: {
    systemMessagesTableHeader () {
      return [
        { text: '', value: 'action', align: 'center', sortable: false },
        { text: 'Content', value: 'content', align: 'left', width: '45%'},
        { text: 'Active', value: 'active', align: 'center' },
        { text: 'Style', value: 'style', align: 'center' },
        { text: 'Create At', value: 'created_at', align: 'center' },
      ]
    },
  },
  methods: {
    loadKPsData() {
      this.loading = true
      let params = {
        page: this.list.pagination.page,
        limit: this.list.pagination.itemsPerPage,
        sort: this.list.pagination.sortBy[0],
        direction: this.list.pagination.sortDesc[0] ? 'desc' : 'asc',
        filterClientId: this.filter.filterClientId ? this.filter.filterClientId : null,
        filterKpPosition: this.filter.filterKpPosition ? this.filter.filterKpPosition : null,
      }

      return this.getSuperAdminDashboard(params).then(response => {
        this.list.items = response.data.KpsTable.data
        this.list.pagination.totalItems = response.data.KpsTable.total ? response.data.KpsTable.total : response.data.KpsTable.length
        this.list.pagination.current_page = response.data.KpsTable.current_page
        this.list.pagination.per_page = response.data.KpsTable.per_page
        this.list.pagination.last_page = response.data.KpsTable.last_page
        this.clientCard = response.data.clientCard
        this.usersCard = response.data.usersCard
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    listSystemMessages() {
      this.loadingSystemMessage = true
      let reqParams = {
        page: this.systemMessagesList.pagination.page,
        limit: this.systemMessagesList.pagination.itemsPerPage,
        order: this.systemMessagesList.pagination.order[0],
        direction: this.systemMessagesList.pagination.direction[0] ? 'asc' : 'desc',
      }

      this.getSystemMessages(reqParams).then(response => {
        this.systemMessagesList.items = response.data.data
        this.systemMessagesList.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.systemMessagesList.pagination.current_page = response.data.current_page
        this.systemMessagesList.pagination.per_page = response.data.per_page
        this.systemMessagesList.pagination.last_page = response.data.last_page
        this.loadingSystemMessage = false
      }, () => {
        this.loadingSystemMessage = false
      })
    },
    getClientList() {
      this.getClients().then(response => {
        this.clients = response.data
      })
    },
    getRandomInt() {
      return Math.floor(Math.random() * Math.floor(100));
    },
    onSendClientsMessage() {
      this.$refs.clientsMessagesModal.toggle()
    },
    onDeleteMessage(messageId) {
      swal({
        title: 'Are you sure to delete this Message?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteSystemMessage(messageId)
            .then(() => {
              this.onCallToastr('success', 'Message has been deleted.', 'Success!')
              this.listSystemMessages()
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })
    },
    getStyleTitleText(value) {
      let style = this.styles.find(item => item.value === value)
      return style ? style.title : ''
    }
  },
  mounted() {
    this.getClientList()
    this.loadKPsData()
    this.listSystemMessages()
  },
  watch: {
    'filter.filterClientId' () {
      this.loadKPsData()
    },
    'filter.filterKpPosition' () {
      this.loadKPsData()
    }
  },
}
</script>
