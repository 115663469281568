import axios from 'axios'

export default {
  computed: {
    selectedModalDistrict: {
      get() {
        return this.$store.state.app.selectedModalDistrict
      },
      set(newValue) {
        this.$store.dispatch('app/setSelectedModalDistrict', {'district':newValue})
      }
    },
    districtModalEnabled: {
      get() {
        return this.$store.state.app.districtModalEnabled
      },
      set(newValue) {
        this.$store.dispatch('app/setDistrictModalEnabled', {'status':newValue})
      }
    }
  },
  methods: {
    searchDistrictByAddress (payload) {
      return axios.post('/districts/search-by-address', payload)
    },
    openDistrictModal(district){
      if(typeof district == "undefined" ) return

      this.$store.dispatch('app/setSelectedModalDistrict', {'district':district})
      this.$store.dispatch('app/setDistrictModalEnabled', {'status':true})
    },

    // Api
    getStateDistricts (params) {
      return axios.get('/districts', { params })
    },
    saveStateDistrict (payload) {
      return axios.post('/districts/store', payload)
    },
    deleteStateDistrict (district_id) {
      return axios.post(`/districts/${district_id}/delete`)
    },
    getStateDistrictById (district_id) {
      return axios.get(`/districts/${district_id}`)
    },
    getKmlFileByDistrict (district_id) {
      return axios.get(`/districts/${district_id}/kml-file`)
    },
  }
}
