import wretch from 'wretch'

export default {
  methods: {
    uploadToCloudinary (file) {
      const url = 'https://api.cloudinary.com/v1_1/kpdash/upload'
      return wretch(url)
        .formData({
          file,
          upload_preset: 'official_image',
          tags: 'official_image'
        })
        .post()
    }
  }
}
