<template>
  <div class="app_pagination">
    <div v-if="count > 0" class="app_pagination__text">
      <span>{{ ((page - 1) * limit) + 1 }}-{{ page * limit }} of {{ count }}</span>
    </div>
    <div class="app_pagination__buttons">
      <button
        @click="prevPage"
        :class="{ active: page > 1 }"
        :style="{ backgroundColor: $vuetify.theme.dark ? '#312d4b' : '#fff' }"
      ><i class="material-icons">navigate_before</i></button>
      <button
        @click="nextPage"
        :class="{ active: page < pagesCount }"
        :style="{ backgroundColor: $vuetify.theme.dark ? '#312d4b' : '#fff' }"
      ><i class="material-icons">navigate_next</i></button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    props: ['page', 'count', 'limit', 'pagesCount'],
    data() {
      return {
        scrollOptions: {
          duration: 500,
          easing: 'easeInOutCubic',
        }
      }
    },
    methods: {
      nextPage() {
        this.$emit('changePage', Math.min(this.pagesCount, this.page + 1))
        this.$vuetify.goTo(0, this.scrollOptions)
      },
      prevPage() {
        this.$emit('changePage', Math.max(1, this.page - 1))
        this.$vuetify.goTo(0, this.scrollOptions)
      },
    },
  }
</script>
