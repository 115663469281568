<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog max-width="900" content-class="member-detail" v-model="isModalOpen" :fullscreen="isMobile"
            @click:outside="onClose">
    <!-- Sub Sidebar -->
    <v-card :loading="loading" class="flex-1 member-detail_modal">
      <v-card-title>
        <span class="title">{{ member.nickname }} {{ member.lastname }}</span>
        <v-spacer />
        <div
          v-if="isVIP && isMemberKP1 || isSuperAdmin && isMemberKP1"
          class="d-none d-sm-flex subtitle-2 align-center pl-2"
        >
          {{ ezLink }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="copyText(ezLink)"
                class="ml-1"
              >
                <v-icon small color="grey theme--light">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>copy {{ member.nickname }}'s login link</span>
          </v-tooltip>
        </div>
        <v-spacer />
        <v-icon class="ml-3" @click="onClose">close</v-icon>
      </v-card-title>
      <v-card-title
        v-if="isVIP && isMemberKP1 || isSuperAdmin && isMemberKP1"
        class="pt-0 pb-1 mt-n4 d-block d-sm-none"
      >
        <span class="subtitle-2 align-center">
          {{ ezLink }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="copyText(ezLink)"
                class="ml-1"
              >
                <v-icon small color="grey theme--light">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>copy {{ member.nickname }}'s login link</span>
          </v-tooltip>
        </span>
      </v-card-title>
        <!-- Main Sidebar -->
        <v-tabs
          :vertical="$vuetify.breakpoint.smAndUp"
          show-arrows
          v-model="tabs"
        >
          <v-tooltip bottom v-for="(item, index) in tabItemList" :key="index" v-if="item">
          <template v-slot:activator="{ on }">
            <v-tab :class="{'active': item.title === activeTabName}" @click="onSelectTab(item.title)" v-on="on">
              <v-icon size="25" v-if="item.icon">{{ item.icon }}</v-icon>
              <div class="tab-item" v-else>{{ item.text }}</div>
            </v-tab>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>

        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="(item, index) in tabItemList" :key="index">
          <v-card v-if="activeTabName === 'General'" flat>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12 class="px-2">
                  <v-avatar v-if="member.avatar_url" tile size="140" :style="kpPreferenceBorder(member, '5px',
                  '-left')" class="mb-4">
                    <v-img
                      lazy-src="/images/member-avatar.jpg"
                      contain
                      :src="member.avatar_url"
                      :alt="member.fullname"
                      width="140"
                      height="140"
                    />
                  </v-avatar>
                  <v-avatar v-else tile size="140" :style="kpPreferenceBorder(member, '5px', '-left')" class="mb-4">
                    <img
                      src="/images/member-avatar.jpg"
                      width="140"
                      height="140"
                    />
                  </v-avatar>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm6 v-if="member.cell" class="px-2">
                  <v-text-field
                    label="Cell"
                    outlined
                    dense
                    append-icon="call"
                    v-model="member.cell"
                    @click:append="onRequestCall"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 v-if="member.email" class="px-2">
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    append-icon="email"
                    v-model="member.email"
                    @click:append="onSendEmail"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-2">
                  <v-textarea
                    label="Advocate Notes"
                    outlined
                    dense
                    rows="4"
                    v-model="member.notes"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  v-if="isVIP || isSuperAdmin"
                  class="ma-2"
                  small
                  color="primary"
                  @click="onSave"
              >
                Save Notes
                <v-icon small right>save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="activeTabName === 'Assignment History'" flat>
            <v-card-text>
              <v-flex xs12 class="text-xs-center">
                <v-data-table
                  class="elevation-1"
                  :headers="kpHistoryTableHeader"
                  :items="kpHistory"
                  sort-by.sync="end_date"
                  :sort-desc.sync="sortDesc"
                  :loading="loading"
                >
                  <template v-slot:item.legislatorTitle="{ item }">
                    <td :style="item.end_date != 'Present' ? 'background-color: rgba(0, 0, 0, 0.1);' : ''">
                      <a @click="$refs.legislatorDetailModal.toggle(item.legislator)">
                        {{ item.legislatorTitle }}
                        <v-tooltip bottom v-if="item.legislator.lame_duck">
                          <template v-slot:activator="{ on }">
                            <a v-on="on">
                              <v-icon color="#EECB23" size="18" class="mx-2">fa-solid fa-duck</v-icon>
                            </a>
                          </template>
                          <span>Lame Duck</span>
                        </v-tooltip>
                      </a>
                    </td>
                  </template>
                  <template v-slot:item.district="{ item }">
                    <td :style="item.end_date != 'Present' ? 'background-color: rgba(0, 0, 0, 0.1);' : ''">
                      <a @click="openDistrictModal(item.district)">{{ item.district }}</a>
                    </td>
                  </template>
                  <template v-slot:item.position="{ item }">
                    <td :style="item.end_date != 'Present' ? 'background-color: rgba(0, 0, 0, 0.1);' : ''">
                      {{ item.position }}
                    </td>
                  </template>
                  <template v-slot:item.start_date="{ item }">
                    <td :style="item.end_date != 'Present' ? 'background-color: rgba(0, 0, 0, 0.1);' : ''">
                      {{ item.start_date }}
                    </td>
                  </template>
                  <template v-slot:item.end_date="{ item }">
                    <td :style="item.end_date != 'Present' ? 'background-color: rgba(0, 0, 0, 0.1);' : ''">
                      {{ item.end_date }}
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
            </v-card-text>
          </v-card>
          <v-card v-if="activeTabName === 'Advocacy Messages'" flat>
            <v-card-text>
              <v-flex xs12>
                <v-data-table
                  class="elevation-1"
                  :headers="advocacyMessageHistoryTableHeader"
                  :items="advocacyMessageHistory"
                >
                  <template v-slot:item.sent_at="{ item }">
                    {{ item.sent_at | dateTimeFormatter }}
                  </template>
                  <template v-slot:item.legislator_name="{ item }">
                    {{ item.legislator_name }}
                    <v-tooltip bottom v-if="item.lame_duck">
                      <template v-slot:activator="{ on }">
                        <a v-on="on">
                          <v-icon color="#EECB23" size="18" class="mx-2">fa-solid fa-duck</v-icon>
                        </a>
                      </template>
                      <span>Lame Duck</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:item.message="{ item }" v-if="isVIP">
                    <div>
                      <v-tooltip bottom v-if="item.messageForLegislator">
                        <template v-slot:activator="{ on }">
                          <a v-on="on"
                             @click="onOpenSentMessageModal(item.message ? item.message : item.messageForLegislator)">
                            <v-icon color="primary" size="35" class="mx-1">drafts</v-icon>
                          </a>
                        </template>
                        <span>Read Advocacy Message</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <template v-slot:item.reply_sent_at="{ item }">
                    <div class="d-flex align-center justify-center">
                      <v-icon color="success" v-if="item.reply_sent_at">check_circle</v-icon>
                      <v-icon color="error" v-else>cancel</v-icon>
                    </div>
                  </template>
                </v-data-table>
              </v-flex>
            </v-card-text>
          </v-card>
          <!-- Start message tab's content  -->
          <v-card v-if="activeTabName === 'Messages' && !isSuperAdmin" flat >
            <v-card-text class="pb-2">
              <div class="d-flex message-view-buttons">
                <v-btn
                    small
                    tile
                    outlined
                    @click="changeMessageType(0)"
                    :color="messageType === 0 ? 'primary' : 'grey lighten-1'"
                    class="ma-0"
                >
                  <v-icon size="18">mail_outline</v-icon>
                  <span v-if="isDesktop || isDevice">Email messages</span>
                  <span v-else>Email</span>
                </v-btn>
                <v-btn
                    small
                    tile
                    outlined
                    @click="changeMessageType(1)"
                    :color="messageType === 1 ? 'primary' : 'grey lighten-1'"
                    class="ma-0"
                >
                  <v-icon size="18">phone_iphone</v-icon>
                  <span v-if="isDesktop || isDevice">Sms messages</span>
                  <span v-else>Sms</span>
                </v-btn>
              </div>

              <div class="message-view-modal" ref="messagesBlock" v-if="groupedMessageList && groupedMessageList.length">
                <v-btn
                    small
                    block
                    text
                    @click="loadMoreMessages"
                    v-if="messages.pagination.last_page > messages.pagination.page"
                    :loading="isMessagesLoading"
                >
                  <span>Show more messages</span>
                </v-btn>
                <div v-for="(group, index) in groupedMessageList" :key="index">
                  <div>
                    <v-layout align-center class="my-4">
                      <v-divider></v-divider>
                      <div class="px-4">{{ group.date }}</div>
                      <v-divider></v-divider>
                    </v-layout>
                  </div>
                  <div class="mb-2" v-for="message in group.messageList" :key="message.id">
                    <v-layout>
                      <v-spacer v-if="!message.is_reply"></v-spacer>
                      <v-avatar :color="getColor(member.nickname)" v-if="message.is_reply">
                        <span class="white--text headline">{{ member.nickname[0] }}</span>
                      </v-avatar>
                      <div class="message tinyEditor readonly" :class="{'reply': message.is_reply}" >
                        <v-layout align-center>
                          <div class="font-weight-bold">
                            {{ client.assoc_abbrev }}
                            {{ message.is_reply ? `- ${member.nickname} ${member.lastname}` : '' }}
                          </div>
                          <v-spacer />
                          <div class="ml-3">{{ message.created_at | timeFormatter }}</div>
                        </v-layout>
                        <div class="mt-1" v-html="message.message"></div>
                      </div>
                      <v-avatar v-if="!message.is_reply">
                        <span class="headline tinyEditor readonly">{{ client.assoc_abbrev }}</span>
                      </v-avatar>
                    </v-layout>
                  </div>
                </div>
              </div>
              <v-layout class="align-center column pa-5 empty-message-box" v-else>
                <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="isMessagesLoading"
                    size="22"
                    width="2"
                ></v-progress-circular>
                <v-alert type="info" outlined class="mt-2">
                  {{ isMessagesLoading ? 'Loading messages...' : 'There is no messages yet...'}}
                </v-alert>
              </v-layout>
              <!-- Import message input box -->
              <message-input-box 
                :selectedMember="member" 
                :messageType="messageType"
                @reFetchMessage="fetchMessageList"
              ></message-input-box>
            </v-card-text>
          </v-card>
          <!-- End message tab's content  -->
          <v-card v-if="activeTabName === 'Legislators'" flat>
            <v-card-text>
              <v-flex xs12 class="text-xs-center">
                <v-data-table
                  class="elevation-1 has_gray_bg"
                  :headers="legislatorsHeader"
                  :items="legislators"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                >
                  <template v-slot:item.fullname="{ item }">
                    <div>
                      <a @click="$refs.legislatorDetailModal.toggle(item)">{{ item.fullname }}</a>
                      <v-tooltip bottom v-if="item.lame_duck">
                        <template v-slot:activator="{ on }">
                          <a v-on="on">
                            <v-icon color="#EECB23" size="18" class="mx-2">fa-solid fa-duck</v-icon>
                          </a>
                        </template>
                        <span>Lame Duck</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:item.district="{ item }">
                    <div>
                      <a @click="openDistrictModal(item.district.district)">{{ item.district.district }}</a>
                    </div>
                  </template>
                  <template v-slot:item.position="{ item }">
                    <div>
                      {{ getLegislatorKp(item).fullname }}
                      <v-tooltip bottom v-if="getLegislatorKp(item).kp_preference !== 'unknown'">
                        <template v-slot:activator="{ on }">
                          <v-avatar v-on="on" :color="kpPreferenceColor(getLegislatorKp(item))" size="10"></v-avatar>
                        </template>
                        <span>{{ kpPreferenceTitle(getLegislatorKp(item)) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="getLegislatorKp(item).political_leaning_id !== null">
                        <template v-slot:activator="{ on }">
                          <v-avatar class="mx-1" v-on="on" :color="politicalLearningsColor(getLegislatorKp(item))" size="10"></v-avatar>
                        </template>
                        <span>{{ politicalLearningsTitle(getLegislatorKp(item)) }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:item.region="{ item }">
                    <div>
                      {{ item.district.region ? item.district.region.name : '—' }}
                    </div>
                  </template>
                  <template v-slot:item.member="{ item }">
                    <div>
                      {{ item.district && item.district.region && item.district.region.member && item.district.region.member.fullname ? item.district.region.member.fullname : '—' }}
                    </div>
                  </template>
                </v-data-table>
              </v-flex>
            </v-card-text>
          </v-card>
          <v-card v-if="activeTabName === 'KP Tasks' && (activeKP1 || isMemberKPCCD)" class="card-content" column justify-center
               align-center >
            <kp-tasks-component :member="member" :isFullScreen="true"/>
          </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-actions style="background-color: #fff">
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="isShowSendLoginLinkBtn()"
            class="ma-2"
            outlined small
            v-on="on"
            color="primary"
            @click="onQuickMenuHandler('login')"
                :disabled="isDisabledMessageBtn()"
          >
            Send KP Login
            <v-icon small right>link</v-icon>
          </v-btn>
          </template>
            <span>Quickly send a login link to only this one KP.</span>
          </v-tooltip>
        </span>
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="isVIP || isSuperAdmin"
                v-on="on"
                class="ma-2"
                outlined small
                color="primary"
                @click="onQuickMenuHandler('message')"
                :disabled="isDisabledMessageBtn()"
              >
              Send Message
                <v-icon small right>send</v-icon>
              </v-btn>
            </template>
            <span>Send a message via email or SMS to only this one Advocate.</span>
          </v-tooltip>
        </span>
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="isShowProfileUpdateLinkBtn()"
                class="ma-2"
                v-on="on"
                outlined small
                color="primary"
                @click="onQuickMenuHandler('profile')"
                :disabled="isDisabledMessageBtn()"
              >
                Send Profile Update
                <v-icon small right>account_circle</v-icon>
              </v-btn>
            </template>
            <span>Quickly send a profile update to only this one Advocate.</span>
          </v-tooltip>
        </span>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isVIP || isSuperAdmin"
          class="ma-2"
          outlined small
          color="primary"
          @click="onEditMember"
        >
          Edit Profile
          <v-icon small right>edit</v-icon>
        </v-btn>
        <!--adam moved this button dec10 2021-->
      </v-card-actions>
    </v-card>

    <send-text-message-modal ref="sendText"></send-text-message-modal>
    <sent-message-modal ref="sentMessageModal"></sent-message-modal>
    <legislator-detail-modal ref="legislatorDetailModal" :refresh="update"></legislator-detail-modal>
    <edit-member-modal ref="editMemberModal" :client-list="clientList" :selectedClient="client" :refresh="update"></edit-member-modal>
  </v-dialog>
</template>

<script>
  import swal from 'sweetalert2'
  import AuthService from '@/services/AuthService'
  import MemberService from '@/services/MemberService'
  import ClientService from '@/services/ClientService'
  import DistrictService from '@/services/DistrictService'
  import AppService from '@/services/AppService'

  import MessagesInputBox from '@/components/messages/message-input-box'
  import SentMessageModal from '@/components/advocacy/sent-message-modal'
  import legislatorDetailModal from '@/components/legislators/legislator-detail-modal'
  import editMemberModal from '@/components/members/edit-member-modal'
  import KpTasksComponent from '@/components/kp-tasks/kp-tasks-component';
  import SendTextMessage from "@/components/advocacy/send-text-message-modal";
  import LegislatorService from "@/services/LegislatorService";
  import {mapGetters} from "vuex";
  import politicalLeaningsBorder from "@/mixins/politicalLeaningsBorder";

  export default {
    name: 'MemberDetailModal',
    mixins: [
      MemberService, ClientService, DistrictService, AuthService,
      AppService, LegislatorService, politicalLeaningsBorder
    ],
    components: {
      'sent-message-modal': SentMessageModal,
      'message-input-box': MessagesInputBox,
      'legislator-detail-modal': legislatorDetailModal,
      'edit-member-modal': editMemberModal,
      'kp-tasks-component' : KpTasksComponent,
      'send-text-message-modal': SendTextMessage,
    },
    data () {
      return {
        isModalOpen: false,
        activeTabName: "General",
        activeKP1: false,
        member: {},
        kpHistory: [],
        legislators: [],
        advocacyMessageHistory: [],
        politicalLearnings: [],
        sortBy: "start_date",
        sortDesc: true,
        messages: {
          pagination: {
            page: 1,
            itemsPerPage: 15,
            last_page: 0,
          },
          items: []
        },
        messageType: 0,
        isMessagesLoading: false,
        selectedClientId: null,
        clientList: [],
        loading: false,
        ezLink: '',
        tabs: null
      }
    },
    mounted() {
      this.getPoliticalLeanings()
          .then(response => {
            this.politicalLearnings = response.data
          })
    },
    computed: {
      ...mapGetters('app', ['superAdminSelectedClient']),
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      tabItemList () {
        let menuItems = [
            {
              icon: 'account_box',
              title: 'General',
            },
            {
              text: 'KP',
              title: 'Assignment History',
            },
        ];

        if (this.isSuperAdmin) {
          menuItems = menuItems.concat([
            {
              icon: 'fa-check-square',
              title: 'KP Tasks',
            },
            {
              icon: 'account_circle',
              title: 'Legislators',
            },
          ])
        } else if (this.isKP) {
          menuItems = menuItems.concat([
            {
              icon: 'forum',
              title: 'Messages',
            },
            {
              icon: 'account_circle',
              title: 'Legislators',
            }
          ])
        } else if (this.isShowKPTaskTabs) {
          menuItems = menuItems.concat([
            {
              icon: 'forum',
              title: 'Messages',
            },
            {
              icon: 'account_circle',
              title: 'Legislators',
            },
            {
              icon: 'fa-check-square',
              title: 'KP Tasks',
            },
          ])
        } else {
          menuItems = menuItems.concat([
            {
              icon: 'forum',
              title: 'Messages',
            },
            {
              icon: 'account_circle',
              title: 'Legislators',
            }
          ])
        };

        if((this.client && this.client.tariff && this.client.tariff.name === 'Premium') || (this.advocacyMessageHistory && this.advocacyMessageHistory.length !== 0)) {
          menuItems.splice(2, 0, {
              icon: 'account_balance',
              title: 'Advocacy Messages',
            });
        } 
        
        // else {
        //   delete menuItems[2]
        // }

        return menuItems;
      },
      kpHistoryTableHeader () {
        return [
          { text: 'Legislator', value: 'legislatorTitle', align: 'center'},
          { text: 'District', value: 'district', align: 'center'},
          { text: 'KP Position', value: 'position', align: 'center'},
          { text: 'Start Date', value: 'start_date', align: 'center'},
          { text: 'End Date', value: 'end_date', align: 'center', sortable: true},
        ]
      },
      legislatorsHeader () {
        return [
          { text: 'Legislator', value: 'fullname', align: 'center'},
          { text: 'District', value: 'district', align: 'center'},
          { text: 'KP1', value: 'position', align: 'center'},
          { text: 'Region', value: 'region', align: 'center'},
          { text: 'Coordinator', value: 'member', align: 'center'},
        ]
      },
      advocacyMessageHistoryTableHeader () {
        if(this.isVIP) {
          return [
            { text: 'Date', value: 'sent_at'},
            { text: 'Legislator', value: 'legislator_name'},
            { text: 'Message', value: 'message', align: 'center', sortable: false },
            { text: 'Sent?', value: 'reply_sent_at', align: 'center'},
          ]
        }
        return [
          { text: 'Date', value: 'sent_at'},
          { text: 'Legislator', value: 'legislator_name'},
          { text: 'Sent?', value: 'reply_sent_at', align: 'center'},
        ]
      },
      groupedMessageList () {
        let messageList = this.messages.items.filter(message => message.is_sms === this.messageType).reverse()
        let groupedMessageMap = _.groupBy(messageList, (message) => {
          return this.getLocaleFromUTC(message.created_at, 'dddd MMMM DD, YYYY')
        })
        let groupedMessageList = []
        _.forEach(groupedMessageMap, (messageList, date) => {
          groupedMessageList.push({
            date,
            messageList
          })
        })
        return groupedMessageList
      },
      isMemberKP1 () {
        return this.member.is_kp1 && this.member.activeKP1
      },
      isMemberKPCCD () {
        let show = false;
        if (this.member && this.member.member_legislator) {
        this.member.member_legislator.forEach(kp => {
            if(kp.kp_position !== '1' && kp.end_date == null && kp.send_copy){
              show =true;
              return false;
            }
          }); 
        }

        return show;
      },
      isShowKPTaskTabs(){
        let show = false;
        if (this.member && this.member.member_legislator) {
        this.member.member_legislator.forEach(kp => {
            if(kp.kp_position == 1 && kp.end_date == null){
              show =true;
              return false;
            }else if(kp.kp_position == 2 && kp.end_date == null && kp.send_copy){
              show =true;
              return false;
            }
          }); 
        }
        return show;
      }
    },
    methods: {
      async toggle (member) {
        this.tabs = null
        this.activeKP1 = member.is_kp1 && member.activeKP1
        this.member = []
        this.loading = true
        this.isModalOpen = true
        this.activeTabName = "General"
        this.member = (await this.getMembers({ 'id': member.id })).data
        if (this.activeKP1) await this.getEzLinkMember()
        this.getClientLegislator(this.member)
        await this.fetchKPHistory()
        await this.fetchAdvocacyMessageHistory()
        if (!this.isSuperAdmin) this.fetchMessageList()
        this.clientList = (await this.getClients()).data
        this.setClientId()
        this.loading = false
      },
      isShowProfileUpdateLinkBtn(){
        //Here "Professional" is id = 3 
        //And "premium" is id = 2 it's tarrif table
       return this.client &&  this.client.active &&  this.client.tariff && ([2, 3].includes(this.client.tariff.id));
      },
      isShowSendLoginLinkBtn(){
       return !!((this.isVIP && this.isMemberKP1) || (this.isSuperAdmin && this.isMemberKP1) || this.isMemberKPCCD);
      },
      isDisabledMessageBtn() {
        if (!this.member) return true; // If there is no member, disable the button

        if (this.member.bad_cell || !this.member.active || this.member.silence) return true;
        // Check if email and phone are valid
        const emailIsValid = this.isValidEmail(this.member.email);
        const phoneIsValid = this.isValidPhone(this.member.cell);

        return !(emailIsValid && phoneIsValid);
      },
      openDistrictModal (item) {
        this.$emit('openDistrictModal', item)
      },
      setClientId () {
        if (this.isSuperAdmin)
          this.selectedClientId = this.member.client_id
      },
      async fetchKPHistory () {
        const kpHistory = (await this.getMemberKPHistory({
          id: this.member.id,
          client_id: this.client.id,
        })).data
        this.kpHistory = kpHistory.map((kp) => {
          const startDate = kp.start_date ? moment(kp.start_date).format('MMM DD, YYYY') : ''
          const endDate = kp.end_date ? moment(kp.end_date).format('MMM DD, YYYY') : 'Present'
          const item = {
            legislatorTitle: `${kp.legislator.nickname} ${kp.legislator.lastname}`,
            district: `${kp.legislator.district.district}`,
            position: `KP${kp.kp_position}`,
            start_date: startDate,
            end_date: endDate,
            legislator: kp.legislator
          }
          return item
        })

      },
      async fetchAdvocacyMessageHistory () {
        this.advocacyMessageHistory = (await this.getMemberAdvocacyMessageHistory(this.member.id)).data
      },
      onSelectTab (tabName) {
        this.activeTabName = tabName;
        // When click on the message then scroll to bottom
        if(tabName === 'Messages') {
          this.scrollToBottom();
        }
      },
      onRequestCall () {
        window.location.href = `tel:${this.member.cell}`
      },
      onSendEmail () {
        window.location.href = `mailto:${this.member.email}`
      },
      onClose() {
        this.activeTabName = "General"
        this.messages.items = []
        this.messages.pagination.page = 1
        this.messageType = 0
        this.isModalOpen = false
      },
      onOpenSentMessageModal(message) {
        this.$refs.sentMessageModal.open(message)
      },
      isValidEmail(email) {
        return this.validateEmail(email);
      },
      isValidPhone(phone) {
        return this.validateCell(phone);
      },
      getMessages() {
        this.isMessagesLoading = true
        this.messages.items = []
        let data = {
          page: this.messages.pagination.page,
          limit: this.messages.pagination.itemsPerPage,
          member_id: this.member.id
        }

        return this.getClientMessagesWithPagination(this.client.id, data).then(response => {
          this.messages.items = this.messages.items.concat(response.data.data ? response.data.data : response.data)
          this.messages.pagination.last_page = response.data.last_page
        })
      },
      loadMoreMessages() {
        this.messages.pagination.page++
        this.fetchMessageList()
      },
      scrollToBottom() {
        this.$nextTick().then(() => {
          const element = this.$refs.messagesBlock
          if (element && element.scrollHeight) {
            element.scrollTop = element.scrollHeight
          }
          this.isMessagesLoading = false
        })
      },
      fetchMessageList () {
        this.getMessages().then(()=> {
          if(this.messages.pagination.page == 1) {
            this.scrollToBottom();
          }
        })
      },
      changeMessageType(type) {
        this.messages.pagination.page = 1
        this.messages.items = []
        this.messageType = type

        this.fetchMessageList()
      },
      checkAndSetDistricts(district) {
        if (district == null) return
        return typeof district === "object" ? district.flat() : district
      },
      getClientLegislator(item) {
        this.loading = true
        let form = {
          districts: [
            this.checkAndSetDistricts(item.home_sen_district),
            this.checkAndSetDistricts(item.home_house_district),
            this.checkAndSetDistricts(item.work_sen_district),
            this.checkAndSetDistricts(item.work_house_district),
            this.checkAndSetDistricts(item.work2_sen_district),
            this.checkAndSetDistricts(item.work2_house_district),
            this.checkAndSetDistricts(item.work2_sen_district),
            this.checkAndSetDistricts(item.work3_sen_district),
            this.checkAndSetDistricts(item.work3_house_district),
            this.checkAndSetDistricts(item.work3_sen_district),
            this.checkAndSetDistricts(item.work4_sen_district),
            this.checkAndSetDistricts(item.work4_house_district),
            this.checkAndSetDistricts(item.work4_sen_district),
          ].filter(district => (district))
        }

        return this.getClientLegislatorsWithCommittees(item.client_id, form).then((response) => {
          this.loading = false
          this.legislators = response.data.data ? response.data.data : response.data
        })
      },
      getLegislatorKp(item) {
        let itemKp = item.member_legislators.filter((kp) => {
          return kp.kp_position == 1
        })

        return itemKp[0] && itemKp[0].member ? itemKp[0].member : '-'
      },
      update() {
        this.isModalOpen = false
        this.$parent.update()
      },
      onEditMember() {
        this.$refs.editMemberModal.toggle(this.member)
      },
      onSave() {
        this.loading = true
        this.updateMember(this.member)
          .then(() => {
            this.loading = false
            this.onCallToastr('success', 'Member has been updated.', 'Success!')
          })
          .catch(() => {
            this.loading = false
            this.onCallToastr('error', 'Something went wrong!', 'Error!')
          })
      },
      async getEzLinkMember() {
        let payload = {
          legislator_id: this.member.client_id,
          member_id: this.member.id,
          message: '{ez_login}',
        }
        await this.getAppFormattedMessage(payload).then(response => {
          this.ezLink = response.data;
        })
      },
      copyText(string) {
        navigator.clipboard.writeText(string);
      },
      onQuickMenuHandler(context){
        if(this.member && this.member.id) {
          if( context === 'login' || context === 'profile' ) {
            let title = `Send login link to ${this.member.fullname}?`;
            if(context === 'profile') title = `Send profile update link to ${this.member.fullname}?`;

            swal({
              title,
              type: 'question',
              showCancelButton: true,
              confirmButtonColor: '#4caf50',
              cancelButtonColor: '#9E9E9E',
              confirmButtonText: '&#10003 Send',
              reverseButtons: true,
            }).then( async (result) => {
              if (result.value) {
                  let data = { member_id: this.member.id };

                  if(this.member.cell) {
                    data.phone = this.member.cell
                  } else if(this.member.email){
                    data.email = this.member.email
                  } else {
                    this.onCallToastr('error', 'Sorry, Phone or email is missing!', 'Error!');
                    return true;
                  }

                  if(context === 'profile') {
                    data.sufix = 'prof';
                    try {
                      const response = (await this.sendKPProfileUpdateLink(data)).data;
                      this.onCallToastr('success', response && response.message, 'Success!');
                    } catch (e) {
                      this.onCallToastr('error', e.response && e.response.data && e.response.data.message, 'Error!');
                    }
                    
                  } else {
                    try {
                      const response = (await this.sendKPLoginLink(data)).data;
                      this.onCallToastr('success', response && response.message, 'Success!');
                    } catch (e) {
                      this.onCallToastr('error', e.response && e.response.data && e.response.data.message, 'Error!');
                    }
                  }
              }
            })

          } else if(context === 'message') {
            this.$refs.sendText.toggle(this.member, true, {});
          }
        }

      }
    },
    filters: {
      dateFormatter (value) {
        return moment(value).format('hh:mm A')
      }
    }
  }
</script>

<style>
 .member-detail_modal .message-view-modal {
      width: 100%;
      max-height: calc(90vh - 265px) !important;
      overflow: auto;
  }
</style>