<template>
  <v-dialog v-model="isModalOpen" max-width="600">
    <v-card>
      <v-card-title class="headline">Message Text</v-card-title>
      <v-card-text>
        <div class="tinyEditor" v-html="message"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="isModalOpen = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MessageDetailModal',
  data () {
    return {
      isModalOpen: false,
      message: ''
    }
  },
  methods: {
    open (message) {
      this.isModalOpen = true
      this.message = message
    }
  }
}
</script>
