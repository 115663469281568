import Vue from 'vue'

Vue.mixin({
  methods: {
    transformMembersDistrictsFromArrayToString(member) {
      member.home_house_district = this.issetForMembersDistrict(member.home_house_district)
      member.home_sen_district = this.issetForMembersDistrict(member.home_sen_district)
      member.work_house_district = this.issetForMembersDistrict(member.work_house_district)
      member.work_sen_district = this.issetForMembersDistrict(member.work_sen_district)
      member.work2_house_district = this.issetForMembersDistrict(member.work2_house_district)
      member.work2_sen_district = this.issetForMembersDistrict(member.work2_sen_district)
      member.work3_house_district = this.issetForMembersDistrict(member.work3_house_district)
      member.work3_sen_district = this.issetForMembersDistrict(member.work3_sen_district)
      member.work4_house_district = this.issetForMembersDistrict(member.work4_house_district)
      member.work4_sen_district = this.issetForMembersDistrict(member.work4_sen_district)
      return member
    },
    issetForMembersDistrict(value) {
      const isNull = value === null
      const isArrow = Array.isArray(value)
      return !isNull && isArrow ? value[0] : value
    }
  }
})
