import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"700","fullscreen":_vm.isMobile},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"pb-0"},[_c('span',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.member.fullname)+" notes")]),_c(VSpacer),_c(VIcon,{staticClass:"ml-3",on:{"click":_vm.close}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,[_c(VLayout,{staticClass:"card-content mt-3",attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VTextarea,{staticClass:"ma-1",attrs:{"outlined":""},model:{value:(_vm.member.notes),callback:function ($$v) {_vm.$set(_vm.member, "notes", $$v)},expression:"member.notes"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.onSave()}}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }