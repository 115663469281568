import { Store } from 'vuex'

import * as modules from './modules'

const debug = process.env.NODE_ENV !== 'production'

export default new Store({
  modules: modules,
  strict: debug,
})
