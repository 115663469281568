<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg>

    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>Level Admin</h1>
        <v-spacer></v-spacer>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <v-data-table
      :loading="isLoading"
      class="elevation-1"
      :options.sync="pagination"
      :headers="clientTableHeader"
      :items="tariffList"
    >

      <template v-slot:item.name="{ item }">
        <div>{{ item.name }}</div>
      </template>

      <template v-slot:item.uses_regions_option="{ item }">
        <v-btn icon class="mx-0" @click="deactivateTariff(item.id, 'uses_regions_option')" disabled v-if="item.uses_regions_option">
          <v-icon size="18" color="success">check_circle</v-icon>
        </v-btn>
        <v-btn icon class="mx-0" @click="activeTariff(item.id, 'uses_regions_option')" disabled v-else>
          <v-icon color="error">cancel</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.message_kps="{ item }">
        <v-btn icon class="mx-0" @click="deactivateTariff(item.id, 'message_kps')" disabled v-if="item.message_kps">
          <v-icon size="18" color="success">check_circle</v-icon>
        </v-btn>
        <v-btn icon class="mx-0" @click="activeTariff(item.id, 'message_kps')" disabled v-else>
          <v-icon color="error">cancel</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.advocacy_messages="{ item }">
        <v-btn icon class="mx-0" @click="deactivateTariff(item.id, 'advocacy_messages')" v-if="item.advocacy_messages">
          <v-icon size="18" color="success">check_circle</v-icon>
        </v-btn>
        <v-btn icon class="mx-0" @click="activeTariff(item.id, 'advocacy_messages')" v-else>
          <v-icon color="error">cancel</v-icon>
        </v-btn>
      </template>

      
    </v-data-table>

  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import ClientService from '@/services/ClientService'

export default {
  name: 'ClientsPage',
  mixins: [ClientService],
  data () {
    return {
      isLoading: true,
      tariffList: [],
      pagination: {
        itemsPerPage: 10
      }
    }
  },
  computed: {
    clientTableHeader () {
      return [
        { text: 'Name', value: 'name', align: 'center', sortable: false },
        { text: 'Region Support', value: 'uses_regions_option', align: 'center', sortable: false },
        { text: 'KP Messaging', value: 'message_kps', align: 'center', sortable: false },
        { text: 'Advocacy Messaging', value: 'advocacy_messages', align: 'center', sortable: false },
      ]
    }
  },
  async mounted () {
    this.refresh()
  },
  methods: {
    async refresh () {
      this.isLoading = true
      this.tariffList = (await this.getTariffs()).data
      this.isLoading = false
    },
    activeTariff (rowID, columnName) {
      swal({
        title: 'Are you sure to activate this?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {

          const payload = {
            tariff_id: rowID,
            column_name: columnName,
            status: true,
          }
          this.changeTariffStatus(payload)
            .then(() => {
              this.onCallToastr('success', 'Successfully Activated.', 'Success!')
              this.refresh()
            })
            .catch((err) => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            });
        }
      })
    },
    deactivateTariff (rowID, columnName) {
      swal({
        title: 'Are you sure to deactivate this?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          const payload = {
            tariff_id: rowID,
            column_name: columnName,
            status: false,
          }
          this.changeTariffStatus(payload)
            .then(() => {
              this.onCallToastr('success', 'Successfully Deactivated.', 'Success!')
              this.refresh()
            })
            .catch((err) => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            });
        }
      })
    },
  }
}
</script>
