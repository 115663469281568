import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VForm,{ref:"newCommitteeForm",on:{"submit":function($event){$event.preventDefault();return _vm.onAddCommittee()}}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Add New Committee")])]),_c(VDivider),_c(VCardText,[(_vm.error)?_c(VAlert,{attrs:{"type":"error","outlined":"","value":true}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{staticClass:"px-2",attrs:{"xs12":""}},[_c(VSelect,{attrs:{"label":"Type","item-value":"id","item-text":"text","items":_vm.committeeTypeList,"rules":_vm.typeRules},model:{value:(_vm.newCommittee.type),callback:function ($$v) {_vm.$set(_vm.newCommittee, "type", $$v)},expression:"newCommittee.type"}})],1),_c(VFlex,{staticClass:"px-2",attrs:{"xs12":""}},[_c(VTextField,{attrs:{"label":"Short Name","rules":_vm.shortNameRules},model:{value:(_vm.newCommittee.shortname),callback:function ($$v) {_vm.$set(_vm.newCommittee, "shortname", $$v)},expression:"newCommittee.shortname"}})],1),_c(VFlex,{staticClass:"px-2",attrs:{"xs12":""}},[_c(VTextField,{attrs:{"label":"Full Name","rules":_vm.fullNameRules},model:{value:(_vm.newCommittee.fullname),callback:function ($$v) {_vm.$set(_vm.newCommittee, "fullname", $$v)},expression:"newCommittee.fullname"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},nativeOn:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"type":"submit","color":"primary","loading":_vm.isBusy}},[_vm._v("Add")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }