import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary","size":"24"},on:{"click":function($event){$event.stopPropagation();_vm.isModalOpen = true}}},[_vm._v("help")]),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Tasks Help")]),_c(VCardText,[_vm._v(" Each Session should have tasks assigned. Tasks are specific actions that you would like to track for each Session per Key Person/ Legislator. Examples, \"Call Legislator\", \"Meet with Legisaltor\", \"Donate to Legislator\", \"Predicted Vote from Legislator\". "),_c('br'),_c('br'),_vm._v(" When setting up Tasks, you may choose from our predefined tasks, or create your own custom tasks. Each tasks should have a deadline and the Session Dashboard columns are ordered by Task deadline. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }