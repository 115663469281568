<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.setupFavicon()
  },
  methods: {
    setupFavicon() {
      const colorSchemeQueryList = window.matchMedia('(prefers-color-scheme: dark)')

      document.addEventListener('focusin', function(event) {
          if (event.target.closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root")) {
            event.stopImmediatePropagation();
          }
        })

      const setColorScheme = e => {
        if (e.matches) {
          // Dark
          const favicon = document.querySelectorAll('link[data-type="favicon"]')
          let i = favicon.length
          while (i--) {
            favicon[i].setAttribute('href', favicon[i].dataset.dark)
          }
        } else {
          // Light
          const favicon = document.querySelectorAll('link[data-type="favicon"]')
          let i = favicon.length
          while (i--) {
            favicon[i].setAttribute("href", favicon[i].dataset.light)
          }
        }
      }

      setColorScheme(colorSchemeQueryList)
      colorSchemeQueryList.addEventListener('change', setColorScheme)
    },
  }
};
</script>
