<template>
  <v-dialog max-width="500" v-model="dialog">
    <v-card>
      <v-container>
        <v-card-text class="py-5">
          <div class="confirm-alert-icon">
            <div class="icon" v-if="question">?</div>
          </div>
          <v-flex
            v-if="title && title !== null"
            class="headline text-center"
          >
            {{ title }}
          </v-flex>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn dark color="blue" @click="close">{{ textCancelButton }}</v-btn>
            <v-btn dark color="green" @click="onSuccess">{{ textSuccessButton }}</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import MemberMessageModal from '@/components/members/member-message-modal'

export default {
  name: 'ConfirmationDialog',
  components: {
    'member-message-modal': MemberMessageModal
  },
  props: {
    title: {
      type: String,
      default: null
    },
    textSuccessButton: {
      type: String,
      default: 'Ok'
    },
    textCancelButton: {
      type: String,
      default: 'Cancel'
    },
    question: {
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      isProceed: false
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    onSuccess() {
      this.close()
      this.$emit('onSuccess')
    }
  }
}
</script>
