import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary","size":"24"},on:{"click":function($event){$event.stopPropagation();_vm.isModalOpen = true}}},[_vm._v("help")]),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Resources Page Help")]),_c(VCardText,[_c('p',[_vm._v(" Resources are essentially web pages within the Dashboard that your team and KPs can access for reference. Add as many as you'd like. You can set each Resource to be viewable by different user types. ")]),_c('p',[_vm._v(" You can also securely add private attachments to each Resource. ")]),_c('p',[_vm._v("Some example Resources might be:")]),_c('ul',[_c('li',[_vm._v("Legislator Talking Points")]),_c('li',[_vm._v("How to be a KP")]),_c('li',[_vm._v("Coordinator Resources")]),_c('li',[_vm._v("Admin-Only Notes")]),_c('li',[_vm._v("Phone Scripts")]),_c('li',[_vm._v("Contact List")]),_c('li',[_vm._v("Marketing Materials (images and videos)")])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }