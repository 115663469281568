<template>
  <v-flex xs12 sm8 lg4 xl3 class="my-auto">
    <v-form ref="resetPasswordForm" @submit.prevent="onResetPassword">
      <v-card>
        <v-card-title>
          <p :style="{fontSize: isMobile ? '26px' : '36px'}" class="mb-4">RESET PASSWORD</p>
        </v-card-title>
        <v-card-text>
          <v-alert type="error" color="error" :value="true" outlined class="mb-3" v-if="error">{{ error }}</v-alert>
          <v-alert type="success" color="primary" :value="true" outlined class="mb-3" v-if="message">{{ message
            }}</v-alert>
          <v-text-field
            type="email"
            prepend-inner-icon="person"
            label="E-mail ID*"
            v-model="user.email"
            :rules="emailRules"
            @input="error = ''"
          >
          </v-text-field>
          <v-text-field
            type="password"
            prepend-inner-icon="lock"
            label="Password*"
            v-model="user.password"
            :rules="passwordRules"
            @input="error = ''"
          >
          </v-text-field>
          <v-text-field
            type="password"
            prepend-inner-icon="lock"
            label="Password Confirmation*"
            v-model="user.password_confirmation"
            :rules="passwordConfirmationRules"
            @input="error = ''"
          >
          </v-text-field>
        </v-card-text>
        <v-btn block type="submit" color="success" :loading="isBusy">Reset Password</v-btn>
      </v-card>
    </v-form>
  </v-flex>
</template>

<script>
import AuthService from '@/services/AuthService'

export default {
  name: 'ResetPasswordPage',
  mixins: [AuthService],
  data () {
    return {
      user: {},
      isFormValid: false,
      error: '',
      message: '',
      isBusy: false,
      token: ''
    }
  },
  computed: {
    emailRules () {
      return [
        v => !!v || 'E-mail is required.',
        v => this.validateEmail(v) || 'E-mail must be valid.',
      ]
    },
    passwordRules () {
      return [
        v => !!v || 'Password is required.',
        v => (v && v.length >= 6) || 'Password should contain more than 6 characters.'
      ]
    },
    passwordConfirmationRules () {
      return [
        v => v === this.user.password || 'Password doesn\'t match.'
      ]
    }
  },
  mounted() {
    this.token = this.$route.params.token
  },
  methods: {
    onResetPassword () {
      if (!this.$refs.resetPasswordForm.validate()) return
      this.isBusy = true
      this.reset({token: this.token, ...this.user})
        .then((res) => {
          this.error = ''
          this.message = res.data.message
          this.isBusy = false
          setTimeout(() => {
            this.$router.push({name: 'login'})
          }, 3000)
        })
        .catch((err) => {
          this.error = err.response.data.message
          this.message = ''
          this.isBusy = false
        })
    }
  }
}
</script>
