<template>
  <v-dialog max-width="600" v-model="isModalOpen">
    <v-form ref="tasksForm">
      <v-card>
        <v-card-title>
          <span class="title">Session Tasks (*optional)</span>
          <v-spacer></v-spacer>
          <v-icon class="ml-3" @click="isModalOpen = false">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-list three-line>
          <template v-for="task in filteredTaskList">
            <v-list-item :key="task.name">
              <v-list-item-content>
                <v-list-item-title>{{ task.name }} ({{ formatDate(task.deadline) }})</v-list-item-title>
                <v-list-item-subtitle><em>{{ task.tooltip }}</em></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: ['taskList'],
  data () {
    return {
      isModalOpen: false
    }
  },
  computed: {
    filteredTaskList() {
      return this.taskList.filter((task) => {
        return !['Currency', 'Notes'].includes(task.task_type.data_type)
      })
    },
  },
  methods: {
    toggle () {
      this.isModalOpen = true
    }
  }
}
</script>
