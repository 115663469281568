import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary","size":"24"},on:{"click":function($event){$event.stopPropagation();_vm.isModalOpen = true}}},[_vm._v("help")]),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Session Dashboard Help")]),_c(VCardText,[_vm._v(" This is where you will monitor all Session Tasks, notes, donations, etc. This page will allow you to see everything that is relevant to your current Session in one page. "),_c('br'),_c('br'),_vm._v(" If you do not have an active Session, either cextend the previous Session (by giving it a future end date), or create a new Session. You must have an active Session at all times. "),_c('br'),_c('br'),_vm._v(" To send Messages, click the \"Send Message\" button and then you may select recipients and filters. "),_c('br'),_c('br'),_vm._v(" Show or hide columns by using the \"Edit Columns\" button in the submenu (behind the 3-dot icon). You may also Export what you see using the \"Export\" button. "),_c('br'),_c('br'),_c(VChip,{staticClass:"ma-2",attrs:{"small":"","color":"primary","label":"","href":"https://help.kpdashboard.com/en/articles/8867317-sending-messages","target":"_blank"}},[_vm._v(" How to send messages "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v(" mdi-open-in-new ")])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }