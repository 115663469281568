import axios from 'axios'

export default {
  methods: {
    getCustomMessages(clientId, params = '') {
      return axios.get(`/clients/${clientId}/custom-message`, {params})
    },
    storeCustomMessages(clientId, payload) {
      return axios.post(`/clients/${clientId}/custom-message`, payload)
    },
    deleteCustomMessage(clientId, customMessageId) {
      return axios.post(`/clients/${clientId}/custom-message/${customMessageId}/delete`)
    },
  }
}
